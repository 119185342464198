import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { deleteDepartmentProperty } from '../../actions/apiDepartmentUpdates';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
	content: {
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
	},
};

class DeleteDepartmentPropertyButton extends Component {
	constructor(props) {
		super(props);

		this.state = { department_id: this.props.department_id, errors: { deleteDepartmentPropertyError: false } };

		this.deleteDepartmentProperty = this.deleteDepartmentProperty.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	afterOpenModal() {
	}

	deleteDepartmentProperty() {
		const id = this.props.columnid;
		const { departmentid } = this.props;
		const errorType = 'deleteDepartmentPropertyError';

		this.props.deleteDepartmentProperty(departmentid, id, errorType);
	}

	render() {
		const { errors } = this.state;
		return (
			<button className="btn btn-sm btn-danger float-end" onClick={this.deleteDepartmentProperty} key={`delete_col_button_${this.props.columnid}`}>
				<i className="fal fa-minus-square float-end" />
			</button>
		);
	}
}

const mappedActions = {
	deleteDepartmentProperty,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.original_departments,
	};
}
export default connect(mapStateToProps, mappedActions)(DeleteDepartmentPropertyButton);
