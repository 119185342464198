import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import FileAttachment from './FileAttachment';
import Estimates from './Estimates';
import PrintTab from './PrintTab';
import PartOrders from './PartOrders';
import Vauto from './Vauto';
import Recalls from './Recalls';
import BodyWork from './BodyWork';
import Inspection from './Inspection';
import InspectionEautomotive from './InspectionEautomotive';
import InspectionSimple from './InspectionSimple';
import VehicleHistorySummary from './VehicleHistorySummary';
import PicturesTab from './PicturesTab';
import EditVehicle from './EditVehicle';
import EditAll from './EditAll';
import SuperForm from './SuperForm';
import MechanicalTab from './MechanicalTab';
import VendorTab from './VendorTab';
import ReconTracker from './ReconTracker';
import ServiceForm from './ServiceForm';
import LoanerForm from './LoanerForm';
import DetailSlip from './DetailSlip';
import UciForm from './UciForm';
import UviForm from './UviForm';
import UviFormPilot from './UviFormPilot';
import CheckboxesTab from './CheckboxesTab';
import UsedCarChecklist from './UsedCarChecklist';
import CarvanaTab from './CarvanaTab';
import NysiReadyTab from './NysiReadyTab';
import ReconCost from './ReconCost';
import StanleyReconCost from './StanleyReconCost';
import Expenses from './Expenses';
import ListCostTab from './ListCostTab';
import WorkOrderList from './WorkOrderList';
import AutomaxForm from './AutomaxForm';
import MultipleVins from './MultipleVins';
import NewWorkItems from './NewAppraisals';
import Transport from './Transport';

import {
	lookupProfilePermission,
	activeVehicle,
} from '../../../../actions/lookups';

class VehicleTab extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	componentDidMount() {
		this.props.activeVehicle(this.props.vehicle_id);
	}

	render() {
		let admin_user = false;
		if ((this.props.current_user_profile.name === 'admin') || (this.props.current_user_profile.name === 'super_admin')) {
			admin_user = true;
		}
		let vehicle = {};
		let tab = this.props.tab;

		if (this.props.active_vehicle.id === this.props.vehicle_id) {
			vehicle = this.props.active_vehicle;
		} else {
			tab = '';
		}

		return (
			<div className="w-100 bg-light-grey pad-bottom-1" key={`${this.props.tab.id}___${vehicle.id}`}>

				{(() => {
					switch (tab) {
					case 'tab_automax_form':
						// found
						if (this.props.lookupProfilePermission('tab_automax_form') || admin_user) {
							return <AutomaxForm {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_file_attachment':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_file_attachment')) {
							return <FileAttachment vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_history':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_history')) {
							return <VehicleHistorySummary vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_history_detailed':
						if (admin_user || this.props.lookupProfilePermission('tab_history_detailed')) {
							return <VehicleHistorySummary vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_parts_order':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_parts_order')) {
							return <PartOrders vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_estimate':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_estimate')) {
							return <Estimates vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_pictures':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_pictures')) {
							return <PicturesTab vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_cost':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_cost')) {
							return '';
						}
						break;
					case 'tab_body_work':
						if (admin_user || this.props.lookupProfilePermission('tab_body_work')) {
							return <BodyWork vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_detail_slip':
						if (admin_user || this.props.lookupProfilePermission('tab_detail_slip')) {
							return <DetailSlip vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_inspection':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_inspection')) {
							if (this.props.current_dealership === 'eautomotive') {
								return <InspectionEautomotive vehicle={vehicle} vehicle_id={vehicle.id} />;
							}
							return <Inspection vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_todo':
						if (admin_user || this.props.lookupProfilePermission('tab_todo')) {
							return <FileAttachment vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_appraisal':
						if (admin_user || this.props.lookupProfilePermission('tab_appraisal')) {
							return <Estimates vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_transport':
						if (admin_user || this.props.lookupProfilePermission('tab_transport')) {
							return <Transport vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_vauto':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_vauto')) {
							return <Vauto vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_loaner_form':
						if (admin_user || this.props.lookupProfilePermission('tab_loaner_form')) {
							return <LoanerForm vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_file_attachment':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_file_attachment')) {
							return <FileAttachment vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_checkboxes':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_checkboxes')) {
							return <CheckboxesTab tab={tab} vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_customer_pay':
						if (admin_user || this.props.lookupProfilePermission('tab_customer_pay')) {
							return <FileAttachment vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_appointment':
						if (admin_user || this.props.lookupProfilePermission('tab_appointment')) {
							return <FileAttachment vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_deposit':
						if (admin_user || this.props.lookupProfilePermission('tab_deposit')) {
							return <FileAttachment vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_print':
						if (admin_user || this.props.lookupProfilePermission('tab_print')) {
							return <PrintTab vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_recall':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_recall')) {
							return <Recalls vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_vehicle_history_summary':
						if (admin_user || this.props.lookupProfilePermission('tab_vehicle_history_summary')) {
							return <VehicleHistorySummary vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_multiple_vins':
						if (admin_user || this.props.lookupProfilePermission('tab_multiple_vins')) {
							return <MultipleVins vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_edit_vehicle':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_edit_vehicle')) {
							return <EditVehicle vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_edit_all':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_edit_all')) {
							return <EditAll vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_vendor':
						// found
						if (admin_user || this.props.lookupProfilePermission('tab_vendor')) {
							return <VendorTab vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_mechanical':
						// found
						if (this.props.lookupProfilePermission('tab_mechanical') || admin_user) {
							return <MechanicalTab vehicle={vehicle} vehicle_id={vehicle.id} />;
						}
						break;
					case 'tab_superform':
						if (this.props.lookupProfilePermission('tab_superform') || admin_user) {
							return <SuperForm vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_recon_tracker':
						if (this.props.lookupProfilePermission('tab_recon_tracker') || admin_user) {
							return <ReconTracker {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_service_form':
						if (this.props.lookupProfilePermission('tab_service_form') || admin_user) {
							return <ServiceForm {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_uci':
						if (this.props.lookupProfilePermission('tab_uci') || admin_user) {
							return <UciForm {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_carvana':
						if (this.props.lookupProfilePermission('tab_carvana') || admin_user) {
							return <CarvanaTab {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_uvi':
						if (this.props.lookupProfilePermission('tab_uvi') || admin_user) {
							if (this.props.current_dealership === 'pilot') {
								return <UviFormPilot {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
							}
								return <UviForm {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_used_car_checklist':
						if (this.props.lookupProfilePermission('tab_used_car_checklist') || admin_user) {
							return <UsedCarChecklist {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_nysi_ready':
						if (this.props.lookupProfilePermission('tab_nysi_ready') || admin_user) {
							return <NysiReadyTab {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_recon_cost':
						if (this.props.lookupProfilePermission('tab_recon_cost') || admin_user) {
							return <ReconCost {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_stanley_recon_cost':
						if (this.props.lookupProfilePermission('tab_stanley_recon_cost') || admin_user) {
							return <StanleyReconCost {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_expenses':
						if (this.props.lookupProfilePermission('tab_expenses') || admin_user) {
							return <Expenses {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_list_cost':
						if (this.props.lookupProfilePermission('tab_list_cost') || admin_user) {
							return <ListCostTab {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_work_order_list':
						if (this.props.lookupProfilePermission('tab_list_cost') || admin_user) {
							return <WorkOrderList {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					case 'tab_new_work_items':
						if (this.props.lookupProfilePermission('tab_new_work_items') || admin_user) {
							return <NewWorkItems {...this.props} vehicle={vehicle} vehicle_id={vehicle.id} tab={this.props.tab} />;
						}
						break;
					default:
						return '';
					}
				})()}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		current_user_profile: state.settings_users.current_user_profile,
		current_dealership: state.settings_dealerships.current_dealership,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
const mappedActions = {
	lookupProfilePermission,
	activeVehicle,
};

export default connect(mapStateToProps, mappedActions)(VehicleTab);
