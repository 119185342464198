import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as lookups from './lookups';
import * as AllMutates from './AllMutates';

export function updateFilter(id, data, errorType) {
	return dispatch => Api.patchData(`all_filters/${id}`, data).then(() => {
		dispatch(getInfo.getAllFilters());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addFilter(data, errorType) {
	return dispatch => Api.postData('all_filters/', data).then((resp) => {
		dispatch(getInfo.getAllFilters());
		return resp.data.data.id;
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addDepartmentFilter(data, errorType) {
	return dispatch => Api.postData('department_filters/', data).then(() => dispatch(lookups.activeEditDepartment(data.department_id))).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteDepartmentFilter(data, errorType) {
	return dispatch => Api.delete(`department_filters/${data.id}`).then(() => dispatch(lookups.activeEditDepartment(data.department_id))).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteFilter(id, errorType) {
	return dispatch => Api.delete(`all_filters/${id}`).then(() => dispatch(getInfo.getAllFilters())).catch(ex => Api.handleApiError(ex, errorType));
}
