import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as AllMutates from './AllMutates';

export function updateWorkItemPresets(id, data, errorType) {
	return dispatch => Api.patchData(`work_item_presets/${id}`, data).then(() => {
		dispatch(getInfo.getWorkItemPresets());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addWorkItemPreset(data, errorType) {
	if(data.id){
		return (dispatch, getState) => Api.patchData(`work_item_presets/${data.id}`, data).then(resp => dispatch(getInfo.getWorkItemPresets())).catch(ex => Api.handleApiError(ex, errorType));
	}
	else{
		return (dispatch, getState) => Api.postData('work_item_presets/', data).then(resp => dispatch(getInfo.getWorkItemPresets())).catch(ex => Api.handleApiError(ex, errorType));
	}
}

export function deleteWorkItemPreset(id, errorType) {
	return dispatch => Api.delete(`work_item_presets/${id}`).then(() => {
		dispatch(getInfo.getWorkItemPresets());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}
