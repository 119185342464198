import React, { Component } from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getReportVehicles } from '../../actions/apiGetInfo';
import { lookupSoldVehicleById, lookupVehicleByIdAllVehicles } from '../../actions/lookups';
import {
	convertSecondsToTime, getSecondsFromTimeRange_24_5, noteStamp, returnDaysHours,
} from '../../utils/dateFunctions';
import ReportVehicles from './ReportVehicles';

class EditTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
 report_id: 0, department_id: 0, modalIsOpen: false, active: false,
};
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal(report_id, department_id) {
		if (report_id > 0) {
			this.props.getReportVehicles(report_id);
			this.setState({ report_id, department_id, modalIsOpen: true });				
		}
	}

	afterOpenModal() {}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	renderItem(item) {
		const onClickHandler = () => this.openModal(item.report_id, item.department_id);
		const i = 1;
		const itemRows = [
			<tr key={`row-data-${item.department + item.dealership}`}>
			    <td><button onClick={onClickHandler} className="btn btn-sm btn-success"><i className="fal fa-plus" /></button></td>
			    <td>{item.dealership}</td>
			    <td>{item.department}</td>
			    <td>{item.average_time}</td>
			    <td>{returnDaysHours(item.goal || 0)}</td>
			    <td>{item.number_of_vehicles}</td>
			</tr>,
		];

		return itemRows;
	}

	render() {
		const self = this;
		const report_dealerships = self.props.report_dealerships.split(',');

		const tableData = [];
		report_dealerships.map((dealership) => {
			if (dealership !== '') {
				const data = self.props.data.xAxis.map((department, index) => ({
					dealership,
					department: self.props.data.xAxis[index] ? self.props.data.xAxis[index] : '',
					average_time: self.props.data.yAxis[dealership] ? self.props.data.yAxis[dealership][index] : '',
					average_time_24_5: self.props.data.rawtime_24_5[dealership] ? self.props.data.rawtime_24_5[dealership][index] : '',
					average_time_24_6: self.props.data.rawtime_24_6[dealership] ? self.props.data.rawtime_24_6[dealership][index] : '',
					report_id: self.props.data.dataId[dealership] ? self.props.data.dataId[dealership][index] : '',
					department_id: self.props.data.departmentId[dealership] ? self.props.data.departmentId[dealership][index] : '',
					goal: self.props.data.goal[dealership] ? self.props.data.goal[dealership][index] : '',
					number_of_vehicles: self.props.data.xCount[dealership] ? self.props.data.xCount[dealership][index] : '',
					vehicles: [],
				}));

				tableData.push(data);
			}
		});

		let allItemRows = [];
		var total_vehicles = 0;
		var total_times = 0;
		var total_average_times = 0;

		tableData.forEach((dealership) => {
			dealership.forEach((item) => {
				if (item.number_of_vehicles > 0) {
					const perItemRows = this.renderItem(item);
					allItemRows = allItemRows.concat(perItemRows);
					total_vehicles += item.number_of_vehicles;
					total_average_times += item.average_time;
					total_times += (item.number_of_vehicles * item.average_time);
				}
			});
		});

		var range = '(24/5)';
		if (this.props.dealership_report_hours === 'age-24_7') {
			range = '(24/7)';
		} else if (this.props.dealership_report_hours === 'age-24_6') {
			range = '(24/6)';
		} else {
			range = '(24/5)';
		}

		return (
			<div>
			<p className="text-center"><span className="badge bg-success">Average Step Time(Combined Displayed Averages): {Math.round(((total_times / total_vehicles) + Number.EPSILON) * 100) / 100} Days / Vehicles: {total_vehicles},
			Totaled Displayed Averages: {Math.round(((total_average_times) + Number.EPSILON) * 100) / 100} Days / Vehicles: {total_vehicles} </span></p>

			<table className="table table-light table-striped w100">
				<thead>
					<tr>
						<th />
						<th>Dealership</th>
						<th>Department</th>
						<th>Avg Days {range}</th>
						<th>Goal</th>
						<th>Vehicles Count</th>
					</tr>
				</thead>
				<tbody>
					{allItemRows}
				</tbody>
			</table>
			<Modal
				isOpen={this.state.modalIsOpen}
				onAfterOpen={this.afterOpenModal}
				onRequestClose={this.closeModal}
				contentLabel="Report Vehicles"
				className="customModal"
				ariaHideApp={false}
				overlayClassName="overlay"
				>
				<div className="customCard">
					<div className="customHeader">
					<button type="button" className="btn btn-danger btn-sm float-end" ariaLabel="Close" onClick={this.closeModal}>
                        <span ariaHidden="true">&times;</span>
                    </button>
					</div>
					<ReportVehicles department_id={this.state.department_id} report_id={this.state.report_id} />
				</div>
				</Modal>
			</div>
		);
	}
}

const mappedActions = {
	lookupSoldVehicleById,
	lookupVehicleByIdAllVehicles,
	getReportVehicles,
};

function mapStateToProps(state) {
	return {
		report_dealerships: state.settings_reports.report_dealerships,
		report_data_filtered: state.settings_reports.report_data_filtered,
		report_monthly_data_filtered: state.settings_reports.report_monthly_data_filtered,
		report_vehicles: state.settings_reports.report_vehicles,
	};
}
export default connect(mapStateToProps, mappedActions)(EditTable);
