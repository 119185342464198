import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserRow from './UserRow';

class ShowUsers extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	filterUsers() {
		if (this.props.searched_users.length > 0) {
			var users = this.props.searched_users.map((user) => {
				if (user.deleted !== true && user.position !== 'super_admin') {
					return <UserRow key={`userrow_${user.id}`} {...this.props} user={user} />;
				}
			});
		} else if (this.props.related_users.length > 0) {
			var users = this.props.related_users.map((user) => {
				if (user.deleted !== true && user.position !== 'super_admin') {
					return <UserRow key={`userrow_${user.id}`} {...this.props} user={user} />;
				}
			});
		} else {
			var users = this.props.all_users.map((user) => {
				if (user.deleted !== true && user.position !== 'super_admin') {
					return <UserRow key={`userrow_${user.id}`} {...this.props} user={user} />;
				}
			});
		}

		return users || [];
	}

	render() {
		return (
			<table className="table mobiletable">
				<thead className={'thead-dark'}>
					<tr>
						<th><i className={'fas fa-edit'} /></th>
						<th>UserName</th>
						<th>First</th>
						<th>Last</th>
						<th>Profile</th>
						<th>Dealerships</th>
						<th />
						<th />
						<th />
					</tr>
				</thead>
				<tbody>

					{this.filterUsers()}

				</tbody>
			</table>
		);
	}
}

function mapStateToProps(state) {
	return {
		all_users: state.settings_users.all_users,
		related_users: state.settings_users.related_users,
		searched_users: state.settings_users.searched_users,
		user_filter: state.settings_users.user_filter,
	};
}

export default connect(mapStateToProps)(ShowUsers);
