import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubHeader from '../../components/SubHeader';
import VehicleList from '../VehicleList';
import Footer from '../../components/Footer/footer.js';
import Header from '../../components/Header/header.js';
import Sidebar from '../../components/Sidebar/sidebar.js';
import SidebarRight from '../../components/SidebarRight';
import Dashboard from '../Dashboard';
import WorkItemDashboard from '../WorkItemDashboard';
import SiteSettings from '../SiteSettings';
import DealerSettings from '../DealerSettings';
import ColorSettings from '../ColorSettings';
import FeedCenter from '../FeedCenter';
import Audits from '../Audits';
import DealerForms from '../DealerForms';
import AddOns from '../AddOns';
import IncomingStepDefaults from '../IncomingStepDefaults';
import Messages from '../Messages';
import Estimates from '../Estimates';
import Users from '../Users';
import Calendar from '../Calendar';
import TabSettings from '../TabSettings';
import TabSettingsEdit from '../TabSettings/TabSettingsEdit';
import AutomaticMoves from '../AutomaticMoves';
import StepWorkFlow from '../StepWorkFlow';
import ReportDefaults from '../ReportDefaults';
import Goals from '../Goals';
import WorkingHours from '../WorkingHours';
import Reports from '../Reports';
import Notifications from '../Notifications';
import DepartmentSetup from '../NewDepartmentSetup';
import EditColumns from '../EditColumns';
import EmailReports from '../EmailReports';
import SupportCenter from '../SupportCenter';
import SupportTickets from '../SupportCenter/SupportTickets';
import SupportVideos from '../SupportCenter/SupportVideos';
import Chat from '../Chat';

import {
	setInactiveStatusInterval,
	syncRepeatData,
	syncInitialData,
	syncInitialReportData,
	setIntervalNumber,
	setInactiveStatus,
} from '../../actions/apiSetInfo';

import {
	refreshAllDepartments,
	getUserCheck,
} from '../../actions/apiGetInfo';

class Content extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
		this.resetTimer = this.resetTimer.bind(this);
		this.setup = this.setup.bind(this);
		this.startTimer = this.startTimer.bind(this);
		this.goActive = this.goActive.bind(this);
		this.goInactive = this.goInactive.bind(this);
	}

	componentDidMount() {
		const self = this;
		const { props } = this;
		this.setup();
		clearInterval(self.props.interval_number);
		var timeRefresh = process.env.NODE_ENV === 'development' ? 5000 : 5000;

		var getData = setInterval(() => {
			if (self.props.inactive_status === false || self.props.current_user.position == 'detail view' || self.props.current_user.position == 'reconditioning inspection') {
				if (self.props.cancel_old_requests === false) {
					self.props.getUserCheck();
					self.props.syncRepeatData();
				}
			}
		}, timeRefresh);
		self.props.setIntervalNumber(getData);
	}

	componentWillUnmount() {
		const self = this;
		clearInterval(self.props.interval_number);
		window.clearTimeout(this.props.inactive_status_interval);
	}

	setup() {
		window.addEventListener('mousemove', this.resetTimer, false);
		window.addEventListener('mousedown', this.resetTimer, false);
		window.addEventListener('keypress', this.resetTimer, false);
		window.addEventListener('DOMMouseScroll', this.resetTimer, false);
		window.addEventListener('mousewheel', this.resetTimer, false);
		window.addEventListener('touchmove', this.resetTimer, false);
		window.addEventListener('MSPointerMove', this.resetTimer, false);

		this.startTimer();
	}

	startTimer() {
		const self = this;
		var timeRefresh2 = process.env.NODE_ENV === 'development' ? 100000 : 100000;
		const timeoutID = window.setTimeout(this.goInactive, timeRefresh2);
		self.props.setInactiveStatusInterval(timeoutID);
	}

	resetTimer(e) {
		if (this.props.inactive_status === true) {
			window.clearTimeout(this.props.inactive_status_interval);
			this.goActive();
		}
	}

	goActive() {
		this.props.setInactiveStatus(false);
		this.props.refreshAllDepartments();
		this.startTimer();
	}

	goInactive() {
		console.log('inactive');
		this.props.setInactiveStatus(true);
	}

	render() {
		var sidebar = 'row row-offcanvas row-offcanvas-left';
		var maincontent = 'col-md-8 col-lg-9 main-vehicles pad-top-0 pad-left-0 pad-right-0';

		const hideArray = ['support-tickets', 'support-center', 'live-chats', 'email-reports', 'notifications', 'color-settings', 'site-settings', 'dealer-settings', 'reports', 'profile', 'users', 'department-setup', 'estimates', 'dashboard', 'messages', 'user-single', 'addons', 'automatic-moves', 'edit-columns', 'tab-settings', 'tab-settings-edit', 'step-workflows', 'working-hours', 'dealer-forms', 'site-settings', 'goals', 'calendar', 'incoming-step-defaults', 'report-defaults', 'audits', 'feed-center'];

		if (this.props.sidebar_status) {
			if (this.props.sidebar_status_right) {
				sidebar = 'row row-offcanvas row-offcanvas-left row-offcanvas-right active right-active space-right-0 space-left-0';
			} else {
				sidebar = 'row row-offcanvas row-offcanvas-left row-offcanvas-right active space-right-0 space-left-0';
			}
		} else if (this.props.sidebar_status_right) {
			sidebar = 'row row-offcanvas row-offcanvas-left row-offcanvas-right right-active space-right-0 space-left-0';
		} else {
			sidebar = 'row row-offcanvas row-offcanvas-left row-offcanvas-right space-right-0 space-left-0';
		}

		if (hideArray.includes(this.props.page)) {
			if (this.props.sidebar_status_right) {
				maincontent = 'col-md-8 col-lg-9 main-vehicles pad-top-0 pad-left-0 pad-right-0';
			} else {
				maincontent = 'col-md-12 main-vehicles pad-top-0 pad-left-0 pad-right-0';
			}
		} else if (this.props.sidebar_status && this.props.sidebar_status_right) {
			maincontent = 'col-md-5 col-lg-6 main-vehicles pad-top-0 pad-left-0 pad-right-0';
		} else if (this.props.sidebar_status || this.props.sidebar_status_right) {
			maincontent = 'col-md-8 col-lg-9 main-vehicles pad-top-0 pad-left-0 pad-right-0';
		} else {
			maincontent = 'col-md-12 main-vehicles pad-top-0 pad-left-0 pad-right-0';
		}

		return <div className="container-fluid main">
			<Header />
			<div className={sidebar}>
				<Sidebar />
				<div className={maincontent}>
					<SubHeader />
					{(() => {
						switch (this.props.settings_nav.page) {
						case 'dashboard':
							return <Dashboard />;
						case 'site-settings':
							return <SiteSettings />;
						case 'color-settings':
							return <ColorSettings />;
						case 'dealer-settings':
							return <DealerSettings />;
						case 'feed-center':
							return <FeedCenter />;
						case 'audits':
							return <Audits />;
						case 'incoming-step-defaults':
							return <IncomingStepDefaults />;
						case 'addons':
							return <AddOns />;
						case 'calendar':
							return <Calendar />;
						case 'tab-settings':
							return <TabSettings />;
						case 'tab-settings-edit':
							return <TabSettingsEdit />;
						case 'automatic-moves':
							return <AutomaticMoves />;
						case 'dealer-forms':
							return <DealerForms />;
						case 'messages':
							return <Messages />;
						case 'users':
							return <Users />;
						case 'step-workflows':
							return <StepWorkFlow />;
						case 'report-defaults':
							return <ReportDefaults />;
						case 'goals':
							return <Goals />;
						case 'working-hours':
							return <WorkingHours />;
						case 'reports':
							return <Reports />;
						case 'notifications':
							return <Notifications />;
						case 'department-setup':
							return <DepartmentSetup />;
						case 'edit-columns':
							return <EditColumns />;
						case 'email-reports':
							return <EmailReports />;
						case 'support-tickets':
							return <SupportTickets />;
						case 'support-center':
							return <SupportCenter />;
						case 'support-videos':
							return <SupportVideos />;
						case 'work-item-dashboard':
							return <WorkItemDashboard />;
						case 'chat':
							return <Chat />;

						default:
							return <VehicleList />;
						}
					})()}
				</div>
				<SidebarRight />
			</div>
			<Footer />
		</div>;
	}
}

const mappedActions = {
	setInactiveStatusInterval,
	syncRepeatData,
	syncInitialData,
	setIntervalNumber,
	setInactiveStatus,
	syncInitialReportData,
	refreshAllDepartments,
	getUserCheck,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		interval_number: state.settings_nav.interval_number,
		settings_nav: state.settings_nav,
		page: state.settings_nav.page,
		sidebar_status: state.settings_nav.menus.sidebar_status,
		sidebar_status_right: state.settings_nav.menus.sidebar_status_right,
		interval_number: state.settings_nav.interval_number,
		last_updated: state.settings_nav.last_updated,
		cancel_old_requests: state.settings_nav.cancel_old_requests,
		inactive_status: state.settings_nav.inactive_status,
		inactive_status_interval: state.settings_nav.inactive_status_interval,
		is_updating: state.settings_nav.is_updating,
	};
}

export default connect(mapStateToProps, mappedActions)(Content);
