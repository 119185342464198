import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import TextFieldGroupWithButton from '../../inputs/TextFieldGroupWithButton';
import EditTabSettings from '../../inputs/EditTabSettings';
import FlashMessageList from '../../inputs/FlashMessageList';
import Api from '../../api/reconApi.js';
import { addEmailReport } from '../../actions/apiEmailReports';

class EmailReport extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			name: '',
			report_steps: '',
			report_dealerships: '',
			report_filters: '',
			report_run_day: '',
			report_run_time: '',
			report_run_frequency: '',
			report_choices: '',
			report_users: '',
			report_time_zone: '',
			active: true,
		};

		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.addEmailReport = this.addEmailReport.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	addEmailReport() {
		const data = {
			name: this.state.name,
			report_steps: this.state.report_steps,
			report_dealerships: this.state.report_dealerships,
			report_filters: this.state.report_filters,
			report_run_day: this.state.report_run_day,
			report_run_time: this.state.report_run_time,
			report_run_frequency: this.state.report_run_frequency,
			report_choices: this.state.report_choices,
			report_users: this.state.report_users,
			report_time_zone: this.state.report_time_zone,
			active: this.state.active,
		};

		const extra = {};

		this.props.addEmailReport(data, extra).then((resp) => {
			if (resp == 'email report added') {
				this.setState({
					name: '',
					report_steps: '',
					report_dealerships: '',
					report_filters: '',
					report_run_day: '',
					report_run_time: '',
					report_run_frequency: '',
					report_choices: '',
					report_users: '',
					report_time_zone: '',
					modalIsOpen: false,
				});
			}
		});
	}

	onChange(event) {
		const { name } = event.target;
		this.setState({ [name]: event.target.value });
	}

	onChangeSelect(e) {
		this.setState({ department_id: e.value });
	}

	render() {
		const { errors } = this.state;

		const defaultStyle = {
			background: this.props.bg_color,
			backgroundColor: this.props.bg_color,
			color: this.props.font_color,
		};

		return (
			<div className="div">
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="PickColor"
					className="d-flex justify-content-center pad-bottom-2 space-bottom-2"
					contentLabel="Add Email Report"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-6 md-auto space-top-2 bg-dark">
						<div className="modal-content pad-left-2 pad-right-2">

							<div className="modal-header bg-dark text-white">
								<h4 className="modal-title  pad-left-1 pad-right-1">Create a report name below:</h4>
								<button type="button" className="bg-danger close text-white" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
							</div>

							<div className="modal-body add-email-report mobile-pad-top-2">
								<div className="card-block bg-dark">
									<div className="row text-center text-white">
										<FlashMessageList {...this.props} />
									</div>
									<div className="row">
										<TextFieldGroup
											onChange={this.onChange}
											value={this.state.name}
											type={'text'}
											name={'name'}
											field={'name'}
											label={'Report Name'}
											updatetype="email_report"
											disabled={false}
										/>

									</div>
								</div>
							</div>
							<div className="modal-footer mobile-pad-top-2">
								<button type="button" className="btn btn-success" onClick={this.addEmailReport}>Add EmailReport</button>
							</div>

						</div>
					</div>

				</Modal>
				<span className="input-group-text btn btn-primary" onClick={this.openModal}>
					<i className="fal fa-plus" />
					{' '}
Add EmailReport
				</span>
			</div>
		);
	}
}

const mappedActions = {
	addEmailReport,
};

function mapStateToProps(state) {
	return {
		email_reports: state.settings_reports.email_reports,
	};
}
export default connect(mapStateToProps, mappedActions)(EmailReport);
