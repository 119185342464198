import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { addProfilePermission } from '../actions/users';

class PermissionCheckbox extends Component {
	constructor(props) {
		super(props);

		this.state = { value: this.props.value || '', checked: this.props.checked || '' };
		this.onChangeChecked = this.onChangeChecked.bind(this);
	}

	onChangeChecked(e) {
		const self = this;
		const errorType = 'addProfilePermissionError';
		const data = {
			default_profile_id: this.props.default_profile_id,
			value: e.target.checked ? 'true' : 'false',
			key: e.target.name,
		};

		self.setState({ checked: event.target.checked });

		self.props.addProfilePermission(data, errorType);
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card">
					<div className="card-body row">
						<div className="col-md-12">
							<div className={classnames('form-group', { 'has-danger': this.props.error })}>
								<span className="switch">
									<input
										onChange={this.onChangeChecked}
										type={'checkbox'}
										name={this.props.name}
										data-id={this.props.parentid}
										key={`permission${this.props.name}`}
										checked={this.props.checked == 'true' ? 'checked' : ''}
										className={classnames('text-center', { 'form-control-danger': this.props.error })}
										id={`switch_id_${this.props.name}`}
									/>
									<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}`}>{this.props.name.replace(/_/g, ' ')}</label>
								</span>
							</div>
						</div>
						<div className="col-md-12">
							<small>
								<i className={'fa fa-info-circle text-info'} />
								{' '}
							</small>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

const mappedActions = {
	addProfilePermission,
};

function mapStateToProps(state) {
	return {
		input_types: state.settings_departments.input_types,
	};
}
export default connect(mapStateToProps, mappedActions)(PermissionCheckbox);
