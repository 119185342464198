import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import AllInputs from '../../../../inputs/AllInputs';
import MechanicalNoteContainer from './MechanicalNoteContainer';
import WorkItems from './WorkItems';
import {
	addWorkItem,
} from '../../../../actions/apiWorkItems';
import {
	processDepartments,
} from '../../../../actions/apiGetInfo';
import {
	lookupService,
	lookupDepartmentById,
	vsearch,
} from '../../../../actions/lookups';
import {
	addVehicleNote,
} from '../../../../actions/apiVehicleNotes';
import {
	addMehanicalNotification,
} from '../../../../actions/apiNotifications';

const estimateFields = ['id', 'Select Vendor', 'Description', 'Files/Images', 'Action', 'x'];

class MechanicalTab extends Component {
	constructor(props) {
		super(props);

		this.state = { addEstimateError: '', service_advisor: '', service_advisor_name: '' };
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.tabByName = this.tabByName.bind(this);
		this.onClickAlertReconForm = this.onClickAlertReconForm.bind(this);
		this.onClickAlertServiceAdvisor = this.onClickAlertServiceAdvisor.bind(this);
		this.vehiclePropertyUID = this.vehiclePropertyUID.bind(this);
		this.vehicleProperty = this.vehicleProperty.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeSelect(e) {
		this.setState({ service_advisor: e.value, service_advisor_name: e.label });
	}

	vehicleProperty(name) {
		const value = _.filter(this.props.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	vehiclePropertyUID(name) {

		const value = _.filter(this.props.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				this.setState({ service_advisor: value[0].uid });
				return value[0].uid;
			}
		} else {
			return '';
		}
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
		};

		Promise.all([
			this.props.addWorkItem(data, 'addWorkItem'),
		]).then(() => {
			this.props.processDepartments([this.props.vehicle.department_id]);
		}).then(() => {
			const { vehicle_filter, department } = this.props;
			if (vehicle_filter.type === 'searched') {
				this.props.vsearch(vehicle_filter.value);
			}
		});
	}

	onClickAlertReconForm(e) {
		const message = `Vehicle Vin:${this.props.active_vehicle.vin} Stock: ${this.props.active_vehicle.stock} requires your attention using the Mech Form. Vehicle is currently located in the ${this.props.lookupDepartmentById(this.props.active_vehicle.department_id).name} stage`;

		if (this.props.softname.toLowerCase().includes('walser')) {
			const data3 = {
				note_type: 'mechanical_form',
				message,
				user_id: '18271',
			};

			this.props.addMehanicalNotification(data3, 'addMehanicalNotification');

			const data2 = {
				vehicle_id: this.props.active_vehicle.id,
				value: 'Alert sent to Recon Manager',
				note_type: 'mechanical',
				uid: this.props.current_user.user,
			};

			this.props.addVehicleNote(data2, 'addVehicleNoteError');
		}
	}

	onClickAlertServiceAdvisor(e) {
		const message = `Vehicle Vin:${this.props.active_vehicle.vin} Stock: ${this.props.active_vehicle.stock} requires your attention using the Mech Form. Vehicle is currently located in the ${this.props.lookupDepartmentById(this.props.active_vehicle.department_id).name} stage`;
		const data = {
			note_type: 'mechanical_form',
			message,
			user_id: this.state.service_advisor,
		};

		this.props.addMehanicalNotification(data, 'addMehanicalNotification');

		const data2 = {
			vehicle_id: this.props.active_vehicle.id,
			value: `Alert sent to ${this.state.service_advisor_name}`,
			note_type: 'mechanical',
			uid: this.props.current_user.user,
		};

		this.props.addVehicleNote(data2, 'addVehicleNoteError');
	}

	tabByName(name) {
		const tab = _.sortBy(_.filter(this.props.all_tabs, o => o.name == name), 'id');
		return tab[0] || [];
	}

	serviceOptions() {
		return this.props.lookupService().map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.id, label: option.user_name };
			} else {
				var obj = { value: option.id, label: option.user_name };
			}
			return obj;
		});
	}

	getTires() {
		const certifiedFields = [{ name: 'tread depth front_left', label: 'tread depth front left', type: 'userstamp_text' },
			{ name: 'tread depth front_right', label: 'tread depth front right', type: 'userstamp_text' },
			{ name: 'tread depth rear_left', label: 'tread depth rear left', type: 'userstamp_text' },
			{ name: 'tread depth rear_right', label: 'tread depth rear right', type: 'userstamp_text' },
			{ name: 'pad life front_left', label: 'pad life front left', type: 'userstamp_text' },
			{ name: 'pad life front_right', label: 'pad life front right', type: 'userstamp_text' },
			{ name: 'pad life rear_left', label: 'pad life rear left', type: 'userstamp_text' },
			{ name: 'pad life rear_right', label: 'pad life rear right', type: 'userstamp_text' }];

		const vehicle = this.props.active_vehicle;

		const html = certifiedFields.map((tab_property) => {
			const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name });

			if (typeof vehicle_column !== 'undefined') {
				const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

				var setdisabled = true;

				if (vehicle_column.name) {
					const allowedArray = ['super_admin', 'admin', 'service', 'service_manager', 'management', 'recon_manager'];

					if (allowedArray.includes(this.props.current_user.position)) {
						var setdisabled = false;
					}
				}

				return <div className="col-md-3 space-bottom-1 bg-dark text-white text-center" key={`form2_${vehicle.id}${vehicle_column.id}_${vehicle.id}`}>
					<label className="text-capitalize font-weight-bold">{vehicle_column.name}</label>
					<AllInputs
						value={colObject.value || ''}
						type={'userstamp_text'}
						name={vehicle_column.name}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={colObject.user_name}
						vehicle={vehicle}
						updated_at={colObject.updated_at}

					/>
           </div>;
			}
		});

		return html;
	}

	render() {
		const vehicle = this.props.active_vehicle;
		const vehicle_properties = this.props.vehicle_properties;
		const get_tires = this.getTires();

		const ro_number = _.find(vehicle_properties, { key: 'ro number' }) || [];
		const certified = _.find(vehicle_properties, { key: 'certified' }) || [];
		const precision_used = _.find(vehicle_properties, { key: 'precision used' }) || [];
		const not_certified = _.find(vehicle_properties, { key: 'not certified' }) || [];
		const wholesale = _.find(vehicle_properties, { key: 'wholesale' }) || [];
		const work_complete = _.find(vehicle_properties, { key: 'work complete' }) || [];

		let setdisabled = true;
		let setdisabledService = true;
		let allowedArrayService = [];
		let allowedArray = [];

		allowedArrayService = ['super_admin', 'admin', 'service', 'service_manager', 'management', 'recon_manager'];

		if (allowedArrayService.includes(this.props.current_user.position)) {
			// for ro number and work complete
			setdisabledService = false;
		}

		allowedArray = ['super_admin', 'admin', 'management'];

		if (allowedArray.includes(this.props.current_user.position)) {
			setdisabled = false;
		}

		return (<div>

			<div className="row bg-light-grey pad-top-1" />

			<div className="container space-top-1">
				<div className="row">

					<table className="table">
						<thead>
							<tr className="text-center">
								<td>

									<div className="vehicleInfo text-capitalize text-center">
										{this.vehicleProperty('year')}
										{' '}
										{this.vehicleProperty('make')}
										{this.vehicleProperty('model')}
										<br />
										{' '}
(
										{vehicle.stock}
)
										{' '}
										<br />
										<mark>{vehicle.dealership}</mark>
										<br />
										{this.vehicleProperty('service advisor')}

									</div>

								</td>
								<td>
									<label>Ro Number</label>
									<AllInputs
										value={ro_number.value || ''}
										type={'userstamp_text'}
										name={'ro number'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabledService}
										user_name={ro_number.user_name}
										updated_at={ro_number.updated_at}
										vehicle={vehicle}
									/>
								</td>
								<td>
									<label>Certified</label>
									<AllInputs
										value={certified.value || ''}
										type={'userstamp'}
										name={'certified'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={certified.user_name}
										updated_at={certified.updated_at}
									/>
								</td>
								<td>
									<label>Precision Used</label>
									<AllInputs
										value={precision_used.value || ''}
										type={'userstamp'}
										name={'precision used'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={precision_used.user_name}
										updated_at={precision_used.updated_at}
									/>
								</td>
								<td>
									<label>Not Certified</label>
									<AllInputs
										value={not_certified.value || ''}
										type={'userstamp'}
										name={'not certified'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={not_certified.user_name}
										updated_at={not_certified.updated_at}
									/>
								</td>
								<td>
									<label>Wholesale</label>
									<AllInputs
										value={wholesale.value || ''}
										type={'userstamp'}
										name={'wholesale'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={wholesale.user_name}
										updated_at={wholesale.updated_at}
									/>
								</td>
								<td>
									<label>Work Complete</label>
									<AllInputs
										value={work_complete.value || ''}
										type={'userstamp'}
										name={'work complete'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabledService}
										user_name={work_complete.user_name}
										updated_at={work_complete.updated_at}
										vehicle={vehicle}
									/>
								</td>

							</tr>

						</thead>
					</table>
				</div>
			</div>
			<div className="row bg-light-grey pad-top-1" />
			<div className="container space-top-1">

				<div className="row">

					{get_tires}
				</div>

				<div className="row bg-light-grey pad-top-1" />

				<div className="row">

					<button className="btn btn-sm btn-success" onClick={this.onClick}>Add Work Item</button>

					<table className="table table-striped">
						<thead className={'thead text-center'}>
							<tr>
								<th className="text-center">Recommend</th>
								<th className="text-center">Required</th>
								<th className="text-center">Description</th>
								<th className="text-center">Labor</th>
								<th className="text-center">OEM</th>
								<th className="text-center">Non OEM</th>
								<th className="text-center">Work Approved</th>
								<th className="text-center">Work Declined</th>
								<th className="text-center">Item Completed</th>
								<th className="text-center">X</th>
							</tr>
						</thead>
						<WorkItems {...this.props} vehicle={vehicle} />
					</table>

				</div>

				<div className="row bg-light-grey pad-top-1">
					<div className="col" />
					<div className="col-md-8 space-bottom-1">
						<div className="card">
							<div className="card card-header">
								<div className="d-flex">
									<div className="col text-center">
										<button className="btn btn-sm btn-success" onClick={this.onClickAlertReconForm}>Alert Recon Manager</button>
									</div>

									<div className="col text-center">
										<Select
											onChange={this.onChangeSelect}
											value={this.serviceOptions().filter(({ value }) => value == this.state.service_advisor)}
											name={'service_advisor'}
											options={this.serviceOptions()}
											className="text-capitalize col"
											clearable={false}
											searchable={false}
											key={`ds_${this.props.active_vehicle.id}_service`}
											menuContainerStyle={{ zIndex: 5 }}
										/>
									</div>
									<div className="col text-center">
										<button className="btn btn-sm btn-warning" onClick={this.onClickAlertServiceAdvisor}>Alert Selected User</button>
									</div>
								</div>
							</div>
							<div className="card bg-light">
								<MechanicalNoteContainer vehicle={vehicle} notes={vehicle.vehicle_notes.reverse()} />
							</div>
						</div>
					</div>
					<div className="col" />
				</div>

			</div>

		</div>
		);
	}
}

const mappedActions = {
	addWorkItem,
	lookupService,
	lookupDepartmentById,
	addMehanicalNotification,
	addVehicleNote,
	processDepartments,
	vsearch,
};

function mapStateToProps(state) {
	return {
		all_tabs: state.settings_site.all_tabs,
		vehicle_columns: state.settings_departments.vehicle_columns,
		current_user: state.current_user,
		softname: state.settings_dealerships.installation.softname || '',
		vehicle_filter: state.settings_nav.vehicle_filter,
		department: state.settings_nav.department,
		active_vehicle: state.settings_refresh.active_vehicle,
		vehicle_properties: state.settings_refresh.active_vehicle.vehicle_properties,
	};
}

export default connect(mapStateToProps, mappedActions)(MechanicalTab);
