import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Goals from '../Goals';
import EmailReports from '../EmailReports';
import WorkingHours from '../WorkingHours';
import StepWorkFlow from '../StepWorkFlow';
import ReportDefaults from '../ReportDefaults';
import TimeDashboardSettings from '../TimeDashboardSettings';
import ReportDashboard from './ReportDashboard';
import WorkFlowDataReport from './WorkFlowDataReport';
import InRecon from './InRecon';
import {
	lookupPermission,
	lookupIsAdmin,
} from '../../actions/lookups';

import {
	getReportDepartments,
} from '../../actions/apiGetInfo';

import {
	setCurrentReportTab, syncReportData, setReportInfo, setReportFilterInfo,
} from '../../actions/apiSetInfo';

class ButtonGroup extends Component {
	constructor() {
		super();

		this.changeReportTab = this.changeReportTab.bind(this);
	}

	changeReportTab(e) {
		this.props.setCurrentReportTab(this.props.name);
	}

	render() {
		return (
			<button type="button" className={this.props.active ? 'btn btn-success active shadow_2 text-center text-white buttonbar' : 'btn btn-default shadow_2  text-center text-success buttonbar'} name={this.props.name} onClick={this.changeReportTab}>
				<i className={`${this.props.icon}`} />
				<br />
				<span className="pad-top-1">{this.props.name}</span>
			</button>
		);
	}
}

class Reports extends Component {
	constructor(props) {
		super(props);
		// Set some initial data.
		this.state = {};
	}

	componentDidMount() {
		const self = this;
		self.props.getReportDepartments();
	}

	render() {
		const work_flow = _.find(this.props.work_flows, { name: 'used' }) || [];
		var departments = this.props.report_department_data || [];
        var buttons = [];
		let reguser = '';
        if (this.props.current_user.position == 'super_admin') {
            buttons.push(<ButtonGroup {...this.props} name="Department Reports" icon={'fal fa-analytics text-left'} active={this.props.current_report_tab == 'Department Reports'} />);
            buttons.push(<ButtonGroup {...this.props} name="Time Dashboard" icon={'fa fa-ballot-check text-left'} active={this.props.current_report_tab == 'Time Dashboard'} />);
            buttons.push(<ButtonGroup {...this.props} name="Goals" icon={'fal fa-stopwatch text-left'} active={this.props.current_report_tab == 'Goals'} />);
            buttons.push(<ButtonGroup {...this.props} name="Email Reports" icon={'far fa-envelope-open-text text-left'} active={this.props.current_report_tab == 'Email Reports'} />);
            buttons.push(<ButtonGroup {...this.props} name="Working Hours" icon={'fal fa-clock text-left'} active={this.props.current_report_tab == 'Working Hours'} />);
            buttons.push(<ButtonGroup {...this.props} name="Edit Work Flows" icon={'fal fa-draw-circle  text-left'} active={this.props.current_report_tab == 'Edit Work Flows'} />);
        } else if (this.props.lookupPermission('page_dashboard')) {
            buttons.push(<ButtonGroup {...this.props} name="Department Reports" icon={'fal fa-analytics text-left'} active={this.props.current_report_tab == 'Department Reports'} />);
            buttons.push(<ButtonGroup {...this.props} name="Time Dashboard" icon={'fa fa-ballot-check text-left'} active={this.props.current_report_tab == 'Time Dashboard'} />);
            buttons.push(<ButtonGroup {...this.props} name="Goals" icon={'fal fa-stopwatch text-left'} active={this.props.current_report_tab == 'Goals'} />);
            buttons.push(<ButtonGroup {...this.props} name="Email Reports" icon={'far fa-envelope-open-text text-left'} active={this.props.current_report_tab == 'Email Reports'} />);
            buttons.push(<ButtonGroup {...this.props} name="Working Hours" icon={'fal fa-clock text-left'} active={this.props.current_report_tab == 'Working Hours'} />);
       }


		reguser = <div className="container text-center position-sticky">
			<div className="btn-group text-left" role="group">



				{/* <ButtonGroup {...this.props} name="Live Averages" icon={'fal fa-analytics text-left'} active={this.props.current_report_tab == 'Live Averages'} />

				<ButtonGroup {...this.props} name="Vehicle Over-Due Report" icon={'fal fa-analytics text-left'} active={this.props.current_report_tab == 'Vehicle Over-Due Report'} />

				<ButtonGroup {...this.props} name="Parts Over-Due Report" icon={'fal fa-analytics text-left'} active={this.props.current_report_tab == 'Parts Over-Due Report'} />

				<ButtonGroup {...this.props} name="WorkFlow Reports" icon={'fal fa-analytics text-left'} active={this.props.current_report_tab == 'WorkFlow Reports'} />

                <ButtonGroup {...this.props} name="Edit Work Flows" icon={'fal fa-draw-circle  text-left'} active={this.props.current_report_tab == 'Edit Work Flows'} />
                 */}






			</div>

		</div>;


		return (
			<div className="row">

				<div className="container text-center position-sticky">
			<div className="btn-group text-left" role="group">
                        {buttons}
                        </div>
                        </div>

			{(() => {
						switch (this.props.current_report_tab) {
						case 'Monthly Averages':
							return <ReportDashboard />;;

						case 'Step Averages':
							return <ReportDashboard />;;

						case 'Overdue Vehicles':
							return;

						case 'Parts Overdue':
							return;

						case 'Live Averages':
							return <InRecon />;

						case 'Reports':
							return <ReportDashboard />;

						case 'Department Reports':
							return <ReportDashboard />;

						case 'WorkFlow Reports':
							return <WorkFlowDataReport />;

						case 'Edit Work Flows':
							return <ReportDefaults />;

						case 'Goals':
							return <Goals />;

						case 'Time Dashboard':
							return <TimeDashboardSettings />;

						case 'Email Reports':
							return <EmailReports />;

						case 'Working Hours':
							return <WorkingHours />;

						default:
							return '';
						}
					})()}
			</div>
		);
	}
}

const mappedActions = {
	setCurrentReportTab,
	syncReportData,
	setReportInfo,
	setReportFilterInfo,
	getReportDepartments,
	lookupPermission,
};

function mapStateToProps(state) {
	return {
		report_department_data: state.settings_reports.report_department_data,
		work_flows: state.settings_departments.work_flows,
		current_report_tab: state.settings_reports.current_report_tab,
        current_user: state.current_user,
	};
}
export default connect(mapStateToProps, mappedActions)(Reports);
