import React, { Component, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import {
 Box, Button, createTheme, ThemeProvider, useTheme,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import {
  getReportVehicles,
} from '../../actions/apiGetInfo';
import { convertSecondsToTime, getSecondsFromTimeRange_24_5, noteStamp } from '../../utils/dateFunctions';
import DepartmentListItemContainer from './DepartmentListItemContainer';
import TabEditDepartmentColors from './TabEditDepartmentColors';

const columns = [
  {
    accessorKey: 'name',
    header: 'name',
    size: 120,
  },
  {
  	accessorKey: 'display_name',
    header: 'Display Name',
  },
  {
    accessorKey: 'order',
    header: 'Order',
  },
  {
    accessorKey: 'goal',
    header: 'Goal',
  },
  {
    accessorKey: 'department_type',
    header: 'Department Type',
  },
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const ExportCSV = (props) => {
  const globalTheme = useTheme();
  const [expandedRow, setExpandedRow] = useState(null);

  const tableTheme = useMemo(
    () => createTheme({
        palette: {
          mode: 'light', // let's use the same dark/light mode as the global theme
        },
      }),
    [globalTheme],
  );

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const newdata = [];

  props.departments.map((department) => {
          newdata.push({
            name: [department.name],
            display_name: [department.display_name],
            name: [department.name],
            display_name: [department.display_name],
            order: [department.order],
            goal: [department.goal],
            department_type: [department.department_type],
            subgroup: [department.subgroup],
            subdomain_department: [department.subdomain_department],
            subdomain: [department.subdomain],
            department_id: [department.id],
            department_dealerships: [department.department_dealerships],
            dealerships: [department.dealerships],
            department_filters: [department.department_filters],
            department_properties: [department.department_properties],
            background_color: [department.background_color],
            badge_background_color: [department.badge_background_color],
            badge_text_color: [department.badge_text_color],
            text_color: [department.text_color],
            hours_profile_id: [department.hours_profile_id],
            is_hours: [department.is_hours],

        });
  });
  const [data, setData] = useState(() => newdata);

  return (
    <ThemeProvider theme={tableTheme}>
    <MaterialReactTable
      columns={columns}
      data={data}
      enablePagination={false}
      enableRowOrdering
	  editable={{
	  	onRowAdd: (newrow) => new Promise((resolve, reject) => {
	  		setData([...data, newRow]);
	  	}),
	  }}
    muiRowDragHandleProps={{
    onDragEnd: (event, data) => {
    },
  }}
      initialState={{ showColumnFilters: true }}
      detailPanel={[{
        open: rowData => rowData.tableData.id === expandedRow,
        render: rowData => {
          if (rowData.tableData.id !== expandedRow) {
            setExpandedRow(null);
          }
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <DepartmentListItemContainer department_id={rowData.original.department_id} />
            </Box>
          );
        },
        onClick: (event, rowData) => {
          setExpandedRow(
            expandedRow === rowData.tableData.id ? null : rowData.tableData.id,
          );
        },
      }]}

    /></ThemeProvider>
  );
};

const mappedActions = {
  getReportVehicles,
  convertSecondsToTime,
  getSecondsFromTimeRange_24_5,
  noteStamp,
};

function mapStateToProps(state) {
  return {
    departments: state.settings_departments.original_departments,
  };
}

export default connect(mapStateToProps, mappedActions)(ExportCSV);
