import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SketchPicker, SwatchesPicker } from 'react-color';

class EditTabProperties extends Component {
	constructor(props) {
		super(props);

		this.state = { value: this.props.value || '', checked: this.props.checked || '' };
		this.onChange = this.onChange.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.handleChangeComplete = this.handleChangeComplete.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	onBlur(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'editTabSetting';
			const id = self.props.dataid;
			const data = {
				id,
				[self.props.name]: e.target.value,
			};

			self.setState({ value: e.target.value });

			self.props.updateTabProperty(id, data, errorType);
		}
	}

	onChangeChecked(e) {
		const self = this;
		const errorType = 'editTabProperty';
		const data = {
			id: this.props.dataid,
			active: e.target.checked,
		};
		self.setState({ checked: e.target.checked });

		self.props.updateTabProperty(self.props.dataid, data, errorType);
	}

	onChangeSelect(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'editTabProperty';
			const id = self.props.dataid;
			const data = {
				id,
				[self.props.name]: e.value,
			};

			self.setState({ value: e.value });

			self.props.updateTabProperty(id, data, errorType);
		}
	}

	selectData() {
		const options = this.props.vehicle_columns.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});
		return options;
	}

	handleChangeComplete(e) {
		this.setState({ value: e.hex });

		const self = this;
		const errorType = 'editColorPicker';

		const data = {
			id: self.props.dataid,
			[self.props.name]: e.hex,
		};

		self.props.updateTabProperty(self.props.dataid, data, errorType);
	}

	selectComparison() {
		const comparisonArray = [{ name: 'Greater Than', label: 'Greater Than' },
			{ name: 'Greater or Equal', label: 'Greater or Equal' }, { name: 'Equals', label: 'Equals' },
			{ name: 'Less Than', label: 'Less Than' }, { name: 'Less or Equal', label: 'Less or Equal' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card departmentCard">
					<div className="card-body row">
						<div className="col-md-12">
							<div className={classnames('form-group', { 'has-danger': this.props.error })}>
								{(() => {
									switch (this.props.type) {
									case 'switch':
										return <span className="switch">
											<input
												onChange={this.onChangeChecked}
												type={'checkbox'}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`${this.props.name}_permission`}
												checked={this.state.checked ? 'checked' : ''}
												className={classnames('text-center', { 'form-control-danger': this.props.error })}
												id={`switch_id_${this.props.name}`}
											/>
											<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}`}>{this.props.label.replace(/_/g, ' ')}</label>
										</span>;

									case 'switch-edit':
										return <div>
											<span className="switch">
												<input
													onChange={this.onChangeChecked}
													type={'checkbox'}
													name={this.props.name}
													data-id={this.props.dataid}
													key={`${this.props.name}_permission`}
													checked={this.state.checked ? 'checked' : ''}
													className={classnames('text-center', { 'form-control-danger': this.props.error })}
													id={`switch_id_${this.props.name}`}
												/>
												<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}`}>{this.props.label.replace(/_/g, ' ')}</label>
											</span>
											{this.state.checked ? <button className={'btn btn-success btn-sm float-right'} onClick={this.onEditClick}>
												<i className="fa fa-edit" />
												{' '}
Settings
											</button> : ''}
										</div>;

									case 'switch-nolabel':
										return <span className="switch">
											<input
												onChange={this.onChangeChecked}
												type={'checkbox'}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`${this.props.name}_permission`}
												checked={this.state.checked ? 'checked' : ''}
												className={classnames('text-center', { 'form-control-danger': this.props.error })}
												id={`switch_id_${this.props.name}`}
											/>
											<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}`} />
										</span>;

									case 'text':
										return <input
											onChange={this.onChange}
											onBlur={this.onBlur}
											value={this.state.value}
											type={this.props.type}
											name={this.props.name}
											data-id={this.props.dataid}
											key={`input${this.props.name}`}
											className={classnames('form-control ', { 'form-control-danger': this.props.error })}
										/>;

									case 'number-prepend':
										return <div className="input-group mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text" id="basic-addon3">Tab Order:</span>
											</div>
											<input
												onChange={this.onChange}
												onBlur={this.onBlur}
												value={this.state.value}
												type={'number'}
												name={this.props.name}
												data-id={this.props.dataid}
												key={`input${this.props.name}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
												aria-describedby="basic-addon3"
											/>
										</div>;

									case 'text-prepend':
										return <div className="input-group mb-3">
											<div className="input-group-prepend">
												<span className="input-group-text" id="basic-addon3">{this.props.label}</span>
											</div>
											<input
												onChange={this.onChange}
												onBlur={this.onBlur}
												value={this.state.value}
												type={'text'}
												name={this.props.name}
												data-id={this.props.dataid}
												key={`input${this.props.name}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
												aria-describedby="basic-addon3"
											/>
										</div>;

									case 'checkbox':
										return <span className="switch">
											<input
												onChange={this.onChangeChecked}
												type={'checkbox'}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`input${this.props.name}`}
												checked={this.state.checked ? 'checked' : ''}
												className={classnames('text-center', { 'form-control-danger': this.props.error })}
												id={`switch_id_${this.props.name}`}
											/>
											<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}`}>{this.props.label.replace(/_/g, ' ')}</label>
										</span>;

									case 'colorpicker':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<SwatchesPicker
												color={this.state.value}
												onChangeComplete={this.handleChangeComplete}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`input${this.props.name}`}
												width={'100%'}
											/>
										</div>;

									case 'select-comparison':
										return <Select
											onChange={this.onChangeSelect}
											value={this.selectComparison().filter(({ value }) => value === this.state.value)}
											name={this.props.name}
											options={this.selectComparison()}
											className="text-capitalize"
											data-id={this.props.dataid}
											clearable={false}
											searchable={false}
											key={`input${this.props.name}`}
											menuContainerStyle={{ zIndex: 5 }}
										/>;

									case 'select-data':
										return <Select
											onChange={this.onChangeSelect}
											value={this.selectData().filter(({ value }) => value === this.state.value)}
											name={this.props.name}
											options={this.selectData()}
											className="text-capitalize"
											data-id={this.props.dataid}
											clearable={false}
											searchable
											key={`input${this.props.name}`}
											menuContainerStyle={{ zIndex: 5 }}
										/>;

									default:
										return <input
											onChange={this.onChange}
											value={this.state.value}
											type={this.props.type}
											name={this.props.field}
											className={classnames('form-control', { 'form-control-danger': this.props.error })}
										/>;
									}
								})()}

							</div>
							{this.props.description ? <div className="col-md-12">
								<small>
									<i className={'fa fa-info-circle text-info'} />
									{' '}
									{this.props.description}
								</small>
							</div> : ''}
						</div>

					</div>

				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		vehicle_columns: state.settings_departments.vehicle_columns,
		departments: state.settings_departments.departments,
	};
}
export default connect(mapStateToProps)(EditTabProperties);
