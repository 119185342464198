import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	toggleSiteMap,
	toggleExpandAllPlus,
	toggleHideBlank,
	toggleSortDescending,
	toggleWorkFlow,
	toggleSidebar,
} from '../actions/toggleMenu';
import {
	getCurrentDepartment,
} from '../actions/apiGetInfo';

class SidebarListButton extends PureComponent {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	dealerSettingValue(name) {
		// TODO: this must be moved to some shared utils
		// as it used through whole project
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onClick() {
		const self = this;

		switch (self.props.name) {
		case 'menu-sitemap':
			self.props.toggleSiteMap();
			break;
		case 'menu-expandall':
			self.props.toggleExpandAllPlus();
			break;
		case 'menu-hideblank':
			self.props.toggleHideBlank();
			break;
		case 'menu-descending':
			self.props.toggleSortDescending();
			break;
		case 'menu-workflow':
			self.props.toggleWorkFlow(self.props.workflow);
			break;
		case 'menu-togglesidebar':
			self.props.toggleSidebar();
			break;
		default:
			break;
		}

		this.props.getCurrentDepartment(self.props.current_department.id);
	}

	render() {
		let btnStyle = 'info';
		let isCurrentWorkflow = false;
		let applyCustomizations = false;
		switch (this.props.name) {
		case 'menu-sitemap':
			applyCustomizations = true;
			btnStyle = 'light';
			break;
		case 'menu-expandall':
			btnStyle = 'light';
			break;
		case 'menu-hideblank':
			applyCustomizations = true;
			btnStyle = 'light';
			break;
		case 'menu-descending':
			btnStyle = 'light';
			break;
		case 'menu-togglesidebar':
			btnStyle = 'light';
			break;
		case 'menu-workflow':
			applyCustomizations = true;
			if (this.props.workflow == this.props.work_flow) {
				btnStyle = 'success';
				isCurrentWorkflow = true;
			} else {
				btnStyle = 'light';
			}
			break;
		default:
			break;
		}

		let sidebarStyle = {};
		if (applyCustomizations && !isCurrentWorkflow) {
			sidebarStyle = {
				background: this.dealerSettingValue('color_sidebar') || undefined,
				color: this.dealerSettingValue('color_sidebar_text') || undefined,
			};
		}

		return (
			<button style={sidebarStyle} title={this.props.title} className={`btn btn-${btnStyle} btn-sm`} onClick={this.onClick}>
				{this.props.text}
				{' '}
				{this.props.workflow ? '' : <i className={this.props.icon} />}
			</button>
		);
	}
}

const mappedActions = {
	toggleExpandAllPlus,
	toggleHideBlank,
	toggleSiteMap,
	toggleSortDescending,
	toggleWorkFlow,
	getCurrentDepartment,
	toggleSidebar,
};

function mapStateToProps(state) {
	return {
		site_map: state.settings_nav.menus.site_map,
		expand_all: state.settings_nav.menus.expand_all,
		hide_blank: state.settings_nav.menus.hide_blank,
		work_flow: state.settings_nav.menus.work_flow,
		dealer_settings: state.settings_site.dealer_settings,
		current_department: state.settings_departments.department,
	};
}
export default connect(mapStateToProps, mappedActions)(SidebarListButton);
