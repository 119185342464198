import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
	lookupSupportTicketById, lookupUserById, lookupDealershipById, lookupSupportTicketsByUserId, lookupUserNameById,
} from '../../actions/lookups';
import {
	readableStamp,
} from '../../utils/dateFunctions';
import DeleteSupportTicketButton from './DeleteSupportTicketButton';
import AddSupportTicket from './AddSupportTicket';
import SupportMenu from './SupportMenu';

class SupportTickets extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: [],
		};
	}

	handleRowClick(rowId) {
		const currentExpandedRows = this.state.expandedRows;
		const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

		const newExpandedRows = isRowCurrentlyExpanded
			? currentExpandedRows.filter(department => department !== rowId)
			: currentExpandedRows.concat(rowId);

		this.setState({ expandedRows: newExpandedRows });
	}

	onClick(rowId) {
	}

	openSingle() {

	}

	renderItem(item) {
		const clickCallback = () => this.handleRowClick(item.id);
		const onClick = () => this.onClick(item.id);
		const itemRows = [
			<tr key={`row-data-${item.id}`}>
			    <td><button className="btn btn-sm btn-success" onClick={clickCallback}><icon className="fal fa-arrow-right" /></button></td>
			    <td>{item.id}</td>
			    <td>{item.status}</td>
			    <td>{item.initial_issue}</td>
				<td>{item.created_at}</td>
				<td>{readableStamp(item.updated_at)}</td>
				<td>{this.props.lookupUserNameById(item.user_id)}</td>
				<td>{item.installation_id}</td>
				<td><DeleteSupportTicketButton id={item.id} /></td>
			</tr>,
		];

		if (this.state.expandedRows.includes(item.id)) {
			itemRows.push(
				<tr key={`row-expanded-${item.id}`}>
					<td colSpan="12">
						<table className="table">
							<thead className={'thead-light'}>
								<tr className="bg-dark text-light">
									<th><label>Status</label></th>
									<td>
										{' '}
										{item.status}
									</td>
								</tr>
								<tr className="bg-dark text-light">
									<th style={{ width: '25%' }}>
										<label>Issue</label>
									</th>
									<td>{item.initial_issue}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Opened</label></th>
									<td>
										{' '}
										{item.created_at}
									</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>User</label></th>
									<td>{item.user_id}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Group Name</label></th>
									<td>{item.group_name}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Agent</label></th>
									<td>{item.agent_id}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Agent Email</label></th>
									<td>{item.agent_email}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Last Updated</label></th>
									<td>{item.last_updated}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Last Updated By</label></th>
									<td>{item.last_updated_by}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Priority</label></th>
									<td>{item.priority}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Vehicle</label></th>
									<td>{item.vehicle_id}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Include Emails</label></th>
									<td>{item.include_emails}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Keywords</label></th>
									<td>{item.keywords}</td>
								</tr>
								<tr className="bg-dark text-light">
									<th><label>Dealership</label></th>
									<td>{item.installation_id}</td>
								</tr>
							</thead>
						</table>
					</td>
				</tr>,
			);
		}

		return itemRows;
	}

	render() {
		const self = this;

		const support_tickets = _.filter(this.props.support_tickets, o => o.user_id === this.props.current_user.user) || [];
		const tableData = [];
		const data = support_tickets.map((support_ticket, index) => {
			const obj = {
				id: support_ticket.id,
				user_id: support_ticket.user_id,
				installation_id: this.props.lookupDealershipById(support_ticket.installation_id).subdomain,
				status: support_ticket.status,
				group_name: support_ticket.group_name,
				agent_id: support_ticket.agent_id,
				priority: support_ticket.priority,
				include_emails: support_ticket.include_emails,
				agent_email: support_ticket.agent_email,
				display_all: support_ticket.display_all,
				last_updated: support_ticket.last_updated,
				last_updated_by: support_ticket.last_updated_by,
				created_at: readableStamp(support_ticket.created_at),
				updated_at: support_ticket.updated_at,
				vehicle_id: support_ticket.vehicle_id,
				initial_issue: support_ticket.initial_issue,
				keywords: support_ticket.keywords,
			};
			return obj;
		});

		tableData.push(data);

		let allItemRows = [];

		data.forEach((item) => {
			if (item) {
				const perItemRows = this.renderItem(item);
				allItemRows = allItemRows.concat(perItemRows);
			}
		});

		return (
			<div className="col-md-12">
				<div className="card space-top-0">

					<h3 className="text-center pad-top-1">Support Tickets</h3>
					<p className="text-center">Please create a ticket with any information you can provide to help us resolve your issue.</p>
					<p className="text-center"><AddSupportTicket /></p>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">
						<SupportMenu />
						<table className="table table-striped w100 pad-top-1">
							<thead>
								<tr>
									<th />
									<th>Ticket ID</th>
									<th>Status</th>
									<th>Issue</th>
									<th>Created</th>
									<th>Last Updated</th>
									<th>UserName</th>
									<th>Dealership</th>
									<th />
								</tr>
							</thead>
							<tbody>
							{allItemRows}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupSupportTicketById,
	lookupSupportTicketsByUserId,
	lookupUserById,
	lookupDealershipById,
	readableStamp,
	lookupUserNameById,
};

function mapStateToProps(state) {
	return {
		softname: state.settings_dealerships.installation.softname || '',
		support_tickets: state.settings_support_tickets.support_tickets || [],
		rooms: state.settings_support_tickets.rooms || [],
		room_messages: state.settings_support_tickets.room_messages || [],
		current_user: state.current_user,
	};
}
export default connect(mapStateToProps, mappedActions)(SupportTickets);
