import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import DeleteRoomButton from './DeleteRoomButton';
import { lookupVehicleById } from '../../actions/lookups';
import Room from './Room';

class RoomTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: [],
		};
	}

	handleRowClick(rowId) {
		const currentExpandedRows = this.state.expandedRows;
		const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

		const newExpandedRows = isRowCurrentlyExpanded
			? currentExpandedRows.filter(department => department !== rowId)
			: currentExpandedRows.concat(rowId);

		this.setState({ expandedRows: newExpandedRows });
	}

	onClick(rowId) {
	}

	getData(room_messages) {
		const data = room_messages.map((room_message) => {
			const obj = {
				id: room_message.id,
			};
			return obj;
		});

		return data;
	}

	openSingle() {

	}

	renderItem(item) {
		const clickCallback = () => this.handleRowClick(item.id);
		const onClick = () => this.onClick(item.id);
		const itemRows = [
			<tr key={`row-data-${item.id}`}>
			    <td><button className="btn btn-sm btn-info" onClick={clickCallback}><i className="fal fa-comments" /></button></td>
			    <td>{item.name}</td>
				<td><DeleteRoomButton {...this.props} room_id={item.id} /></td>
			</tr>,
		];

		if (this.state.expandedRows.includes(item.id)) {
			itemRows.push(
				<tr key={`row-expanded-${item.id}`}>
					<td colSpan="3">
						<table className="table w100 bg-white">
							<tbody>
								<tr key={`row-vehicle_sub-${item.id}`}>
									<td><Room {...this.props} room_id={item.id} room={item} /></td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>,
			);
		}

		return itemRows;
	}

	render() {
		const self = this;
		const rooms = self.props.rooms;

		const tableData = [];
		const data = self.props.rooms.map((room, index) => {
			const obj = {
				id: room.id,
				name: room.name,
				room_type: room.room_type,
				status: room.status,
				room_messages: room.room_messages,
			};
			return obj;
		});

		tableData.push(data);

		let allItemRows = [];

		data.forEach((item) => {
			if (item) {
				const perItemRows = this.renderItem(item);
				allItemRows = allItemRows.concat(perItemRows);
			}
		});

		return (
			<table className="table w100">
				<thead>
					<tr>
						<th />
						<th />
						<th />
					</tr>
				</thead>
				<tbody>
				{allItemRows}
				</tbody>
			</table>
		);
	}
}

const mappedActions = {
	lookupVehicleById,
};

function mapStateToProps(state) {
	return {
		rooms: state.settings_support_tickets.rooms,
	};
}
export default connect(mapStateToProps, mappedActions)(RoomTable);
