import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';
import Select from '../../../../inputs/Dropdown';
import FileUploadButton from '../../../../inputs/FileUploadButton';
import {
	getDateTime,
	userStamp,
} from '../../../../utils/dateFunctions';

import {
	deleteWorkItem,
	updateUviItem,
	updateUviItemRaw,
} from '../../../../actions/apiWorkItems';
import {
	sendEstimate,
} from '../../../../actions/apiEstimates';
import {
	activeVehicle,
} from '../../../../actions/lookups';

class UviCustomItem extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			addDeleteEstimateError: '',
			vehicle_id: this.props.active_vehicle.id,
			description: this.props.work_item.description,
			part_number: this.props.work_item.part_number,
			qty: this.props.work_item.qty,
			labor: this.props.work_item.labor || 0,
			parts: this.props.work_item.parts || 0,
			labor_hours: this.props.work_item.labor_hours || 0,
			total: this.props.work_item.total,
			active: this.props.work_item.active,
		};
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onDecline = this.onDecline.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.tireOptions = this.tireOptions.bind(this);
		this.updateDecline = this.updateDecline.bind(this);
	}

	componentDidMount() {
		if (this.state.active === false) {
			this.updateDecline();
		}
	}

	onChangeTextArea(event) {
		this.setState({ value: event.target.value });
	}

	onChangeSelect(e, part_number, qty, parts, labor_hours, labor, total) {
		const self = this;
		const errorType = 'updateUviItemError';
		const { id } = self.props.work_item;

		const data = {
			id,
			vehicle_id: this.props.active_vehicle.id,
			description: e,
			part_number,
			qty,
			parts,
			labor_hours,
			labor,
			total,
		};

		this.props.updateUviItem(id, data, errorType);
	}

	vehicleProperty(name) {
		const self = this;

		const value = _.filter(self.props.vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onBlur(e) {
		const self = this;
		const errorType = 'updateUviItemError';
		const { id } = self.props.work_item;
		const { value } = e.target;

		const total = parseFloat(this.state.labor) + parseFloat(this.state.parts);
		this.setState({ total });

		const data = {
			id,
			vehicle_id: this.props.active_vehicle.id,
			[e.target.name]: value,
			total,
		};

		this.props.updateUviItemRaw(id, data, errorType);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onDelete(e) {
		Promise.all([this.props.deleteWorkItem(this.props.work_item.id, 'addDeleteEstimateError')]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	onDecline(e) {
		const item_id = this.props.work_item.id;
		document.getElementById(`work_item_description_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_labor_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_labor_hours_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_parts_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_total_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_qty_${item_id}`).setAttribute('data-total', '');

		document.getElementById(`work_item_description_${item_id}`).innerHTML = this.state.description;
		document.getElementById(`work_item_labor_${item_id}`).innerHTML = this.state.labor;
		document.getElementById(`work_item_labor_hours_${item_id}`).innerHTML = this.state.labor_hours;
		document.getElementById(`work_item_parts_${item_id}`).innerHTML = this.state.parts;
		document.getElementById(`work_item_total_${item_id}`).innerHTML = this.state.total;
		document.getElementById(`work_item_qty_${item_id}`).innerHTML = this.state.qty;

		const self = this;
		const errorType = 'updateUviItemError';
		const { id } = self.props.work_item;

		const data = {
			id,
			vehicle_id: this.props.active_vehicle.id,
			active: false,
		};
		this.setState({ active: false });
		this.props.updateUviItem(id, data, errorType);
	}

	updateDecline(e) {
		const item_id = this.props.work_item.id;
		document.getElementById(`work_item_description_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_labor_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_labor_hours_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_parts_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_total_${item_id}`).setAttribute('data-total', '');
		document.getElementById(`work_item_qty_${item_id}`).setAttribute('data-total', '');

		document.getElementById(`work_item_description_${item_id}`).innerHTML = this.state.description;
		document.getElementById(`work_item_labor_${item_id}`).innerHTML = this.state.labor;
		document.getElementById(`work_item_labor_hours_${item_id}`).innerHTML = this.state.labor_hours;
		document.getElementById(`work_item_parts_${item_id}`).innerHTML = this.state.parts;
		document.getElementById(`work_item_total_${item_id}`).innerHTML = this.state.total;
		document.getElementById(`work_item_qty_${item_id}`).innerHTML = this.state.qty;
	}

	tireOptions() {
		const select_options = [
			{
				name: 'Front Brakes', part_number: '', id: 1, qty: 1, parts: '67.71', labor_hours: '1.5', labor: '137.29', total: '205.00', item_type: 'set-locked',
			},
			{
				name: 'Rear Brakes', part_number: '', id: 2, qty: 1, parts: '75.14', labor_hours: '1.5', labor: '129.86', total: '205.00', item_type: 'set-locked',
			},
			{
				name: 'Rear Brakes - Super Duty', part_number: '', id: 3, qty: 1, parts: '75.14', labor_hours: '3', labor: '275.86', total: '351.00', item_type: 'set-locked',
			},
			{
				name: 'Rotate balance', part_number: '', id: 4, qty: 1, parts: '', labor_hours: '.8', labor: '56.95', total: '56.95', item_type: 'set-locked',
			},
			{
				name: 'Mount balance', part_number: '', id: 5, qty: 1, parts: '', labor_hours: '1.3', labor: '87.50', total: '87.50', item_type: 'set-locked',
			},
			{
				name: 'Alignment', part_number: '99', id: 6, qty: 1, parts: '', labor_hours: '1.2', labor: '99.00', total: '99.00', item_type: 'set-locked',
			},
			{
				name: 'Choose Item', part_number: '', id: 7, qty: 0, parts: 0, labor_hours: 0, labor: 0, total: 0, item_type: 'none',
			}];

		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.props.work_item.name === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected, parts: option.parts, qty: option.qty, labor_hours: option.labor_hours, labor: option.labor, total: option.total, part_number: option.part_number,
			};
			return obj;
		});
		return options || [];
	}

	subletOptions() {
		const select_options = [
			{
				name: 'PDR', part_number: 'Sublet Item', id: 1, qty: 1, parts: '', labor_hours: '', labor: '', total: '', item_type: 'sublet',
			},
			{
				name: 'Touchup', part_number: 'Sublet Item', id: 2, qty: 1, parts: '', labor_hours: '', labor: '', total: '', item_type: 'sublet',
			},
			{
				name: 'Windshield', part_number: 'Sublet Item', id: 3, qty: 1, parts: '', labor_hours: '', labor: '', total: '', item_type: 'sublet',
			},
			{
				name: 'Bodywork (bumpers, panels, etc.)', part_number: 'Sublet Item', id: 4, qty: 1, parts: '', labor_hours: '', labor: '', total: '', item_type: 'sublet',
			}];

		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.props.work_item.name === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected, parts: option.parts, qty: option.qty, labor_hours: option.labor_hours, labor: option.labor, total: option.total, part_number: option.part_number,
			};
			return obj;
		});
		return options || [];
	}

	render() {
		self = this;
		const vehicle = this.props.active_vehicle;
		var setdisabledService = this.props.setdisabled;

		const { work_item } = self.props;
		const item_id = work_item.id;

		return (
			<tr className={this.state.active ? 'text-center' : 'text-center strikeout'} id={`work_item_row_${item_id}`}>
				<td className="text-left" id={`work_item_description_${item_id}`}>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						type={'text'}
						name={'description'}
						data-id={this.props.work_item.id}
						value={this.state.description || ''}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
				</td>
				<td id={`work_item_part_number_${item_id}`}>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						type={'text'}
						name={'part_number'}
						data-id={this.props.work_item.id}
						value={this.state.part_number || ''}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
				</td>
				<td id={`work_item_qty_${item_id}`}>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						type={'number'}
						name={'qty'}
						data-id={this.props.work_item.id}
						value={this.state.qty || ''}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
				</td>
				<td id={`work_item_parts_${item_id}`} className={`parts_${vehicle.id}`} data-total={this.state.parts}>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						type={'text'}
						name={'parts'}
						data-id={this.props.work_item.id}
						value={this.state.parts || ''}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
				</td>
				<td id={`work_item_labor_hours_${item_id}`} className={`labor_hours_${vehicle.id}`} data-total={this.state.labor_hours}>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						type={'text'}
						name={'labor_hours'}
						data-id={this.props.work_item.id}
						value={this.state.labor_hours || ''}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
				</td>
				<td id={`work_item_labor_${item_id}`} className={`labor_${vehicle.id}`} data-total={this.state.labor}>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						type={'text'}
						name={'labor'}
						value={this.state.labor || ''}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
				</td>
				<td id={`work_item_total_${item_id}`} className={`total_${vehicle.id}`} data-total={parseFloat(this.state.total || 0)}>{parseFloat(this.state.total || 0)}</td>
				<td>{this.state.active && !setdisabledService ? <button className="btn btn-sm btn-danger" onClick={self.onDecline}>Decline</button> : ''}</td>
				<td>{!setdisabledService ? <button className="btn btn-sm btn-danger" onClick={self.onDelete}>Delete</button> : ''}</td>
			</tr>
		);
	}
}

const mappedActions = {
	deleteWorkItem,
	updateUviItem,
	sendEstimate,
	updateUviItemRaw,
	activeVehicle,
};

function mapStateToProps(state) {
	return {
		all_tabs: state.settings_site.all_tabs,
		vehicle_columns: state.settings_departments.vehicle_columns,
		installation: state.settings_dealerships.installation,
		current_user: state.current_user,
		vehicle_filter: state.settings_nav.vehicle_filter,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(UviCustomItem);
