import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import AllInputs from '../../../../inputs/AllInputs';

class ReconTracker extends Component {
	constructor(props) {
		super(props);

		this.state = { addPartOrderError: '' };
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onPrint = this.onPrint.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
		};

		this.props.addPartOrder(data, 'addPartOrderError');
	}

	onPrint(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById(`recon_tracker_${vehicle.stock}`);
		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 0, 0);
				    pdf.save(`recon_tracker_${vehicle.stock}.pdf`);
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const errors = this.state;
		const arbitrationDeadline = _.find(vehicle.vehicle_properties, { key: 'arbitration deadline' }) || '';
		const safetyCheckCompletedBy = _.find(vehicle.vehicle_properties, { key: 'safety check completed by' }) || '';
		const waitingOnParts = _.find(vehicle.vehicle_properties, { key: 'waiting on parts' }) || '';
		const waitingOnPartsNotes = _.find(vehicle.vehicle_properties, { key: 'waiting on parts notes' }) || '';
		const clearedForDetail = _.find(vehicle.vehicle_properties, { key: 'cleared for detail' }) || '';
		const detailCompletedBy = _.find(vehicle.vehicle_properties, { key: 'detail completed by' }) || '';
		const qualityInspectedBy = _.find(vehicle.vehicle_properties, { key: 'quality inspected by' }) || '';
		const dateDetailCompleted = _.find(vehicle.vehicle_properties, { key: 'date detail completed' }) || '';
		const photosTaken = _.find(vehicle.vehicle_properties, { key: 'photos taken' }) || '';

		return (
			<div className="container">
				<button onClick={this.onPrint} className="btn btn-success float-right">Print</button>
				<div className="card" id={`recon_tracker_${vehicle.stock}`}>
					<div
className="card"
style={{
 padding: '10px', width: '800px', fontFamily: 'Verdana',
}}
					>
						<div className="card-text"><h3 style={{ textAlign: 'center' }}>Recon Tracking</h3></div>
						<div className="card-text">
							<div className="d-flex flex-wrap" style={{ border: '1px solid black', padding: '10px', width: '95%' }}>
								<table className="table table-striped table-bordered">
									<thead className={'thead text-left'}>
										<tr>
											<th className="text-right" style={{ width: '40%' }}>Stock #</th>
											<td className="text-left pad-left-2" style={{ fontSize: '22px' }}>{vehicle.stock}</td>
										</tr>
										<tr>
											<th className="text-right">Year / Make / Model:</th>
											<td className="text-left pad-left-2" style={{ fontSize: '22px' }}>
												{vehicle.year}
												{' '}
/
												{' '}
												{vehicle.make}
												{' '}
/
												{' '}
												{vehicle.model}
											</td>
										</tr>
										<tr>
											<th className="text-right">Vin #</th>
											<td className="text-left pad-left-2" style={{ fontSize: '22px' }}>{vehicle.vin}</td>
										</tr>
										<tr>
											<th className="text-right">Miles</th>
											<td className="text-left pad-left-2" style={{ fontSize: '22px' }}>{vehicle.miles.toLocaleString()}</td>
										</tr>
									</thead>
									<tbody />
								</table>
							</div>
							<div
								className="d-flex flex-wrap"
								style={{
									border: '1px solid black', padding: '10px', width: '95%', marginTop: '15px',
								}}
							>
								<table className="table table-striped table-bordered">
									<thead className={'thead text-center'}>
										<tr>
											<th className="text-right" style={{ width: '40%' }}>Arbitration Deadline (Sales Manager)</th>
											<td className="text-left pad-left-2" />
										</tr>
									</thead>
									<tbody />
								</table>
							</div>
							<div
								className="d-flex flex-wrap"
								style={{
									border: '1px solid black', padding: '10px', width: '95%', marginTop: '15px',
								}}
							>
								<table className="table table-striped table-bordered">
									<thead className={'thead text-center'}>
										<tr>
											<th className="text-right" style={{ width: '40%' }}>Person who Completed Safety Check:</th>
											<td colSpan={2} className="text-left pad-left-2" />
										</tr>
										<tr>
											<th className="text-right" style={{ width: '40%' }}>Waiting On Parts:</th>
											<td className="text-left pad-left-2">
												<AllInputs
													{...this.props}
													value={waitingOnParts.value}
													type={'userstamp'}
													name={'waiting on parts'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													user_name={waitingOnParts.user_name}
													updated_at={waitingOnParts.updated_at}
													vehicle={vehicle}
												/>
											</td>
											<td>
												<AllInputs
													{...this.props}
													value={waitingOnPartsNotes.value}
													type={'textarea'}
													name={'waiting on parts notes'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													user_name={waitingOnPartsNotes.user_name}
													updated_at={waitingOnPartsNotes.updated_at}
													vehicle={vehicle}
												/>
											</td>
										</tr>
										<tr>
											<th className="text-right" style={{ width: '40%' }}>Person who Completed Quality Inspection:</th>
											<td className="text-left pad-left-2" />
										</tr>
										<tr>
											<th className="text-right" style={{ width: '40%' }}>Date Cleared For Detail:</th>
											<td colSpan={2} className="text-left pad-left-2" />
										</tr>
									</thead>
									<tbody />
								</table>
							</div>
							<div
								className="d-flex flex-wrap"
								style={{
									border: '1px solid black', padding: '10px', width: '95%', marginTop: '15px',
								}}
							>
								<table className="table table-striped table-bordered">
									<thead className={'thead text-center'}>
										<tr>
											<th className="text-right" style={{ width: '40%' }}>Person who Completed Detail:</th>
											<td className="text-left pad-left-2" />
										</tr>
										<tr>
											<th className="text-right" style={{ width: '40%' }}>Date Detail Completed:</th>
											<td className="text-left pad-left-2" />
										</tr>
									</thead>
									<tbody />
								</table>
							</div>
						</div>
						<div className="card-footer" />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { active_vehicle: state.settings_refresh.active_vehicle };
}

export default connect(mapStateToProps)(ReconTracker);
