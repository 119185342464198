import _ from 'lodash';
import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const initialState = {
	edit_department: [],
	edit_departments: '',
	edit_department_properties: [],
	edit_vehicle_column: [],
	edit_tab: '',
	edit_user_locations: [],
	edit_department_current_tab: 'edit-department-settings',
	edit_locations: [],
	set_dealership_display_filters: [],
	set_dealership_display_options: {
		billing_contact: '', feed_contact: '', phone: '', website: '', default_timezone: true, street1: true, recon_chart: '', recon_summary: '',
	},
	current_tab: 'default-department',
	should_login: '',
};

export const settings_admin = createReducer(initialState, {
	[types.SET_EDIT_DEPARTMENT](state = initialState, action) {
		return {
			...state,
			edit_department: action.edit_department,
			edit_department_properties: _.sortBy(action.edit_department.department_properties, 'order'),
		};
	},
	[types.SET_EDIT_DEPARTMENTS](state = initialState, action) {
		return {
			...state,
			edit_departments: action.edit_departments,
		};
	},
	[types.SET_EDIT_DEPARTMENT_PROPERTIES](state = initialState, action) {
		return {
			...state,
			edit_department_properties: _.sortBy(action.edit_department_properties, 'order'),
		};
	},
	[types.SET_EDIT_VEHICLE_COLUMN](state = initialState, action) {
		return {
			...state,
			edit_vehicle_column: action.edit_vehicle_column,
		};
	},
	[types.SET_EDIT_LOCATIONS](state = initialState, action) {
		return {
			...state,
			edit_locations: action.edit_locations,
		};
	},
	[types.SET_EDIT_USER_LOCATIONS](state = initialState, action) {
		return {
			...state,
			edit_user_locations: action.edit_user_locations,
		};
	},
	[types.SET_EDIT_TAB](state = initialState, action) {
		return {
			...state,
			edit_tab: action.tab_name,
		};
	},
	[types.SET_DEALERSHIP_DISPLAY_OPTIONS](state = initialState, action) {
		return {
			...state,
			set_dealership_display_options: action.display_options,
		};
	},
	[types.SET_DEALERSHIP_DISPLAY_FILTERS](state = initialState, action) {
		return {
			...state,
			set_dealership_display_filters: action.display_filters,
		};
	},
	[types.SET_CURRENT_TAB](state = initialState, action) {
		return {
			...state,
			current_tab: action.container,
		};
	},
	[types.SET_EDIT_DEPARTMENT_CURRENT_TAB](state = initialState, action) {
		return {
			...state,
			edit_department_current_tab: action.container,
		};
	},
	[types.SET_SHOULD_LOGIN](state = initialState, action) {
		return {
			...state,
			should_login: action.status,
		};
	},
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			edit_department: [],
			edit_departments: '',
			edit_department_properties: [],
			edit_vehicle_column: [],
			edit_tab: '',
			edit_department_current_tab: 'edit-department-settings',
			edit_user_locations: [],
			edit_locations: [],
			set_dealership_display_filters: [],
			set_dealership_display_options: {
				billing_contact: '', feed_contact: '', phone: '', website: '', default_timezone: true, street1: true, recon_chart: '', recon_summary: '',
			},
			current_tab: 'default-department',
			should_login: '',
		};
	},
});

export default 'settings_admin';
