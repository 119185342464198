import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import InputTypes from '../../../../inputs/InputTypes';
import AllInputs from '../../../../inputs/AllInputs';
import {
	lookupDepartmentByName,
	vsearch,
	activeVehicle,
} from '../../../../actions/lookups';
import {
	setCustomModalTab,
} from '../../../../actions/apiSetInfo';
import {
	processDepartments,
	getCurrentDepartment,
} from '../../../../actions/apiGetInfo';
import {
	addVehicleProperty,
	updateVehicleDepartment,
} from '../../../../actions/AllUpdates';

class NysiReadyTab extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.state = {};
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const tab = _.find(this.props.all_tabs, { name: this.props.tab }) || [];
		const vehicle = this.props.vehicle || [];
		const vehicle_properties = this.props.vehicle_properties;
		const tab_properties = tab.tab_properties || [];
		const automax = '';
		const automax2 = '';
		const automax3 = '';
		const automax4 = '';
		const automax5 = '';
		const colObject = [];
		const self = this;
		var input = '';

		const vehicle_column = _.find(this.props.vehicle_columns, { name: 'nysi ready' }) || [];

					if (typeof vehicle_column !== 'undefined') {
						const colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						input = <AllInputs
							name={vehicle_column.name}
							label={vehicle_column.name}
							onChange={this.onChange}
							onBlur={this.onBlur}
							user_name={colObject.user_name}
							value={colObject.value || ''}
							type={vehicle_column.input_name}
							options={vehicle_column.options}
							checked={colObject.value || ''}
							parentid={this.props.vehicle_id}
							rowid={colObject.id || ''}
							uid={colObject.uid}
							updated_at={colObject.updated_at}
							updatetype="vehicleproperty"
							icon={vehicle_column.icon}
							userStamp={this.props.userStamp}
							setdisabled={false}
							vehicle={vehicle}
						/>;
		}

		return <div className="card text-center">
			<div className="card-text bg-light">
				<label className="form-control">NYSI Ready</label>{input}
			</div>
			<div className="card-footer" />
		</div>;
	}
}

const mappedActions = {
	lookupDepartmentByName,
	setCustomModalTab,
	addVehicleProperty,
	updateVehicleDepartment,
	processDepartments,
	vsearch,
};

function mapStateToProps(state) {
	return {
		vehicle: state.settings_refresh.active_vehicle,
		vehicle_properties: state.settings_refresh.active_vehicle.vehicle_properties,
		installation: state.settings_dealerships.installation,
		vehicle_columns: state.settings_departments.vehicle_columns,
		current_user: state.current_user,
		all_tabs: state.settings_site.all_tabs,
	};
}
export default connect(mapStateToProps, mappedActions)(NysiReadyTab);
