import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as lookups from './lookups';
import * as AllMutates from './AllMutates';

export function addAttachment(data, errorType) {
	return dispatch => Api.postData('attachments/', data).then(resp => dispatch(lookups.activeVehicle(data.vehicle_id))).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteAttachment(id, errorType) {
	return dispatch => Api.delete(`attachments/${id}`).then(() => 'success').catch(ex => Api.handleApiError(ex, errorType));
}
