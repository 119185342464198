import Api from '../api/reconApi.js';
import * as setInfo from './apiSetInfo';
import * as toggleMenu from './toggleMenu';
import * as AllUpdates from './AllUpdates';
import * as AllMutates from './AllMutates';
import * as lookups from './lookups';

export function getVinInfo(vin, errorType) {
	return dispatch => Api.getVinInfo(vin).then((resp) => {
		const vehicle = {
			vin, year: '', make: '', model: '', message: '',
		};

		resp.data.Results.map((item) => {
			if (item.Variable === 'Error Code') {
				vehicle.message = item.Value;
			}
			if (item.Variable === 'Make') {
				vehicle.make = item.Value;
			}
			if (item.Variable === 'Model') {
				vehicle.model = item.Value;
			}
			if (item.Variable === 'Model Year') {
				vehicle.year = item.Value;
			}
		});
		dispatch(setInfo.setVinDecodedVehicle(vehicle));
		dispatch(lookups.findVehicleVinScanner(vin.toLowerCase()));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function getVinInfo2(vin, errorType) {
	return dispatch => Api.getVinInfo2(vin).then((resp) => {
		const vehicle = {
			vin: vin.toUpperCase(), year: '', make: '', model: '', message: '',
		};

		vehicle.make = resp.data.make;
		vehicle.model = resp.data.model_name;
		vehicle.year = resp.data.model_year;
		dispatch(setInfo.setVinDecodedVehicle(vehicle));
		dispatch(lookups.findVehicleVinScanner(vin.toLowerCase()));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function sendVinScanner(data, errorType) {
	return dispatch => Api.sendVinScanner(data).then((resp) => {
		const { description } = resp.responses[0].textAnnotations[0];
		res = description.replace(/[|&;$%@"<>()+,]/g, ' ').toLowerCase();
		res = res.replace(/i/g, '1');
		res = res.replace(/:\s*,/g, ', ');
		res = res.replace(/o/g, '0');
		res = res.replace(/q/g, '0');
		res = res.replace(/\n/g, ' ');

		const results = res.split(' ');

		results.map((vin) => {
			if (vin.length === 17) {
				dispatch(toggleMenu.change_page('vin_scanner_results'));
				dispatch(setInfo.setVinScanner(vin));
				dispatch(getVinInfo(vin));
				return 'success';
			}
		});
		return 'no vin found';
	}).catch(ex => Api.handleApiError(ex, errorType));
}
