import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AddVehicle from './AddVehicle';
import {
	lookupPermission,
	lookupIsAdmin,
	vsearch,
} from '../../actions/lookups';
import {
	setIncludeSold,
} from '../../actions/apiSetInfo';

class SearchBar extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			search: '', errors: {}, isLoading: false, include_sold: this.props.include_sold,
		};
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		if (this.props.installation.database === 'marhofer') {
			if (e.target.value.length >= 7) {
				this.props.vsearch(e.target.value.toLowerCase(), this.state.include_sold);
			}
		} else if (e.target.value.length >= 5) {
			this.props.vsearch(e.target.value.toLowerCase(), this.state.include_sold);
		}
	}

	onClick(e) {
		this.setState({ include_sold: !this.state.include_sold });
		this.props.setIncludeSold(!this.state.include_sold);
	}

	onBlur(e) {
		if (e.target.value.length >= 4) {
			this.props.vsearch(e.target.value.toLowerCase(), this.state.include_sold);
		}
	}

	onKeyDown(e) {
		if (e.key === 'Enter' && e.target.value.length >= 3) {
			this.props.vsearch(e.target.value.toLowerCase(), this.state.include_sold);
		}
	}

	onFocus(e) {
		this.setState({ [e.target.name]: '' });
	}

	render() {
		const { errors } = this.state;
		let vehicle_create = '';
		let advanced_search = false;
		const include_sold = [];

		include_sold.push(<div key="search-sold" title={'Includes Sold Vehicles In Search'} className={this.state.include_sold ? 'input-group-append success' : 'input-group-append info'} onClick={this.onClick}>
			<span className={this.state.include_sold ? 'input-group-text success input-sm' : 'input-group-text default strike'}>Include Sold</span>
		</div>);

		if (this.props.lookupPermission('vehicle_create') || this.props.lookupIsAdmin()) {
			vehicle_create = <AddVehicle key="create-add-vehicle" />;
		}

		if (this.props.lookupPermission('advanced_search') || this.props.lookupIsAdmin()) {
			advanced_search = <div className="input-group" key="search-group">
				<input type="text" onKeyDown={this.onKeyDown} className="form-control search-input input-sm" placeholder="Search Stock, Vin, Make, Model" onFocus={this.onFocus} onChange={this.onChange} name="search" value={this.state.search} />
				{include_sold}
				{vehicle_create}
                     </div>;
		}

		return (
			<div className="input-group pad-left-1">
				{advanced_search || vehicle_create}
			</div>
		);
	}
}

const mappedActions = {
	lookupPermission,
	lookupIsAdmin,
	vsearch,
	setIncludeSold,
};

function mapStateToProps(state) {
	return {
		include_sold: state.settings_nav.include_sold,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps, mappedActions)(SearchBar);
