import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
	content: {
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
	},
};

class TabIcon extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const self = this;
		const { tab } = self.props;

		const defaultStyle = {
			background: 'black',
			backgroundColor: 'black',
			color: '#FFF',
			borderRadius: '.4em',
			padding: '1px',
		};

		const pendingStyle = {
			background: tab.icon_pending_bg_color,
			backgroundColor: tab.icon_pending_bg_color,
			color: tab.icon_pending_font_color,
			borderRadius: '.4em',
			padding: '1px',
		};

		const successStyle = {
			background: tab.icon_success_bg_color,
			backgroundColor: tab.icon_success_bg_color,
			color: tab.icon_success_font_color,
			borderRadius: '.4em',
			padding: '1px',
		};

		const warningStyle = {
			background: tab.icon_warning_bg_color,
			backgroundColor: tab.icon_warning_bg_color,
			color: tab.icon_warning_font_color,
			borderRadius: '.4em',
			padding: '1px',
		};

		const tabStyle = defaultStyle;
		let style = {};
		if (self.props.value === 'needed') {
			style = pendingStyle;
		} else if (self.props.value === 'completed') {
			style = successStyle;
		}

		return (
			<span key={`gettab_${self.props.vehicle.id}_icon${tab.id}`} style={style}>
				<i className={self.props.icon} />
			</span>
		);
	}
}

function mapStateToProps(state) {
	return { vehicle_columns: state.settings_departments.vehicle_columns };
}
export default TabIcon;
// export default connect(mapStateToProps)(TabIcon);
