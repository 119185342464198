import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import FlashMessageList from './FlashMessageList';
import {
	deleteEmailReport,
} from '../actions/apiEmailReports';
import {
	addFlashMessage,
	deleteFlashMessages,
} from '../actions/toggleMenu';

class DeleteEmailReportButton extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, email: '', user_name: '', password: '', errors: { deleteUserError: false },
		};
		this.onChange = this.onChange.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.deleteEmailReport = this.deleteEmailReport.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	deleteEmailReport() {
		const { id } = this.props.email_report;
		const data = {
			id,
		};
		const self = this;
		const errorType = 'deleteEmailReport';
		self.props.deleteEmailReport(id, errorType);
		this.closeModal();
	}

	render() {
		const { email_report } = this.props;
		const cantDelete = false;
		return (
			<span>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					className="d-flex justify-content-center"
					contentLabel="Delete Email Report"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-6 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="card-header bg-dark text-white">Warning you are about to Delete Report: {email_report.name}<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>x</button>
						</div>
						<div className="card-text">
							<div className="d-flex flex-column">
								<FlashMessageList />
							</div>
						</div>
						<div className="card-footer">
							<button className="btn btn-sm btn-danger float-left" onClick={this.deleteEmailReport}>
								<i className="fas fa-trash" /> Click to Confirm Delete Report: {email_report.name}
							</button>
							<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>Cancel</button>
						</div>
					</div>

				</Modal>
				<button disabled={cantDelete} className="btn btn-sm btn-danger" onClick={this.openModal}>
					<i className="fas fa-trash" />
				</button>
			</span>
		);
	}
}

const mappedActions = {
	deleteFlashMessages,
	addFlashMessage,
	deleteEmailReport,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.departments,
		currentUserId: state.current_user.user,
	};
}
export default connect(mapStateToProps, mappedActions)(DeleteEmailReportButton);
