import React,{ useState } from 'react';
import './VehicleDisplayImage.scss'
import ReconIcon from '../../../../assets/images/ipacket_recon_icon.png'
export default function VehicleDisplayImage(props){
  //Add Search for vehicle thumbnail and fallback
  const [errorLoading,setErrorLoading] = useState(false)
  if(props.vehicle){
    let vehicleImageProperty = null
    vehicleImageProperty = props.vehicle.vehicle_properties.find((property)=>{
      return(property.key==='thumbnail' && property.value)
    })
    if(!errorLoading && vehicleImageProperty){
      return(
        <div className="vehicle-display-image-container">
          <img className="vehicle-display-image" src={vehicleImageProperty.value} onError={()=>{setErrorLoading(true)}}/>
        </div>
      )
    }
  }
  return(
    <div className="vehicle-display-image-container">
      <div className="vehicle-display-image-placeholder-container">
        <img className="vehicle-display-image-placeholder" src={ReconIcon}/>
      </div>
    </div>
  )
}
