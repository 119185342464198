import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as AllMutates from './AllMutates';
import * as types from './types';

export function openSupportChat() {
	return {
		type: types.OPEN_SUPPORT_CHAT,
	};
}
export function closeSupportChat() {
	return {
		type: types.CLOSE_SUPPORT_CHAT,
	};
}
export function supportChatStatus(status) {
	return {
		type: types.SUPPORT_CHAT_STATUS,
		status,
	};
}

export function openSupportTicket() {
	return {
		type: types.OPEN_SUPPORT_TICKET,
	};
}
export function closeSupportTicket() {
	return {
		type: types.CLOSE_SUPPORT_TICKET,
	};
}
export function supportTicketStatus(status) {
	return {
		type: types.SUPPORT_TICKET_STATUS,
		status,
	};
}

export function updateSupportTickets(id, data, errorType) {
	return dispatch => Api.patchData(`support_tickets/${id}`, data).then(() => {
		dispatch(getInfo.getSupportTickets());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addSupportTicket(data, errorType) {
	return (dispatch, getState) => Api.postData('support_tickets/', data).then(resp => dispatch(getInfo.getSupportTickets())).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteSupportTicket(id, errorType) {
	return dispatch => Api.delete(`support_tickets/${id}`).then(() => {
		dispatch(getInfo.getSupportTickets());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateRooms(id, data, errorType) {
	return dispatch => Api.patchData(`rooms/${id}`, data).then(() => {
		dispatch(getInfo.getRooms());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addRoom(data, errorType) {
	return (dispatch, getState) => Api.postData('rooms/', data).then(resp => dispatch(getInfo.getRooms())).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteRoom(id, errorType) {
	return dispatch => Api.delete(`rooms/${id}`).then(() => {
		dispatch(getInfo.getRooms());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateRoomMessages(id, data, errorType) {
	return dispatch => Api.patchData(`room_messages/${id}`, data).then(() => {
		dispatch(getInfo.getRooms());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addRoomMessage(data, errorType) {
	return (dispatch, getState) => Api.postData('room_messages/', data).then(resp => dispatch(getInfo.getRooms())).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteRoomMessage(id, errorType) {
	return dispatch => Api.delete(`room_messages/${id}`).then(() => {
		dispatch(getInfo.getRooms());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}
