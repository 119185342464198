import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import EditTabSettings from '../../inputs/EditTabSettings';
import ColorPickerPopup from './ColorPickerPopup';
import IconPickerPopup from './IconPickerPopup';

class EditTabStyle extends Component {
	constructor(props) {
		super(props);

		this.state = { errors: { editTab: false }, option_name: '', icon: '' };
		this.getTabByName = this.getTabByName.bind(this);
	}

	getTabByName() {
		const self = this;
		const value = _.sortBy(_.filter(self.props.all_tabs, o => o.name === self.props.edit_tab), 'id');

		return value[0];
	}

	render() {
		const self = this;
		const { props } = this;
		const tab = this.getTabByName();

		const defaultStyle = {
			background: tab.icon_bg_color,
			backgroundColor: tab.icon_bg_color,
			color: tab.icon_font_color,
		};

		const pendingStyle = {
			background: tab.icon_pending_bg_color,
			backgroundColor: tab.icon_pending_bg_color,
			color: tab.icon_pending_font_color,
		};

		const successStyle = {
			background: tab.icon_success_bg_color,
			backgroundColor: tab.icon_success_bg_color,
			color: tab.icon_success_font_color,
		};

		const warningStyle = {
			background: tab.icon_warning_bg_color,
			backgroundColor: tab.icon_warning_bg_color,
			color: tab.icon_warning_font_color,
		};

		const statusArray = ['default', 'pending', 'success', 'warning'];

		return (
			<div className="col-md-12">

				<div className="card space-top-1">
					<div className="card-header">
						<div className="col-md-12">
							<small>
								<i className={'fa fa-info-circle text-info'} />
								{' '}
Settings only effect this tab. Use "Global Settings" to set the default colors for all tabs.
							</small>
						</div>
					</div>
					<div className="card-body">
						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Status</th>
									<th>Icon</th>
									<th>FontColor</th>
									<th>BgColor</th>
								</tr>
							</thead>
							<tbody>

								{statusArray.map(status => <tr key={`status_${status}`}>
									<th className="text-center text-capitalize">{status}</th>

									<td className="text-center"><IconPickerPopup {...this.props} value={tab[`icon${status == 'default' ? '' : `_${status}`}`] || tab.icon} name={`icon${status == 'default' ? '' : `_${status}`}`} dataid={tab.id} bg_color={tab[`icon${status === 'default' ? '' : `_${status}`}_bg_color`]} font_color={tab[`icon${status == 'default' ? '' : `_${status}`}_font_color`]} /></td>

									<td className="text-center"><ColorPickerPopup {...this.props} type={'colorpicker'} name={`icon${status === 'default' ? '' : `_${status}`}_font_color`} value={tab[`icon${status === 'default' ? '' : `_${status}`}_font_color`]} dataid={tab.id} cat={'tab'} bg_color={tab[`icon${status === 'default' ? '' : `_${status}`}_bg_color`]} font_color={tab[`icon${status == 'default' ? '' : `_${status}`}_font_color`]} /></td>

									<td className="text-center"><ColorPickerPopup {...this.props} type={'colorpicker'} name={`icon${status === 'default' ? '' : `_${status}`}_bg_color`} value={tab[`icon${status === 'default' ? '' : `_${status}`}_bg_color`]} dataid={tab.id} cat={'tab'} bg_color={tab[`icon${status === 'default' ? '' : `_${status}`}_bg_color`]} font_color={tab[`icon${status == 'default' ? '' : `_${status}`}_font_color`]} /></td>

								</tr>)}

							</tbody>
						</table>

					</div>
				</div>
				<div className="card space-top-1">
					<div className="card-header">
						<div className="col-md-12">
							<small>
								<i className={'fa fa-info-circle text-info'} />
								{' '}
Default comparisons vary for each tab. Ask a Recon Advisor support agent for best practices.
							</small>
						</div>
					</div>
					<div className="card-body row">

						<table className="table table-responsive">
							<thead className={'thead text-center'}>
								<tr>
									<th className={'desk-w-20'}>Status</th>
									<th className={'desk-w-30'}>Compare</th>
									<th className={'desk-w-20'}>Operator</th>
									<th className={'desk-w-30'}>Data</th>
								</tr>
							</thead>
							<tbody>

								{statusArray.map(status => <tr key={`status_compare_${status}`}>
									<th className="text-center text-capitalize">{status}</th>

									<td className="text-center">{status !== 'default' ? <EditTabSettings {...this.props} type={'select-data'} name={`icon_${status}_condition`} value={tab[`icon_${status}_condition`]} dataid={tab.id} /> : 'Default'}</td>
									<td className="text-center">{status !== 'default' ? <EditTabSettings {...this.props} type={'select-comparison'} name={`icon_${status}_condition_compare`} value={tab[`icon_${status}_condition_compare`]} dataid={tab.id} /> : 'Default'}</td>
									<td className="text-center">{status !== 'default' ? <EditTabSettings {...this.props} type={'text'} name={`icon_${status}_condition_equal`} value={tab[`icon_${status}_condition_equal`]} dataid={tab.id} /> : 'Default'}</td>
								</tr>)}

							</tbody>
						</table>

					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		all_tabs: state.settings_site.all_tabs,
		edit_tab: state.settings_admin.edit_tab,
	};
}
export default withRouter(connect(mapStateToProps)(EditTabStyle));
