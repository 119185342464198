import React from 'react';
import classnames from 'classnames';

const AddDefaultProfileButton = ({
	field, value, error, type, onChange, onClick,
}) => (
	<div className={classnames('input-group', { 'has-danger': error })}>

		<input
			onChange={onChange}
			value={value}
			type={type}
			name={field}
			className={classnames('form-control', { 'form-control-danger': error })}
		/>

		<span className="input-group-btn">
			<button
				className="btn btn-success"
				type="button"
				onClick={onClick}
			>
				<i className="fa fa-plus" />
				{' '}
Add Default Profile
			</button>
		</span>
	</div>

);

export default AddDefaultProfileButton;
