import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import TextFieldGroup from '../../../inputs/TextFieldGroupNoLabel';
import FlashMessageList from '../../../inputs/FlashMessageList';

import { addWorkFlow } from '../../../actions/apiWorkFlows';
import { setCurrentReportTab } from '../../../actions/apiSetInfo';

class AddVehicle extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			isLoading: '',
			work_flow: '',
			isValid: true,
			errorMessage: '',
		};
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.addWorkFlow = this.addWorkFlow.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	workFlowExtsts(name) {
		const {
			work_flows,
		} = this.props;
		return work_flows.some(w => (w.name || '').toLowerCase() === (name || '').toLowerCase());
	}

	validateWorkFlow(work_flow) {
		const result = {
			isValid: true,
			message: '',
		};
		const { name } = work_flow;
		if (!name || !name.trim()) {
			result.isValid = false;
			result.message = 'workflow name must be not empty';
		}
		if (this.workFlowExtsts(name)) {
			result.isValid = false;
			result.message = `workflow with name "${name}" already exists`;
		}
		return result;
	}

	addWorkFlow() {
		const self = this;
		const data = {
			name: this.state.work_flow,
		};
		const { isValid, message } = this.validateWorkFlow(data);
		this.setState({ isValid, errorMessage: message });
		if (!isValid) {
			return;
		}
		self.props.addWorkFlow(data, 'addWorkFlow');
		self.closeModal();
		setTimeout(() => {
			self.props.setCurrentReportTab('Outliers');
			self.props.setCurrentReportTab('Work Flows');
		}, 1000);
	}

	onChange(event) {
		const { name } = event.target;
		const { isValid, message } = this.validateWorkFlow({ name: event.target.value });
		this.setState({ [name]: event.target.value, isValid, errorMessage: message });
	}

	render() {
		const { errors, isValid, errorMessage } = this.state;

		const defaultStyle = {
			background: this.props.bg_color,
			backgroundColor: this.props.bg_color,
			color: this.props.font_color,
		};

		return (
			<div className="input-group-append">
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="PickColor"
					className="d-flex justify-content-center pad-bottom-2 space-bottom-2"
					contentLabel="Add Work Flow"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-6 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="modal-content">

							<div className="modal-header bg-dark text-white">
								<h4 className="modal-title">Add Work Flow</h4>
								<button type="button" className="close text-white" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
							</div>

							<div className="modal-body add-work-flow">
								<div className="card-block bg-white">
									<div className="row text-center">
										<FlashMessageList {...this.props} />
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Work Flow</label>
												<TextFieldGroup
													field={'work_flow'}
													value={this.state.work_flow}
													label={'Add Work Flow'}
													error={''}
													type={'text'}
													placeholder={'Work Flow'}
													onChange={this.onChange}
												/>
												{!isValid
												&& <div className="row text-center text-danger">
													{errorMessage}
												</div>}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button disabled={!isValid} type="button" className="btn btn-success" onClick={this.addWorkFlow}>Create Work Flow</button>
							</div>

						</div>
					</div>

				</Modal>
				<button className="input-group-text btn btn-primary" onClick={this.openModal}>
					<i className="fal fa-plus" />
					{' '}
Add Work Flow
				</button>
			</div>
		);
	}
}

const mappedActions = {
	addWorkFlow,
	setCurrentReportTab,
};

function mapStateToProps(state) {
	return {
		work_flows: state.settings_departments.work_flows || [],
	};
}
export default connect(mapStateToProps, mappedActions)(AddVehicle);
