import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import DropdownMultiple from './DropdownMultiple';
import {
	addNotificationSetting,
} from '../actions/apiNotifications';

class NotificationCheckbox extends Component {
	constructor(props) {
		super(props);

		this.state = { value: this.props.value || '', checked: this.props.checked || '', dealership: this.props.dealership };
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.selectRelatedInstallations = this.selectRelatedInstallations.bind(this);
	}

	onChangeChecked(e) {
		const self = this;
		const errorType = 'addProfilePermissionError';
		const dealership = this.props.installation.subdomain;

		const data = {
			department_id: this.props.department_id,
			value: e.target.checked === true,
			user_id: this.props.user_id,
			note_type: this.props.note_type,
			dealership,
		};

		self.setState({ checked: e.target.checked });

		self.props.addNotificationSetting(data, errorType);
	}

	selectRelatedInstallations() {
		const dealerships = this.props.dealership || '';
		const selected_dealerships = [];

		const dealer_values = dealerships.split(',');

		dealer_values.map((option) => {
			selected_dealerships.push(option);
		});

		const options = this.props.related_installations.map((option) => {
			let selected = false;
			const dataid = 0;
			if (selected_dealerships.includes(option.subdomain)) {
				selected = true;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, selected, dataid, subdomain: option.subdomain,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, selected, dataid, subdomain: option.subdomain,
				};
			}
			return obj;
		});

		let selected = false;
		if (selected_dealerships.includes('all')) {
			selected = true;
		}

		const obj = {
			id: 999, label: 'all', selected, subdomain: 'all',
		};
		options.push(obj);

		return options;
	}

	render() {
		const data = {
			department_id: this.props.department_id,
			user_id: this.props.user_id,
			note_type: this.props.note_type,
			dealership: this.props.dealership,
			value: true,
		};
		let dropdown = '';
		if (this.props.related_installations.length > 1 && this.state.checked) {
			dropdown = <DropdownMultiple
				{...this.props}
				titleHelper="Location"
				title="Select location"
				department_id={this.props.department_id}
				update_type={'notifciation_settings'}
				data={data}
				checkvalue={this.state.checked}
				list={this.selectRelatedInstallations()}
				dealers={this.props.dealership}
			/>;
		}

		return (
			<td className="text-center">
				<div className={classnames('form-group', { 'has-danger': this.props.error })}>
					<span className="switch">
						<input
							onChange={this.onChangeChecked}
							type={'checkbox'}
							name={this.props.name}
							data-id={this.props.department_id}
							key={`switch_id_${this.props.department_id}_${this.props.user_id}_${this.props.name}`}
							checked={this.state.checked === true ? 'checked' : ''}
							className={classnames('text-center', { 'form-control-danger': this.props.error })}
							id={`switch_id_${this.props.department_id}_${this.props.user_id}_${this.props.name}`}
						/>
						<label className={'text-capitalize'} key={`label_${this.props.department_id}_${this.props.user_id}_${this.props.name}`} htmlFor={`switch_id_${this.props.department_id}_${this.props.user_id}_${this.props.name}`} />
					</span>
				</div>
				<div className="col-md-12">
					{dropdown}
				</div>
			</td>
		);
	}
}

const mappedActions = {
	addNotificationSetting,
};

function mapStateToProps(state) {
	return {
		input_types: state.settings_departments.input_types,
		related_installations: state.settings_dealerships.related_installations,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps, mappedActions)(NotificationCheckbox);
