import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { lookupDepartmentNameById, activeEditDepartment } from '../../actions/lookups';
import DeleteDepartmentButton from './DeleteDepartmentButton';
import EditDepartmentOrder from '../../inputs/EditDepartmentOrder';
import EditDepartmentSettings from '../../inputs/EditDepartmentSettings';
import { updateEditDepartment, updateDepartmentOrder, deleteDepartment } from '../../actions/apiDepartmentUpdates';
import {
	setCurrentTab, setEditDepartmentCurrentTab,
} from '../../actions/apiSetInfo';
import TabEditDepartmentColors from './TabEditDepartmentColors';
import TabEditDepartmentProperties from './TabEditDepartmentProperties';
import TabEditDepartmentFilters from './TabEditDepartmentFilters';

class DepartmentListItem extends PureComponent {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.deleteDepartment = this.deleteDepartment.bind(this);
		this.updateDepartmentOrders = this.updateDepartmentOrders.bind(this);
		this.onTabClick = this.onTabClick.bind(this);
		this.state = {};
	}

	deleteDepartment(id) {
		const name = this.props.lookupDepartmentNameById(id);
		const data = {
			id,
			active: false,
			name: `deleted_${Math.floor(1000 + Math.random() * 9000)}_${name}`,
		};

		this.setState({ errors: { deleteDepartmentError: false } });
		this.props.updateEditDepartment(0);
		this.props.deleteDepartment(id, data, 'deleteDepartmentError');
	}

	onClick() {
		this.props.activeEditDepartment(this.props.department.id);
		if (this.props.edit_department_current_tab === '') {
			this.props.setCurrentTab('default-department-columns');
		}
		window.scrollTo(0, 0);
	}

	updateDepartmentOrders(e) {
		const errorType = 'updateDepartmentOrders';
		this.props.updateDepartmentOrder(e, errorType);
	}

	selectDealers(dealerships) {
		const options = dealerships.split().map((option) => {
			const obj = { value: option, label: option };
			return obj;
		});
		return options;
	}

	onTabClick(e) {
		this.props.setEditDepartmentCurrentTab(e.target.name);
	}

	render() {
		const self = this;
		const department = self.props.edit_department;
		const dealerships = department.dealerships || '';
		const dealers = this.selectDealers(dealerships);

		return (
				<div className="container row space-top-0">
					<div className="col">
					  <ul className="nav nav-pills nav-fill pad-bottom-1" role="tablist">
						<li className="nav-item">
							<a className={(this.props.edit_department_current_tab === 'edit-department-settings' ? 'nav-link active' : 'nav-link')} name="edit-department-settings" onClick={this.onTabClick}>Edit {department.name} Settings</a>
						</li>
						<li className="nav-item">
							<a className={(this.props.edit_department_current_tab === 'edit-department-columns' ? 'nav-link active' : 'nav-link')} name="edit-department-columns" onClick={this.onTabClick}>Add/Edit {department.name} Columns</a>
						</li>
						<li className="nav-item">
							<a className={(this.props.edit_department_current_tab === 'edit-department-colors' ? 'nav-link active' : 'nav-link')} name="edit-department-colors" onClick={this.onTabClick}>Colors</a>
						</li>
						<li className="nav-item">
							<a className={(this.props.edit_department_current_tab === 'edit-department-filters' ? 'nav-link active' : 'nav-link')} name="edit-department-filters" onClick={this.onTabClick}>Filters</a>
						</li>
					</ul>
					<div className="container-fluid pad-left-0 pad-right-0">

						<div className={(this.props.edit_department_current_tab === 'edit-department-settings' ? 'tab-box active' : 'tab-box')}>
							<div className="col-md-6 space-top-0 text-dark">
								<label className="label pad-2">Order</label>
								<EditDepartmentOrder {...this.props} key={`settings_order_${department.id}`} parentid={department.id} name={'order'} label={'order'} type={'number'} value={department.order} />
								<label className="label pad-2">Edit Department Name</label>
								<EditDepartmentSettings {...this.props} key={`settings_name_${department.id}`} parentid={department.id} name={'name'} label={'Name'} type={'text'} value={department.name} />
								<label className="label pad-2">Edit Display Name</label>
								<EditDepartmentSettings {...this.props} key={`settings_display_name_${department.id}`} parentid={department.id} name={'display_name'} label={'Display Name'} type={'text'} value={department.display_name} />
								<label className="label pad-2">Nest Under</label>
								<EditDepartmentSettings {...this.props} key={`settings_subgroup_${department.id}`} checked={false} parentid={department.id} name={'subgroup'} label={'Subgroup Parent'} type={'select-subgroup'} value={department.subgroup} />
								<label className="label pad-2">Show in Dealerships</label>
								<EditDepartmentSettings {...this.props} key={`settings_related_installations_${department.id}`} checked={false} parentid={department.id} name={'dealerships'} label={'Dealerships'} type={'select-related-installations-multiple'} value={dealerships} />
								<label className="label pad-2">Edit Department Type</label>
								<EditDepartmentSettings {...this.props} key={`settings_department_type_${department.id}`} checked={false} parentid={department.id} name={'department_type'} label={'Department Type'} type={'select-department-type'} value={department.department_type} />
								<label className="label pad-2">Transfer To New Location:</label>
								<EditDepartmentSettings {...this.props} key={`settings_trasfer_subdomain_${department.id}`} checked={false} parentid={department.id} name={'subdomain'} label={'Trasfer Vehicle To'} type={'select-related-installations'} value={department.subdomain} />
								<label className="label pad-2">Transfer To New Location's Department:</label>
								<EditDepartmentSettings {...this.props} key={`settings_subdomain_department_${department.id}`} checked={false} parentid={department.id} name={'subdomain_department'} label={'Set Transfer Department'} type={'select-departments'} value={department.subdomain_department} />
								<DeleteDepartmentButton property_id={department.id} onClick={this.deleteDepartment} />
							</div>
						</div>
						<div className={(this.props.edit_department_current_tab === 'edit-department-colors' ? 'tab-box active' : 'tab-box')}>
							<TabEditDepartmentColors edit_department={department} />
						</div>
						<div className={(this.props.edit_department_current_tab === 'edit-department-columns' ? 'tab-box active' : 'tab-box')}>
							<TabEditDepartmentProperties />
						</div>
						<div className={(this.props.edit_department_current_tab === 'edit-department-filters' ? 'tab-box active' : 'tab-box')}>
							<TabEditDepartmentFilters />
						</div>

					</div>
				</div>
			</div>

		);
	}
}

const mappedActions = {
	lookupDepartmentNameById,
	updateEditDepartment,
	deleteDepartment,
	updateDepartmentOrder,
	setCurrentTab,
	activeEditDepartment,
	setEditDepartmentCurrentTab,
};

function mapStateToProps(state) {
	return {
		current_department: state.settings_nav.department,
		departments: state.settings_departments.departments,
		current_tab: state.settings_admin.current_tab,
		edit_department: state.settings_admin.edit_department,
		edit_department_current_tab: state.settings_admin.edit_department_current_tab,
	};
}
export default connect(mapStateToProps, mappedActions)(DepartmentListItem);
