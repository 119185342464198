import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AddEmailReport from './AddEmailReport';
import EmailReportSingle from './EmailReportSingle';

class EmailReports extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<div className="col-md-12">

				<div className="space-top-1 mx-auto w-75 p-3">
					<h3 className="text-center pad-top-1">Automated Email Reports</h3>
					<p>Defining a workflow creates that workflow report as an option here.</p>

					<div className="card-body">
						<div className="card-text">
							<AddEmailReport {...this.props} />
						</div>
						<table className="table w100">
							<thead>
								<tr>
									<th>Edit</th>
									<th>Name</th>
									<th>Report</th>
									<th>Dealership</th>
									<th>Run Times</th>
									<th>Run Days</th>
									<th>Recipients</th>
									<th>Run Report</th>
									<th>Delete</th>
								</tr>
							</thead>
							<tbody>
						{this.props.email_reports.map(email_report => <EmailReportSingle key={email_report.id} {...this.props} email_report={email_report} />)}

							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { email_reports: state.settings_reports.email_reports };
}
export default connect(mapStateToProps)(EmailReports);
