import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextFieldGroup from '../../../../inputs/TextFieldGroupNoLabel';
import AllInputs from '../../../../inputs/AllInputs';
import TextFieldGroupWithButton from '../../../../inputs/TextFieldGroupWithButton';

class EditAll extends Component {
	constructor(props) {
		super(props);

		this.state = { active_photo: '' };
		this.onChange = this.onChange.bind(this);
		this.onChangeDealership = this.onChangeDealership.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onStockTypeChangeSelect = this.onStockTypeChangeSelect.bind(this);
		this.onDeleteVehicle = this.onDeleteVehicle.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.field]: e.target.value });
		const errorType = 'Edit Vehicle';

		const data = {
			id: this.props.active_vehicle.id,
			[e.target.field]: e.target.value,
		};

		this.props.updateVehicleData(this.props.active_vehicle.id, data, errorType);
	}

	onClick(e) {
		this.setState({ active_photo: e.target.src });
	}

	onDeleteVehicle(e) {
		this.props.deleteVehicle(this.props.active_vehicle.id);
	}

	onChangeDealership(e) {
		this.setState({ dealership: e.value });
		this.setState({ subdomain: e.value });
		const errorType = 'Change Dealership';

		const data = {
			id: this.props.active_vehicle.id,
			subdomain: e.value,
			dealership: e.value,
		};

		this.props.updateVehicleData(this.props.active_vehicle.id, data, errorType);
	}

	onChangeSelect(e) {
		this.setState({ department_id: e.value });
	}

	onStockTypeChangeSelect(e) {
		this.setState({ stocktype: e.value });
	}

	showDepartments() {
		return Object.keys(this.props.departments).map(key => this.props.departments[key]);
	}

	selectRelatedInstallations() {
		const options = this.props.related_installations.map((option) => {
			if (typeof option.softname !== 'undefined') {
				var obj = { value: option.subdomain, label: option.softname };
			} else {
				var obj = { value: option.subdomain, label: option.subdomain };
			}
			return obj;
		});
		return options;
	}

	departmentOptions() {
		const departments = this.showDepartments().map((department) => {
			if (department.department_type !== 'data step') {
				const obj = { value: department.id, label: department.name };
				return obj;
			}
		});
		return departments.filter(e => e);
	}

	stocktypeOptions() {
		const comparisonArray = [{ name: 'U', label: 'Used' },
			{ name: 'N', label: 'New' }, { name: 'W', label: 'Wholesale' },
			{ name: 'I', label: 'In Transit' }, { name: 'P', label: 'Prep' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	vehicleColumns(department) {
		if (_.isEmpty(department)) {
			if (this.props.active_vehicle.department_id) {
				if (department.department_properties) {
					var newObj = Object.keys(department.department_properties).map(key => department.department_properties[key]);
					return _.sortBy(newObj, 'order');
				}
				return [];
			}
			return [];
		}

		var newObj = Object.keys(department.department_properties).map(key => department.department_properties[key]);

		return _.sortBy(newObj, 'order');
	}

	getDepartmentBody(department, vehicle) {
		return this.vehicleColumns(department).map((vehCol) => {
			if (vehCol.vehicle_column.name == 'thumbnail') {
				return;
			}
			const colObject = _.find(vehicle.vehicle_properties, { key: vehCol.vehicle_column.name }) || '';
			const setdisabled = false;

			return <div className="col-md-3 text-center pad-top-1 pad-bottom-1" key={`vcedit_${vehCol.vehicle_column.name}_${department.id}`}>
				<label className="control-label">{vehCol.vehicle_column.name !== 'thumbnail' ? vehCol.vehicle_column.name : ''}</label>
				<AllInputs
					value={colObject.value || ''}
					type={vehCol.vehicle_column.input_name}
					name={vehCol.vehicle_column.name}
					parentid={vehicle.id}
					updatetype="vehicleproperty"
					setdisabled={setdisabled}
					user_name={colObject.user_name}
					updated_at={colObject.updated_at}
					options={vehCol.vehicle_column.options || []}
					vehicle={vehicle}
				/>
          </div>;
		});
	}

	getBody() {
		let body = '';
		const vehicle = this.props.active_vehicle;
		body = this.props.departments.map(department => <div className="card" key={`editcard_${department.id}`}>
			<div className="card-header bg-info text-white">
				<div className="text-capitalize">
					{department.name}
				</div>

			</div>
			<div className="card-text">

				<div className="row text-capitalize">
					{this.getDepartmentBody(department, vehicle)}
				</div>
			</div>
		</div>);

		return body;
	}

	render() {
		const body = this.getBody();
		return (
			<div className="container bg-light pad-top-1">
				<div className="card space-top-2">
					{body}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		related_installations: state.settings_dealerships.related_installations,
		current_department: state.settings_nav.department,
		vehicle_tab: state.settings_nav.menus.vehicle_tab,
		edit_vehicle: state.settings_nav.edit_vehicle,
		all_tabs: state.settings_site.all_tabs,
		expand_all: state.settings_nav.menus.expand_all,
		departments: state.settings_departments.dealership_departments,
		all_departments: state.settings_departments.departments,
		current_dealership: state.settings_dealerships.current_dealership,
		vehicle_notes: state.settings_refresh.vehicle_notes,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps)(EditAll);
