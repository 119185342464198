import findIndex from 'lodash/findIndex';
import _ from 'lodash';
import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const initialState = {
	all_tabs: [],
	error: {},
	dealer_settings: [],
	flash_messages: {},
	edit_work_flows: 0,
	edit_filter: '',
	email_reports: [],
	email_report: [],
	notification_settings: [],
};

export const settings_site = createReducer(initialState, {
	[types.SET_TABS](state = initialState, action) {
		return {
			...state,
			all_tabs: _.sortBy(action.tabs, ['order', 'id']) || [],
		};
	},
	[types.SET_DEALER_SETTINGS](state = initialState, action) {
		return {
			...state,
			dealer_settings: action.dealer_settings,
		};
	},
	[types.EDIT_WORK_FLOWS](state = initialState, action) {
		return {
			...state,
			edit_work_flows: action.edit_work_flows,
		};
	},
	[types.EDIT_FILTER](state = initialState, action) {
		return {
			...state,
			edit_filter: action.edit_filter,
		};
	},
	[types.SET_ALL_FILTERS](state = initialState, action) {
		return {
			...state,
			all_filters: _.sortBy(action.all_filters, 'id'),
		};
	},
	[types.ADD_FLASH_MESSAGE](state = initialState, action) {
		return {
			...state,
			flash_messages: action.message,
		};
	},
	[types.SET_INSTALLATION](state = initialState, action) {
		return {
			...state,
			installation: action.installation[0],
		};
	},
	[types.SET_NOTIFICATION_SETTINGS](state = initialState, action) {
		return {
			...state,
			notification_settings: action.notification_settings,
		};
	},
	[types.SET_RELATED_INSTALLATIONS](state = initialState, action) {
		return {
			...state,
			related_installations: action.related_installations,
		};
	},
	[types.DELETE_FLASH_MESSAGE](state = initialState, action) {
		const index = findIndex(state, { id: action.id });

		if (index >= 0) {
			const messages = [
				...state.slice(0, index),
				...state.slice(index + 1),
			];

			return {
				...state,
				flash_messages: messages,
			};
		}
		return {
			...state,
			flash_messages: {},
		};
	},
	[types.DELETE_FLASH_MESSAGES](state = initialState) {
		return {
			...state,
			flash_messages: {},
		};
	},
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			all_tabs: [],
			error: {},
			dealer_settings: [],
			flash_messages: {},
			installation: [],
			related_installations: [],
			edit_work_flows: 0,
			edit_filter: '',
			notification_settings: [],
		};
	},
});

export default 'settings_site';
