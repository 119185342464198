import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { editDefaultProfile } from '../actions/users';

class DefaultProfileCheckbox extends Component {
	constructor(props) {
		super(props);

		this.state = { value: this.props.value || '', checked: this.props.checked || '' };
		this.onChangeChecked = this.onChangeChecked.bind(this);
	}

	onChangeChecked(e) {
		const self = this;
		const errorType = 'editVehicleColumnError';
		const data = {
			id: self.props.parentid,
			[e.target.name]: e.target.checked,
		};

		self.setState({ checked: event.target.checked });

		self.props.editDefaultProfile(self.props.parentid, data, errorType);
	}

	returnDescription() {
		switch (this.props.name) {
		case 'all_departments':
			return 'Access to All Departments/Steps';

		case 'all_reports':
			return 'Access to All Reports without restrictions';

		case 'parts_overdue':
			return 'Access to the list of parts overdue';

		case 'in_progress':
			return 'Access to the in progress reports';

		case 'step_times':
			return 'Access to All Step Times Reports for All Stages';

		case 'monthly_averages':
			return 'Access to All Monthly Averages';

		case 'department_averages':
			return 'Access to All Department Averages';

		case 'email_reports':
			return 'Access to Create and Edit Emailable Reports';

		case 'advanced_search':
			return 'Access to Advanced Search of all inventory including deleted and sold';

		case 'custom_filtered':
			return 'Access to Use Custom Filters';

		case 'login_logs':
			return 'Access to the User Login Logs';

		case 'vehicle_history_summary':
			return 'Access to Basic Vehicle Recon Timeline Summary';

		case 'vehicle_history_detailed':
			return 'Access to Detailed Vehicle Recon Timeline';

		case 'all_vehicle_information':
			return 'Access to all of the vehicles information without restriction';

		case 'approve_estimates':
			return 'Is allowed to approve an estimate. This will also include the user in the drowpdown list for recipients';

		case 'approve_estimate_cosmetic':
			return 'Is allowed to approve an estimate with category - cosmetic. This will also include the user in the drowpdown list for recipients';

		case 'approve_estimate_mechanical':
			return 'Is allowed to approve an estimate with category - mechanical. This will also include the user in the drowpdown list for recipients';

		case 'approve_estimate_bodywork':
			return 'Is allowed to approve an estimate with category - bodywork. This will also include the user in the drowpdown list for recipients';

		case 'appointment_notifications':
			return 'Access to all appointments and appointment notifications';

		case 'lock_appraisals':
			return 'Ability to Lock the appraisal form';

		case 'add_files':
			return 'Ability to Add Files/Attachments';

		case 'delete_files':
			return 'Access to All delete_files';

		case 'edit_vehicle_core_information':
			return 'Ability to edit a vehicles stock and vin number - should only be allowed if the user is also responsible for entering vehicles by hand';

		case 'edit_vehicle':
			return 'Ability to edit vehicle information and status';

		case 'create_vehicle':
			return 'Ability to create a vehicle manually';

		case 'delete_vehicle':
			return 'Ability to delete a vehicle - once deleted the stock number is blocked from being imported again during a feed.';

		case 'view_invoice':
			return 'Access to invoice and cost information';

		case 'manage_vendors':
			return 'Ability to add and edit vendors';

		case 'view_all_tabs':
			return 'View All Vehicle Tabs';

		case 'move_vehicle_all_stages':
			return 'Allowed to move a vehicle to any stage/department';

		case 'create_edit_users':
			return 'Ability to create and edit users';

		case 'create_edit_user_profiles':
			return 'Ability to create and edit default user profiles';

		case 'create_edit_custom_filters':
			return 'Create and Edit Custom Filters';

		default:
			return '';
		}
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card">
					<div className="card-body row">
						<div className="col-md-12">
							<div className={classnames('form-group', { 'has-danger': this.props.error })}>
								<span className="switch">
									<input
										onChange={this.onChangeChecked}
										type={'checkbox'}
										name={this.props.name}
										data-id={this.props.parentid}
										key={`permission${this.props.parentid}_${this.props.name}`}
										checked={this.props.checked ? 'checked' : ''}
										className={classnames('text-center', { 'form-control-danger': this.props.error })}
										id={`switch_id_${this.props.parentid}_${this.props.name}`}
									/>
									<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.parentid}_${this.props.name}`}>{this.props.name.replace(/_/g, ' ')}</label>
								</span>
							</div>
						</div>
						<div className="col-md-12">
							<small>
								<i className={'fa fa-info-circle text-info'} />
								{' '}
								{this.returnDescription()}
							</small>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

const mappedActions = {
	editDefaultProfile,
};

function mapStateToProps(state) {
	return {
		input_types: state.settings_departments.input_types,
	};
}
export default connect(mapStateToProps, mappedActions)(DefaultProfileCheckbox);
