import _ from 'lodash';
import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as AllMutates from './AllMutates';
import * as lookups from './lookups';

export function updateDepartmentSetting(id, data, errorType) {
	return dispatch => Api.patchData(`departments/${id}`, data).then(() => {
		dispatch(lookups.activeEditDepartment(id));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addNewDepartment(meta, value, errorType) {
	return (dispatch, getState) => {
		const { current_dealership } = getState().settings_dealerships;

		const data = {
			name: value,
			department_type: 'department',
			dealerships: current_dealership,
			order: 99,
		};

		return Api.postData('departments/', data).then(() => dispatch(getInfo.getDepartments())).catch(ex => Api.handleApiError(ex, errorType));
	};
}

export function addNewDepartmentProperty(id, data, errorType) {
	return dispatch => Api.postData('department_properties/', data);
}

export function deleteDepartment(id, data, errorType) {
	return dispatch => Api.delete(`departments/${id}`, data).then(() => dispatch(getInfo.getDepartments())).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateEditDepartment(department_id) {
	return (dispatch, getState) => {
		dispatch(lookups.activeEditDepartment(department_id));
	};
}

export function updateDepartmentOrder(departments, errorType) {
	return (dispatch) => {
		departments.map((department, i) => {
			const data = {
				id: department,
				order: i,
			};

			return Api.patchData(`departments/${department}`, data).then(() => {
			}).catch(ex => Api.handleApiError(ex, errorType));
		});

		dispatch(getInfo.getDepartments());
	};
}

export function addNewVehicleColumn(data, errorType) {
	return dispatch => Api.postData('vehicle_columns/', data).then(() => {
		dispatch(getInfo.getVehicleColumns());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addNewVehicleColumnID(data, errorType) {
	return dispatch => Api.postData('vehicle_columns/', data).then((resp) => {
		dispatch(getInfo.getVehicleColumns());
		return resp.data.data.id;
	}).catch(ex => Api.handleApiError(ex, errorType));
}
export function addVehicleColumnOption(id, data, errorType) {
	return (dispatch, getState) => Api.postData('options/', data).then((resp) => {
		dispatch(lookups.activeEditVehicleColumn(data.vehicle_column_id));
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateVehicleColumn(id, data, errorType) {
	return dispatch => Api.patchData(`vehicle_columns/${id}`, data)
		.then(() => {
			dispatch(lookups.activeEditVehicleColumn(id));
			dispatch(getInfo.getVehicleColumns());
		})
		.catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteVehicleColumnOption(id, column_id, errorType) {
	return (dispatch, getState) => Api.delete(`options/${id}`).then(() => {
		dispatch(lookups.activeEditVehicleColumn(column_id));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateDepartment(id, meta, value) {
	return (dispatch, getState) => Api.patch(`departments/${id}`, meta, value).then(() => {
		dispatch(lookups.activeEditDepartment(id));
	}).catch(() => {
	});
}

export function deleteDepartmentProperty(departmentid, id, errorType) {
	return dispatch => Api.delete(`department_properties/${id}`).then(() => {
		dispatch(lookups.activeEditDepartment(departmentid));
	}).catch(ex => Api.handleApiError(ex, errorType));
}
