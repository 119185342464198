import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const initialState = {
	vin_scanner: '',
	vin_decoded_vehicle: {
		vin: '', year: '', make: '', model: '', message: '',
	},
	vin_scanner_size: {
		height: 0,
		width: 0,
	},
	interval_vin_scanner: '',
};

export const settings_vin_scanner = createReducer(initialState, {
	[types.VIN_SCANNER](state = initialState, action) {
		return {
			...state,
			vin_scanner: action.vin_scanner,
		};
	},
	[types.VIN_DECODED_VEHICLE](state = initialState, action) {
		return {
			...state,
			vin_decoded_vehicle: action.vehicle,
		};
	},
	[types.SET_INTERVAL_VIN_SCANNER](state = initialState, action) {
		return {
			...state,
			interval_vin_scanner: action.id,
		};
	},
	[types.SET_VIN_SCANNER_HEIGHT](state = initialState, action) {
		return {
			...state,
			vin_scanner_size: {
				height: action.height,
				width: action.width,
			},
		};
	},
	[types.SET_VIN_SCANNER_WIDTH](state = initialState, action) {
		return {
			...state,
			vin_scanner_width: action.width,
		};
	},
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			vin_scanner: '',
			vin_decoded_vehicle: {
				vin: '', year: '', make: '', model: '', message: '',
			},
			vin_scanner_size: {
				height: 0,
				width: 0,
			},
			interval_vin_scanner: '',
		};
	},
});

export default 'settings_vin_scanner';
