export function userStamp(dbtime) {
	const updateDate = new Date(dbtime);
	let hours = updateDate.getHours();
	const ampm = hours >= 12 ? 'pm' : 'am';
	hours %= 12;
	hours = hours || 12;

	let minutes = `0${updateDate.getMinutes()}`;
	const year = updateDate.getFullYear();
	const month = updateDate.getMonth() + 1;
	const day = updateDate.getDate();

	minutes = minutes < 10 ? `0${minutes}` : minutes;

	const formattedTime = `${month}/${day}/${year} ${hours}:${minutes.substr(-2)}${ampm}`;

	return formattedTime;
}

export function readableStamp(dbtime) {
	const updateDate = new Date(dbtime);
	let hours = updateDate.getHours();
	const ampm = hours >= 12 ? 'pm' : 'am';
	hours %= 12;
	hours = hours || 12;

	let minutes = `0${updateDate.getMinutes()}`;
	const year = updateDate.getFullYear();
	const month = updateDate.getMonth() + 1;
	const day = updateDate.getDate();

	minutes = minutes < 10 ? `0${minutes}` : minutes;

	const formattedTime = `${month}/${day}/${year} ${hours}:${minutes.substr(-2)}${ampm}`;

	return formattedTime;
}

export function readableStampSimple(dbtime) {
	const updateDate = new Date(dbtime);
	const year = updateDate.getFullYear();
	const month = updateDate.getMonth() + 1;
	const day = updateDate.getDate();

	const formattedTime = `${month}/${day}/${year}`;

	return formattedTime;
}

export function returnAgeFromTimestamp(timestamp) {
	let totaltime = 0;

	const startDate = new Date(timestamp * 1000);
	const currentDate = new Date();

	const timePassed = parseInt((currentDate - startDate) / 1000);

	if (timePassed > (24 * 60 * 60)) {
		totaltime = `${parseInt(timePassed / (24 * 60 * 60))} days`;
	} else {
		totaltime = `${parseInt(timePassed / (60 * 60))} hrs`;
	}

	return totaltime;
}

export function returnDaysFromTimestamp(timestamp) {
	let totaltime = 0;

	const startDate = new Date(timestamp * 1000);
	const currentDate = new Date();

	const timePassed = parseInt((currentDate - startDate) / 1000);

	totaltime = `${(timePassed / (24 * 60 * 60)).toFixed(1)}`;

	return totaltime;
}

export function returnHoursFromTimestamp(timestamp) {
	let totaltime = 0;

	const startDate = new Date(timestamp * 1000);
	const currentDate = new Date();

	const timePassed = parseInt((currentDate - startDate) / 1000);

	totaltime = parseInt(timePassed / (60 * 60));

	return totaltime;
}

export function returnAge(dbtime) {
	let totaltime = 0;
	const startDate = new Date(dbtime);
	const currentDate = new Date();

	const timePassed = parseInt((currentDate - startDate) / 1000);

	if (timePassed > (24 * 60 * 60)) {
		totaltime = `${parseInt(timePassed / (24 * 60 * 60))} days`;
	} else {
		totaltime = `${parseInt(timePassed / (60 * 60))} hrs`;
	}

	return totaltime;
}

export function returnDaysHours(hours) {
	var totaltime = '';
	if (hours >= (24)) {
		totaltime = `${parseInt(hours / 24)} days`;
	} else {
		totaltime = `${parseInt(hours)} hrs`;
	}

	return totaltime;
}

export function getSecondsFromTimeRange(starttime, endtime) {
	const start = new Date(starttime);
	const end = new Date(endtime);
	const time = end.getTime() - start.getTime();
	return time / 1000;
}

export function calcBusinessDays(starttime, endtime) {
	const start = new Date(starttime);
	const end = new Date(endtime);
	const time = end.getTime() - start.getTime();
	return time / 1000;
}

export function returnTabAge(department_histories, work_flow) {
	let totaltime = 0;
	let use_work_flow = false;

	if (work_flow.length > 0) {
		use_work_flow = true;
	}
	department_histories.map((department_history) => {
		if (use_work_flow) {
			if (_.find(work_flow, { department_id: department_history.department_id })) {
				totaltime += department_history.recon_total_24_5;
			}
		} else {
			totaltime += department_history.recon_total_24_5;
		}
	});
	return totaltime;
}

export function returnTabAge_24_5(department_histories, work_flow) {
	let totaltime = 0;
	let use_work_flow = false;

	if (work_flow.length > 0) {
		use_work_flow = true;
	}
	department_histories.map((department_history) => {
		if (use_work_flow) {
			if (_.find(work_flow, { department_id: department_history.department_id })) {
				totaltime += department_history.recon_total_24_5;
			}
		} else {
			totaltime += department_history.recon_total_24_5;
		}
	});
	return totaltime;
}

export function returnTabAge_24_6(department_histories, work_flow) {
	let totaltime = 0;
	let use_work_flow = false;

	if (work_flow.length > 0) {
		use_work_flow = true;
	}
	department_histories.map((department_history) => {
		if (use_work_flow) {
			if (_.find(work_flow, { department_id: department_history.department_id })) {
				totaltime += department_history.recon_total_24_6;
			}
		} else {
			totaltime += department_history.recon_total_24_6;
		}
	});
	return totaltime;
}

export function returnTabAge_24_7(department_histories, work_flow) {
	let totaltime = 0;
	let use_work_flow = false;

	if (work_flow.length > 0) {
		use_work_flow = true;
	}
	department_histories.map((department_history) => {
		if (use_work_flow) {
			if (_.find(work_flow, { department_id: department_history.department_id })) {
				totaltime += department_history.recon_total_24_7;
			}
		} else {
			totaltime += department_history.recon_total_24_7;
		}
	});
	return totaltime;
}
export function returnAgeFromDepartmentHistories(department_histories, work_flow) {
	let totaltime = 0;
	let use_work_flow = false;

	if (work_flow.length > 0) {
		use_work_flow = true;
	}
	department_histories.map((department_history) => {
		if (use_work_flow) {
			if (_.find(work_flow, { department_id: department_history.department_id })) {
				totaltime += department_history.recon_total_24_5;
			}
		} else {
			totaltime += department_history.recon_total_24_5;
		}
	});
	return convertSecondsToDays(totaltime);
}
export function returnAgeFromDepartmentHistories_24_7(department_histories, work_flow) {
	let totaltime = 0;
	let use_work_flow = false;

	if (work_flow.length > 0) {
		use_work_flow = true;
	}
	department_histories.map((department_history) => {
		if (use_work_flow) {
			if (_.find(work_flow, { department_id: department_history.department_id })) {
				totaltime += department_history.recon_total_24_7;
			}
		} else {
			totaltime += department_history.recon_total_24_7;
		}
	});
	return convertSecondsToDays(totaltime);
}
export function getSecondsFromTimeRange_24_5(starttime, enddate) {
	const start = new Date(starttime);
	const end = new Date(enddate);

	const firstdaytime = start.getSeconds() + (((start.getHours() * 60) + start.getMinutes()) * 60);
	const midnightSeconds = 24 * 60 * 60;
	const firstdaySeconds = midnightSeconds - firstdaytime;
	const lastdaySeconds = end.getSeconds() + (((end.getHours() * 60) + end.getMinutes()) * 60);

	const rawseconds = (end.getTime() - start.getTime()) / 1000;
	const rawminutes = rawseconds / 60;
	const rawhours = rawseconds / (60 * 60);
	const rawdays = rawseconds / (60 * 60 * 24);
	const wholedays = Math.floor(rawdays);

	const firstDayDigit = start.getDate();
	const firstMonthDigit = start.getMonth() + 1;
	const firstYearDigit = start.getFullYear();

	const firstDayDay = start.getDay();

	const endDayDigit = end.getDate();
	const endDayDay = end.getDay();

	let adjustedSeconds = 0;

	const adjustedDailySeconds = 24 * 60 * 60;

	if (rawdays > 1) {
		if (firstDayDay !== 0 && firstDayDay !== 6) {
			adjustedSeconds += firstdaySeconds;
		}

		if (endDayDay !== 0 && endDayDay !== 6) {
			adjustedSeconds += lastdaySeconds;
		}

		const dayDigit = firstDayDigit;
		const yearDigit = firstYearDigit;
		const monthDigit = firstMonthDigit;

		for (let loopdays = 0; loopdays < wholedays; loopdays += 1) {
			const loopday = `${yearDigit}-${monthDigit}-${dayDigit}`;
			const checkDate = new Date(loopday);
			const checkDay = (checkDate.getDay() + loopdays) % 7;
			if (checkDay !== 0 && checkDay !== 6) {
				adjustedSeconds += adjustedDailySeconds;
			}
		}
	} else if (firstDayDigit !== endDayDigit) {
		if (firstDayDay !== 0 && firstDayDay !== 6) {
			adjustedSeconds += firstdaySeconds;
		}

		if (endDayDay !== 0 && endDayDay !== 6) {
			adjustedSeconds += lastdaySeconds;
		}
	} else if (firstDayDay !== 0 && firstDayDay !== 6) {
		adjustedSeconds += rawseconds;
	}

	return adjustedSeconds;
}

export function getSecondsFromTimeRange_24_6(starttime, enddate) {
	const start = new Date(starttime);
	const end = new Date(enddate);

	const firstdaytime = start.getSeconds() + (((start.getHours() * 60) + start.getMinutes()) * 60);
	const midnightSeconds = 24 * 60 * 60;
	const firstdaySeconds = midnightSeconds - firstdaytime;
	const lastdaySeconds = end.getSeconds() + (((end.getHours() * 60) + end.getMinutes()) * 60);

	const rawseconds = (end.getTime() - start.getTime()) / 1000;
	const rawminutes = rawseconds / 60;
	const rawhours = rawseconds / (60 * 60);
	const rawdays = rawseconds / (60 * 60 * 24);
	const wholedays = Math.floor(rawdays);

	const firstDayDigit = start.getDate();
	const firstMonthDigit = start.getMonth() + 1;
	const firstYearDigit = start.getFullYear();

	const firstDayDay = start.getDay();

	const endDayDigit = end.getDate();
	const endDayDay = end.getDay();

	let adjustedSeconds = 0;

	const adjustedDailySeconds = 24 * 60 * 60;

	if (rawdays > 1) {
		if (firstDayDay !== 0) {
			adjustedSeconds += firstdaySeconds;
		}

		if (endDayDay !== 0) {
			adjustedSeconds += lastdaySeconds;
		}

		const dayDigit = firstDayDigit;
		const yearDigit = firstYearDigit;
		const monthDigit = firstMonthDigit;

		for (let loopdays = 0; loopdays < wholedays; loopdays += 1) {
			const loopday = `${yearDigit}-${monthDigit}-${dayDigit}`;
			const checkDate = new Date(loopday);
			const checkDay = (checkDate.getDay() + loopdays) % 7;
			if (checkDay !== 0) {
				adjustedSeconds += adjustedDailySeconds;
			}
		}
	} else if (firstDayDigit !== endDayDigit) {
		if (firstDayDay !== 0) {
			adjustedSeconds += firstdaySeconds;
		}

		if (endDayDay !== 0) {
			adjustedSeconds += lastdaySeconds;
		}
	} else if (firstDayDay !== 0) {
		adjustedSeconds += rawseconds;
	}

	return adjustedSeconds;
}

export function convertSecondsToTime(secondsPassed) {
	let totaltime = 0;

	if (secondsPassed > (24 * 60 * 60)) {
		if (secondsPassed > (24 * 60 * 60 * 365)) {
			totaltime = 0;
		} else {
			totaltime = `${parseInt(secondsPassed / (24 * 60 * 60))} days`;
		}
	} else {
		totaltime = `${parseInt(secondsPassed / (60 * 60))} hrs`;
	}

	return totaltime;
}

export function convertSecondsToDays(secondsPassed) {
	let totaltime = 0;

	totaltime = `${parseInt(secondsPassed / (24 * 60 * 60))}`;

	return totaltime;
}

export function convertSecondsToHours(secondsPassed) {
	let totaltime = 0;

	totaltime = `${parseInt(secondsPassed / (60 * 60))}`;

	return totaltime;
}
export function convertSecondsToTime2(secondsPassed) {
	let totaltime = 0;

	totaltime = parseInt(secondsPassed / (60 * 60));

	return totaltime;
}

export function returnAgeSeconds(dbtime) {
	const startDate = new Date(dbtime);
	const currentDate = new Date();

	const timePassed = parseInt((currentDate - startDate) / 1000);

	return timePassed;
}

export function noteStamp(dbtime) {
	const updateDate = new Date(dbtime);
	let hours = updateDate.getHours();
	const ampm = hours >= 12 ? 'pm' : 'am';
	hours %= 12;
	hours = hours || 12;

	let minutes = `0${updateDate.getMinutes()}`;
	const month = updateDate.getMonth() + 1;
	const day = updateDate.getDate();

	minutes = minutes < 10 ? `0${minutes}` : minutes;

	const formattedTime = `${month}/${day}  ${hours}:${minutes.substr(-2)}${ampm}`;

	return formattedTime;
}

export function getDateTime() {
	const currentDate = new Date();

	const date = currentDate.getDate();
	const month = currentDate.getMonth() + 1;
	const year = currentDate.getFullYear();

	const hours = currentDate.getHours();
	const minutes = currentDate.getMinutes();
	const seconds = currentDate.getSeconds();

	const dateString = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;

	return dateString;
}

export function getMonthDayYear() {
	const currentDate = new Date();

	const date = currentDate.getDate();
	const month = currentDate.getMonth() + 1;
	const year = currentDate.getFullYear();

	const dateString = `${month}/${date}/${year}`;

	return dateString;
}

export function getMonthDayYearFromDate(datetime) {
	const currentDate = new Date(datetime);

	const date = currentDate.getDate();
	const month = currentDate.getMonth() + 1;
	const year = currentDate.getFullYear();

	const dateString = `${month}/${date}/${year}`;

	return dateString;
}

export function getDayMonthYear() {
	const currentDate = new Date();
	currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset());
	const date = currentDate.getDate();
	let month = currentDate.getMonth() + 1;
	if (month < 10) { month = `0${month}`; }
	const year = currentDate.getFullYear();

	const dateString = `${year}-${month}-${date}`;

	return dateString;
}

export function getDayMonthYearFromDate(datetime) {
	let dt = datetime;
	if (datetime === '0') {
		dt = '';
	}

	const currentDate = new Date(dt);
	currentDate.setMinutes(currentDate.getMinutes() + currentDate.getTimezoneOffset());
	let date = currentDate.getDate();
	let month = currentDate.getMonth() + 1;
	if (month < 10) { month = `0${month}`; }
	if (date < 10) { date = `0${date}`; }
	const year = currentDate.getFullYear();

	const dateString = `${year}-${month}-${date}`;

	return dateString;
}

export function getTimestamp() {
	const date = new Date();
	return date.getTime();
}

export function numberWithCommas(x) {
	return x > 0 ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
}

export function formatPhoneNumber(phoneNumberString) {
	var cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
	var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return `${match[1]}-${match[2]}-${match[3]}`;
	}
	return null;
}

export function formatCleanNumber(phoneNumberString) {
	var cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
	var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return `${match[1]}-${match[2]}-${match[3]}`;
	}
	return null;
}

export function paginateList(array, page_size, page_number) {
	page_number -= 1;
	return array.slice(page_number * page_size, (page_number + 1) * page_size);
}
