import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import TextFieldGroupWithButton from '../../inputs/TextFieldGroupWithButton';
import FlashMessageList from '../../inputs/FlashMessageList';
import Api from '../../api/reconApi.js';
import { addRoom } from '../../actions/apiSupportTickets';

class AddRoom extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			installation_id: '',
			vehicle_id: '',
			room_type: '',
			profile: '',
			support: '',
			status: '',
			active: false,
			modalIsOpen: false,
		};

		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.addRoom = this.addRoom.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onChangeDealership = this.onChangeDealership.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	onChangeSelect(e) {
		this.setState({ audit_type: e.value });
	}

	onChangeDealership(e) {
		this.setState({ dealerships: e.value });
	}

	selectRoomType() {
		const comparisonArray = [{ name: 'one-on-one chat-only', label: 'One on One - Chat Only' },
			{ name: 'vehicle-chat', label: 'Vehicle Chat' }, { name: 'department-chat', label: 'Department Chat' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectRelatedInstallations() {
		const options = this.props.related_installations.map((option) => {
			if (typeof option.subdomain !== 'undefined') {
				var obj = { value: option.subdomain, label: option.softname };
			} else {
				var obj = { value: option.subdomain, label: option.softname };
			}
			return obj;
		});
		return options;
	}

	addRoom() {
		const data = {
			name: this.state.name,
			status: this.state.status,
			installation_id: this.state.installation_id,
			vehicle_id: this.state.vehicle_id,
			room_type: this.state.room_type,
			profile: this.state.profile,
			support: this.state.support,
			status: this.state.status,
			active: this.state.active,
		};

		const extra = {};

		this.props.addRoom(data, extra).then((resp) => {
			this.setState({
				name: '',
				installation_id: '',
				vehicle_id: '',
				room_type: '',
				profile: '',
				support: '',
				status: '',
				active: false,
				modalIsOpen: false,
			});
		});
	}

	onChange(event) {
		const { name } = event.target;
		this.setState({ [name]: event.target.value });
	}

	render() {
		const { errors } = this.state;

		const defaultStyle = {
			background: this.props.bg_color,
			backgroundColor: this.props.bg_color,
			color: this.props.font_color,
		};

		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="PickColor"
					className="d-flex justify-content-center pad-bottom-2 space-bottom-2"
					contentLabel="Add Audit"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-3 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="modal-content">

							<div className="modal-header bg-dark text-white">
								<h4 className="modal-title">Add Chat Room</h4>
								<button type="button" className="close text-white" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
							</div>

							<div className="modal-body add-vehicle">
								<div className="card-block bg-white">
									<div className="row text-center">
										<FlashMessageList {...this.props} />
									</div>
									<div className="row">
										<label>Room Name: </label>
										<TextFieldGroup
											onChange={this.onChange}
											value={this.state.name}
											type={'text'}
											name={'name'}
											field={'name'}
											updatetype="room"
											disabled={false}
										/>
									</div>
									<div className="row">
										<label>Chat Type: </label>
										<Select
											onChange={this.onChangeSelect}
											value={this.selectRoomType().filter(({ value }) => value === this.state.room_type)}
											name={'chat_type'}
											options={this.selectRoomType()}
											className="form-control text-capitalize text-dark"
											clearable={false}
											searchable={false}
											menuContainerStyle={{ zIndex: 5 }}

										/>

									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-success" onClick={this.addRoom}>Add Room</button>
							</div>

						</div>
					</div>

				</Modal>
				<span className="btn btn-info" onClick={this.openModal}>
					<i className="fal fa-plus" />
					{' '}
Add Chat Room
				</span>
			</div>
		);
	}
}

const mappedActions = {
	addRoom,
};

function mapStateToProps(state) {
	return {
		rooms: state.settings_support_tickets.rooms,
		related_installations: state.settings_dealerships.related_installations,
	};
}
export default connect(mapStateToProps, mappedActions)(AddRoom);
