import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as lookups from './lookups';
import * as AllMutates from './AllMutates';

export function updatePartOrder(id, data, errorType) {
	return (dispatch, getState) => Api.patchData(`part_orders/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(getInfo.getPartOrdersByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(data.vehicle_id));
		});
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addPartOrder(data, errorType) {
	return (dispatch, getState) => Api.postData('part_orders/', data).then(() => {
		Promise.all([
			dispatch(getInfo.getPartOrdersByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(data.vehicle_id));
		});
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addPartOrderRaw(data, errorType) {
	return dispatch => Api.postData('part_orders/', data).then(() => dispatch(getInfo.getPartOrdersByVidAndUpdate(data.vehicle_id))).catch(ex => Api.handleApiError(ex, errorType));
}

export function deletePartOrder(id, vehicle_id, errorType) {
	return (dispatch, getState) => Api.delete(`part_orders/${id}`, '').then(() => {
		Promise.all([
			dispatch(getInfo.getPartOrdersByVidAndUpdate(vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(data.vehicle_id));
		});
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}
