import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SuperFormWorkItem from './SuperFormWorkItem';
import FileUploadButton from '../../../../inputs/FileUploadButton';
import {
	addEstimate,
} from '../../../../actions/apiEstimates';

const estimateFields = ['id', 'Select Vendor', 'Description', 'Files/Images', 'Action', 'x'];

const allFields = ['vehicle_id', 'ro_number', 'requested_by_uid', 'description', 'estimate',
	'actual', 'ro_status', 'approved_by_uid', 'approved_date', 'declined_by_uid', 'declined_date', 'ro_files',
	'declined_reason', 'ro_email_link_declined', 'ro_email_link_approved'];

class SuperForm extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { addEstimateError: '' };
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.getTopCheckboxes = this.getTopCheckboxes.bind(this);
		this.getOtherCheckboxes = this.getOtherCheckboxes.bind(this);
		this.tabByName = this.tabByName.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	vehicleProperty(name) {
		const self = this;

		const value = _.filter(self.props.active_vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_status: 'getapproved',
		};

		this.props.addEstimate(data, 'addEstimateError');
	}

	tabByName(name) {
		const self = this;
		const tab = _.sortBy(_.filter(self.props.all_tabs, o => o.name == name), 'id');
		return tab[0] || [];
	}

	getTopCheckboxes() {
		const tab = this.tabByName(this.props.tab);
		const vehicle = this.props.active_vehicle;

		const html = tab.tab_properties.map((tab_property) => {
			const vehicle_column = _.find(this.props.vehicle_columns, { id: tab_property.vehicle_column_id });

			if (typeof vehicle_column !== 'undefined') {
				const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

				return <div className="col-md-4 text-center" key={`form_${vehicle_column.name}_${vehicle.id}`}>
					<label className="text-capitalize">{vehicle_column.name}</label>
					<VehicleColumn
						name={vehicle_column.name}
						label={vehicle_column.name}
						onChange={this.onChange}
						value={colObject.value || ''}
						type={vehicle_column.input_name}
						error=""
						options={vehicle_column.options}
						vehicle={vehicle}
						checked={colObject.value || ''}
						updateType="vehicle"
						key={`vehicle_${vehicle.id}tab_property${tab_property.id}`}
						vehicle_id={vehicle.id}
						data_id={colObject.id || ''}
						uid={colObject.uid}
						user_name={colObject.user_name}
						updated_at={colObject.updated_at}
						icon={vehicle_column.icon}
					/>
				</div>;
			}
		});

		return html;
	}

	getOtherCheckboxes() {
		const self = this;
		const certifiedFields = [{ name: 'ro number', label: 'RO Number', type: 'userstamp_text' },
			{ name: 'certified', label: 'Certified', type: 'userstamp' },
			{ name: 'not certified', label: 'Not Certified', type: 'userstamp' },
			{ name: 'wholesale', label: 'Wholesale', type: 'userstamp' },
			{ name: 'work completed', label: 'Work Completed', type: 'userstamp' }];

		const vehicle = this.props.active_vehicle;

		const html = certifiedFields.map((tab_property) => {
			const vehicle_column = _.find(self.props.vehicle_columns, { name: tab_property.name });

			if (typeof vehicle_column !== 'undefined') {
				const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

				return <td className="text-center" key={`form_${vehicle_column.name}_${vehicle.id}`}>
					<label className="text-capitalize">{vehicle_column.name}</label>
					<VehicleColumn
						name={vehicle_column.name}
						label={vehicle_column.name}
						onChange={this.onChange}
						value={colObject.value || ''}
						type={vehicle_column.input_name}
						error=""
						options={vehicle_column.options}
						vehicle={vehicle}
						checked={colObject.value || ''}
						updateType="vehicle"
						key={`vehicle_${vehicle.id}tab_property${tab_property.id}`}
						vehicle_id={vehicle.id}
						data_id={colObject.id || ''}
						uid={colObject.uid}
						user_name={colObject.user_name}
						updated_at={colObject.updated_at}
						icon={vehicle_column.icon}

					/>
           </td>;
			}
		});

		return html;
	}

	getTires() {
		const self = this;
		const certifiedFields = [{ name: 'tread depth front left', label: 'tread depth front left', type: 'userstamp_text' },
			{ name: 'tread depth front right', label: 'tread depth front right', type: 'userstamp_text' },
			{ name: 'tread depth rear left', label: 'tread depth rear left', type: 'userstamp_text' },
			{ name: 'tread depth rear right', label: 'tread depth rear right', type: 'userstamp_text' },
			{ name: 'pad life front left', label: 'pad life front left', type: 'userstamp_text' },
			{ name: 'pad life front right', label: 'pad life front right', type: 'userstamp_text' },
			{ name: 'pad life rear left', label: 'pad life rear left', type: 'userstamp_text' },
			{ name: 'pad life rear right', label: 'pad life rear right', type: 'userstamp_text' }];

		const vehicle = this.props.active_vehicle;

		const html = certifiedFields.map((tab_property) => {
			const vehicle_column = _.find(self.props.vehicle_columns, { name: tab_property.name });

			if (typeof vehicle_column !== 'undefined') {
				const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

				return <td className="text-center" key={`form_${vehicle_column.name}_${vehicle.id}`}>
					<label className="text-capitalize">{vehicle_column.name}</label>
					<VehicleColumn
						name={vehicle_column.name}
						label={vehicle_column.name}
						onChange={this.onChange}
						value={colObject.value || ''}
						type={vehicle_column.input_name}
						error=""
						options={vehicle_column.options}
						vehicle={vehicle}
						checked={colObject.value || ''}
						updateType="vehicle"
						key={`vehicle_${vehicle.id}tab_property${tab_property.id}`}
						vehicle_id={vehicle.id}
						data_id={colObject.id || ''}
						uid={colObject.uid}
						user_name={colObject.user_name}
						updated_at={colObject.updated_at}
						icon={vehicle_column.icon}

					/>
           </td>;
			}
		});

		return html;
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const top_checkboxes = this.getTopCheckboxes();
		const other_checkboxes = this.getOtherCheckboxes();
		const get_tires = this.getTires();

		return (<div>
			<div className="container">
				<div className="row pad-top-1">
					{top_checkboxes}
				</div>
			</div>

			<div className="row bg-dark pad-top-1" />

			<div className="container space-top-1">
				<div className="row">

					<table className="table">
						<thead>
							<tr>
								<td>

									<div className="vehicleInfo text-capitalize text-center">
										{this.vehicleProperty('year')}
										{' '}
										{this.vehicleProperty('make')}
										{' '}
										{this.vehicleProperty('model')}
										<br />
										{' '}
(
										{vehicle.stock}
)
										<br />
										<mark>{vehicle.dealership}</mark>

									</div>

								</td>

								{other_checkboxes}
							</tr>

						</thead>
					</table>
				</div>
			</div>
			<div className="row bg-dark pad-top-1" />
			<div className="container space-top-1">

				<div className="row">

					<table className="table">
						<thead>
							<tr>

								{get_tires}
							</tr>

						</thead>
					</table>
				</div>
			</div>

			<div className="row bg-dark pad-top-1" />
			<div className="card space-top-1">

				<div className="card-text">
					<div className="d-flex flex-wrap">
						<button className="btn btn-sm btn-success" onClick={self.onClick}>Add Work Item</button>
						<table className="table table-striped">
							<thead className={'thead text-center'}>
								<tr>
									<td className="text-center">#</td>
									{estimateFields.map(option => <th key={`estimate_${option}`} className="text-center">{option}</th>)}
								</tr>
							</thead>
							<tbody>

								{vehicle.appraisals.map(estimate => <SuperFormWorkItem key={`superform_work_item_single_${estimate.id}`} {...this.props} estimate={estimate} vehicle={vehicle} />)}

							</tbody>
						</table>
					</div>

				</div>
				<div className="card-footer" />
			</div>
		</div>
		);
	}
}

const mappedActions = {
	addEstimate,
};

function mapStateToProps(state) {
	return {
		all_tabs: state.settings_site.all_tabs,
		vehicle_columns: state.settings_departments.vehicle_columns,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}

export default connect(mapStateToProps, mappedActions)(SuperForm);
