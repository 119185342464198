import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';
import Select from '../../../../inputs/Dropdown';
import FileUploadButton from '../../../../inputs/FileUploadButton';
import {
	getDateTime,
	userStamp,
} from '../../../../utils/dateFunctions';

import {
	deleteWorkItem,
	updateUviItem,
	updateUviItemRaw,
} from '../../../../actions/apiWorkItems';
import {
	sendEstimate,
} from '../../../../actions/apiEstimates';
import {
	activeVehicle,
} from '../../../../actions/lookups';

class AutomaxItem extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			addDeleteEstimateError: '',
			vehicle_id: this.props.active_vehicle.id,
			description: this.props.work_item.description,
			part_number: this.props.work_item.part_number,
			qty: this.props.work_item.qty,
			labor: this.props.work_item.labor || 0,
			parts: this.props.work_item.parts || 0,
			labor_hours: this.props.work_item.labor_hours || 0,
			total: this.props.work_item.total || 0,
			contact_recon: this.props.work_item.contact_recon,
			active: this.props.work_item.active,
			contact_tech: this.props.work_item.contact_tech,
		};
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
	}

	componentDidMount() {
		if (this.state.active === false) {
			this.updateDecline();
		}
	}

	onChangeTextArea(event) {
		this.setState({ value: event.target.value });
	}

	onChangeSelect(e, part_number, qty, parts, labor_hours, labor, total, contact_recon) {
		const self = this;
		const errorType = 'updateUviItemError';
		const { id } = self.props.work_item;

		const data = {
			id,
			vehicle_id: this.props.active_vehicle.id,
			description: e,
			part_number,
			qty,
			parts,
			labor_hours,
			labor,
			total,
			contact_recon,
		};

		this.setState({
			part_number, description: e, qty, labor_hours, labor, total, parts, contact_recon,
		});

		this.props.updateUviItem(id, data, errorType);
	}

	vehicleProperty(name) {
		const self = this;

		const value = _.filter(self.props.vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onBlur(e) {
		this.setState({ [e.target.name]: e.target.value });
		const self = this;
		const errorType = 'updateUviItemError';
		const { id } = self.props.work_item;
		const { value } = e.target;
		const work_item = this.props.work_item;

		if (e.target.name === 'total') {
			document.getElementById(`sublet_${work_item.id}`).setAttribute('data-total', parseFloat(value));
		}

		const data = {
			id,
			vehicle_id: this.props.active_vehicle.id,
			[e.target.name]: value,
		};

		this.props.updateUviItemRaw(id, data, errorType);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onDelete(e) {
		Promise.all([this.props.deleteWorkItem(this.props.work_item.id, 'addDeleteEstimateError')]).then(() => {
			this.props.activeVehicle(this.props.active_vehicle.id);
		});
	}

	subletOptions() {
		const select_options = [
			{
				name: 'Front Bumper', part_number: 'Sublet Item', id: 1, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			},
			{
				name: 'D/S Fender', part_number: 'Sublet Item', id: 2, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'D/S Front Door', part_number: 'Sublet Item', id: 3, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'D/S Quarter', part_number: 'Sublet Item', id: 4, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'D/S Quarter Cab', name: 'Touchup', part_number: 'Sublet Item', id: 5, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'D/S Rear Door', part_number: 'Sublet Item', id: 6, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Deal Adjustment', part_number: 'Sublet Item', id: 7, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Front Bumper', part_number: 'Sublet Item', id: 8, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Gas Bill', part_number: 'Sublet Item', id: 9, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Key Programming', part_number: 'Sublet Item', id: 10, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Over All', part_number: 'Sublet Item', id: 11, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'P/S Fender', part_number: 'Sublet Item', id: 12, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'P/S Fron Door', part_number: 'Sublet Item', id: 13, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'P/S Quarter', part_number: 'Sublet Item', id: 14, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'P/S Rear Door', part_number: 'Sublet Item', id: 15, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Rear Bumper', part_number: 'Sublet Item', id: 16, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Rear Spoiler', part_number: 'Sublet Item', id: 17, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Rocker Panel', part_number: 'Sublet Item', id: 18, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Roof', part_number: 'Sublet Item', id: 19, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Trunk', part_number: 'Sublet Item', id: 20, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}, {
				name: 'Custom', part_number: 'Sublet Item', id: 21, qty: 1, parts: '', labor_hours: '', labor: '', total: '150', item_type: 'sublet',
			}];

		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.props.work_item.name === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected, parts: option.parts, qty: option.qty, labor_hours: option.labor_hours, labor: option.labor, total: option.total, part_number: option.part_number,
			};
			return obj;
		});
		return options || [];
	}

	render() {
		self = this;
		const vehicle = this.props.active_vehicle;
		var setdisabledService = this.props.setdisabled;

		const { work_item } = self.props;
		const item_id = work_item.id;

		return (
			<tr className={this.state.active ? 'text-center' : 'text-center strikeout'} id={`work_item_row_${item_id}`}>
				<td className="text-left" id={`work_item_description_${item_id}`}>
					{this.state.description !== 'Custom' ? <Select
						titleHelper={''}
						title={'Choose Item'}
						onChange={this.onChangeSelect}
						value={this.state.description || ''}
						name={'description'}
						disabled={this.props.setdisabled ? 'disabled' : ''}
						list={this.subletOptions()}
						update_type={'uvi_form_vehicle'}
					/> : <input
						onChange={this.onChange}
						onBlur={this.onBlur}
						type={'text'}
						name={'contact_tech'}
						value={this.state.contact_tech}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>}
				</td>
				<td className={`sublet_${vehicle.id}`} id={`sublet_${work_item.id}`} data-total={parseFloat(this.state.total)}>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						type={'text'}
						name={'total'}
						value={parseFloat(this.state.total)}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
				</td>
				<td>{!setdisabledService ? <button className="btn btn-sm btn-danger" onClick={self.onDelete}>X</button> : ''}</td>

			</tr>
		);
	}
}

const mappedActions = {
	deleteWorkItem,
	activeVehicle,
	updateUviItem,
	updateUviItemRaw,
	sendEstimate,
};

function mapStateToProps(state) {
	return {
		all_tabs: state.settings_site.all_tabs,
		vehicle_columns: state.settings_departments.vehicle_columns,
		installation: state.settings_dealerships.installation,
		current_user: state.current_user,
		vehicle_filter: state.settings_nav.vehicle_filter,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(AutomaxItem);
