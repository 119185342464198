import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeleteDepartmentPropertyButton from './DeleteDepartmentPropertyButton';
import EditVehicleColumnButton from './EditVehicleColumnButton';
import {
	setCurrentTab,
} from '../../actions/apiSetInfo';
import {
	activeEditVehicleColumn,
} from '../../actions/lookups';

class SortableColumns extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: this.props.department_properties,
		};
		this.onDragEnd = this.onDragEnd.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	onDragEnd(item) {
		const errorType = 'updateDepartmentPropertyOrder';

		const id = this.props.edit_department.id;
		const items = [];
		this.props.updateDepartmentPropertyOrder(id, items, errorType);
	}

	onClick(e) {
		Promise.all([this.props.activeEditVehicleColumn(this.props.item.vehicle_column.id),
		]).then(() => this.props.setCurrentTab('default-department-columns'));
	}

	render() {
		const item = this.props.item || [];

		return (
			<li
				className={'list-group-item text-capitalize '}
				data-columnid={item.id}
				data-departmentid={item.department_id}
			>
				<span className="badge badge-default"><i className="fa fa-arrows float-start" /></span>
				{' '}
				{item.vehicle_column.name}
{/*
				<button className="btn btn-sm btn-primary float-end" onClick={this.onClick} name="default-department-columns">
					<i className="fa fa-edit float-end" />
					{' '}
				</button>
		*/}
				<DeleteDepartmentPropertyButton {...this.props} departmentid={item.department_id} columnid={item.id} />

			</li>
		);
	}
}

const mappedActions = {
	setCurrentTab,
	activeEditVehicleColumn,
};

function mapStateToProps(state) {
	return {
		edit_department: state.settings_admin.edit_department,
		department_properties: state.settings_admin.department_properties || [],
	};
}
export default connect(mapStateToProps, mappedActions)(SortableColumns);
