import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddAudit from './AddAudit';
import AuditTable from './AuditTable';
import { getAudits } from '../../actions/apiGetInfo';

class Audits extends Component {
	constructor(props) {
		super(props);

		this.state = { username: '', password: '' };
	}

	componentDidMount() {
		this.props.getAudits();
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card space-top-0">
					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">
						<div className="col-md-12 pad-bottom-1 text-center">
							<h3 className="text-center">
Audits
								<AddAudit {...this.props} />
							</h3>

							{this.props.audits.length > 0 ? <AuditTable {...this.props} /> : ''}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	getAudits,
};

function mapStateToProps(state) {
	return {
		audits: state.settings_refresh.audits,
	};
}
export default connect(mapStateToProps, mappedActions)(Audits);
