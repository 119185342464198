import React, { Component } from 'react';
import { connect } from 'react-redux';

const DeleteDepartmentWorkFlowButton = ({ property_id, onClick }) => (
	<button className="btn btn-sm btn-danger float-end" onClick={() => onClick(property_id)}>
		<i className="fal fa-minus-square float-end" />
	</button>
);

export default DeleteDepartmentWorkFlowButton;
