import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

class NewDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listOpen: false,
			headerTitle: this.props.title,
			list: this.props.list,
		};
		this.toggleList = this.toggleList.bind(this);
		this.close = this.close.bind(this);
		this.resetThenSet = this.resetThenSet.bind(this);
	}

	componentDidUpdate() {
		const { listOpen } = this.state;
		setTimeout(() => {
			if (listOpen) {
				window.addEventListener('click', this.close);
			} else {
				window.removeEventListener('click', this.close);
			}
		}, 0);
	}

	componentDidMount() {
		if (this.props.value) {
			var self = this;
			var index = _.findIndex(self.state.list, { value: this.props.value });

			var { list } = self.state;

			list.forEach((item, ind) => {
				if (index !== ind) {
					return item;
				}
				self.setState({
					headerTitle: item.label,
				});
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.close);
	}

	close(timeOut) {
		this.setState({
			listOpen: false,
		});
	}

	selectItem(title, id) {
		this.setState({
			headerTitle: title,
			listOpen: false,
		}, this.resetThenSet(id));
	}

	toggleList() {
		this.setState(prevState => ({
			listOpen: !prevState.listOpen,
		}));
	}

	resetThenSet(id) {
		const self = this;
		const index = _.findIndex(self.state.list, { id: parseInt(id) });
		let headerTitle = this.props.title;
		const { list } = self.state;
		list.forEach(item => item.selected = false);
		const newObj = list.map((item, ind) => {
			if (index !== ind) {
				return item;
			}
			item.selected = true;
			headerTitle = item.label;
			this.props.onChange(item);
			return item;
		});

		this.setState({
			list: newObj,
			headerTitle,
		});
	}

	render() {
		const { list } = this.state;
		const { listOpen, headerTitle } = this.state;
		const { disabled } = this.props;

		return (
			<div className="dd-wrapper" key={`select_me${this.props.vehicle_id}_${this.props.department_id}`} tabIndex={this.props.tabindex}>
				<div className="dd-header" onClick={this.toggleList}>
					<div className="dd-header-title">{headerTitle}</div>
					<div className="text-muted">
						{listOpen
							? <i className="fal fa-angle-up text-muted" />
							: <i className="fal fa-angle-down text-muted" />}
					</div>
				</div>
				{!disabled && listOpen && <ul className={list.length > 10 ? 'dd-list scroll' : 'dd-list'} onClick={e => e.stopPropagation()}>
					{list.map(item => (
						<li className={item.selected ? 'dd-list-item selected' : 'dd-list-item'} key={item.id} onClick={() => this.selectItem(item.label, item.id)}>
							{item.label}
							{' '}
							{item.selected && <i className="fas fa-check float-right" />}
						</li>
					))}
				</ul>}
			</div>
		);
	}
}

const mappedActions = {};

export default connect(undefined, mappedActions)(NewDropdown);
