import React, { Component } from 'react';
import { connect } from 'react-redux';

class VehicleCount extends Component {
	constructor(props) {
		super(props);
		this.state = { };
	}

	render() {
		const badgeStyle = {
			background: this.props.department.badge_background_color,
			backgroundColor: this.props.department.badge_background_color,
			color: this.props.department.badge_text_color,
		};

		return (
			<span className="badge badge-primary float-end" style={badgeStyle}>{this.props.count}</span>
		);
	}
}

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps)(VehicleCount);
