import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as AllMutates from './AllMutates';

export function updateEmailReport(id, data, errorType) {
	return dispatch => Api.patchData(`email_reports/${id}`, data).then(() => {
		dispatch(getInfo.getEmailReports());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addEmailReport(data, errorType) {
	return dispatch => Api.postData('email_reports/', data).then(() => {
		dispatch(getInfo.getEmailReports());

		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function runEmailReport(id, errorType) {
	return dispatch => Api.get(`email_reports?q[id_runemailreports]=${id}`, '').then((resp) => 'success').catch((ex) => {
		const errorType = 'email_reports';
		return Api.handleApiError(ex, errorType);
	});
}

export function deleteEmailReport(id, errorType) {
	return dispatch => Api.delete(`email_reports/${id}`).then(() => {
		dispatch(getInfo.getEmailReports());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}
