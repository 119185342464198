import * as apiDealerUpdates from './apiDealerUpdates';
import * as apiDepartmentUpdates from './apiDepartmentUpdates';
import * as apiEstimates from './apiEstimates';
import * as apiWorkItems from './apiWorkItems';
import * as apiPartOrders from './apiPartOrders';
import * as apiAttachments from './apiAttachments';
import * as apiVehicleNotes from './apiVehicleNotes';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as apiNotifications from './apiNotifications';
import * as AuthenticationActions from './authentication';
import * as departments from './departments';
import * as toggleMenu from './toggleMenu';
import * as users from './users';
import * as apiFilters from './apiFilters';
import * as vinScanner from './vinScanner';
import * as lookups from './lookups';
import * as AllUpdates from './AllUpdates';
import * as apiEmailReports from './apiEmailReports';
import * as apiReports from './apiReports';
import * as apiWorkFlows from './apiWorkFlows';
import * as apiAudits from './apiAudits';
import * as AllMutates from './AllMutates';

export const ActionCreators = {
	...apiDealerUpdates,
	...apiDepartmentUpdates,
	...apiEstimates,
	...apiPartOrders,
	...apiVehicleNotes,
	...getInfo,
	...setInfo,
	...apiNotifications,
	...AuthenticationActions,
	...departments,
	...toggleMenu,
	...users,
	...apiAttachments,
	...vinScanner,
	...lookups,
	...apiFilters,
	...apiWorkItems,
	...AllUpdates,
	...AllMutates,
	...apiEmailReports,
	...apiWorkFlows,
	...apiAudits,
	...apiReports,
};

export default 'ActionCreators';
