import findIndex from 'lodash/findIndex';
import _ from 'lodash';
import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const initialState = {
	input_types: [],
	allowed_departments: [],
	department_counts: [],
	departments: [],
	dealership_departments: [],
	original_departments: [],
	vehicle_columns: [],
	dealership_columns: [],
	work_flows: [],
	department: [],
	data_step_targets: [],
};

export const settings_departments = createReducer(initialState, {
	[types.SET_INPUT_TYPES](state = initialState, action) {
		return {
			...state,
			input_types: _.sortBy(action.input_types, 'name'),
		};
	},
	[types.SET_VEHICLE_COLUMNS](state = initialState, action) {
		return {
			...state,
			vehicle_columns: _.sortBy(action.vehicle_columns, 'name'),
		};
	},
	[types.SET_CURRENT_DEPARTMENT](state = initialState, action) {
		return {
			...state,
			department: action.department,
		};
	},
	[types.SET_DEALERSHIP_COLUMNS](state = initialState, action) {
		return {
			...state,
			dealership_columns: action.dealership_columns,
		};
	},
	[types.SET_ALLOWED_DEPARTMENTS](state = initialState, action) {
		return {
			...state,
			allowed_departments: _.sortBy(action.departments, 'order'),
		};
	},
	[types.SET_DATA_STEP_TARGETS](state = initialState, action) {
		return {
			...state,
			data_step_targets: action.data_step_targets,
		};
	},
	[types.SET_DEPARTMENTS](state = initialState, action) {
		return {
			...state,
			departments: _.sortBy(action.departments, 'order'),
		};
	},
	[types.SET_DEALERSHIP_DEPARTMENTS](state = initialState, action) {
		return {
			...state,
			dealership_departments: _.sortBy(action.dealership_departments, 'order'),
		};
	},
	[types.SET_ORIGINAL_DEPARTMENTS](state = initialState, action) {
		return {
			...state,
			original_departments: _.sortBy(action.departments, 'order'),
		};
	},
	[types.SET_DEPARTMENT_COUNTS](state = initialState, action) {
		return {
			...state,
			department_counts: action.departments,
		};
	},
	[types.SET_WORK_FLOWS](state = initialState, action) {
		return {
			...state,
			work_flows: _.sortBy(action.work_flows, 'name'),
		};
	},
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			input_types: [],
			allowed_departments: [],
			department_counts: [],
			departments: [],
			dealership_departments: [],
			original_departments: [],
			vehicle_columns: [],
			dealership_columns: [],
			work_flows: [],
			department: [],
			data_step_targets: [],
		};
	},
});

export default 'settings_departments';
