import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import EditDepartmentSettings from '../../inputs/EditDepartmentSettings';
import AddFilter from '../AllFilters/AddFilter';
import AddDepartmentFilterButton from '../AllFilters/AddDepartmentFilterButton';
import EditFilterButton from '../AllFilters/EditFilterButton';
import EditFilter from '../../inputs/EditFilter';
import { deleteDepartmentFilter, addDepartmentFilter } from '../../actions/apiFilters';
import DropdownMultiple from '../../inputs/DropdownMultiple';
import { setEditFilter } from '../../actions/apiSetInfo';
import Dropdown from '../../inputs/Dropdown';

const activeOptions = ['true', 'false'];

class TabEditDepartmentFilters extends Component {
	constructor(props) {
		super(props);

		this.state = { value: this.props.value || '', checked: this.props.checked || '' };
		this.onClick = this.onClick.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.deleteDepartmentFilter = this.deleteDepartmentFilter.bind(this);
		this.onClose = this.onClose.bind(this);
	}

	onClose() {
		this.props.setEditFilter();
	}

	onClick(e) {
		const self = this;
		const data = {
			department_id: parseInt(self.props.edit_department.id),
			all_filter_id: parseInt(e),
		};
		const errorType = 'add department filter';

		self.props.addDepartmentFilter(data, errorType);
	}

	onEdit(e) {
		const self = this;
		self.props.setEditFilter(e);
	}

	department() {
		const self = this;
		const department = _.sortBy(_.filter(self.props.departments, o => o.id == self.props.edit_department), 'id');
		return department[0] || [];
	}

	deleteDepartmentFilter(e) {
		const data = {
			department_id: parseInt(e.target.dataset.department),
			id: parseInt(e.target.dataset.id),
		};
		const errorType = 'delete department filter';

		this.props.deleteDepartmentFilter(data, errorType);
	}

	allFilters() {
		const all_filters = _.sortBy(this.props.all_filters, 'filter_target');

		const options = all_filters.map(option => <li key={`${option.id}_filter`} className="list-group-item">
			<AddDepartmentFilterButton name={option.id} onClick={this.onClick} />
			<strong>{option.filter_name}</strong>
			{this.props.edit_filter !== option.id ? <EditFilterButton onClick={this.onEdit} name={option.id} /> : <button className="btn btn-sm btn-danger float-end" onClick={this.onClose}>Close</button>}
			{this.props.edit_filter === option.id ? <div className={'col-md-12'}><EditFilter {...this.props} item={option} /></div> : ''}
                                                       </li>);
		return options;
	}

	selectDepartments() {
		const department = this.props.edit_department || [];
		const selected_departments = [];

		selected_departments.push(department.id);

		const options = this.props.original_departments.map((option) => {
			let selected = false;
			const dataid = 0;
			if (selected_departments.includes(option.id)) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, selected,
			};
			return obj;
		});
		return options;
	}

	render() {
		const self = this;
		const department = self.props.edit_department;
		const filter_list = [];
		if (self.props.edit_department && typeof self.props.edit_department.department_filters !== 'undefined') {
			self.props.edit_department.department_filters.map(department_filter => filter_list.push(<div className={'card'} key={`filter_id_${department_filter.all_filter.id}`}>
				<div className={'card-header bg-dark text-white col-md-12 text-capitalize'}>
					{department_filter.all_filter.filter_name}
					<button data-id={department_filter.id} data-department={department.id} onClick={this.deleteDepartmentFilter} className={'btn btn-sm btn-danger float-end'}><i data-id={department_filter.id} data-department={department.id} onClick={this.deleteDepartmentFilter} className="fal fa-minus-square" /></button>
				</div>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<label className={'pad-left-2'}><strong>Target Data:</strong></label>
					</div>
					<div className={'col-md-6'}>
						{department_filter.all_filter.filter_target}
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<label className={'pad-left-2'}><strong>FilterType:</strong></label>
					</div>
					<div className={'col-md-6'}>
						{department_filter.all_filter.filter_type}
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<label className={'pad-left-2'}><strong>DataSource:</strong></label>
					</div>
					<div className={'col-md-6'}>
						{department_filter.all_filter.data_origin}
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<label className={'pad-left-2'}><strong>Operator:</strong></label>
					</div>
					<div className={'col-md-6'}>
						{department_filter.all_filter.filter_operator}
					</div>
				</div>
				<div className={'row'}>
					<div className={'col-md-6'}>
						<label className={'pad-left-2'}><strong>Equals:</strong></label>
					</div>
					<div className={'col-md-6'}>
						{department_filter.all_filter.filter_value}
					</div>
				</div>
			</div>));
		}
		return (
			<div className="col-md-12">
				<div className="navbar">
					<div className="col-md-6">
						<Dropdown
							{...this.props}
							titleHelper="Department"
							title={department.name ? department.name : 'Select Department'}
							list={this.selectDepartments()}
							update_type={'set_edit_department'}
						/>
					</div>
				</div>
				<h5 className="text-success text-center">
Filters.
					<br />
					{' '}
					<small className="text-warning text-center">Contact support if you need any assistance.</small>
				</h5>
				<div className={self.props.edit_department.id ? 'card space-top-1' : 'd-none'}>
					<div className="card-header text-capitalize">
						<button className="btn btn-dark form-control">
Edit
							{department.name}
						</button>
					</div>
					<div className="card-text row">
						<div className="col-md-6">
							<div className="card-header text-capitalize text-center">
								<strong>
Filters Applied to
									{department.name}
								</strong>
							</div>
							<div className="card-body">

								{filter_list}

							</div>
						</div>
						<div className="col-md-6">
							<div className="card-header row text-white text-capitalize">
								<AddFilter {...this.props} department={department} />
							</div>
							<div className="card-body row">
								<ul className="list-group col-md-12">
									{this.allFilters()}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	deleteDepartmentFilter,
	addDepartmentFilter,
	setEditFilter,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.original_departments,
		all_filters: state.settings_site.all_filters,
		edit_filter: state.settings_site.edit_filter,
		current_tab: state.settings_admin.current_tab,
		original_departments: state.settings_departments.original_departments,
		vehicle_columns: state.settings_departments.vehicle_columns,
		edit_department: state.settings_admin.edit_department,
		edit_departments: state.settings_admin.edit_departments,
		department_properties: state.settings_admin.edit_department_properties,
		input_types: state.settings_departments.input_types,
		dealerships: state.settings_admin.edit_locations,
		set_dealerships: state.settings_dealerships.related_installations,
	};
}
export default connect(mapStateToProps, mappedActions)(TabEditDepartmentFilters);
