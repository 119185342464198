import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import { addNewVehicleColumn } from '../../actions/apiDepartmentUpdates';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
};

class AddVehicleColumnButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, input_name: '', name: '', errors: { addVehicleColumnError: false },
		};
		this.onChange = this.onChange.bind(this);
		this.onChangeSelectInputType = this.onChangeSelectInputType.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.addNewVehicleColumn = this.addNewVehicleColumn.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeSelectInputType(e) {
		this.setState({ input_name: e.value });
	}

	onClick(e) {

	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	input_types() {
		const newObj = Object.keys(this.props.input_types).map(key => this.props.input_types[key]);
		return _.sortBy(newObj, 'name');
	}

	vehicle_columns() {
		const newObj = Object.keys(this.props.vehicle_columns).map(key => this.props.vehicle_columns[key]);
		return _.sortBy(newObj, 'name');
	}

	selectColumns() {
		const columns = this.vehicle_columns();

		const options = columns.map((option) => {
			const obj = { value: option.id, label: option.name };
			return obj;
		});
		return options;
	}

	inputColumns() {
		const input_types = this.input_types();
		const options = input_types.map((option) => {
			const obj = { value: option.name, label: `${option.name} ( ${option.description} )` };
			return obj;
		});
		return options;
	}

	deleteDepartment(e) {
		this.props.deleteDepartment(e.target.dataset.id);
	}

	addNewVehicleColumn() {
		const { input_name } = this.state;
		const { name } = this.state;
		const errorType = 'add new vehicle column';
		const data = {
			name,
			input_name,
		};

		this.props.addNewVehicleColumn(data, errorType).then((resp) => {
			if (resp == 'success') {
				this.setState({ errors: { addDepartmentPropertyError: false } });
				this.setState({ newdepartmentproperty: '' });
				this.closeModal();
			} else {
				const { errors } = resp;
				this.setState({ errors, addDepartmentPropertyError: true });
			}
		});
	}

	render() {
		const { errors } = this.state;
		return (
			<span className="float-end">
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					style={customStyles}
					contentLabel="Example Modal"
					newdepartment={this.state.newdepartment}
					setAppElement={'body'}
					ariaHideApp={false}
				>

					<div className="card col-md-6">
						<div className="card-header bg-darkgrey text-white">
                      Add Department Column
							{' '}
							<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>x</button>
						</div>
						<div className="card-text">

							<div className="p-2">
								<TextFieldGroup
									field="name"
									label="Column Name"
									onChange={this.onChange}
									value={this.state.name}
									type="text"
									error={errors.addVehicleColumnError}
								/>

							</div>
							<div className="p-2">
								<label>Input Type</label>
								<Select
									onChange={this.onChangeSelectInputType}
									value={this.inputColumns().filter(({ value }) => value === this.state.input_name)}
									name={'input_name'}
									options={this.inputColumns()}
									className="text-capitalize bigdropdown"
									clearable={false}
									searchable
									key={'input_name_add_type'}
									placeholder="Choose Input Type"
									error={errors.addVehicleColumnError}
								/>
							</div>

						</div>
						<div className="card-footer">
							<button className="btn btn-sm btn-success float-end" onClick={this.addNewVehicleColumn}>
								<i className="fa fa-plus" />
								{' '}
Add
							</button>
						</div>
					</div>
				</Modal>
				<button className="btn btn-sm btn-primary float-end" onClick={this.openModal}>
					<i className="fa fa-plus" />
					{' '}
Create New Column
					{' '}
					{this.props.department_name}
				</button>
			</span>
		);
	}
}

const mappedActions = {
	addNewVehicleColumn,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.original_departments,
		input_types: state.settings_departments.input_types,
		vehicle_columns: state.settings_departments.vehicle_columns,
		editDepartment: state.editDepartment,
	};
}
export default connect(mapStateToProps, mappedActions)(AddVehicleColumnButton);
