import React, { Component } from 'react';
import { connect } from 'react-redux';
import PermissionCheckbox from '../../inputs/PermissionCheckbox';
import DefaultProfileCheckbox from '../../inputs/DefaultProfileCheckbox';
import AddDefaultProfileButton from '../../inputs/AddDefaultProfileButton';
import {
	setCurrentTab,
	setCurrentDefaultProfile,
	setViewAllDepartments,
	setViewAllTabs,
	setMoveAllDepartments,
	unsetMoveAllDepartments,
	unsetViewAllDepartments,
	unsetViewAllTabs,
} from '../../actions/apiSetInfo';
import {
	addDefaultProfile,
} from '../../actions/users';
import {
	lookupProfileUsers,
	lookupProfileUsersCount,
} from '../../actions/lookups';

class DefaultProfiles extends Component {
	constructor(props) {
		super(props);

		this.state = { current_default_profile: this.props.current_default_profile, name: '' };
		this.onTabClick = this.onTabClick.bind(this);
		this.changePage = this.changePage.bind(this);
		this.onMenuClick = this.onMenuClick.bind(this);
		this.checkAll = this.checkAll.bind(this);
		this.checkAllTabs = this.checkAllTabs.bind(this);
		this.uncheckAll = this.uncheckAll.bind(this);
		this.uncheckAllTabs = this.uncheckAllTabs.bind(this);
		this.checkAllMove = this.checkAllMove.bind(this);
		this.uncheckAllMove = this.uncheckAllMove.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onAddDefaultProfile = this.onAddDefaultProfile.bind(this);
	}

	onAddDefaultProfile(e) {
		e.preventDefault();
		const name = this.state.name;
		const errorType = 'addDefaultProfile';
		const data = {
			name,
			active: 'true',
		};

		this.props.addDefaultProfile(data, errorType);
		this.setState({ name: '' });
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	changePage(e) {
		this.props.change_page(e.target.name);
	}

	onTabClick(e) {
		this.props.setCurrentTab(e.target.name);
	}

	onMenuClick(e) {
		this.props.setCurrentDefaultProfile(e.target.name);
	}

	checkAll(e) {
		this.props.setViewAllDepartments(this.props.current_default_profile.id);
	}

	checkAllTabs(e) {
		this.props.setViewAllTabs(this.props.current_default_profile.id);
	}

	checkAllMove(e) {
		this.props.setMoveAllDepartments(this.props.current_default_profile.id);
	}

	uncheckAllMove(e) {
		this.props.unsetMoveAllDepartments(this.props.current_default_profile.id);
	}

	uncheckAll(e) {
		this.props.unsetViewAllDepartments(this.props.current_default_profile.id);
	}

	uncheckAllTabs(e) {
		this.props.unsetViewAllTabs(this.props.current_default_profile.id);
	}

	showDepartments() {
		const newObj = Object.keys(this.props.departments).map(key => this.props.departments[key]);
		return _.sortBy(newObj, 'order');
	}

	default_profile() {
		const self = this;
		if (self.props.current_default_profile.id) {
			const default_profile = _.sortBy(_.filter(self.props.default_profiles, o => o.id == self.props.current_default_profile.id), 'id');
			return default_profile[0].profile_permissions || [];
		}
		return [];
	}

	getPermission(permission) {
		let permissionValue = '';
		const profile_permissions = this.default_profile();

		if (profile_permissions.length > 0) {
			profile_permissions.map((obj) => {
				if (obj.key == permission) {
					permissionValue = obj.value;
				}
			});
		}
		return permissionValue;
	}

	render() {
		const self = this;
		const { props } = this;
		const list_departments = [];
		const list_allowed_move_departments = [];
		const list_users = [];
		const list_tabs = [];

		if (self.props.current_default_profile.name) {
			this.showDepartments().map((department) => {
				if (department.id) {
					list_departments.push(<PermissionCheckbox {...props} key={`key_${department.id}`} checked={this.getPermission(`${department.name}`)} default_profile_id={this.props.current_default_profile.id} parentid={department.id} name={department.name} />);
				}
			});
		}

		if (self.props.current_default_profile.name) {
			this.showDepartments().map((department) => {
				if (department.department_type !== 'data step') {
					list_allowed_move_departments.push(<PermissionCheckbox {...props} key={`key_${department.id}`} checked={this.getPermission(`allowed_move_${department.name}`)} default_profile_id={this.props.current_default_profile.id} parentid={department.id} name={`allowed_move_${department.name}`} />);
				}
			});
		}

		if (self.props.current_default_profile.name) {
			this.props.all_tabs.map((tab) => {
				if (tab.active === true) {
					list_tabs.push(<PermissionCheckbox {...props} key={`tab_${tab.id}`} checked={this.getPermission(`${tab.name}`)} default_profile_id={this.props.current_default_profile.id} parentid={tab.id} name={tab.name} />);
				}
			});
		}

		if (self.props.current_default_profile.name) {
			this.props.lookupProfileUsers(self.props.current_default_profile.name).map((user) => {
				list_users.push(<li>
					<a className="list-group-item">
Name:
						{user.user_name}
						{' '}
						<span className="float-end">
Email:
							{user.email}
						</span>
					</a>
				</li>);
			});
		}

		return (

			<div className="content-box container-fluid bg-light">
				<div className="row space-left-0 space-right-0">
					<div className="col-md-3 pad-left-0 pad-right-0">
						<div className="col-md-12">
							<small>
								<i className={'fa fa-info-circle text-info'} />
								{' '}
Select a profile below to edit permissions and views
							</small>
						</div>
						<ul className="side-box list-group space-left-1 space-top-1">
							{this.props.default_profiles.map((profile) => {
								if (profile.active && profile.name !== 'admin' && profile.name !== 'super_admin') {
									return <li key={`${profile.id}_profile`}>
										<a className={(this.props.current_default_profile.id === profile.id ? 'list-group-item text-capitalize active' : 'list-group-item text-capitalize ')} name={profile.id} onClick={this.onMenuClick}>
											{profile.name.replace(/_/g, ' ')}
											{' '}
											<span className="badge badge-success float-end">{ this.props.lookupProfileUsersCount(profile.name) }</span>
										</a>
									</li>;
								}
							})}
						</ul>
					</div>
					<div className="col-md-9">
						<ul className="nav nav-pills nav-fill bg-light" role="tablist">
							<li className="nav-item space-bottom-half space-right-half">
								<a className={(this.props.current_tab === 'default-departments' ? 'nav-link active' : 'nav-link')} name="default-departments" onClick={this.onTabClick}>Department/Step Views</a>
							</li>
							<li className="nav-item space-bottom-half space-right-half">
								<a className={(this.props.current_tab === 'default-allowed-departments' ? 'nav-link active' : 'nav-link')} name="default-allowed-departments" onClick={this.onTabClick}>Allowed Move Destination</a>
							</li>
							<li className="nav-item space-bottom-half space-right-half">
								<a className={(this.props.current_tab === 'default-permissions' ? 'nav-link active' : 'nav-link')} name="default-permissions" onClick={this.onTabClick}>Permissions</a>
							</li>
							<li className="nav-item space-bottom-half space-right-half">
								<a className={(this.props.current_tab === 'default-tabs' ? 'nav-link active' : 'nav-link')} name="default-tabs" onClick={this.onTabClick}>Tab Permissions</a>
							</li>
							<li className="nav-item space-bottom-half space-right-half">
								<a className={(this.props.current_tab === 'default-profile-users' ? 'nav-link active' : 'nav-link')} name="default-profile-users" onClick={this.onTabClick}>Profile Users</a>
							</li>
							<li className="nav-item space-bottom-half space-right-half">
								<a className={(this.props.current_tab === 'default-add-profile' ? 'nav-link active' : 'nav-link')} name="default-add-profile" onClick={this.onTabClick}>Create New Profile</a>
							</li>
						</ul>
						<div className="container-fluid pad-left-0 pad-right-0 bg-light pad-top-1">

							<div className={(this.props.current_tab === 'default-departments' ? 'tab-box active' : 'tab-box')}>
								<h5 className={'text-success text-center'}>
Customize the Default Department and Step Views.
									<br />
									{' '}
									<small className={'text-warning text-center'}>Contact support if you need any assistance.</small>
								</h5>
								<div className="d-grid gap-2 d-md-block clearfix">
									{self.props.current_default_profile.name ? <button className="btn btn-success float-end space-left-2" onClick={this.checkAll}>Check All Departments</button> : ''}
									{' '}
									{self.props.current_default_profile.name ? <button className="btn btn-success float-end space-left-2" onClick={this.uncheckAll}>Uncheck All Departments</button> : ''}
								</div>
								{list_departments}
							</div>
							<div className={(this.props.current_tab === 'default-allowed-departments' ? 'tab-box active' : 'tab-box')}>
								<h5 className={'text-success text-center'}>
Restrict users to move vehicles to only these Department and Step Views.
									<br />
									{' '}
									<small className={'text-warning text-center'}>Contact support to turn on the restricted movement feature</small>
								</h5>
								<div className="d-grid gap-2 d-md-block clearfix">
									{self.props.current_default_profile.name ? <button className="btn btn-success float-end space-left-2" onClick={this.checkAllMove}>Check All Departments</button> : ''}
									{' '}
									{self.props.current_default_profile.name ? <button className="btn btn-success float-end space-left-2" onClick={this.uncheckAllMove}>Uncheck All Departments</button> : ''}
								</div>

								{list_allowed_move_departments}
							</div>
							<div className={(this.props.current_tab === 'default-permissions' ? 'tab-box active' : 'tab-box')}>
								<h5 className={'text-success text-center'}>
Customize the Default Profile Permissions.
									<br />
									{' '}
									<small className={'text-warning text-center'}>Contact support if you need any assistance.</small>
								</h5>

								{Object.keys(self.props.current_default_profile).map((key) => {
									if (key === 'id' || key === 'installation_id' || key === 'active' || key === 'global_profile' || key === 'name' || key === 'page_vehicles' || key === 'profile_permissions') {
										return '';
									}
									return <DefaultProfileCheckbox {...props} key={`permissions_${key}`} checked={self.props.current_default_profile[key]} parentid={self.props.current_default_profile.id} name={key} />;
								})}

							</div>
							<div className={(this.props.current_tab === 'default-tabs' ? 'tab-box active' : 'tab-box')}>
								<h5 className={'text-success text-center'}>
Tab Permssions
									<br />
									{' '}
									<small className={'text-warning text-center'}>Contact support if you need any assistance.</small>
								</h5>

								<div className="d-grid gap-2 d-md-block clearfix">
									{self.props.current_default_profile.name ? <button className="btn btn-success float-start space-left-2" onClick={this.checkAllTabs}>Check All Tabs</button> : ''}
									{' '}
									{self.props.current_default_profile.name ? <button className="btn btn-success float-start space-left-2" onClick={this.uncheckAllTabs}>Uncheck All Tabs</button> : ''}
								</div>

								{list_tabs}
							</div>
							<div className={(this.props.current_tab === 'default-profile-users' ? 'tab-box active' : 'tab-box')}>
								<h5 className={'text-success text-center'}>
Profile Users -
									{self.props.current_default_profile.name}
									<br />
									{' '}
									<small className={'text-warning text-center'}>Contact support if you need any assistance.</small>
								</h5>
								<ul className="list-group">
									{list_users}
								</ul>
							</div>
							<div className={(this.props.current_tab === 'default-all' ? 'tab-box active' : 'tab-box')}>
								<h5 className={'text-success text-center'}>
Compare all Profiles.
									<br />
									{' '}
									<small className={'text-warning text-center'}>Contact support if you need any assistance.</small>
								</h5>

                                  All
							</div>
							<div className={(this.props.current_tab === 'default-add-profile' ? 'tab-box active' : 'tab-box')}>
								<h5 className={'text-success text-center'}>
Create New Profile.
									<br />
									{' '}
									<small className={'text-warning text-center'}>Contact support if you need any assistance.</small>
								</h5>

								<AddDefaultProfileButton
									field={'name'}
									value={this.state.name}
									error={'AddDefaultProfileButton'}
									type={'text'}
									onChange={this.onChange}
									onClick={this.onAddDefaultProfile}
								/>
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	setCurrentTab,
	setCurrentDefaultProfile,
	setViewAllDepartments,
	setViewAllTabs,
	setMoveAllDepartments,
	unsetMoveAllDepartments,
	unsetViewAllDepartments,
	unsetViewAllTabs,
	addDefaultProfile,
	lookupProfileUsers,
	lookupProfileUsersCount,
};

function mapStateToProps(state) {
	return {
		default_profiles: state.settings_users.default_profiles,
		current_tab: state.settings_admin.current_tab,
		current_default_profile: state.settings_users.current_default_profile,
		departments: state.settings_departments.original_departments,
		all_tabs: state.settings_site.all_tabs,
		all_users: state.settings_users.all_users,
	};
}
export default connect(mapStateToProps, mappedActions)(DefaultProfiles);
