import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AddEmailReport from './AddEmailReport';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import { runEmailReport, updateEmailReport } from '../../actions/apiEmailReports';
import DeleteEmailReportButton from '../../inputs/DeleteEmailReportButton';
import DropdownMultiple from '../../inputs/DropdownMultiple';
import Dropdown from '../../inputs/NewDropdown';

class EmailReportSingle extends Component {
	constructor(props) {
		super(props);

		this.state = { report_choice: this.props.email_report.report_choices };
		this.runThisEmailReport = this.runThisEmailReport.bind(this);
		this.selectReportDealerships = this.selectReportDealerships.bind(this);
		this.selectReportChoices = this.selectReportChoices.bind(this);
		this.updateReportChoice = this.updateReportChoice.bind(this);
		this.selectReportUsers = this.selectReportUsers.bind(this);
		this.selectReportTimes = this.selectReportTimes.bind(this);
		this.selectReportDays = this.selectReportDays.bind(this);
	}

	runThisEmailReport() {
		this.props.runEmailReport(this.props.email_report.id, 'email run report');
	}

	selectReportDealerships() {
		const report_dealerships = this.props.email_report.report_dealerships.split(',') || [];
		const selected_dealerships = [];

		report_dealerships.map((option) => {
			selected_dealerships.push(option);
		});

		const options = this.props.related_installations.map((option) => {
			let selected = false;
			const dataid = this.props.email_report.id;
			if (selected_dealerships.includes(option.subdomain)) {
				selected = true;
			}

			var obj = {
					id: option.id, label: option.softname, subdomain: option.subdomain, selected, dataid,
				};

			return obj;
		});
		return options;
	}

	selectReportChoices() {
		const report_choices = this.props.report_choices;
		const dataid = this.props.email_report.id;
		const returnChoices = report_choices.map((option) => {
			let selected = false;
			if (option.name === this.props.email_report.report_choices) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = {
				 id: parseInt(option.id), value: option.name, label: option.title, selected, dataid,
				};
							} else {
								var obj = {
				 id: parseInt(option.id), value: option.name, label: option.title, selected, dataid,
				};
			}
			return obj;
		});

		returnChoices.push({ id: 0, label: 'None Selected' });
		return returnChoices || [];
	}

	updateReportChoice(e) {
		var name = e.value;
		this.setState({ report_choice: name });
		const data = {
			id: e.dataid,
			report_choices: name,
		};
		this.props.updateEmailReport(e.dataid, data, 'report_choices');
	}

	selectReportUsers() {
		const report_users = this.props.email_report.report_users.split(',') || [];
		const selected_users = [];

		report_users.map((option) => {
			selected_users.push(option);
		});

		var all_users = [];

		if (this.props.related_users.length > 0) {
			all_users = this.props.related_users;
		} else {
			all_users = this.props.all_users;
		}

		const options = all_users.map((option) => {
			let selected = false;
			const dataid = this.props.email_report.id;
			if (selected_users.includes(option.user_name)) {
				selected = true;
			}

			var obj = {
					id: option.id, label: option.user_name, user_name: option.user_name, selected, dataid,
				};

			return obj;
		});
		return options;
	}

	selectReportTimes() {
		const report_times = this.props.email_report.report_run_time.split(',') || [];
		const selected_times = [];

		report_times.map((option) => {
			selected_times.push(option);
		});

		var times = ['12:00 AM', '12:15 AM', '12:30 AM', '12:45 AM', '01:00 AM', '01:15 AM', '01:30 AM', '01:45 AM', '02:00 AM', '02:15 AM', '02:30 AM', '02:45 AM', '03:00 AM', '03:15 AM', '03:30 AM', '03:45 AM', '04:00 AM', '04:15 AM', '04:30 AM', '04:45 AM', '05:00 AM', '05:15 AM', '05:30 AM', '05:45 AM', '06:00 AM', '06:15 AM', '06:30 AM', '06:45 AM', '07:00 AM', '07:15 AM', '07:30 AM', '07:45 AM', '08:00 AM', '08:15 AM', '08:30 AM', '08:45 AM', '09:00 AM', '09:15 AM', '09:30 AM', '09:45 AM', '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM', '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM', '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM', '01:00 PM', '01:15 PM', '01:30 PM', '01:45 PM', '02:00 PM', '02:15 PM', '02:30 PM', '02:45 PM', '03:00 PM', '03:15 PM', '03:30 PM', '03:45 PM', '04:00 PM', '04:15 PM', '04:30 PM', '04:45 PM', '05:00 PM', '05:15 PM', '05:30 PM', '05:45 PM', '06:00 PM', '06:15 PM', '06:30 PM', '06:45 PM', '07:00 PM', '07:15 PM', '07:30 PM', '07:45 PM', '08:00 PM', '08:15 PM', '08:30 PM', '08:45 PM', '09:00 PM', '09:15 PM', '09:30 PM', '09:45 PM', '10:00 PM', '10:15 PM', '10:30 PM', '10:45 PM', '11:00 PM', '11:15 PM', '11:30 PM', '11:45 PM'];

		var i = 0;

		const options = times.map((option) => {
			let selected = false;
			const dataid = this.props.email_report.id;
			if (selected_times.includes(option)) {
				selected = true;
			}
			i++;

			var obj = {
					id: i, label: option, value: option, selected, dataid,
				};

			return obj;
		});
		return options;
	}

	selectReportDays() {
		const report_days = this.props.email_report.report_run_day.split(',') || [];
		const selected_days = [];

		report_days.map((option) => {
			selected_days.push(option);
		});

		var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'First Day of the Month', 'Last Day of the Month'];

		var i = 0;

		const options = days.map((option) => {
			let selected = false;
			const dataid = this.props.email_report.id;
			if (selected_days.includes(option)) {
				selected = true;
			}
			i++;

			var obj = {
					id: i, label: option, value: option, selected, dataid,
				};

			return obj;
		});
		return options;
	}

	render() {
		const { email_report } = this.props;

		return (
			<tr>
				<td><button type="button" className="btn btn-warning" data-id={email_report.id} onClick={this.runThisEmailReport}><i className={'fal fa-edit'} /></button></td>
				<td>{email_report.name}</td>
				<td><Dropdown
												{...this.props}
												titleHelper="Report Choice"
												title={'Select Report'}
												list={this.selectReportChoices()}
												update_type={'email_report_choice'}
												name="report_choice"
												onChange={this.updateReportChoice}
												value={this.state.report_choice}
				/></td>
				<td><DropdownMultiple
							{...this.props}
							titleHelper="Dealership"
							title="Dealership"
							list={this.selectReportDealerships()}
							update_type={'email_report_dealerships'}
							email_report={email_report}
				/></td>
				<td><DropdownMultiple
							{...this.props}
							titleHelper="Times"
							title="Times"
							list={this.selectReportTimes()}
							update_type={'email_report_times'}
							email_report={email_report}
				/></td>
				<td><DropdownMultiple
							{...this.props}
							titleHelper="Days"
							title="Days"
							list={this.selectReportDays()}
							update_type={'email_report_days'}
							email_report={email_report}
				/></td>
				<td><DropdownMultiple
							{...this.props}
							titleHelper="Users"
							title="Users"
							list={this.selectReportUsers()}
							update_type={'email_report_users'}
							email_report={email_report}
				/></td>
				<td><button type="button" className="btn btn-success" onClick={this.runThisEmailReport}><i className={'fal fa-play'} /></button></td>
				<td><DeleteEmailReportButton email_report={email_report} /></td>
			</tr>
		);
	}
}

const mappedActions = {
	runEmailReport,
	updateEmailReport,
};

function mapStateToProps(state) {
	return {
			 email_reports: state.settings_reports.email_reports,
			 related_installations: state.settings_dealerships.related_installations,
			 report_choices: state.settings_reports.report_types,
			 all_users: state.settings_users.all_users,
		     related_users: state.settings_users.related_users,
			};
}

export default connect(mapStateToProps, mappedActions)(EmailReportSingle);
