import * as types from '../../actions/types';

function getInitialState() {
  const LS_AUTHENTICATION = JSON.parse(localStorage.getItem('authentication'))
  return LS_AUTHENTICATION ? { ...LS_AUTHENTICATION, checkedLoginStatus: false } : {checkedLoginStatus: false}
}
const initialState = getInitialState()

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_USER_LOGIN_INFO:
      return {
        ...state,
        ...action.authentication,
        checkedLoginStatus: true,
      }
    case types.LOGOUT_USER:
      return {
        ...getInitialState(),
      }
    default:
      return state
  }
}
