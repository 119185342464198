import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';
import StepWorkFlow from '../StepWorkFlow';

class ReportDefaults extends Component {
	constructor(props) {
		super(props);

		this.state = { current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '' };
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	render() {
		return (
			<div className="col-md-12">

				<div className="card space-top-0">
					<StepWorkFlow />

					<h3 className="text-center pad-top-1">Recon is Complete Step Defaults</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<p>
							<i className={'fa fa-info-circle text-info'} />
							{' '}
For total recon times we need have a start and and end stage. We accomplish this by designating the default end stage for each work flow such as "New, Used, Wholesale, MISC".
						</p>
						<div className="card-body">

							<EditDealershipSettings {...this.props} type={'select-departments'} name="ra_lot_queue_new" label="Inventory Queue New (end stage)" value={this.dealerSettingValue('ra_lot_queue_new')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="ra_lot_queue_used" label="Inventory Queue Used (end stage)" value={this.dealerSettingValue('ra_lot_queue_used')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="ra_lot_queue_misc" label="Inventory Queue Misc (end stage)" value={this.dealerSettingValue('ra_lot_queue_misc')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="ra_lot_queue_misc2" label="Inventory Queue Misc2 (end stage)" value={this.dealerSettingValue('ra_lot_queue_misc2')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="ra_lot_queue_misc3" label="Inventory Queue Misc3 (end stage)" value={this.dealerSettingValue('ra_lot_queue_misc3')} />
							<EditDealershipSettings {...this.props} type={'select-departments'} name="ra_lot_queue_misc4" label="Inventory Queue Misc4 (end stage)" value={this.dealerSettingValue('ra_lot_queue_misc4')} />
						</div>
					</div>
					<h3 className="text-center pad-top-1">Set Min/Max time in steps</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text">

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'number'} name="site_limit_lower" label="Set Minimum Time (minutes) in a Step:" value={this.dealerSettingValue('site_limit_lower')} />

								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'number'} name="site_limit_upper" label="Set Maximum Time (days) in a Step:" value={this.dealerSettingValue('site_limit_upper')} />
								</div>
							</div>

							<div className="row">
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'number'} name="site_limit_recon_lower" label="Set Minimum Total Time (hours) in Recon:" value={this.dealerSettingValue('site_limit_recon_lower')} />
								</div>
								<div className="col-md-6">

									<EditDealershipSettings {...this.props} type={'number'} name="site_limit_recon_upper" label="Set Maximum Total Time (days) in Recon:" value={this.dealerSettingValue('site_limit_recon_upper')} />
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
	 };
}
export default connect(mapStateToProps)(ReportDefaults);
