import { createRoot } from 'react-dom/client';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import configureStore from './state/configureStore'
import App from './app.js';
import { getUser } from './actions/apiGetInfo';


// Sentry.init({
// 	dsn: 'https://f424ff8a4b6f463094c73c963881881a@o383427.ingest.sentry.io/5550011',
// 	autoSessionTracking: true,
// 	integrations: [
// 		new Integrations.BrowserTracing(),
// 	],
// 	tracesSampleRate: 1.0,
// });

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

export const { store } = configureStore({})
let authentication = JSON.parse(localStorage.getItem('authentication'))
if (authentication && authentication.access_token) {
	store.dispatch(getUser());
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
