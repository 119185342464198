import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import { getMonthDayYear } from '../../../../utils/dateFunctions';

class LoanerForm extends Component {
	constructor(props) {
		super(props);

		this.state = { addPartOrderError: '' };
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onPrint = this.onPrint.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
		};

		this.props.addPartOrder(data, 'addPartOrderError');
	}

	onPrint(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById(`loaner-form_${vehicle.stock}`);
		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 0, 0);
				    pdf.save(`loaner-form-${vehicle.stock}.pdf`);
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const errors = this.state;
		const arbitrationDeadline = _.find(vehicle.vehicle_properties, { key: 'arbitration deadline' }) || '';
		const safetyCheckCompletedBy = _.find(vehicle.vehicle_properties, { key: 'safety check completed by' }) || '';
		const waitingOnParts = _.find(vehicle.vehicle_properties, { key: 'waiting on parts' }) || '';
		const waitingOnPartsNotes = _.find(vehicle.vehicle_properties, { key: 'waiting on parts notes' }) || '';
		const clearedForDetail = _.find(vehicle.vehicle_properties, { key: 'cleared for detail' }) || '';
		const detailCompletedBy = _.find(vehicle.vehicle_properties, { key: 'detail completed by' }) || '';
		const qualityInspectedBy = _.find(vehicle.vehicle_properties, { key: 'quality inspected by' }) || '';
		const dateDetailCompleted = _.find(vehicle.vehicle_properties, { key: 'date detail completed' }) || '';
		const photosTaken = _.find(vehicle.vehicle_properties, { key: 'photos taken' }) || '';

		let logo = '';

		if (self.props.installation.logo) {
			logo = <img key="loaner-logo" src={self.props.installation.logo} name="dealerships" style={{ maxHeight: '40px', maxWidth: '150px', marginLeft: '10px' }} />;
		}

		let street1 = '';

		if (self.props.installation.street1) {
			street1 = self.props.installation.street1;
		}

		let city = '';

		if (self.props.installation.city) {
			city = self.props.installation.city;
		}

		let state = '';

		if (self.props.installation.state) {
			state = self.props.installation.state;
		}

		let zip = '';

		if (self.props.installation.zip) {
			zip = self.props.installation.zip;
		}

		let phone = '';

		if (self.props.installation.phone) {
			phone = self.props.installation.phone;
		}

		return (
			<div className="container">
				<button onClick={this.onPrint} className="btn btn-success float-right">Print</button>
				<div className="card" id={`recon_tracker_${vehicle.stock}`}>
					<div className="card" style={{ padding: '10px', width: '960px' }}>
						<div className="card-text"><h3 style={{ textAlign: 'center' }}>Loaner Form</h3></div>
						<div className="card-text">
							<div
								className="d-flex flex-wrap"
								style={{
									border: '1px solid black', padding: '10px', width: '95%', fontSize: '14px',
								}}
							>
								<div className="container" id={`loaner-form_${vehicle.stock}`}>
									<table className="loanFormTableContainer">
										<tr>
											<td>
												<table className="table">
													<tr>
														<td>
															{logo}
															{' '}
															{this.props.installation.softname}
															<p>
																{street1}
,
																<br />
																{city}
,
																{state}
																{' '}
																{zip}
																<br />
Telephone:
																{phone}
															</p>
														</td>
														<td>
															<table>
																<tr>
																	<td align="right">
Date:
																		<u>{getMonthDayYear()}</u>
																		<br />
																		{' '}
Mileage Out: ____________
																		<br />
																		{' '}
Mileage In: ____________
																		<br />
																		{' '}
STOCK:
																		<u>{vehicle.stock}</u>
																		<br />
																		{' '}
SALESPERSON: ____________
																	</td>
																</tr>
															</table>
														</td>
													</tr>
												</table>
											</td>
										</tr>
										<tr>
											<td>
												<table className="table" style={{ marginTop: 10 }}>
													<tr>
														<td className="loanLabel">Name:</td>
														<td style={{ borderBottom: 'thin solid black' }} colSpan="5">
															<input type="text" className="form-control" />
														</td>
													</tr>
													<tr>
														<td className="loanLabel">Address:</td>
														<td style={{ borderBottom: 'thin solid black' }} colSpan="5">
															<input type="text" className="form-control" />
														</td>
													</tr>
													<tr>
														<td className="loanLabel" style={{ textAlign: 'bottom' }}>City:</td>
														<td style={{ borderBottom: 'thin solid black' }}>
															<input type="text" />
														</td>
														<td>State:</td>
														<td style={{ borderBottom: 'thin solid black' }}>
															<input type="text" />
														</td>
														<td>Zip:</td>
														<td style={{ borderBottom: 'thin solid black' }}>
															<input type="text" />
														</td>
													</tr>
													<tr>
														<td className="loanLabel">Cell Phone:</td>
														<td style={{ borderBottom: 'thin solid black' }} colSpan="5">
															<input type="text" />
														</td>
													</tr>
													<tr>
														<td className="loanLabel">Home Phone:</td>
														<td style={{ borderBottom: 'thin solid black' }} colSpan="5">
															<input type="text" />
														</td>
													</tr>
												</table>

											</td>
										</tr>
										<tr>
											<td className="text-center">
												<table className="table text-center">
													<tr>
														<td><u>ABSOLUTELY NO SMOKING</u></td>
													</tr>
													<tr>
														<td>(CUSTOMER AGREES TO PAY $150 CHARGE TO REMOVE ODOR)</td>
													</tr>
													<tr>
														<td>
															<u>____________</u>
/
															<u>____________</u>
														</td>
													</tr>
													<tr>
														<td>(CUSTOMER INITIALS)</td>
													</tr>
												</table>
											</td>
										</tr>
										<tr>
											<td>
												<table className="text-center">
													<tr>
														<td align="left" style={{ paddingLeft: '70px' }}>
I, THE &nbsp;&nbsp;&nbsp;UNDERSIGNED, &nbsp;&nbsp;&nbsp;HEREBY, AGREE TO RENT FROM
															{this.props.installation.softname}
, one (1)
															<br />
															<input
																type="text"
																style={{
																	fontSize: '.8em', fontStyle: 'italic', width: '250px', display: 'inline-block',
																}}
																value={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
															/>
															{' '}
with serial number of:
															<input
																type="text"
																style={{
																	fontSize: '.8 em', fontStyle: 'italic', width: '250px', display: 'inline-block',
																}}
																value={vehicle.vin}
																onChange={this.onChange}
															/>
															{' '}
at
															<br />
a rate of $39 for ________ days. I understand that there is no insurance provided for me by
															{' '}
															{this.props.installation.softname}
															{' '}
of any kind while this vehicle is in my care, custody, and control. I further understand
															<br />
that I am responsible to the owner for any damage done to this vehicle while in my custody, care, and
															<br />
control. &nbsp;&nbsp;&nbsp;&nbsp;I &nbsp;&nbsp;&nbsp;&nbsp;carrry &nbsp;&nbsp;&nbsp;&nbsp;liability,&nbsp;&nbsp;&nbsp;&nbsp; collision,&nbsp;&nbsp;&nbsp;&nbsp; and&nbsp;&nbsp;&nbsp;&nbsp; comprehensive&nbsp;&nbsp;&nbsp;&nbsp; insurance &nbsp;&nbsp;&nbsp;&nbsp;with
															<br />
_______________________________ insurance&nbsp;&nbsp;&nbsp;&nbsp; company.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; My insurance &nbsp;&nbsp;&nbsp;&nbsp;Agent is
															<br />
_____________________, and has been notified to extend my coverage to this vehicle, while
															<br />
															{' '}
this vehicle is in my care, custody, and control. I certify that the car I am renting or loaned from
															{' '}
															{this.props.installation.softname}
															{' '}
has no damage at the beginning of the rental or loan except for any listed below.
														</td>
													</tr>
												</table>
											</td>
										</tr>
										<tr>
											<td>
												<table className="loanFormTableInfoSmall2" style={{ width: '50%', display: 'inline-block' }}>
													<tr>
														<td>
															<table>
																<tr>
																	<td className="smallLoanLabel">A)</td>
																	<td style={{ borderBottom: 'thin solid black' }}>
																		<input type="text" />
																	</td>
																</tr>
																<tr>
																	<td className="smallLoanLabel">B)</td>
																	<td style={{ borderBottom: 'thin solid black' }}>
																		<input type="text" />
																	</td>
																</tr>
																<tr>
																	<td className="smallLoanLabel">C)</td>
																	<td style={{ borderBottom: 'thin solid black' }}>
																		<input type="text" />
																	</td>
																</tr>
															</table>
														</td>
													</tr>
													<tr>
														<td style={{ paddingTop: '15px' }} />
													</tr>
													<tr>
														<td>
															<table>
																<tr>
																	<td className="loanLabel">Total Rental Due:</td>
																	<td style={{ borderBottom: 'thin solid black' }}>
																		<input type="text" />
																	</td>
																</tr>
															</table>
														</td>
													</tr>
													<tr>
														<td>
															<table>
																<tr>
																	<td style={{ borderBottom: 'thin solid black' }}>
																		<input type="text" />
																	</td>
																</tr>
																<tr>
																	<td>Managers Signature</td>
																</tr>
															</table>
														</td>
													</tr>
													<tr>
														<td>
															<table>
																<tr>
																	<td style={{ borderBottom: 'thin solid black' }}>
																		<input type="text" />
																	</td>
																</tr>
																<tr>
																	<td>Customers Signature</td>
																</tr>
															</table>
														</td>
													</tr>
												</table>
												<table cellPadding="1" className="loanFormTableInfo2" style={{ width: '50%', display: 'inline-block' }}>
													<tr>
														<td>
															<table>
																<tr>
																	<td style={{ borderBottom: 'thin solid black' }}>
																		<p>
I _____________________________(SALESPERSON)
																			<br />
                                                    DO HEREBY CERTIFY THAT I HAVE PERSONALLY
																			<br />
                                                    VERIFIED &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;THAT &nbsp;&nbsp;&nbsp;FULL &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;COVERAGE
																			<br />
                                                    INSURANCE &nbsp;&nbsp;&nbsp;IS&nbsp;&nbsp;&nbsp; ACTIVE&nbsp;&nbsp;&nbsp; ON &nbsp;&nbsp;&nbsp;THE &nbsp;&nbsp;&nbsp;ABOVE
																			<br />
                                                    CUSTOMER. I HAVE ALSO &nbsp;&nbsp;&nbsp;REVIEWED &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AND
																			<br />
                                                    PROVIDED THE CUSTOMER WITH A COPY OF THIS
																			<br />
                                                    RENTAL/LOANER AGREEMENT. THE CUSTOMER
																			<br />
                                                    IS&nbsp;&nbsp;&nbsp; AWARE &nbsp;&nbsp;&nbsp;OF &nbsp;&nbsp;&nbsp;ALL &nbsp;&nbsp;&nbsp;DETAILS OF &nbsp;&nbsp;&nbsp;THIS
																			<br />
                                                    RENTAL/LOANER AGREEMENT.
																			<br />
																			<span>
																				<u>
																					<input
																						type="date"
																						style={{
																							width: '150px', fontSize: '.8em', fontStyle: 'italic', display: 'inline',
																						}}
																						value=""
																					/>
																				</u>
																			</span>
(DATE)
																			<br />
																			<br />
________________________(SIGNATURE)
																		</p>
																	</td>
																</tr>
															</table>
														</td>
													</tr>
												</table>
											</td>
										</tr>
										<tr>
											<td className="text-center">
												<p className="bottomPageText">
Note: This rental is provided as a courtesy by
													{this.props.installation.softname}
. Please return vehicle in the condition it was
													<br />
given to you
												</p>
												<p className="bottomPageText2">
													{this.props.installation.softname}
													{' '}
DOES NOT SUPPLY GAS
													<br />
THANK YOU!
												</p>
											</td>
										</tr>
									</table>
								</div>
							</div>

						</div>
						<div className="card-footer" />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		installation: state.settings_dealerships.installation,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}

export default connect(mapStateToProps)(LoanerForm);
