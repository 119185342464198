import _ from 'lodash';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';

import {
	lookupDepartmentById,
	lookupUserById,
} from '../../actions/lookups';

import {
	getDateTime,
	readableStamp,
	returnAgeSeconds,
	getSecondsFromTimeRange,
	convertSecondsToTime,
	returnAgeFromTimestamp,
	numberWithCommas,
	returnHoursFromTimestamp,
	returnAgeFromDepartmentHistories,
	returnAgeFromDepartmentHistories_24_7,
	getSecondsFromTimeRange_24_5,
	getSecondsFromTimeRange_24_6,
	returnTabAge,
	returnTabAge_24_5,
	returnTabAge_24_6,
	returnTabAge_24_7,
	convertSecondsToDays,
} from '../../utils/dateFunctions';

class DownloadCSV extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
		};
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onDownloadButtonClick = this.onDownloadButtonClick.bind(this);
		this.onDownloadStepButtonClick = this.onDownloadStepButtonClick.bind(this);
		this.dealerSettingValue = this.dealerSettingValue.bind(this);
		this.returnTimeDisplay = this.returnTimeDisplay.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	dealerSettingValue(name) {
		const value = _.sortBy(_.filter(this.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	returnTimeDisplay(time_tab) {
		const vehicle = this.props.vehicle || [];
		const { completed_departments } = vehicle;
		const work_flows = this.props.work_flows;
		const { tab } = this.props;
		let vehicle_workflow = '';
		let plus_seconds = 0;
		let work_flow_properties = [];
		const end = new Date();
		let seconds = 0;

		switch (vehicle.stocktype.toUpperCase()) {
		case 'N':
			vehicle_workflow = this.dealerSettingValue('recon_new_work_flow');
			break;
		case 'U':
			vehicle_workflow = this.dealerSettingValue('recon_used_work_flow');
			break;
		case 'W':
			vehicle_workflow = this.dealerSettingValue('recon_wholesale_work_flow');
			break;
		default:
			vehicle_workflow = '';
		}

		if (vehicle_workflow !== '') {
			const work_flow = _.find(work_flows, { name: vehicle_workflow }) || '';

			if (work_flow.work_flow_properties.length > 0) {
				work_flow_properties = work_flow.work_flow_properties;
			}
		}

		switch (time_tab) {
		case 'age-24_7':
			seconds = getSecondsFromTimeRange(vehicle.created_at, end);
			break;
		case 'age-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.created_at, end);
			break;
		case 'age-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.created_at, end);
			break;
		case 'age-recononly-24_7':
			plus_seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			seconds = returnTabAge_24_7(completed_departments, work_flow_properties);
			break;
		case 'age-recononly-24_6':
			plus_seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			seconds = returnTabAge_24_6(completed_departments, work_flow_properties);
			break;
		case 'age-recononly-24_5':
			plus_seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			seconds = returnTabAge_24_5(completed_departments, work_flow_properties);
			break;
		case 'department-24_7':
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		case 'department-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			break;
		case 'department-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			break;
		case 'department-instance-24_7':
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		case 'department-instance-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			break;
		case 'department-instance-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			break;
		default:
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		}

		let days = 0;
		days = seconds + plus_seconds;

		if (days > (24 * 60 * 60)) {
			if (days > (24 * 60 * 60 * 365)) {
				var time = 0;
			} else {
				var time = (days / (24 * 60 * 60));
				time = `${parseInt(time)} days`;
			}
		} else {
			var time = (days / (60 * 60));
			time = `${parseInt(time)} hrs`;
		}
		return time;
	}

	onDownloadButtonClick() {
		const end = new Date();
		let vehicles = this.props.active_vehicles;

		if (this.props.sort_descending === true) {
			vehicles = _.sortBy(vehicles, this.props.sort_by_data).reverse();
		} else {
			vehicles = _.sortBy(vehicles, this.props.sort_by_data);
		}
		const headers = [];
		const headers2 = [];

		const columns = ['department', 'subdomain', 'days in bucket', 'total age (days)', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'certified'];
		columns.map((column) => {
			headers.push(column);
		});

		const columns2 = ['department', 'location', 'days in bucket', 'total age (days)', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'certified'];
		columns2.map((column) => {
			headers2.push(column);
		});

		const dealership_columns = this.props.dealership_columns || [];
		headers.push(...dealership_columns);
		headers2.push(...dealership_columns);

		columns.push(...dealership_columns);
		columns2.push(...dealership_columns);

		headers.push('notes');
		headers2.push('notes');

		columns.push('notes');
		columns2.push('notes');

		const full_csv = [];
		full_csv.push(headers2);

		const work_flows = this.props.work_flows;
		const work_flow = _.find(work_flows, { name: 'used' });
		let work_flow_properties = [];
		if (work_flow.work_flow_properties.length > 0) {
			work_flow_properties = work_flow.work_flow_properties;
		}

		vehicles.map((vehicle) => {
			const { completed_departments } = vehicle;
			const row = [];
			const department = this.props.lookupDepartmentById(vehicle.department_id);
			if (department) {
				var name = department.display_name || department.name || '';
			} else {
				var name = '';
			}

			columns.map((column) => {
				if (typeof column !== 'undefined') {
					if (column == 'department') {
						row.push(name.replace(/#|\}|\{|\"|\:/g, ' '));
					} else {
						let newcol = '';
						if (column === 'notes') {
							const notes = vehicle.vehicle_notes;
							notes.map((note) => {
								const userid = this.props.lookupUserById(note.uid);
								newcol += `${userid.user_name} - ${note.value.replace(/#/g, '')} | `;
							});
						}

						if (column === 'days in bucket') {
							const first_time_tab = this.dealerSettingValue('first_time_tab');
							var seconds = 0;
							var plus_seconds = 0;
							switch (first_time_tab) {
							case 'age-24_7':
								seconds = getSecondsFromTimeRange(vehicle.created_at, end);
								break;
							case 'age-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.created_at, end);
								break;
							case 'age-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.created_at, end);
								break;
							case 'age-recononly-24_7':
								plus_seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								seconds = returnTabAge_24_7(completed_departments, work_flow_properties);
								break;
							case 'age-recononly-24_6':
								plus_seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								seconds = returnTabAge_24_6(completed_departments, work_flow_properties);
								break;
							case 'age-recononly-24_5':
								plus_seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								seconds = returnTabAge_24_5(completed_departments, work_flow_properties);
								break;
							case 'department-24_7':
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							case 'department-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								break;
							case 'department-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								break;
							case 'department-instance-24_7':
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							case 'department-instance-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								break;
							case 'department-instance-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								break;
							default:
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							}

							newcol = convertSecondsToDays(seconds + plus_seconds);
						}

						if (column === 'total age (days)') {
							const second_time_tab = this.dealerSettingValue('second_time_tab');
							var seconds = 0;
							var plus_seconds = 0;
							switch (second_time_tab) {
							case 'age-24_7':
								seconds = getSecondsFromTimeRange(vehicle.created_at, end);
								break;
							case 'age-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.created_at, end);
								break;
							case 'age-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.created_at, end);
								break;
							case 'age-recononly-24_7':
								plus_seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								seconds = returnTabAge_24_7(completed_departments, work_flow_properties);
								break;
							case 'age-recononly-24_6':
								plus_seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								seconds = returnTabAge_24_6(completed_departments, work_flow_properties);
								break;
							case 'age-recononly-24_5':
								plus_seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								seconds = returnTabAge_24_5(completed_departments, work_flow_properties);
								break;
							case 'department-24_7':
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							case 'department-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								break;
							case 'department-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								break;
							case 'department-instance-24_7':
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							case 'department-instance-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								break;
							case 'department-instance-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								break;
							default:
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							}

							newcol = convertSecondsToDays(seconds + plus_seconds);
						}

						const colcheck = vehicle[column] || '';
						let col = [];
						let val = colcheck;

						if (colcheck == '') {
							col = _.find(vehicle.vehicle_properties, o => o.key === column) || [];
							val = col.value || '';
						}

						if (newcol.length > 0) {
							row.push(newcol);
						} else {
							row.push(val);
						}
					}
				}
			});
			full_csv.push(row);
		});

		const filename = `${this.props.current_department.name}_${getDateTime()}.csv`;

		const csvContent = `data:text/csv;charset=utf-8,${full_csv.map(e => e.join(',')).join('\n')}`;

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		this.setState({ modalIsOpen: false });
	}

	onDownloadStepButtonClick() {
		const end = new Date();
		let vehicles = this.props.active_vehicles;

		if (this.props.sort_descending === true) {
			vehicles = _.sortBy(vehicles, this.props.sort_by_data).reverse();
		} else {
			vehicles = _.sortBy(vehicles, this.props.sort_by_data);
		}
		const headers = [];
		const headers2 = [];

		const columns = ['department', 'subdomain', 'days in bucket', 'total age (days)', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'certified', 'notes'];
		columns.map((column) => {
			headers.push(column);
		});

		const columns2 = ['department', 'location', 'days in bucket', 'total age (days)', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'certified', 'notes'];
		columns2.map((column) => {
			headers2.push(column);
		});

		const full_csv = [];
		full_csv.push(headers2);

		const work_flows = this.props.work_flows;
		const work_flow = _.find(work_flows, { name: 'used' });
		let work_flow_properties = [];
		if (work_flow.work_flow_properties.length > 0) {
			work_flow_properties = work_flow.work_flow_properties;
		}

		vehicles.map((vehicle) => {
			const { completed_departments } = vehicle;
			const row = [];
			const department = this.props.lookupDepartmentById(vehicle.department_id);
			if (department) {
				var name = department.display_name || department.name;
			} else {
				var name = '';
			}

			columns.map((column) => {
				if (typeof column !== 'undefined') {
					if (column == 'department') {
						row.push(name);
					} else {
						let newcol = '';
						if (column === 'notes') {
							const notes = vehicle.vehicle_notes;
							notes.map((note) => {
								const userid = this.props.lookupUserById(note.uid);
								newcol += `${userid.user_name} - ${note.value.replace(/#/g, '')} | `;
							});
						}

						if (column === 'days in bucket') {
							const first_time_tab = this.dealerSettingValue('first_time_tab');
							var seconds = 0;
							switch (first_time_tab) {
							case 'age-24_7':
								seconds = getSecondsFromTimeRange(vehicle.created_at, end);
								break;
							case 'age-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.created_at, end);
								break;
							case 'age-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.created_at, end);
								break;
							case 'age-recononly-24_7':
								plus_seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								seconds = returnTabAge_24_7(completed_departments, work_flow_properties);
								break;
							case 'age-recononly-24_6':
								plus_seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								seconds = returnTabAge_24_6(completed_departments, work_flow_properties);
								break;
							case 'age-recononly-24_5':
								plus_seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								seconds = returnTabAge_24_5(completed_departments, work_flow_properties);
								break;
							case 'department-24_7':
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							case 'department-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								break;
							case 'department-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								break;
							case 'department-instance-24_7':
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							case 'department-instance-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								break;
							case 'department-instance-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								break;
							default:
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							}

							newcol = convertSecondsToDays(seconds);
						}

						if (column === 'total age (days)') {
							const second_time_tab = this.dealerSettingValue('second_time_tab');
							var seconds = 0;
							var plus_seconds = 0;
							switch (second_time_tab) {
							case 'age-24_7':
								seconds = getSecondsFromTimeRange(vehicle.created_at, end);
								break;
							case 'age-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.created_at, end);
								break;
							case 'age-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.created_at, end);
								break;
							case 'age-recononly-24_7':
								plus_seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								seconds = returnTabAge_24_7(completed_departments, work_flow_properties);
								break;
							case 'age-recononly-24_6':
								plus_seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								seconds = returnTabAge_24_6(completed_departments, work_flow_properties);
								break;
							case 'age-recononly-24_5':
								plus_seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								seconds = returnTabAge_24_5(completed_departments, work_flow_properties);
								break;
							case 'department-24_7':
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							case 'department-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								break;
							case 'department-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								break;
							case 'department-instance-24_7':
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							case 'department-instance-24_6':
								seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
								break;
							case 'department-instance-24_5':
								seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
								break;
							default:
								seconds = getSecondsFromTimeRange(vehicle.department_time, end);
								break;
							}

							newcol = convertSecondsToDays(seconds + plus_seconds);
						}
						const col = vehicle[column] || '';
						if (newcol.length > 0) {
							row.push(newcol);
						} else {
							row.push(col);
						}
					}
				}
			});

			full_csv.push(row);
		});

		const filename = `${this.props.current_department.name}_${getDateTime()}.csv`;

		const csvContent = `data:text/csv;charset=utf-8,${full_csv.map(e => e.join(',')).join('\n')}`;

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
	}

	render() {
		const { errors } = this.state;

		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="DownloadCSV"
					className="d-flex justify-content-center pad-bottom-2 space-bottom-2"
					contentLabel="DownloadCSV"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-6 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="modal-header bg-dark text-white">
							<button type="button" className="btn btn-sm btn-danger" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
						</div>

						<div className="modal-footer">
							<button className="btn btn-primary" onClick={this.onDownloadButtonClick}>
								<i className="fa fa-download" />
								{' '}
Download All Columns
							</button>
							<button className="btn btn-primary" onClick={this.onDownloadStepButtonClick}>
								<i className="fa fa-download" />
								{' '}
Download
								{' '}
								{this.props.current_department.name}
							</button>
						</div>

					</div>

				</Modal>
				<span className="btn btn-light btn-md" onClick={this.openModal}><i className="fa fa-download" /></span>
			</div>
		);
	}
}

const mappedActions = {
	lookupDepartmentById,
	lookupUserById,
};

function mapStateToProps(state) {
	return {
		dealership_columns: state.settings_departments.dealership_columns,
		current_department: state.settings_departments.department || [],
		work_flows: state.settings_departments.work_flows,
		active_vehicles: state.settings_refresh.active_vehicles,
		dealer_settings: state.settings_site.dealer_settings,
	};
}
export default connect(mapStateToProps, mappedActions)(DownloadCSV);
