import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddWorkItemPreset from './AddWorkItemPreset';
import WorkItemPresetTable from './WorkItemPresetTable';

class WorkItemDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = { username: '', password: '' };
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card space-top-0">
					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-100 p-3">
						<div className="col-md-12 pad-bottom-1 text-center">
							<WorkItemPresetTable {...this.props} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {};

function mapStateToProps(state) {
	return {
		work_item_presets: state.settings_refresh.work_item_presets,
	};
}
export default connect(mapStateToProps, mappedActions)(WorkItemDashboard);
