import React, { Component } from 'react';
import { connect } from 'react-redux';
import UsedCarStockInSheet from './UsedCarStockInSheet';
import DetailSlip from './DetailSlip';
import { setCurrentDealerForm } from '../../actions/apiSetInfo';

class DealerForms extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		this.props.setCurrentDealerForm(e.target.name);
	}

	render() {
		return (
			<div className="col-md-9 col-lg-10 main pad-top-0" id="page-content-wrapper">
				<button className="btn btn-success" name="usedcar" onClick={this.onClick}>Used Car Stock-In-Sheet</button>
				<button className="btn btn-success space-left-1" name="detail-slip" onClick={this.onClick}>Detail Slip</button>

				{(() => {
					switch (this.props.dealer_form) {
					case 'usedcar':
						return <UsedCarStockInSheet />;

					case 'detail-slip':
						return <DetailSlip />;

					default:
						return <UsedCarStockInSheet />;
					}
				})()}
			</div>
		);
	}
}

const mappedActions = {
	setCurrentDealerForm,
};

function mapStateToProps(state) {
	return {
		dealer_form: state.settings_refresh.dealer_form,
	};
}
export default connect(mapStateToProps, mappedActions)(DealerForms);
