import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import VehicleHistorySummary from './VehicleHistorySummary';
import { lookupVehicleByIdAllVehicles } from '../../actions/lookups';

class ReportSingleVehicle extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
		};
		this.onChange = this.onChange.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	render() {
		const { errors } = this.state;
        console.log(this.props.vehicle);
		const vehicle = this.props.vehicle;
		const title = `${vehicle.year} ${vehicle.make} ${vehicle.model} Stock: ${vehicle.stock} Vin: ${vehicle.vin}`;

		return (
			<div className="col-md-3">
				<Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                className="customModal"
                contentLabel={title}
                ariaHideApp={false}
                overlayClassName="overlay"
                >
                <div className="customCard">
                    <div className="headerWrapper">
                    <button type="button" className="btn btn-danger btn-sm float-end" ariaLabel="Close" onClick={this.closeModal}>
                        <span ariaHidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="cardText">
                    <VehicleHistorySummary vehicle={vehicle} vehicle_id={this.props.vehicle.id} is_single />
                    </div>
                    <div className="footerWrapper" />
                </div>
                </Modal>
				<div className="btn-group">
					<button className="btn btn-sm btn-success" onClick={this.openModal}><i className="fal fa-car" /></button>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupVehicleByIdAllVehicles,
};

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps, mappedActions)(ReportSingleVehicle);