import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';
import EditTabSettings from '../../inputs/EditTabSettings';
import EditEstimates from './EditEstimates';
import EditTabStyle from './EditTabStyle';
import EditTabCheckboxes from './EditTabCheckboxes';
import EditInspection from './EditInspection';
import EditSuperForm from './EditSuperForm';
import EditTabCost from './EditTabCost';
import EditUsedCarChecklist from './EditUsedCarChecklist';
import EditNewWorkItems from './EditNewWorkItems';
import EditVehicleHistory from './EditVehicleHistory';
import { change_page } from '../../actions/toggleMenu';

const activeOptions = ['true', 'false'];

class TabSettingsEdit extends Component {
	constructor(props) {
		super(props);

		this.state = { errors: { editTab: false }, option_name: '' };
		this.onBackClick = this.onBackClick.bind(this);
	}

	onBackClick(e) {
		this.props.change_page('tab-settings');
	}

	getTabByName() {
		const self = this;
		const value = _.sortBy(_.filter(self.props.all_tabs, o => o.name === self.props.edit_tab), 'id');

		return value[0];
	}

	render() {
		const self = this;
		const { props } = this;
		const tab = this.getTabByName();
		return (
			<div className="col-md-12">
				<div className="card space-top-0">
					<h3 className="text-center pad-top-1">
						{this.props.edit_tab.replace('tab_', '')}
						{' '}
Settings
					</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card space-top-1">
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<button className={'btn btn-success btn-sm desk-w-30 float-left'} onClick={this.onBackClick}>
											<i className="fa fa-arrow-left" />
											{' '}
All Tabs
										</button>

									</div>
									<div className="col-md-6">

										<EditTabSettings {...this.props} type={'number-prepend'} name={'order'} value={tab.order} dataid={tab.id} />

									</div>
								</div>
							</div>
						</div>

						<EditTabStyle {...this.props} />

						{(() => {
							switch (this.props.edit_tab) {
							case 'tab_estimate':
								return <EditEstimates {...this.props} tab={tab} />;

							case 'tab_checkboxes':
								return <EditTabCheckboxes {...this.props} tab={tab} />;

							case 'tab_inspection':
								return <EditInspection {...this.props} tab={tab} />;

							case 'tab_superform':
								return <EditSuperForm {...this.props} tab={tab} />;

							case 'tab_cost':
								return <EditTabCost {...this.props} tab={tab} />;

							case 'tab_new_work_items':
								return <EditNewWorkItems {...this.props} tab={tab} />;

							case 'attachment':
								return '';

							case 'tab_history':
								return <EditVehicleHistory {...this.props} tab={tab} />;

							case 'tab_used_car_checklist':
								return <EditUsedCarChecklist {...this.props} tab={tab} />;

							default:
								return '';
							}
						})()}

					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	change_page,
};

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		edit_tab: state.settings_admin.edit_tab,
		all_tabs: state.settings_site.all_tabs,
	};
}
export default connect(mapStateToProps, mappedActions)(TabSettingsEdit);
