import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	setReportWorkFlows, setReportDealerships, setCurrentReportTab, setReportFilters, setReportDepartments, setEditDepartments,
} from '../actions/apiSetInfo';
import { filterReport, filterMonthlyReport, reportVehicles } from '../actions/apiReports';
import { addUserInstallation, deleteUserInstallation } from '../actions/users';
import {
	lookupUserById, lookupWorkFlowByName, lookupWorkFlowById, activeEditDepartment,
} from '../actions/lookups';
import { addNotificationSetting } from '../actions/apiNotifications';
import { updateDepartmentSetting, updateVehicleColumn } from '../actions/apiDepartmentUpdates';
import { sendEstimate } from '../actions/apiEstimates';
import { updateEmailReport } from '../actions/apiEmailReports';
import {
	change_page,
} from '../actions/toggleMenu';

class DropdownMultiple extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listOpen: false,
			headerTitle: this.props.title,
			timeOut: null,
			list: this.props.list,
		};
		this.close = this.close.bind(this);
		this.toggleSelected = this.toggleSelected.bind(this);
	}

	componentDidUpdate() {
		const { listOpen } = this.state;
		setTimeout(() => {
			if (listOpen) {
				window.addEventListener('click', this.close);
			} else {
				window.removeEventListener('click', this.close);
			}
		}, 0);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.close);
	}

	close(timeOut) {
		this.setState({
			listOpen: false,
		});
	}

	static getDerivedStateFromProps(nextProps) {
		const count = nextProps.list.filter(a => a.selected).length;
		const labels = nextProps.list.filter(a => a.selected);

		var title = `(${count}) `;

		if (count === 0) {
			return { headerTitle: nextProps.title };
		}
		if (count === 1) {
		labels.map((option) => {
			title = `${title} ${option.label}`;
		});
			return { headerTitle: `${title}` };
		}
		if (count > 1) {
		var i = 1;
		labels.map((option) => {
			if (i > 3) {
				title = `${title} ...`;
			} else if (i === count) {
				title = `${title} ${option.label}`;
			} else {
				title = `${title} ${option.label} |`;
			}
			i++;
		});

			return { headerTitle: `${title}` };
		}
	}

	toggleList() {
		this.setState(prevState => ({
			listOpen: !prevState.listOpen,
		}));
	}

	toggleSelected(id) {
		const install_id = id;
		self = this;
		const index = _.findIndex(self.state.list, { id: parseInt(id) });

		const newObj = self.state.list.map((item, ind) => {
			if (index !== ind) {
				return item;
			}
			item.selected = !item.selected;
			const { user_id } = this.props;

			if (self.props.update_type == 'user_installation') {
				if (item.selected === true) {
					var errorType = 'addUserInstallation';
					var data = {
						installation_id: install_id,
						user_id,
					};
					self.props.addUserInstallation(data, errorType);
				} else {
					var errorType = 'deleteUserInstallation';
					const user = self.props.lookupUserById(user_id);
					if (user.user_installations.length > 1) {
						self.props.deleteUserInstallation(item.dataid, errorType);
					} else {
						alert('User Must Have at Least One Installation');
					}
				}
			} else if (this.props.update_type == 'notifciation_settings') {
				var dealers = this.props.dealers || '';

				const dealer_values = dealers.split(',');

				if (item.selected === true) {
					dealer_values.push(item.subdomain);

					var errorType = 'addNotificationSetting';

					var { data } = this.props;

					data.dealership = dealer_values.join();

					self.props.addNotificationSetting(data, errorType);
				} else {
					var dealersFiltered = dealer_values.filter((value, index, arr) => value !== item.subdomain);

					var errorType = 'addNotificationSetting';

					var { data } = this.props;

					data.dealership = dealersFiltered.join();

					self.props.addNotificationSetting(data, errorType);
				}
			} else if (this.props.update_type == 'department_dealerships') {
				var dealers = this.props.dealers.split(',');

				if (item.selected === true) {
					dealers.push(item.subdomain);

					var errorType = 'editVehicleColumnError';

					var data = {
						id: self.props.department_id,
						dealerships: dealers.join(),
					};

					self.props.updateDepartmentSetting(self.props.department_id, data, errorType);
				} else {
					var dealersFiltered = dealers.filter((value, index, arr) => value !== item.subdomain);

					var errorType = 'editVehicleColumnError';

					var data = {
						id: self.props.department_id,
						dealerships: dealersFiltered.join(),
					};

					self.props.updateDepartmentSetting(self.props.department_id, data, errorType);
				}
			} else if (this.props.update_type == 'vehicle_column_locations') {
				var dealers = this.props.dealers.split(',');
				var id = self.props.vehicle_column_id;
				if (item.selected === true) {
					dealers.push(item.id);

					const errorType = 'editVehicleColumnError';
					const data = {
						id: self.props.vehicle_column_id,
						location: dealers.join(),
					};

					this.props.updateVehicleColumn(id, data, errorType);
				} else {
					var dealersFiltered = dealers.filter((value, index, arr) => value.toString() !== item.id.toString());

					var errorType = 'editVehicleColumnError';

					var data = {
						id: self.props.vehicle_column_id,
						location: dealersFiltered.join(),
					};

					this.props.updateVehicleColumn(id, data, errorType);
				}
			} else if (this.props.update_type == 'work_flows') {
				const work_flows = this.props.set_work_flows.split(',');

				if (item.selected === true) {
					work_flows.push(item.label);

					var errorType = 'work_flows';

					var data = {
						id: self.props.department_id,
						work_flow: work_flows.join(),
					};

					self.props.updateDepartmentSetting(self.props.department_id, data, errorType);
				} else {
					const work_flowsFiltered = work_flows.filter((value, index, arr) => (value) !== item.label);

					var errorType = 'work_flows';

					var data = {
						id: self.props.department_id,
						work_flow: work_flowsFiltered.join(),
					};

					self.props.updateDepartmentSetting(self.props.department_id, data, errorType);
				}
			} else if (this.props.update_type == 'select-recipients') {
				let recipients = this.props.value.split(',');
				let value = '';
				const id = item.estimate_id;
				if (item.selected === true) {
					if (recipients.indexOf(item.id.toString()) === -1) {
						recipients.push(item.id);
					}
					var errorType = 'select-recipients';
				} else {
					recipients = recipients.filter((value, index, arr) => value !== item.id.toString());
				}
				value = [...new Set(recipients)];

				const data = {
					id,
					vehicle_id: this.props.parentid,
					recipients: value.join(),
				};
				this.props.sendEstimate(id, data, errorType);
			} else if (this.props.update_type == 'report_dealerships') {
				const report_dealerships = this.props.report_dealerships.split(',');

				if (item.selected === true) {
					if (report_dealerships.indexOf(item.subdomain) === -1) {
						report_dealerships.push(item.subdomain);
					}
					var errorType = 'report_dealerships';
					self.props.setReportDealerships(report_dealerships.join());
				} else {
					const report_dealershipsFiltered = report_dealerships.filter((value, index, arr) => value !== item.subdomain);
					self.props.setReportDealerships(report_dealershipsFiltered.join());
				}
			} else if (this.props.update_type == 'email_report_dealerships') {
				const report_dealerships = this.props.email_report.report_dealerships.split(',');
				var id = this.props.email_report.id;
				if (item.selected === true) {
					if (report_dealerships.indexOf(item.subdomain) === -1) {
						report_dealerships.push(item.subdomain);
					}
					var errorType = 'report_dealerships';
					var data = {
						id,
						report_dealerships: report_dealerships.join(),
					};

					self.props.updateEmailReport(id, data, 'report_dealerships');
				} else {
					const report_dealershipsFiltered = report_dealerships.filter((value, index, arr) => value !== item.subdomain);
					var data = {
						id,
						report_dealerships: report_dealershipsFiltered.join(),
					};
					self.props.updateEmailReport(id, data, 'report_dealerships');
				}
			} else if (this.props.update_type == 'email_report_users') {
				const report_users = this.props.email_report.report_users.split(',');
				var id = this.props.email_report.id;
				if (item.selected === true) {
					if (report_users.indexOf(item.user_name) === -1) {
						report_users.push(item.user_name);
					}
					var errorType = 'report_users';
					var data = {
						id,
						report_users: report_users.join(),
					};

					self.props.updateEmailReport(id, data, 'report_users');
				} else {
					const report_usersFiltered = report_users.filter((value, index, arr) => value !== item.user_name);
					var data = {
						id,
						report_users: report_usersFiltered.join(),
					};
					self.props.updateEmailReport(id, data, 'report_users');
				}
			} else if (this.props.update_type == 'email_report_times') {
				const report_times = this.props.email_report.report_run_time.split(',');
				var id = this.props.email_report.id;
				if (item.selected === true) {
					if (report_times.indexOf(item.value) === -1) {
						report_times.push(item.value);
					}
					var errorType = 'report_times';
					var data = {
						id,
						report_run_time: report_times.join(),
					};

					self.props.updateEmailReport(id, data, 'report_times');
				} else {
					const report_timesFiltered = report_times.filter((value, index, arr) => value !== item.value);
					var data = {
						id,
						report_run_time: report_timesFiltered.join(),
					};
					self.props.updateEmailReport(id, data, 'report_times');
				}
			} else if (this.props.update_type == 'email_report_days') {
				const report_days = this.props.email_report.report_run_day.split(',');
				var id = this.props.email_report.id;
				if (item.selected === true) {
					if (report_days.indexOf(item.value) === -1) {
						report_days.push(item.value);
					}
					var errorType = 'report_days';
					var data = {
						id,
						report_run_day: report_days.join(),
					};

					self.props.updateEmailReport(id, data, 'report_dealerships');
				} else {
					const report_daysFiltered = report_days.filter((value, index, arr) => value !== item.value);
					var data = {
						id,
						report_run_day: report_daysFiltered.join(),
					};
					self.props.updateEmailReport(id, data, 'report_run_day');
				}
			} else if (this.props.update_type == 'report_departments') {
				const report_departments = this.props.report_departments.split(',');

				if (item.selected === true) {
					if (report_departments.indexOf(item.id) === -1) {
						report_departments.push(item.id);
					}
					var errorType = 'report_departments';
					self.props.setReportDepartments(report_departments.join());
				} else {
					const report_departmentsFiltered = report_departments.filter((value, index, arr) => parseInt(value) !== parseInt(item.id));

					self.props.setReportDepartments(report_departmentsFiltered.join());
				}
			} else if (this.props.update_type == 'edit_departments') {
				const edit_departments = this.props.edit_departments.split(',');

				if (item.selected === true) {
					if (edit_departments.indexOf(item.id) === -1) {
						edit_departments.push(item.id);
					}
					var errorType = 'edit_departments';
					self.props.activeEditDepartment(item.id);
					self.props.setEditDepartments(edit_departments.join());
				} else {
					const edit_departmentsFiltered = edit_departments.filter((value, index, arr) => parseInt(value) !== parseInt(item.id));

					self.props.setEditDepartments(edit_departmentsFiltered.join());
				}
			} else if (this.props.update_type == 'report_work_flows') {
				const report_work_flows = this.props.report_work_flows.split(',');
				const departments = this.props.departments;
				const work_flow_departmentsArray = [];
				let wf = [];
				let work_flow = [];
				if (item.selected === true) {
					if (report_work_flows.indexOf(item.id) === -1) {
						report_work_flows.push(item.id);
					}
					report_work_flows.map((report_work_flow) => {
						if (report_work_flow !== '') {
							work_flow = this.props.lookupWorkFlowById(report_work_flow);
							departments.map((department) => {
								if (department.department_type === 'department') {
									const department_found = _.find(work_flow.work_flow_properties, { department_id: department.id });
									if (department_found) {
										work_flow_departmentsArray.push(department.id);
									}
								}
							});
						}
					});
					wf = [...new Set(work_flow_departmentsArray)];
					var errorType = 'report_work_flows';
					Promise.all([self.props.setReportWorkFlows(report_work_flows.join())]).then(() => {
						self.props.setReportDepartments(wf.join());
					});
				} else {
					const report_work_flowsFiltered = report_work_flows.filter((value, index, arr) => value !== item.id);

					report_work_flowsFiltered.map((report_work_flow) => {
						if (report_work_flow !== '') {
							work_flow = this.props.lookupWorkFlowById(report_work_flow);
							departments.map((department) => {
								if (department.department_type === 'department') {
									const department_found = _.find(work_flow.work_flow_properties, { department_id: department.id });
									if (department_found) {
										work_flow_departmentsArray.push(department.id);
									}
								}
							});
						}
					});
					wf = [...new Set(work_flow_departmentsArray)];
					var errorType = 'report_work_flowsFiltered';
					Promise.all([self.props.setReportWorkFlows(report_work_flowsFiltered.join())]).then(() => {
						self.props.setReportDepartments(wf.join());
					});
				}
			} else if (this.props.update_type == 'report_filters') {
				const report_filters = this.props.report_filters.split(',');

				if (item.selected === true) {
					if (report_filters.indexOf(item.id) === -1) {
						report_filters.push(item.id);
					}
					var errorType = 'report_filters';
					self.props.setReportFilters(report_filters.join());
				} else {
					const report_filtersFiltered = report_filters.filter((value, index, arr) => parseInt(value) !== parseInt(item.id));
					self.props.setReportFilters(report_filtersFiltered.join());
				}
			}

			return item;
		});

		self.setState({
			list: newObj,
		});
	}

  resetThenSet(id, key) {
  	const temp = JSON.parse(JSON.stringify(this.state[key]));
  	temp.forEach(item => item.selected = false);
  	temp[id].selected = true;
  	this.setState({
  		[key]: temp,
  	});
  }

  render() {
  	const { list } = this.state;

  	const { toggleItem } = this.props;
  	const { listOpen, headerTitle } = this.state;
  	return (
  		<div>
  			<div className="dd-wrapper" key="testkey" tabIndex={this.props.tabindex}>
  				<div className="dd-header" onClick={() => this.toggleList()}>
  					<div className="dd-header-title">{headerTitle}</div>
  					<div className="text-muted">
  						{listOpen
  							? <i className="fal fa-2x fa-angle-up text-muted" />
  							: <i className="fal fa-2x fa-angle-down text-muted" />}
  					</div>
  				</div>
  				{listOpen && <div>
  					<ul className={list.length > 10 ? 'dd-list scroll' : 'dd-list'} onClick={e => e.stopPropagation()}>
  					{list.map(item => (
  						<li className={item.selected ? 'dd-list-item selected' : 'dd-list-item'} key={`dd_${item.id}`} onClick={() => this.toggleSelected(item.id)}>
  							{item.label}
  								{' '}
  								{item.selected ? <span><i className="fas fa-check float-right" /></span> : ''}
  						</li>

  					))}
  					</ul>
                   </div>}
  			</div>
  		</div>
  	);
  }
}

const mappedActions = {
	setReportWorkFlows,
	setReportDealerships,
	setCurrentReportTab,
	filterReport,
	filterMonthlyReport,
	reportVehicles,
	addUserInstallation,
	deleteUserInstallation,
	lookupWorkFlowByName,
	lookupWorkFlowById,
	lookupUserById,
	addNotificationSetting,
	updateDepartmentSetting,
	sendEstimate,
	setReportFilters,
	setReportDepartments,
	activeEditDepartment,
	setEditDepartments,
	updateEmailReport,
	change_page,
};

function mapStateToProps(state) {
	return {
		searched_vehicles: state.settings_refresh.searched_vehicles,
		current_report_tab: state.settings_reports.current_report_tab,
		departments: state.settings_departments.departments,
		related_installations: state.settings_dealerships.related_installations,
		report_start_date: state.settings_reports.report_start_date,
		report_end_date: state.settings_reports.report_end_date,
		report_dealerships: state.settings_reports.report_dealerships,
		report_departments: state.settings_reports.report_departments,
		report_data: state.settings_reports.report_data,
		report_filters: state.settings_reports.report_filters,
		report_work_flows: state.settings_reports.report_work_flows,
		set_work_flows: state.settings_departments.work_flows,
	};
}
export default connect(mapStateToProps, mappedActions)(DropdownMultiple);
