import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PartOrderSingle from './PartOrderSingle';
import { addPartOrder } from '../../../../actions/apiPartOrders';

const partsFields = ['Part (sku) Ordered', 'From', 'Date Ordered', 'Date Expected',
	'Parts on Hand', 'x'];

const allFields = ['part_ordered', 'from', 'date_ordered', 'date_expected',
	'parts_on_hand', 'deleted'];

class PartOrders extends PureComponent {
	constructor(props) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
		};

		this.props.addPartOrder(data, 'addPartOrderError');
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;

		return (
			<div className="card">

				<div className="card-text">
					<div className="d-flex flex-wrap">
						<button className="btn btn-sm btn-success" onClick={self.onClick}>Add Part Order</button>
						<table className="table table-striped">
							<thead className={'thead text-center'}>
								<tr>
									<td className="text-center">#</td>
									{partsFields.map(option => <th key={`part_order_${option}`} className="text-center">{option}</th>)}
								</tr>
							</thead>
							<tbody>

								{this.props.active_vehicle.part_orders.map(part_order => <PartOrderSingle key={`part_order_single_${part_order.id}`} part_order={part_order} vehicle={vehicle} />)}

							</tbody>
						</table>
					</div>

				</div>
				<div className="card-footer" />
			</div>
		);
	}
}

const mappedActions = {
	addPartOrder,
};
function mapStateToProps(state) {
	return {
		part_orders: state.settings_refresh.part_orders,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}

export default connect(mapStateToProps, mappedActions)(PartOrders);
