import findIndex from 'lodash/findIndex';
import _ from 'lodash';
import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const initialState = {
	installation: [],
	related_installations: [],
	current_dealership: '',
	current_dealership_id: '',
	switch_dealership: false,
	single_dealership: false,
	set_dealerships: [],
	set_searched_dealerships: [],
};

export const settings_dealerships = createReducer(initialState, {
	[types.SET_INSTALLATION](state = initialState, action) {
		return {
			...state,
			installation: action.installation[0],
		};
	},
	[types.SET_RELATED_INSTALLATIONS](state = initialState, action) {
		return {
			...state,
			related_installations: action.related_installations,
		};
	},
	[types.SWITCH_DEALERSHIP](state = initialState, action) {
		return {
			...state,
			switch_dealership: action.switch_dealership,
		};
	},
	[types.SINGLE_DEALERSHIP](state = initialState, action) {
		return {
			...state,
			single_dealership: action.single_dealership,
		};
	},
	[types.SET_CURRENT_DEALERSHIP](state = initialState, action) {
		return {
			...state,
			current_dealership: action.dealership,
			current_dealership_id: action.dealership_id,
		};
	},
	[types.SET_DEALERSHIPS](state = initialState, action) {
		return {
			...state,
			set_dealerships: _.sortBy(action.dealerships, ['database', 'subdomain']),
		};
	},
	[types.SET_SEARCHED_DEALERSHIPS](state = initialState, action) {
		return {
			...state,
			set_searched_dealerships: _.sortBy(action.dealerships, ['database', 'subdomain']),
		};
	},
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			installation: [],
			related_installations: [],
			current_dealership: '',
			current_dealership_id: '',
			switch_dealership: false,
			single_dealership: false,
			set_searched_dealerships: [],
		};
	},
});

export default 'settings_dealerships';
