import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AddUserButton from './AddUserButton';
import EditUserInputs from '../../inputs/EditUserInputs';
import SearchUser from './SearchUser';
import ShowUsers from './ShowUsers';
import NotificationSettings from './NotificationSettings';
import DefaultProfiles from './DefaultProfiles';
import UserSingle from './UserSingle';
import FlashMessageList from '../../inputs/FlashMessageList';

class PageUsers extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<div className="col-md-12">

				<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">
					<div className="row">
						<FlashMessageList {...this.props} />
					</div>
					<div className="card-header row align-items-center">
						<SearchUser {...this.props} />
						{' '}
						<AddUserButton {...this.props} />
					</div>

					<div className="card-body space-bottom-2">
						{(() => {
							switch (this.props.users_tab) {
							case 'users':
								return <ShowUsers {...this.props} />;

							case 'default-profiles':
								return <DefaultProfiles {...this.props} />;

							case 'user-single':
								return <UserSingle {...this.props} />;

							default:
								return <ShowUsers {...this.props} />;
							}
						})()}

					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		all_users: state.settings_users.all_users,
		searched_users: state.settings_users.searched_users,
		user_filter: state.settings_users.user_filter,
		users_tab: state.settings_users.users_tab,
	};
}

export default connect(mapStateToProps)(PageUsers);
