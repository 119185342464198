import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FeedLogs from './FeedLogs';
import FeedSettings from './FeedSettings';

import {
	getThisWeekFeedLogs, getFeedCenter,
} from '../../actions/apiGetInfo';


class FeedCenter extends Component {
	constructor(props) {
		super(props);

		this.state = { username: '', password: '' };
	}

	componentDidMount() {
		this.props.getThisWeekFeedLogs();
		this.props.getFeedCenter();
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card space-top-0">
					<h3 className="text-center pad-top-1">Feed Settings</h3>
					<FeedSettings />
				</div>
				<div className="card space-top-0">
					<h3 className="text-center pad-top-1">Feed History</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">
						{this.props.feed_logs.length > 0 ? <FeedLogs {...this.props} /> : ''}
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	getThisWeekFeedLogs,
	getFeedCenter,
};

function mapStateToProps(state) {
	return {
		feed_logs: state.settings_feed.feed_logs,
		feed_errors: state.settings_feed.feed_errors,
		feed_notification_settings: state.settings_feed.feed_notification_settings,
		feed_providers: state.settings_feed.feed_providers,
		feed_provider_settings: state.settings_feed.feed_provider_settings,
		feed_provider_rules: state.settings_feed.feed_provider_rules,
	};
}
export default connect(mapStateToProps, mappedActions)(FeedCenter);
