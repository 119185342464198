import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import EditTabSettings from '../../inputs/EditTabSettings';

const activeOptions = ['true', 'false'];

class TabSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const self = this;
		const { props } = this;
		return (
			<div className="col-md-12">

				<div className="card space-top-0">
					<h3 className="text-center pad-top-1">Tab Settings</h3>

					<div className="card space-bottom-1 space-top-1 mx-auto w-50 p-3">

						<div className="card-text">

							<table className="table">
								<thead className={'thead-dark text-center'}>
									<tr>
										<th />
									</tr>
								</thead>
								<tbody>

									{this.props.all_tabs.map(tab => <tr key={`tab_${tab.id}`}>
										<td>
											<EditTabSettings {...this.props} type={'switch-edit'} name={tab.name} label={tab.label} checked={tab.active} dataid={tab.id} description={tab.description} />
										</td>
                                         </tr>)}

								</tbody>
							</table>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		all_tabs: state.settings_site.all_tabs,
	};
}
export default connect(mapStateToProps)(TabSettings);
