import React from 'react';
import classnames from 'classnames';

const AddOptionButton = ({
	field, value, label, error, type, onChange, onClick,
}) => (
	<div className={classnames('input-group', { 'has-danger': error })}>

		<input
			onChange={onChange}
			value={value}
			type={type}
			name={field}
			key={'option_input'}
			className={classnames('form-control', { 'form-control-danger': error })}
		/>

		<span className="input-group-btn">
			<button
				className="btn btn-success"
				type="button"
				onClick={onClick}
			>
				<i className="fa fa-plus" />
				{' '}
Add Option
			</button>
		</span>
		{error && <span className="form-control-feedback">{error}</span>}
	</div>

);

export default AddOptionButton;
