import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import axios, { post } from 'axios';
import DropBox from './DropBox';

class FileUploadButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', checked: this.props.checked || '', file: '', image: '', image_name: '', image_type: '', image_size: '',
		};
		this.onChangeFile = this.onChangeFile.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.fileUpload = this.fileUpload.bind(this);
		this.uploadData = this.uploadData.bind(this);
	}

	onFormSubmit(e) {
		e.preventDefault();
		this.fileUpload(this.state.image);
	}

	onChange(e) {
		const files = e.target.files || e.dataTransfer.files;
		if (!files.length) return;
		this.createImage(files[0]);
	}

	createImage(file) {
		this.setState({
			image: file,
			image_name: file.name,
			image_type: file.type,
			image_size: file.size,
		});
	}

	uploadData(response) {
		const fileurl = response.data;
		const errorType = 'addAttachmentError';
		const { vid } = this.props;
		const { siteid } = this.props;
		const { user_name } = this.props;
		const fileid = this.state.image_name;
		const data = {
			vehicle_id: vid,
			filename: fileid,
			location: fileurl,
			fileid,
			siteid,
			user_name,
		};

		this.props.addAttachment(data, errorType);
	}

	fileUpload(image) {
		const url = 'http://localhost:3000/upload';
		const self = this;
		const name = self.state.image_name;
		const data = new FormData();
		data.append('file', self.state.image);
		data.append('action', 'put-newserver-buffer');
		data.append('fileid', self.state.image_name);
		data.append('siteid', 'demo_1');

		return axios.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},

		}).then(response => this.uploadData(response));
	}

	onChangeFile(event) {
	}

	render() {
		return (
			<div>
				<input
					onChange={this.onChange}
					type={'file'}
					name="data"
				/>

				<button onClick={this.onFormSubmit} className="btn btn-success">+</button>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return { user_name: state.current_user.user_name };
}
export default connect(mapStateToProps)(FileUploadButton);
