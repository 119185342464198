import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DepartmentSetupBody from './DepartmentSetupBody';

const activeOptions = ['true', 'false'];

class DepartmentSetup extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	render() {
		const self = this;

		return (
			<div className="col-md-12">

				<div className="card space-top-0">

					<div className="card-text">
						<div className="card bg-light space-top-1 mx-auto p-3">

							<div className="row">
								<DepartmentSetupBody />

							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps)(DepartmentSetup);
