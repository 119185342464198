import React from 'react';
import classnames from 'classnames';
import packageJson from '../../../package.json'

const Footer = () => {
	let today = new Date()
	let year = today.getFullYear()

	return(
		<div className="container-fluid text-center">
			<nav className="fixed-bottom navbar-dark bg-dark text-center pad-top-p3 pad-bottom-p3">
				<small className="text-white text-center">{`©${year} AutoIPacket, LLC - All rights reserved. ${packageJson.version}`}</small>
			</nav>
		</div>

	)
}

export default Footer;
