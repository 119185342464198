import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import TextBox from '../../inputs/TextBox';
import {
	getVehicleByVinAndSubdomain,
} from '../../actions/apiGetInfo';
import {
	getDateTime,
} from '../../utils/dateFunctions';

class Carvana extends Component {
	constructor(props) {
		super(props);
		const vin_link = this.props.match.params.vinToken.split(':');
		this.state = {
			subdomain: vin_link[0], vin: vin_link[1], vehicle: {},
		};
		this.setLink = this.setLink.bind(this);
	}

	setLink() {
		this.setState({ vehicle: this.props.active_vehicle });
	}

	componentDidMount() {
		Promise.all([this.props.getVehicleByVinAndSubdomain(this.state.vin, this.state.subdomain)]).then(() => {
			this.setLink();
		});
	}

	render() {
		const { errors } = this.state;
		const vehicle = this.props.active_vehicle;
		const vehicle_properties = vehicle.vehicle_properties;

		const vin_1_passed = _.find(vehicle_properties, { key: 'Correct VIN plate and anti-theft labels_passed' }) || [];
		const vin_1_fixed = _.find(vehicle_properties, { key: 'Correct VIN plate and anti-theft labels_fixed' }) || [];

		const vin_2_passed = _.find(vehicle_properties, { key: 'Clean Experian Auto Check Confirm_passed' }) || [];
		const vin_2_fixed = _.find(vehicle_properties, { key: 'Clean Experian Auto Check Confirm_fixed' }) || [];

		const vin_3_passed = _.find(vehicle_properties, { key: 'No reported accidents/flood damage/salvage_passed' }) || [];
		const vin_3_fixed = _.find(vehicle_properties, { key: 'No reported accidents/flood damage/salvage_fixed' }) || [];

		const hood_1_passed = _.find(vehicle_properties, { key: 'Battery(condition,fluid level,load test)_passed' }) || [];
		const hood_1_fixed = _.find(vehicle_properties, { key: 'Battery(condition,fluid level,load test)_fixed' }) || [];

		return (
			<div className="row bg-white">

				<div className="container float-right">
					<p className="text-center text-dark float-right">150 POINT INSPECTION CHECKLIST</p>
				</div>
				<div className="container space-bottom-2">
					<li className="text-left text-dark list-group-item">
						{vehicle.year}
						{' '}
						{vehicle.make}
						{' '}
						{vehicle.model}
						{' '}
						{vehicle.trim}
						{' '}
VIN:
						{' '}
						{vehicle.vin}
						{' '}
STOCK:
						{' '}
						{vehicle.stock}
					</li>
				</div>
				<div className="container">
					<h3 className="carvana-color pad-left-1">FROM THE VIN</h3>
					<table className="table table-bordered">
			  <thead>
			    <tr>
			      <th scope="col" style={{ width: '30%' }} />
			      <th scope="col" style={{ width: '10%' }}>PASSED</th>
			      <th scope="col" style={{ width: '10%' }}>FIXED OR REPLACED</th>
			      <th scope="col" style={{ width: '30%' }} />
			      <th scope="col" style={{ width: '10%' }}>PASSED</th>
			      <th scope="col" style={{ width: '10%' }}>FIXED OR REPLACED</th>
			    </tr>
			  </thead>
			  <tbody>
			    <tr>
			      <th scope="row">Correct VIN plate and anti-theft labels</th>
			      <td>{vin_1_passed.value === 'true' ? <i className="fa fa-check-circle text-success" /> : ''}</td>
			      <td>{vin_1_fixed.value === 'true' ? <i className="fa fa-check-circle text-success" /> : ''}</td>
			      <td scope="row">No reported accidents/flood damage/salvage</td>
			      <td>{vin_3_passed.value === 'true' ? <i className="fa fa-check-circle text-success" /> : ''}</td>
			      <td>{vin_3_fixed.value === 'true' ? <i className="fa fa-check-circle text-success" /> : ''}</td>
			    </tr>
			    <tr>
			      <th scope="row">Clean Experian Auto Check Confirm</th>
			      <td>{vin_2_passed.value === 'true' ? <i className="fa fa-check-circle text-success" /> : ''}</td>
			      <td>{vin_2_fixed.value === 'true' ? <i className="fa fa-check-circle text-success" /> : ''}</td>
			      <td />
			      <td />
			      <td />
			    </tr>
			  </tbody>
					</table>
				</div>
				<div className="container space-top-2">
					<h3 className="carvana-color pad-left-1">UNDER THE HOOD</h3>
					<table className="table table-bordered">
			  <thead>
			    <tr>
			      <th scope="col" style={{ width: '30%' }} />
			      <th scope="col" style={{ width: '10%' }}>PASSED</th>
			      <th scope="col" style={{ width: '10%' }}>FIXED OR REPLACED</th>
			      <th scope="col" style={{ width: '30%' }} />
			      <th scope="col" style={{ width: '10%' }}>PASSED</th>
			      <th scope="col" style={{ width: '10%' }}>FIXED OR REPLACED</th>
			    </tr>
			  </thead>
			  <tbody>
			    <tr>
			      <th scope="row">Battery(condition,fluid level,load test)</th>
			      <td>{hood_1_passed.value === 'true' ? <i className="fa fa-check-circle text-success" /> : ''}</td>
			      <td>{hood_1_passed.value === 'true' ? <i className="fa fa-check-circle text-success" /> : ''}</td>
			      <td scope="row" />
			      <td />
			      <td />
			    </tr>
			  </tbody>
					</table>
				</div>
			</div>

		);
	}
}

const mappedActions = {
	getVehicleByVinAndSubdomain,
};

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default withRouter(connect(mapStateToProps, mappedActions)(Carvana));
