import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SketchPicker, SwatchesPicker } from 'react-color';
import DropdownMultiple from './DropdownMultiple';
import Dropdown from './Dropdown';
import { updateDepartmentSetting } from '../actions/apiDepartmentUpdates';

class EditDepartmentOrder extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', checked: this.props.checked || '', background_color: this.props.background_color, text_color: this.props.text_color,
		};
		this.onChange = this.onChange.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.handleChangeComplete = this.handleChangeComplete.bind(this);
		this.onRelatedInstallationsChangeSelect = this.onRelatedInstallationsChangeSelect.bind(this);
		this.selectRelatedInstallationsSingle = this.selectRelatedInstallationsSingle.bind(this);
	}

	onChange(event) {

	}

	handleChangeComplete(e) {
		this.setState({ value: e.hex });

		const self = this;
		const errorType = 'editColorPicker';
		const data = {
			id: self.props.parentid,
			[this.props.name]: e.hex,
		};

		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	onChangeInput(e) {
		this.setState({ value: e.target.value });
	}

	onBlur(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'editUserProfileError';
			const data = {
				id: self.props.parentid,
				[e.target.name]: e.target.value,
			};

			self.setState({ value: e.target.value });

			self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
		}
	}

	onChangeSelect(e) {
		const self = this;
		const errorType = 'editVehicleColumnError';
		const data = {
			id: self.props.parentid,
			[self.props.name]: e.value,
		};

		self.setState({ value: e.value });
		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	onRelatedInstallationsChangeSelect(e) {
		const self = this;

		const dealerArray = e.map(dealer => dealer.value);

		const errorType = 'editVehicleColumnError';

		const data = {
			id: self.props.parentid,
			[self.props.name]: dealerArray.join(),
		};

		self.setState({ value: dealerArray });

		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	onChangeChecked(e) {
		const self = this;

		const errorType = 'editVehicleColumnError';
		const data = {
			id: self.props.parentid,
			[e.target.name]: e.target.checked,
		};

		self.setState({ checked: e.target.checked });

		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	selectOptions() {
		const departments = this.props.options.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});
		return departments;
	}

	selectData() {
		const options = this.props.vehicle_columns.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});
		return options;
	}

	selectDepartments(excludeCurrent = false) {
		const departments = excludeCurrent
			? this.props.departments.filter(d => d.id !== this.props.edit_department.id)
			: this.props.departments;

		const returnDepartments = departments.map((option) => {
			let selected = false;
			if (option.id === parseInt(this.props.value)) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = { id: parseInt(option.id), label: option.name, selected };
			} else {
				var obj = { id: parseInt(option.id), label: option.name, selected };
			}
			return obj;
		});
		returnDepartments.push({ id: 0, label: 'None Selected' });
		return returnDepartments;
	}

	selectWorkFlows() {
		const work_flows = this.props.value || '';

		const work_flows_values = work_flows.split(',');

		const options = this.props.work_flows.map((option) => {
			let selected = false;

			if (work_flows_values.includes(option.name)) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = { id: option.id, label: option.name, selected };
			} else {
				var obj = { id: option.id, label: option.name, selected };
			}
			return obj;
		});
		return options;
	}

	selectRelatedInstallations() {
		const dealerships = this.props.value || '';

		const dealer_values = dealerships.split(',');

		const selected_dealerships = [];

		const options = this.props.related_installations.map((option) => {
			let selected = false;
			const dataid = 0;

			if (dealer_values.includes(option.subdomain)) {
				selected = true;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, selected, dataid, subdomain: option.subdomain,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, selected, dataid, subdomain: option.subdomain,
				};
			}
			return obj;
		});
		return options;
	}

	selectRelatedInstallationsSingle() {
		const options = this.props.related_installations.map((option) => {
			let selected = false;
			const dataid = 0;

			if (this.props.value == option.subdomain) {
				selected = true;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, selected, dataid, subdomain: option.subdomain,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, selected, dataid, subdomain: option.subdomain,
				};
			}
			return obj;
		});
		return options;
	}

	selectComparison() {
		const comparisonArray = [{ name: 'Greater Than', label: 'Greater Than' },
			{ name: 'Greater or Equal', label: 'Greater or Equal' }, { name: 'Equals', label: 'Equals' }, { name: 'Not Equals', label: 'Not Equals' },
			{ name: 'Less Than', label: 'Less Than' }, { name: 'Less or Equal', label: 'Less or Equal' }, { name: 'Contains', label: 'Contains' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectDepartmentType() {
		const comparisonArray = [{ id: 1, name: 'transfer', label: 'transfer' },
			{ id: 2, name: 'return to origin', label: 'return to origin' },
			{ id: 3, name: 'department', label: 'department' },
			{ id: 4, name: 'data step', label: 'data step' },
			{ id: 6, name: 'inventory', label: 'inventory' },
			{ id: 7, name: 'recently sold', label: 'recently sold' },
			{ id: 8, name: 'all reconditioning', label: 'all reconditioning' },
			{ id: 9, name: 'all vehicles', label: 'all vehicles' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			if (option.label == this.props.value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = { id: option.id, label: option.label, selected };
			} else {
				var obj = { id: option.id, label: option.label, selected };
			}
			return obj;
		});
		return options;
	}

	returnDescription() {
		switch (this.props.name) {
		case 'active':
			return 'Step is Active';

		case 'goal':
			return 'Goal';

		case 'overdue_1':
			return '1st Notification';

		case 'overdue_2':
			return '2nd Notification';

		case 'overdue_3':
			return '3rd Notification';

		case 'children_departments':
			return 'Select children steps';

		case 'subgroup':
			return 'Parent step';

		case 'nonstep':
			return 'This stage is a representation of compared data. Example: "Jason", would be all vehicles that Jason appraised';

		case 'nonstep_meta':
			return 'This stage is a representation of compared data. Example: "Jason"';

		case 'nonstep_compare':
			return 'Comparison operator';

		case 'step':
			return 'Is this step considered part of the reconditioning process to be timed';

		case 'display_name':
			return 'Display Name';

		default:
			return '';
		}
	}

	render() {
		return (<input
			onChange={this.onChangeInput}
			onBlur={this.onBlur}
			value={this.state.value}
			type={this.props.type}
			name={this.props.name}
			data-id={this.props.parentid}
			key={`ds_${this.props.parentid}_${this.props.field}`}
			className={classnames('form-control desk-w-30', { 'form-control-danger': this.props.error })}
		/>);
	}
}

const mappedActions = {
	updateDepartmentSetting,
};

function mapStateToProps(state) {
	return {
		edit_department: state.settings_admin.edit_department,
		vehicle_columns: state.settings_departments.vehicle_columns,
		departments: state.settings_departments.original_departments,
		related_installations: state.settings_dealerships.related_installations,
		work_flows: state.settings_departments.work_flows,
		set_dealerships: state.settings_dealerships.set_dealerships,
	};
}
export default connect(mapStateToProps, mappedActions)(EditDepartmentOrder);
