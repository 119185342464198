import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import AllInputs from '../../../../inputs/AllInputs';
import { lookupChecked, lookupVehiclePropertyValueByName } from '../../../../actions/lookups';
import { addVehicleProperty } from '../../../../actions/AllUpdates';

class CarvanaTab extends Component {
	constructor(props) {
		super(props);

		this.state = { addPartOrderError: '' };
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onPrint = this.onPrint.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
		};

		this.props.addPartOrder(data, 'addPartOrderError');
	}

	onChangeChecked(e) {
		const self = this;
		var vehicle_id = this.props.active_vehicle.id;
		var vehicle = this.props.active_vehicle;
		var department_id = this.props.active_vehicle.department_id;
		var errorType = 'updateVehicleError';
		var key = e.target.dataset.metakey;
		var keylowercase = key.toLowerCase();
		var value = e.target.checked ? 'true' : '';
		var add_pay = e.target.dataset.pay;
		var add_sale = e.target.dataset.sale;

		var ds_total_sale = _.find(vehicle.vehicle_properties, { key: 'ds_total_sale' }) ? _.find(vehicle.vehicle_properties, { key: 'ds_total_sale' }).value : 0;
		var ds_total_pay = _.find(vehicle.vehicle_properties, { key: 'ds_total_pay' }) ? _.find(vehicle.vehicle_properties, { key: 'ds_total_pay' }).value : 0;

		var data = {
			vehicle_id,
			key,
			value,
		};
		var data1 = {
			vehicle_id,
			key: 'ds_total_pay',
			value: Number(ds_total_pay) + Number(add_pay),
		};
		var data2 = {
			vehicle_id,
			key: 'ds_total_sale',
			value: Number(ds_total_sale) + Number(add_sale),
		};

		this.props.addVehicleProperty(data, errorType);
		this.props.addVehicleProperty(data1, errorType);
		this.props.addVehicleProperty(data2, errorType);
	}

	onBlur(e) {
		const self = this;
		var vehicle_id = this.props.active_vehicle.id;
		var department_id = this.props.active_vehicle.department_id;
		var errorType = 'updateVehicleError';
		var key = e.target.dataset.metakey;
		var keylowercase = key.toLowerCase();
		var value = e.target.value;

		var data = {
			vehicle_id,
			key,
			value,
		};

		this.props.addVehicleProperty(data, errorType);
	}

	onPrint(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById(`recon_tracker_${vehicle.stock}`);
		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 0, 0);
				    pdf.save(`recon_tracker_${vehicle.stock}.pdf`);
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const errors = this.state;
		const vehicle_properties = vehicle.vehicle_properties;

		const vin_1_passed = _.find(vehicle_properties, { key: 'Correct VIN plate and anti-theft labels_passed' }) || [];
		const vin_1_fixed = _.find(vehicle_properties, { key: 'Correct VIN plate and anti-theft labels_fixed' }) || [];

		const vin_2_passed = _.find(vehicle_properties, { key: 'Clean Experian Auto Check Confirm_passed' }) || [];
		const vin_2_fixed = _.find(vehicle_properties, { key: 'Clean Experian Auto Check Confirm_fixed' }) || [];

		const vin_3_passed = _.find(vehicle_properties, { key: 'No reported accidents/flood damage/salvage_passed' }) || [];
		const vin_3_fixed = _.find(vehicle_properties, { key: 'No reported accidents/flood damage/salvage_fixed' }) || [];

		const hood_1_passed = _.find(vehicle_properties, { key: 'Battery(condition,fluid level,load test)_passed' }) || [];
		const hood_1_fixed = _.find(vehicle_properties, { key: 'Battery(condition,fluid level,load test)_fixed' }) || [];

		const setdisabled = false;

		return (
			<div className="container">
				<button onClick={this.onPrint} className="btn btn-success float-right">Print</button>
				<div className="card small-font pad-right-2 pad-top-2 pad-left-2" id={`recon_tracker_${vehicle.stock}`}>
					<div className="card">
						<div className="card-text text-center">
							<h3>
								<div className="pull-left">
#DS_
									{vehicle.vin}
								</div>
150 POINT INSPECTION CHECKLIST (Sample)
							</h3>
						</div>

								    <div className="row">

							<div className="col-md-12 space-top-1">
								<div className="row pad-bottom-1 pad-top-2">
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-3">Year:</div>
											<div className="col-md-9"><input disabled type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="year" className="updateformdata form-control" value={vehicle.year} /></div>

										</div>
									</div>
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-3">Make:</div>
											<div className="col-md-9"><input disabled type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="make" className="updateformdata form-control" value={vehicle.make} /></div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-3">Model:</div>
											<div className="col-md-9"><input disabled type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="model" className="updateformdata form-control" value={vehicle.model} /></div>
										</div>
									</div>
								</div>

								<div className="row pad-bottom-1">
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-3">Color:</div>
											<div className="col-md-9"><input disabled type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="color" className="updateformdata form-control" value={vehicle.color} /></div>
										</div>
									</div>
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-3">Stock:</div>
											<div className="col-md-9"><input disabled type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="stock number" className="updateformdata form-control" value={vehicle.stock} /></div>
										</div>
									</div>
									<div className="col-md-3">
										<div className="row">
											<div className="col-md-3">Date:</div>
											<div className="col-md-9"><input type="date" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="detaileddate" className="updateformdata form-control" value={this.props.lookupVehiclePropertyValueByName(vehicle.id, 'detaileddate')} /></div>
										</div>
									</div>
								</div>

								<div className="row pad-bottom-1">
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-5">Authorized By:</div>
											<div className="col-md-7"><input type="text" onChange={this.onChange} onBlur={this.onBlur} data-vid={vehicle.id} data-metakey="authorized_by" className="updateformdata form-control" value={this.props.lookupVehiclePropertyValueByName(vehicle.id, 'authorized_by')} /></div>

										</div>
									</div>

								</div>
							</div>

						</div>
						<div className="row">
							<div className="col-md-12 pad-bottom-1">

								<div className="col-md-12 column no-more-tables">
									<table className="col-md-12 table-bordered table-hover table-condensed cf">
										<thead className="cf">
											<tr>
												<th className="text-center desk-w-60">
																	From The VIN
												</th>
												<th className="text-center desk-w-20">
																Passed
												</th>
												<th className="text-center desk-w-20">
																Fixed Or Replaced
												</th>
											</tr>
										</thead>
										<tbody className="CarvanaTab">

											<tr>
												<td>Correct VIN plate and anti-theft labels</td>
												<td>
													<AllInputs
														value={vin_1_passed.value || ''}
														type={'switch-userstamp'}
														name={'Correct VIN plate and anti-theft labels_passed'}
														parentid={vehicle.id}
														updatetype="vehicleproperty"
														setdisabled={setdisabled}
														user_name={vin_1_passed.user_name}
														updated_at={vin_1_passed.updated_at}
														vehicle={vehicle}
													/>
												</td>
												<td>
													<AllInputs
														value={vin_1_fixed.value || ''}
														type={'switch-userstamp'}
														name={'Correct VIN plate and anti-theft labels_fixed'}
														parentid={vehicle.id}
														updatetype="vehicleproperty"
														setdisabled={setdisabled}
														user_name={vin_1_fixed.user_name}
														updated_at={vin_1_fixed.updated_at}
														vehicle={vehicle}
													/>
												</td>
											</tr>
											<tr>
												<td>Clean Experian Auto Check Confirm</td>
												<td>
													<AllInputs
														value={vin_2_passed.value || ''}
														type={'switch-userstamp'}
														name={'Clean Experian Auto Check Confirm_passed'}
														parentid={vehicle.id}
														updatetype="vehicleproperty"
														setdisabled={setdisabled}
														user_name={vin_2_passed.user_name}
														updated_at={vin_2_passed.updated_at}
														vehicle={vehicle}
													/>
												</td>
												<td>
													<AllInputs
														value={vin_2_fixed.value || ''}
														type={'switch-userstamp'}
														name={'Clean Experian Auto Check Confirm_fixed'}
														parentid={vehicle.id}
														updatetype="vehicleproperty"
														setdisabled={setdisabled}
														user_name={vin_2_fixed.user_name}
														updated_at={vin_2_fixed.updated_at}
														vehicle={vehicle}
													/>
												</td>
											</tr>
											<tr>
												<td>No reported accidents/flood damage/salvage</td>
												<td>
													<AllInputs
														value={vin_3_passed.value || ''}
														type={'switch-userstamp'}
														name={'No reported accidents/flood damage/salvage_passed'}
														parentid={vehicle.id}
														updatetype="vehicleproperty"
														setdisabled={setdisabled}
														user_name={vin_3_passed.user_name}
														updated_at={vin_3_passed.updated_at}
														vehicle={vehicle}
													/>
												</td>
												<td>
													<AllInputs
														value={vin_3_fixed.value || ''}
														type={'switch-userstamp'}
														name={'No reported accidents/flood damage/salvage_fixed'}
														parentid={vehicle.id}
														updatetype="vehicleproperty"
														setdisabled={setdisabled}
														user_name={vin_3_fixed.user_name}
														updated_at={vin_3_fixed.updated_at}
														vehicle={vehicle}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

							</div>

						</div>
						<div className="row">
							<div className="col-md-12 pad-bottom-1">

								<div className="col-md-12 column no-more-tables">
									<table className="col-md-12 table-bordered table-hover table-condensed cf">
										<thead className="cf">
											<tr>
												<th className="text-center desk-w-60">
																	UNDER THE HOOD
												</th>
												<th className="text-center desk-w-20">
																Passed
												</th>
												<th className="text-center desk-w-20">
																Fixed Or Replaced
												</th>
											</tr>
										</thead>
										<tbody className="CarvanaTab">

											<tr>
												<td>Battery(condition,fluid level,load test)</td>
												<td>
													<AllInputs
														value={hood_1_passed.value || ''}
														type={'switch-userstamp'}
														name={'Battery(condition,fluid level,load test)_passed'}
														parentid={vehicle.id}
														updatetype="vehicleproperty"
														setdisabled={setdisabled}
														user_name={hood_1_passed.user_name}
														updated_at={hood_1_passed.updated_at}
														vehicle={vehicle}
													/>
												</td>
												<td>
													<AllInputs
														value={hood_1_fixed.value || ''}
														type={'switch-userstamp'}
														name={'Battery(condition,fluid level,load test)_fixed'}
														parentid={vehicle.id}
														updatetype="vehicleproperty"
														setdisabled={setdisabled}
														user_name={hood_1_fixed.user_name}
														updated_at={hood_1_fixed.updated_at}
														vehicle={vehicle}
													/>
												</td>
											</tr>

										</tbody>
									</table>
								</div>

							</div>

						</div>
					</div>

				</div>
			</div>

		);
	}
}
const mappedActions = {
	lookupChecked,
	addVehicleProperty,
	lookupVehiclePropertyValueByName,
};

function mapStateToProps(state) {
	return { active_vehicle: state.settings_refresh.active_vehicle };
}

export default connect(mapStateToProps, mappedActions)(CarvanaTab);
