import _ from 'lodash';
import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as lookups from './lookups';
import * as AllUpdates from './AllUpdates';

export function mutateVehicleProperty(vehicle_id, resp) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let depart = [];
		depart = _.find(departments, _.flow(
				    _.property('vehicles'),
				    _.partialRight(_.some, { id: vehicle_id }),
		));

		if (typeof depart !== 'undefined') {
			const index = _.findIndex(depart.vehicles, { id: parseInt(vehicle_id) });
			const departmentIndex = _.findIndex(departments, { id: parseInt(depart.id) });

			const nextdepts = departments.map((department, ind) => {
				if (departmentIndex !== ind) {
					return department;
				}
				const newDepartment = department;

				newDepartment.vehicles = department.vehicles.map((vehicle, indv) => {
					if (index !== indv) {
						return vehicle;
					}

					const propertyArray = vehicle.vehicle_properties.slice();

					_.remove(propertyArray, { key: resp.data.data.key });

					const newObj2 = propertyArray.map(vehicle_property => vehicle_property);
					vehicle.vehicle_properties = newObj2;
					vehicle.vehicle_properties.push(resp.data.data);
					return vehicle;
				});
				return newDepartment;
			});

			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				Promise.all([
					dispatch(setInfo.setDepartments(nextdepts)),
				]).then(() => {
					dispatch(lookups.vsearch(vehicle_filter.value));
				});
			} else {
				dispatch(setInfo.setDepartments(nextdepts));
			}
		}
	};
}

export function mutateVehicleNotes(vehicle_id, resp) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let depart = [];
		depart = _.find(departments, _.flow(
				    _.property('vehicles'),
				    _.partialRight(_.some, { id: vehicle_id }),
		));

		if (typeof depart !== 'undefined') {
			const index = _.findIndex(depart.vehicles, { id: parseInt(vehicle_id) });
			const departmentIndex = _.findIndex(departments, { id: parseInt(depart.id) });

			const nextdepts = departments.map((department, ind) => {
				if (departmentIndex !== ind) {
					return department;
				}
				const newDepartment = department;

				newDepartment.vehicles = department.vehicles.map((vehicle, indv) => {
					if (index !== indv) {
						return vehicle;
					}
					vehicle.vehicle_notes.push(resp.data.data);
					return vehicle;
				});
				return newDepartment;
			});

			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				Promise.all([
					dispatch(setInfo.setDepartments(nextdepts)),
				]).then(() => {
					dispatch(lookups.vsearch(vehicle_filter.value));
				});
			} else {
				dispatch(setInfo.setDepartments(nextdepts));
			}
		}
	};
}

export function mutateVehicleAttachments(vehicle_id, resp) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let depart = [];
		depart = _.find(departments, _.flow(
				    _.property('vehicles'),
				    _.partialRight(_.some, { id: vehicle_id }),
		));

		if (typeof depart !== 'undefined') {
			const index = _.findIndex(depart.vehicles, { id: parseInt(vehicle_id) });
			const departmentIndex = _.findIndex(departments, { id: parseInt(depart.id) });

			const nextdepts = departments.map((department, ind) => {
				if (departmentIndex !== ind) {
					return department;
				}
				const newDepartment = department;

				newDepartment.vehicles = department.vehicles.map((vehicle, indv) => {
					if (index !== indv) {
						return vehicle;
					}
					vehicle.vehicle_attachments.push(resp.data.data);
					return vehicle;
				});
				return newDepartment;
			});

			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				Promise.all([
					dispatch(setInfo.setDepartments(nextdepts)),
				]).then(() => {
					dispatch(lookups.vsearch(vehicle_filter.value));
				});
			} else {
				dispatch(setInfo.setDepartments(nextdepts));
			}
		}
	};
}
export function mutateWorkItem(vehicle_id, resp) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let depart = [];
		depart = _.find(departments, _.flow(
				    _.property('vehicles'),
				    _.partialRight(_.some, { id: vehicle_id }),
		));

		if (typeof depart !== 'undefined') {
			const index = _.findIndex(depart.vehicles, { id: parseInt(vehicle_id) });
			const departmentIndex = _.findIndex(departments, { id: parseInt(depart.id) });

			const nextdepts = departments.map((department, ind) => {
				if (departmentIndex !== ind) {
					return department;
				}
				const newDepartment = department;

				newDepartment.vehicles = department.vehicles.map((vehicle, indv) => {
					if (index !== indv) {
						return vehicle;
					}

					vehicle.work_items.push(resp.data.data);
					return vehicle;
				});
				return newDepartment;
			});

			const { vehicle_filter, department } = getState().settings_nav;

			dispatch(lookups.activeVehicle(vehicle_id));

			if (vehicle_filter.type === 'searched') {
				Promise.all([
					dispatch(setInfo.setDepartments(nextdepts)),
				]).then(() => {
					dispatch(lookups.vsearch(vehicle_filter.value));
				});
			} else {
				dispatch(setInfo.setDepartments(nextdepts));
			}
		}
	};
}

export function mutateWorkItem2(vehicle_id, resp) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let depart = [];
		depart = _.find(departments, _.flow(
				    _.property('vehicles'),
				    _.partialRight(_.some, { id: vehicle_id }),
		));

		if (typeof depart !== 'undefined') {
			const index = _.findIndex(depart.vehicles, { id: parseInt(vehicle_id) });
			const departmentIndex = _.findIndex(departments, { id: parseInt(depart.id) });

			const nextdepts = departments.map((department, ind) => {
				if (departmentIndex !== ind) {
					return department;
				}
				const newDepartment = department;

				newDepartment.vehicles = department.vehicles.map((vehicle, indv) => {
					if (index !== indv) {
						return vehicle;
					}

					const work_items = vehicle.work_items.map((work_item, dex) => {
						if (dex !== resp.data.data.id) {
							return work_item;
						}
						return resp.data.data;
					});
					vehicle.work_items = work_items;

					return vehicle;
				});
				return newDepartment;
			});

			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				Promise.all([
					dispatch(setInfo.setDepartments(nextdepts)),
				]).then(() => {
					dispatch(lookups.vsearch(vehicle_filter.value));
				});
			} else {
				dispatch(setInfo.setDepartments(nextdepts));
			}
		}
	};
}

export function mutateEstimates(vehicle_id, resp) {
	return (dispatch, getState) => {
		const vehicle = getState().settings_refresh.active_vehicle;

		let pendingCount = 0;
		let successCount = 0;
		let warningCount = 0;
		let status_estimates;

		vehicle.appraisals.map((est) => {
			if (est.ro_status === 'pending') {
				pendingCount += 1;
			} else if (est.ro_status === 'approved') {
				successCount += 1;
			} else if (est.ro_status === 'denied') {
				warningCount += 1;
			} else if (est.ro_status === 'getapproved') {
				warningCount += 1;
			}
			return true;
		});

		if (pendingCount > 0) {
			status_estimates = 'pending';
		} else if (successCount > 0) {
			status_estimates = 'success';
		} else if (warningCount > 0) {
			status_estimates = 'warning';
		} else {
			status_estimates = '';
		}

		const errorType = 'addVehicleProperty';

		const data2 = {
			vehicle_id,
			key: 'status_estimates',
			value: status_estimates,
		};

		Api.postData('vehicle_properties/', data2);

		if (status_estimates === 'success') {
			const { installation } = getState().settings_dealerships;

			if (installation.database === 'walser') {
				const departmentCurrent = dispatch(lookups.lookupDepartmentByName('stage three - cosmetic work items'));
				if (vehicle.department_id === parseInt(departmentCurrent.id)) {
					const next_department = dispatch(lookups.lookupDepartmentByName('stage four - detail assignment'));
					dispatch(AllUpdates.updateVehicleDepartment(vehicle_id, 'department_id', parseInt(next_department.id)));
				}
			}
		}

		dispatch(lookups.activeVehicle(vehicle_id));
	};
}

export function mutateEstimatesDelete(id, vehicle_id) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let depart = [];
		depart = _.find(departments, _.flow(
				    _.property('vehicles'),
				    _.partialRight(_.some, { id: vehicle_id }),
		));

		if (typeof depart !== 'undefined') {
			const index = _.findIndex(depart.vehicles, { id: parseInt(vehicle_id) });
			const departmentIndex = _.findIndex(departments, { id: parseInt(depart.id) });

			const nextdepts = departments.map((department, ind) => {
				if (departmentIndex !== ind) {
					return department;
				}
				const newDepartment = department;

				newDepartment.vehicles = department.vehicles.map((vehicle, indv) => {
					if (index !== indv) {
						return vehicle;
					}

					const appraisalArray = vehicle.appraisals.slice();

					_.remove(appraisalArray, { id });

					const newObj2 = appraisalArray.map(vehicle_property => vehicle_property);
					vehicle.appraisals = newObj2;

					let pendingCount = 0;
					let successCount = 0;
					let warningCount = 0;
					let status_estimates;

					newObj2.map((est) => {
						if (est.ro_status === 'pending') {
							pendingCount += 1;
						} else if (est.ro_status === 'approved') {
							successCount += 1;
						} else if (est.ro_status === 'denied') {
							warningCount += 1;
						} else if (est.ro_status === 'getapproved') {
							warningCount += 1;
						}
						return true;
					});

					if (pendingCount > 0) {
						status_estimates = 'pending';
					} else if (successCount > 0) {
						status_estimates = 'success';
					} else if (warningCount > 0) {
						status_estimates = 'warning';
					} else {
						status_estimates = '';
					}

					const errorType = 'addVehicleProperty';

					const data2 = {
						vehicle_id,
						key: 'status_estimates',
						value: status_estimates,
					};
					Api.postData('vehicle_properties/', data2);

					const propertyArray = vehicle.vehicle_properties.slice();

					_.remove(propertyArray, { key: 'status_estimates' });

					const newObj3 = propertyArray.map(vehicle_property => vehicle_property);
					vehicle.vehicle_properties = newObj3;
					vehicle.vehicle_properties.push(data2);

					if (status_estimates === 'success') {
						const { installation } = getState().settings_dealerships;

						if (installation.database === 'walser') {
							const departmentCurrent = dispatch(lookups.lookupDepartmentByName('stage three - cosmetic work items'));
							if (vehicle.department_id === parseInt(departmentCurrent.id)) {
								const next_department = dispatch(lookups.lookupDepartmentByName('stage four - detail assignment'));
								dispatch(AllUpdates.updateVehicleDepartment(vehicle_id, 'department_id', parseInt(next_department.id)));
							}
						}
					}

					return vehicle;
				});
				return newDepartment;
			});

			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				Promise.all([
					dispatch(setInfo.setDepartments(nextdepts)),
				]).then(() => {
					dispatch(lookups.vsearch(vehicle_filter.value));
				});
			} else {
				dispatch(setInfo.setDepartments(nextdepts));
			}
		}
	};
}
