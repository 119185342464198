import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import axios, { post } from 'axios';
import Dropzone from 'react-dropzone';

class DropList extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		this.props.onClickDelete(this.props.f.name);
	}

	render() {
		const { f } = this.props;
		return (
			<li className="list-group-item" key={f.name}>
				{
					f.type.includes('image') ? <img src={f.preview} className={'img-thumbnail'} /> : ''
				}
				{
					f.type.includes('pdf') ? <i className={'fas fa-file-pdf'} /> : ''
				}
				{
					f.type.includes('word') ? <i className={'fas fa-file-word'} /> : ''
				}
				{
					f.type.includes('csv') ? <i className={'fas fa-file-excel'} /> : ''
				}
				{
					f.type.includes('xls') ? <i className={'fas fa-file-excel'} /> : ''
				}
				{ f.name}
				{' '}
-
				{f.size}
				{' '}
bytes --
				{this.props.user_name}
				{' '}
				<button onClick={this.onClick} className="btn btn-sm btn-danger float-right"><i className={'fas fa-minus-square'} /></button>
			</li>

		);
	}
}

function mapStateToProps(state) {
	return { user_name: state.current_user.user_name };
}
export default connect(mapStateToProps)(DropList);
