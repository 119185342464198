import React, { Component } from 'react';
import { connect } from 'react-redux';

class Messages extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (

			<div className="container-fluid text-center">
Messages
			</div>

		);
	}
}

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
	};
}
export default connect(mapStateToProps)(Messages);
