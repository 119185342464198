import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { readableStamp } from '../../utils/dateFunctions';

class FeedLogs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: [],
		};
	}

	handleRowClick(rowId) {
		const currentExpandedRows = this.state.expandedRows;
		const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

		const newExpandedRows = isRowCurrentlyExpanded
			? currentExpandedRows.filter(department => department !== rowId)
			: currentExpandedRows.concat(rowId);

		this.setState({ expandedRows: newExpandedRows });
	}

	getData(vehicles) {
		const data = vehicles.map((vehicle) => {
			const obj = {
				stock: 'teststock',
			};
			return obj;
		});

		return data;
	}

	openSingle() {

	}

	renderItem(item) {
		const clickCallback = () => this.handleRowClick(item.id);
		const itemRows = [
			<tr onClick={clickCallback} key={`row-data-${item.id}`}>
			    <td><button className="btn btn-sm btn-success"><icon className="fal fa-arrow-right" /></button></td>
			    <td>{readableStamp(item.feed_time)}</td>
			    <td>{item.count}</td>
			    <td>{item.count_in_feed}</td>
			    <td>{item.count_new_in_feed}</td>
			    <td>{item.count_used_in_feed}</td>
			    <td>{item.count_wholesale_in_feed}</td>
			</tr>,
		];

		if (this.state.expandedRows.includes(item.id)) {
			itemRows.push(
				<tr key={`row-expanded-${item.id}`}>
					<td colSpan="7">
						<table className="table table-dark table-striped w100">
							<thead>
								<tr>
									<th>Stock</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>stock holdplace</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>,
			);
		}

		return itemRows;
	}

	render() {
		const self = this;
		const feed_logs = self.props.feed_logs;

		const tableData = [];
		const data = self.props.feed_logs.map((feed_log, index) => {
			const obj = {
				id: feed_log.id,
				feed_time: feed_log.created_at,
				count: feed_log.count,
				count_in_feed: feed_log.count_in_feed,
				count_new_in_feed: feed_log.count_new_in_feed,
				count_used_in_feed: feed_log.count_used_in_feed,
				count_wholesale_in_feed: feed_log.count_wholesale_in_feed,
			};
			return obj;
		});

		tableData.push(data);

		let allItemRows = [];

		data.forEach((item) => {
			if (item.count > 0) {
				const perItemRows = this.renderItem(item);
				allItemRows = allItemRows.concat(perItemRows);
			}
		});

		return (
			<table className="table table-striped w100">
				<thead>
					<tr>
						<th />
						<th>FeedTime</th>
						<th>Count</th>
						<th>Count - In Feed</th>
						<th>Count New - In Feed</th>
						<th>Count Used - In Feed</th>
						<th>Count Wholesale - In Feed</th>
					</tr>
				</thead>
				{allItemRows}
			</table>
		);
	}
}

function mapStateToProps(state) {
	return {
		feed_logs: state.settings_feed.feed_logs,
	};
}
export default connect(mapStateToProps)(FeedLogs);
