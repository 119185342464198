import _ from 'lodash';
import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as lookups from './lookups';
import * as apiEstimates from './apiEstimates';
import * as toggleMenu from './toggleMenu';
import * as AllMutates from './AllMutates';

export function updateVehicle(vid, meta, value) {
	return (dispatch, getState) => {
		const errorType = 'updateVehicle';
		Api.patch(`vehicles/${vid}`, meta, value).then(res => 'success').catch(ex => Api.handleApiError(ex, errorType));
	};
}

export function updateVehicleData(vid, data, department_id = '') {
	const errorType = 'updateVehicleData';
	return (dispatch, getState) => {
		Api.patchData(`vehicles/${vid}`, data).then((resp) => {
			dispatch(lookups.activeVehicle(vid));
			dispatch(getInfo.getCurrentDepartment(resp.data.data.department_id));
		}).catch(ex => Api.handleApiError(ex, errorType));
	};
}

export function updateVehicleDataRaw(vid, data, department_id = '') {
	const errorType = 'updateVehicleData';
	return (dispatch, getState) => {
		Api.patchData(`vehicles/${vid}`, data).then((resp) => {
			const { data_step_targets } = getState().settings_departments;
		}).catch(ex => Api.handleApiError(ex, errorType));
	};
}

export function addVehicle(data, extra, errorType = 'addVehicle Error') {
	return (dispatch) => {
		const { department_id } = data;

		return Api.postData('vehicles/', data).then((resp) => {
			const vehicle_id = resp.data.data.id;

			if (vehicle_id) {
				dispatch(updateVehicleDepartmentHistory(vehicle_id, department_id));
				dispatch(getInfo.refreshDepartment(department_id));
			}

			return 'vehicle added';
		}).catch(ex => Api.handleApiError(ex, errorType));
	};
}

export function deleteVehicle(id, department_id, errorType = 'deleteVehicle Error') {
	return (dispatch, getState) => Api.delete(`vehicles/${id}`).then((resp) => {
		const { vehicle_filter,department } = getState().settings_nav || [];
		dispatch(getInfo.refreshDepartment(department_id));
		if (vehicle_filter.type === 'searched') {
			dispatch(lookups.vsearch('none'));
		} else {
			dispatch(getInfo.getCurrentDepartment(parseInt(department)));
		}
	});
}

export function addVehicleProperty(data, errorType) {
	return (dispatch, getState) => Api.postData('vehicle_properties/', data).then((resp) => {
		const { data_step_targets } = getState().settings_departments;
		dispatch(getInfo.processDepartments());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addVehiclePropertyRaw(data, errorType) {
	return (dispatch, getState) => Api.postData('vehicle_properties/', data).then((resp) => {
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateVehicleProperty(vehicle_id, data_id, data, errorType) {
	return (dispatch, getState) => Api.postData('vehicle_properties/', data).then((resp) => {
		const { data_step_targets } = getState().settings_departments;
		dispatch(getInfo.processDepartments());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateVehicleDepartment(vid, meta, value, vehicle) {
	let updated_value = value;

	return (dispatch, getState) => {
		const departments = getState().settings_departments.original_departments;
		const { installation } = getState().settings_dealerships;
		let data = {};

		const newObj = _.filter(departments, d => parseInt(value) === parseInt(d.id));

		// transfer subdomain for the selected department
		const { subdomain } = newObj[0];

		// department at the  transfer domain
		const { subdomain_department } = newObj[0];

		// department type
		const { department_type } = newObj[0];

		// set temp department as selected department if transfer
		let temp_department = value;

		const tempObj = _.filter(departments, d => parseInt(subdomain_department) === parseInt(d.id));

		let dealership = '';

		dealership = vehicle.dealership;
		const just_refresh = true;

		if (department_type === 'return to origin') {
			temp_department = '';

			if ((typeof tempObj[0] !== 'undefined') && tempObj[0].department_type === 'transfer') {
				data = {
					id: vid,
					subdomain: tempObj[0].subdomain,
					department_id: tempObj[0].subdomain_department,
					temp_department: subdomain_department,
				};
			} else {
				data = {
					id: vid,
					subdomain: dealership,
					department_id: subdomain_department,
					temp_department: '',
				};
			}

			Promise.all([
				dispatch(updateVehicleDepartmentHistory(vid, subdomain_department)),
				dispatch(updateVehicleData(vid, data)),
			]).then(() => {
				dispatch(getInfo.refreshDepartment(data.department_id));
				dispatch(getInfo.refreshDepartment(vehicle.department_id));
				dispatch(getInfo.processDepartments());
			});

			const { vehicle_filter,department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
		} else if (department_type === 'transfer') {
			data = {
				id: vid,
				subdomain,
				department_id: subdomain_department,
				temp_department,
			};

			Promise.all([
				dispatch(updateVehicleDepartmentHistory(vid, subdomain_department)),
				dispatch(updateVehicleData(vid, data)),
			]).then(() => {
				dispatch(getInfo.refreshDepartment(subdomain_department));
				dispatch(getInfo.refreshDepartment(temp_department));
				dispatch(getInfo.refreshDepartment(vehicle.department_id));
				dispatch(getInfo.processDepartments());
			});

			const { vehicle_filter,department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
		} else {
			if (installation.database === 'walser') {
				if (newObj[0].name === 'stage four - detail assignment') {
					const onsite_needed = _.find(vehicle.vehicle_properties, { key: 'on site - interior' }) || [];
					const sublet_needed = _.find(vehicle.vehicle_properties, { key: 'sublet detail' }) || [];
					const touch_up_complete_needed = _.find(vehicle.vehicle_properties, { key: 'on site - exterior and touch up' }) || [];
					let data_est = {};
					if ((onsite_needed.value === 'completed' || sublet_needed.value === 'completed') && (touch_up_complete_needed.value === 'completed')) {
						const interior_needed = _.find(vehicle.vehicle_properties, { key: 'anderson auto interior' }) || [];
						if (interior_needed.value !== 'needed') {
							const department = dispatch(lookups.lookupDepartmentByName('stage six - digital listings'));
							updated_value = parseInt(department.id);
						}
					} else if (onsite_needed.value === 'completed' || sublet_needed.value === 'completed') {
						data_est = {
							vehicle_id: vid,
							value: 'needed',
							key: 'on site - exterior and touch up',
						};
						Api.postData('vehicle_properties/', data_est);
					} else {
						data_est = {
							vehicle_id: vid,
							value: 'needed',
							key: 'on site - interior',
						};
						Api.postData('vehicle_properties/', data_est);
					}
				} else if (newObj[0].name === 'stage three - cosmetic work items - ready') {
					const touchupOnly = _.find(vehicle.vehicle_properties, { key: 'touch up only' }) || [];

					if (touchupOnly.value === 'true') {
						const department = dispatch(lookups.lookupDepartmentByName('stage four - detail assignment'));
						updated_value = parseInt(department.id);
					} else {
						Api.get(`appraisals/?q[vehicle_id_eq]=${vid}`, '').then((resp) => {
							const estimates = resp.data;

							estimates.map((estimate) => {
								const data_est = {
									vehicle_id: vid,
									ro_status: 'pending',
								};
								let key = '';

								switch (estimate.assigned) {
								case 'safelite':
									key = 'safelite auto glass';
									break;
								case 'alloywheels':
									key = 'alloy wheels repair';
									break;
								case 'theglassman':
									key = 'the glass man';
									break;
								case 'allanglescollision':
									key = 'all angles collision repair';
									break;
								case 'dentcenter':
									key = 'dent center';
									break;
								case 'scholfield':
									key = 'scholfield hatchett';
									break;
								case 'discountautoglass':
									key = 'discountautoglass';
									break;
								case 'andersonauto_interior':
									key = 'anderson auto interior';
									break;
								case 'autoimagebody':
									key = 'auto image - body shop';
									break;
								case 'autoimage_pdr':
									key = 'auto image - pdr';
									break;
								case 'bodyshopneeded':
									key = 'body shop needed';
									break;
								default:
									key = '';
									break;
								}

								const data2 = {
									vehicle_id: vid,
									key,
									value: 'needed',
								};

								Api.postData('vehicle_properties/', data2);

								dispatch(apiEstimates.sendEstimate(estimate.id, data_est, 'addEstimateError'));
							});
						});
					}
				}
			}

			const just_refresh = true;
			const { vehicle_filter, department } = getState().settings_nav;

			Promise.all([
				dispatch(updateVehicleDepartmentHistory(vid, updated_value)),
			]).then(() => {
				dispatch(getInfo.refreshDepartment(vehicle.department_id));
				dispatch(getInfo.refreshDepartment(updated_value));
				if (vehicle_filter.type === 'searched') {
					dispatch(lookups.vsearch(vehicle_filter.value));
				} else {
					dispatch(getInfo.getCurrentDepartment(vehicle.department_id));
					dispatch(getInfo.refreshDepartment(parseInt(vehicle_filter.value)));
				}
			}).then(() => {
				dispatch(getInfo.processDepartments());
			});
		}
	};
}

export function updateVehicleDepartmentHistory(vid, value) {
	return (dispatch, getState) => {
		const { user } = getState().current_user;
		const data = {
			vehicle_id: vid,
			department_id: value,
			uid: user,
		};

		return Api.postData('department_histories/', data);
	};
}

export function updateDeleteVehicle(vid, department_id) {
	return (dispatch, getState) => {
		const errorType = 'updateVehicle';
		return Api.patch(`vehicles/${vid}`, 'deleted', true).then((res) => {
			const { vehicle_filter,department } = getState().settings_nav || [];
			dispatch(getInfo.refreshDepartment(department_id));
			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch('none'));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
		}).catch(ex => Api.handleApiError(ex, errorType));
	};
}
