import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import FlashMessageList from '../../inputs/FlashMessageList';
import Api from '../../api/reconApi.js';
import {
	setSearchedUsers,
	setUsersTab,
} from '../../actions/apiSetInfo';
import { addNewUser } from '../../actions/users';
import { addFlashMessage, deleteFlashMessages } from '../../actions/toggleMenu';

class AddUserButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, email: '', user_name: '', dealerships: this.props.current_dealership, password: '', errors: { addNewUserError: false },
		};
		this.onChange = this.onChange.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.addNewUser = this.addNewUser.bind(this);
		this.changePage = this.changePage.bind(this);
	}

	changePage(e) {
		this.props.setSearchedUsers({ searched_users: [] });
		this.props.setUsersTab(e.target.name);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	validateAddUser(data) {
		const result = {
			isValid: true,
		};
		return result;
	}

	addNewUser() {
		const self = this;
		const localurl = Api.localurl();
		const data = {
			email: this.state.email,
			password: 'LRIH45UTfwPtIFOil_1kP6dsw-',
			confirmation_redirect_url: `${localurl}/password_resets`,
		};
		const { isValid, errorMessage } = this.validateAddUser(data);
		if (!isValid) {
			this.props.addFlashMessage({
				type: 'error',
				text: errorMessage,
			});
			return;
		}

		const errorType = 'addNewUserError';
		this.props.addNewUser(data, errorType).then((resp) => {
			if (resp == 'success') {
				this.setState({ errors: { addNewUserError: false } });
				this.setState({ password: '' });
				this.setState({ email: '' });
				this.setState({ user_name: '' });
				this.props.addFlashMessage({
					type: 'success',
					text: 'User has been successfully added',
				});
				this.setState({ isLoading: false });
				setTimeout(() => { self.props.deleteFlashMessages(); }, 3000);
			} else {
				const { errors } = resp;
				this.props.addFlashMessage({
					type: 'error',
					text: errors.addNewUserError,
				});
				setTimeout(() => { self.props.deleteFlashMessages(); }, 3000);
			}
		});
	}

	render() {
		const { errors } = this.state;
		return (
			<div className="col-md-6 pad-top-1">
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					className="d-flex justify-content-center"
					contentLabel="Add User"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-6 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="card-header bg-dark text-white">
                     Add User
							{' '}
							<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>x</button>
						</div>
						<div className="card-text">

							<div className="d-flex flex-column">
								<FlashMessageList {...this.props} />
								{/*
									<div className="p-2">
										<TextFieldGroup
											field="user_name"
											name="user_name"
											label="Add Username"
											onChange={this.onChange}
											value={this.state.user_name}
											type="text"
											error={errors.addNewUserError}
										/>

									</div>
								*/}

								<div className="p-2">
									<TextFieldGroup
										field="email"
										name="email"
										label="Add Email"
										onChange={this.onChange}
										value={this.state.email}
										type="text"
									/>
								</div>
								{/*
								<div className="p-2">
									<TextFieldGroup
										field="password"
										name="password"
										label="Password (optional)"
										onChange={this.onChange}
										value={this.state.password}
										type="password"
									/>
								</div>
								*/}

							</div>
							<div className="card-footer text-center">
								<button className="btn btn-success" onClick={this.addNewUser}>
									<i className="fas fa-user-plus" />
									{' '}
Add
								</button>
							</div>
						</div>

					</div>

				</Modal>
				<div className="btn-group">
					<button className="btn btn-sm btn-primary space-right-2" name="default-profiles" onClick={this.changePage}>
						<i className="fas fa-users" />
						{' '}
Default Profiles
					</button>
					<button className="btn btn-sm btn-success space-right-2" onClick={this.openModal}>
						<i className="fas fa-user-plus" />
						{' '}
Add New User
					</button>
					<button className="btn btn-sm btn-primary" name="users" onClick={this.changePage}>
						<i className="fas fa-users" />
						{' '}
All Users
					</button>
					{/* <button className="btn btn-sm btn-primary" name="notification-settings" onClick={this.changePage}><i className="fas fa-envelope-o"></i> Notification Settings</button> */}
				</div>
			</div>
		);
	}
}

const mappedActions = {
	setSearchedUsers,
	setUsersTab,
	addNewUser,
	addFlashMessage,
	deleteFlashMessages,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.departments,
		current_dealership: state.settings_dealerships.current_dealership,
	};
}
export default connect(mapStateToProps, mappedActions)(AddUserButton);
