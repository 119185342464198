import React from 'react'
import { connect } from 'react-redux';
import {
	setCurrentDealership,
} from '../../actions/apiSetInfo';
import {
	updateInstallation,
} from '../../actions/apiDealerUpdates';
import {
	change_page,
} from '../../actions/toggleMenu';

import './DealershipLink.scss'
function DealershipLink(props){
  const {dealership,current_user} = props
  const selectLocation = () =>{
    props.setCurrentDealership(dealership.database, dealership.subdomain, dealership.id);
  }
  const editLocationUsers = () =>{
    selectLocation()
    props.change_page('users')
  }
  return(
    <div className="dealership-link-container">
      <div className="dealership-link">
        <div className="dealership-link-info">
          <div className="dealership-link-info-primary">
            <div className="dealership-link-dealer-name">{dealership.softname?dealership.softname:'N/A'}</div>
            <div className="dealership-link-dealer-id" >Dealer ID: {dealership.feedprefix?dealership.feedprefix:'N/A'}</div>
          </div>
          <div className="dealership-link-info-secondary">
            <div className="dealership-link-info-secondary-item">{dealership.city?dealership.city:'N/A'}</div>
            <div className="dealership-link-info-secondary-item">{dealership.state?dealership.state:'N/A'}</div>
          </div>
        </div>
        <div className="dealership-link-buttons">
          <button onClick={()=>{selectLocation()}}>Select Location</button>
          {
            (current_user.position === 'admin' || current_user.position === 'super_admin')?
            <button onClick={()=>{editLocationUsers()}}>Edit Users</button>
            :
            null
          }
        </div>
      </div>
    </div>
  )
}
const mappedActions = {
	setCurrentDealership,
	updateInstallation,
  change_page
};

function mapStateToProps(state) {
	return {
		display_options: state.settings_admin.set_dealership_display_options,
		current_user: state.current_user,
	};
}
export default connect(mapStateToProps, mappedActions)(DealershipLink);
