import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as AllMutates from './AllMutates';

export function addMehanicalNotification(data, errorType) {
	return () => Api.postData('notifications/', data).then(() => 'success').catch(ex => Api.handleApiError(ex, errorType));
}

export function appraisalRequestNotification(data, errorType) {
	return () => Api.postData('notifications/', data).then(() => 'success').catch(ex => Api.handleApiError(ex, errorType));
}

export function appraisalNotification(data, errorType, subdomain) {
	return () => Api.postDataNotification('notifications/', data, subdomain).then(() => 'success').catch(ex => Api.handleApiError(ex, errorType));
}

export function notesNotification(data, errorType) {
	return () => Api.postData('notifications/', data, subdomain).then(() => 'success').catch(ex => Api.handleApiError(ex, errorType));
}

export function addNotificationSetting(data, errorType) {
	return dispatch => Api.postData('notification_settings/', data).then(() => {
  		dispatch(getInfo.getNotificationSettings());
 		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteNotificationSetting(id, errorType) {
	return dispatch => Api.delete(`notification_settings/${id}`).then(() => {
		dispatch(getInfo.getNotificationSettings());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}
