import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import DropdownMultiple from './DropdownMultiple';
import Dropdown from './Dropdown';
import { formatPhoneNumber } from '../utils/dateFunctions';
import { editUserProfile, addUserInstallation, deleteUserInstallation } from '../actions/users';

class EditUserInputs extends Component {
	constructor(props) {
		super(props);

		this.state = { value: this.props.value || '', checked: this.props.checked || '' };
		this.onChange = this.onChange.bind(this);
		this.onChangePhoneNumber = this.onChangePhoneNumber.bind(this);
		this.onBlurPhoneNumber = this.onBlurPhoneNumber.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onChangeSelectDealership = this.onChangeSelectDealership.bind(this);
		this.onClearDealership = this.onClearDealership.bind(this);
	}

	onChange(event) {
		this.setState({ value: event.target.value });
	}

	onChangePhoneNumber(event) {
		if (event.target.value.replace(/\D+/g, '').length < 11) {
			this.setState({ value: formatPhoneNumber(event.target.value) ? formatPhoneNumber(event.target.value) : event.target.value.replace(/\D+/g, '') });
		}
	}

	componentDidMount() {
		if (this.props.type === 'phone_number') {
			this.setState({ value: formatPhoneNumber(this.state.value) ? formatPhoneNumber(this.state.value) : this.state.value.replace(/\D+/g, '') });
		}
	}

	onChangeInput(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'editUserProfileError';
			const data = {
				id: self.props.parentid,
				[e.target.name]: e.target.value,
			};

			self.setState({ value: e.target.value });

			self.props.editUserProfile(self.props.user_id, data, errorType);
		}
	}

	onBlur(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'editUserProfileError';
			const data = {
				[self.props.name]: e.target.value,
			};

			self.setState({ value: e.target.value });

			self.props.editUserProfile(self.props.user_id, data, errorType);
		}
	}

	onBlurPhoneNumber(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'editUserProfileError';
			const data = {
				[self.props.name]: e.target.value.replace(/\D+/g, ''),
			};

			self.setState({ value: e.target.value });

			self.props.editUserProfile(self.props.user_id, data, errorType);
		}
	}

	onChangeSelect(event) {
		this.setState({ value: event.value });

		const errorType = 'editUserProfile';

		const data = {
			[this.props.name]: event.value,
		};

		this.props.editUserProfile(this.props.user_id, data, errorType);
	}

	onChangeSelectDealership(event) {
		const self = this;
		const selectedDealerships = this.selectedDealerships();

		const installArray = event.map(dealer => dealer.value);

		if (installArray.length > selectedDealerships.length) {
			event.map((dealer) => {
				if (selectedDealerships.indexOf(dealer.value) === -1) {
					this.setState({ value: dealer.value });

					const errorType = 'addUserInstallation';

					const { user_id } = this.props;

					const data = {
						installation_id: dealer.value,
						user_id,
					};

					this.props.addUserInstallation(data, errorType);
				}
			});
		} else {
			const diffArray = _.difference(selectedDealerships, installArray);
			diffArray.map((id) => {
				self.props.dealerships.map((user_installation) => {
					if (user_installation.installation_id === id) {
						self.props.deleteUserInstallation(user_installation.id);
					}
				});
			});
		}
	}

	onClearDealership(event) {
		this.props.deleteUserInstallation(event[0].value);
	}

	input_types() {
		return Object.keys(this.props.input_types).map(key => this.props.input_types[key]);
	}

	onChangeChecked(e) {
		const self = this;
		const errorType = 'editVehicleColumnError';
		const data = {
			id: self.props.user_id,
			[e.target.name]: e.target.checked,
		};
		self.setState({ value: e.target.checked });

		self.props.editUserProfile(self.props.user_id, data, errorType);
	}

	selectDealerships() {
		const dealerships = this.props.set_dealerships.map((option) => {
			if (typeof option.softname !== 'undefined') {
				var obj = { value: option.id, label: option.softname };
			} else {
				var obj = { value: option.id, label: option.softname };
			}
			return obj;
		});

		return dealerships;
	}

	selectedDealerships() {
		var user = this.props.all_users;

		const self = this;
		const user_id = self.props.user_id || self.props.single_user;
		var user = _.sortBy(_.filter(self.props.all_users, o => o.id === user_id), 'id');

		const installs = [];

		self.props.set_dealerships.map((option) => {
			option.user_installations.map((o) => {
				if (o.user_id === user[0].id) {
					const obj = { value: o.installation_id, label: option.softname };
					installs.push(obj);
				}
			});
		});

		return installs;
	}

	selectRoles() {
		const roles = ['superadmin', 'admin', 'user'];
		const rolesList = roles.map((option) => {
			if (typeof option.softname !== 'undefined') {
				var obj = { value: option, label: option };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});

		return rolesList;
	}

	selectNotifications() {
		const roles = [{ id: 1, name: 'email' }, { id: 2, name: 'text' }, { id: 3, name: 'both' }];
		const rolesList = roles.map((option) => {
			var obj = { id: option.id, label: option.name };
			return obj;
		});

		return rolesList;
	}

	selectProfiles() {
		const profiles = [];

		const default_profiles = this.props.default_profiles.map((option) => {
			if (option.name !== 'super_admin') {
				var obj = { id: option.id, label: option.name };
				profiles.push(obj);
			}
		});

		return profiles;
	}

	selectRelatedInstallations() {
		const dealerships = this.props.dealerships || [];
		const selected_dealerships = [];

		dealerships.map((option) => {
			selected_dealerships.push(option.installation_id);
		});

		const options = this.props.set_dealerships.map((option) => {
			let selected = false;
			let dataid = 0;
			if (selected_dealerships.includes(option.id)) {
				selected = true;
				const user_installation_id = _.filter(dealerships, o => o.installation_id === option.id);
				dataid = user_installation_id[0].id;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, selected, dataid,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, selected, dataid,
				};
			}
			return obj;
		});
		return options;
	}

	returnDescription() {
		switch (this.props.name) {
		case 'all_departments':
			return 'Access to All Departments/Steps';

		case 'all_reports':
			return 'Access to All Reports without restrictions';

		case 'parts_overdue':
			return 'Access to the list of parts overdue';

		case 'in_progress':
			return 'Access to the in progress reports';

		case 'step_times':
			return 'Access to All Step Times Reports for All Stages';

		case 'monthly_averages':
			return 'Access to All Monthly Averages';

		case 'department_averages':
			return 'Access to All Department Averages';

		case 'email_reports':
			return 'Access to Create and Edit Emailable Reports';

		case 'advanced_search':
			return 'Access to Advanced Search of all inventory including deleted and sold';

		case 'custom_filtered':
			return 'Access to Use Custom Filters';

		case 'login_logs':
			return 'Access to the User Login Logs';

		case 'vehicle_history_summary':
			return 'Access to Basic Vehicle Recon Timeline Summary';

		case 'vehicle_history_detailed':
			return 'Access to Detailed Vehicle Recon Timeline';

		case 'all_vehicle_information':
			return 'Access to all of the vehicles information without restriction';

		case 'approve_estimates':
			return 'Is allowed to approve an estimate. This will also include the user in the drowpdown list for recipients';

		case 'approve_estimate_cosmetic':
			return 'Is allowed to approve an estimate with category - cosmetic. This will also include the user in the drowpdown list for recipients';

		case 'approve_estimate_mechanical':
			return 'Is allowed to approve an estimate with category - mechanical. This will also include the user in the drowpdown list for recipients';

		case 'approve_estimate_bodywork':
			return 'Is allowed to approve an estimate with category - bodywork. This will also include the user in the drowpdown list for recipients';

		case 'appointment_notifications':
			return 'Access to all appointments and appointment notifications';

		case 'lock_appraisals':
			return 'Ability to Lock the appraisal form';

		case 'add_files':
			return 'Ability to Add Files/Attachments';

		case 'delete_files':
			return 'Access to All delete_files';

		case 'edit_vehicle_core_information':
			return 'Ability to edit a vehicles stock and vin number - should only be allowed if the user is also responsible for entering vehicles by hand';

		case 'edit_vehicle':
			return 'Ability to edit vehicle information and status';

		case 'create_vehicle':
			return 'Ability to create a vehicle manually';

		case 'delete_vehicle':
			return 'Ability to delete a vehicle - once deleted the stock number is blocked from being imported again during a feed.';

		case 'view_invoice':
			return 'Access to invoice and cost information';

		case 'manage_vendors':
			return 'Ability to add and edit vendors';

		case 'view_all_tabs':
			return 'View All Vehicle Tabs';

		case 'move_vehicle_all_stages':
			return 'Allowed to move a vehicle to any stage/department';

		case 'create_edit_users':
			return 'Ability to create and edit users';

		case 'create_edit_user_profiles':
			return 'Ability to create and edit default user profiles';

		case 'create_edit_custom_filters':
			return 'Create and Edit Custom Filters';

		default:
			return '';
		}
	}

	render() {
		return (

			<div className={classnames('form-group', { 'has-danger': this.props.error })}>
				{(() => {
					switch (this.props.type) {
					case 'slider':
						return <span className="switch">
							<input
								onChange={this.onChangeChecked}
								type={'checkbox'}
								name={this.props.name}
								data-id={this.props.parentid}
								checked={this.props.checked ? 'checked' : ''}
								className={classnames('text-center', { 'form-control-danger': this.props.error })}
								id={`switch_id_${this.props.name}`}
								disabled={this.props.disabled}
							/>
							<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}`}>{this.props.name.replace(/_/g, ' ')}</label>
						</span>;

					case 'select':
						return <Select
							onChange={this.onChangeSelect}
							value={this.selectOptions().filter(({ value }) => value === this.props.value)}
							name={this.props.name}
							options={this.selectOptions()}
							className="text-capitalize max30"
							data-id={this.props.parentid}
							clearable={false}
							searchable={false}
							menuContainerStyle={{ zIndex: 5 }}
							disabled={this.props.disabled}
						/>;

					case 'dealerships':
						return <Select
							onChange={this.onChangeSelectDealership}
							value={this.selectedDealerships()}
							onValueClick={this.onClearDealership}
							name={this.props.name}
							key={`dealerships_${this.props.name}_${this.props.user_id}`}
							options={this.selectDealerships()}
							className="text-capitalize"
							data-id={this.props.parentid}
							clearable={false}
							searchable
							menuContainerStyle={{ zIndex: 5 }}
							isMulti
							disabled={this.props.disabled}
						/>;

					case 'dealerships_2':
						return <div className="col-md-12">
							<DropdownMultiple
								{...this.props}
								titleHelper="Location"
								title="Select location"
								list={this.selectRelatedInstallations()}
								update_type={'user_installation'}
								user_id={this.props.user_id}
								disabled={this.props.disabled}
							/>
             </div>;

					case 'position':
						return <Dropdown
							{...this.props}
							titleHelper="Select Profile"
							title="Select Profile"
							list={this.selectProfiles()}
							update_type={'position'}
							value={this.props.value}
							user_id={this.props.user_id}
							disabled={this.props.disabled}
						/>;

					case 'role':
						return <Select
							onChange={this.onChangeSelect}
							value={this.selectRoles().filter(({ value }) => value === this.props.value)}
							name={this.props.name}
							options={this.selectRoles()}
							className="text-capitalize max30"
							data-id={this.props.parentid}
							clearable={false}
							searchable={false}
							menuContainerStyle={{ zIndex: 5 }}
							disabled={this.props.disabled}
						/>;

					case 'select-notification':
						return <Dropdown
							{...this.props}
							titleHelper="Select Notification Preference"
							title="Select Notification Preference"
							list={this.selectNotifications()}
							update_type={'notification_preference'}
							value={this.props.value}
							user_id={this.props.user_id}
							disabled={this.props.disabled}
						/>;

					case 'text':
						return <input
							onChange={this.onChange}
							onBlur={this.onBlur}
							value={this.state.value}
							type={this.props.type}
							name={this.props.name}
							data-id={this.props.parentid}
							disabled={this.props.disabled}
							className={classnames('form-control ', { 'form-control-danger': this.props.error })}
						/>;
					case 'phone_number':
						return <input
							onChange={this.onChangePhoneNumber}
							onBlur={this.onBlurPhoneNumber}
							value={this.state.value}
							type={this.props.type}
							name={this.props.name}
							data-id={this.props.parentid}
							disabled={this.props.disabled}
							className={classnames('form-control ', { 'form-control-danger': this.props.error })}
						/>;

					case 'switch':
						return <span className="switch">
							<input
								onChange={this.onChangeChecked}
								checked={this.state.value === true ? 'checked' : ''}
								type={'checkbox'}
								name={this.props.name}
								data-id={this.props.rowid}
								data-parentid={this.props.parentid}
								data-updatetype={this.props.updatetype}
								disabled={this.props.setdisabled ? 'disabled' : ''}
								key={`${this.props.type}_${this.props.user_id}_${this.props.name}`}
								id={`switch_id_${this.props.user_id}_${this.props.name}`}
							/>
							<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.user_id}_${this.props.name}`} />
						</span>;

					default:
						return <input
							onChange={this.onChange}
							value={this.state.value}
							type={this.props.type}
							name={this.props.field}
							className={classnames('form-control', { 'form-control-danger': this.props.error })}
							disabled={this.props.disabled}
						/>;
					}
				})()}

			</div>
		);
	}
}

const mappedActions = {
	editUserProfile,
	addUserInstallation,
	deleteUserInstallation,
};

function mapStateToProps(state) {
	return {
		input_types: state.settings_departments.input_types,
		set_dealerships: state.settings_dealerships.set_dealerships,
		default_profiles: state.settings_users.default_profiles,
		all_users: state.settings_users.all_users,
		related_installations: state.settings_dealerships.related_installations,
	};
}
export default connect(mapStateToProps, mappedActions)(EditUserInputs);
