import React, { Component } from 'react';
import { connect } from 'react-redux';
import VehicleCount from './VehicleCount';
import SidebarListElement from './SidebarListElement';

class SidebarListElementBox extends Component {
	constructor(props) {
		super(props);
		this.state = { department: this.props.department };
	}

	render() {
		return (
			<ul>
				{this.props.departments.map((department) => {
					if (department.subgroup === this.props.parent_department) {
						const department_dealership = _.find(department.department_dealerships, { subdomain: this.props.installation.subdomain }) || [];
						const count = department_dealership.vehicle_count >= 0 ? department_dealership.vehicle_count : '...';

						return <SidebarListElement department={department} count={count} key={`sidebar_sub_${department.id}`} />;
					}
				})}
			</ul>
		);
	}
}

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.departments,
		site_map: state.settings_nav.menus.site_map,
		expand_all: state.settings_nav.menus.expand_all,
		hide_blank: state.settings_nav.menus.hide_blank,
		work_flow: state.settings_nav.menus.work_flow,
		work_flows: state.settings_departments.work_flows,
		profile: state.current_user.position || '',
		current_department: state.settings_departments.department || {},
		installation: state.settings_dealerships.installation || [],
	};
}
export default connect(mapStateToProps)(SidebarListElementBox);
