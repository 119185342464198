import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Expense from './Expense';
import {
	addEstimate,
} from '../../../../actions/apiEstimates';

const estimateFields = ['Expense ID', 'Ref#', 'Service Company Name', 'Description', 'Amount', 'Expense Post Date', 'Service Date', 'Expense Class Code'];

class Expenses extends Component {
	constructor(props) {
		super(props);

		this.state = { addEstimateError: '' };
		this.onClick = this.onClick.bind(this);
		this.vehicleProperty = this.vehicleProperty.bind(this);
	}

	onClick() {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_status: 'getapproved',
		};
		this.props.addEstimate(data, 'addEstimateError');
	}

	vehicleProperty(name) {
		const self = this;

		const value = _.filter(self.props.active_vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const errors = this.state;
		let button_text = 'Add Estimate';
		if (this.props.installation.database === 'stanley') {
			button_text = 'Add Additional Required Work';
		}

		var headers = <tr><th>ID</th><th>Ref#</th><th>Company</th><th>Description</th><th>Amount</th><th>Service Date</th></tr>;

		return (
			<div className="card">
				<div className="card-header">
					<button className="btn btn-sm btn-success" onClick={this.onClick}>{button_text}</button>
					<button className="btn btn-sm btn-info">Total Expenses: {this.vehicleProperty('expense_total')}</button>
				</div>
				<div className="card-body">
					<div className="table-responsive">
						<table className="table">
							<thead>{headers}</thead>
							<tbody>
					{this.props.appraisals.map(estimate => {
										if (estimate.ro_status === 'expense') {
											return <Expense key={`estimate_single_${estimate.id}`} estimate={estimate} vehicle={vehicle} />;
										}
										})}
						</tbody>
						</table>
					</div>
				</div>
				<div className="card-footer" />
			</div>
		);
	}
}

const mappedActions = {
	addEstimate,
};

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
		appraisals: state.settings_refresh.active_vehicle.appraisals,
		installation: state.settings_dealerships.installation,
	};
}

export default connect(mapStateToProps, mappedActions)(Expenses);
