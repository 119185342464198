import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import TextFieldGroupWithButton from '../../inputs/TextFieldGroupWithButton';
import FlashMessageList from '../../inputs/FlashMessageList';
import Api from '../../api/reconApi.js';
import { addAudit } from '../../actions/apiAudits';

class AddAudit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			name: '',
			status: '',
			audit_type: '',
			audit_start: '',
			audit_end: '',
			audit_confirmed: '',
			audit_confirmed_by: '',
			audit_created_by: '',
			locked: '',
			deleted: '',
			dealerships: '',
			active: false,
		};

		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.addAudit = this.addAudit.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onChangeDealership = this.onChangeDealership.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	onChangeSelect(e) {
		this.setState({ audit_type: e.value });
	}

	onChangeDealership(e) {
		this.setState({ dealerships: e.value });
	}

	selectAuditType() {
		const comparisonArray = [{ name: 'used-only', label: 'Used Only' },
			{ name: 'new-only', label: 'New Only' }, { name: 'all-inventory', label: 'All Inventory' }, { name: 'all-wholesale', label: 'All Wholesale' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectRelatedInstallations() {
		const options = this.props.related_installations.map((option) => {
			if (typeof option.subdomain !== 'undefined') {
				var obj = { value: option.subdomain, label: option.softname };
			} else {
				var obj = { value: option.subdomain, label: option.softname };
			}
			return obj;
		});
		return options;
	}

	addAudit() {
		const data = {
			name: this.state.name,
			status: this.state.status,
			audit_type: this.state.audit_type,
			audit_start: this.state.audit_start,
			audit_end: this.state.audit_end,
			audit_confirmed: this.state.audit_confirmed,
			audit_confirmed_by: this.state.audit_confirmed_by,
			audit_created_by: this.state.audit_created_by,
			active: this.state.active,
			locked: this.state.locked,
			deleted: this.state.deleted,
			dealerships: this.state.dealerships,
		};

		const extra = {};

		this.props.addAudit(data, extra).then((resp) => {
			this.setState({
				name: '',
				status: '',
				audit_type: '',
				audit_start: '',
				audit_end: '',
				audit_confirmed: '',
				audit_confirmed_by: '',
				audit_created_by: '',
				locked: '',
				deleted: '',
				active: false,
				modalIsOpen: false,
				dealerships: '',
			});
		});
	}

	onChange(event) {
		const { name } = event.target;
		this.setState({ [name]: event.target.value });
	}

	render() {
		const { errors } = this.state;

		const defaultStyle = {
			background: this.props.bg_color,
			backgroundColor: this.props.bg_color,
			color: this.props.font_color,
		};

		return (
			<div className="input-group-append">
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="PickColor"
					className="d-flex justify-content-center pad-bottom-2 space-bottom-2"
					contentLabel="Add Audit"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-3 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="modal-content">

							<div className="modal-header bg-dark text-white">
								<h4 className="modal-title">Add Audit</h4>
								<button type="button" className="close text-white" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
							</div>

							<div className="modal-body add-vehicle">
								<div className="card-block bg-white">
									<div className="row text-center">
										<FlashMessageList {...this.props} />
									</div>
									<div className="row">
										<label>Audit Name: </label>
										<TextFieldGroup
											onChange={this.onChange}
											value={this.state.name}
											type={'text'}
											name={'name'}
											field={'name'}
											updatetype="audit"
											disabled={false}
										/>
									</div>
									<div className="row">
										<label>Audit Type: </label>
										<Select
											onChange={this.onChangeSelect}
											value={this.selectAuditType().filter(({ value }) => value === this.state.audit_type)}
											name={'audit_type'}
											options={this.selectAuditType()}
											className="form-control text-capitalize text-dark"
											clearable={false}
											searchable={false}
											menuContainerStyle={{ zIndex: 5 }}

										/>

									</div>
									<div className="row">
										<label>Dealership: </label>
										<Select
											onChange={this.onChangeDealership}
											value={this.selectRelatedInstallations().filter(({ value }) => value === this.state.dealerships)}
											name={'dealerships'}
											options={this.selectRelatedInstallations()}
											className="form-control text-capitalize text-dark"
											clearable={false}
											searchable={false}
											menuContainerStyle={{ zIndex: 5 }}

										/>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-success" onClick={this.addAudit}>Add Audit</button>
							</div>

						</div>
					</div>

				</Modal>
				<span className="input-group-text btn btn-primary" onClick={this.openModal}>
					<i className="fal fa-plus" />
					{' '}
Add Audit
				</span>
			</div>
		);
	}
}

const mappedActions = {
	addAudit,
};

function mapStateToProps(state) {
	return {
		audits: state.settings_refresh.audits,
		related_installations: state.settings_dealerships.related_installations,
	};
}
export default connect(mapStateToProps, mappedActions)(AddAudit);
