import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EditUserInputs from '../../inputs/EditUserInputs';
import DeleteUserButton from '../../inputs/DeleteUserButton';
import {
	setSingleUser,
	setUsersTab,
} from '../../actions/apiSetInfo';
import {
	addFlashMessage,
	deleteFlashMessages,
	change_page,
} from '../../actions/toggleMenu';
import {
	forgotpassword,
} from '../../actions/authentication';

class UserRow extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
		this.onClickEdit = this.onClickEdit.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	changePage(e) {
		this.props.change_page(e.target.name);
	}

	onClickDelete() {
	}

	onClickEdit() {
		this.props.setSingleUser(this.props.user.id);
		this.props.setUsersTab('user-single');
	}

	onClick(e) {
		const self = this;
		var user = this.props.user;

		forgotpassword(user.user_name).then((res) => {
			if(res && res.detail){
				this.props.addFlashMessage({
					type: 'success',
					text: 'Password reset has been sent!',
				});
				setTimeout(() => { self.props.deleteFlashMessages(); }, 5000);
			}
			else{
				this.props.addFlashMessage({
					type: 'error',
					text: resp.errors.loginError,
				});
				setTimeout(() => { self.props.deleteFlashMessages(); }, 3000);
				this.setState({ isLoading: false });
			}
			// if (resp == 'password reset has been sent') {
			// 	this.props.addFlashMessage({
			// 		type: 'success',
			// 		text: 'Password reset has been sent!',
			// 	});
			// 	setTimeout(() => { self.props.deleteFlashMessages(); }, 5000);
			// } else {
			// 	this.props.addFlashMessage({
			// 		type: 'error',
			// 		text: resp.errors.loginError,
			// 	});
			// 	setTimeout(() => { self.props.deleteFlashMessages(); }, 3000);
			// 	this.setState({ isLoading: false });
			// }
		});
	}

	render() {
		const { user } = this.props;

		return (
			<tr key={`user_edit_${user.id}`}>
				<td data-title="EditUser"><button onClick={this.onClickEdit} className="btn btn-sm btn-success"><i className={'fal fa-user-edit'} /></button></td>
				<td data-title="UserName" data-user-id={user.id}>{user.user_name || 'N/A'}</td>
				<td data-title="First">{user.given_name || 'N/A'}</td>
				<td data-title="Last">{user.family_name || 'N/A'}</td>
				<td data-title="Profile"><div className="col-md-12"><EditUserInputs key={`user_profile_${user.id}`} {...this.props} user_id={user.id} name={'position'} type="position" value={user.position} /></div></td>
				<td data-title="Dealerships"><EditUserInputs key={`user_dealer_${user.id}`} {...this.props} user_id={user.id} name={'dealerships'} type="dealerships_2" dealerships={user.user_installations} /></td>
				<td data-title="EditUser">
					<button onClick={this.onClick} className="btn btn-sm btn-primary">
						<i className={'fal fa-envelope'} />
						{' '}
						Password Reset
					</button>
				</td>
				<td data-title="DeleteUser"><DeleteUserButton user={user} /></td>
			</tr>
		);
	}
}

const mappedActions = {
	setSingleUser,
	setUsersTab,
	deleteFlashMessages,
	addFlashMessage,
	change_page,
};

function mapStateToProps(state) {
	return { all_users: state.settings_users.all_users };
}
export default connect(mapStateToProps, mappedActions)(UserRow);
