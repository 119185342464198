import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import AllInputs from '../../../../inputs/AllInputs';
import DollarInput from '../../../../inputs/DollarInput';
import TextFieldGroup from '../../../../inputs/TextFieldGroupNoLabel';
import TextInput from '../../../../inputs/TextInput';
import Select from '../../../../inputs/Dropdown';
import {
	addVehicleProperty,
} from '../../../../actions/AllUpdates';
import {
	getCurrentDepartment,
} from '../../../../actions/apiGetInfo';
import {
	vsearch,
} from '../../../../actions/lookups';

class ReconCost extends Component {
	constructor(props) {
		super(props);

		this.state = {
 timer: 0,
addPartOrderError: '',
final_total: 0,
recon_total_approved: 0,
recon_total_final: 0,
ally_daily: 0.000136111,
rwo_number_of_days: 0,
rwo_purchase_date: '',
			rwo_recon_shop: 0,
			rwo_date_received: 0,
			rwo_original_acv: 0,
			rwo_arb_adjustment: 0,
			rwo_transport_from_auction: 0,
			rwo_ally_floor_expense: 0,
			rwo_fedex_fee: 0,
			rwo_mechanical_reconditioning_approved: 0,
			rwo_mechanical_reconditioning_final: 0,
			rwo_body_shop_approved: 0,
			rwo_body_shop_final: 0,
			rwo_bg_and_gps_approved: 0,
			rwo_bg_and_gps_final: 0,
			rwo_pdr_approved: 0,
			rwo_pdr_final: 0,
			rwo_touch_up_approved: 0,
			rwo_touch_up_final: 0,
			rwo_interior_approved: 0,
			rwo_interior_final: 0,
			rwo_to_shop_transport_approved: 0,
			rwo_to_shop_transport_final: 0,
			rwo_from_shop_transport_approved: 0,
			rwo_from_shop_transport_final: 0,
			rwo_blank_label: 0,
			rwo_blank_approved: 0,
			rwo_blank_final: 0,
			rwo_blank_label2: 0,
			rwo_blank_approved2: 0,
			rwo_blank_final2: 0,
			rwo_blank_label3: 0,
			rwo_blank_approved3: 0,
			rwo_blank_final3: 0,
			rwo_blank_label4: 0,
			rwo_blank_approved4: 0,
			rwo_blank_final4: 0,
			rwo_blank_label5: 0,
			rwo_blank_approved5: 0,
			rwo_blank_final5: 0,
			rwo_blank_label6: 0,
			rwo_blank_approved6: 0,
			rwo_blank_final6: 0,
			rwo_misc_approved: 0,
			rwo_misc_final: 0,
			rwo_transport_to_store_approved: 0,
			rwo_transport_to_store_final: 0,
			rwo_sold_date: 0,
};
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onPrint = this.onPrint.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		const vehicle = this.props.active_vehicle;
		document.getElementById(`${e.target.name}_${vehicle.id}`).setAttribute('data-total', e.target.value);
	}

	onChangeSelect(e, key) {
		const self = this;
		const value = e;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;
		const errorType = 'recon-cost';
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			key,
			value,
			uid,
			user_name,
		};

		Promise.all([
			this.props.addVehicleProperty(data, errorType),
		]).then(() => {
			const { vehicle_filter, department } = this.props;

			if (vehicle_filter.type === 'searched') {
				this.props.vsearch(vehicle_filter.value);
			} else {
				this.props.getCurrentDepartment(parseInt(department));
			}
		}).then(() => {
			this.updateTotals();
		});
	}

	onBlur(e) {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const vehicle_id = vehicle.id;
		const key = e.target.name;
		if (key === 'rwo_purchase_date') {
			var date1 = new Date(e.target.value);
			const rwo_sold_date = this.vehicleProperty('rwo_sold_date');
			if (rwo_sold_date !== '') {
				date1 = new Date(rwo_sold_date);
			}

			var date2 = new Date();
			var Difference_In_Time = date2.getTime() - date1.getTime();
			var rwo_number_of_days = parseInt(Difference_In_Time / (1000 * 3600 * 24)).toFixed();
			this.setState({ rwo_number_of_days });
		}
		const { value } = e.target;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;
		const errorType = 'dollar';
		const data = {
			vehicle_id,
			key,
			value,
			uid,
			user_name,
		};

		Promise.all([
			this.props.addVehicleProperty(data, errorType),
		]).then(() => {
			const { vehicle_filter, department } = this.props;

			if (vehicle_filter.type === 'searched') {
				this.props.vsearch(vehicle_filter.value);
			} else {
				this.props.getCurrentDepartment(parseInt(department));
			}
		}).then(() => {
			this.updateTotals();
		});
	}

	reconShopOptions() {
		const select_options = [
			{
				name: 'Pilot', id: 1,
			},
			{
				name: 'Ashley', id: 2,
			},
			{
				name: 'Pilot/Ashley', id: 3,
			},
			{
				name: 'Gilmer CDJ', id: 4,
			},
			{
				name: 'Ace', id: 5,
			},
			{
				name: "Javier's", id: 6,
			},
			{
				name: 'Metro UVI', id: 7,
			},
			];

		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.state.rwo_recon_shop === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected,
			};
			return obj;
		});
		return options || [];
	}

	bgOptions() {
		const select_options = [
			{
				name: '225.93', id: 1,
			},
			{
				name: 'Included', id: 2,
			},
			];

		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.state.rwo_recon_shop === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected,
			};
			return obj;
		});
		return options || [];
	}

	toAndFromOptions() {
		const select_options = [
			{
				name: 'Pilot', id: 1,
			},
			{
				name: 'Ashley', id: 2,
			},
			{
				name: 'Pilot/Ashley', id: 3,
			},
			{
				name: 'Gilmer CDJ', id: 4,
			},
			{
				name: 'Ace', id: 5,
			},
			{
				name: "Javier's", id: 6,
			},
			{
				name: 'Metro UVI', id: 7,
			},
			];

		const options = select_options.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.state.rwo_recon_shop === option.name) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, value: option.name, selected,
			};
			return obj;
		});
		return options || [];
	}

	componentDidMount() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const errors = this.state;
		const rwo_purchase_date = this.vehicleProperty('rwo_purchase_date');
		const rwo_sold_date = this.vehicleProperty('rwo_sold_date');
		var date1 = new Date(rwo_purchase_date);

		if (rwo_sold_date !== '') {
			date1 = new Date(rwo_sold_date);
		}

		var date2 = new Date();
		var Difference_In_Time = date2.getTime() - date1.getTime();
		var rwo_number_of_days = parseInt(Difference_In_Time / (1000 * 3600 * 24)).toFixed();

		const rwo_recon_shop = this.vehicleProperty('rwo_recon_shop');
		const rwo_date_received = this.vehicleProperty('rwo_date_received');

		const rwo_original_acv = this.vehicleProperty('rwo_original_acv');
		const rwo_arb_adjustment = this.vehicleProperty('rwo_arb_adjustment');
		const rwo_transport_from_auction = this.vehicleProperty('rwo_transport_from_auction');

		const rwo_ally_floor_expense = this.vehicleProperty('rwo_ally_floor_expense');
		const rwo_fedex_fee = 25;

		const rwo_mechanical_reconditioning_approved = this.vehicleProperty('rwo_mechanical_reconditioning_approved');
		const rwo_mechanical_reconditioning_final = this.vehicleProperty('rwo_mechanical_reconditioning_final');

		const rwo_body_shop_approved = this.vehicleProperty('rwo_body_shop_approved');
		const rwo_body_shop_final = this.vehicleProperty('rwo_body_shop_final');

		const rwo_bg_and_gps_approved = this.vehicleProperty('rwo_bg_and_gps_approved');
		const rwo_bg_and_gps_final = this.vehicleProperty('rwo_bg_and_gps_final');

		const rwo_pdr_approved = this.vehicleProperty('rwo_pdr_approved');
		const rwo_pdr_final = this.vehicleProperty('rwo_pdr_final');

		const rwo_touch_up_approved = this.vehicleProperty('rwo_touch_up_approved');
		const rwo_touch_up_final = this.vehicleProperty('rwo_touch_up_final');

		const rwo_interior_approved = this.vehicleProperty('rwo_interior_approved');
		const rwo_interior_final = this.vehicleProperty('rwo_interior_final');

		const rwo_to_shop_transport_approved = this.vehicleProperty('rwo_to_shop_transport_approved');
		const rwo_to_shop_transport_final = this.vehicleProperty('rwo_to_shop_transport_final');

		const rwo_from_shop_transport_approved = this.vehicleProperty('rwo_from_shop_transport_approved');
		const rwo_from_shop_transport_final = this.vehicleProperty('rwo_from_shop_transport_final');

		const rwo_blank_label = this.vehicleProperty('rwo_blank_label');
		const rwo_blank_approved = this.vehicleProperty('rwo_blank_approved');
		const rwo_blank_final = this.vehicleProperty('rwo_blank_final');

		const rwo_blank_label2 = this.vehicleProperty('rwo_blank_label2');
		const rwo_blank_approved2 = this.vehicleProperty('rwo_blank_approved2');
		const rwo_blank_final2 = this.vehicleProperty('rwo_blank_final2');

		const rwo_blank_label3 = this.vehicleProperty('rwo_blank_label3');
		const rwo_blank_approved3 = this.vehicleProperty('rwo_blank_approved3');
		const rwo_blank_final3 = this.vehicleProperty('rwo_blank_final3');

		const rwo_blank_label4 = this.vehicleProperty('rwo_blank_label4');
		const rwo_blank_approved4 = this.vehicleProperty('rwo_blank_approved4');
		const rwo_blank_final4 = this.vehicleProperty('rwo_blank_final4');

		const rwo_blank_label5 = this.vehicleProperty('rwo_blank_label5');
		const rwo_blank_approved5 = this.vehicleProperty('rwo_blank_approved5');
		const rwo_blank_final5 = this.vehicleProperty('rwo_blank_final5');

		const rwo_blank_label6 = this.vehicleProperty('rwo_blank_label6');
		const rwo_blank_approved6 = this.vehicleProperty('rwo_blank_approved6');
		const rwo_blank_final6 = this.vehicleProperty('rwo_blank_final6');

		const rwo_misc_approved = this.vehicleProperty('rwo_misc_approved');
		const rwo_misc_final = this.vehicleProperty('rwo_misc_final');

		const rwo_transport_to_store_approved = this.vehicleProperty('rwo_transport_to_store_approved');
		const rwo_transport_to_store_final = this.vehicleProperty('rwo_transport_to_store_final');

		this.setState({
			rwo_purchase_date,
			rwo_recon_shop,
			rwo_date_received,
			rwo_original_acv,
			rwo_arb_adjustment,
			rwo_transport_from_auction,
			rwo_ally_floor_expense,
			rwo_fedex_fee,
			rwo_mechanical_reconditioning_approved,
			rwo_mechanical_reconditioning_final,
			rwo_body_shop_approved,
			rwo_body_shop_final,
			rwo_bg_and_gps_approved,
			rwo_bg_and_gps_final,
			rwo_pdr_approved,
			rwo_pdr_final,
			rwo_touch_up_approved,
			rwo_touch_up_final,
			rwo_interior_approved,
			rwo_interior_final,
			rwo_to_shop_transport_approved,
			rwo_to_shop_transport_final,
			rwo_from_shop_transport_approved,
			rwo_from_shop_transport_final,
			rwo_blank_label,
			rwo_blank_approved,
			rwo_blank_final,
			rwo_blank_label2,
			rwo_blank_approved2,
			rwo_blank_final2,
			rwo_blank_label3,
			rwo_blank_approved3,
			rwo_blank_final3,
			rwo_blank_label4,
			rwo_blank_approved4,
			rwo_blank_final4,
			rwo_blank_label5,
			rwo_blank_approved5,
			rwo_blank_final5,
			rwo_blank_label6,
			rwo_blank_approved6,
			rwo_blank_final6,
			rwo_misc_approved,
			rwo_misc_final,
			rwo_transport_to_store_approved,
			rwo_transport_to_store_final,
			rwo_number_of_days,
			rwo_sold_date,
		});

		const timer = setInterval(() => {
			this.updateTotals();
		}, 5000);

		this.setState({ timer });
	}

	updateTotals() {
		const vehicle = this.props.active_vehicle;
		var all_approved = document.getElementsByClassName(`approved_rwo_totals_${vehicle.id}`);
		var all_total = document.getElementsByClassName(`rwo_totals_${vehicle.id}`);
		var all_final = document.getElementsByClassName(`top_rwo_totals_${vehicle.id}`);

		var approved_total = 0;
		for (var i = 0, len = all_approved.length; i < len; i += 1) {
		    approved_total += parseFloat(all_approved[i].dataset.total) || 0;
		}

		var final_total = 0;

		var bottom_total = 0;

		for (var i = 0, len = all_total.length; i < len; i += 1) {
		    bottom_total += parseFloat(all_total[i].dataset.total) || 0;
		}

		var top_total = 0;
		for (var i = 0, len = all_final.length; i < len; i += 1) {
		    top_total += parseFloat(all_final[i].dataset.total) || 0;
		}

		final_total = parseFloat(bottom_total) + parseFloat(top_total);
		this.setState({ recon_total_approved: approved_total.toFixed(2) });
		this.setState({ recon_total_final: bottom_total.toFixed(2) });
		this.setState({ final_total: final_total.toFixed(2) });
	}

	componentWillUnmount() {
		clearInterval(this.state.timer);
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
		};

		this.props.addPartOrder(data, 'addPartOrderError');
	}

	onPrint(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById(`recon_work_order_${vehicle.stock}`);
		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 0, 0);
				    pdf.save(`recon_work_order_${vehicle.stock}.pdf`);
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	vehicleProperty(name) {
		const self = this;

		const value = _.filter(self.props.vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const rwo_purchase_date = this.vehicleProperty('rwo_purchase_date');
		const rwo_recon_shop = this.vehicleProperty('rwo_recon_shop');
		const rwo_date_received = this.vehicleProperty('rwo_date_received');
		const final_approved = _.find(vehicle.vehicle_properties, { key: 'final_approved' }) || '';
		var setdisabled = false;

		if (final_approved.value === 'true') {
			setdisabled = true;
		}
		return (
			<div className="container">
				<button onClick={this.onPrint} className="btn btn-success float-right">Print</button>
				<div className="card" id={`recon_work_order_${vehicle.stock}`}>
					<div className="card" style={{ padding: '10px', width: '80%' }}>
						<div className="card-text"><h3 style={{ textAlign: 'center' }}>Recon Work Order</h3></div>
						<div className="card-text">
							<div className="d-flex flex-wrap" style={{ border: '1px solid black', padding: '10px', width: '95%' }}>
								<table className="table table-striped table-bordered">
									<thead className={'thead text-center'}>
										<tr>
											<td id={`rwo_purchase_date_${vehicle.id}`}><label>Purchase Date</label><TextFieldGroup
													{...this.props}
													value={this.state.rwo_purchase_date}
													type={'date'}
													name={'rwo_purchase_date'}
													field={'rwo_purchase_date'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
													disabled={setdisabled}
											/></td>
											<td id={`rwo_recon_shop_${vehicle.id}`}><label>Recon Shop</label><Select
												titleHelper={''}
												title={'Choose Item'}
												onChange={this.onChangeSelect}
												value={rwo_recon_shop}
												name={'rwo_recon_shop'}
												disabled={setdisabled}
												list={this.reconShopOptions()}
												update_type={'vehicleproperty'}
											/></td>
											<td id={`rwo_date_received_${vehicle.id}`}><label>Date Received</label><TextFieldGroup
													{...this.props}
													value={this.state.rwo_date_received}
													type={'date'}
													name={'rwo_date_received'}
													field={'rwo_date_received'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
													disabled={setdisabled}
											/></td>
										</tr>
										<tr>
											<td><label>Stock Number</label><AllInputs
													{...this.props}
													value={vehicle.stock}
													type={'display_only'}
													name={'stock'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td><label>VIN</label><AllInputs
													{...this.props}
													value={vehicle.vin}
													type={'display_only'}
													name={'vin'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td><label>Odometer</label><AllInputs
													{...this.props}
													value={vehicle.miles}
													type={'display_only'}
													name={'miles'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
													setdisabled
											/></td>
										</tr>
										<tr>
											<td><label>Year</label><AllInputs
													{...this.props}
													value={vehicle.year}
													type={'display_only'}
													name={'year'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td><label>Make</label><AllInputs
													{...this.props}
													value={vehicle.make}
													type={'display_only'}
													name={'make'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td><label>Model</label><AllInputs
													{...this.props}
													value={vehicle.model}
													type={'display_only'}
													name={'model'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td colSpan={2}><label>Trim</label><AllInputs
													{...this.props}
													value={vehicle.trim}
													type={'text'}
													name={'trim'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td><label>Color</label><AllInputs
													{...this.props}
													value={vehicle.color}
													type={'display_only'}
													name={'color'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td colSpan={2}><label className={'float-end'}>Original ACV:</label></td>
											<td id={`rwo_original_acv_${vehicle.id}`} className={`top_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_original_acv}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_original_acv}
													type={'dollar'}
													name={'rwo_original_acv'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td colSpan={2}><label className={'float-end'}>Arb Adjustment:</label></td>
											<td id={`rwo_arb_adjustment_${vehicle.id}`} className={`top_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_arb_adjustment}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_arb_adjustment}
													type={'dollar'}
													name={'rwo_arb_adjustment'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td colSpan={2}><label className={'float-end'}>Transport from Auction:</label></td>
											<td id={`rwo_transport_from_auction_${vehicle.id}`} className={`top_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_transport_from_auction}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_transport_from_auction}
													type={'dollar'}
													name={'rwo_transport_from_auction'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td colSpan={2}><label className={'float-end'}>Ally Floor Expense:</label></td>
											<td id={`rwo_ally_floor_expense_${vehicle.id}`} className={`top_rwo_totals_${vehicle.id}`} data-total={parseFloat(this.state.ally_daily * this.state.rwo_number_of_days * (this.state.rwo_original_acv || 0)).toFixed(2)}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={parseFloat(this.state.ally_daily * this.state.rwo_number_of_days * (this.state.rwo_original_acv || 0)).toFixed(2)}
													type={'dollar'}
													name={'rwo_ally_floor_expense'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td colSpan={2}><label className={'float-end'}>FedEx Fee:</label></td>
											<td id={`rwo_fedex_fee_${vehicle.id}`} className={`top_rwo_totals_${vehicle.id}`} data-total={25}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={25}
													type={'dollar'}
													name={'rwo_fedex_fee'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td />
											<td><label>Approved Amount</label></td>
											<td><label>Final Amount</label></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>Mechanical Reconditioning:</label></td>
											<td id={`rwo_mechanical_reconditioning_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_mechanical_reconditioning_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_mechanical_reconditioning_approved}
													type={'dollar'}
													name={'rwo_mechanical_reconditioning_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_mechanical_reconditioning_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_mechanical_reconditioning_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_mechanical_reconditioning_final}
													type={'dollar'}
													name={'rwo_mechanical_reconditioning_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>Body Shop:</label></td>
											<td id={`rwo_body_shop_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_body_shop_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_body_shop_approved}
													type={'dollar'}
													name={'rwo_body_shop_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_body_shop_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_body_shop_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_body_shop_final}
													type={'dollar'}
													name={'rwo_body_shop_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>BG and GPS:</label></td>
											<td id={`rwo_bg_and_gps_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_bg_and_gps_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_bg_and_gps_approved}
													type={'dollar'}
													name={'rwo_bg_and_gps_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_bg_and_gps_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_bg_and_gps_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_bg_and_gps_final}
													type={'dollar'}
													name={'rwo_bg_and_gps_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>PDR:</label></td>
											<td id={`rwo_pdr_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_pdr_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_pdr_approved}
													type={'dollar'}
													name={'rwo_pdr_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_pdr_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_pdr_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_pdr_final}
													type={'dollar'}
													name={'rwo_pdr_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>Touch UP:</label></td>
											<td id={`rwo_touch_up_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_touch_up_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_touch_up_approved}
													type={'dollar'}
													name={'rwo_touch_up_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_touch_up_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_touch_up_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_touch_up_final}
													type={'dollar'}
													name={'rwo_touch_up_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>Interior:</label></td>
											<td id={`rwo_interior_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_interior_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_interior_approved}
													type={'dollar'}
													name={'rwo_interior_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_interior_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_interior_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_interior_final}
													type={'dollar'}
													name={'rwo_interior_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>To Shop Transport:</label></td>
											<td id={`rwo_to_shop_transport_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_to_shop_transport_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_to_shop_transport_approved}
													type={'dollar'}
													name={'rwo_to_shop_transport_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_to_shop_transport_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_to_shop_transport_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_to_shop_transport_final}
													type={'dollar'}
													name={'rwo_to_shop_transport_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>From Shop Transport:</label></td>
											<td id={`rwo_from_shop_transport_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_from_shop_transport_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_from_shop_transport_approved}
													type={'dollar'}
													name={'rwo_from_shop_transport_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_from_shop_transport_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_from_shop_transport_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_from_shop_transport_final}
													type={'dollar'}
													name={'rwo_from_shop_transport_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td id={`rwo_blank_label_${vehicle.id}`} data-total={this.state.rwo_blank_label}><TextInput
													{...this.props}
													value={this.state.rwo_blank_label}
													type={'text'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													name={'rwo_blank_label'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_approved_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_approved}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_approved}
													type={'dollar'}
													name={'rwo_blank_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_final_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_final}
													type={'dollar'}
													name={'rwo_blank_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td id={`rwo_blank_label2_${vehicle.id}`} data-total={this.state.rwo_blank_label2}><TextInput
													{...this.props}
													value={this.state.rwo_blank_label2}
													type={'text'}
													name={'rwo_blank_label2'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_approved2_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_approved2}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_approved2}
													type={'dollar'}
													name={'rwo_blank_approved2'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_final2_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_final2}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_final2}
													type={'dollar'}
													name={'rwo_blank_final2'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td id={`rwo_blank_label3_${vehicle.id}`} data-total={this.state.rwo_blank_label3}><TextInput
													{...this.props}
													value={this.state.rwo_blank_label3}
													type={'text'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													name={'rwo_blank_label3'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_approved3_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_approved3}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_approved3}
													type={'dollar'}
													name={'rwo_blank_approved3'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_final3_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_final3}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_final3}
													type={'dollar'}
													name={'rwo_blank_final3'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td id={`rwo_blank_label4_${vehicle.id}`} data-total={this.state.rwo_blank_label4}><TextInput
													{...this.props}
													value={this.state.rwo_blank_label4}
													type={'text'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													name={'rwo_blank_label4'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_approved4_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_approved4}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_approved4}
													type={'dollar'}
													name={'rwo_blank_approved4'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_final4_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_final4}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_final4}
													type={'dollar'}
													name={'rwo_blank_final4'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td id={`rwo_blank_label5_${vehicle.id}`} data-total={this.state.rwo_blank_label5}><TextInput
													{...this.props}
													value={this.state.rwo_blank_label5}
													type={'text'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													name={'rwo_blank_label5'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_approved5_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_approved5}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_approved5}
													type={'dollar'}
													name={'rwo_blank_approved5'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_final5_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_final5}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_final5}
													type={'dollar'}
													name={'rwo_blank_final5'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td id={`rwo_blank_label6_${vehicle.id}`} data-total={this.state.rwo_blank_label6}><TextInput
													{...this.props}
													value={this.state.rwo_blank_label6}
													type={'text'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													name={'rwo_blank_label6'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_approved3_${vehicle.id}`} className={`approved_rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_approved6}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_approved6}
													type={'dollar'}
													name={'rwo_blank_approved6'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_blank_final3_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_blank_final6}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_blank_final6}
													type={'dollar'}
													name={'rwo_blank_final6'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>
										<tr>
											<td><label className={'float-end'}>Recon Total:</label></td>
											<td>{this.state.recon_total_approved}</td>
											<td>{this.state.recon_total_final}</td>
										</tr>
										<tr>
											<td colSpan={2}><label className="float-end">Final Total:</label></td>
											<td>{this.state.final_total}</td>
										</tr>
										<tr>
											<td><label className={'float-end'}>Transport to Store:</label></td>
											<td id={`rwo_original_acv_${vehicle.id}`}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_transport_to_store_approved}
													type={'dollar'}
													name={'rwo_transport_to_store_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td id={`rwo_original_acv_${vehicle.id}`} className={`rwo_totals_${vehicle.id}`} data-total={this.state.rwo_transport_to_store_final}><DollarInput
													{...this.props}
													disabled={setdisabled}
													onChange={this.onChange}
													onBlur={this.onBlur}
													value={this.state.rwo_transport_to_store_final}
													type={'dollar'}
													name={'rwo_transport_to_store_final'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
										</tr>

										<tr>
											<td id={`rwo_sold_date_${vehicle.id}`}><label className={'float-end'}>Sold Date</label><TextFieldGroup
													{...this.props}
													value={this.state.rwo_sold_date}
													type={'date'}
													name={'rwo_sold_date'}
													field={'rwo_sold_date'}
													onChange={this.onChange}
													onBlur={this.onBlur}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
											/></td>
											<td><label>Final Approved</label><AllInputs
													{...this.props}
													value={final_approved.value}
													type={'userstamp'}
													name={'final_approved'}
													parentid={vehicle.id}
													updatetype="vehicleproperty"
													vehicle={vehicle}
													user_name={final_approved.user_name}
													updated_at={final_approved.updated_at}
											/></td>
										</tr>

									</thead>
									<tbody />
								</table>
							</div>
						</div>
						<div className="card-footer" />
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	addVehicleProperty,
	getCurrentDepartment,
	vsearch,
};

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
		current_user: state.current_user,
		vehicle_filter: state.settings_nav.vehicle_filter,
		department: state.settings_nav.department,
	 };
}

export default connect(mapStateToProps, mappedActions)(ReconCost);
