import React, { PureComponent } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import ContentLoader from 'react-content-loader'
import VehicleDisplay from "./VehicleDisplay/VehicleDisplay.js"
import "./VehicleList.scss"

class VehicleList extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      renderedVehicles: [],
    }
  }

  componentDidMount() {
    this.renderItems()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.department !== this.props.department){
      this.setState({ isLoading: true }, () => {})
    }
  	if (prevProps.active_vehicles !== this.props.active_vehicles) {
      let renderedVehicles = this.renderItems()
      this.setState({ isLoading: false, renderedVehicles }, () => {})
  	}
  }

  renderItems = () => {
    const vehicles = this.props.active_vehicles
    const mappedVehicles = vehicles.map((vehicle) => (
      <VehicleDisplay vehicle={vehicle} key={`vid_${vehicle.id}`} />
    ))
    return mappedVehicles
  }

  render() {
    const { isLoading, renderedVehicles } = this.state

	const vehiclesLoadingSkeleton = (
		<div className="loading-skeleton">
			{/* Vehicle Image */}
			<ContentLoader
				height={250}
				width={400}
				speed={1.1}
			>
				<rect x="40" y="40" rx="3" ry="3" width="360" height="180" />
			</ContentLoader>
			{/* First Info Column */}
			<ContentLoader
				height={180}
				width={700}
				speed={1.1}
			>
				<rect x="20" y="10" rx="3" ry="3" width="160" height="10" />
				<rect x="60" y="10" rx="3" ry="3" width="600" height="10" />
				<rect x="280" y="10" rx="3" ry="3" width="160" height="10" />
				<rect x="60" y="30" rx="3" ry="3" width="520" height="10" />
				<rect x="105" y="50" rx="3" ry="3" width="550" height="10" />
				<rect x="20" y="70" rx="3" ry="3" width="160" height="10" />
				<rect x="60" y="70" rx="3" ry="3" width="580" height="10" />
				<rect x="280" y="70" rx="3" ry="3" width="160" height="10" />
				<rect x="60" y="90" rx="3" ry="3" width="500" height="10" />
				<rect x="60" y="110" rx="3" ry="3" width="510" height="10" />
				<rect x="105" y="130" rx="3" ry="3" width="550" height="10" />
				<rect x="20" y="150" rx="3" ry="3" width="160" height="10" />
				<rect x="60" y="150" rx="3" ry="3" width="500" height="10" />
				<rect x="280" y="150" rx="3" ry="3" width="160" height="10" />
				<rect x="120" y="170" rx="3" ry="3" width="500" height="10" />
				<rect x="120" y="190" rx="3" ry="3" width="420" height="10" />
			</ContentLoader>
			{/* Second Info Column */}
			<ContentLoader
				width={700}
				height={290}
				speed={1.1}
			>
				<rect x="40" y="65" rx="3" ry="3" width="160" height="10" />
				<rect x="80" y="65" rx="3" ry="3" width="550" height="10" />
				<rect x="40" y="85" rx="3" ry="3" width="160" height="10" />
				<rect x="182" y="85" rx="3" ry="3" width="520" height="10" />
				<rect x="40" y="105" rx="3" ry="3" width="360" height="10" />
			  </ContentLoader>
		</div>
	)

	return (
      <div
        id="page-vehicles-wrapper"
        className={"container-fluid pad-top-0 pad-left-0 bg-custom-"}
      >
        {isLoading && this.props.department ? (
			vehiclesLoadingSkeleton
        ) : !isLoading ? (
          <div className="rendered-content">{renderedVehicles}</div>
        ) : (
          ""
        )}

        {this.props.update_status &&
        this.props.update_status == "first_load" ? (
          <div className="container bg-dark">
            <h3 className="text-center">
              Vehicles and data are being loaded.
              <img
                src="https://cdn.ipacketrecon.com/images/loading.gif"
                className="mx-auto d-block col-md-2"
              />
            </h3>
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    active_vehicles: state.settings_refresh.active_vehicles,
    department: state.settings_departments.department.id,
  }
}
export default connect(mapStateToProps)(VehicleList)
