import React, { useMemo, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { MaterialReactTable } from 'material-react-table';
import {
 Box, Button, createTheme, ThemeProvider, useTheme,
} from '@mui/material';
import { updateDepartmentOrder } from '../../actions/apiDepartmentUpdates';
import { setCurrentTab } from '../../actions/apiSetInfo';
import DepartmentListItemContainer from './DepartmentListItemContainer';

const Example = (props) => {
  const departments = useSelector((state) => state.settings_departments.original_departments);
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () => createTheme({
        palette: {
          mode: 'light',
        },
      }),
    [globalTheme],
  );

  const columns = useMemo(
    () => [
    {
    accessorKey: 'name',
    header: 'name',
    size: 120,
  },
  {
    accessorKey: 'display_name',
    header: 'Display Name',
  },
  {
    accessorKey: 'order',
    header: 'Order',
  },
  {
    accessorKey: 'goal',
    header: 'Goal',
  },
  {
    accessorKey: 'department_type',
    header: 'Department Type',
  }],
  );

  const newdata = [];

  departments.map((department) => {
          newdata.push({
            name: [department.name],
            display_name: [department.display_name],
            name: [department.name],
            display_name: [department.display_name],
            order: [department.order],
            goal: [department.goal],
            department_type: [department.department_type],
            subgroup: [department.subgroup],
            subdomain_department: [department.subdomain_department],
            subdomain: [department.subdomain],
            department_id: [department.id],
            department_dealerships: [department.department_dealerships],
            dealerships: [department.dealerships],
            department_filters: [department.department_filters],
            department_properties: [department.department_properties],
            background_color: [department.background_color],
            badge_background_color: [department.badge_background_color],
            badge_text_color: [department.badge_text_color],
            text_color: [department.text_color],
            hours_profile_id: [department.hours_profile_id],
            is_hours: [department.is_hours],

        });
  });
  const tableRef = useRef();

  return (
    <ThemeProvider theme={tableTheme}>
    <MaterialReactTable
      tableRef={tableRef}
      autoResetPageIndex={false}
      columns={columns}
      data={newdata}
      enableRowOrdering
      enableSorting={false}
      enablePagination={false}
      renderDetailPanel={({ row }) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
         <DepartmentListItemContainer department_id={row.original.department_id} />
        }
        </Box>
      )}
      muiRowDragHandleProps={({ table }) => ({
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.getState();

          if (hoveredRow && draggingRow) {
            newdata.splice(
              hoveredRow.index,
              0,
              newdata.splice(draggingRow.index, 1)[0],
            );
          const neworder = newdata.map(item => (item.department_id[0]));
            props.updateDepartmentOrder(neworder);
          }
        },
      })}
    /></ThemeProvider>
  );
};

const mappedActions = {
  updateDepartmentOrder,
  setCurrentTab,
};

export default connect(null, mappedActions)(Example);
