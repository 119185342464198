import Api from '../api/reconApi.js';
import * as setInfo from './apiSetInfo';
import * as lookups from './lookups';
import * as dateFunctions from '../utils/dateFunctions';
import * as AllMutates from './AllMutates';
import * as toggleMenu from './toggleMenu';

export function createReportData() {
	return (dispatch, getState) => {
		const report_departments = getState().settings_reports.report_departments;
		const report_departments_array = report_departments.split(',');

		const report_dealerships = getState().settings_reports.report_dealerships;
		const report_dealerships_array = report_dealerships.split(',');

		const report_filters = getState().settings_reports.report_filters;
		const all_filters = getState().settings_site.all_filters;

		const report_start_date = new Date(getState().settings_reports.report_start_date);
		const report_end_date = new Date(getState().settings_reports.report_end_date);

		const vehicles = getState().settings_refresh.all_vehicles;

		const site_limit_upper = parseInt(dispatch(lookups.lookupDealerSettingByName('site_limit_upper')));
		const site_limit_recon_upper = parseInt(dispatch(lookups.lookupDealerSettingByName('site_limit_recon_upper')));
		const site_limit_lower = parseInt(dispatch(lookups.lookupDealerSettingByName('site_limit_lower')));
		const site_limit_recon_lower = parseInt(dispatch(lookups.lookupDealerSettingByName('site_limit_recon_lower')));

		const report_data = {};
		const errorType = 'getDepartmentAverages';
		const allTimes = [];
		const allTimesCount = [];
		const allTimesAverage = [];
		const xAxis = [];
		const yAxis = [];
		const xCount = [];
		const monthlyArray = [];
		const countArray = [];
		const departmentVehicles = [];
		const rawCountArray = [];
		const report_array = [];
		const vehicleOutliers = [];

		report_dealerships_array.map((dealership) => {
			report_data[dealership] = [];
			allTimes[dealership] = [];
			allTimesCount[dealership] = [];
			allTimesAverage[dealership] = [];
			monthlyArray[dealership] = [];
			countArray[dealership] = [];
			yAxis[dealership] = [];
			xCount[dealership] = [];
			departmentVehicles[dealership] = [];
			report_data[dealership] = [];
			rawCountArray[dealership] = [];
			vehicleOutliers[dealership] = [];
			report_departments_array.map((department) => {
				if (parseInt(department) > 0) {
					report_data[dealership][department] = [];
					allTimes[dealership][department] = 0;
					allTimesCount[dealership][department] = 0;
					allTimesAverage[dealership][department] = 0;
					departmentVehicles[dealership][department] = [];
					rawCountArray[dealership][department] = [];
					vehicleOutliers[dealership][department] = [];
				}
			});
		});

		vehicles.map((vehicle) => {
			let condition = 0;
			const conditionArray = [];
			let filter_count = 0;
			report_filters.split(',').map((all_filter_id) => {
				const filt = dispatch(lookups.lookupFilterById(parseInt(all_filter_id)));
				if (filt.length === 0) {
					return;
				}
				filter_count += 1;
				const filter_name = filt.filter_name;
				const filter_target = filt.filter_target;
				const data_origin = filt.data_origin;
				let filter_value = filt.filter_value || 0;
				let find_value = '';
				let value = '';
				switch (data_origin) {
				case 'vehicle':
					value = vehicle[filter_target] || '';
					break;
				case 'vehicle_meta':
					find_value = dispatch(lookups.lookupVehiclePropertyByNameAll(vehicle.id, filter_target));
					value = find_value.value || '';
					break;
				default:
					break;
				}

				if (filt.filter_type === 'number') {
					filter_value = filt.filter_value || 0;
					switch (filt.filter_operator) {
					case 'Equals':
						if (parseInt(value) === parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Not Equals':
						if (parseInt(value) !== parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Greater Than':
						if (parseInt(value) > parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Less Than':
						if (parseInt(value) < parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Less or Equal':
						if (parseInt(value) <= parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Greater or Equal':
						if (parseInt(value) >= parseInt(filter_value)) {
							condition += 1;
						}
						break;
					default:
						break;
					}
				}

				if (filt.filter_type === 'condition') {
					filter_value = filt.filter_value || '';
					switch (filt.filter_operator) {
					case 'Equals':
						if (value.toLowerCase() === filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Not Equals':
						if (value.toLowerCase() !== filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Greater Than':
						if (value.toLowerCase() > filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Less Than':
						if (value.toLowerCase() < filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Less or Equal':
						if (value.toLowerCase() <= filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Greater or Equal':
						if (value.toLowerCase() >= filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					default:
						break;
					}
				}
			});

			if (filter_count > 0 && condition !== filter_count) {
				return;
			}

			vehicle.completed_departments.map((department_history) => {
				const department = department_history.department_id || 0;
				const dealership = vehicle.dealership || '';
				const totaltime = department_history.recon_total_24_5;
				const endtime = new Date(department_history.recon_endtime);

				if (totaltime > 0 && (endtime > report_start_date && endtime < report_end_date) && report_departments_array.includes(department.toString()) && report_dealerships_array.includes(dealership.toString())) {
					let notoutlier = true;
					let outlier_reason = '';
					if ((site_limit_lower > 0) && (totaltime < (site_limit_lower * 60))) {
						notoutlier = false;
						outlier_reason = 'site_limit_lower';
					}

					if ((site_limit_upper > 0) && (totaltime > (site_limit_upper * 60 * 60 * 24))) {
						notoutlier = false;
						outlier_reason = 'site_limit_upper';
					}
					if (notoutlier) {
						allTimes[dealership][department] += totaltime;
						allTimesCount[dealership][department] += 1;
						rawCountArray[dealership][department].push(department_history.vehicle_id);
						departmentVehicles[dealership][department].push(department_history);
					} else {
						vehicleOutliers[dealership][department].push({
							vehicle_id: department_history.vehicle_id, outlier_reason, totaltime, starttime: department_history.starttime, endtime: department_history.endtime,
						});
					}
				}
			});
			vehicle.completed_data_steps.map((department_history) => {
				const department = department_history.department_id || 0;
				const dealership = vehicle.dealership || '';
				const totaltime = department_history.recon_total_24_5;
				const endtime = new Date(department_history.recon_endtime);

				if (totaltime > 0 && (endtime > report_start_date && endtime < report_end_date) && report_departments_array.includes(department.toString()) && report_dealerships_array.includes(dealership.toString())) {
					let notoutlier = true;
					let outlier_reason = '';
					if ((site_limit_lower > 0) && (totaltime < (site_limit_lower * 60))) {
						notoutlier = false;
						outlier_reason = 'site_limit_lower';
					}

					if ((site_limit_upper > 0) && (totaltime > (site_limit_upper * 60 * 60 * 24))) {
						notoutlier = false;
						outlier_reason = 'site_limit_upper';
					}
					if (notoutlier) {
						allTimes[dealership][department] += totaltime;
						allTimesCount[dealership][department] += 1;
						rawCountArray[dealership][department].push(department_history.vehicle_id);
						departmentVehicles[dealership][department].push(department_history);
					} else {
						vehicleOutliers[dealership][department].push({
							vehicle_id: department_history.vehicle_id, outlier_reason, totaltime, starttime: department_history.starttime, endtime: department_history.endtime,
						});
					}
				}
			});
		});

		report_dealerships_array.map((dealership) => {
			report_departments_array.map((department) => {
				if (parseInt(department) > 0) {
					const count = [...new Set(rawCountArray[dealership][department])];
					if (allTimesCount[dealership][department] > 0) {
						allTimesAverage[dealership][department] = ((allTimes[dealership][department] / 86400) / count.length).toFixed(2);
					} else {
						allTimesAverage[dealership][department] = 0;
					}
					report_data[dealership][department] = {
						department_id: department,
						xAxis: department,
						yAxis: parseFloat(allTimesAverage[dealership][department]),
						xCount: count.length,
						rawCountArray: count,
						departmentVehicles: departmentVehicles[dealership][department],
						rawTime: allTimes[dealership][department],
						vehicleOutliers: vehicleOutliers[dealership][department],
					};
				}
			});
		});
		return dispatch(setInfo.setReportData(report_data));
	};
}

export function reportVehicles() {
	return (dispatch, getState) => {
		const report_departments = getState().settings_reports.report_departments;
		const report_departments_array = report_departments.split(',');
		const report_dealerships = getState().settings_reports.report_dealerships;
		const report_dealerships_array = report_dealerships.split(',');
		const report_filters = getState().settings_reports.report_filters;
		const all_filters = getState().settings_site.all_filters;
		const report_start_date = new Date(getState().settings_reports.report_start_date);
		const report_end_date = new Date(getState().settings_reports.report_end_date);
		const vehicles = getState().settings_refresh.all_vehicles;
		const site_limit_upper = parseInt(dispatch(lookups.lookupDealerSettingByName('site_limit_upper')));
		const site_limit_recon_upper = parseInt(dispatch(lookups.lookupDealerSettingByName('site_limit_recon_upper')));
		const site_limit_lower = parseInt(dispatch(lookups.lookupDealerSettingByName('site_limit_lower')));
		const site_limit_recon_lower = parseInt(dispatch(lookups.lookupDealerSettingByName('site_limit_recon_lower')));

		const report_data = {};
		const errorType = 'getDepartmentAverages';
		const allTimes = [];
		const allTimesCount = [];
		const allTimesAverage = [];
		const xAxis = [];
		const yAxis = [];
		const xCount = [];
		const monthlyArray = [];
		const countArray = [];
		const departmentVehicles = [];
		const rawCountArray = [];
		const report_array = [];
		const vehicleOutliers = [];
		report_dealerships_array.map((dealership) => {
			report_data[dealership] = [];
			allTimes[dealership] = [];
			allTimesCount[dealership] = [];
			allTimesAverage[dealership] = [];
			monthlyArray[dealership] = [];
			countArray[dealership] = [];
			yAxis[dealership] = [];
			xCount[dealership] = [];
			departmentVehicles[dealership] = [];
			report_data[dealership] = [];
			rawCountArray[dealership] = [];
			vehicleOutliers[dealership] = [];
			report_departments_array.map((department) => {
				if (parseInt(department) > 0) {
					report_data[dealership][department] = [];
					allTimes[dealership][department] = 0;
					allTimesCount[dealership][department] = 0;
					allTimesAverage[dealership][department] = 0;
					departmentVehicles[dealership][department] = [];
					rawCountArray[dealership][department] = [];
					vehicleOutliers[dealership][department] = [];
				}
			});
		});

		vehicles.map((vehicle) => {
			let condition = 0;
			const conditionArray = [];
			let filter_count = 0;
			report_filters.split(',').map((all_filter_id) => {
				const filt = dispatch(lookups.lookupFilterById(parseInt(all_filter_id)));
				if (filt.length === 0) {
					return;
				}
				filter_count += 1;
				const filter_name = filt.filter_name;
				const filter_target = filt.filter_target;
				const data_origin = filt.data_origin;
				let filter_value = filt.filter_value || 0;
				let find_value = '';
				let value = '';
				switch (data_origin) {
				case 'vehicle':
					value = vehicle[filter_target] || '';
					break;
				case 'vehicle_meta':
					find_value = dispatch(lookups.lookupVehiclePropertyByNameAll(vehicle.id, filter_target));
					value = find_value.value || '';
					break;
				default:
					break;
				}

				if (filt.filter_type === 'number') {
					filter_value = filt.filter_value || 0;
					switch (filt.filter_operator) {
					case 'Equals':
						if (parseInt(value) === parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Not Equals':
						if (parseInt(value) !== parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Greater Than':
						if (parseInt(value) > parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Less Than':
						if (parseInt(value) < parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Less or Equal':
						if (parseInt(value) <= parseInt(filter_value)) {
							condition += 1;
						}
						break;
					case 'Greater or Equal':
						if (parseInt(value) >= parseInt(filter_value)) {
							condition += 1;
						}
						break;
					default:
						break;
					}
				}

				if (filt.filter_type === 'condition') {
					filter_value = filt.filter_value || '';
					switch (filt.filter_operator) {
					case 'Equals':
						if (value.toLowerCase() === filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Not Equals':
						if (value.toLowerCase() !== filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Greater Than':
						if (value.toLowerCase() > filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Less Than':
						if (value.toLowerCase() < filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Less or Equal':
						if (value.toLowerCase() <= filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					case 'Greater or Equal':
						if (value.toLowerCase() >= filter_value.toLowerCase()) {
							condition += 1;
						}
						break;
					default:
						break;
					}
				}
			});

			if (filter_count > 0 && condition !== filter_count) {
				return;
			}

			vehicle.completed_departments.map((department_history) => {
				const department = department_history.department_id || 0;
				const dealership = vehicle.dealership || '';
				const totaltime = department_history.recon_total_24_5;
				const endtime = new Date(department_history.recon_endtime);

				if (totaltime > 0 && (endtime > report_start_date && endtime < report_end_date) && report_departments_array.includes(department.toString()) && report_dealerships_array.includes(dealership.toString())) {
					let notoutlier = true;
					let outlier_reason = '';
					if ((site_limit_lower > 0) && (totaltime < (site_limit_lower * 60))) {
						notoutlier = false;
						outlier_reason = 'site_limit_lower';
					}

					if ((site_limit_upper > 0) && (totaltime > (site_limit_upper * 60 * 60 * 24))) {
						notoutlier = false;
						outlier_reason = 'site_limit_upper';
					}
					if (notoutlier) {
						allTimes[dealership][department] += totaltime;
						allTimesCount[dealership][department] += 1;
						rawCountArray[dealership][department].push(department_history.vehicle_id);
						departmentVehicles[dealership][department].push(department_history);
					} else {
						vehicleOutliers[dealership][department].push({
							vehicle_id: department_history.vehicle_id, outlier_reason, totaltime, starttime: department_history.starttime, endtime: department_history.endtime,
						});
					}
				}
			});
		});

		report_dealerships_array.map((dealership) => {
			report_departments_array.map((department) => {
				if (parseInt(department) > 0) {
					if (allTimesCount[dealership][department] > 0) {
						allTimesAverage[dealership][department] = ((allTimes[dealership][department] / 86400) / allTimesCount[dealership][department]).toFixed(2);
					} else {
						allTimesAverage[dealership][department] = 0;
					}

					report_data[dealership][department] = {
						department_id: department,
						xAxis: department,
						yAxis: parseFloat(allTimesAverage[dealership][department]),
						xCount: allTimesCount[dealership][department],
						rawCountArray: rawCountArray[dealership][department],
						departmentVehicles: departmentVehicles[dealership][department],
						rawTime: allTimes[dealership][department],
						vehicleOutliers: vehicleOutliers[dealership][department],
					};
				}
			});
		});
		return dispatch(setInfo.setReportData(report_data));
	};
}

export function filterReport() {
	return (dispatch, getState) => {
		const departments = getState().settings_reports.report_departments;
		const report_departments_array = departments.split(',');
		const report_data = getState().settings_reports.report_data;
		const report_dealerships = getState().settings_reports.report_dealerships;
		const report_dealerships_array = report_dealerships.split(',');
		const report_data_filtered = {};
		const xAxis = [];
		const yAxis = [];
		const monthlyArray = [];
		const countArray = [];
		const departmentVehicles = [];
		const xCount = [];
		let rawTime = 0;
		let rawCount = 0;
		const rawCountArray = [];

		report_departments_array.map((department) => {
			if (parseInt(department) > 0) {
				const department_name = dispatch(lookups.lookupDepartmentNameById(parseInt(department)));
				xAxis.push(department_name);
			}
		});
		report_dealerships_array.map((dealership, i) => {
			if (dealership !== '') {
				monthlyArray[dealership] = [];
				countArray[dealership] = [];
				departmentVehicles[dealership] = [];
				yAxis[dealership] = [];
				xCount[dealership] = [];

				if (report_departments_array) {
					report_departments_array.map((department) => {
						if (report_data[dealership][parseInt(department)]) {
							yAxis[dealership].push(report_data[dealership][parseInt(department)].yAxis);
							xCount[dealership].push(report_data[dealership][parseInt(department)].xCount);
							rawTime += report_data[dealership][parseInt(department)].rawTime;
							rawCount += report_data[dealership][parseInt(department)].xCount;
							departmentVehicles[dealership].push(report_data[dealership][parseInt(department)].departmentVehicles);
							rawCountArray.push(...report_data[dealership][parseInt(department)].rawCountArray);
						}
					});
				}
			}
		});
		const count = [...new Set(rawCountArray)];
		report_data_filtered.xAxis = xAxis;
		report_data_filtered.yAxis = yAxis;
		report_data_filtered.xCount = xCount;
		report_data_filtered.rawCount = count.length;
		report_data_filtered.rawTime = rawTime;
		report_data_filtered.departmentVehicles = departmentVehicles;
		dispatch(setInfo.setReportDataFiltered(report_data_filtered));
		return true;
	};
}

export function filterMonthlyReport() {
	return (dispatch, getState) => {
		const departments = getState().settings_reports.report_departments;
		const report_data = getState().settings_reports.report_data;
		const report_dealerships = getState().settings_reports.report_dealerships;
		const report_data_filtered = {};
		const xAxis = ['January', 'February', 'March', 'April', 'May', 'June',
			'July', 'August', 'September', 'October', 'November', 'December'];
		const monthlyArray = [];
		const countArray = [];
		const departmentVehicles = [];
		const yAxis = [];
		const xCount = [];
		const report_dealerships_array = report_dealerships.split(',');
		report_dealerships_array.map((dealership, i) => {
			if (dealership !== '') {
				monthlyArray[dealership] = [];
				countArray[dealership] = [];
				departmentVehicles[dealership] = [];
				yAxis[dealership] = [];
				xCount[dealership] = [];
			}
		});

		xAxis.map((month, i) => {
			report_dealerships_array.map((dealership) => {
				if (dealership !== '') {
					monthlyArray[dealership][i] = 0;
					countArray[dealership][i] = 0;
					departmentVehicles[dealership][i] = [];
				}
			});
		});
		const vehicles = getState().settings_refresh.all_vehicles;

		vehicles.map((vehicle) => {
			if (vehicle.recon_complete === true && report_dealerships_array.includes(vehicle.dealership)) {
				const recon_complete_date = new Date(vehicle.recon_complete_date);
				const recon_complete_month = recon_complete_date.getMonth();
				const seconds = dateFunctions.getSecondsFromTimeRange(vehicle.created_at, vehicle.recon_complete_date);
				const days = parseInt(seconds) / (24 * 60 * 60);
				monthlyArray[vehicle.dealership][recon_complete_month] += days;
				countArray[vehicle.dealership][recon_complete_month] += 1;
				departmentVehicles[vehicle.dealership][recon_complete_month].push(vehicle);
			}
		});
		report_dealerships_array.map((dealership) => {
			if (dealership !== '') {
				monthlyArray[dealership].map((month_total_days, i) => {
					const total = (month_total_days / countArray[dealership][i]) || 0;
					yAxis[dealership].push(parseInt(total.toFixed(2)));
					xCount[dealership].push(countArray[dealership][i]);
				});
			}
		});
		report_data_filtered.xAxis = xAxis;
		report_data_filtered.yAxis = yAxis;
		report_data_filtered.xCount = xCount;
		report_data_filtered.departmentVehicles = departmentVehicles;

		dispatch(setInfo.setMonthlyReportDataFiltered(report_data_filtered));
	};
}

export function runReport() {
	return (dispatch, getState) => {
		const departments = getState().settings_reports.report_departments;
		const report_departments_array = departments.split(',');

		const report_data = getState().settings_reports.report_data;

		const report_dealerships = getState().settings_reports.report_dealerships;
		const report_dealerships_array = report_dealerships.split(',');

		const report_data_filtered = {};
		const xAxis = [];
		const yAxis = [];
		const monthlyArray = [];
		const countArray = [];
		const departmentVehicles = [];
		const xCount = [];
		let rawTime = 0;
		let rawCount = 0;
		const rawCountArray = [];

		report_departments_array.map((department) => {
			if (parseInt(department) > 0) {
				const department_name = dispatch(lookups.lookupDepartmentNameById(parseInt(department)));
				xAxis.push(department_name);
			}
		});

		report_dealerships_array.map((dealership, i) => {
			if (dealership !== '') {
				monthlyArray[dealership] = [];
				countArray[dealership] = [];
				departmentVehicles[dealership] = [];
				yAxis[dealership] = [];
				xCount[dealership] = [];

				if (report_departments_array) {
					report_departments_array.map((department) => {
						if (report_data[dealership][parseInt(department)]) {
							yAxis[dealership].push(report_data[dealership][parseInt(department)].yAxis);
							xCount[dealership].push(report_data[dealership][parseInt(department)].xCount);
							rawTime += report_data[dealership][parseInt(department)].rawTime;
							rawCount += report_data[dealership][parseInt(department)].xCount;
							departmentVehicles[dealership].push(report_data[dealership][parseInt(department)].departmentVehicles);
							rawCountArray.push(...report_data[dealership][parseInt(department)].rawCountArray);
						}
					});
				}
			}
		});

		const count = [...new Set(rawCountArray)];
		report_data_filtered.xAxis = xAxis;
		report_data_filtered.yAxis = yAxis;
		report_data_filtered.xCount = xCount;
		report_data_filtered.rawCount = count.length;
		report_data_filtered.rawTime = rawTime;
		report_data_filtered.departmentVehicles = departmentVehicles;

		dispatch(setInfo.setReportDataFiltered(report_data_filtered));
		dispatch(toggleMenu.change_page(''));
		dispatch(toggleMenu.change_page('reports'));

		return true;
	};
}

export default 'getDepartmentAverages';
