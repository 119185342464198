import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroupNoLabel';
import TextFieldGroupWithButton from '../../inputs/TextFieldGroupWithButton';
import FlashMessageList from '../../inputs/FlashMessageList';
import Api from '../../api/reconApi.js';
import { addWorkItemPreset } from '../../actions/apiWorkItemPresets';
import './AddWorkItemPreset.scss'

class AddWorkItemPreset extends Component {
	constructor(props) {
		super(props);
		if(props.workItem){
			this.state = {
				modalIsOpen: false,
				name: '',
				estimate_total: '',
				estimate_type: '',
				parts_price: 0,
				labor_price: 0,
				labor_units: '',
				category: '',
				description: '',
				...props.workItem,
			};
		}
		else{
			this.state = {
				modalIsOpen: false,
				name: '',
				estimate_total: '',
				estimate_total_editable: true,
				estimate_type: '',
				parts_price: 0,
				parts_price_editable: true,
				labor_price: 0,
				labor_price_editable: true,
				labor_units: '',
				labor_units_editable: true,
				category: '',
				description: '',
			};
		}
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.addWorkItemPreset = this.addWorkItemPreset.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onChangeDealership = this.onChangeDealership.bind(this);
		this.onChangeEstimateType = this.onChangeEstimateType.bind(this);
		this.onChangeCategory = this.onChangeCategory.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	onChangeSelect(e) {
		this.setState({ audit_type: e.value });
	}

	onChangeDealership(e) {
		this.setState({ dealerships: e.value });
	}

	onChangeEstimateType(e) {
		this.setState({ estimate_type: e.value });
	}

	onChangeCategory(e) {
		this.setState({ category: e.value });
	}

	selectCategory() {
		const categoryArray = [{ name: 'cosmetic', label: 'Cosmetic' }, { name: 'mechanical', label: 'Mechanical' }, { name: 'other', label: 'Other' }];

		const options = categoryArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectType() {
		const categoryArray = [{ name: 'parts_and_labor', label: 'Parts and Labor' }, { name: 'blank_parts_and_labor', label: 'Blank Parts and Labor' }];

		const options = categoryArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectRelatedInstallations() {
		const options = this.props.related_installations.map((option) => {
			if (typeof option.subdomain !== 'undefined') {
				var obj = { value: option.subdomain, label: option.softname };
			} else {
				var obj = { value: option.subdomain, label: option.softname };
			}
			return obj;
		});
		return options;
	}

	addWorkItemPreset() {
		let data = {
			name: this.state.name,
			description: this.state.description,
			category: this.state.category,
			estimate_type: this.state.estimate_type,
			estimate_type_editable: this.state.estimate_type_editable,
			parts_price: this.state.parts_price,
			parts_price_editable: this.state.parts_price_editable,
			labor_price: this.state.labor_price,
			labor_price_editable: this.state.labor_price_editable,
			labor_units: this.state.labor_units,
			labor_units_editable: this.state.labor_units_editable,
			estimate_total: this.state.estimate_total,
		};
		if(this.props.workItem && this.props.workItem.id){
			data.id = this.props.workItem.id
		}

		const extra = {};

		this.props.addWorkItemPreset(data, extra).then((resp) => {
			this.setState({
				name: '',
				locked: '',
				deleted: '',
				active: false,
				modalIsOpen: false,
			});
		});
	}

	onChange(event) {
		const { name } = event.target;
		this.setState({ [name]: event.target.value });
	}

	render() {
		const { errors } = this.state;

		const defaultStyle = {
			background: this.props.bg_color,
			backgroundColor: this.props.bg_color,
			color: this.props.font_color,
		};

		return (
			<div className="input-group-append">
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="PickColor"
					className="d-flex justify-content-center pad-bottom-2 space-bottom-2 add-work-item-modal"
					contentLabel="Add WorkItem Preset"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card md-auto space-top-2 pad-left-1 pad-right-1">
						<div className="modal-content">

							<div className="modal-header text-dark">
								<h1 className="modal-title">{`${this.props.workItem?'Edit':'Add'} Work Item Preset`}</h1>
								<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>x</button>
							</div>

							<div className="modal-body pad-1">
								<div className="row">

									<div className="mb-3">
										<label className="form-label"><h2>Name:</h2></label>
										<TextFieldGroup
											onChange={this.onChange}
											value={this.state.name}
											type={'text'}
											name={'name'}
											field={'name'}
											updatetype="work_item_preset"
											disabled={false}
										/>

												<label><h2>Category</h2></label>
												<Select
													onChange={this.onChangeCategory}
													value={this.selectCategory().filter(({ value }) => value === this.state.category)}
													name={'category'}
													options={this.selectCategory()}
													className="text-capitalize"
													clearable={false}
													searchable={false}
													menuContainerStyle={{ zIndex: 5 }}
												/>

												<label><h2>Estimate Template</h2></label>
												<Select
													onChange={this.onChangeEstimateType}
													value={this.selectType().filter(({ value }) => value === this.state.estimate_type)}
													name={'estimate_type'}
													options={this.selectType()}
													className="text-capitalize"
													clearable={false}
													searchable={false}
													menuContainerStyle={{ zIndex: 5 }}
												/>

										<label><h2>Parts Price:</h2>Allow Editing? <input type="checkbox" checked={this.state.parts_price_editable} onClick={()=>{this.setState({parts_price_editable:!this.state.parts_price_editable})}}/></label>
										<TextFieldGroup
											onChange={this.onChange}
											value={this.state.parts_price}
											type={'text'}
											name={'parts_price'}
											field={'parts_price'}
											updatetype="work_item_preset"
											disabled={false}
										/>

										<label><h2>Labor Units:</h2> Allow Editing? <input type="checkbox" checked={this.state.labor_units_editable} onClick={()=>{this.setState({labor_units_editable:!this.state.labor_units_editable})}}/></label>
										<TextFieldGroup
											onChange={this.onChange}
											value={this.state.labor_units}
											type={'text'}
											name={'labor_units'}
											field={'labor_units'}
											updatetype="work_item_preset"
											disabled={false}
										/>

										<label><h2>Labor Price:</h2> Allow Editing? <input type="checkbox" checked={this.state.labor_price_editable} onClick={()=>{this.setState({labor_price_editable:!this.state.labor_price_editable})}}/></label>
										<TextFieldGroup
											onChange={this.onChange}
											value={this.state.labor_price}
											type={'text'}
											name={'labor_price'}
											field={'labor_price'}
											updatetype="work_item_preset"
											disabled={false}
										/>

										<label><h2>Estimate Total:</h2> Allow Editing? <input type="checkbox" checked={this.state.estimate_total_editable} onClick={()=>{this.setState({estimate_total_editable:!this.state.estimate_total_editable})}}/></label>
										<TextFieldGroup
											onChange={this.onChange}
											value={this.state.estimate_total}
											type={'text'}
											name={'estimate_total'}
											field={'estimate_total'}
											updatetype="work_item_preset"
											disabled={false}
										/></div>
								</div>
							</div>
							<div className="modal-footer pad-top-2 pad-bottom-2">
								<button type="button" className="btn btn-success" onClick={this.addWorkItemPreset}>Save</button>
							</div>

						</div>
					</div>

				</Modal>
				<span className="input-group-text btn btn-primary" onClick={this.openModal}>
					{`${this.props.workItem?'Edit':'Add'} Preset`}
				</span>
			</div>
		);
	}
}

const mappedActions = {
	addWorkItemPreset,
};

function mapStateToProps(state) {
	return {
		work_item_presets: state.settings_refresh.work_item_presets,
		related_installations: state.settings_dealerships.related_installations,
	};
}
export default connect(mapStateToProps, mappedActions)(AddWorkItemPreset);
