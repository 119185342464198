import React, { Component, useMemo } from 'react';
import { connect } from 'react-redux';
import { MaterialReactTable } from 'material-react-table';
import {
 Box, Button, createTheme, ThemeProvider, useTheme, MenuItem, ListItemIcon,
} from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import {
  getReportVehicles,
} from '../../../../actions/apiGetInfo';
import { convertSecondsToTime, getSecondsFromTimeRange_24_5, noteStamp } from '../../../../utils/dateFunctions';
import NewEstimateSingle from './TransportSingle';
import EstimateSingleTableRow from './EstimateSingleTableRow';
import {
	addEstimate,
} from '../../../../actions/apiEstimates';

const estimateFields = ['Ro Number', 'Recipients', 'Description', 'Amount', 'Status', 'x'];

const columns = [
  {
    accessorKey: 'action',
    header: 'Action',
    Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <NewEstimateSingle estimate={row} />
              </Box>
            ),
  },
  {
    accessorKey: 'requested_by_uid',
    header: 'Recipients',
  },
  {
    accessorKey: 'delete',
    header: 'Delete',
    Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'left',
                  gap: '1rem',
                }}
              >
                <button className="btn btn-sm btn-danger" onClick={self.onDelete}>X</button>
              </Box>
            ),
  },
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const Transport = (props) => {
	const onDelete = (e) => {
		props.deleteEstimate(props.estimate.id, props.active_vehicle.id, 'addDeleteEstimateError');
	};
	const globalTheme = useTheme();

	  const tableTheme = useMemo(
	    () => createTheme({
	        palette: {
	          mode: 'light', // let's use the same dark/light mode as the global theme
	        },
	      }),
	    [globalTheme],
	  );

	  const handleExportRows = (rows) => {
	    csvExporter.generateCsv(rows.map((row) => row.original));
	  };

	  const handleExportData = () => {
	    csvExporter.generateCsv(data);
	  };

  	const newdata = [];

	const onClick = () => {
		const data = {
			vehicle_id: props.active_vehicle.id,
			ro_status: 'transport',
		};
		props.addEstimate(data, 'addEstimateError');
	};

	props.appraisals.map(estimate => {
		if (estimate.ro_status === 'transport') {
              newdata.push({
                requested_by_uid: [estimate.requested_by_uid],
                action: [estimate.ro_status],
            });
			}
          });

		return (
			<ThemeProvider theme={tableTheme}>
      <div className="card-header">
          <button className="btn btn-sm btn-success" onClick={onClick}>Add Transport</button>
        </div>
    <MaterialReactTable
      columns={columns}
      data={newdata}
      positionToolbarAlertBanner="bottom"
      renderDetailPanel={({ row }) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        />
      )}
    /></ThemeProvider>
		);
};

const mappedActions = {
	addEstimate,
};

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
		appraisals: state.settings_refresh.appraisals,
		installation: state.settings_dealerships.installation,
		all_tabs: state.settings_site.all_tabs,
	};
}

export default connect(mapStateToProps, mappedActions)(Transport);
