import React, { Component } from 'react';
import { connect } from 'react-redux';
import TabEditDepartmentSettings from './TabEditDepartmentSettings';
import TabEditDepartmentColors from './TabEditDepartmentColors';
import TabEditDepartmentFilters from './TabEditDepartmentFilters';
import TabEditVehicleColumn from './TabEditVehicleColumn';
import TabEditDepartmentProperties from './TabEditDepartmentProperties';
import DropdownMultiple from '../../inputs/DropdownMultiple';
import {
	setCurrentTab,
	setCurrentDefaultProfile,
} from '../../actions/apiSetInfo';

const activeOptions = ['true', 'false'];

class DepartmentSetupBody extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: { addDepartmentPropertyError: false }, option_name: '',
		};
		this.onTabClick = this.onTabClick.bind(this);
		this.onMenuClick = this.onMenuClick.bind(this);
	}

	onTabClick(e) {
		this.props.setCurrentTab(e.target.name);
	}

	onMenuClick(e) {
		this.props.setCurrentDefaultProfile(e.target.name);
	}

	render() {
		const self = this;
		const { props } = this;
		const department = self.props.edit_department;
		const items = [];

		return (
			<div className="col">
				<ul className="nav nav-pills nav-fill bg-light pad-bottom-1" role="tablist">
					<li className="nav-item">
						<a className={(this.props.current_tab === 'default-department' ? 'nav-link active' : 'nav-link')} name="default-department" onClick={this.onTabClick}>Add/Edit Department</a>
					</li>
					<li className="nav-item">
						<a className={(this.props.current_tab === 'default-department-columns' ? 'nav-link active' : 'nav-link')} name="default-department-columns" onClick={this.onTabClick}>Add/Edit Columns</a>
					</li>
					<li className="nav-item">
						<a className={(this.props.current_tab === 'default-department-filters' ? 'nav-link active' : 'nav-link')} name="default-department-filters" onClick={this.onTabClick}>Filters</a>
					</li>
				</ul>
				<div className="container-fluid pad-left-0 pad-right-0 bg-light">

					<div className={(this.props.current_tab === 'default-department-columns' ? 'tab-box active' : 'tab-box')}>
						<TabEditDepartmentProperties />
					</div>
					<div className={(this.props.current_tab === 'default-department' ? 'tab-box active' : 'tab-box')}>
						<TabEditDepartmentSettings />
					</div>
					<div className={(this.props.current_tab === 'default-department-filters' ? 'tab-box active' : 'tab-box')}>
						<TabEditDepartmentFilters />
					</div>

				</div>
			</div>
		);
	}
}

const mappedActions = {
	setCurrentTab,
	setCurrentDefaultProfile,
};

function mapStateToProps(state) {
	return {
		current_tab: state.settings_admin.current_tab,
		original_departments: state.settings_departments.original_departments,
		vehicle_columns: state.settings_departments.vehicle_columns,
		edit_department: state.settings_admin.edit_department,
		edit_departments: state.settings_admin.edit_departments,
		department_properties: state.settings_admin.edit_department_properties,
		input_types: state.settings_departments.input_types,
		dealerships: state.settings_admin.edit_locations,
		set_dealerships: state.settings_dealerships.related_installations,
	};
}
export default connect(mapStateToProps, mappedActions)(DepartmentSetupBody);
