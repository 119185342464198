import React, {
 Component, useMemo, useState, useRef,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { MaterialReactTable } from 'material-react-table';
import {
 Box, Button, createTheme, ThemeProvider, useTheme,
} from '@mui/material';
import SortableColumns from './SortableColumns';
import AddVehicleColumnButton from './AddVehicleColumnButton';
import AddColumnToDepartmentPropertyButton from './AddColumnToDepartmentPropertyButton';
import DropdownMultiple from '../../inputs/DropdownMultiple';
import Dropdown from '../../inputs/Dropdown';
import TabEditVehicleColumnContainer from './TabEditVehicleColumnContainer';

import {
	setCurrentTab,
} from '../../actions/apiSetInfo';

const columns = [
  {
    accessorKey: 'addto',
    header: 'Add +',
    size: 120,
    Cell: ({ renderedCellValue, row }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <AddColumnToDepartmentPropertyButton vehicle_column={row} />
                <span>{renderedCellValue}</span>
              </Box>
            ),
  },
  {
    accessorKey: 'name',
    header: 'name',
    size: 120,
  },
  {
    accessorKey: 'input_name',
    header: 'Input Type',
    size: 120,
  },
  {
    accessorKey: 'automove',
    header: 'Automove',
  },
];

const TabEditDepartmentProperties = (props) => {
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () => createTheme({
        palette: {
          mode: 'light', // let's use the same dark/light mode as the global theme
        },
      }),
    [globalTheme],
  );

  const newdata = [];

  props.vehicle_columns.map((vehicle_column) => {
          newdata.push({
            name: [vehicle_column.name],
            input_name: [vehicle_column.input_name],
            automove: [vehicle_column.automove],
            org: [vehicle_column],
            vehicle_column_id: [vehicle_column.id],
        });
  });

	const selectDepartments = () => {
		const department = props.edit_department || [];
		const selected_departments = [];

		selected_departments.push(department.id);

		const options = props.original_departments.map((option) => {
			let selected = false;
			const dataid = 0;
			if (selected_departments.includes(option.id)) {
				selected = true;
			}

			var obj = {
				id: option.id, label: option.name, selected,
			};
			return obj;
		});
		return options;
	};

		const department = props.edit_department;
		const items = [];

		if (props.department_properties !== 'undefined') {
			props.department_properties.map((item, i) => {
				items.push(<SortableColumns item={item} key={`sortable_columns_${i}`} />);
			});
		}

		const tableRef = useRef();

		return (
			<div className="container-fluid">
				<div className="col-md-12">
					<h5 className="text-success text-center text-capitalize">{department.name}<br />
						{' '}
						<small className="text-warning text-center">Contact support if you need any assistance.</small>
					</h5>
					<div className="row">
						<div className="card col-md-3">
							<div className={department.name ? 'card-header bg-success text-white' : 'card-header bg-warning text-white'}>
								<div className="p-2 text-capitalize">
									{department.name ? department.name : 'Select Departments'}
								</div>

							</div>
							<div className="card-text">

								<div className="d-flex flex-column">
									<div className="p-2">
										<div className="col-md-12">
											<small>
												<i className="fa fa-info-circle text-info" />
												{' '}
Drag and Drop columns to change order
											</small>
										</div>

										<div className="p-2 list-group setscroll">
											{items}
										</div>

									</div>

								</div>
							</div>
							<div className="card-footer" />
						</div>

						<div className="card col-md-9">
							<div className="card-header bg-dark text-white">
								<div className="col-md-6">
									<AddVehicleColumnButton />
								</div>

								<div className="col-md-6 text-dark">
									<Dropdown
										{...props}
										titleHelper="Department"
										title={department.name ? department.name : 'Select Department'}
										list={selectDepartments()}
										update_type={'set_edit_department'}
									/>
								</div>

							</div>
							<div className="card-text">

								<div className="d-flex flex-column">
									<div className="p-2 list-group setscroll">
										<ThemeProvider theme={tableTheme}>
										    <MaterialReactTable
											  tableRef={tableRef}
										      columns={columns}
											  enableSorting={false}
										      data={newdata}
											  initialState={{ showColumnFilters: true }}
										       renderDetailPanel={({ row }) => (
										        <Box
										          sx={{
										            display: 'flex',
										            justifyContent: 'space-around',
										            alignItems: 'center',
										          }}
										        >
										         <TabEditVehicleColumnContainer vehicle_column={row} />
										        </Box>
										      )}

										    /></ThemeProvider>
									</div>

								</div>
							</div>
							<div className="card-footer" />
						</div>
					</div>
				</div>
			</div>
		);
	};

function mapStateToProps(state) {
	return {
		current_tab: state.settings_admin.current_tab,
		original_departments: state.settings_departments.original_departments,
		vehicle_columns: state.settings_departments.vehicle_columns,
		edit_department: state.settings_admin.edit_department,
		edit_departments: state.settings_admin.edit_departments,
		department_properties: state.settings_admin.edit_department_properties,
		input_types: state.settings_departments.input_types,
		dealerships: state.settings_admin.edit_locations,
		set_dealerships: state.settings_dealerships.related_installations,
	};
}

export default connect(mapStateToProps)(TabEditDepartmentProperties);
