import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import TextFieldGroupWithButton from '../../inputs/TextFieldGroupWithButton';
import FlashMessageList from '../../inputs/FlashMessageList';
import * as dateFunctions from '../../utils/dateFunctions';

class BigCalendar extends Component {
	constructor(props) {
		super(props);

		this.state = { modalIsOpen: false, starttime: '', endtime: '' };
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.addEvent = this.addEvent.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	onChange(e) {
		console.log(e);
	}

	onBlur(e) {
		console.log(e.value);
	}

	addEvent(e) {
		console.log(e);
	}

	openModal(event) {
		console.log(event);
		const date = new Date(event.start);
		const start = this.props.getMonthDayYearFromDate(date);

		this.setState({
			modalIsOpen: true,
			starttime: event.start,
			endtime: event.end,
			date: start,
		});
	}

	afterOpenModal() {}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	render() {
		const eventArray = [];

		return (
			<div className="container text-center calendar-container pad-right-2 pad-left-2">
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="Add Event"
					className="d-flex justify-content-center pad-bottom-2 space-bottom-2"
					contentLabel="Add Event"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-6 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="modal-content">

							<div className="modal-header bg-dark text-white">
								<h4 className="modal-title">{this.state.date}</h4>
								<button type="button" className="close text-white" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
							</div>

							<div className="modal-body add-vehicle">
								<div className="card-block bg-white">
									<div className="row text-center">
										<FlashMessageList {...this.props} />
									</div>
									<div className="row">
										<div className="container">
												  <form>
												    <ul className="flex-outer">
												      <li>
												        <label>Vehicle</label>
												      </li>
												      <li>
												        <label>Start Date/Time</label>
												        <input type="date" name="start_date" onBlur={this.onBlur} value={() => dateFunctions.getDayMonthYearFromDate(this.state.date)} />
												      </li>
												      <li>
												        <label>End Date/Time</label>
												        <input type="date" name="end_date" onBlur={this.onBlur} />
												      </li>
												      <li>
												        <label>Event</label>
												        <input type="text" name="event" onBlur={this.onBlur} placeholder="EventType" />
												      </li>
												      <li>
												        <label>Notes</label>
												        <textarea name="description" placeholder="Enter Description" cols={70} />
												      </li>

												    </ul>
												  </form>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-success" onClick={this.addVehicle}>Create Event</button>
							</div>

						</div>
					</div>

				</Modal>
				<h3 />

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps)(BigCalendar);
