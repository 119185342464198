import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';
import EditInstallation from '../../inputs/EditInstallation';
import DropBoxDealer from '../../inputs/FileUploadButton/DropBoxDealer';

class TimeDashboardSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value1: this.props.override_value_1, current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');
		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	render() {
		const { installation } = this.props;
		const vehicle_tab_override = this.dealerSettingValue('vehicle_tab_override') || 'false';
		const timedashboard_1 = this.dealerSettingValue('timedashboard_1') || 'false';
		const timedashboard_2 = this.dealerSettingValue('timedashboard_2') || 'false';
		const timedashboard_3 = this.dealerSettingValue('timedashboard_3') || 'false';
		const timedashboard_4 = this.dealerSettingValue('timedashboard_4') || 'false';
		const timedashboard_5 = this.dealerSettingValue('timedashboard_5') || 'false';
		const timedashboard_6 = this.dealerSettingValue('timedashboard_6') || 'false';
		const timedashboard_7 = this.dealerSettingValue('timedashboard_7') || 'false';
		const timedashboard_8 = this.dealerSettingValue('timedashboard_8') || 'false';
		const timedashboard_9 = this.dealerSettingValue('timedashboard_9') || 'false';
		const timedashboard_10 = this.dealerSettingValue('timedashboard_10') || 'false';

		const timedashboard_1_time = this.dealerSettingValue('timedashboard_1_time') || 'false';
		const timedashboard_2_time = this.dealerSettingValue('timedashboard_2_time') || 'false';
		const timedashboard_3_time = this.dealerSettingValue('timedashboard_3_time') || 'false';
		const timedashboard_4_time = this.dealerSettingValue('timedashboard_4_time') || 'false';
		const timedashboard_5_time = this.dealerSettingValue('timedashboard_5_time') || 'false';
		const timedashboard_6_time = this.dealerSettingValue('timedashboard_6_time') || 'false';
		const timedashboard_7_time = this.dealerSettingValue('timedashboard_7_time') || 'false';
		const timedashboard_8_time = this.dealerSettingValue('timedashboard_8_time') || 'false';
		const timedashboard_9_time = this.dealerSettingValue('timedashboard_9_time') || 'false';
		const timedashboard_10_time = this.dealerSettingValue('timedashboard_10_time') || 'false';

		const html = [];

		return (
			<div className="col-md-12">

				<div className="space-top-1 mx-auto w-75 p-3">
					<h3 className="text-center pad-top-1">Set Time Dashboard Default Reports (Set Report type)</h3>
					<p>Defining a workflow creates that workflow report as an option here.</p>

					<div className="card-body">

				<div className="card space-top-0 v80">
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_1" label="Dashboard Report Spot 1" value={this.dealerSettingValue('timedashboard_1')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_1_time" label="Dashboard Report Time Type spot 1" value={this.dealerSettingValue('timedashboard_1_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_2" label="Dashboard Report Spot 2" value={this.dealerSettingValue('timedashboard_2')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_2_time" label="Dashboard Report Time Type spot 2" value={this.dealerSettingValue('timedashboard_2_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_3" label="Dashboard Report Spot 3" value={this.dealerSettingValue('timedashboard_3')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_3_time" label="Dashboard Report Time Type spot 3" value={this.dealerSettingValue('timedashboard_3_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_4" label="Dashboard Report Spot 4" value={this.dealerSettingValue('timedashboard_4')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_4_time" label="Dashboard Report Time Type spot 4" value={this.dealerSettingValue('timedashboard_4_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_5" label="Dashboard Report Spot 5" value={this.dealerSettingValue('timedashboard_5')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_5_time" label="Dashboard Report Time Type spot 5" value={this.dealerSettingValue('timedashboard_5_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_6" label="Dashboard Report Spot 6" value={this.dealerSettingValue('timedashboard_6')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_6_time" label="Dashboard Report Time Type spot 6" value={this.dealerSettingValue('timedashboard_6_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_7" label="Dashboard Report Spot 7" value={this.dealerSettingValue('timedashboard_7')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_7_time" label="Dashboard Report Time Type spot 7" value={this.dealerSettingValue('timedashboard_7_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_8" label="Dashboard Report Spot 8" value={this.dealerSettingValue('timedashboard_8')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_8_time" label="Dashboard Report Time Type spot 8" value={this.dealerSettingValue('timedashboard_8_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_9" label="Dashboard Report Spot 9" value={this.dealerSettingValue('timedashboard_9')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_9_time" label="Dashboard Report Time Type spot 9" value={this.dealerSettingValue('timedashboard_9_time')} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-report'} name="timedashboard_10" label="Dashboard Report Spot 10" value={this.dealerSettingValue('timedashboard_10')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-dashboard-time'} name="timedashboard_10_time" label="Dashboard Report Time Type spot 10" value={this.dealerSettingValue('timedashboard_10_time')} />
						</div>
					</div>
					</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps)(TimeDashboardSettings);
