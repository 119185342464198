import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import EstimateSingle from './EstimateSingle';
import {
	addEstimate,
} from '../../../../actions/apiEstimates';

const estimateFields = ['Ro Number', 'Recipients', 'Description', 'Amount', 'Status', 'x'];

const allFields = ['vehicle_id', 'ro_number', 'requested_by_uid', 'description', 'estimate',
	'actual', 'ro_status', 'approved_by_uid', 'approved_date', 'declined_by_uid', 'declined_date', 'ro_files',
	'declined_reason', 'ro_email_link_declined', 'ro_email_link_approved'];

class Estimates extends Component {
	constructor(props) {
		super(props);

		this.state = { addEstimateError: '' };
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_status: 'getapproved',
		};
		this.props.addEstimate(data, 'addEstimateError');
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const errors = this.state;
		let button_text = 'Add Estimate';
		if (self.props.installation.database.includes('stanley')) {
			button_text = 'Add Additional Required Work';
		}

		return (
			<div className="card">
				<div className="card-header">
					<button className="btn btn-sm btn-success" onClick={this.onClick}>{button_text}</button>
				</div>
				<div className="card-body">

					{this.props.appraisals.map(estimate => <EstimateSingle key={`estimate_single_${estimate.id}`} estimate={estimate} vehicle={vehicle} />)}

				</div>
				<div className="card-footer" />
			</div>
		);
	}
}

const mappedActions = {
	addEstimate,
};

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
		appraisals: state.settings_refresh.active_vehicle.appraisals,
		installation: state.settings_dealerships.installation,
	};
}

export default connect(mapStateToProps, mappedActions)(Estimates);
