import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import VinSearch from './VinSearch';
import FlashMessageList from '../../inputs/FlashMessageList';

class VinSearchBox extends Component {
	constructor(props) {
		super(props);

		this.state = { username: '', password: '' };
	}

	componentDidMount() {
	}

	render() {
		return (
			<div className="container-fluid bg-light">

				<div className="col-md-12">
					<div className="col-md-12 pad-bottom-1 pad-top-1 text-center">
						<img src="https://cdn.ipacketrecon.com/images/logo.png" className="img-fluid" />
					</div>
					<VinSearch {...this.props} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
	};
}
export default connect(mapStateToProps)(VinSearchBox);
