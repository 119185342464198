import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DepartmentSetupBody from './DepartmentSetupBody';
import { activeEditVehicleColumn } from '../../actions/lookups';
import DepartmentListItem from './DepartmentListItem';
import { updateEditDepartment } from '../../actions/apiDepartmentUpdates';

const activeOptions = ['true', 'false'];

class DepartmentListItemContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	componentDidMount() {
		this.props.updateEditDepartment(this.props.department_id);
	}

	render() {
		const self = this;

		return (
			<div className="col-md-12">

				<div className="row">
					{this.props.edit_department.id > 0 ? <DepartmentListItem /> : ''}
				</div>
			</div>
		);
	}
}

const mappedActions = {
	updateEditDepartment,
};

function mapStateToProps(state) {
	return { edit_department: state.settings_admin.edit_department };
}

export default connect(mapStateToProps, mappedActions)(DepartmentListItemContainer);
