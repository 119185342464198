import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as lookups from './lookups';
import * as AllMutates from './AllMutates';

export function updateWorkItem(id, data, errorType) {
	return dispatch => Api.patchData(`work_items/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(lookups.activeVehicle(data.vehicle_id)),
		]).then(() => dispatch(getInfo.getWorkItemsByVidAndUpdate(data.vehicle_id)));
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateWorkItemPage(id, key, data, errorType) {
	return dispatch => Api.patchData(`work_items/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(lookups.activeVehicle(data.vehicle_id)),
		]).then(() => dispatch(getInfo.getWorkItemsByVidAndUpdate(data.vehicle_id)));
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function sendWorkItem(id, data, errorType) {
	return dispatch => Api.patchData(`work_items/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(lookups.activeVehicle(data.vehicle_id)),
		]).then(() => dispatch(getInfo.getWorkItemsByVidAndUpdate(data.vehicle_id)));
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addWorkItem(data, errorType) {
	return dispatch => Api.postData('work_items/', data).then((resp) => {
		Promise.all([
			dispatch(lookups.activeVehicle(data.vehicle_id)),
		]).then(() => dispatch(getInfo.getWorkItemsByVidAndUpdate(data.vehicle_id)));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteWorkItem(id, errorType) {
	return dispatch => Api.delete(`work_items/${id}`).then(() => dispatch(getInfo.processDepartments())).catch(ex => Api.handleApiError(ex, errorType));
}

export function addUviItem(data, errorType) {
	return dispatch => Api.postData('work_items/', data).then(resp => 'success').catch(ex => Api.handleApiError(ex, errorType));
}

export function updateUviItem(id, data, errorType) {
	return (dispatch, getState) => Api.patchData(`work_items/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(getInfo.getWorkItemsByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(data.vehicle_id));
		});
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateUviItemRaw(id, data, errorType) {
	return dispatch => Api.patchData(`work_items/${id}`, data).then((resp) => {
	}).catch(ex => Api.handleApiError(ex, errorType));
}
