import _ from 'lodash';
import * as types from './types';
import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as AllMutates from './AllMutates';

export default function update_loading_status(update_status) {
	return {
		type: types.UPDATE_STATUS,
		update_status,
	};
}
