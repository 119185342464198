import React from 'react';
import VehicleDisplayImage from './VehicleDisplayImage/VehicleDisplayImage';
import VehicleDisplayTabSelection from './VehicleDisplayTabSelection/VehicleDisplayTabSelection';
import VehicleDisplayInfoHeader from './VehicleDisplayInfoHeader/VehicleDisplayInfoHeader';
import VehicleDisplayInfoBody from './VehicleDisplayInfoBody/VehicleDisplayInfoBody';
import VehicleDisplayTabView from './VehicleDisplayTabView/VehicleDisplayTabView';
import './VehicleDisplay.scss'


export default function VehicleDisplay(props){
	const { vehicle } = props;


	return (
		<div data-vehicle-id={vehicle.id} className="ReactVehicle vehicle-display-container" data-vin={vehicle.vin}>
			<div className="bg-vehicle vehicle-display">
				<div className="vehicle-display-main">
					<div className="vehicle-display-main-left">
						<VehicleDisplayImage vehicle={vehicle}/>
						<VehicleDisplayTabSelection vehicle={vehicle}/>
					</div>
					<div className="vehicle-display-main-right">
						<VehicleDisplayInfoHeader vehicle={vehicle}/>
		        <VehicleDisplayInfoBody vehicle={vehicle} />
					</div>
				</div>
				<div className="vehicle-display-expanded-container">
					<div className="vehicle-display-expanded">
						<VehicleDisplayTabView vehicle={vehicle} vehicle_id={vehicle.id}/>
					</div>
				</div>
			</div>
		</div>
	);
}
