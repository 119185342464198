import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import TextBox from '../../inputs/TextBox';
import {
	getEstimatesByApprovedLink,
	getEstimateUserById,
	getEstimatesByDeclinedLink,
	getUserCheck,
} from '../../actions/apiGetInfo';
import {
	getDateTime,
} from '../../utils/dateFunctions';

class AppraisalForm extends Component {
	constructor(props) {
		super(props);
		const appraisal_link = this.props.match.params.appraisalToken.split(':');

		this.state = {
			link_checked: false, counter_estimate: '', declined_reason: '', current_user: appraisal_link[1], subdomain: appraisal_link[2], appraisal_link: appraisal_link[0], link_type: appraisal_link[3], estimate_id: '', estimate_updated: false,
		};
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.setLink = this.setLink.bind(this);
	}

	setLink() {
		let data = {};
		switch (this.state.link_type) {
		case 'approved':
			data = {
				ro_status: this.state.link_type,
				approved_by_uid: this.state.current_user,
				approved_date: getDateTime(),
			};
			this.props.getEstimatesByApprovedLink(this.state.appraisal_link, this.state.subdomain, data);
			break;
		case 'declined':
			data = {
				ro_status: this.state.link_type,
				declined_by_uid: this.state.current_user,
				declined_date: getDateTime(),
			};
			this.props.getEstimatesByDeclinedLink(this.state.appraisal_link, this.state.subdomain, data);
			break;
		default:
			break;
		}
	}

	componentDidMount() {
		const props = this.props;
		props.setLoginRedirectDeepLink(`appraisals/${this.props.match.params.appraisalToken}`);

		const { history } = this.props;

		if (!this.props.access_token) {
			return history.push('/');
		}

		Promise.all([props.getUserCheck]).then(() => {
			props.getEstimateUserById(this.state.current_user, this.state.subdomain);
		}).then(() => {
			this.setLink();
		}).then(() => {
			this.setState({ link_checked: true });
		});
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClick(e) {
		e.preventDefault();
		const counter_estimate = this.state.counter_estimate;
		const declined_reason = this.state.declined_reason;
		const estimate_id = this.props.estimate[0].id;
		const vehicle_id = this.props.estimate[0].vehicle_id;
		const data = {
			counter_estimate,
			declined_reason,
		};
		this.props.patchEstimatesById(estimate_id, this.state.subdomain, data, vehicle_id);
		this.setState({ estimate_updated: true });
	}

	render() {
		const { errors } = this.state;
		const approved = (
			<div className="container">
				{this.props.estimate.map(estimate => <div className="card">
					<h1 className="text-success">
Status:
						{estimate.ro_status}
					</h1>
					<ul style={{ listStyle: 'none' }}>
						<li className="text-dark">
Order#:
							{estimate.ro_number}
						</li>
						<li className="text-dark">
Description:
							{estimate.description}
						</li>
						<li className="text-dark">
Amount:
							{estimate.estimate}
						</li>
					</ul>

				</div>)}
			</div>
		);

		const declined = (
			<div className="row">
				{approved}
				<div className="container">
					<form onSubmit={this.onSubmit}>
						<TextFieldGroup
							field="counter_estimate"
							name="counter_estimate"
							label="Enter New Amount"
							onChange={this.onChange}
							value={this.state.password}
							type="number"
						/>
						<br />
						<TextBox
							field="declined_reason"
							name="declined_reason"
							label="Enter Declined Reason/Notes"
							onChange={this.onChange}
							value={this.state.password}
							type="text"
						/>
						<br />

						<div className="form-group pad-top-1">
							<button
								disabled={this.state.isLoading}
								type="submit"
								onClick={this.onClick}
								className="btn btn-primary"
							>
		                        Send Adjusted Amount
							</button>

						</div>
					</form>
				</div>
			</div>
		);

		let body = this.state.link_type === 'approved' ? approved : declined;
		if (this.props.estimate.length === 0 && this.state.link_checked) {
			body = <p className="text-danger text-center">This link has expired or has already been used.</p>;
		}

		if (this.state.estimate_updated === true) {
			body = <p className="text-success text-center">Estimate has been updated.</p>;
		}

		return (
			[body]
		);
	}
}

const mappedActions = {
	getEstimatesByApprovedLink,
	getEstimateUserById,
	getEstimatesByDeclinedLink,
	getDateTime,
	getUserCheck,
};

function mapStateToProps(state) {
	return {
		estimate: state.settings_refresh.single_estimate,
		user: state.settings_refresh.single_estimate_user,
		access_token: state.authentication.access_token
	};
}
export default withRouter(connect(mapStateToProps, mappedActions)(AppraisalForm));
