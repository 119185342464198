import React, { Component } from 'react';
import { connect } from 'react-redux';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import AllInputs from '../../../../inputs/AllInputs';

class Inspection extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.onChange = this.onChange.bind(this);
		this.vehicleProperty = this.vehicleProperty.bind(this);
		this.onPrint = this.onPrint.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	vehicleProperty(name) {
		const vehicle = this.props.active_vehicle;
		const colObject = _.find(vehicle.vehicle_properties, { key: name }) || '';

		return colObject;
	}

	onPrint(e) {
		const { vehicle } = this.props;
		const print_page = document.getElementById('print-inspection');
		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 20, 5, 175, 290);
				    pdf.save('vehicle_inspection.pdf');
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	render() {
		const vehicle = this.props.active_vehicle;
		const rows = [];
		const leftAndRight = ['Tread Depth Front', 'Tread Depth Rear', 'Pad Life Front', 'Pad Life Rear'];

		let topRows = ['custom_inspection1', 'custom_inspection2', 'custom_inspection3', 'custom_inspection4'];

		let firstRows = ['custom_inspection1', 'custom_inspection2', 'custom_inspection3', 'custom_inspection4', 'Tread Depth Front', 'Tread Depth Rear', 'Pad Life Front', 'Pad Life Rear', 'Rotors', 'Suspension', 'Axle/Bools/U Joints', 'Steering Components',
			'Shocks/Strusts', 'Oil Leaks', 'Tranny Leaks/Flush', 'Exaust',
			'Power Steering', 'Filters', 'Chassis Damage', "Owner's Manual",
			'Lube Doors/Hinges', 'Jack Assembly', 'Spare Tire', 'Engine Operation', 'Cruise', 'Alignment', 'Steering Wheel Center',
			'Interior Lights', 'Power Accessories', 'A/C & Heater', 'Seat Belts', 'Keys/Fobs'];

		let fields = ['Pass', 'Recommend', 'Required', 'Notes', 'Labor Hours', 'Parts', 'Total', 'Completed'];

		if (this.props.current_dealership === 'eautomotive1') {
			topRows = [];
			firstRows = ['Scan for Codes', 'Warning Lights', 'Horn', 'Wipers/Sprayers', 'Interior/Exterior Lights', 'Belts', 'Tread Depth Front', 'Tread Depth Rear', 'Matching Tire Sizes', 'Pad Life Front', 'Pad Life Rear', 'Suspension Components', 'Axle/Bools/U Joints', 'Steering Components',
			'Shocks/Strusts', 'Oil Leaks', 'Tranny Leaks/Flush', 'Exaust',
			'Power Steering', 'Filters', 'Carriage Rust', "Owner's Manual",
			'Lube Doors/Hinges', 'Jack Assembly', 'Spare Tire', 'Engine Operation', 'Cruise', 'Alignment', 'Steering Wheel Center',
			'Interior Lights', 'Power Accessories', 'A/C & Heater', 'Seat Belts', 'Keys/Fobs', 'custom_inspection1', 'custom_inspection2', 'custom_inspection3', 'custom_inspection4'];
			fields = ['Pass', 'Required', 'Notes', 'Labor Hours', 'Parts', 'Total', 'Completed'];
		}

		firstRows.map((row) => {
			const pass = `${row}_Pass`;
			const recommend = `${row}_Recommend`;
			const required_name = `${row}_Required`;
			const notes = `${row}_Notes`;
			const labor_hours = `${row}_Labor Hours`;
			const parts = `${row}_Parts`;
			const total = `${row}_Total`;
			const completed = `${row}_Completed`;
			const setdisabled = false;

			const custom_1 = _.find(vehicle.vehicle_properties, { key: 'custom_inspection1' }) || '';
			const custom_2 = _.find(vehicle.vehicle_properties, { key: 'custom_inspection2' }) || '';
			const custom_3 = _.find(vehicle.vehicle_properties, { key: 'custom_inspection3' }) || '';
			const custom_4 = _.find(vehicle.vehicle_properties, { key: 'custom_inspection4' }) || '';

			const tread_depth_front_left = _.find(vehicle.vehicle_properties, { key: 'Tread Depth Front_left' }) || '';
			const tread_depth_front_right = _.find(vehicle.vehicle_properties, { key: 'Tread Depth Front_right' }) || '';
			const tread_depth_rear_left = _.find(vehicle.vehicle_properties, { key: 'Tread Depth Rear_left' }) || '';
			const tread_depth_rear_right = _.find(vehicle.vehicle_properties, { key: 'Tread Depth Rear_right' }) || '';

			const pad_life_front_left = _.find(vehicle.vehicle_properties, { key: 'Pad Life Front_left' }) || '';
			const pad_life_front_right = _.find(vehicle.vehicle_properties, { key: 'Pad Life Front_right' }) || '';
			const pad_life_rear_left = _.find(vehicle.vehicle_properties, { key: 'Pad Life Rear_left' }) || '';
			const pad_life_rear_right = _.find(vehicle.vehicle_properties, { key: 'Pad Life Rear_right' }) || '';

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const recommend_colObject = _.find(vehicle.vehicle_properties, { key: recommend }) || '';
			const required_name_colObject = _.find(vehicle.vehicle_properties, { key: required_name }) || '';
			const notes_colObject = _.find(vehicle.vehicle_properties, { key: notes }) || '';
			const labor_hours_colObject = _.find(vehicle.vehicle_properties, { key: labor_hours }) || '';
			const parts_colObject = _.find(vehicle.vehicle_properties, { key: parts }) || '';
			const total_colObject = _.find(vehicle.vehicle_properties, { key: total }) || '';
			const completed_colObject = _.find(vehicle.vehicle_properties, { key: completed }) || '';

			const label = row;
			const firstrow = [];

			if (row.includes('custom')) {
				if (row === 'custom_inspection1') {
					firstrow.push(<AllInputs
						value={custom_1.value || ''}
						type={'text'}
						name={'custom_inspection1'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={custom_1.user_name}
						updated_at={custom_1.updated_at}
						vehicle={vehicle}
					/>);
				} else if (row === 'custom_inspection2') {
					firstrow.push(<AllInputs
						value={custom_2.value || ''}
						type={'text'}
						name={'custom_inspection2'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={custom_2.user_name}
						updated_at={custom_2.updated_at}
						vehicle={vehicle}
					/>);
				} else if (row === 'custom_inspection3') {
					firstrow.push(<AllInputs
						value={custom_3.value || ''}
						type={'text'}
						name={'custom_inspection3'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={custom_3.user_name}
						updated_at={custom_3.updated_at}
						vehicle={vehicle}
					/>);
				} else if (row === 'custom_inspection4') {
					firstrow.push(<AllInputs
						value={custom_4.value || ''}
						type={'text'}
						name={'custom_inspection4'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={custom_4.user_name}
						updated_at={custom_4.updated_at}
						vehicle={vehicle}
					/>);
				}
			}

			if (row.includes('Tread') || row.includes('Pad')) {
				if (row === 'Tread Depth Front') {
					firstrow.push(<div className="row">
						<div className="col-md-12">{row}</div>
						<div className="col-md-6">
							<AllInputs
								value={tread_depth_front_left.value || ''}
								type={'text'}
								name={'Tread Depth Front_left'}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								setdisabled={setdisabled}
								user_name={tread_depth_front_left.user_name}
								updated_at={tread_depth_front_left.updated_at}
								vehicle={vehicle}
							/>
						</div>
						<div className="col-md-6">
							<AllInputs
								value={tread_depth_front_right.value || ''}
								type={'text'}
								name={'Tread Depth Front_right'}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								setdisabled={setdisabled}
								user_name={tread_depth_front_right.user_name}
								updated_at={tread_depth_front_right.updated_at}
								vehicle={vehicle}
							/>
						</div>
                   </div>);
				} else if (row === 'Tread Depth Rear') {
					firstrow.push(<div className="row">
						<div className="col-md-12">{row}</div>
						<div className="col-md-6">
							<AllInputs
								value={tread_depth_rear_left.value || ''}
								type={'text'}
								name={'Tread Depth Rear_left'}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								setdisabled={setdisabled}
								user_name={tread_depth_rear_left.user_name}
								updated_at={tread_depth_rear_left.updated_at}
								vehicle={vehicle}
							/>
						</div>
						<div className="col-md-6">
							<AllInputs
								value={tread_depth_rear_right.value || ''}
								type={'text'}
								name={'Tread Depth Rear_right'}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								setdisabled={setdisabled}
								user_name={tread_depth_rear_right.user_name}
								updated_at={tread_depth_rear_right.updated_at}
								vehicle={vehicle}
							/>
						</div>
                   </div>);
				} else if (row === 'Pad Life Front') {
					firstrow.push(<div className="row">
						<div className="col-md-12">{row}</div>
						<div className="col-md-6">
							<AllInputs
								value={pad_life_front_left.value || ''}
								type={'text'}
								name={'Pad Life Front_left'}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								setdisabled={setdisabled}
								user_name={pad_life_front_left.user_name}
								updated_at={pad_life_front_left.updated_at}
								vehicle={vehicle}
							/>
						</div>
						<div className="col-md-6">
							<AllInputs
								value={pad_life_front_right.value || ''}
								type={'text'}
								name={'Pad Life Front_right'}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								setdisabled={setdisabled}
								user_name={pad_life_front_right.user_name}
								updated_at={pad_life_front_right.updated_at}
								vehicle={vehicle}
							/>
						</div>
                   </div>);
				} else if (row === 'Pad Life Rear') {
					firstrow.push(<div className="row">
						<div className="col-md-12">{row}</div>
						<div className="col-md-6">
							<AllInputs
								value={pad_life_rear_left.value || ''}
								type={'text'}
								name={'Pad Life Rear_left'}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								setdisabled={setdisabled}
								user_name={pad_life_rear_left.user_name}
								updated_at={pad_life_rear_left.updated_at}
								vehicle={vehicle}
							/>
						</div>
						<div className="col-md-6">
							<AllInputs
								value={pad_life_rear_right.value || ''}
								type={'text'}
								name={'Pad Life Rear_right'}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								setdisabled={setdisabled}
								user_name={pad_life_rear_right.user_name}
								updated_at={pad_life_rear_right.updated_at}
								vehicle={vehicle}
							/>
						</div>
                   </div>);
				}
			}

			rows.push(<tr>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<AllInputs
						value={pass_colObject.value || ''}
						type={'userstamp'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td className={recommend_colObject.value === 'true' ? 'bg-yellow text-center' : 'text-center'}>
					<AllInputs
						value={recommend_colObject.value || ''}
						type={'userstamp'}
						name={recommend}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={recommend_colObject.user_name}
						updated_at={recommend_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td className={required_name_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<AllInputs
						value={required_name_colObject.value || ''}
						type={'userstamp'}
						name={required_name}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={required_name_colObject.user_name}
						updated_at={required_name_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td>
					<AllInputs
						value={notes_colObject.value || ''}
						type={'textarea'}
						name={notes}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={notes_colObject.user_name}
						updated_at={notes_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td>
					<AllInputs
						value={labor_hours_colObject.value || ''}
						type={'text'}
						name={labor_hours}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={labor_hours_colObject.user_name}
						updated_at={labor_hours_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td>
					<AllInputs
						value={parts_colObject.value || ''}
						type={'text'}
						name={parts}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={parts_colObject.user_name}
						updated_at={parts_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td>
					<AllInputs
						value={total_colObject.value || ''}
						type={'text'}
						name={total}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={total_colObject.user_name}
						updated_at={total_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td className={completed_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<AllInputs
						value={completed_colObject.value || ''}
						type={'userstamp'}
						name={completed}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={completed_colObject.user_name}
						updated_at={completed_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
			</tr>);
		});

		return (
			<>
				<button onClick={this.onPrint} className="btn btn-success float-left">Print</button>

				<div className="container-fluid" id="print-inspection">
					<div className="row">
						<div className="col-xs-12 column no-more-tables ui-grid-a">
							<h3>
								<small className="float-left space-left-2 pad-left-2">
									{vehicle.year}
									{' '}
									{vehicle.make}
									{' '}
									{vehicle.model}
									{' '}
	(
									{vehicle.stock}
	)
								</small>
							</h3>
						<table className="col-xs-12 table-bordered table-striped inspection-table" style={{ width: '94%', margin: 'auto' }}>
								<thead className="thead-light">
									<tr>
										<th className="text-center col-xs-2" style={{ width: '200px' }} />
										<th className="text-center col-xs-1" style={{ width: '90px' }}>
									Pass
										</th>
										<th className="text-center col-xs-1" style={{ width: '90px' }}>
									Recommend
										</th>
										<th className="text-center col-xs-1" style={{ width: '90px' }}>
									Required
										</th>
										<th className="text-center col-xs-2" style={{ width: '180px' }}>
									Notes
										</th>
										<th className="text-center col-xs-1" style={{ width: '45px' }}>
									Labor Hours
										</th>
										<th className="text-center col-xs-1" style={{ width: '45px' }}>
									Parts
										</th>
										<th className="text-center col-xs-1" style={{ width: '45px' }}>
									Total
										</th>
										<th className="text-center col-xs-1" style={{ width: '90px' }}>
									Completed
										</th>
									</tr>
								</thead>
								<tbody className="detail_slip_form">

								{rows}
								</tbody>
						</table>
						</div>
					</div>

				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps)(Inspection);
