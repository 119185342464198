import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import VehicleHistorySummarySingle from './VehicleHistorySummarySingle';
import VehicleDataHistorySummarySingle from './VehicleDataHistorySummarySingle';
import Chart from '../ChartSingleVehiclePie';
import {
	returnAgeSeconds,
	getSecondsFromTimeRange,
	getSecondsFromTimeRange_24_5,
	getSecondsFromTimeRange_24_6,
	convertSecondsToTime,
	readableStamp,
} from '../../../../utils/dateFunctions';
import {
	lookupDepartmentByName,
} from '../../../../actions/lookups';
import { setTimeRange } from '../../../../actions/apiSetInfo';

class VehicleHistorySummary extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
		this.onChange = this.onChange.bind(this);
		this.recon_time = this.recon_time.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	department(id) {
		const self = this;
		const department = _.sortBy(_.filter(self.props.all_departments, o => o.id == id), 'id');
		return department[0] || [];
	}

	onClick(e) {
		this.props.setTimeRange(e.target.dataset.id);
	}

	recon_time() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const departmentHistories = this.props.active_vehicle.completed_departments;
		const data = [];
		const steps = [];
		let goal = '#2C55FF';
		let hours = 0;
		let totaltime = 0;
		let current_time = 0;

		const end = new Date();
		const predata = [];

		departmentHistories.forEach((o) => {
			steps[o.department_id] = {
				name: this.department(o.department_id).name, department_id: o.department_id, current_time: 0, goal: this.department(o.department_id).goal,
			};
		});

		departmentHistories.forEach((o) => {
			hours = 0;
			totaltime = 0;
			current_time = 0;
			if (this.props.time_range === '24-5') {
				totaltime = o.recon_total_24_5;
			} else if (this.props.time_range === '24-6') {
				totaltime = o.recon_total_24_6;
			} else if (this.props.time_range === '24-7') {
				totaltime = o.recon_total_24_7;
			}

			hours = (totaltime) / (60 * 60);
			current_time = (totaltime) / (60 * 60 * 24);

			steps[o.department_id].current_time = current_time + steps[o.department_id].current_time;
		});

		steps.forEach((o) => {
			goal = steps[o.department_id].goal > (steps[o.department_id].current_time / 24) ? '#2C55FF' : '#b71c1c';
			data.push({ name: steps[o.department_id].name, y: steps[o.department_id].current_time, color: goal });
		});

		return data;
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	render() {
		const vehicle = this.props.active_vehicle;
		const end = new Date();
		const sold_id = this.props.lookupDepartmentByName('sold');
		const completed_departments = _.sortBy(vehicle.completed_departments, 'id').reverse();

		var current_department_user = vehicle.current_user;

		if (sold_id.id == vehicle.department_id) {
			current_department_user = 0;
		}

		const current_time = {
			recon_starttime: vehicle.department_time,
			recon_total_24_7: getSecondsFromTimeRange(vehicle.department_time, end),
			recon_total_24_6: getSecondsFromTimeRange_24_6(vehicle.department_time, end),
			recon_total_24_5: getSecondsFromTimeRange_24_5(vehicle.department_time, end),
			recon_total: getSecondsFromTimeRange(vehicle.department_time, end),
			department_id: vehicle.department_id,
			recon_entered_by: current_department_user,
		};
		let toggletime = current_time.recon_total;

		const second_time_tab = this.dealerSettingValue('second_time_tab');
		switch (second_time_tab) {
		case 'age-24_7':
			toggletime = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		case 'age-24_6':
			toggletime = current_time.recon_total_24_6;
			break;
		case 'age-24_5':
			toggletime = current_time.recon_total_24_5;
			break;
		default:
			toggletime = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		}

		const department_id = vehicle.department_id;

		vehicle.completed_departments.map((completed_department) => {
			if (completed_department.department_id === department_id) {
				switch (second_time_tab) {
				case 'age-24_7':
					toggletime += completed_department.recon_total_24_7;
					break;
				case 'age-24_6':
					toggletime += completed_department.recon_total_24_6;
					break;
				case 'age-24_5':
					toggletime += completed_department.recon_total_24_5;
					break;
				default:
					toggletime += completed_department.recon_total_24_7;
					break;
				}
			}
		});

		return (
			<div className={'row'}>
				<div className="col-xl-6">

					<div className="col-md-12">
						<div className="card card-summary">
							<div className="card-header">
								{vehicle.year} {vehicle.make} {vehicle.model}
								<span className="badge badge-primary">Stock:</span>
								{' '}
								{vehicle.stock}
								{' '}
								<span className="badge badge-primary">Vin:</span>
								{' '}
								{vehicle.vin}
							</div>
							<div className="card-body">
								<table className="table table-striped">
									<tbody>
										<tr>
											<th>Inventory Date:</th>
											<td>{this.props.active_vehicle.inventory_date ? readableStamp(this.props.active_vehicle.inventory_date) : readableStamp(this.props.active_vehicle.created_at)}</td>
										</tr>
										<tr>
											<th>Entered RA:</th>
											<td>{readableStamp(this.props.active_vehicle.created_at)}</td>
										</tr>
										<tr>
											<th>WorkFlow Profile:</th>
											<td>{vehicle.stocktype}</td>
										</tr>
										<tr>
											<th>Current Step:</th>
											<td>{this.department(department_id).name}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="card card-summary">
							<div className="card-header">
Recon Breakdown
							</div>
							<div className="card-body">
								<Chart data={this.recon_time()} container="chart" />
							</div>
						</div>
					</div>

				</div>
				<div className="col-lg-6">
					<div className="col-md-12">
						<div className="card card-summary">
							<div className="card-header text-center">
Step History
							</div>
							<div className="card-body">
								<ul className="timeline">
									<VehicleHistorySummarySingle key={`timeline_${current_time.department_id}`} department_history={current_time} current time_range={this.props.time_range} />
									{completed_departments.map((department_history) => {
										if (department_history.department_id !== sold_id.id) {
											return <VehicleHistorySummarySingle key={`timeline_${department_history.id}`} department_history={department_history} time_range={this.props.time_range} />;
										}
									})}

								</ul>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="card card-summary">
							<div className="card-header text-center">
Data Steps Completed (
								{this.props.time_range}
)
							</div>
							<div className="card-body">
								<ul className="timeline">
									{vehicle.completed_data_steps.map((department_history) => {
										if (department_history.department_id !== sold_id) {
											return <VehicleDataHistorySummarySingle key={`timeline_${department_history.id}`} department_history={department_history} />;
										}
									})}

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupDepartmentByName,
	setTimeRange,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		all_departments: state.settings_departments.original_departments,
		time_range: state.settings_reports.time_range,
		active_vehicle: state.settings_refresh.active_vehicle,
		dealer_settings: state.settings_site.dealer_settings,
	};
}

export default connect(mapStateToProps, mappedActions)(VehicleHistorySummary);
