import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AllInputs from '../../../../inputs/AllInputs';
import DollarInput from '../../../../inputs/DollarInput';
import TextFieldGroup from '../../../../inputs/TextFieldGroupNoLabel';
import Select from '../../../../inputs/Dropdown';
import {
	addVehicleProperty,
	updateVehicleDepartment,
} from '../../../../actions/AllUpdates';
import {
	getCurrentDepartment,
} from '../../../../actions/apiGetInfo';
import DropBoxEstimate from '../../../../inputs/FileUploadButton/DropBoxEstimate';
import {
	getDateTime,
	readableStamp,
} from '../../../../utils/dateFunctions';
import {
	deleteEstimate,
	sendEstimate,
} from '../../../../actions/apiEstimates';
import {
	appraisalRequestNotification,
	appraisalNotification,
} from '../../../../actions/apiNotifications';
import {
	lookupUserById,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	vsearch,
} from '../../../../actions/lookups';
import FileAttachmentSingle from './FileAttachmentSingle';

let estimateFields = [{ name: 'recipients', label: 'Recipients', type: 'select-transport' }];

const classicFields = [{ name: 'ro_number', label: 'Ro Number', type: 'text' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'estimate', label: 'Amount', type: 'dollar' }];

const freddyFields = [{ name: 'ro_number', label: 'Ro Number', type: 'text' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'item_1', label: 'Parts', type: 'number' },
	{ name: 'item_2', label: 'Labor', type: 'number' },
	{ name: 'item_3', label: 'Hours', type: 'number' },
	{ name: 'estimate', label: 'Total', type: 'dollar' }];

const stanleyFields = [{ name: 'ro_number', label: 'ROs', type: 'text' },
	{ name: 'type_item_1', label: 'Created', type: 'date' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'estimate', label: 'Est Amount', type: 'dollar' }];

const allFields = ['vehicle_id', 'ro_number', 'requested_by_uid', 'description', 'estimate',
	'actual', 'ro_status', 'approved_by_uid', 'approved_date', 'declined_by_uid', 'declined_date', 'ro_files',
	'declined_reason', 'ro_email_link_declined', 'ro_email_link_approved'];

const classicArray = [15143, 16596];

class TransportSingle extends Component {
	constructor(props) {
		super(props);
		const estimate = this.props.estimate;
		this.state = {
			addDeleteEstimateError: '',
			denied_reason: false,
			vehicle_id: this.props.active_vehicle.id,
			ro_number: estimate.ro_number,
			requested_by_uid: estimate.requested_by_uid,
			requested_date: estimate.requested_date,
			description: estimate.description,
			estimate: estimate.estimate,
			actual: estimate.actual,
			ro_status: estimate.ro_status,
			approved_by_uid: estimate.approved_by_uid,
			approved_date: estimate.approved_date,
			declined_by_uid: estimate.declined_by_uid,
			declined_date: estimate.declined_date,
			ro_files: estimate.ro_files,
			declined_reason: estimate.declined_reason,
			ro_email_link_declined: estimate.ro_email_link_declined,
			ro_email_link_approved: estimate.ro_email_link_approved,
		};
		this.onChange = this.onChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSend = this.onSend.bind(this);
		this.onApprove = this.onApprove.bind(this);
		this.onDeny = this.onDeny.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	componentDidMount() {
		const estimate = this.props.estimate;

		this.setState({
			addDeleteEstimateError: '',
			denied_reason: false,
			vehicle_id: this.props.active_vehicle.id,
			ro_number: estimate.ro_number,
			requested_by_uid: estimate.requested_by_uid,
			requested_date: estimate.requested_date,
			description: estimate.description,
			estimate: estimate.estimate,
			actual: estimate.actual,
			ro_status: estimate.ro_status,
			approved_by_uid: estimate.approved_by_uid,
			approved_date: estimate.approved_date,
			declined_by_uid: estimate.declined_by_uid,
			declined_date: estimate.declined_date,
			ro_files: estimate.ro_files,
			declined_reason: estimate.declined_reason,
			ro_email_link_declined: estimate.ro_email_link_declined,
			ro_email_link_approved: estimate.ro_email_link_approved,
		});
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onDelete(e) {
	}

	onClick(e) {
	}

	onSend(e) {

	}

	onApprove(e) {

	}

	onDeny(e) {

	}

	render() {
		self = this;
		var setdisabled = true;
		const html = [];
		var filerow = [];
		var estimate = this.props.estimate;
		var vehicle = this.props.active_vehicle;

		if (estimate.ro_status === 'pending') {
			setdisabled = false;
		}

		if (estimate.ro_status === 'approved') {
			setdisabled = true;
		}

		if (estimate.ro_status == 'getapproved') {
			setdisabled = false;
		}

		if (this.props.current_dealership === 'fredychevy') {
			estimateFields = freddyFields;
		}

		filerow.push(<div key={`approved-col_${estimate.id}`} className="col-md-6">
					<label>Add Files</label>
					<DropBoxEstimate vid={vehicle.id} siteid={`${this.props.current_dealership}_${vehicle.id}`} estimate_id={estimate.id} />
				</div>);

			filerow.push(<ul className="list-group col-md-6">
									{vehicle.attachments.map(attachment => {
										if (attachment.appraisal_id === estimate.id) {
											return <FileAttachmentSingle key={`attach_${attachment.id}`} attachment={attachment} vehicle_id={vehicle.id} data_id={attachment.id} />;
										}
										})}
								</ul>);

		if (this.props.current_dealership === 'fredychevy') {
			filerow = [];
		}

		return (
			<>
			{(() => {
							switch (estimate.ro_status) {
							case 'pending':
								return (
									<div className="p-2">
										<p className="text-center">
											<small className="text-capitalize" key={`requested_sign_${estimate.id}`}>
											Requested By:
												{' '}
												{estimate.requested_by_uid ? this.props.lookupUserById(estimate.requested_by_uid).user_name : ''}
												<br />
												{estimate.requested_date ? readableStamp(estimate.requested_date) : ''}
											</small>
										</p>
										<div className={'btn-group'} role="group">
											<button className="btn btn-sm btn-success" onClick={self.onApprove}>Approve</button>
											<button className="btn btn-sm btn-danger" onClick={self.onDeny}>Deny</button>
										</div>
									</div>
								);

							case 'approved':
								return (
									<button className="btn btn-sm btn-success text-capitalize">
									Approved
										{' '}
										{estimate.approved_by_uid ? this.props.lookupUserById(estimate.approved_by_uid).user_name : ''}
										{estimate.approved_date ? readableStamp(estimate.approved_date) : ''}
									</button>
								);

							default:
								return <button className="btn btn-sm btn-success" onClick={self.onSend}>Send Transporter Alert</button>;
							}
						})()}
						<div className="row">
				{filerow}
				</div>
		</>
		);
	}
}

const mappedActions = {
	lookupUserById,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	deleteEstimate,
	sendEstimate,
	appraisalRequestNotification,
	updateVehicleDepartment,
	appraisalNotification,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		all_users: state.settings_users.all_users,
		current_dealership: state.settings_dealerships.current_dealership,
		installation: state.settings_dealerships.installation,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(TransportSingle);
