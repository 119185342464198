import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import AllInputs from '../../../../inputs/AllInputs';

import {
	addVehicleProperty,
} from '../../../../actions/AllUpdates';

import {
	addVehicleNoteRaw,
} from '../../../../actions/apiVehicleNotes';
import {
	vsearch,
	lookupIsAdmin,
} from '../../../../actions/lookups';
import {
	userStamp,
} from '../../../../utils/dateFunctions';
import FileUploadButtonSingle from '../../../../inputs/FileUploadButton/FileUploadButtonSingle';

class BodyWork extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { bodywork_status: '' };
		this.onChange = this.onChange.bind(this);
		this.vehicleProperty = this.vehicleProperty.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onUnLock = this.onUnLock.bind(this);
		this.onPrint = this.onPrint.bind(this);
	}

	onPrint(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById(`body_shop_${vehicle.stock}`).innerHTML;
        var a = window.open('', '', '');
        a.document.write('<html><body>');
        a.document.write(print_page);
        a.document.write('</body></html>');
        a.document.close();

        setTimeout(a.print(), 6000);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	vehicleProperty(name) {
		const vehicle = this.props.active_vehicle;
		const colObject = _.find(vehicle.vehicle_properties, { key: name }) || '';

		return colObject;
	}

	componentDidMount() {
		const vehicle = this.props.active_vehicle;

		const bodywork_status = this.vehicleProperty('bodywork status');

		this.setState({
			bodywork_status,
		});
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			key: 'bodywork status',
			value: 'completed',
		};

		this.props.addVehicleProperty(data, 'addVehicleProperty');
		var data2 = {
			vehicle_id: this.props.active_vehicle.id,
			value: 'BodyWork Form submitted',
			uid: this.props.current_user.user,
		};
		this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError');
		this.setState({
			bodywork_status: 'completed',
		});
	}

	onUnLock(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			key: 'bodywork status',
			value: 'pending',
		};

		this.props.addVehicleProperty(data, 'addVehicleProperty');
		var data2 = {
			vehicle_id: this.props.active_vehicle.id,
			value: 'BodyWork Form UnLocked',
			uid: this.props.current_user.user,
		};
		this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError');
		this.setState({
			bodywork_status: 'pending',
		});
	}

	getChecks(diagram_array, min, max) {
		const checks = [];
		let i = min;
		do {
			i += 1;
			const name = `car_diagram_${i}`;
			const vehCol = `${name}`;

			const name2 = `car_diagram_checked_${i}`;
			const vehCol2 = `${name2}`;

			checks.push(<div className="input-group" key={`bw_${i}`}>
				<div className="input-group-prepend">
					{i}
.
				</div>

				<AllInputs
					name={`car_diagram_${i}`}
					label={`car_diagram_${i}`}
					value={diagram_array[vehCol].value}
					type={'text'}
					error=""
					options={''}
					updatetype="vehicleproperty"
					vehicle={this.props.active_vehicle}
					parentid={this.props.active_vehicle.id}
					uid={diagram_array[vehCol].uid}
					user_name={diagram_array[vehCol].user_name}
					updated_at={diagram_array[vehCol].updated_at}
					icon={''}
					setdisabled={this.state.bodywork_status === 'completed'}

				/>
				<div className="input-group-append">
					<AllInputs
						name={`car_diagram_checked_${i}`}
						label={`car_diagram_checked_${i}`}
						value={diagram_array[vehCol2].value}
						type={'userstamp'}
						error=""
						options={''}
						updatetype="vehicleproperty"
						vehicle={this.props.active_vehicle}
						parentid={this.props.active_vehicle.id}
						uid={diagram_array[vehCol2].uid}
						user_name={diagram_array[vehCol2].user_name}
						updated_at={diagram_array[vehCol2].updated_at}
						icon={''}
						setdisabled={this.state.bodywork_status === 'completed'}

					/><p className="show-print" style={{ display: 'none' }}>{diagram_array[vehCol2].value}</p>
				</div>
			</div>);
		} while (i < max);
		return checks;
	}

	render() {
		const diagram_array = [];
		const vehicle = this.props.active_vehicle;
		diagram_array.car_diagram_1 = this.vehicleProperty('car_diagram_1');
		diagram_array.car_diagram_2 = this.vehicleProperty('car_diagram_2');
		diagram_array.car_diagram_3 = this.vehicleProperty('car_diagram_3');
		diagram_array.car_diagram_4 = this.vehicleProperty('car_diagram_4');
		diagram_array.car_diagram_5 = this.vehicleProperty('car_diagram_5');
		diagram_array.car_diagram_6 = this.vehicleProperty('car_diagram_6');
		diagram_array.car_diagram_7 = this.vehicleProperty('car_diagram_7');
		diagram_array.car_diagram_8 = this.vehicleProperty('car_diagram_8');
		diagram_array.car_diagram_9 = this.vehicleProperty('car_diagram_9');
		diagram_array.car_diagram_10 = this.vehicleProperty('car_diagram_10');
		diagram_array.car_diagram_11 = this.vehicleProperty('car_diagram_11');
		diagram_array.car_diagram_12 = this.vehicleProperty('car_diagram_12');
		diagram_array.car_diagram_13 = this.vehicleProperty('car_diagram_13');
		diagram_array.car_diagram_14 = this.vehicleProperty('car_diagram_14');
		diagram_array.car_diagram_15 = this.vehicleProperty('car_diagram_15');
		diagram_array.car_diagram_16 = this.vehicleProperty('car_diagram_16');
		diagram_array.car_diagram_17 = this.vehicleProperty('car_diagram_17');
		diagram_array.car_diagram_18 = this.vehicleProperty('car_diagram_18');
		diagram_array.car_diagram_19 = this.vehicleProperty('car_diagram_19');
		diagram_array.car_diagram_20 = this.vehicleProperty('car_diagram_20');
		diagram_array.car_diagram_21 = this.vehicleProperty('car_diagram_21');
		diagram_array.car_diagram_22 = this.vehicleProperty('car_diagram_22');

		diagram_array.car_diagram_checked_1 = this.vehicleProperty('car_diagram_checked_1');
		diagram_array.car_diagram_checked_2 = this.vehicleProperty('car_diagram_checked_2');
		diagram_array.car_diagram_checked_3 = this.vehicleProperty('car_diagram_checked_3');
		diagram_array.car_diagram_checked_4 = this.vehicleProperty('car_diagram_checked_4');
		diagram_array.car_diagram_checked_5 = this.vehicleProperty('car_diagram_checked_5');
		diagram_array.car_diagram_checked_6 = this.vehicleProperty('car_diagram_checked_6');
		diagram_array.car_diagram_checked_7 = this.vehicleProperty('car_diagram_checked_7');
		diagram_array.car_diagram_checked_8 = this.vehicleProperty('car_diagram_checked_8');
		diagram_array.car_diagram_checked_9 = this.vehicleProperty('car_diagram_checked_9');
		diagram_array.car_diagram_checked_10 = this.vehicleProperty('car_diagram_checked_10');
		diagram_array.car_diagram_checked_11 = this.vehicleProperty('car_diagram_checked_11');
		diagram_array.car_diagram_checked_12 = this.vehicleProperty('car_diagram_checked_12');
		diagram_array.car_diagram_checked_13 = this.vehicleProperty('car_diagram_checked_13');
		diagram_array.car_diagram_checked_14 = this.vehicleProperty('car_diagram_checked_14');
		diagram_array.car_diagram_checked_15 = this.vehicleProperty('car_diagram_checked_15');
		diagram_array.car_diagram_checked_16 = this.vehicleProperty('car_diagram_checked_16');
		diagram_array.car_diagram_checked_17 = this.vehicleProperty('car_diagram_checked_17');
		diagram_array.car_diagram_checked_18 = this.vehicleProperty('car_diagram_checked_18');
		diagram_array.car_diagram_checked_19 = this.vehicleProperty('car_diagram_checked_19');
		diagram_array.car_diagram_checked_20 = this.vehicleProperty('car_diagram_checked_20');
		diagram_array.car_diagram_checked_21 = this.vehicleProperty('car_diagram_checked_21');
		diagram_array.car_diagram_checked_22 = this.vehicleProperty('car_diagram_checked_22');

		const bodywork_status = _.find(vehicle.vehicle_properties, { key: 'bodywork status' }) || [];

		let button = <button className="btn btn-success hider" onClick={this.onClick}>Submit and Lock Form</button>;
		let userstamp = '';
		let setdisabled = false;
		if (this.state.bodywork_status === 'completed') {
			setdisabled = true;
			userstamp = `Approved By ${bodywork_status.user_name} - ${userStamp(bodywork_status.updated_at)}`;
			button = <button className="btn btn-danger hider">Locked</button>;
			if (this.props.lookupIsAdmin()) {
				button = <button className="btn btn-danger" onClick={this.onUnLock}>UnLock</button>;
			}
		} else {
			setdisabled = false;
			button = <button className="btn btn-success hider" onClick={this.onClick}>Submit and Lock Form</button>;
		}

		const checks_1 = this.getChecks(diagram_array, 0, 5);
		const checks_2 = this.getChecks(diagram_array, 5, 10);
		const checks_3 = this.getChecks(diagram_array, 10, 16);
		const checks_4 = this.getChecks(diagram_array, 16, 22);

		return (
			<div className="container">
				<button onClick={this.onPrint} className="btn btn-success float-right">Print</button>
				{' '}
				<br />
				<div className="" id={`body_shop_${vehicle.stock}`} style={{ width: '800px', display: 'block;' }}>
				<div className="" style={{ width: '900px', display: 'block;' }}>
					<div style={{ width: '450px', display: 'inline;', float: 'left' }}>
						<div className="card card-default">
							<div className="card-heading" />
							<div className="card-body drop-container">
								<div className="row">
									<div className="col-md-12">
										<img src={'https://cdn.ipacketrecon.com/images/car-diagram-front.png'} className={'img-thumbnail diagram'} style={{ maxWidth: '400px' }} />
									</div>
									<div className="col-md-12">
										{checks_1}
									</div>
								</div>
							</div>

						</div>
					</div>
					<div style={{ width: '450px', display: 'contents;', float: 'left' }}>
						<div className="card card-default">
							<div className="card-heading" />
							<div className="card-body drop-container">
								<div className="row">
									<div className="col-md-12">
										<img src={'https://cdn.ipacketrecon.com/images/car-diagram-back.png'} className={'img-thumbnail diagram'} style={{ maxWidth: '400px' }} />
									</div>
									<div className="col-md-12">
										{checks_2}
									</div>
								</div>
							</div>

						</div>
					</div>

					<div style={{
 width: '450px', display: 'contents;', float: 'left', clear: 'both',
}}
					>
						<div className="card card-default">
							<div className="card-heading" />
							<div className="card-body drop-container">
								<div className="row">
									<div className="col-md-12">
										<img src={'https://cdn.ipacketrecon.com/images/car-diagram-drivers.png'} className={'img-thumbnail diagram'} style={{ maxWidth: '400px' }} />
									</div>
									<div className="col-md-12">
										{checks_3}
									</div>
								</div>
							</div>

						</div>
					</div>
					<div style={{ width: '450px', display: 'contents;', float: 'left' }}>
						<div className="card card-default">
							<div className="card-heading" />
							<div className="card-body drop-container">
								<div className="row">
									<div className="col-md-12">
										<img src={'https://cdn.ipacketrecon.com/images/car-diagram-passenger.png?a=1'} className={'img-thumbnail diagram'} style={{ maxWidth: '400px' }} />
									</div>
									<div className="col-md-12">
										{checks_4}
									</div>
								</div>
							</div>

						</div>
					</div>
					</div>
				</div>
				<div className="clearfix">{button}</div>
			</div>
		);
	}
}

const mappedActions = {
	addVehicleProperty,
	addVehicleNoteRaw,
	lookupIsAdmin,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		current_user: state.current_user,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(BodyWork);
