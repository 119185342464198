import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AllInputs from '../../../../inputs/AllInputs';
import {
	lookupDepartmentByName,
} from '../../../../actions/lookups';
import {
	getPhotosByVid,
} from '../../../../actions/apiGetInfo';

class PicturesTab extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { active_photo: '' };
		this.onClick = this.onClick.bind(this);
		this.getPhotos = this.getPhotos.bind(this);
	}

	onClick(e) {
		this.setState({ active_photo: e.target.src });
	}

	getPhotos() {
		this.props.getPhotosByVid(this.props.active_vehicle.id);
	}

	vehicleColumns() {
		const department = this.props.lookupDepartmentByName('stage six - digital listings');
		if (_.isEmpty(department)) {
			return [];
		}

		const newObj = Object.keys(department.department_properties).map(key => department.department_properties[key]);

		return _.sortBy(newObj, 'order');
	}

	render() {
		const vehicle = this.props.active_vehicle;
		const vehicle_properties = this.props.vehicle_properties;
		const self = this;

		var setdisabled = false;
		if (this.props.current_user.position !== 'admin' && this.props.current_user.position !== 'super_admin') {
			var setdisabled = true;
		}

		return (
			<div className="container">
				<div className="row bg-light-grey pad-top-1" />
				<div className="row">
					{this.vehicleColumns().map((vehCol) => {
						const colObject = _.find(vehicle_properties, { key: vehCol.vehicle_column.name }) || '';

						return <div className="col-sm-2 text-center" key={`vc_${vehicle.id}_${vehCol.vehicle_column.name}`}>
							<label className="control-label">{vehCol.vehicle_column.name !== 'thumbnail' ? vehCol.vehicle_column.name : ''}</label>
							<AllInputs
								value={colObject.value}
								type={vehCol.vehicle_column.input_name}
								name={vehCol.vehicle_column.name}
								parentid={vehicle.id}
								updatetype="vehicleproperty"
								user_name={colObject.user_name}
								updated_at={colObject.updated_at}
								setdisabled={setdisabled}
								vehicle_id={vehicle.id}
								vehicle={vehicle}
							/>
						</div>;
					})}
				</div>
				<div className="row bg-light-grey pad-top-1" />
				<div className="row">

					<div className="col-md-6">

						<div className="row">
							{this.props.photos.map((photo, i) => <div className="col-md-3" key={`${this.props.active_vehicle.id}_pic_${i}`} onClick={this.onClick}><img src={photo} className="img-thumbnail" /></div>)}
						</div>
					</div>

					<div className="col-md-6">
						<div className="col-xs-12">
							<div className="item"><img src={this.state.active_photo ? this.state.active_photo : this.props.photos[0]} className="img-thumbnail" /></div>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

const mappedActions = {
	lookupDepartmentByName,
	getPhotosByVid,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		current_user: state.current_user,
		photos: state.settings_refresh.photos,
		active_vehicle: state.settings_refresh.active_vehicle,
		vehicle_properties: state.settings_refresh.active_vehicle.vehicle_properties,
	};
}
export default connect(mapStateToProps, mappedActions)(PicturesTab);
