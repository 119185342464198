import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
	noteStamp,
} from '../../../../utils/dateFunctions';
import {
	lookupUserById,
	lookupPermission,
} from '../../../../actions/lookups';
import {
	addVehicleNote,
} from '../../../../actions/apiVehicleNotes';
import DeleteVehicleNote from './DeleteVehicleNote';

class VehicleNoteContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { value: '' };
		this.addNote = this.addNote.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
	}

	notes() {
		return Object.keys(this.props.notes).map(key => this.props.notes[key]);
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onKeyPress(e) {
		if (e.key === 'Enter') {
			const data = {
				vehicle_id: this.props.vehicle.id,
				value: this.state.value,
				uid: this.props.current_user.user,
				department_id: this.props.vehicle.department_id,
			};
			this.setState({ value: '' });
			this.props.addVehicleNote(data, 'addVehicleNoteError');
		}
	}

	addNote(e) {
		if(this.state.value && this.state.value.length){
			const data = {
				vehicle_id: this.props.vehicle.id,
				value: this.state.value,
				uid: this.props.current_user.user,
				department_id: this.props.vehicle.department_id,
			};
			this.setState({ value: '' });
			this.props.addVehicleNote(data, 'addVehicleNoteError');
		}
	}

	render() {
		const { vehicle } = this.props;
		const vehicle_notes = _.sortBy(vehicle.vehicle_notes, 'id');
		var delete_notes = false;

		if (this.props.current_user.position == 'admin') {
			delete_notes = true;
		}
		if (this.props.current_user.position == 'super_admin') {
			delete_notes = true;
		}

		const delete_notes_dealership = this.dealerSettingValue('delete_notes');

		return (
			<div className="container vehicle-display-notes">
				<div className="input-group">
					<input type="text" className="form-control" placeholder="Add note..." onKeyPress={this.onKeyPress} onChange={this.onChange} value={this.state.value} />
					<button disabled={!(this.state.value && this.state.value.length)}className="btn btn-primary" onClick={this.addNote}><i className="fal fa-plus" /></button>
				</div>
				<div className="vehicle-display-notes-items">
					{

						vehicle_notes.reverse().map((note) => {
								const username = this.props.lookupUserById(note.uid);

								if (typeof username.user_name !== 'undefined') {
									var user = username.user_name.split('@');
								}

								var delete_button = <div className="row space-left-0 space-right-0">
										<div className="col-3 ReactNoteTime pad-right-0 pad-left-0">
											{user ? <>{user[0]}<br/></> : ''}
											{noteStamp(note.created_at)}
										</div>
										<div className="col-9">
											<small>{note.value}</small>
										</div>
									</div>;

								if (delete_notes_dealership && delete_notes && (note.uid == this.props.current_user.user)) {
									delete_button = <div className="row space-left-0 space-right-0">
										<div className="col-3 ReactNoteTime pad-right-0 pad-left-0">
											{user ? user[0] : ''}
											<br />
											{noteStamp(note.created_at)}
										</div>
										<div className="col-7">
											<small>{note.value}</small>
										</div>
										<div className="col-2 float-right">
											<DeleteVehicleNote note_id={note.id} />
										</div>
									</div>;
								}

								return <div className="card" key={`note_${note.id}`}>

									{ delete_button }

	              </div>;
						})
					}
				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupUserById,
	addVehicleNote,
	lookupPermission,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		dealer_settings: state.settings_site.dealer_settings,
	};
}
export default connect(mapStateToProps, mappedActions)(VehicleNoteContainer);
