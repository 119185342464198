import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';
import EditTabStyle from './EditTabStyle';
import EditTabProperties from '../../inputs/EditTabProperties';
import AddListItemButton from '../../inputs/AddListItemButton';
import { addNewVehicleColumnID, addNewVehicleColumn } from '../../actions/apiDepartmentUpdates';
import { addTabProperty, deleteTabProperty } from '../../actions/apiDealerUpdates';

class EditVehicleHistory extends Component {
	constructor(props) {
		super(props);

		this.state = { errors: { addDepartmentPropertyError: false }, option_name: '', vehicle_column_id: '' };
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.addNewVehicleColumn = this.addNewVehicleColumn.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClick(e) {
    	const self = this;
    	const errorType = 'addTabProperty';

    	const data = {
	    		key: 'use_work_flow',
	    		value: 'used',
	    		tab_id: this.props.tab.id,
	    		name: 'use_work_flow',
	    	};

    	this.props.addTabProperty(data, errorType);
	}

	onDelete(e) {
    	const errorType = 'Delete Tab Property';
    	const { id } = e.target.dataset;
    	this.props.deleteTabProperty(id, errorType);
	}

	render() {
		const { errors } = this.state;
		const self = this;
		const { props } = this;
		const { tab } = this.props;

		return (
			<div className="col-md-12">
				<h3 className="text-center">Checklist</h3>

				<div className="card space-top-1">

					<div className="card-text">

						<h3>Checklist Settings</h3>
						<div className="col-md-12">
                        	<small>
                        		<i className={'fa fa-info-circle text-info'} />
								{' '}
Choose from an existing vehicle column or create a new column and then add it to the list.
							</small>
                        		 <AddListItemButton field={'option_name'} value={this.state.option_name} label={'test'} error={errors.addDepartmentPropertyError} type={'text'} onChange={this.onChange} onClick={this.onClick} />
						</div>

						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>order</th>
									<th>name</th>
									<th>key</th>
									<th>value</th>
									<th>Delete</th>
								</tr>
							</thead>
							<tbody>

								{tab.tab_properties.map((tab_property) => {
									if (tab_property.name === 'use_work_flow') {
										return (<tr key={`tab_property_${tab_property.id}`}>
											<td>{tab_property.order}</td>
											<td>{tab_property.name}</td>
											<td>{tab_property.key}</td>
											<td>{tab_property.value}</td>
											<td><button className={'btn btn-danger btn-sm'} onClick={this.onDelete} data-id={tab_property.id}>X</button></td>
										</tr>);
									}
								})}

							</tbody>
						</table>

					</div>
				</div>

			</div>
		);
	}
}

const mappedActions = {
	addTabProperty,
	deleteTabProperty,
	addNewVehicleColumn,
	addNewVehicleColumnID,
};

function mapStateToProps(state) {
	return {
		edit_tab: state.settings_admin.edit_tab,
		vehicle_columns: state.settings_departments.vehicle_columns,
	};
}
export default connect(mapStateToProps, mappedActions)(EditVehicleHistory);
