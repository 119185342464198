import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	numberWithCommas,
} from '../../../../utils/dateFunctions';

class Vauto extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const vehicle = this.props.active_vehicle;

		const VautoLastModified = _.find(vehicle.vehicle_properties, { key: 'VautoLastModified' }) || '';
		const AppraisedValue = _.find(vehicle.vehicle_properties, { key: 'AppraisedValue' }) || '';
		const ReconditioningCost = _.find(vehicle.vehicle_properties, { key: 'ReconditioningCost' }) || '';
		const CostToMarket = _.find(vehicle.vehicle_properties, { key: 'CostToMarket' }) || '';
		const AdjCostToMarket = _.find(vehicle.vehicle_properties, { key: 'AdjCostToMarket' }) || '';
		const MktDaysSupply = _.find(vehicle.vehicle_properties, { key: 'MktDaysSupply' }) || '';
		const rBook = _.find(vehicle.vehicle_properties, { key: 'rBook' }) || '';
		const NADA = _.find(vehicle.vehicle_properties, { key: 'NADA' }) || '';
		const Disp = _.find(vehicle.vehicle_properties, { key: 'Disp' }) || '';
		const Appraiser = _.find(vehicle.vehicle_properties, { key: 'Appraiser' }) || '';
		const Comments = _.find(vehicle.vehicle_properties, { key: 'Comments' }) || '';
		const AppraisalDescription = _.find(vehicle.vehicle_properties, { key: 'AppraisalDescription' }) || '';
		const CarfaxRecall = _.find(vehicle.vehicle_properties, { key: 'CarfaxRecall' }) || '';
		const CarfaxWarnings = _.find(vehicle.vehicle_properties, { key: 'CarfaxWarnings' }) || '';
		const CarfaxProblems = _.find(vehicle.vehicle_properties, { key: 'CarfaxProblems' }) || '';
		const LikeMineDaysSupply = _.find(vehicle.vehicle_properties, { key: 'LikeMineDaysSupply' }) || '';

		const Buyer = _.find(vehicle.vehicle_properties, { key: 'Buyer' }) || '';
		const mMR = _.find(vehicle.vehicle_properties, { key: 'mMR' }) || '';

		const acv_target = _.find(vehicle.vehicle_properties, { key: 'acv_target' }) || '';

		const retail_velocity = _.find(vehicle.vehicle_properties, { key: 'retail_velocity' }) || '';

		let table = [];
		let nada = 'NADA';
		if (this.props.database === 'mboffredericksburg') {
			nada = 'J.D. Power';
		}

		table.push(<table className="table table-striped">
			<thead className={'thead-light'}>

				<tr>
					<th>Last Modified:</th>
					<td>{VautoLastModified.value}</td>
				</tr>
				<tr>
					<th style={{ width: '30%' }}>Appraiser:</th>
					<td>{Appraiser.value}</td>
				</tr>
				<tr>
					<th>AppraisalDescription:</th>
					<td>{AppraisalDescription.value}</td>
				</tr>
				<tr>
					<th>Recon Allowance:</th>
					<td>{ReconditioningCost.value}</td>
				</tr>
				<tr>
					<th>Like Mine Days Supply:</th>
					<td>{LikeMineDaysSupply.value}</td>
				</tr>
				<tr>
					<th>{nada}:</th>
					<td>
$
						{numberWithCommas(NADA.value)}
					</td>
				</tr>
				<tr>
					<th>DISP:</th>
					<td>{Disp.value}</td>
				</tr>
				<tr>
					<th>Appraised Value:</th>
					<td>
$
						{numberWithCommas(AppraisedValue.value)}
					</td>
				</tr>
				<tr>
					<th>Mkt Days Supply:</th>
					<td>{MktDaysSupply.value}</td>
				</tr>
				<tr>
					<th>rBook:</th>
					<td>
$
						{numberWithCommas(rBook.value)}
					</td>
				</tr>
				<tr>
					<th>CarfaxRecall:</th>
					<td>{CarfaxRecall.value}</td>
				</tr>
				<tr>
					<th>CarfaxWarnings:</th>
					<td>{CarfaxWarnings.value}</td>
				</tr>
				<tr>
					<th>CarfaxProblems:</th>
					<td>{CarfaxProblems.value}</td>
				</tr>
			</thead>

			<tbody />
		</table>);

		if (this.props.database === 'stanley') {
			table = [];
			table.push(<table className="table table-striped">
				<thead className={'thead-light'}>

					<tr>
						<th>Buyer:</th>
						<td>{Buyer.value}</td>
					</tr>
					<tr>
						<th style={{ width: '30%' }}>Appraiser:</th>
						<td>{Appraiser.value}</td>
					</tr>
					<tr>
						<th>AppraisalDescription:</th>
						<td>{AppraisalDescription.value}</td>
					</tr>
					<tr>
						<th>Recon Allowance:</th>
						<td>
$
							{numberWithCommas(ReconditioningCost.value)}
						</td>
					</tr>
					<tr>
						<th>J.D. Power:</th>
						<td>
$
							{numberWithCommas(NADA.value)}
						</td>
					</tr>
					<tr>
						<th>rBook:</th>
						<td>
$
							{numberWithCommas(rBook.value)}
						</td>
					</tr>
					<tr>
						<th>mMR:</th>
						<td>
$
							{numberWithCommas(mMR.value)}
						</td>
					</tr>
					<tr>
						<th>ACV Target:</th>
						<td>${numberWithCommas(acv_target.value)}</td>
					</tr>
				</thead>

				<tbody />
			</table>);
		}

		return (
			<div className="col-md-12">

				<div className="card space-top-1">
					<div className="card-header text-capitalize">
						<button className="btn btn-dark form-control">
Imported Appraisal Reports
							<span className="float-right">source: vAuto</span>
						</button>
					</div>

					<div className="card-text">

						{table}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		database: state.settings_dealerships.installation.database || '',
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps)(Vauto);
