import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AllInputs from '../../../../inputs/AllInputs';

class RecallSingle extends Component {
	constructor(props) {
		super(props);

		this.state = { addDepartmentPropertyError: '' };
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const self = this;
		const { recall } = this.props;
		const vehicle = this.props.active_vehicle;
		const recall_value = _.find(vehicle.vehicle_properties, { key: `recall_id_${recall.recall_id}` }) || '';
		const recall_checked_on = _.find(vehicle.vehicle_properties, { key: 'recall_checked' }) || '';

		return (<div className="container">

			<div className="row col-md-12 custyle">

				<table className="table table-striped custab">
					<thead>
						<tr>
							<th>Status</th>
							<th>Recall ID</th>
							<th>Campaign ID</th>
							<th>Recall Date</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td data-title="RecallCompleted" className="text-center">

								<AllInputs
									{...self.props}
									value={recall_value.value || ''}
									type={'switch-userstamp'}
									name={`recall_id_${recall.recall_id}`}
									parentid={vehicle.id}
									updatetype="recall_completed"
									user_name={recall_value.user_name}
									updated_at={recall_value.updated_at}
									vehicle={vehicle}
								/>

							</td>
							<td>{recall.recall_id}</td>
							<td>{recall.nhtsa_id}</td>
							<td>{recall.recall_age}</td>
						</tr>
						<tr>
							<th>Description</th>
							<td colSpan="3">{recall.description}</td>
						</tr>
						<tr>
							<th>Consequence</th>
							<td colSpan="3">{recall.risk_type}</td>
						</tr>
						<tr>
							<th>Remedy</th>
							<td colSpan="3">{recall.remedy}</td>
						</tr>
					</tbody>
				</table>

			</div>
		</div>
		);
	}
}

function mapStateToProps(state) {
	return { active_vehicle: state.settings_refresh.active_vehicle };
}
export default connect(mapStateToProps)(RecallSingle);
