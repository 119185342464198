import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import InputTypes from '../../../../inputs/InputTypes';
import AllInputs from '../../../../inputs/AllInputs';
import {
	lookupDepartmentByName,
	vsearch,
	activeVehicle,
} from '../../../../actions/lookups';
import {
	setCustomModalTab,
} from '../../../../actions/apiSetInfo';
import {
	processDepartments,
	getCurrentDepartment,
} from '../../../../actions/apiGetInfo';
import {
	addVehicleProperty,
	updateVehicleDepartment,
} from '../../../../actions/AllUpdates';

class UsedCarChecklist extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.state = {};
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const tab = _.find(this.props.all_tabs, { name: this.props.tab }) || [];
		const vehicle = this.props.vehicle || [];
		const vehicle_properties = this.props.vehicle_properties;
		const tab_properties = tab.tab_properties || [];

		const colObject = [];
		const self = this;
		const tr1 = { width: '60%' };
		const tr2 = { width: '20%' };
		const tr3 = { width: '20%' };

		return <div className="card text-center">
			<div className="card-text bg-light">
				<p className="text-left">
STOCK#
					{vehicle.stock}
				</p>
				<h3 className="text-center">USED CAR CHECK LIST</h3>
				<table className="table">
					<thead>
						<tr>
							<th style={tr1} />
							<th style={tr2}>Yes</th>
							<th style={tr3}>No</th>
						</tr>
					</thead>
					<tbody>
						{tab_properties.map((tab_property) => {
							const vehicle_column = _.find(this.props.vehicle_columns, { id: tab_property.vehicle_column_id }) || [];

							if (typeof vehicle_column !== 'undefined') {
								const y = `yes_${vehicle_column.name}`;
								const n = `no_${vehicle_column.name}`;
								const colObject = _.find(vehicle_properties, { key: y }) || [];
								const colObject_no = _.find(vehicle_properties, { key: n }) || [];

								return <tr key={tab_property.id}>
									<th className="text-left pad-left-1">{vehicle_column.name}</th>
									<td>
										<AllInputs
											name={y}
											label={y}
											onChange={this.onChange}
											onBlur={this.onBlur}
											user_name={colObject.user_name}
											value={colObject.value || ''}
											type={vehicle_column.input_name}
											options={vehicle_column.options}
											checked={colObject.value || ''}
											parentid={this.props.vehicle_id}
											rowid={colObject.id || ''}
											uid={colObject.uid}
											updated_at={colObject.updated_at}
											updatetype="vehicleproperty"
											icon={vehicle_column.icon}
											userStamp={this.props.userStamp}
											setdisabled={false}
											vehicle={vehicle}
										/>
									</td>
									<td>
										<AllInputs
											name={n}
											label={n}
											onChange={this.onChange}
											onBlur={this.onBlur}
											user_name={colObject_no.user_name}
											value={colObject_no.value || ''}
											type={vehicle_column.input_name}
											options={vehicle_column.options}
											checked={colObject_no.value || ''}
											parentid={this.props.vehicle_id}
											rowid={colObject_no.id || ''}
											uid={colObject_no.uid}
											updated_at={colObject_no.updated_at}
											updatetype="vehicleproperty"
											icon={vehicle_column.icon}
											userStamp={this.props.userStamp}
											setdisabled={false}
											vehicle={vehicle}
										/>
									</td>
								</tr>;
							}
						})}
					</tbody>
				</table>
			</div>
			<div className="card-footer" />
		</div>;
	}
}

const mappedActions = {
	lookupDepartmentByName,
	setCustomModalTab,
	addVehicleProperty,
	updateVehicleDepartment,
	processDepartments,
	vsearch,
};

function mapStateToProps(state) {
	return {
		vehicle: state.settings_refresh.active_vehicle,
		vehicle_properties: state.settings_refresh.active_vehicle.vehicle_properties,
		installation: state.settings_dealerships.installation,
		vehicle_columns: state.settings_departments.vehicle_columns,
		current_user: state.current_user,
		all_tabs: state.settings_site.all_tabs,
	};
}
export default connect(mapStateToProps, mappedActions)(UsedCarChecklist);
