import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as lookups from './lookups';
import * as AllMutates from './AllMutates';

export function updateEstimate(id, data, errorType) {
	return (dispatch, getState) => Api.patchData(`appraisals/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(getInfo.getEstimatesByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
		});
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateEstimateVendor(id, key, data, errorType) {
	return (dispatch, getState) => Api.patchData(`appraisals/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(getInfo.getEstimatesByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(data.vehicle_id));
		});
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function sendEstimate(id, data, errorType) {
	return (dispatch, getState) => Api.patchData(`appraisals/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(getInfo.getEstimatesByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(data.vehicle_id));
		});
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function sendEstimateRaw(id, data, errorType) {
	return (dispatch, getState) => Api.patchData(`appraisals/${id}`, data).then((resp) => {
		dispatch(lookups.activeVehicle(data.vehicle_id));
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function sendEstimateUpdate(id, data, errorType) {
	return (dispatch, getState) => Api.patchData(`appraisals/${id}`, data).then((resp) => {
		Promise.all([
			dispatch(getInfo.getEstimatesByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}

			setTimeout(() => { dispatch(lookups.activeVehicle(data.vehicle_id)); }, 100);
});
}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addEstimate(data, errorType) {
	return (dispatch, getState) => Api.postData('appraisals/', data).then((resp) => {
		Promise.all([
			dispatch(getInfo.getEstimatesByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(data.vehicle_id));
		});
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addEstimateRaw(data, errorType) {
	return (dispatch, getState) => Api.postData('appraisals/', data).then((resp) => {
		Promise.all([
			dispatch(getInfo.getEstimatesByVidAndUpdate(data.vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(data.vehicle_id));
		});
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteEstimate(id, vehicle_id, errorType) {
	return (dispatch, getState) => Api.delete(`appraisals/${id}`).then(() => {
		Promise.all([
			dispatch(getInfo.getEstimatesByVidAndUpdate(vehicle_id)),
		]).then(() => {
			const { vehicle_filter, department } = getState().settings_nav;

			if (vehicle_filter.type === 'searched') {
				dispatch(lookups.vsearch(vehicle_filter.value));
			} else {
				dispatch(getInfo.getCurrentDepartment(parseInt(department)));
			}
			dispatch(lookups.activeVehicle(vehicle_id));
		});
	}).catch(ex => Api.handleApiError(ex, errorType));
}
