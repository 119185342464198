import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EditDepartmentSettings from '../../inputs/EditDepartmentSettings';

class Goals extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<div className="col-md-12">

				<div className="space-top-1 mx-auto w-75 p-3">
					<h3 className="text-center pad-top-1">Set Goals (in hours)</h3>
					<p>After setting goals, escalated alerts are set in Dashboard > Notifications</p>

					<div className="card-body">

						<table className="table table-striped table-light">
							<thead className={'thead-dark text-center'}>
								<tr>
									<th>Step</th>
									<th>Goal</th>
									<th>1st Notification</th>
									<th>2nd Notification</th>
									<th>3rd Notification</th>
								</tr>
							</thead>
							<tbody>

								{this.props.departments.map((department) => {
									return (
										<tr key={`dept_goal_${department.id}`}>
											<td className={'text-capitalize'}>{department.name}</td>

											<td><EditDepartmentSettings {...this.props} key={`settings_goal_${department.id}`} parentid={department.id} label={`${department.name} Goal`} name={'goal'} type={'text-goal'} value={department.goal} /></td>

											<td><EditDepartmentSettings {...this.props} key={`overdue_1_${department.id}`} parentid={department.id} label={`${department.name} Goal`} name={'overdue_1'} type={'text-goal'} value={department.overdue_1} /></td>

											<td><EditDepartmentSettings {...this.props} key={`overdue_2_${department.id}`} parentid={department.id} label={`${department.name} Goal`} name={'overdue_2'} type={'text-goal'} value={department.overdue_2} /></td>

											<td><EditDepartmentSettings {...this.props} key={`overdue_3_${department.id}`} parentid={department.id} label={`${department.name} Goal`} name={'overdue_3'} type={'text-goal'} value={department.overdue_3} /></td>

										</tr>
									);
								})}

							</tbody>
						</table>

					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		departments: state.settings_departments.departments,
	};
}

export default connect(mapStateToProps)(Goals);
