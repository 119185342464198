import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import AddOptionButton from '../../inputs/AddOptionButton';
import DisplayOptions from '../DepartmentSetup/DisplayOptions';
import { addVehicleColumnOption } from '../../actions/apiDepartmentUpdates';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
	content: {
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
	},
};

class EditVehicleColumnButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, column_id: this.props.column.id, name: this.props.column.name, input_name: this.props.column.input_name, options: [], option_name: '', errors: { editVehicleColumnError: false },
		};
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClick() {
		const name = this.state.option_name;
		const { id } = this.props.column;
		const errorType = 'addVehicleColumnOption';
		const data = {
			vehicle_column_id: id,
			name,
			special: '',
			active: 'true',
		};

		this.props.addVehicleColumnOption(id, data, errorType);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	render() {
		const { errors } = this.state;

		return (
			<span>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					style={customStyles}
					contentLabel="EditDropdownList"
					newdepartment={this.state.newdepartment}
					ariaHideApp={false}
				>

					<div className="card">
						<div className="card-header bg-grey text-white">
							<div className="card-header bg-grey text-white text-capitalize">
								<button className="btn btn-dark form-control">
Add/Remove Options for
									{this.state.name}
								</button>
							</div>

						</div>
						<div className="card-text">

							<div className="d-flex flex-column">
								<div className="p-2">
									<AddOptionButton
										field="option_name"
										label=""
										onChange={this.onChange}
										onClick={this.onClick}
										value={this.state.option_name}
										type="add_option"
										error={errors.addDepartmentPropertyError}
									/>
								</div>
								<DisplayOptions {...this.props} column_id={this.props.column.id} />

							</div>
						</div>
						<div className="card-footer">
							<button className="btn btn-sm btn-danger float-start" onClick={this.closeModal}>Close</button>
						</div>
					</div>
				</Modal>

				<button className="btn btn-sm btn-primary float-end" onClick={this.openModal}>
					<i className="fa fa-edit float-right" />
					{' '}
				</button>
			</span>
		);
	}
}

const mappedActions = {
	addVehicleColumnOption,
};

function mapStateToProps() {
	return {};
}
export default connect(mapStateToProps, mappedActions)(EditVehicleColumnButton);
