import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import axios, { post } from 'axios';
import ReactDropzone from 'react-dropzone';
import DropList from './DropList';
import { updateInstallation, addDealerSetting } from '../../actions/apiDealerUpdates';

class DropBoxFeed extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', checked: this.props.checked || '', file: '', image: '', image_name: '', image_type: '', image_size: '', files: [],
		};
		this.onClick = this.onClick.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
	}

	onDrop(files) {
		this.setState({
			files,
		});
	}

	onClick() {
		const { files } = this.state;

		files.map((file) => {
			this.fileUpload(file);
		});
		this.setState({
			files: [],
		});
	}

	onClickDelete(name) {
		const newFiles = this.state.files.filter(el => el.name !== name);
		this.setState({
			files: newFiles || [],
		});
	}

	onChangeChecked22(e) {
		const self = this;
		const errorType = 'editDealerSetting';
		const value = e.target.checked ? 'true' : '';

		const data = {
			key: self.props.name,
			value,
		};

		self.setState({ checked: e.target.checked });

		self.props.addDealerSetting(data, errorType);
	}

	uploadData(response, fileid) {
		const fileurl = response.data;

		const self = this;
		const errorType = 'updateInstallation';
		const data = {
			key: `${self.props.installation}-feed`,
			value: fileurl,
		};

		self.setState({ upload_feedfile: fileurl });

		self.props.addDealerSetting(data, errorType);
	}

	fileUpload(file) {
		const url = "https://gotoreconfiles.com/cors_new_server.php";

		const self = this;
		const { vid } = this.props;
		const { siteid } = this.props;
		const { user_name } = this.props;
		const fileid = file.name;

		const data = new FormData();
		data.append('data', file);
		data.append('action', 'put-newserver-file');
		data.append('fileid', fileid);
		data.append('siteid', siteid);

		return axios.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},

		}).then(response => this.uploadData(response, fileid));
	}

	render() {
		return (
			<div className="row">
				<div className="col-md-6">
					<div className="dropzone">
						<div className="card-header text-capitalize">
							<h3 className="text-center">Drop/Add Files Below</h3>
						</div>
						<ReactDropzone className="drop-box p-2 text-center space-top-1" onDrop={this.onDrop.bind(this)}>
							{({ getRootProps, getInputProps }) => (
								<section>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<button className="form-control text-center btn btn-success btn-large">
Drop Feed File(CSV) Here or Click to Browse for File
											<i className="fa fa-plus" />
										</button>
									</div>
								</section>
							)}
						</ReactDropzone>
					</div>
				</div>
				<div className="col-md-6">
					<aside>
						<div className="card-header text-white text-capitalize">
							{
								this.state.files.length > 0 ? <button onClick={this.onClick} className="btn btn-success form-control">
									<i className={'fas fa-cloud-upload'} />
									{' '}
Upload Feed File
									{' '}
									<i className={'fas fa-cloud-upload'} />
                                      </button> : ''
							}
						</div>

						<ul className="list-group">
							{
								this.state.files.map(f => <DropList {...this.props} f={f} key={`dropped_file_${f.name}`} onClickDelete={this.onClickDelete} />)
							}
						</ul>

					</aside>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	updateInstallation,
	addDealerSetting,
};

function mapStateToProps(state) {
	return { user_name: state.current_user.user_name };
}

export default connect(mapStateToProps, mappedActions)(DropBoxFeed);
