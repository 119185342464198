import React, { Component } from 'react';
import { connect } from 'react-redux';
import { findUser } from '../../actions/lookups';
import { reset_user_password } from '../../actions/authentication';
import {
	addFlashMessage,
	deleteFlashMessages,
} from '../../actions/toggleMenu';

class UpdatePassword extends Component {
	constructor(props) {
		super(props);

		this.state = { changepassword: '', errors: {}, isLoading: false };
		this.onChange = this.onChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onFocus(e) {
		this.setState({ [e.target.name]: '' });
	}

	onClick(e) {
		var self = this;
		if (this.props.single_user > 0) {
			var user = _.find(this.props.all_users, { id: Number(this.props.single_user) }) || [];

			self.props.reset_user_password(user.reset_password_token, this.state.changepassword).then((resp) => {
				self.props.addFlashMessage({
					type: 'success',
					text: 'Password Has Been Reset!',
				});
				setTimeout(() => { self.props.deleteFlashMessages(); }, 3000);
			});
		}
	}

	render() {
		return (
			<div className="input-group col-md-12 pad-left-2">
				<input type="text" className="form-control" placeholder="Enter New Password" onChange={this.onChange} onFocus={this.onFocus} name="changepassword" value={this.state.changepassword} />
				<div className="input-group-append btn-warning" onClick={this.onClick}>
					<span className="input-group-text btn-warning" onClick={this.onClick}>Update Password</span>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	findUser,
	reset_user_password,
	addFlashMessage,
	deleteFlashMessages,
};

function mapStateToProps(state) {
	return {
		all_users: state.settings_users.all_users,
		single_user: state.settings_users.single_user,
	};
}
export default connect(mapStateToProps, mappedActions)(UpdatePassword);
