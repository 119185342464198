import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
	noteStamp,
} from '../../utils/dateFunctions';
import {
	lookupUserById,
} from '../../actions/lookups';
import {
	addRoomMessage,
} from '../../actions/apiSupportTickets';

class Room extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { value: '' };
		this.addRoomMessage = this.addRoomMessage.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
	}

	notes() {
		return Object.keys(this.props.notes).map(key => this.props.notes[key]);
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	onKeyPress(e) {
		if (e.key === 'Enter') {
			const data = {
				message: this.state.value,
				user_id: this.props.current_user.user,
				room_id: this.props.room_id,
			};
			this.setState({ value: '' });
			this.props.addRoomMessage(data, 'addRoomMessage');
		}
	}

	addRoomMessage(e) {
		const data = {
			message: this.state.value,
			user_id: this.props.current_user.user,
			room_id: this.props.room_id,
		};
		this.setState({ value: '' });
		this.props.addRoomMessage(data, 'addRoomMessage');
	}

	render() {
		const { rooms } = this.props;

		const room = _.find(rooms, { id: this.props.room_id }) || [];

		const room_messages = _.sortBy(room.room_messages, 'id');

		return (
			<div className="container ReactNotesBox deskscrollNotes">
				<div className="input-group space-bottom-2">
					<input type="text" className="form-control" placeholder="Send Message..." onKeyPress={this.onKeyPress} onChange={this.onChange} value={this.state.value} />

					<div className="input-group-append" onClick={this.addRoomMessage}>
						<span className="input-group-text btn btn-primary"><i className="fal fa-plus" /></span>

					</div>

				</div>
				{

					room_messages.reverse().map((room_message) => {
						const username = this.props.lookupUserById(room_message.user_id);
						var userFloat1 = 'float-left';
						var userFloat2 = 'float-right';
						var bubble = 'bubble-left';
						if (room_message.user_id === this.props.current_user.user) {
							userFloat1 = 'float-right';
							userFloat2 = 'float-left';
							bubble = 'bubble-right';
						}

						if (typeof username.user_name !== 'undefined') {
							var user = username.user_name.split('@');
						} else {
							var user = 'RA Support'.split('@');
						}
						return <div className="card bg-none border-0 space-bottom-1" key={`room_message_${room_message.id}`}>
							<div className="container space-left-0 space-right-0">
								<div className={`col-3 ReactNoteTime align-text-bottom pad-right-0 pad-left-0 ${userFloat1}`}>
									{user ? user[0] : ''}
									<br />
									{noteStamp(room_message.created_at)}
								</div>
								<div className={`col-9 align-text-top ${userFloat2}`}>
									<small className={`row room-message ${bubble} ${userFloat1}`}>{room_message.message}</small>
								</div>
							</div>
             </div>;
					})
				}
			</div>
		);
	}
}

const mappedActions = {
	lookupUserById,
	addRoomMessage,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		rooms: state.settings_support_tickets.rooms,
	};
}
export default connect(mapStateToProps, mappedActions)(Room);
