import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import InputTypes from '../../../../inputs/InputTypes';
import AllInputs from '../../../../inputs/AllInputs';
import {
	lookupDepartmentByName,
	vsearch,
	activeVehicle,
} from '../../../../actions/lookups';
import {
	setCustomModalTab,
} from '../../../../actions/apiSetInfo';
import {
	processDepartments,
	getCurrentDepartment,
} from '../../../../actions/apiGetInfo';
import {
	addVehicleProperty,
	updateVehicleDepartment,
} from '../../../../actions/AllUpdates';

class CheckboxesTab extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.state = {};
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		const tab = _.find(this.props.all_tabs, { name: this.props.tab }) || [];
		const vehicle = this.props.vehicle || [];
		const vehicle_properties = this.props.vehicle_properties;
		const tab_properties = tab.tab_properties || [];
		let automax = '';
		let automax2 = '';
		let automax3 = '';
		let automax4 = '';
		let automax5 = '';
		let automax6 = '';
		let automax7 = '';
		var is_automax = false;
		let colObject = [];
		const self = this;
		if (self.props.installation.subdomain === 'automaxatlanta') {
			is_automax = true;
			const wheel_array = [{ name: 'wheel repair' }, { name: 'Wheel Repair FL' }, { name: 'Wheel Repair FR' }, { name: 'Wheel Repair BL' }, { name: 'Wheel Repair BR' }];
			automax = (
				wheel_array.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name }) || [];

					if (typeof vehicle_column !== 'undefined') {
						colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						return <span>
							<label className="label checkboxes-tab-label text-capitalize fw-bold">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								display_label_1={vehicle_column.display_label_1}
								display_label_2={vehicle_column.display_label_2}
								onChange={this.onChange}
								onBlur={this.onBlur}
								user_name={colObject.user_name}
								value={colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
								vehicle={vehicle}
							/>
						</span>;
					}
				})

			);

			const tire_array = [{ name: 'tires' }, { name: 'tire size' }, { name: 'Tires FL' }, { name: 'Tires FR' }, { name: 'Tires BL' }, { name: 'Tires BR' }];
			automax2 = (
				tire_array.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name }) || [];

					if (typeof vehicle_column !== 'undefined') {
						colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						return <span>
							<label className="label checkboxes-tab-label text-capitalize fw-bold">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								display_label_1={vehicle_column.display_label_1}
								display_label_2={vehicle_column.display_label_2}
								onChange={this.onChange}
								onBlur={this.onBlur}
								user_name={colObject.user_name}
								value={colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
								vehicle={vehicle}
							/>
						</span>;
					}
				})

			);

			const interior_array = [{ name: 'interior dye' }, { name: 'driver seat only' }, { name: 'overall interior' }];
			automax3 = (
				interior_array.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name }) || [];

					if (typeof vehicle_column !== 'undefined') {
						colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						return <span>
							<label className="label checkboxes-tab-label text-capitalize fw-bold">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								display_label_1={vehicle_column.display_label_1}
								display_label_2={vehicle_column.display_label_2}
								onChange={this.onChange}
								onBlur={this.onBlur}
								user_name={colObject.user_name}
								value={colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
								vehicle={vehicle}
							/>
						</span>;
					}
				})

			);

			const interior_array2 = [{ name: 'interior repair' }, { name: 'repair driver seat' }, { name: 'repair passenger seat' }, { name: 'repair other' }];
			automax4 = (
				interior_array2.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name }) || [];

					if (typeof vehicle_column !== 'undefined') {
						colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						return <span>
							<label className="label checkboxes-tab-label text-capitalize fw-bold">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								display_label_1={vehicle_column.display_label_1}
								display_label_2={vehicle_column.display_label_2}
								onChange={this.onChange}
								onBlur={this.onBlur}
								user_name={colObject.user_name}
								value={colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
								vehicle={vehicle}
							/>
						</span>;
					}
				})

			);

			const pdr_array = [{ name: 'pdr' }, { name: 'pdr notes' }];
			automax5 = (
				pdr_array.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name }) || [];

					if (typeof vehicle_column !== 'undefined') {
						colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						return <span>
							<label className="label checkboxes-tab-label text-capitalize fw-bold">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								display_label_1={vehicle_column.display_label_1}
								display_label_2={vehicle_column.display_label_2}
								onChange={this.onChange}
								onBlur={this.onBlur}
								user_name={colObject.user_name}
								value={colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
								vehicle={vehicle}
							/>
						</span>;
					}
				})

			);

			const service_array = [{ name: 'service' }, { name: 'service notes' }];
			automax6 = (
				service_array.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name }) || [];

					if (typeof vehicle_column !== 'undefined') {
						colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						return <span>
							<label className="label checkboxes-tab-label text-capitalize fw-bold">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								display_label_1={vehicle_column.display_label_1}
								display_label_2={vehicle_column.display_label_2}
								onChange={this.onChange}
								onBlur={this.onBlur}
								user_name={colObject.user_name}
								value={colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
								vehicle={vehicle}
							/>
						</span>;
					}
				})

			);

			const refresh_array = [{ name: 'refresh' }, { name: 'refresh notes' }];
			automax7 = (
				refresh_array.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name }) || [];

					if (typeof vehicle_column !== 'undefined') {
						colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						return <span>
							<label className="label checkboxes-tab-label text-capitalize fw-bold">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								display_label_1={vehicle_column.display_label_1}
								display_label_2={vehicle_column.display_label_2}
								onChange={this.onChange}
								onBlur={this.onBlur}
								user_name={colObject.user_name}
								value={colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
								vehicle={vehicle}
							/>
						</span>;
					}
				})

			);
		}

		const automax_all = <div className="row"><div className="card card-body">{automax}</div>
				<div className="card card-body">{automax2}</div>
				<div className="card card-body">{automax3}</div>
				<div className="card card-body">{automax4}</div>
				<div className="card card-body">{automax5}</div>
				<div className="card card-body">{automax6}</div>
				<div className="card card-body">{automax7}</div></div>;

		return <div className="card col-md-6 offset-3 text-center text-center">
			<div className="card-body checkboxes-tab">

				{tab_properties.map((tab_property) => {
					if (this.props.installation.database === 'wp') {
						var newrows = ['pd', 'access', 'tint', 'sublets', 'aftermarket'];
						if (vehicle.stocktype === 'N') {
							if (!newrows.includes(tab_property.name)) {
								return false;
							}
						} else if (newrows.includes(tab_property.name)) {
							return false;
						}
					}
					const vehicle_column = _.find(this.props.vehicle_columns, { id: tab_property.vehicle_column_id }) || [];
					{console.log('input name',vehicle_column.input_name)}
					if (self.props.installation.subdomain.includes('automaxatlanta') && (vehicle_column.name === 'service' || vehicle_column.name === 'tires' || vehicle_column.name === 'wheel repair' || vehicle_column.name === 'interior dye' || vehicle_column.name === 'interior repair' || vehicle_column.name === 'pdr' || vehicle_column.name === 'refresh')) {
						return false;
					}

					if (typeof vehicle_column !== 'undefined') {
						const colObject = _.find(vehicle_properties, { key: vehicle_column.name }) || [];

						return <span>
						<label className="label checkboxes-tab-label text-capitalize fw-bold">{tab_property.name}</label><AllInputs
							name={vehicle_column.name}
							label={vehicle_column.name}
							display_label_1={vehicle_column.display_label_1}
							display_label_2={vehicle_column.display_label_2}
							onChange={this.onChange}
							onBlur={this.onBlur}
							user_name={colObject.user_name}
							value={colObject.value || ''}
							type={vehicle_column.input_name}
							options={vehicle_column.options}
							checked={colObject.value || ''}
							parentid={this.props.vehicle_id}
							rowid={colObject.id || ''}
							uid={colObject.uid}
							updated_at={colObject.updated_at}
							updatetype="vehicleproperty"
							icon={vehicle_column.icon}
							userStamp={this.props.userStamp}
							setdisabled={false}
							vehicle={vehicle}
						/></span>;
					}
				})}

				{is_automax ? automax_all : ''}
			</div>
			<div className="card-footer" />
		</div>;
	}
}

const mappedActions = {
	lookupDepartmentByName,
	setCustomModalTab,
	addVehicleProperty,
	updateVehicleDepartment,
	processDepartments,
	vsearch,
};

function mapStateToProps(state) {
	return {
		vehicle: state.settings_refresh.active_vehicle,
		vehicle_properties: state.settings_refresh.active_vehicle.vehicle_properties,
		installation: state.settings_dealerships.installation,
		vehicle_columns: state.settings_departments.vehicle_columns,
		current_user: state.current_user,
		all_tabs: state.settings_site.all_tabs,
	};
}
export default connect(mapStateToProps, mappedActions)(CheckboxesTab);
