import React, { Component } from 'react';
import { connect } from 'react-redux';
import Example from './Sortable';
import Dropdown from '../../inputs/NewDropdown';
import AddWorkFlow from './AddWorkFlow';
import AddDepartmentToWorkFlowButton from './AddDepartmentToWorkFlowButton';
import TextFieldGroup from '../../inputs/TextFieldGroupNoLabel';
import DeleteWorkFlowButton from './DeleteWorkflowButton';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';

import {
	lookupSortableWorkFlowPropertiesByWorkFlow,
	lookupDepartmentByName,
	lookupWorkFlowById,
} from '../../actions/lookups';
import {
	updateWorkFlow,
	deleteWorkFlow,
	deleteWorkFlowProperty,
	updateWorkFlowPropertyGroup,
	addWorkFlowProperty,
} from '../../actions/apiWorkFlows';

import {
	editWorkFlows,
} from '../../actions/apiSetInfo';

const activeOptions = ['true', 'false'];

class StepWorkFlow extends Component {
	constructor(props) {
		super(props);

		this.state = {
 abbreviation: '', exclude_1: '', exclude_2: '', exclude_3: '', include_1: '', include_2: '', include_3: '',
};
		this.onClick = this.onClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.AddWorkFlowProperty = this.AddWorkFlowProperty.bind(this);
		this.deleteWorkFlowProperty = this.deleteWorkFlowProperty.bind(this);
		this.updateWorkFlowPropertyGroup = this.updateWorkFlowPropertyGroup.bind(this);
		this.onExcludeChange1 = this.onExcludeChange1.bind(this);
		this.onExcludeChange2 = this.onExcludeChange2.bind(this);
		this.onExcludeChange3 = this.onExcludeChange3.bind(this);

		this.onIncludeChange1 = this.onIncludeChange1.bind(this);
		this.onIncludeChange2 = this.onIncludeChange2.bind(this);
		this.onIncludeChange3 = this.onIncludeChange3.bind(this);

		this.editWorkFlows = this.editWorkFlows.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onExcludeChange1(e) {
		this.setState({ exclude_1: e.id });
		const data = {
			id: this.props.edit_work_flows,
			exclude_1: e.id,
		};
		this.props.updateWorkFlow(this.props.edit_work_flows, data, 'exclude_1');
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onExcludeChange2(e) {
		this.setState({ exclude_2: e.id });
		const data = {
			id: this.props.edit_work_flows,
			exclude_2: e.id,
		};
		this.props.updateWorkFlow(this.props.edit_work_flows, data, 'exclude_2');
	}

	onExcludeChange3(e) {
		this.setState({ exclude_3: e.id });
		const data = {
			id: this.props.edit_work_flows,
			exclude_3: e.id,
		};
		this.props.updateWorkFlow(this.props.edit_work_flows, data, 'exclude_3');
	}

	onIncludeChange1(e) {
		this.setState({ include_1: e.id });
		const data = {
			id: this.props.edit_work_flows,
			include_1: e.id,
		};
		this.props.updateWorkFlow(this.props.edit_work_flows, data, 'include_1');
	}

	onIncludeChange2(e) {
		this.setState({ include_2: e.id });
		const data = {
			id: this.props.edit_work_flows,
			include_2: e.id,
		};
		this.props.updateWorkFlow(this.props.edit_work_flows, data, 'include_2');
	}

	onIncludeChange3(e) {
		this.setState({ include_3: e.id });
		const data = {
			id: this.props.edit_work_flows,
			include_3: e.id,
		};
		this.props.updateWorkFlow(this.props.edit_work_flows, data, 'include_3');
	}

	onBlur(e) {
		const data = {
			id: this.props.edit_work_flows,
			abbreviation: e.target.value,
		};
		this.props.updateWorkFlow(this.props.edit_work_flows, data, 'abbreviation');
	}

	deleteWorkflow(id) {
		this.props.deleteWorkFlow(id).then(() => {
			this.forceUpdate();
		});
	}

	selectWorkFlows() {
		const options = this.props.work_flows.map((option) => {
			let selected = false;
			const dataid = 0;
			if (this.props.edit_work_flows === option.id) {
				selected = true;
			}

			if (typeof option.id !== 'undefined') {
				var obj = {
					id: option.id, value: option.id, label: option.name, selected,
				};
			} else {
				var obj = {
					id: option.id, value: option.id, label: option.name, selected,
				};
			}
			return obj;
		});
		return options;
	}

	deleteWorkFlowProperty(e) {
		const errorType = 'deleteWorkFlowProperty';
		this.props.deleteWorkFlowProperty(e, errorType);
	}

	updateWorkFlowPropertyGroup(e) {
		const errorType = 'deleteWorkFlowProperty';
		this.props.updateWorkFlowPropertyGroup(e, errorType);
	}

	onClick() {
	}

	onUpdate(department_id, order) {
		const data = {
			work_flow_id: parseInt(this.props.edit_work_flows),
			department_id,
			order,
		};
		this.props.addWorkFlowProperty(data, 'AddWorkFlowProperty');
	}

	AddWorkFlowProperty(e) {
		const department_id = this.props.lookupDepartmentByName(e);
		const data = {
			work_flow_id: parseInt(this.props.edit_work_flows),
			department_id: department_id.id,
			order: 99,
		};
		this.props.addWorkFlowProperty(data, 'AddWorkFlowProperty');
	}

	componentDidMount() {
		const work_flow = this.props.lookupWorkFlowById(this.props.edit_work_flows);
		this.setState({ abbreviation: work_flow.abbreviation });
		this.setState({ exclude_1: work_flow.exclude_1 });
		this.setState({ exclude_2: work_flow.exclude_2 });
		this.setState({ exclude_3: work_flow.exclude_3 });
	}

	selectDepartments(department_id) {
		const departments = this.props.departments;

		const returnDepartments = departments.map((option) => {
			let selected = false;
			if (option.id === parseInt(department_id)) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = {
 id: parseInt(option.id), value: option.id, label: option.name, selected,
};
			} else {
				var obj = {
 id: parseInt(option.id), value: option.id, label: option.name, selected,
};
			}
			return obj;
		});
		returnDepartments.push({ id: 0, label: 'None Selected' });
		return returnDepartments;
	}

	editWorkFlows(e) {
		this.props.editWorkFlows(e.id);
	}

	render() {
		const work_flows = this.props.lookupSortableWorkFlowPropertiesByWorkFlow(parseInt(this.props.edit_work_flows));
		const work_flow_ids = work_flows.map(item => item.id);
		const workFlow = this.props.lookupWorkFlowById(this.props.edit_work_flows);
		const workFlowId = workFlow.id;
		let delete_disabled = false;

		if (workFlow.name === 'used' || workFlow.name === 'new' || workFlow.name === 'wholesale') {
			delete_disabled = true;
		}
		let abbreviation = '';
		if (workFlowId) {
			abbreviation = <div className="col-sm-3 bg-light text-dark text-center">
				<label className="text-center"><strong>Abbreviation</strong></label>
				<TextFieldGroup
					field="abbreviation"
					label="Enter Abbreviation"
					onChange={this.onChange}
					onBlur={this.onBlur}
					value={this.state.abbreviation || workFlow.abbreviation}
					type="text"
				/>
			</div>;
		}
		let exclude_1 = '';
		let exclude_2 = '';
		let exclude_3 = '';

		let include_1 = '';
		let include_2 = '';
		let include_3 = '';

		if (workFlowId) {
			exclude_1 = <div className="list-item bg-light text-dark text-center">
				<label className="text-center"><strong>Must Exclude Department 1</strong></label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Department'}
												list={this.selectDepartments(this.state.exclude_1 || workFlow.exclude_1)}
												update_type={'work_flow'}
												name="exclude_1"
												onChange={this.onExcludeChange1}
												value={this.state.exclude_1 || workFlow.exclude_1}
											/></div>;
			exclude_2 = <div className="list-item bg-light text-dark text-center">
				<label className="text-center"><strong>Must Exclude Department 2</strong></label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Department'}
												list={this.selectDepartments(this.state.exclude_2 || workFlow.exclude_2)}
												update_type={'work_flow'}
												name="exclude_2"
												onChange={this.onExcludeChange2}
												value={this.state.exclude_2 || workFlow.exclude_2}
											/></div>;
			exclude_3 = <div className="list-item bg-light text-dark text-center">
				<label className="text-center"><strong>Must Exclude Department 3</strong></label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Department'}
												list={this.selectDepartments(this.state.exclude_3 || workFlow.exclude_3)}
												update_type={'work_flow'}
												name="exclude_3"
												onChange={this.onExcludeChange3}
												value={this.state.exclude_3 || workFlow.exclude_3}
											/></div>;
		}

		if (workFlowId) {
			include_1 = <div className="list-item bg-light text-dark text-center">
				<label className="text-center"><strong>Must Include Department 1</strong></label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Department'}
												list={this.selectDepartments(this.state.include_1 || workFlow.include_1)}
												update_type={'work_flow'}
												name="include_1"
												onChange={this.onIncludeChange1}
												value={this.state.include_1 || workFlow.include_1}
											/></div>;
			include_2 = <div className="list-item bg-light text-dark text-center">
				<label className="text-center"><strong>Must Include Department 2</strong></label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Department'}
												list={this.selectDepartments(this.state.include_2 || workFlow.include_2)}
												update_type={'work_flow'}
												name="include_2"
												onChange={this.onIncludeChange2}
												value={this.state.include_2 || workFlow.include_2}
											/></div>;
			include_3 = <div className="list-item bg-light text-dark text-center">
				<label className="text-center"><strong>Must Include Department 3</strong></label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Department'}
												list={this.selectDepartments(this.state.include_3 || workFlow.include_3)}
												update_type={'work_flow'}
												name="include_3"
												onChange={this.onIncludeChange3}
												value={this.state.include_3 || workFlow.include_3}
											/></div>;
		}

		return (
			<div className="col-md-12">
				<div className="card space-top-0">

					<h3 className="text-center pad-top-1">Work Flows</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text row bg-light pad-top-1 pad-bottom-1">
							<div className="col-sm-3 bg-light text-dark text-center">
								<label className="text-center"><strong>Manage Workflow</strong></label>
								<Dropdown
									value={workFlow}
									onChange={this.editWorkFlows}
									titleHelper="Select Work Flow"
									title="Select Work Flow"
									list={this.selectWorkFlows()}
									update_type={'edit_work_flows'}
								/>
							</div>
							<div className="col-sm-3 bg-light text-dark text-center">
								<AddWorkFlow {...this.props} />
							</div>
							{abbreviation}
						</div>
						<div className="card-text container">
							{workFlow.id > 0 ? <DeleteWorkFlowButton disabled={!workFlowId} onClick={() => this.deleteWorkflow(workFlowId)} /> : ''}
							<div className="row">
								<div className="card col-sm-6 pad-top-1">
									<small>
										<i className="fa fa-info-circle text-info" />
										{' '}
Sort Work flow Items by Drag and Drop
									</small>
									<Example list={this.props.departments} onClick={this.onClick} cards={work_flows} onUpdate={this.onUpdate} updateWorkFlowPropertyGroup={this.updateWorkFlowPropertyGroup} getWorkFlows={this.props.getWorkFlows} deleteWorkFlowProperty={this.props.deleteWorkFlowProperty} />
								</div>
								<div className="card col-sm-6 pad-top-1">
									<small>
										<i className="fa fa-info-circle text-info" />
										{' '}
Click Arrow to Add to Work Flow
									</small>
									<div className="card-text">
										<ul className={workFlowId ? 'list-group setscroll' : 'hidden'}>
											{this.props.departments.map((department, i) => {
												if (department.id && workFlowId && !work_flow_ids.includes(department.id)) {
													var color = 'default';
													if (department.department_type == 'data step') {
														color = 'success';
													}
													return <AddDepartmentToWorkFlowButton color={color} name={department.name} department_id={department.id} onClick={this.AddWorkFlowProperty} key={`flow_${i}`} />;
												}
											})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="card space-top-0">
					<h3 className="text-center pad-top-1">Select Default Workflows for Vehicle Types (used in determining actual recon time)</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text pad-top-2">

							<div className="row">
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'select-recon-workflow'} name="recon_new_work_flow" label="recon_new_work_flow" value={this.dealerSettingValue('recon_new_work_flow')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'select-recon-workflow'} name="recon_used_work_flow" label="recon_used_work_flow" value={this.dealerSettingValue('recon_used_work_flow')} />
								</div>
								<div className="col-md-6">
									<EditDealershipSettings {...this.props} type={'select-recon-workflow'} name="recon_wholesale_work_flow" label="recon_wholesale_work_flow" value={this.dealerSettingValue('recon_wholesale_work_flow')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupSortableWorkFlowPropertiesByWorkFlow,
	lookupDepartmentByName,
	lookupWorkFlowById,
	updateWorkFlow,
	deleteWorkFlow,
	deleteWorkFlowProperty,
	updateWorkFlowPropertyGroup,
	addWorkFlowProperty,
	editWorkFlows,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.original_departments,
		work_flows: state.settings_departments.work_flows,
		edit_work_flows: state.settings_site.edit_work_flows,
		dealer_settings: state.settings_site.dealer_settings,
	};
}
export default connect(mapStateToProps, mappedActions)(StepWorkFlow);
