import React, {
	Component, useState, useCallback, useEffect,
} from 'react';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import Card from './Card';

const style = {
	width: 400,
};

const DragAndDropHOC = (props) => {
	const [cards, setCards] = useState(props.cards);
	const moveCard = useCallback(
		(dragIndex, hoverIndex) => {
			const dragCard = cards[dragIndex];
			setCards(
				update(cards, {
					$splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
				}),
			);
		},
		[cards],
	);

	const onDropCard = () => {
		const success = props.updateWorkFlowPropertyGroup(cards);
	};

	useEffect(() => {
		setCards(props.cards);
	}, [props.cards]);

	const renderCard = (card, index) => (
		<Card
			key={card.id}
			index={index}
			id={card.id}
			text={card.text}
			moveCard={moveCard}
			property_id={card.property_id}
			order={card.order}
			onClick={props.deleteWorkFlowProperty}
			onDropCard={onDropCard}
		/>
	);

	return (
		<DndProvider backend={HTML5Backend}>
			<ul className="list-group">
				{cards.map((card, i) => renderCard(card, i))}
			</ul>
		</DndProvider>
	);
};

export default DragAndDropHOC;
