import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationCheckbox from '../../inputs/NotificationCheckbox';
import DropdownMultiple from '../../inputs/DropdownMultiple';

class NotificationSettings extends Component {
	constructor(props) {
		super(props);

		this.state = { current_default_profile: this.props.current_default_profile };
		this.onTabClick = this.onTabClick.bind(this);
		this.changePage = this.changePage.bind(this);
		this.onMenuClick = this.onMenuClick.bind(this);
		this.notification_setting = this.notification_setting.bind(this);
	}

	changePage(e) {
		this.props.change_page(e.target.name);
	}

	onTabClick(e) {
		this.props.setCurrentTab(e.target.name);
	}

	onMenuClick(e) {
		this.props.setCurrentDefaultProfile(e.target.name);
	}

	showDepartments() {
		const newObj = Object.keys(this.props.departments).map(key => this.props.departments[key]);
		return _.sortBy(newObj, 'order');
	}

	default_profile() {
		const self = this;
		if (self.props.current_default_profile.id) {
			const default_profile = _.sortBy(_.filter(self.props.default_profiles, o => o.id == self.props.current_default_profile.id), 'id');
			return default_profile[0].profile_permissions || [];
		}
		return [];
	}

	notification_setting(note_type, department_id, user_id) {
		const self = this;
		if (self.props.notification_settings.length > 0) {
			const notification_setting = _.sortBy(_.filter(self.props.notification_settings, o => o.user_id === parseInt(user_id) && o.department_id === parseInt(department_id) && o.note_type == note_type), 'id');

			return notification_setting[0] || [];
		}
		return [];
	}

	getPermission(permission) {
		let permissionValue = '';
		const profile_permissions = this.default_profile();

		if (profile_permissions.length > 0) {
			profile_permissions.map((obj) => {
				if (obj.key == permission) {
					permissionValue = obj.value;
				}
			});
		}
		return permissionValue;
	}

	selectRelatedInstallations() {
		const { dealerships } = this.props;
		const selected_dealerships = [];

		const dealer_values = dealerships.split(',');

		dealer_values.map((option) => {
			selected_dealerships.push(option.installation_id);
		});

		const options = this.props.related_installations.map((option) => {
			let selected = false;
			let dataid = 0;
			if (selected_dealerships.includes(option.id)) {
				selected = true;
				const user_installation_id = _.filter(dealerships, o => o.installation_id === option.id);
				dataid = user_installation_id[0].id;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, selected, dataid,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, selected, dataid,
				};
			}
			return obj;
		});
		return options;
	}

	render() {
		const self = this;
		const { props } = this;
		const { user_id } = this.props;
		return (

			<div className="content-box container bg-light">

				<div className="row bg-light">

					<h5 className={'text-success text-center'}>
Notification Settings.
						<br />
						{' '}
						<small className={'text-warning text-center'}>Contact support if you need any assistance.</small>
					</h5>

					<table className="table table-striped table-light text-dark w-100">
						<thead>
							<tr className="text-center">
								<th />
								<th>Entry</th>
								<th>1st Overdue</th>
								<th>2nd Overdue</th>
								<th>3rd Overdue</th>
							</tr>
						</thead>
						<tbody>
							{this.showDepartments().map((department) => {
								if (department.id) {
									const vehicle_entry = this.notification_setting('vehicle_entry', department.id, user_id);

									const vehicle_overdue_first = this.notification_setting('vehicle_overdue_first', department.id, user_id);

									const vehicle_overdue_second = this.notification_setting('vehicle_overdue_second', department.id, user_id);

									const vehicle_overdue_third = this.notification_setting('vehicle_overdue_third', department.id, user_id);

									return <tr key={`${department.id}_notification_row`}>
										<th className="text-capitalize">{department.name}</th>
										<NotificationCheckbox {...props} key={`alert_vehicle_entry_${department.id}`} checked={vehicle_entry.value} dealership={vehicle_entry.dealership} department_id={department.id} department_name={department.name} user_id={user_id} note_type={'vehicle_entry'} name={`alert_vehicle_entry_${department.id}`} />
										<NotificationCheckbox {...props} key={`alert_vehicle_overdue_first_${department.id}`} checked={vehicle_overdue_first.value} dealership={vehicle_overdue_first.dealership} department_id={department.id} department_name={department.name} user_id={user_id} note_type={'vehicle_overdue_first'} name={`alert_vehicle_overdue_first_${department.id}`} />
										<NotificationCheckbox {...props} key={`alert_vehicle_overdue_second_${department.id}`} checked={vehicle_overdue_second.value} dealership={vehicle_overdue_second.dealership} department_id={department.id} department_name={department.name} user_id={user_id} note_type={'vehicle_overdue_second'} name={`alert_vehicle_overdue_second_${department.id}`} />
										<NotificationCheckbox {...props} key={`alert_vehicle_overdue_third_${department.id}`} checked={vehicle_overdue_third.value} dealership={vehicle_overdue_third.dealership} department_id={department.id} department_name={department.name} user_id={user_id} note_type={'vehicle_overdue_third'} name={`alert_vehicle_overdue_third_${department.id}`} />
									</tr>;
								}
							})}
						</tbody>
					</table>

				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		default_profiles: state.settings_users.default_profiles,
		current_tab: state.settings_admin.current_tab,
		current_default_profile: state.settings_users.current_default_profile,
		departments: state.settings_departments.departments,
		notification_settings: state.settings_users.notification_settings,
		current_user: state.current_user,
		related_installations: state.settings_dealerships.related_installations,
	};
}
export default connect(mapStateToProps)(NotificationSettings);
