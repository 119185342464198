import React, { Component } from 'react';
import { connect } from 'react-redux';

const AddDepartmentToWorkFlowButton = ({ name, onClick, color }) => (
	<li className="list-group-item text-capitalize" href="#">
		<button className="btn btn-sm btn-success" onClick={() => onClick(name)}>
			<i className="fa fa-arrow-left float-start" />
			{' '}

		</button>
		{' '}
		<div className={`col-md-12 bg-${color}`}>{name}</div>
	</li>
);

export default AddDepartmentToWorkFlowButton;
