import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import InputTypes from '../../../../inputs/InputTypes';
import AllInputs from '../../../../inputs/AllInputs';
import {
	getDateTime,
	readableStamp,
} from '../../../../utils/dateFunctions';
import {
	addVehicleProperty,
} from '../../../../actions/AllUpdates';
import {
	addVehicleNote,
	addVehicleNoteRaw,
} from '../../../../actions/apiVehicleNotes';
import {
	lookupPermission,
	lookupIsAdmin,
	activeVehicle,
} from '../../../../actions/lookups';

class UciForm extends Component {
	constructor(props) {
		super(props);

		this.state = { mileage: false };
		this.onChange = this.onChange.bind(this);
		this.onChangeTextArea = this.onChangeTextArea.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onBlurTextArea = this.onBlurTextArea.bind(this);

		this.vehicleProperty = this.vehicleProperty.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onUnClick = this.onUnClick.bind(this);
		this.onPrint = this.onPrint.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeTextArea(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onBlur(e) {
		const errorType = 'updateVehicleError';
		const vehicle_id = this.props.vehicle.id;
		const key = e.target.name;
		const { value } = e.target;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;

		const data = {
			vehicle_id,
			key,
			value,
			uid,
			user_name,
		};

		this.props.addVehicleProperty(data, errorType);
	}

	onChangeChecked(e) {
		const self = this;
		var vehicle_id = parseInt(this.props.vehicle.id);

		var errorType = 'updateVehicleError';
		var key = e.target.name;
		var keylowercase = e.target.name.toLowerCase();
		var value = e.target.checked ? 'true' : '';
		var uid = this.props.current_user.user;
		var user_name = this.props.current_user.user_name || this.props.current_user.email;

		var data = {
			vehicle_id,
			key,
			value,
			uid,
			user_name,
		};
		this.props.addVehicleProperty(data, errorType);
		this.props.activeVehicle(vehicle_id);
	}

	onBlurTextArea(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	componentDidMount() {
		const vehicle = this.props.vehicle;
		const actual_mileage = _.find(vehicle.vehicle_properties, { key: 'actual mileage' }) || [];
		if (parseInt(actual_mileage.value) < parseInt(vehicle.miles)) {
			this.setState({ mileage: true });
		}

		if ((parseInt(actual_mileage.value) - 500) > parseInt(vehicle.miles)) {
			this.setState({ mileage: true });
		}
	}

	onClick(e) {
		const errorType = 'uciform';
		const data = {
			vehicle_id: this.props.vehicle.id,
			key: 'inspection status',
			value: 'completed',
		};

		var data2 = {
			vehicle_id: this.props.vehicle.id,
			value: 'UCI Form submitted',
			uid: this.props.current_user.user,
		};

		Promise.all([this.props.addVehicleProperty(data, errorType), this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError')]).then(() => {
			this.props.activeVehicle(this.props.vehicle.id);
		});
	}

	onPrint(e) {
		const vehicle = this.props.vehicle;
		const print_page = document.getElementById(`inspection_form_${vehicle.id}`);

		html2canvas(print_page)
			.then((canvas) => {
				const pdf = new jsPDF('p', 'pt', 'letter');
				for (var i = 0; i <= print_page.clientHeight / 980; i++) {
		                var srcImg = canvas;
		                var sX = 0;
		                var sY = 980 * i;
		                var sWidth = 900;
		                var sHeight = 980;
		                var dX = 0;
		                var dY = 0;
		                var dWidth = 900;
		                var dHeight = 980;

		                window.onePageCanvas = document.createElement('canvas');
		                onePageCanvas.setAttribute('width', 900);
		                onePageCanvas.setAttribute('height', 980);
		                var ctx = onePageCanvas.getContext('2d');
		                ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

		                var canvasDataURL = onePageCanvas.toDataURL('image/png', 1.0);

		                var width = onePageCanvas.width;
		                var height = onePageCanvas.clientHeight;

		                if (i > 0) {
		                    pdf.addPage();
		                }

		                pdf.setPage(i + 1);

		                pdf.addImage(canvasDataURL, 'PNG', 10, 20, (width * 0.72), (height * 0.72));
		            }
				    pdf.save(`uci_form_${vehicle.id}.pdf`);
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	onUnClick(e) {
		const errorType = 'uciform';
		const data = {
			vehicle_id: this.props.vehicle.id,
			key: 'inspection status',
			value: 'pending',
		};

		var data2 = {
			vehicle_id: this.props.vehicle.id,
			value: 'UCI Form UnLocked',
			uid: this.props.current_user.user,
		};

		Promise.all([this.props.addVehicleProperty(data, errorType), this.props.addVehicleNoteRaw(data2, 'addVehicleNoteError')]).then(() => {
			this.props.activeVehicle(this.props.vehicle.id);
		});
	}

	vehicleProperty(name) {
		const vehicle = this.props.vehicle;
		const colObject = _.find(vehicle.vehicle_properties, { key: name }) || '';

		return colObject;
	}

	render() {
		const vehicle = this.props.vehicle;
		const interior_rows = [];
		const under_the_hood_rows = [];
		const exterior_rows = [];
		const under_body_rows = [];
		const brakes_rows = [];
		const brake_service_rows = [];
		const transmission_rows = [];
		const road_test_rows = [];
		const actual_mileage = _.find(vehicle.vehicle_properties, { key: 'actual mileage' }) || [];
		const tech_colObject = _.find(vehicle.vehicle_properties, { key: 'tech' }) || [];
		const inspection_status_colObject = _.find(vehicle.vehicle_properties, { key: 'inspection status' }) || [];
		const inspection_notes_colObject = _.find(vehicle.vehicle_properties, { key: 'inspection notes' }) || [];

		let setdisabled = false;
		let button = <button className="btn btn-success" onClick={this.onClick}>Submit and Lock Form</button>;

		if (inspection_status_colObject.value === 'pending') {
			setdisabled = false;
			button = <button className="btn btn-success" onClick={this.onClick}>Submit and Lock Form</button>;
		} else if (inspection_status_colObject.value === 'completed') {
			setdisabled = true;
			button = <button className="btn btn-danger">Locked</button>;
			if (this.props.lookupIsAdmin()) {
				button = <button className="btn btn-danger" onClick={this.onUnClick}>UnLock</button>;
			}
		}

		const fields = ['Pass', 'Fail', 'Repair Est'];

		const interiorRows = ['air conditioning', 'air bags', 'audio system', 'dash lights', 'defogger', 'defroster', 'heater', 'horn', 'lighting system', 'mirror controls', 'sunroof', 'navigation system', 'power outlets', 'power windows', 'power seats', 'seat belts', 'seat adjuster', 'steering system', 'trim-carpet', 'air vents'];

		const under_the_hoodRows = ['air & fuel filter', 'alternator', 'battery', 'belts', 'cables', 'all fluid levels', 'engine', 'engine fluids', 'fans', 'filters', 'hoses', 'ingition system', 'mounts', 'power steering', 'pumps', 'radiator', 'starter', 'tanks', 'wires', 'master cylinder', 'leaks'];

		const exteriorRows = ['body panels', 'bumpers-fenders', 'door hinges-latches', 'door locks', 'headlamps', 'brake lamps', 'exterior lamps', 'directional signals', 'hood hinges', 'outside mirrors', 'remotes', 'tailgate', 'wheel-wheel covers', 'windshield-glass', 'wipers', 'cracked broken lens'];

		const under_bodyRows = ['bars', 'bushings', 'calipers', 'chassis', 'clutch', 'differential', 'drums', 'exhaust system', 'hoses', 'joints', 'lines', 'shafts', 'steering', 'struts', 'cv boots', 'shocks', 'axle shafts', 'catalytic converter', 'leaks'];

		const brakesRows = ['frt pad thickness', 'rear pad thickness', 'calipers', 'rotors', 'drums', 'lines'];

		const transmissionRows = ['fluid levels', 'mounts', 'no leaks'];

		const road_testRows = ['engine performance', 'gauges', 'noise-vibrations', 'squeaks-rattles', 'cruise control', 'trans operation', 'steering operation', 'suspension operation', 'brake operation', 'speedometer function', 'overall performance'];

		const basic_serviceRows = ['change oil-filter', 'top off all fluids', 'road test', 'vehicle vin plates match engine, door, and dash', 'tire size listed on door jam mfg tag', 'tire size on vehicle currently'];

		const tread_serviceRows = ['tread depth'];

		const basic_serviceRows2 = ['any vehicle modifications to suspend, lift kits, etc.'];

		interiorRows.map((row) => {
			const pass = `${row}_Pass_interior`;
			const fail = `${row}_Fail_interior`;
			const repair_est = `${row}_Repair Est_interior`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			const label = row;
			const firstrow = [];

			interior_rows.push(<tr key={`interior_${row}`}>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<InputTypes
						value={pass_colObject.value || ''}
						type={'checkbox'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td className={fail_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<InputTypes
						value={fail_colObject.value || ''}
						type={'checkbox'}
						name={fail}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={fail_colObject.user_name}
						updated_at={fail_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
				</td>
			</tr>);
		});

		under_the_hoodRows.map((row) => {
			const pass = `${row}_Pass_under_the_hood`;
			const fail = `${row}_Fail_under_the_hood`;
			const repair_est = `${row}_Repair Est_under_the_hood`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			let label = row;
			if (label === 'all fluid levels') {
				label = <strong>DEF %</strong>;
			}

			const firstrow = [];

			under_the_hood_rows.push(<tr key={`under_the_hood_${row}`}>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<InputTypes
						value={pass_colObject.value || ''}
						type={'checkbox'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td className={fail_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<InputTypes
						value={fail_colObject.value || ''}
						type={'checkbox'}
						name={fail}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={fail_colObject.user_name}
						updated_at={fail_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
				</td>
			</tr>);
		});

		exteriorRows.map((row) => {
			const pass = `${row}_Pass_exterior`;
			const fail = `${row}_Fail_exterior`;
			const repair_est = `${row}_Repair Est_exterior`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			const label = row;
			const firstrow = [];

			exterior_rows.push(<tr key={`exterior_${row}`}>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<InputTypes
						value={pass_colObject.value || ''}
						type={pass === 'remotes_Pass_exterior' ? 'text' : 'checkbox'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={pass === 'remotes_Pass_exterior' ? this.onChange : this.onChangeChecked}
					/>
				</td>
				<td className={fail_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<InputTypes
						value={fail_colObject.value || ''}
						type={'checkbox'}
						name={fail}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={fail_colObject.user_name}
						updated_at={fail_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
					/>
				</td>
			</tr>);
		});

		under_bodyRows.map((row) => {
			const pass = `${row}_Pass_under_body`;
			const fail = `${row}_Fail_under_body`;
			const repair_est = `${row}_Repair Est_under_body`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			const label = row;
			const firstrow = [];

			under_body_rows.push(<tr key={`under_body_${row}`}>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<InputTypes
						value={pass_colObject.value || ''}
						type={'checkbox'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td className={fail_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<InputTypes
						value={fail_colObject.value || ''}
						type={'checkbox'}
						name={fail}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={fail_colObject.user_name}
						updated_at={fail_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
				</td>
			</tr>);
		});

		brakesRows.map((row) => {
			const pass = `${row}_Pass_brakes`;
			const fail = `${row}_Fail_brakes`;
			const repair_est = `${row}_Repair Est_brakes`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			const label = row;
			const firstrow = [];

			brakes_rows.push(<tr key={`brakes_${row}`}>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<InputTypes
						value={pass_colObject.value || ''}
						type={'checkbox'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td className={fail_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<InputTypes
						value={fail_colObject.value || ''}
						type={'checkbox'}
						name={fail}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={fail_colObject.user_name}
						updated_at={fail_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
				</td>
			</tr>);
		});

		transmissionRows.map((row) => {
			const pass = `${row}_Pass_transmission`;
			const fail = `${row}_Fail_transmission`;
			const repair_est = `${row}_Repair Est_transmission`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			const label = row;
			const firstrow = [];

			transmission_rows.push(<tr key={`transmission_${row}`}>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<InputTypes
						value={pass_colObject.value || ''}
						type={'checkbox'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td className={fail_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<InputTypes
						value={fail_colObject.value || ''}
						type={'checkbox'}
						name={fail}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={fail_colObject.user_name}
						updated_at={fail_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
				</td>
			</tr>);
		});

		road_testRows.map((row) => {
			const pass = `${row}_Pass_road_test`;
			const fail = `${row}_Fail_road_test`;
			const repair_est = `${row}_Repair Est_road_test`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			const label = row;
			const firstrow = [];

			road_test_rows.push(<tr key={`road_test_${row}`}>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<InputTypes
						value={pass_colObject.value || ''}
						type={'checkbox'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td className={fail_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<InputTypes
						value={fail_colObject.value || ''}
						type={'checkbox'}
						name={fail}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={fail_colObject.user_name}
						updated_at={fail_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChangeChecked}
					/>
				</td>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
				</td>
			</tr>);
		});

		basic_serviceRows.map((row) => {
			const pass = `${row}_Pass_basic_service`;
			const fail = `${row}_Fail_basic_service`;
			const repair_est = `${row}_Repair Est_basic_service`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			const label = row;
			const firstrow = [];

			brake_service_rows.push(<tr key={`basic_service_${row}`}>
				<th className="text-uppercase w-50">{firstrow.length > 0 ? firstrow : label}</th>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
				</td>
			</tr>);
		});

		tread_serviceRows.map((row) => {
			const fl = _.find(vehicle.vehicle_properties, { key: 'thread - fl' }) || '';
			const fr = _.find(vehicle.vehicle_properties, { key: 'thread - fr' }) || '';
			const bl = _.find(vehicle.vehicle_properties, { key: 'thread - bl' }) || '';
			const br = _.find(vehicle.vehicle_properties, { key: 'thread - br' }) || '';

			const label = row;
			const firstrow = [];

			brake_service_rows.push(<tr key={`tread_${row}`}>
				<th className="text-uppercase w-50">{firstrow.length > 0 ? firstrow : label}</th>
				<td>
					<table>
						<tbody>
							<tr>
								<td>
									<InputTypes
										value={fl.value || ''}
										type={'text'}
										name={'thread - fl'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={fl.user_name}
										updated_at={fl.updated_at}
										vehicle_id={vehicle.id}
										placeholder={'FL'}
										onChange={this.onChange}
										onBlur={this.onBlur}
									/>
								</td>
								<td>
									<InputTypes
										value={fr.value || ''}
										type={'text'}
										name={'thread - fr'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={fr.user_name}
										updated_at={fr.updated_at}
										vehicle_id={vehicle.id}
										placeholder={'FR'}
										onChange={this.onChange}
										onBlur={this.onBlur}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<InputTypes
										value={bl.value || ''}
										type={'text'}
										name={'thread - bl'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={bl.user_name}
										updated_at={bl.updated_at}
										vehicle_id={vehicle.id}
										placeholder={'BL'}
										onChange={this.onChange}
										onBlur={this.onBlur}
									/>
								</td>
								<td>
									<InputTypes
										value={br.value || ''}
										type={'text'}
										name={'thread - br'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={br.user_name}
										updated_at={br.updated_at}
										vehicle_id={vehicle.id}
										placeholder={'BR'}
										onChange={this.onChange}
										onBlur={this.onBlur}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>);
		});

		basic_serviceRows2.map((row) => {
			const pass = `${row}_Pass_basic_service`;
			const fail = `${row}_Fail_basic_service`;
			const repair_est = `${row}_Repair Est_basic_service`;

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const fail_colObject = _.find(vehicle.vehicle_properties, { key: fail }) || '';
			const repair_est_colObject = _.find(vehicle.vehicle_properties, { key: repair_est }) || '';

			const label = row;
			const firstrow = [];

			brake_service_rows.push(<tr key={`basic_service_${row}`}>
				<th className="text-uppercase w-50">{firstrow.length > 0 ? firstrow : label}</th>
				<td>
					<InputTypes
						value={repair_est_colObject.value || ''}
						type={'text'}
						name={repair_est}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={repair_est_colObject.user_name}
						updated_at={repair_est_colObject.updated_at}
						vehicle_id={vehicle.id}
						onChange={this.onChange}
						onBlur={this.onBlur}
					/>
				</td>
			</tr>);
		});

		return (
			<div className="container uci-form">
				<button onClick={this.onPrint} className="btn btn-success space-left-2">Save to PDF</button>
				<div className="card" id={`inspection_form_${vehicle.id}`}>
					<div className="card" style={{ width: '800px' }}>
						<div className="row">
							<div className="d-flex flex-wrap" style={{ width: '94%' }}>
								<table className="table table-striped">
									<tr>
										<td className="text-center" colSpan={7}>USED VEHICLE INSPECTION REPORT</td>
									</tr>
									<tr>
										<td>Dealership:</td>
										<td>{vehicle.subdomain}</td>
										<td />
										<td />
										<td />
										<td>Tech Name:</td>
										<td>{tech_colObject.value}</td>
									</tr>
									<tr>
										<td>Stock Number</td>
										<td>Year</td>
										<td>Make</td>
										<td>Model</td>
										<td colSpan={3}>Full Vin</td>
									</tr>
									<tr>
										<td>{vehicle.stock}</td>
										<td>{vehicle.year}</td>
										<td>{vehicle.make}</td>
										<td>{vehicle.model}</td>
										<td colSpan={3}>{vehicle.vin}</td>
									</tr>
									<tr>
										<td>Inspection Date:</td>
										<td colSpan={2}>{inspection_status_colObject.created_at ? readableStamp(inspection_status_colObject.created_at) : ''}</td>
										<td>Mileage:</td>
										<td className={this.state.mileage ? 'bg-danger' : ''}>{vehicle.miles}</td>
										<td>Actual Mileage</td>
										<td>
											<InputTypes
												value={actual_mileage.value || ''}
												type={'number'}
												name={'actual mileage'}
												parentid={vehicle.id}
												updatetype="vehicleproperty"
												setdisabled={setdisabled}
												user_name={actual_mileage.user_name}
												updated_at={actual_mileage.updated_at}
												vehicle_id={vehicle.id}
												onChange={this.onChange}
												onBlur={this.onBlur}
											/>
										</td>
									</tr>

								</table>
							</div>

			   <div className="col-6" style={{ maxWidth: '400px', padding: 0 }}>
			    	<div className="uci-section">
									<div className="col-xs-12 column no-more-tables ui-grid-a">
										<small className="float-left space-left-2 pad-left-2 font-weight-bold">BASIC SERVICE AND TIRE SIZE VERIFICATION</small>
					  <table className="col-xs-12 table-bordered table-striped uci-table" style={{ width: '94%', margin: 'auto' }}>
											<thead className="thead-light">
												<tr>
													<th className="text-center col-xs-2" style={{ width: '60%' }}>ITEM</th>
													<th className="text-center col-xs-2" style={{ width: '40%' }} />
												</tr>
											</thead>
											<tbody className="detail_slip_form">
					  		{brake_service_rows}
											</tbody>
					  </table>
									</div>
			   		</div>
								<div className="uci-section">
									<div className="col-xs-12 column no-more-tables ui-grid-a">
										<small className="float-left space-left-2 pad-left-2 font-weight-bold">INTERIOR</small>
					  <table className="col-xs-12 table-bordered table-striped uci-table" style={{ width: '94%', margin: 'auto' }}>
											<thead className="thead-light">
												<tr>
													<th className="text-center col-xs-2" style={{ width: '40%' }}>ITEM</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Pass</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Fail</th>
													<th className="text-center col-xs-2" style={{ width: '20%' }}>Repair Est $</th>
												</tr>
											</thead>
											<tbody className="detail_slip_form">
					  		{interior_rows}
											</tbody>
					  </table>
									</div>
								</div>
			   <div className="uci-section">
									<div className="col-xs-12 column no-more-tables ui-grid-a">
										<small className="float-left space-left-2 pad-left-2 font-weight-bold">UNDER THE HOOD</small>
					  <table className="col-xs-12 table-bordered table-striped uci-table" style={{ width: '94%', margin: 'auto' }}>
											<thead className="thead-light">
												<tr>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>ITEM</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Pass</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Fail</th>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>Repair Est $</th>
												</tr>
											</thead>
											<tbody className="detail_slip_form">
					  		{under_the_hood_rows}
											</tbody>
					  </table>
									</div>
			   </div>
			   <div className="uci-section">
			   		<h3>Notes:</h3>
				   <AllInputs
										value={inspection_notes_colObject.value || ''}
										type={'textarea-big'}
										name={'inspection notes'}
										parentid={vehicle.id}
										updatetype="vehicleproperty"
										setdisabled={setdisabled}
										user_name={inspection_notes_colObject.user_name}
										updated_at={inspection_notes_colObject.updated_at}
										vehicle_id={vehicle.id}
										vehicle={vehicle}
				   />
			   </div>
			   </div>
			   <div className="col-6" style={{ maxWidth: '400px', padding: 0 }}>

			   <div className="uci-section">
									<div className="col-xs-12 column no-more-tables ui-grid-a">
										<small className="float-left space-left-2 pad-left-2 font-weight-bold">EXTERIOR</small>
					  <table className="col-xs-12 table-bordered table-striped uci-table" style={{ width: '94%', margin: 'auto' }}>
											<thead className="thead-light">
												<tr>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>ITEM</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Pass</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Fail</th>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>Repair Est $</th>
												</tr>
											</thead>
											<tbody className="detail_slip_form">
					  		{exterior_rows}
											</tbody>
					  </table>
									</div>
			   </div>
			   <div className="uci-section">
									<div className="col-xs-12 column no-more-tables ui-grid-a">
										<small className="float-left space-left-2 pad-left-2 font-weight-bold">UNDER BODY</small>
					  <table className="col-xs-12 table-bordered table-striped uci-table" style={{ width: '94%', margin: 'auto' }}>
											<thead className="thead-light">
												<tr>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>ITEM</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Pass</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Fail</th>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>Repair Est $</th>
												</tr>
											</thead>
											<tbody className="detail_slip_form">
					  		{under_body_rows}
											</tbody>
					  </table>
									</div>
			   </div>
			    <div className="uci-section">
									<div className="col-xs-12 column no-more-tables ui-grid-a">
										<small className="float-left space-left-2 pad-left-2 font-weight-bold">BRAKES</small>
					  <table className="col-xs-12 table-bordered table-striped uci-table" style={{ width: '94%', margin: 'auto' }}>
											<thead className="thead-light">
												<tr>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>ITEM</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Pass</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Fail</th>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>Thickness</th>
												</tr>
											</thead>
											<tbody className="detail_slip_form">
					  		{brakes_rows}
											</tbody>
					  </table>
									</div>
								</div>
			    <div className="uci-section">
									<div className="col-xs-12 column no-more-tables ui-grid-a">
										<small className="float-left space-left-2 pad-left-2 font-weight-bold">TRANSMISSION</small>
					  <table className="col-xs-12 table-bordered table-striped uci-table" style={{ width: '94%', margin: 'auto' }}>
											<thead className="thead-light">
												<tr>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>ITEM</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Pass</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Fail</th>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>Repair Est $</th>
												</tr>
											</thead>
											<tbody className="detail_slip_form">
					  		{transmission_rows}
											</tbody>
					  </table>
									</div>
								</div>
			    <div className="uci-section">
									<div className="col-xs-12 column no-more-tables ui-grid-a">
										<small className="float-left space-left-2 pad-left-2 font-weight-bold">ROAD TEST</small>
					  <table className="col-xs-12 table-bordered table-striped uci-table" style={{ width: '94%', margin: 'auto' }}>
											<thead className="thead-light">
												<tr>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>ITEM</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Pass</th>
													<th className="text-center col-xs-1" style={{ width: '20%' }}>Fail</th>
													<th className="text-center col-xs-2" style={{ width: '30%' }}>Repair Est $</th>
												</tr>
											</thead>
											<tbody className="detail_slip_form">
					  		{road_test_rows}
											</tbody>
					  </table>
									</div>
								</div>

			   </div>
			   <div className="col-12">
			   	{button}
			   </div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	addVehicleProperty,
	addVehicleNote,
	addVehicleNoteRaw,
	lookupPermission,
	lookupIsAdmin,
	activeVehicle,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		current_user: state.current_user,
		vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(UciForm);
