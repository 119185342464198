import Api from '../api/reconApi.js';
import AUTH_API, { AUTH_API_URLS } from '../api/authApi'
import setAuthorizationToken from '../api/setAuthorizationToken';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as AllMutates from './AllMutates';
import * as types from './types'
import { store } from '../index.js'


function errorReport(data, type = 'login') {
	const errors = {};

	if (type === 'login') {
		errors.loginError = 'Username or Password is incorrect';
	} else if (type === 'reset_password') {
		errors.loginError = 'Sorry, this Reset Link is no longer active. Please issue another request below.';
	} else if (type === 'forgotpassword') {
		errors.loginError = 'Sorry, Username/Email was not found. Please contact support if you do not know your username or email on file. Thank you!.';
	}

	return {
		errors,
	};
}

// export function login(email, password) {
// 	return (dispatch) => {
// 		const browser = getInfo.getBrowser();
// 		return Api.login('access_tokens', email, password, `desktop${browser}`)
// 			.then((resp) => {
// 				const { token } = resp.data;
// 				const user = resp.data.user_id;
// 				const username = resp.data.user.user_name;
// 				const userAll = resp.data.user;
// 				localStorage.setItem('reconUserName', JSON.stringify(username));
// 				localStorage.setItem('reconToken', token);
// 				localStorage.setItem('reconUser', user);
//
// 				setAuthorizationToken(token, user, username);
//
// 				dispatch(setInfo.setCurrentUser({ user: userAll, token }));
//
// 				dispatch(getInfo.getDealerships());
// 				return 'logged in';
// 			})
// 			.catch(ex => errorReport(ex, 'login'));
// 	};
// }

export function swapRedirectToken(redirectToken, pkce, onSuccess){
	let headers = {
		Authorization: `bearer ${redirectToken}`,
		Accept: 'application/json',
		'Content-Type': 'application/json',
	}
	AUTH_API(AUTH_API_URLS.SWAP_REDIRECT_TOKEN, {
    method: 'POST',
    headers,
    body: JSON.stringify({ pkce, client_id: 8931252 }),
  })
  .then(response => {
    if (response.status === 200) {
      return response.json()
    } else {
      logout(true)
    }
  })
  .then(res => {
    if (res && res.access_token) {
      getUserLoginInfo(res, onSuccess)
    } else {
      logout(true)
    }
  })
}

export function getUserLoginInfo(auth, onSuccess) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (auth) {
    if (auth.access_token) {
      headers['Authorization'] = `bearer ${auth.access_token}`
    }
  } else {
    let oldAuthentication = localStorage.getItem('authentication')
    if (oldAuthentication) {
      auth = JSON.parse(oldAuthentication)
    }
  }
	Api.getUserInfo(auth.access_token).catch(error=>{
		console.log('Error',error)
	}).then(response=>{
		if(response.status ===200){
			return response.data
		}
	}).then(res=>{
		if(res && res.data && res.data.id){
			let user = res.data.id
			let username = res.data.user_name
			let userAll  = res.data
			localStorage.setItem('reconUserName', JSON.stringify(username));
			localStorage.setItem('reconToken', auth.access_token);
			localStorage.setItem('reconUser', user);
			setAuthorizationToken(auth.access_token, user, username)
			store.dispatch(setInfo.setCurrentUser({ user: userAll, token: auth.access_token }));
			store.dispatch(getInfo.getDealerships());
			let authentication = {
				...auth,
				user: {...res.data},
			}
			localStorage.setItem('authentication', JSON.stringify(authentication))
			store.dispatch({
				type: types.UPDATE_USER_LOGIN_INFO,
				authentication,
			})
		}
	})
  // return AIP_DJAPI(AIP_DJAPI_URLS.DjangoLoginInfo, {
  //   headers,
  // })
  //   .then(response => {
  //     if (response.status === 200) {
  //       return response.json()
  //     }
  //   })
  //   .then(res => {
  //     let authentication = {
  //       ...auth,
  //       user: { ...res },
  //       userInfo: buildDeprecatedUserInfoObject(res, auth.access_token),
  //     }
  //     localStorage.setItem('authentication', JSON.stringify(authentication))
  //     store.dispatch({
  //       type: userConstants.UPDATE_USER_LOGIN_INFO,
  //       authentication,
  //     })
  //     if (onSuccess) {
  //       onSuccess()
  //     }
  //   })
}

// export function updateUserLoginInfo() {
//   return getUserLoginInfo()
// }

export function logout(requireLogin) {
	localStorage.clear()
	if(requireLogin){
		window.location.pathname= '/require-login'
	}
	else{
		const user = {};
		const token = '';
		store.dispatch(setInfo.setCurrentUser({ user, token }));
		store.dispatch({
			type: types.LOGOUT_USER,
		})
	}
}

export function resetAll() {
	return (dispatch, getState) => {
		const { all_users } = getState().settings_users;

		all_users.map((user) => {
			dispatch(forgotpassword(user.user_name));
		});
	};
}

export function forgotpassword(email) {
	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	}
	return AUTH_API(AUTH_API_URLS.INITIATE_RESET_PASSWORD, {
    method: 'POST',
    headers,
    body: JSON.stringify({ email }),
  })
  .then(response => {
    if (response.status === 200) {
      return response.json()
		}
  })
	// return () => Api.forgotpassword('password_resets', email)
	// 	.then(() => 'password reset has been sent')
	// 	.catch(ex => errorReport(ex, 'forgotpassword'));
}

export function reset_password(reset_token, password) {
	return () => Api.reset_password(`password_resets/${reset_token}`, password)
		.then(() => 'password has been reset')
		.catch(ex => errorReport(ex, 'reset_password'));
}

export function reset_user_password(reset_token, password) {
	return dispatch => Api.reset_password(`password_resets/${reset_token}`, password)
		.then(() => {
			dispatch(getInfo.getRelatedUsers());
		})
		.catch(ex => errorReport(ex, 'reset_password'));
}

export function changepassword(email) {
	return dispatch => Api.changepassword('password_resets', email)
		.then((resp) => {
			dispatch(getInfo.getRelatedUsers());
		})
		.catch(ex => errorReport(ex, 'changepassword'));
}
