import React, { Component } from 'react';
import { connect } from 'react-redux';
import AllInputs from '../../../../inputs/AllInputs';

class InspectionSimple extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.onChange = this.onChange.bind(this);
		this.vehicleProperty = this.vehicleProperty.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	vehicleProperty(name) {
		const vehicle = this.props.active_vehicle;
		const colObject = _.find(vehicle.vehicle_properties, { key: name }) || '';

		return colObject;
	}

	render() {
		const vehicle = this.props.active_vehicle;
		const rows = [];
		const leftAndRight = ['Tread Depth Front', 'Tread Depth Rear', 'Pad Life Front', 'Pad Life Rear'];

		const topRows = ['custom_inspection1', 'custom_inspection2', 'custom_inspection3', 'custom_inspection4'];

		const firstRows = ['smog', 'windshield repair', 'windshield replace', 'upholstery', 'paint', 'bedliner', 'weight', 'vin verification', 'p d r - fabian', 'interior work - Josh', 'r i m s', 'drive for smog', 'tires', 'bumper repair - kristian', 'alignment', 'bad odor - ozone treatment', 'final test drive', 'final inspection', 'recall', 'key', 'touch up paint - sean needed'];

		const fields = ['Pass', 'Required', 'Notes', 'Completed'];

		firstRows.map((row) => {
			const pass = `${row}_Pass`;
			const recommend = `${row}_Recommend`;
			const required_name = `${row}_Required`;
			const notes = `${row}_Notes`;
			const labor_hours = `${row}_Labor Hours`;
			const parts = `${row}_Parts`;
			const total = `${row}_Total`;
			const completed = `${row}_Completed`;
			const setdisabled = false;

			const custom_1 = _.find(vehicle.vehicle_properties, { key: 'custom_inspection1' }) || '';
			const custom_2 = _.find(vehicle.vehicle_properties, { key: 'custom_inspection2' }) || '';
			const custom_3 = _.find(vehicle.vehicle_properties, { key: 'custom_inspection3' }) || '';
			const custom_4 = _.find(vehicle.vehicle_properties, { key: 'custom_inspection4' }) || '';

			const tread_depth_front_left = _.find(vehicle.vehicle_properties, { key: 'Tread Depth Front_left' }) || '';
			const tread_depth_front_right = _.find(vehicle.vehicle_properties, { key: 'Tread Depth Front_right' }) || '';
			const tread_depth_rear_left = _.find(vehicle.vehicle_properties, { key: 'Tread Depth Rear_left' }) || '';
			const tread_depth_rear_right = _.find(vehicle.vehicle_properties, { key: 'Tread Depth Rear_right' }) || '';

			const pad_life_front_left = _.find(vehicle.vehicle_properties, { key: 'Pad Life Front_left' }) || '';
			const pad_life_front_right = _.find(vehicle.vehicle_properties, { key: 'Pad Life Front_right' }) || '';
			const pad_life_rear_left = _.find(vehicle.vehicle_properties, { key: 'Pad Life Rear_left' }) || '';
			const pad_life_rear_right = _.find(vehicle.vehicle_properties, { key: 'Pad Life Rear_right' }) || '';

			const pass_colObject = _.find(vehicle.vehicle_properties, { key: pass }) || '';
			const recommend_colObject = _.find(vehicle.vehicle_properties, { key: recommend }) || '';
			const required_name_colObject = _.find(vehicle.vehicle_properties, { key: required_name }) || '';
			const notes_colObject = _.find(vehicle.vehicle_properties, { key: notes }) || '';
			const labor_hours_colObject = _.find(vehicle.vehicle_properties, { key: labor_hours }) || '';
			const parts_colObject = _.find(vehicle.vehicle_properties, { key: parts }) || '';
			const total_colObject = _.find(vehicle.vehicle_properties, { key: total }) || '';
			const completed_colObject = _.find(vehicle.vehicle_properties, { key: completed }) || '';

			const label = row;
			const firstrow = [];

			rows.push(<tr>
				<th className="text-uppercase">{firstrow.length > 0 ? firstrow : label}</th>
				<td className={pass_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<AllInputs
						value={pass_colObject.value || ''}
						type={'userstamp'}
						name={pass}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={pass_colObject.user_name}
						updated_at={pass_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td className={required_name_colObject.value === 'true' ? 'bg-red text-center' : 'text-center'}>
					<AllInputs
						value={required_name_colObject.value || ''}
						type={'userstamp'}
						name={required_name}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={required_name_colObject.user_name}
						updated_at={required_name_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td>
					<AllInputs
						value={notes_colObject.value || ''}
						type={'textarea'}
						name={notes}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={notes_colObject.user_name}
						updated_at={notes_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
				<td className={completed_colObject.value === 'true' ? 'bg-green text-center' : 'text-center'}>
					<AllInputs
						value={completed_colObject.value || ''}
						type={'userstamp'}
						name={completed}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={completed_colObject.user_name}
						updated_at={completed_colObject.updated_at}
						vehicle={vehicle}
					/>
				</td>
			</tr>);
		});

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-xs-12 column no-more-tables ui-grid-a">
						<h3>
							<small className="float-left space-left-2 pad-left-2">
								{vehicle.year}
								{' '}
								{vehicle.make}
								{' '}
								{vehicle.model}
								{' '}
(
								{vehicle.stock}
)
							</small>
						</h3>
					  <table className="col-xs-12 table-bordered table-striped inspection-table" style={{ width: '94%', margin: 'auto' }}>
							<thead className="thead-light">
								<tr>
									<th className="text-center col-xs-2" style={{ width: '200px' }} />
									<th className="text-center col-xs-1" style={{ width: '90px' }}>
								Pass
									</th>
									<th className="text-center col-xs-1" style={{ width: '90px' }}>
								Required
									</th>
									<th className="text-center col-xs-2" style={{ width: '180px' }}>
								Notes
									</th>
									<th className="text-center col-xs-1" style={{ width: '90px' }}>
								Completed
									</th>
								</tr>
							</thead>
							<tbody className="detail_slip_form">

					  		{rows}
							</tbody>
					  </table>
					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps)(InspectionSimple);
