import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DepartmentSetupBody from './DepartmentSetupBody';
import { activeEditVehicleColumn } from '../../actions/lookups';
import TabEditVehicleColumn from './TabEditVehicleColumn';

const activeOptions = ['true', 'false'];

class TabEditVehicleColumnContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	componentDidMount() {
		this.props.activeEditVehicleColumn(this.props.vehicle_column.original.org[0].id);
	}

	render() {
		const self = this;

		return (
			<div className="col-md-12">
				<TabEditVehicleColumn />
			</div>
		);
	}
}

const mappedActions = {
	activeEditVehicleColumn,
};

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps, mappedActions)(TabEditVehicleColumnContainer);
