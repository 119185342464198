import axios from 'axios';
import HomeInfo from './HomeInfo';

let recon_api_prefix_default = "default"

if (window.ENV && window.ENV.RECON_API_PREFIX_DEFAULT) {
  recon_api_prefix_default = window.ENV.RECON_API_PREFIX_DEFAULT
}

export default function setAuthorizationToken(token, userId, username = 'websiteuser', database = recon_api_prefix_default, subdomain = recon_api_prefix_default) {
	if (process.env.NODE_ENV === '2development' && database === 'userdefault') {
		subdomain = 'apitest';
		database = 'apitest';
	}

	if (token && userId > 0) {
		axios.defaults.headers.common.Authorization = `Bearer ${token}`
		// axios.defaults.headers.common.Authorization = `${HomeInfo.apiKey()}, access_token=${Number(userId)}:${token}:${database}`;
		axios.defaults.headers.common['X-DEALER'] = database;
		axios.defaults.headers.common['X-DEALER-SUBDOMAIN'] = subdomain;
		axios.defaults.headers.common['X-USERNAME'] = username;
	} else {
		axios.defaults.headers.common.Authorization = HomeInfo.apiKey();
	}
}
