import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import DeleteAuditButton from './DeleteAuditButton';
import { lookupVehicleById } from '../../actions/lookups';

class AuditTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: [],
		};
	}

	handleRowClick(rowId) {
		const currentExpandedRows = this.state.expandedRows;
		const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

		const newExpandedRows = isRowCurrentlyExpanded
			? currentExpandedRows.filter(department => department !== rowId)
			: currentExpandedRows.concat(rowId);

		this.setState({ expandedRows: newExpandedRows });
	}

	onClick(rowId) {
	}

	getData(vehicles) {
		const data = vehicles.map((vehicle_entry) => {
			const vehicle = this.props.lookupVehicleById(vehicle_entry.vehicle_id);
			const obj = {
				stock: vehicle.stock,
				vin: vehicle.vin,
				year: vehicle.year,
				make: vehicle.make,
				model: vehicle.model,
				miles: vehicle.miles,
				department: vehicle.department_id,
				vehicle_lat: vehicle_entry.vehicle_lat,
				vehicle_long: vehicle_entry.vehicle_long,
				confirmed: vehicle_entry.confirmed,
				confirmed_by: vehicle_entry.confirmed_by,
				confirmed_at: vehicle_entry.confirmed_at,
				status: vehicle_entry.status,
				notes: vehicle_entry.notes,
			};
			return obj;
		});

		return data;
	}

	openSingle() {

	}

	renderItem(item) {
		const clickCallback = () => this.handleRowClick(item.id);
		const onClick = () => this.onClick(item.id);
		const itemRows = [
			<tr key={`row-data-${item.id}`}>
			    <td><button className="btn btn-sm btn-success" onClick={clickCallback}><icon className="fal fa-arrow-right" /></button></td>
			    <td>{item.name}</td>
				<td>{item.audit_type}</td>
				<td>{item.status}</td>
				<td>{item.audit_start}</td>
				<td>{item.audit_end}</td>
				<td>{item.active}</td>
				<td>{item.locked}</td>
				<td>{item.open}</td>
				<td>{item.closed}</td>
				<td>{item.total}</td>
				<td><DeleteAuditButton {...this.props} audit_id={item.id} /></td>
			</tr>,
		];

		if (this.state.expandedRows.includes(item.id)) {
			itemRows.push(
				<tr key={`row-expanded-${item.id}`}>
					<td colSpan="12">
						<table className="table table-dark table-striped w100">
							<thead>
								<tr>
									<th>Stock</th>
									<th>Vin</th>
									<th>Year</th>
									<th>Make</th>
									<th>Model</th>
									<th>Confirmed</th>
									<th>By</th>
									<th>Latitude</th>
									<th>Longitude</th>
									<th>Status</th>
									<th>Notes</th>
								</tr>
							</thead>
							<tbody>
								{this.getData(item.vehicles).map((vehicle, i) => (
									<tr key={`row-vehicle_sub-${(i += 1) + vehicle.stock + item.id}`}>
										<td>{vehicle.stock}</td>
										<td>{vehicle.vin}</td>
										<td>{vehicle.year}</td>
										<td>{vehicle.make}</td>
										<td>{vehicle.model}</td>
										<td>{vehicle.confirmed_at}</td>
										<td>{vehicle.confirmed_by}</td>
										<td>{vehicle.vehicle_lat}</td>
										<td>{vehicle.vehicle_long}</td>
										<td>{vehicle.status}</td>
										<td>{vehicle.notes}</td>
									</tr>
								))}
							</tbody>
						</table>
					</td>
				</tr>,
			);
		}

		return itemRows;
	}

	render() {
		const self = this;
		const audits = self.props.audits;

		const tableData = [];
		const data = self.props.audits.map((audit, index) => {
			const obj = {
				id: audit.id,
				name: audit.name,
				audit_type: audit.audit_type,
				status: audit.status,
				audit_start: audit.audit_start,
				audit_end: audit.audit_end,
				active: audit.active,
				locked: audit.locked,
				open: audit.audit_properties.length,
				confirmed: 0,
				total: audit.audit_properties.length,
				vehicles: audit.audit_properties,
			};
			return obj;
		});

		tableData.push(data);

		let allItemRows = [];

		data.forEach((item) => {
			if (item) {
				const perItemRows = this.renderItem(item);
				allItemRows = allItemRows.concat(perItemRows);
			}
		});

		return (
			<table className="table table-striped w100">
				<thead>
					<tr>
						<th />
						<th>Name</th>
						<th>Type</th>
						<th>Status</th>
						<th>Started</th>
						<th>Ended</th>
						<th>Active</th>
						<th>Locked</th>
						<th>Open</th>
						<th>Confirmed</th>
						<th>Total</th>
						<th />
					</tr>
				</thead>
				{allItemRows}
			</table>
		);
	}
}

const mappedActions = {
	lookupVehicleById,
};

function mapStateToProps(state) {
	return {
		audits: state.settings_refresh.audits,
	};
}
export default connect(mapStateToProps, mappedActions)(AuditTable);
