import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextBox from '../../inputs/TextBox';
import TextFieldGroupWithButton from '../../inputs/TextFieldGroupWithButton';
import FlashMessageList from '../../inputs/FlashMessageList';
import Api from '../../api/reconApi.js';
import { addRoom, openSupportChat, closeSupportChat } from '../../actions/apiSupportTickets';

class SupportChatWidget extends Component {
	constructor(props) {
		super(props);

		this.state = {
			name: '',
			installation_id: '',
			vehicle_id: '',
			room_type: '',
			profile: '',
			support: '',
			status: '',
			active: false,
			modalIsOpen: false,
		};

		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChange = this.onChange.bind(this);
		this.addRoom = this.addRoom.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onChangeDealership = this.onChangeDealership.bind(this);
	}

	openModal() {
		this.props.openSupportChat();
	}

	afterOpenModal() {}

	closeModal() {
		this.props.closeSupportChat();
	}

	onChangeSelect(e) {
		this.setState({ audit_type: e.value });
	}

	onChangeDealership(e) {
		this.setState({ dealerships: e.value });
	}

	selectRoomType() {
		const comparisonArray = [{ name: 'one-on-one chat-only', label: 'One on One - Chat Only' },
			{ name: 'vehicle-chat', label: 'Vehicle Chat' }, { name: 'department-chat', label: 'Department Chat' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectRelatedInstallations() {
		const options = this.props.related_installations.map((option) => {
			if (typeof option.subdomain !== 'undefined') {
				var obj = { value: option.subdomain, label: option.softname };
			} else {
				var obj = { value: option.subdomain, label: option.softname };
			}
			return obj;
		});
		return options;
	}

	addRoom() {
		const data = {
			name: this.state.name,
			status: this.state.status,
			installation_id: this.state.installation_id,
			vehicle_id: this.state.vehicle_id,
			room_type: this.state.room_type,
			profile: this.state.profile,
			support: this.state.support,
			status: this.state.status,
			active: this.state.active,
		};

		const extra = {};

		this.props.addRoom(data, extra).then((resp) => {
			this.setState({
				name: '',
				installation_id: '',
				vehicle_id: '',
				room_type: '',
				profile: '',
				support: '',
				status: '',
				active: false,
				modalIsOpen: false,
			});
		});
	}

	onChange(event) {
		const { name } = event.target;
		this.setState({ [name]: event.target.value });
	}

	render() {
		const { errors } = this.state;

		const defaultStyle = {
			background: this.props.bg_color,
			backgroundColor: this.props.bg_color,
			color: this.props.font_color,
		};

		var html = '';

		switch (this.props.support_chat_status) {
		case 'accepted':
			html = 'support_chat_status';
			break;
		case 'awaiting response':
			html = 'a chat operator will be with you shortly';
			break;
		default:
			html = <div className="card-block bg-white">
				<div className="row text-center">
					<FlashMessageList {...this.props} />
				</div>
				<div className="row">
					<label>Chat with Support: </label>
					<small className="pad-top-1">Please enter a description and click "Start Chat" or "Create Support Ticket" if you don't have time to chat. Your ticket status will be available in the support tab above.</small>
					<div className="col text-center">
						<TextBox
							onChange={this.onChange}
							value={this.state.name}
							type={'text'}
							name={'name'}
							field={'name'}
							updatetype="room"
							disabled={false}
						/>
					</div>
				</div>
			</div>;
		}

		return (
			<div className="col-md-12">
				<div className="card space-top-0">
					<div className="card bg-light space-bottom-1 space-top-1 mx-auto p-3">
						<div className="col-md-12 pad-bottom-1 text-center">

							<div className={this.props.support_chat_widget === true ? 'container pad-left-0 pad-right-0 popup-box chat-open' : 'popup-box chat-closed'}>
								<div className="card pad-left-0 pad-right-0 pad-bottom-0">
									<div className="modal-header bg-dark text-white">
										<h4 className="modal-title"><img key="toplogo" src="https://cdn.ipacketrecon.com/images/logo_40_white.png" key="support-chat" name="support-chat" style={{ marginTop: 5 }} height={30} width={157} /></h4>
										<button type="button" className="close text-white" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
									</div>

									<div className="modal-body add-vehicle">
										{html}
									</div>
									<div className="modal-footer">
										<button type="button" className="btn btn-success" onClick={this.addRoom}>Start Chat</button>
										<button type="button" className="btn btn-default">Or</button>
										<button type="button" className="btn btn-success" onClick={this.addRoom}>Create Ticket</button>
									</div>
								</div>

							</div>
							<span className={this.props.support_chat_widget === true ? 'btn btn-info openChatBtn chat-closed' : 'btn btn-info openChatBtn chat-open'} onClick={this.openModal}>
								<i className="fal fa-comment" />
								{' '}
Support Chat/Ticket
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	addRoom,
	openSupportChat,
	closeSupportChat,
};

function mapStateToProps(state) {
	return {
		rooms: state.settings_support_tickets.rooms,
		related_installations: state.settings_dealerships.related_installations,
		support_chat_widget: state.settings_support_tickets.support_chat_widget,
		support_chat_status: state.settings_support_tickets.support_chat_status,
	};
}
export default connect(mapStateToProps, mappedActions)(SupportChatWidget);
