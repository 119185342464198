import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import WorkOrderListItem from './WorkOrderListItem';
import AllInputs from '../../../../inputs/AllInputs';
import FileUploadButton from '../../../../inputs/FileUploadButton';
import { addEstimate } from '../../../../actions/apiEstimates';

const estimateFields = ['id', 'Select Vendor', 'Description', 'Action', 'x'];

const allFields = ['vehicle_id', 'ro_number', 'requested_by_uid', 'description', 'estimate',
	'actual', 'ro_status', 'approved_by_uid', 'approved_date', 'declined_by_uid', 'declined_date', 'ro_files',
	'declined_reason', 'ro_email_link_declined', 'ro_email_link_approved'];

class WorkOrderList extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { addEstimateError: '' };
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.tabByName = this.tabByName.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	vehicleProperty(name) {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const value = _.filter(vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	onClick(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_status: 'getapproved',
		};
		this.props.addEstimate(data, 'addEstimateError');
	}

	tabByName(name) {
		const self = this;
		const tab = _.sortBy(_.filter(self.props.all_tabs, o => o.name == name), 'id');
		return tab[0] || [];
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const colObject = _.find(vehicle.vehicle_properties, { key: 'touch up only' }) || [];
		const setdisabled = false;
		return (
			<div className="container">
				<button className="btn btn-sm btn-success float-right space-top-1" onClick={self.onClick}>Add Work Item</button>
				<div className="row">
				<div className={'col-md-3'}>
					<label className="control-label">Certified</label>
					<AllInputs
						value={this.vehicleProperty('certified') || ''}
						type={'userstamp'}
						name={'certified'}
						parentid={this.props.active_vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={colObject.user_name}
						updated_at={colObject.updated_at}
						vehicle={vehicle}
					/>
				</div>

				<div className={'col-md-3'}>
					<label className="control-label">Not Certified</label>
					<AllInputs
						value={this.vehicleProperty('not certified') || ''}
						type={'userstamp'}
						name={'not certified'}
						parentid={this.props.active_vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={colObject.user_name}
						updated_at={colObject.updated_at}
						vehicle={vehicle}
					/>
				</div>

				<div className={'col-md-3'}>
					<label className="control-label">Wholesale</label>
					<AllInputs
						value={this.vehicleProperty('wholesale') || ''}
						type={'userstamp'}
						name={'wholesale'}
						parentid={this.props.active_vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={colObject.user_name}
						updated_at={colObject.updated_at}
						vehicle={vehicle}
					/>
				</div>

				<div className={'col-md-3'}>
					<label className="control-label">Work Complete</label>
					<AllInputs
						value={this.vehicleProperty('work complete') || ''}
						type={'userstamp'}
						name={'work complete'}
						parentid={this.props.active_vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={colObject.user_name}
						updated_at={colObject.updated_at}
						vehicle={vehicle}
					/>
				</div>
				</div>

				<table className="table table-striped">
					<thead className={'thead text-center'}>
						<tr>
							<th className="text-center">RO #</th>
							<th className="text-center">Select Vendor</th>
							<th className="text-center">Description</th>
							<th className="text-center">Estimate</th>
							<th className="text-center">Total</th>
							<th className="text-center">Action</th>
							<th className="text-center">X</th>
						</tr>
					</thead>
					<tbody>

						{vehicle.appraisals.map(estimate => <WorkOrderListItem key={`work_order_list_item_${estimate.id}`} {...this.props} estimate={estimate} vehicle={vehicle} />)}

					</tbody>
				</table>
			</div>

		);
	}
}

const mappedActions = {
	addEstimate,
};

function mapStateToProps(state) {
	return {
		all_tabs: state.settings_site.all_tabs,
		vehicle_columns: state.settings_departments.vehicle_columns,
		current_user: state.current_user,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}

export default connect(mapStateToProps, mappedActions)(WorkOrderList);
