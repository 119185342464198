import _ from 'lodash';
import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const initialState = {
	feed_logs: [],
	feed_errors: [],
	feed_notification_settings: [],
	feed_provider_rules: [],
	feed_providers: [],
	feed_provider_settings: [],
};

export const settings_feed = createReducer(initialState, {
	[types.SET_FEED_LOGS](state = initialState, action) {
		return {
			...state,
			feed_logs: action.feed_logs,
		};
	},
	[types.SET_FEED_PROVIDERS](state = initialState, action) {
		return {
		  ...state,
		  feed_providers: action.feed_providers,
		};
	  },
	  [types.SET_FEED_PROVIDER_RULES](state = initialState, action) {
		return {
		  ...state,
		  feed_provider_rules: action.feed_provider_rules,
		};
	  },
	  [types.SET_FEED_PROVIDER_SETTINGS](state = initialState, action) {
		return {
		  ...state,
		  feed_provider_settings: action.feed_provider_settings,
		};
	  },
	  [types.SET_FEED_NOTIFICATION_SETTINGS](state = initialState, action) {
		return {
		  ...state,
		  feed_notification_settings: action.feed_notification_settings,
		};
	  },
	  [types.SET_FEED_ERRORS](state = initialState, action) {
		return {
		  ...state,
		  feed_errors: action.feed_errors,
		};
	  },
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			feed_logs: [],
			feed_errors: [],
			feed_notification_settings: [],
			feed_provider_rules: [],
			feed_providers: [],
			feed_provider_settings: [],
				};
	},
});

export default 'settings_feed';
