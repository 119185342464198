import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import AllInputs from '../../../../inputs/AllInputs';
import {
	updateVehicle,
	updateVehicleData,
	updateDeleteVehicle,
	deleteVehicle,
} from '../../../../actions/AllUpdates';
import {
	lookupPermission,
	lookupIsAdmin,
} from '../../../../actions/lookups';

class MultipleVins extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			active_photo: '',
			dealership: this.props.active_vehicle.dealership,
			subdomain: this.props.active_vehicle.subdomain,
			trim: this.props.active_vehicle.trim,
			stock: this.props.active_vehicle.stock,
			vin: this.props.active_vehicle.vin,
			year: this.props.active_vehicle.year,
			model: this.props.active_vehicle.model,
			make: this.props.active_vehicle.make,
			color: this.props.active_vehicle.color,
			miles: this.props.active_vehicle.miles,
			stocktype: this.props.active_vehicle.stocktype,
		};
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangeDealership = this.onChangeDealership.bind(this);
		this.onChangeSubdomain = this.onChangeSubdomain.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onStockTypeChangeSelect = this.onStockTypeChangeSelect.bind(this);
		this.onDeleteVehicle = this.onDeleteVehicle.bind(this);
		this.onDeleteVehicle2 = this.onDeleteVehicle2.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		const errorType = 'Edit Vehicle';
		const meta = e.target.field;
		const { value } = e.target;
	}

	onBlur(e) {
		const meta = e.target.name;
		const { value } = e.target;

		this.props.updateVehicle(this.props.active_vehicle.id, meta, value);
	}

	onClick(e) {
		this.setState({ active_photo: e.target.src });
	}

	onDeleteVehicle(e) {
		this.props.deleteVehicle(this.props.active_vehicle.id, this.props.active_vehicle.department_id);
	}

	onDeleteVehicle2(e) {
		this.props.updateDeleteVehicle(this.props.active_vehicle.id, this.props.active_vehicle.department_id);
	}

	onChangeDealership(e) {
		this.setState({ dealership: e.value });
		this.setState({ subdomain: e.value });
		const errorType = 'Change Dealership';

		const data = {
			id: this.props.active_vehicle.id,
			subdomain: e.value,
			dealership: e.value,
		};

		this.props.updateVehicleData(this.props.active_vehicle.id, data);
	}

	onChangeSubdomain(e) {
		this.setState({ subdomain: e.value });
		const errorType = 'Change Subdomain';

		const data = {
			id: this.props.active_vehicle.id,
			subdomain: e.value,
		};

		this.props.updateVehicleData(this.props.active_vehicle.id, data);
	}

	onChangeSelect(e) {
		this.setState({ department_id: e.value });
	}

	onStockTypeChangeSelect(e) {
		const errorType = 'Change stocktype';
		this.setState({ stocktype: e.value });
		const data = {
			id: this.props.active_vehicle.id,
			stocktype: e.value,
		};
		this.props.updateVehicleData(this.props.active_vehicle.id, data, errorType);
	}

	showDepartments() {
		return Object.keys(this.props.departments).map(key => this.props.departments[key]);
	}

	selectRelatedInstallations() {
		const options = this.props.related_installations.map((option) => {
			if (typeof option.softname !== 'undefined') {
				var obj = { value: option.subdomain, label: option.softname };
			} else {
				var obj = { value: option.subdomain, label: option.subdomain };
			}
			return obj;
		});
		return options;
	}

	departmentOptions() {
		const departments = this.showDepartments().map((department) => {
			if (department.department_type !== 'data step') {
				const obj = { value: department.id, label: department.name };
				return obj;
			}
		});
		return departments.filter(e => e);
	}

	stocktypeOptions() {
		const comparisonArray = [{ name: 'U', label: 'Used' },
			{ name: 'N', label: 'New' }, { name: 'W', label: 'Wholesale' },
			{ name: 'I', label: 'In Transit' }, { name: 'P', label: 'Prep' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	render() {
		const vehicle = this.props.active_vehicle;
		var setdisabled = true;
		if (this.props.lookupPermission('vehicle_edit_core') || this.props.lookupIsAdmin()) {
			setdisabled = false;
		}
		var hideDelete = '';
		if (this.props.lookupPermission('vehicle_delete') || this.props.lookupIsAdmin()) {
			hideDelete = <span>
				<button type="button" className="btn btn-warning float-right" onClick={this.onDeleteVehicle2}>Remove Vehicle and Block from Feed</button>
				<button type="button" className="btn btn-danger float-right" onClick={this.onDeleteVehicle}>Delete/Destroy Vehicle</button>
			</span>;
		}

		const starObject = _.find(vehicle.vehicle_properties, { key: 'goldstar' }) || '';
		const greenObject = _.find(vehicle.vehicle_properties, { key: 'greenstar' }) || '';
		const purpleObject = _.find(vehicle.vehicle_properties, { key: 'purplestar' }) || '';
		const straightObject = _.find(vehicle.vehicle_properties, { key: 'straightstar' }) || '';
		const uviObject = _.find(vehicle.vehicle_properties, { key: 'uvistar' }) || '';

		const goldUsers = [14557, 14472, 14560, 14513, 14508, 14511, 14554, 15090, 15180, 14955, 2, 4378];
		const gold = [];

		if (goldUsers.includes(this.props.current_user.user)) {
			gold.push(<div className="col-md-12">
				<div className="form-group">
					<label>Add Gold Star (Watch)</label>
					<AllInputs
						value={starObject.value || ''}
						type={'userstamp'}
						name={'goldstar'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={starObject.user_name}
						updated_at={starObject.updated_at}
						vehicle={vehicle}
					/>

				</div>
			</div>);
		}

		if (this.props.installation.database === 'stanley' && this.props.lookupIsAdmin()) {
			gold.push(<div className="col-md-12">
				<div className="form-group">
					<label>Add Green Star (SAFE)</label>
					<AllInputs
						value={greenObject.value || ''}
						type={'userstamp'}
						name={'greenstar'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={greenObject.user_name}
						updated_at={greenObject.updated_at}
						vehicle={vehicle}
					/>

				</div>
			</div>);

			gold.push(<div className="col-md-12">
				<div className="form-group">
					<label>Add Purple Star (Montway Transport)</label>
					<AllInputs
						value={purpleObject.value || ''}
						type={'userstamp'}
						name={'purplestar'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={purpleObject.user_name}
						updated_at={purpleObject.updated_at}
						vehicle={vehicle}
					/>

				</div>
			</div>);

			gold.push(<div className="col-md-12">
				<div className="form-group">
					<label>Add Straight to Store Star</label>
					<AllInputs
						value={straightObject.value || ''}
						type={'userstamp'}
						name={'straightstar'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={straightObject.user_name}
						updated_at={straightObject.updated_at}
						vehicle={vehicle}
					/>

				</div>
			</div>);

			gold.push(<div className="col-md-12">
				<div className="form-group">
					<label>Add UVI'ed at Metro Star</label>
					<AllInputs
						value={uviObject.value || ''}
						type={'userstamp'}
						name={'uvistar'}
						parentid={vehicle.id}
						updatetype="vehicleproperty"
						setdisabled={setdisabled}
						user_name={uviObject.user_name}
						updated_at={uviObject.updated_at}
						vehicle={vehicle}
					/>

				</div>
			</div>);
		}

		return (
			<div className="container bg-light pad-top-1">
				<div className="card">

					<div className="card-header bg-dark text-white">
						<div>Edit Vehicle Core Information</div>
					</div>

					<div className="card-text edit-vehicle pad-bottom-1">
						<div className="row pad-left-1 pad-right-1">
							{gold}
							<div className="col-md-6">
								<div className="form-group">
									<label>Edit Location</label>

									<Select
										onChange={this.onChangeSubdomain}
										value={this.selectRelatedInstallations().filter(({ value }) => value === this.props.active_vehicle.subdomain)}
										name={'subdomain'}
										options={this.selectRelatedInstallations()}
										className="text-capitalize"
										clearable={false}
										searchable={false}
										menuContainerStyle={{ zIndex: 5 }}
										isDisabled={setdisabled}
										vehicle={vehicle}
									/>

								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Edit Dealership</label>

									<Select
										onChange={this.onChangeDealership}
										value={this.selectRelatedInstallations().filter(({ value }) => value === this.props.active_vehicle.dealership)}
										name={'dealership'}
										options={this.selectRelatedInstallations()}
										className="text-capitalize"
										clearable={false}
										searchable={false}
										menuContainerStyle={{ zIndex: 5 }}
										isDisabled={setdisabled}
										vehicle={vehicle}
									/>

								</div>
							</div>

						</div>
						<div className="container bg-light pad-top-1" />

						<div className="row pad-left-1 pad-right-1">
							<div className="col-md-6">
								<div className="form-group">
									<label>Vin Number</label>
									<AllInputs
										value={this.state.vin || ''}
										type={'text'}
										name={'vin'}
										parentid={vehicle.id}
										updatetype="vehicle"
										setdisabled={setdisabled}
										vehicle={vehicle}
									/>

								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Stock Number</label>
									<AllInputs
										value={this.state.stock || ''}
										type={'text'}
										name={'stock'}
										parentid={vehicle.id}
										updatetype="vehicle"
										setdisabled={setdisabled}
										vehicle={vehicle}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Stock Type</label>
									<Select
										onChange={this.onStockTypeChangeSelect}
										value={this.stocktypeOptions().filter(({ value }) => value.toLowerCase() === this.props.active_vehicle.stocktype.toLowerCase())}
										name={'stocktype'}
										options={this.stocktypeOptions()}
										className="text-capitalize"
										clearable={false}
										searchable={false}
										menuContainerStyle={{ zIndex: 5 }}
										isDisabled={setdisabled}
										vehicle={vehicle}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Year</label>
									<AllInputs
										value={this.state.year || ''}
										type={'text'}
										name={'year'}
										parentid={vehicle.id}
										updatetype="vehicle"
										setdisabled={setdisabled}
										vehicle={vehicle}
									/>
								</div>

							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Make</label>
									<AllInputs
										value={this.state.make || ''}
										type={'text'}
										name={'make'}
										parentid={vehicle.id}
										updatetype="vehicle"
										setdisabled={setdisabled}
										vehicle={vehicle}
									/>
								</div>

							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Model</label>
									<AllInputs
										value={this.state.model || ''}
										type={'text'}
										name={'model'}
										parentid={vehicle.id}
										updatetype="vehicle"
										setdisabled={setdisabled}
										vehicle={vehicle}
									/>
								</div>

							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Trim</label>
									<AllInputs
										value={this.state.trim || ''}
										type={'text'}
										name={'trim'}
										parentid={vehicle.id}
										updatetype="vehicle"
										setdisabled={setdisabled}
										vehicle={vehicle}
									/>
								</div>

							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Miles</label>
									<AllInputs
										value={this.state.miles || ''}
										type={'text'}
										name={'miles'}
										parentid={vehicle.id}
										updatetype="vehicle"
										setdisabled={setdisabled}
										vehicle={vehicle}
									/>
								</div>

							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label>Color</label>
									<AllInputs
										value={this.state.color || ''}
										type={'text'}
										name={'color'}
										parentid={vehicle.id}
										updatetype="vehicle"
										setdisabled={setdisabled}
										vehicle={vehicle}
									/>
								</div>

							</div>

						</div>
					</div>
					<div className="card-footer">
						{hideDelete}
					</div>
				</div>

			</div>
		);
	}
}

const mappedActions = {
	lookupPermission,
	lookupIsAdmin,
	updateVehicle,
	updateVehicleData,
	updateDeleteVehicle,
	deleteVehicle,
};

function mapStateToProps(state) {
	return {
		related_installations: state.settings_dealerships.related_installations,
		current_department: state.settings_nav.department,
		vehicle_tab: state.settings_nav.menus.vehicle_tab,
		edit_vehicle: state.settings_nav.edit_vehicle,
		all_tabs: state.settings_site.all_tabs,
		expand_all: state.settings_nav.menus.expand_all,
		departments: state.settings_departments.dealership_departments,
		all_departments: state.settings_departments.departments,
		current_dealership: state.settings_dealerships.current_dealership,
		installation: state.settings_dealerships.installation,
		vehicle_notes: state.settings_refresh.vehicle_notes,
		active_vehicle: state.settings_refresh.active_vehicle,
		current_user: state.current_user,
	};
}
export default connect(mapStateToProps, mappedActions)(MultipleVins);
