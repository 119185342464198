import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDropdownListsButton from './EditDropdownListsButton';

class EditDropdownLists extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<li className="list-group-item text-capitalize">
				{this.props.name}
				{' '}
				<EditDropdownListsButton {...this.props} column={this.props.column} />
			</li>
		);
	}
}

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps)(EditDropdownLists);
