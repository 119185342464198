import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import EditDepartmentSettings from '../../inputs/EditDepartmentSettings';

class TabEditDepartmentColors extends Component {
	constructor(props) {
		super(props);
		this.state = { };
	}

	render() {
		const self = this;
		const department = self.props.edit_department;

		return (
			<div className="row bg-dark">
					<div className="col-md-3 text-dark">
						<EditDepartmentSettings {...this.props} key={`settings_background_color_${department.id}`} checked={department.background_color} parentid={department.id} name={'background_color'} label={'Background Color'} text_color={department.text_color} background_color={department.background_color} type={'colorpicker'} value={department.background_color} />
					</div>
					<div className="col-md-3">
						<EditDepartmentSettings {...this.props} key={`settings_text_color_${department.id}`} checked={department.text_color} parentid={department.id} name={'text_color'} label={'Text Color'} type={'colorpicker'} background_color={department.background_color} text_color={department.text_color} value={department.text_color} />
					</div>
					<div className="col-md-3">
						<EditDepartmentSettings {...this.props} key={`settings_badge_background_color_${department.id}`} checked={department.badge_background_color} parentid={department.id} name={'badge_background_color'} label={'Badge Background Color'} text_color={department.badge_text_color} background_color={department.badge_background_color} type={'colorpicker'} value={department.badge_background_color} />
					</div>
					<div className="col-md-3">
						<EditDepartmentSettings {...this.props} key={`settings_badge_text_color_${department.id}`} checked={department.badge_text_color} parentid={department.id} name={'badge_text_color'} label={'Badge Text Color'} type={'colorpicker'} background_color={department.badge_background_color} text_color={department.badge_text_color} value={department.badge_text_color} />
					</div>
			</div>

		);
	}
}

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps)(TabEditDepartmentColors);
