import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import LoadingSpinner from '../LoadingSpinner/loadingSpinner';
import SidebarListElement from './SidebarListElement';
import SidebarListButton from './SidebarListButton';
import {
	lookupWorkFlowPropertiesByWorkFlowName,
	lookupWorkFlowDepartment,
} from '../../actions/lookups';

class SidebarList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
	  }

	render() {
		const {work_flows, departments} = this.props;
		const {loading} = this.state;
		if (work_flows.length > 0 && departments.length > 0 && loading === true) {
			  this.setState({ loading: false }, () => {
		  });
		}

		const buttons = [];
		if (this.props.site_map) {
			buttons.push(<SidebarListButton key={'sitemap_button'} title="Indent Sub Menus" name="menu-sitemap" classtype="default" icon={'fas fa-sitemap'} />);
		} else {
			buttons.push(<SidebarListButton key={'sitemap_button2'} title="Align Sub Menus" name="menu-sitemap" classtype="default" icon={'fas fa-sitemap'} />);
		}

		if (this.props.hide_blank) {
			buttons.push(<SidebarListButton key={'sitemap_button3'} title="Hide Empty Queues" name="menu-hideblank" classtype="default" icon={'fas fa-eye-slash'} />);
		} else {
			buttons.push(<SidebarListButton key={'sitemap_button4'} title="Show Empty Queues" name="menu-hideblank" classtype="default" icon={'fas fa-eye'} />);
		}

		buttons.push(<SidebarListButton key={'work_flow_button_a'} title="All Work Flows" name="menu-workflow" classtype="default" icon={'fas fa-chart-network'} text={'A'} workflow="all" />);

		work_flows.map((wf) => {
			let text = '';
			if (wf.abbreviation) {
				text = wf.abbreviation;
			} else {
				text = (wf.name || '').substring(0, 3);
			}
			if (wf.name !== 'all') {
				buttons.push(<SidebarListButton key={`work_flow_button_${wf.id}`} title={`${wf.name} Vehicle Work Flow`} name="menu-workflow" classtype="default" icon={'fas fa-chart-network'} text={text} workflow={wf.name} />);
			}
		});

		if(loading) {
			return (
				<LoadingSpinner loading />
			)
		} else {
			return (
			<ul className="nav flex-column pl-1 nav-side" key={'SidebarList'}>
			<li className={'nav-item'}>

					<div style={{ display: 'block' }} className="btn-group bg-light-grey pad-top-p2 pad-bottom-p2">
						{buttons}
					</div>

				</li>
				{this.props.departments.map((department) => {
					const department_dealership = _.find(department.department_dealerships, { subdomain: this.props.installation.subdomain }) || [];
					const count = department_dealership.vehicle_count >= 0 ? department_dealership.vehicle_count : '...';
					const overdue_1 = department_dealership.overdue_1
					const overdue_2 = department_dealership.overdue_2



					if (department.name === 'sold') {
						return;
					}
					if (this.props.site_map) {
						if (this.props.work_flow !== 'all') {
							if (this.props.hide_blank || this.props.database === 'stanley') {
								if (count > 0 && this.props.lookupWorkFlowDepartment(this.props.work_flow, department.id)) {
									return <SidebarListElement count={count} overdue_1={overdue_1} overdue_2={overdue_2} key={`${department.id}_topmenu_hideblank`} department={department} current_department_id={this.props.current_department.id} />;
								}
							} else if (this.props.lookupWorkFlowDepartment(this.props.work_flow, department.id)) {
								return <SidebarListElement count={count} overdue_1={overdue_1} overdue_2={overdue_2} key={`${department.id}_topmenu_nohide`} department={department} current_department_id={this.props.current_department.id} />;
							}
						} else if (this.props.hide_blank || this.props.database === 'stanley') {
							if (count > 0) {
								return <SidebarListElement count={count} overdue_1={overdue_1} overdue_2={overdue_2} key={`${department.id}_topmenu_hideblank`} department={department} current_department_id={this.props.current_department.id} />;
							}
						} else {
							return <SidebarListElement count={count} overdue_1={overdue_1} overdue_2={overdue_2} key={`${department.id}_topmenu_nohide`} department={department} current_department_id={this.props.current_department.id} />;
						}
					} else if (!department.subgroup) {
						if (this.props.work_flow !== 'all') {
							if (this.props.hide_blank || this.props.database === 'stanley') {
								if (count > 0 && this.props.lookupWorkFlowDepartment(this.props.work_flow, department.id)) {
									return <SidebarListElement count={count} overdue_1={overdue_1} overdue_2={overdue_2} key={`${department.id}_topmenu_hideblank`} department={department} current_department_id={this.props.current_department.id} />;
								}
							} else if (this.props.lookupWorkFlowDepartment(this.props.work_flow, department.id)) {
								return <SidebarListElement count={count} overdue_1={overdue_1} overdue_2={overdue_2} key={`${department.id}_topmenu_nohide`} department={department} current_department_id={this.props.current_department.id} />;
							}
						} else if (this.props.hide_blank || this.props.database === 'stanley') {
							if (count > 0) {
								return <SidebarListElement count={count} overdue_1={overdue_1} overdue_2={overdue_2} key={`${department.id}_topmenu_hideblank`} department={department} current_department_id={this.props.current_department.id} />;
							}
						} else {
							return <SidebarListElement count={count} overdue_1={overdue_1} overdue_2={overdue_2} key={`${department.id}_topmenu_nohide`} department={department} current_department_id={this.props.current_department.id} />;
						}
					}
				})}
			</ul>
			)
		}
	}
}

const mappedActions = {
	lookupWorkFlowDepartment,
	lookupWorkFlowPropertiesByWorkFlowName,
};

function mapStateToProps(state) {
	return {
		site_map: state.settings_nav.menus.site_map,
		expand_all: state.settings_nav.menus.expand_all,
		hide_blank: state.settings_nav.menus.hide_blank,
		work_flow: state.settings_nav.menus.work_flow,
		work_flows: state.settings_departments.work_flows,
		profile: state.current_user.position || '',
		current_department: state.settings_departments.department || {},
		installation: state.settings_dealerships.installation || [],
		database: state.settings_dealerships.installation.database,
	};
}

export default connect(mapStateToProps, mappedActions)(SidebarList);
