import React from 'react';
import classnames from 'classnames';

const TextFieldGroup = ({
	field, value, label, error, type, onChange, onBlur, disabled, placeholder = '', onClick,
}) => (
	<div className={classnames('input-group', { 'has-danger': error })}>
		<input
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			type={type}
			name={field}
			disabled={disabled}
			autoComplete=""
			autoCapitalize="none"
			autoCorrect="off"
			placeholder={placeholder}
			className={classnames('form-control text-left', { 'form-control-danger': error })}
		/>
		<div className="input-group-append">
			<span className="btn btn-sm btn-success" onClick={onClick}>Decode</span>
		</div>
	</div>

);

export default TextFieldGroup;
