import _ from 'lodash';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from './Dropdown';
import SelectMultiple from './DropdownMultiple';
import {
	userStamp,
} from '../utils/dateFunctions';
import {
	addVehicleProperty,
} from '../actions/AllUpdates';
import {
	activeVehicle,
} from '../actions/lookups';
import {
	updateVehicleColumn,
} from '../actions/apiDepartmentUpdates';

class InputTypes extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { value: this.props.value || '', newurl: '' };
		this.onChange = this.onChange.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onChangeCheckedVehicleColumn = this.onChangeCheckedVehicleColumn.bind(this);
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	onChangeChecked(e) {
		this.setState({ value: e.target.checked ? 'true' : '' });
		const self = this;
		var vehicle_id = parseInt(e.target.dataset.vehicleid);

		var errorType = 'updateVehicleError';
		var key = e.target.name;
		var keylowercase = e.target.name.toLowerCase();
		var value = e.target.checked ? 'true' : '';
		var uid = this.props.current_user.user;
		var user_name = this.props.current_user.user_name || this.props.current_user.email;

		var data = {
			vehicle_id,
			key,
			value,
			uid,
			user_name,
		};
		Promise.all([this.props.addVehicleProperty(data, errorType)]).then(() => {
			this.props.activeVehicle(vehicle_id);
		});
	}

	onChangeCheckedVehicleColumn(e) {
		this.setState({ value: !!e.target.checked });
		const self = this;

		const name = e.target.name;
		const id = this.props.rowid;
		var value = !!e.target.checked;
		const errorType = 'editVehicleColumnError';
		const data = {
			id,
			[name]: value,
		};

		this.props.updateVehicleColumn(id, data, errorType);
	}

	render() {
		const { props } = this;
		return (
			(() => {
				switch (props.type) {
				case 'select':
					return <Dropdown
						titleHelper={''}
						title={'Choose'}
						onChange={this.onChange}
						name={props.name}
						value={this.state.value}
						options={props.options}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						data-updatetype={props.updatetype}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						list={props.options}
					/>;

				case 'select-dealerships':
					return <Dropdown
						onChange={this.onChange}
						name={props.name}
						value={this.state.value}
						options={selectRelatedInstallations()}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						clearable={false}
						isDisabled={props.setdisabled}
						menuContainerStyle={{ zIndex: 5 }}
						className="text-capitalize"
					/>;

				case 'text':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className="form-control"
					/>;

				case 'checkbox':
					return <input
						onChange={this.onChangeChecked}
						checked={props.value == 'true' ? 'checked' : ''}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						key={`${props.type}_${props.parentid}_${props.name}`}
					/>;
				case 'checkbox-edit':
					return <span className="switch">
						<input
							onChange={this.onChangeCheckedVehicleColumn}
							checked={this.state.value === true ? 'checked' : ''}
							type={'checkbox'}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							data-vehicleid={props.vehicle_id}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
							key={`${props.type}_${props.parentid}_${props.name}`}
							id={`switch_id_${props.parentid}_${props.name}`}
						/>
						<label className={'text-capitalize'} htmlFor={`switch_id_${props.parentid}_${props.name}`} />
					</span>;

				case 'userstamp':
					var updateDate = userStamp(props.updated_at);
					return <div>
						<input
							onChange={this.onChangeChecked}
							checked={props.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							data-vehicleid={props.vehicle_id}
							disabled={props.setdisabled ? 'disabled' : ''}
						/>
						<br />
						<small className="text-center">{props.value == 'true' ? props.username : ''}</small>
						<br />
						<small className="text-center">{props.value == 'true' ? updateDate : ''}</small>
            </div>;

				case 'userstamp_text':
					var updateDate = userStamp(props.updated_at);
					return <div>
						<input
							onBlur={props.onBlur}
							onChange={this.onChange}
							value={this.state.value}
							type={props.type}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							data-vehicleid={props.vehicle_id}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
							className={classnames('form-control', { 'form-control-danger': props.error })}
						/>
						<small className="text-center">{props.value ? props.username : ''}</small>
						<br />
						<small className="text-center">{props.value ? updateDate : ''}</small>
            </div>;

				case 'automove_check':
					var updateDate = userStamp(props.updated_at);

					return <div>
						<input
							onChange={this.onChangeChecked}
							checked={props.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							data-vehicleid={props.vehicle_id}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
						/>
						<br />
						<small className="text-center">{props.value == 'true' ? props.username : ''}</small>
						<br />
						<small className="text-center">{props.value == 'true' ? updateDate : ''}</small>
            </div>;

				case 'switch':
					return <span className="switch">
						<input
							onChange={this.onChangeChecked}
							checked={props.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
							id={`switch_id_${props.parentid}_${props.name}`}
						/>
						<label className={'text-capitalize'} htmlFor={`switch_id_${props.parentid}_${props.name}`}>{props.name.replace(/_/g, ' ')}</label>
					</span>;

				case 'switch-no-label':
					return <span className="switch">
						<input
							onChange={this.onChange}
							checked={props.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
							id={`switch_id_${props.parentid}_${props.name}`}
						/>
						<label className={'text-capitalize'} htmlFor={`switch_id_${props.parentid}_${props.name}`} />
					</span>;

				case 'switch-userstamp':
					var updateDate = userStamp(props.updated_at);

					return <div className="switch">
						<input
							onChange={this.onChange}
							checked={props.value == 'true' ? 'checked' : ''}
							type={'checkbox'}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
							id={`switch_id_${props.parentid}_${props.name}`}
						/>
						<label className={'text-capitalize'} htmlFor={`switch_id_${props.parentid}_${props.name}`} />
						<div className="col-md-12">
							<small>{props.value == 'true' ? props.username : ''}</small>
							<br />
							<small>{props.value == 'true' ? updateDate : ''}</small>
						</div>
            </div>;

				case 'switch-needed':
					var updateDate = userStamp(props.updated_at);
					return <table className="table table-striped">
						<thead>
							<tr>
								<th />
								<th>Needed</th>
								<th>Completed</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th className="text-capitalize desk-w-30">{props.name}</th>
								<td className="text-right desk-w-30">
									<div className="switch">
										<input
											onChange={props.onChange}
											checked={props.value == 'needed' ? 'checked' : ''}
											type={'checkbox'}
											name={props.name}
											data-id={props.rowid}
											data-parentid={props.parentid}
											disabled={props.setdisabled ? 'disabled' : ''}
											placeholder={props.placeholder}
											id={`switch_id_${props.parentid}_${props.name}`}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id_${props.parentid}_${props.name}`} />
										<div className="col-md-12 text-center">
											<small>{props.value == 'needed' ? props.username : ''}</small>
											<br />
											<small>{props.value == 'needed' ? updateDate : ''}</small>
										</div>
									</div>

								</td>
								<td className="text-right">
									<div className="switch">
										<input
											onChange={props.onChange}
											checked={props.value == 'completed' ? 'checked' : ''}
											type={'checkbox'}
											name={props.name}
											data-id={props.rowid}
											data-parentid={props.parentid}
											disabled={props.setdisabled ? 'disabled' : ''}
											placeholder={props.placeholder}
											id={`switch_id2_${props.parentid}_${props.name}`}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id2_${props.parentid}_${props.name}`} />
										<div className="col-md-12 text-center">
											<small>{props.value == 'completed' ? props.username : ''}</small>
											<br />
											<small>{props.value == 'completed' ? updateDate : ''}</small>
										</div>
									</div>

								</td>
							</tr>
						</tbody>
            </table>;

				case 'switch-needed-nolabel':
					var updateDate = userStamp(props.updated_at);

					return <table className="table table-striped">
						<thead>
							<tr>
								<th>Needed</th>
								<th>Completed</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="switch">
										<input
											onChange={this.onChange}
											checked={props.value == 'needed' ? 'checked' : ''}
											type={'checkbox'}
											name={props.name}
											data-id={props.rowid}
											data-parentid={props.parentid}
											disabled={props.setdisabled ? 'disabled' : ''}
											placeholder={props.placeholder}
											id={`switch_id_${props.parentid}_${props.name}`}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id_${props.parentid}_${props.name}`} />
										<div className="col-md-12 text-center">
											<small>{props.value == 'needed' ? props.username : ''}</small>
											<br />
											<small>{props.value == 'needed' ? updateDate : ''}</small>
										</div>
									</div>

								</td>
								<td>
									<div className="switch">
										<input
											onChange={this.onChange}
											checked={props.value == 'completed' ? 'checked' : ''}
											type={'checkbox'}
											name={props.name}
											data-id={props.rowid}
											data-parentid={props.parentid}
											disabled={props.setdisabled ? 'disabled' : ''}
											placeholder={props.placeholder}
											id={`switch_id2_${props.parentid}_${props.name}`}
										/>
										<label className={'text-capitalize'} htmlFor={`switch_id2_${props.parentid}_${props.name}`} />
										<div className="col-md-12 text-center">
											<small>{props.value == 'completed' ? props.username : ''}</small>
											<br />
											<small>{props.value == 'completed' ? updateDate : ''}</small>
										</div>
									</div>

								</td>
							</tr>
						</tbody>
            </table>;

				case 'age':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'approved':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'color':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'cost':
					return <div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text">$</span>
						</div>
						<input
							onBlur={props.onBlur}
							onChange={this.onChange}
							value={this.state.value}
							type={props.type}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
							className={classnames('form-control', { 'form-control-danger': props.error })}
						/>
            </div>;

				case 'date':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'days':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'department_extern':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'displayonly':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'dollar':
					return <div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text">$</span>
						</div>
						<input
							onBlur={props.onBlur}
							onChange={this.onChange}
							value={this.state.value}
							type={'number'}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
							className={classnames('form-control', { 'form-control-danger': props.error })}
						/>
					</div>;

				case 'extern':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'external':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'estimate_files':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'icon':
					return <div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text"><i className={`fa fa-${icon}`} /></span>
						</div>
						<input
							onBlur={props.onBlur}
							onChange={this.onChange}
							value={this.state.value}
							type={props.type}
							name={props.name}
							data-id={props.rowid}
							data-parentid={props.parentid}
							disabled={props.setdisabled ? 'disabled' : ''}
							placeholder={props.placeholder}
							className={classnames('form-control', { 'form-control-danger': props.error })}
						/>
            </div>;

				case 'month':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'number':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'radio':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'range':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'textarea':
					return <textarea
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				case 'textarea-big':
					return <textarea
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
						cols={50}
						rows={5}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
					/>;

				case 'thumbnail':
					return <img
						src={props.value}
						className={'img-thumbnail'}
					/>;

				case 'time':
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;

				default:
					return <input
						onBlur={props.onBlur}
						onChange={this.onChange}
						value={this.state.value}
						type={props.type}
						name={props.name}
						data-id={props.rowid}
						data-parentid={props.parentid}
						data-vehicleid={props.vehicle_id}
						disabled={props.setdisabled ? 'disabled' : ''}
						placeholder={props.placeholder}
						className={classnames('form-control', { 'form-control-danger': props.error })}
					/>;
				}
			})()

		);
	}
}

const mappedActions = {
	addVehicleProperty,
	activeVehicle,
	updateVehicleColumn,
};

function mapStateToProps(state) {
	return { current_user: state.current_user };
}
export default connect(mapStateToProps, mappedActions)(InputTypes);
