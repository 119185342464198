import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroupNoLabel';
import { addNewDepartment, deleteDepartment } from '../../actions/apiDepartmentUpdates';
import { change_page } from '../../actions/toggleMenu';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
};

class AddDepartmentButton extends Component {
	constructor(props) {
		super(props);

		this.state = { modalIsOpen: false, newdepartment: '', errors: { addDepartmentError: false } };
		this.onChange = this.onChange.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.addNewDepartment = this.addNewDepartment.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	deleteDepartment(e) {
		this.props.deleteDepartment(e.target.dataset.id);
		this.props.change_page('set-departments');
	}

	addNewDepartment() {
		this.props.addNewDepartment('name', this.state.newdepartment, 'addDepartmentError').then((resp) => {
			if (typeof resp !== 'undefined' && typeof resp.errors !== 'undefined') {
				const { errors } = resp;
				this.setState({ errors, addDepartmentError: true });
			} else {
				this.setState({ errors: { addDepartmentError: false } });
				this.setState({ newdepartment: '' });
				this.closeModal();
			}
		});
	}

	render() {
		const { errors } = this.state;
		return (
			<span>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					className="d-flex justify-content-center"
					contentLabel="Add Department"
					ariaHideApp={false}
					overlayClassName="overlay"
				>

					<div className="col-md-6 card bg-dark mx-auto">
						<div className="card-header text-white">
                      Add Department
							{' '}
							<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>x</button>
						</div>
						<div className="card-block bg-white">

							<TextFieldGroup
								field="newdepartment"
								label="Add Department Name"
								onChange={this.onChange}
								value={this.state.newdepartment}
								type="text"
								error={errors.addDepartmentError}
							/>
						</div>
						<div className="card-footer">
							<button className="btn btn-sm btn-success" onClick={this.addNewDepartment}>
								<i className="fa fa-plus" />
								{' '}
Add
							</button>
						</div>
					</div>
				</Modal>
				<button className="btn btn-sm btn-success" onClick={this.openModal}>
					<i className="fa fa-plus" />
					{' '}
Add Department
				</button>
			</span>
		);
	}
}

const mappedActions = {
	addNewDepartment,
	deleteDepartment,
	change_page,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.original_departments,
	};
}
export default connect(mapStateToProps, mappedActions)(AddDepartmentButton);
