import React, { Component } from 'react';
import { connect } from 'react-redux';
import SidebarList from './SidebarList';

class Sidebar extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const hideArray = ['support-tickets', 'support-center', 'live-chats', 'email-reports', 'notifications', 'color-settings', 'site-settings', 'dealer-settings', 'reports', 'profile', 'users', 'department-setup', 'estimates', 'dashboard', 'messages', 'user-single', 'addons', 'automatic-moves', 'edit-columns', 'tab-settings', 'tab-settings-edit', 'step-workflows', 'working-hours', 'dealer-forms', 'site-settings', 'goals', 'calendar', 'incoming-step-defaults', 'report-defaults', 'audits', 'feed-center'];
		return (
			<div className={((hideArray.includes(this.props.page) || (this.props.sidebar_status === false)) ? 'no-display' : 'col-md-4 col-lg-3 sidebar-offcanvas pad-left-0 pad-right-0')} key="sidebar" id="sidebar" role="navigation">
				<div className="sidebar">
					<SidebarList key="sidebar-list" departments={this.props.departments} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		page: state.settings_nav.page,
		sidebar_status: state.settings_nav.menus.sidebar_status,
		departments: state.settings_departments.allowed_departments,
	};
}
export default connect(mapStateToProps)(Sidebar);
