import React, { Component } from 'react';
import { connect } from 'react-redux';

const AddDepartmentFilterButton = ({ name, onClick }) => (
	<button className="btn btn-sm btn-success space-right-2" name={name} onClick={() => onClick(name)}>
		<i className="fa fa-arrow-left float-left " name={name} />
		{' '}

	</button>
);

export default AddDepartmentFilterButton;
