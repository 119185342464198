import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
	lookupSupportTicketById, lookupUserById, lookupDealershipById, lookupSupportTicketsByUserId, lookupUserNameById,
} from '../../actions/lookups';
import {
	readableStamp,
} from '../../utils/dateFunctions';
import DeleteSupportTicketButton from './DeleteSupportTicketButton';
import AddSupportTicket from './AddSupportTicket';
import SupportMenu from './SupportMenu';

class SupportVideos extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expandedRows: [],
		};
	}

	onPrintButtonClick() {
		const self = this;
		const print_window = window.open('', 'newWin',
			'width=1920,height=900,toolbar=yes,locationbar=1,directories=1,statusbar=1,menubar=1,scrollbar=1,resizable=yes,titlebar=1');

		const topDoc = '<html><head><title>Pilson Report Walk-Through</title></head>';
		const endDoc = '</body></html>';
		const style_tag = '<style> table {border-collapse: collapse;}'
            + 'td { border: 1px solid black; padding: 7px; } '
            + 'tr { border: 1px solid green; padding-top: 5px;} </style>';

		print_window.document.write(topDoc, style_tag, '<body>');

		print_window.document.write("<video width='1920' height='906' controls><source src='https://cdn.ipacketrecon.com/recon_images/pilson_reports.mp4' type='video/mp4' />Your browser does not support the video tag.</video>");
		print_window.document.write(endDoc);
	}

	render() {
		const self = this;

		return (
			<div className="col-md-12">
				<div className="card space-top-0">

					<h3 className="text-center pad-top-1">Support Videos</h3>
					<p className="text-center">Please use the below videos for reference. If you would like for us to make any additional videos for reference please just let us know and we will create one. Thanks!</p>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">
						<SupportMenu />
					</div>
					<button className="btn btn-success" onClick={this.onPrintButtonClick}>Pilson Reports Walk-Through</button>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupSupportTicketById,
	lookupSupportTicketsByUserId,
	lookupUserById,
	lookupDealershipById,
	readableStamp,
	lookupUserNameById,
};

function mapStateToProps(state) {
	return {
		softname: state.settings_dealerships.installation.softname || '',
		support_tickets: state.settings_support_tickets.support_tickets || [],
		rooms: state.settings_support_tickets.rooms || [],
		room_messages: state.settings_support_tickets.room_messages || [],
		current_user: state.current_user,
	};
}
export default connect(mapStateToProps, mappedActions)(SupportVideos);
