import React, { Component } from 'react';
import { connect } from 'react-redux';

class InRecon extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	render() {
		return (
			<div className="col-md-12">

				<div className="card space-top-0">
			
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		departments: state.settings_departments.original_departments,
	 };
}
export default connect(mapStateToProps)(InRecon);