import shortid from 'shortid';
import * as types from './types';
import * as setInfo from './apiSetInfo';
import * as AllMutates from './AllMutates';

export function deleteFlashMessages() {
	return {
		type: types.DELETE_FLASH_MESSAGES,
	};
}

export function deleteFlashMessage(id) {
	return {
		type: types.DELETE_FLASH_MESSAGE,
		id,
	};
}

export function addFlashMessage(oldmessage) {
	const message = oldmessage;
	message.id = shortid.generate();
	return {
		type: types.ADD_FLASH_MESSAGE,
		message,
	};
}

export function toggleReportBar() {
	return {
		type: types.TOGGLE_REPORT_BAR,
	};
}
export function toggleReportNav() {
	return {
		type: types.TOGGLE_REPORT_NAV,
	};
}
export function openReportNav() {
	return {
		type: types.OPEN_REPORT_NAV,
	};
}
export function closeReportNav() {
	return {
		type: types.CLOSE_REPORT_NAV,
	};
}
export function change_page(page) {
	return (dispatch) => {
		dispatch(update_page(page));
	};
}
export function change_page_mobile(page) {
	return (dispatch) => {
		dispatch(update_page(page));
		dispatch(closeNav());
		dispatch(closeSidebar());
	};
}

export function update_page(page) {
	return {
		type: types.CHANGE_CURRENT_PAGE,
		page,
	};
}
export function closeNav() {
	return {
		type: types.CLOSE_NAV,
	};
}
export function closeSidebar() {
	return {
		type: types.CLOSE_SIDEBAR,
	};
}

export function toggleNav() {
	return {
		type: types.TOGGLE_NAV,
	};
}

export function toggleSidebar() {
	return {
		type: types.TOGGLE_SIDEBAR,
	};
}

export function toggleSidebarRight() {
	return {
		type: types.TOGGLE_SIDEBAR_RIGHT,
	};
}

export function toggleExpandAll() {
	return {
		type: types.TOGGLE_EXPAND_ALL,
	};
}

export function toggleExpandAllPlus() {
	return (dispatch) => {
		dispatch(setInfo.setVehicleTab('vehicle-body', 0));
		dispatch(toggleExpandAll());
	};
}

export function toggleHideBlank() {
	return {
		type: types.TOGGLE_HIDE_BLANK,
	};
}

export function toggleSortDescending() {
	return {
		type: types.TOGGLE_SORT_DESCENDING,
	};
}

export function toggleSiteMap() {
	return {
		type: types.TOGGLE_SITE_MAP,
	};
}

export function toggleWorkFlow(work_flow) {
	return {
		type: types.SET_WORK_FLOW,
		work_flow,
	};
}

export function toggleAddFilter(show_add_filter) {
	return {
		type: types.SHOW_ADD_FILTER,
		show_add_filter,
	};
}
