import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { deleteAudit } from '../../actions/apiAudits';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
	content: {
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
	},
};

class DeleteAuditButton extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	onDelete() {
		this.props.deleteAudit(this.props.audit_id, 'deleteAudit');
		this.closeModal();
	}

	render() {
		const { errors } = this.state;
		return (
			<span>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					style={customStyles}
					contentLabel="Delete Audit"
					newdepartment={this.state.newdepartment}
					ariaHideApp={false}
				>

					<div className="card card-warning">
						<div className="card-header text-white">
                  Delete Audit
							{' '}
							<button className="btn btn-sm btn-danger float-right" onClick={this.closeModal}>x</button>
						</div>
						<div className="card-block bg-white text-center">
							<h3 className="pad-left-1 pad-right-1 pad-top-1 pad-bottom-1">Are You Sure you Want to Delete?</h3>
						</div>
						<div className="card-footer">
							<button className="btn btn-sm btn-danger float-left" onClick={this.closeModal}>Cancel</button>
							<button className="btn btn-sm btn-danger float-right" onClick={this.onDelete}>
								<i className="fas fa-trash-alt" />
								{' '}
Delete Audit
							</button>
						</div>
					</div>
				</Modal>
				<button className="btn btn-sm btn-danger float-right" onClick={this.openModal}><i className="fas fa-trash-alt" /></button>
			</span>
		);
	}
}

const mappedActions = {
	deleteAudit,
};

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps, mappedActions)(DeleteAuditButton);
