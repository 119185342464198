import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ReactDropzone from 'react-dropzone';
import DropList from './DropList';
import {
	editUserProfile,
} from '../../actions/users';

class DropBoxUser extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', checked: this.props.checked || '', file: '', image: '', image_name: '', image_type: '', image_size: '', files: [],
		};
		this.onClick = this.onClick.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
	}

	onDrop(files) {
		this.setState({
			files,
		});
	}

	onClick() {
		const { files } = this.state;

		files.map((file) => {
			this.fileUpload(file);
		});
		this.setState({
			files: [],
		});
	}

	onClickDelete(name) {
		const newFiles = this.state.files.filter(el => el.name !== name);
		this.setState({
			files: newFiles || [],
		});
	}

	uploadData(response, fileid) {
		const fileurl = response.data;

		const self = this;
		const errorType = 'updateAvatar';
		const data = {
			avatar: fileurl,
		};

		self.props.editUserProfile(self.props.user, data, errorType);
	}

	fileUpload(file) {
		const url = "https://gotoreconfiles.com/cors_new_server.php";

		const self = this;
		const { vid } = this.props;
		const { siteid } = this.props;
		const { user_name } = this.props;
		const fileid = file.name;

		const data = new FormData();
		data.append('data', file);
		data.append('action', 'put-newserver-file');
		data.append('fileid', fileid);
		data.append('siteid', siteid);

		return axios.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},

		}).then(response => this.uploadData(response, fileid));
	}

	render() {
		return (
			<div className="row">
				<div className="col-md-12">
					<div className="col-md-12">
						<aside>
							<div className="card-header text-white text-capitalize">
								{
									this.state.files.length > 0 ? <button onClick={this.onClick} className="btn btn-success form-control">
										<i className={'fas fa-cloud-upload'} />
										{' '}
Upload Photo/Avatar
										{' '}
										<i className={'fas fa-cloud-upload'} />
                                       </button> : ''
								}
							</div>

							<ul className="list-group">
								{
									this.state.files.map(f => <DropList {...this.props} f={f} key={`dropped_file_${f.name}`} onClickDelete={this.onClickDelete} />)
								}
							</ul>

						</aside>
					</div>
					<div className="dropzone">
						<div className="card-header bg-grey text-white text-capitalize">
							<button className="btn btn-dark form-control">Drop/Add Files</button>
						</div>
						<ReactDropzone className="drop-box p-2 text-center space-top-1" onDrop={this.onDrop.bind(this)}>
							{({ getRootProps, getInputProps }) => (
								<section>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<p className="text-center">Drop Files Here or Click to Browse for Files</p>
									</div>
								</section>
							)}
						</ReactDropzone>
					</div>
				</div>

			</div>
		);
	}
}
const mappedActions = {
	editUserProfile,
};
function mapStateToProps(state) {
	return { user_name: state.current_user.user_name };
}

export default connect(mapStateToProps, mappedActions)(DropBoxUser);
