import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
	content: {
		top: '30%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
	},
};

const DeleteWorkFlowButton = ({ disabled, onClick }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = useCallback(() => setIsModalOpen(true), []);
	const closeModal = useCallback(() => setIsModalOpen(false), []);
	const onDelete = useCallback(() => {
		onClick();
		closeModal();
	}, [onClick]);
	return (
		<>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel="Delete Workflow"
				ariaHideApp={false}
			>

				<div className="card card-warning">
					<div className="card-header text-white bg-darkgrey">
                  		Delete Workflow
						{' '}
						<button className="btn btn-sm btn-danger float-end" onClick={closeModal}>x</button>
					</div>
					<div className="card-block bg-white text-center">
						<h3>Are You Sure you Want to Delete?</h3>
					</div>
					<div className="card-footer">
						<button className="btn btn-sm btn-danger float-start" onClick={closeModal}>Cancel</button>
						<button className="btn btn-sm btn-warning float-end" onClick={onDelete}>
							<i className="fas fa-trash-alt" />
							{' '}
Delete Workflow
						</button>
					</div>
				</div>
			</Modal>
			<button disabled={disabled} className="btn btn-danger input-group-text" onClick={openModal}>
				<i className="fal fa-minus-square" />
				Delete workflow
			</button>
		</>
	);
};
export default DeleteWorkFlowButton;
