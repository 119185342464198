import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import RecallSingle from './RecallSingle';

class Recalls extends PureComponent {
	render() {
		const vehicle = this.props.active_vehicle;
		const recall_checked_on = _.find(vehicle.vehicle_properties, { key: 'recall_checked' }) || '';

		return (
			<div className="card">
				<div className="card-header text-center">All vehicles are checked upon entry into the system</div>
				<div className="card-text">
					<div className="d-flex flex-wrap">
						<h3 className="float-left text-bold">
Recalls checked on:
							{recall_checked_on.value}
						</h3>
						{vehicle.recalls.map(recall => <RecallSingle key={`recall_single_${recall.id}`} recall={recall} vehicle={vehicle} />)}

					</div>

				</div>
				<div className="card-footer" />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps)(Recalls);
