import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import ButtonWithIcon from '../../inputs/ListWithIcon';
import TimeDashboard from './TimeDashboard.js';
import Logo from '../../assets/images/ipacket_logo.png';
import {
	setDealership,
	resetRefresh,
	resetNav,
	resetAdmin,
	resetSite,
	setCurrentReportTab,
	setReportWorkFlows,
	setCurrentReport,
	setReportTimeRange,
} from '../../actions/apiSetInfo';
import {
	getUser,
} from '../../actions/apiGetInfo';
import {
	change_page,
	toggleNav,
	toggleSidebar,
	toggleSidebarRight,
} from '../../actions/toggleMenu';
import {
	logout,
} from '../../actions/authentication';
import {
	lookupPermission,
	lookupWorkFlowIdByName,
} from '../../actions/lookups';

import './header.scss'

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.change_page = this.change_page.bind(this);
		this.logout = this.logout.bind(this);
		this.onClickReport = this.onClickReport.bind(this);
	}

	dealerSettingValue(name) {
		const value = _.sortBy(_.filter(this.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	logout(e) {
		logout(true)
		// this.props.logout();
		// const { history } = this.props;
		//
		// clearInterval(this.props.interval_number);
		// setTimeout(() => {
		// 	this.props.setDealership('');
		// 	this.props.resetRefresh();
		// 	this.props.resetNav();
		// 	this.props.resetAdmin();
		// 	this.props.resetSite();
		// }, 2000);
		// window.location.reload(true);
	}

	change_page(e) {
		if (e == 'dealerships') {
			clearInterval(this.props.interval_number);
			setTimeout(() => {
				this.props.setDealership('');
				this.props.resetRefresh();
				this.props.resetNav();
				this.props.resetAdmin();
				this.props.resetSite();
				this.props.getUser();
			}, 2000);
			window.location.reload(true);
		} else if (e == 'reports') {
			this.props.setCurrentReportTab('Reports');
			this.props.change_page(e);
		} else {
			this.props.change_page(e);
		}
	}

	onClickReport(e) {
		const report_work_flows = [];
		const work_flow_id = e.target.dataset.id;
		report_work_flows.push(work_flow_id);
		this.props.setReportWorkFlows(report_work_flows.join());
		this.props.setCurrentReport('WorkFlow Averages');
		this.props.setCurrentReportTab('Reports');
		this.props.setReportTimeRange(e.target.dataset.timerange);
		this.props.change_page('reports');
	}

	render() {
		// const bg = this.dealerSettingValue('color_header') || '#313a46';
		const headerStyle = {
			backgroundColor: 'white',
			background: 'white',
			color: 'rgb(60,60,60)'
		};

		const buttons = [];
		if (this.props.current_user.position == 'super_admin') {
			buttons.push(<ButtonWithIcon  name="dashboard" key={'dashboard'} label="Dashboard" icon="fas fa-tachometer-alt" onClick={this.change_page} />);
		} else if (this.props.current_user.position == 'admin') {
			buttons.push(<ButtonWithIcon name="dashboard" key={'dashboard'} label="Dashboard" icon="fas fa-tachometer-alt" onClick={this.change_page} />);
		} else if (this.props.lookupPermission('page_dashboard')) {
			buttons.push(<ButtonWithIcon name="dashboard" key={'dashboard'} label="Dashboard" icon="fas fa-tachometer-alt" onClick={this.change_page} />);
		}

		buttons.push(<ButtonWithIcon  name="vehicles" key={'vehicles'} label="Vehicles" icon="far fa-car" onClick={this.change_page} />);
		let logo = '';

		if (this.dealerSettingValue('live_chat') === 'true') {
			buttons.push(<ButtonWithIcon  name="chat" key={'chat'} label="Chat" icon="far fa-comments" onClick={this.props.toggleSidebarRight} />);
		}

		if (this.dealerSettingValue('support-center') === 'true') {
			if (this.props.installation.database === 'pilson') {
				buttons.push(<ButtonWithIcon  name="support-videos" key={'support-videos'} label="Support" icon="fas fa-user-headset" onClick={this.change_page} />);
			} else {
				buttons.push(<ButtonWithIcon  name="support-center" key={'support-center'} label="Support" icon="fas fa-user-headset" onClick={this.change_page} />);
			}
		}

		// if (this.props.installation.logo) {
		// 	logo = <img
		// 		key="toplogo2"
		// 		src={this.props.installation.logo}
		// 		name="dealerships"
		// 		onClick={this.change_page.bind(this)}
		// 		style={{
		// 			maxHeight: '30px', maxWidth: '150px', marginLeft: '10px', marginTop: '10px',
		// 		}}
		// 	/>;
		// }

		var timedashboard = <TimeDashboard />;

		const legend = (
			<table
				style={{
					fontSize: '40%', fontWeight: 'bold', background: 'white', color: 'black', width: '300px', marginTop: '5px', marginRight: '50px',
				}}
				className="table-bordered"
			>
				<tbody>
					<tr>
	            	<th style={{
							width: '125px',
						}}
	            	>
Loaner Car
              </th>
	           		<td style={{
							backgroundColor: '#F1D592', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>

	            	<th style={{
							width: '125px',
						}}
	            	>
Aged Transfer
              </th>
	           		<td style={{
							backgroundColor: '#FF6666', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>
					</tr>
	           	<tr>
	            	<th>Cust/Repo/Non-Inventory</th>
	           		<td style={{
							backgroundColor: '#cc9966', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>

	            	<th>DEMO</th>
	           		<td style={{
							backgroundColor: '#e00043', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>
	           	</tr>
	           	<tr>
	            	<th>SAFE</th>
	           		<td style={{
							backgroundColor: '#ffff55', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>

	            	<th>Liquidation</th>
	           		<td style={{
							backgroundColor: '#ff671f', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>
	           	</tr>
	           	<tr>
	            	<th>SSG Inspect</th>
	           		<td style={{
							backgroundColor: '#79f1fb', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>

	            	<th>Purchase UVI</th>
	           		<td style={{
							backgroundColor: '#009fe0', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>
	           	</tr>
	           	<tr>
	            	<th>Trade UVI</th>
	           		<td style={{
							backgroundColor: '#68fd7b', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>

	            	<th>New</th>
	           		<td style={{
							backgroundColor: '#c71585', height: '5px', marginTop: '3px', width: '20px',
						}}
	           		/>
	           	</tr>
				</tbody>
			</table>

		);

		return (

			<div className="site-header-container">
				<div className="site-header">
					<div className="site-header-left">
						<div className="site-header-logo">
							<img className="site"key="toplogo" src={Logo} />
						</div>
					</div>
					<div className="site-header-center">
						<div className="site-header-reporting">
							{this.props.softname.includes('stanley') ? legend : ''}
							{ timedashboard }
						</div>
					</div>
					<div className="site-header-right">
							{/*
							<div style={headerStyle} className="site-header container-fluid pad-left-1">

								<button className="navbar-dark navbar-toggler navbar-toggler-right" type="button" onClick={this.props.toggleNav}>
									<span className="navbar-toggler-icon" />
								</button>
								<button type="button" className="navbar-dark hidden-md-up navbar-toggler float-left" onClick={this.props.toggleSidebar}>
									<span className="navbar-toggler-icon" />
								</button>


							</div>
							*/}

						<ul className="site-header-nav-links">
							<ButtonWithIcon name="dealerships" key={'dealerships'} label="Dealerships" icon="far fa-home" onClick={this.change_page} />
							{buttons}
							<ButtonWithIcon name="logout" key={'logout'} label="Logout" icon="far fa-power-off" onClick={this.logout} />
						</ul>
					</div>
				</div>
			</div>

		);
	}
}

const mappedActions = {
	setDealership,
	resetRefresh,
	resetNav,
	resetAdmin,
	resetSite,
	setCurrentReportTab,
	setReportTimeRange,
	getUser,
	change_page,
	toggleSidebar,
	toggleSidebarRight,
	toggleNav,
	logout,
	lookupPermission,
	setReportWorkFlows,
	setCurrentReport,
	lookupWorkFlowIdByName,
};

function mapStateToProps(state) {
	return {
		nav_status: state.settings_nav.menus.nav_status,
		report_menu_status: state.settings_nav.menus.report_menu_status,
		auth: state.current_user.isAuthenticated,
		current_user: state.current_user,
		update_status: state.update_status,
		dealer_settings: state.settings_site.dealer_settings,
		interval_number: state.settings_nav.interval_number,
		installation: state.settings_dealerships.installation,
		softname: state.settings_dealerships.installation.softname || '',
		report_department_data: state.settings_reports.report_department_data,
		departments: state.settings_departments.departments,
		inactive_status: state.settings_nav.inactive_status,
	};
}
export default connect(mapStateToProps, mappedActions)(Header);
