import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';

import {
	findDealership,
} from '../../actions/lookups';


function SearchDealer(props){
	const [searchField, setSearchField] = useState('');
	const handleUpdateSearchField = (value) =>{
		setSearchField(value)
		props.findDealership(value.toLowerCase())
	}
	return (
		<div className="input-group pad-left-2">
			<input type="text" className="form-control" placeholder="Search Dealerships" onChange={(e)=>{handleUpdateSearchField(e.target.value)}} name="search" value={searchField} />
			<div className="input-group-append btn-dark">
				<span className="input-group-text">
					{' '}
					<i className="fa fa-search" />
				</span>
			</div>
			<div className="input-group-append btn-dark" onClick={()=>{handleUpdateSearchField('')}} title="clear search">
				<span className="input-group-text clearSearch">
					{' '}
					<i className="far fa-ban" />
				</span>

			</div>
		</div>
	);
}
const mappedActions = {
	findDealership,
};

function mapStateToProps(state) {
	return {
		current_department: state.settings_departments.department,
		dealerships: state.settings_dealerships.set_dealerships,
	};
}
export default connect(mapStateToProps, mappedActions)(SearchDealer);
