import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AllInputs from '../../../../inputs/AllInputs';
import DollarInput from '../../../../inputs/DollarInput';
import TextFieldGroup from '../../../../inputs/TextFieldGroupNoLabel';
import Select from '../../../../inputs/Dropdown';
import {
	addVehicleProperty,
	updateVehicleDepartment,
} from '../../../../actions/AllUpdates';
import {
	getCurrentDepartment,
} from '../../../../actions/apiGetInfo';
import DropBoxEstimate from '../../../../inputs/FileUploadButton/DropBoxEstimate';
import {
	getDateTime,
	readableStamp,
} from '../../../../utils/dateFunctions';
import {
	deleteEstimate,
	sendEstimate,
} from '../../../../actions/apiEstimates';
import {
	appraisalRequestNotification,
	appraisalNotification,
} from '../../../../actions/apiNotifications';
import {
	lookupUserById,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	vsearch,
} from '../../../../actions/lookups';
import FileAttachmentSingle from './FileAttachmentSingle';

const estimateFields = [{ name: 'ro_number', label: 'Expense ID', type: 'text' },
	{ name: 'type_item_4', label: 'Ref#', type: 'text' },
	{ name: 'type_item_2', label: 'Company', type: 'text' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'actual', label: 'Amount', type: 'dollar' },
	{ name: 'type_item_3', label: 'Service Date', type: 'text' }];

const classicArray = [15143, 16596];

class Expense extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addDeleteEstimateError: '',
			denied_reason: false,
			vehicle_id: this.props.active_vehicle.id,
			ro_number: this.props.estimate.ro_number,
			description: this.props.estimate.description,
			estimate: this.props.estimate.estimate,
			actual: this.props.estimate.actual,
			type_item_1: this.props.estimate.type_item_1,
			type_item_2: this.props.estimate.type_item_2,
			type_item_3: this.props.estimate.type_item_3,
			type_item_4: this.props.estimate.type_item_4,
			expense_post_date: this.props.estimate.approved_date,
		};
		this.onChange = this.onChange.bind(this);
	}

	componentDidMount() {
		const estimate = this.props.estimate;

		this.setState({
			addDeleteEstimateError: '',
			denied_reason: false,
			vehicle_id: this.props.active_vehicle.id,
			ro_number: estimate.ro_number,
			description: estimate.description,
			estimate: estimate.estimate,
			actual: estimate.actual,
			type_item_1: estimate.type_item_1,
			type_item_2: estimate.type_item_2,
			type_item_3: estimate.type_item_3,
			type_item_4: estimate.type_item_4,
			expense_post_date: estimate.approved_date,
		});
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	render() {
		self = this;
		var setdisabled = true;
		const html = [];
		var filerow = [];
		var estimate = this.props.estimate;
		var vehicle = this.props.active_vehicle;

		return (
			<>
			<tr key={`estimate_row_${this.props.active_vehicle.id}_${estimate.id}`}>
				{estimateFields.map(option => <td key={`estimate_col_${this.props.active_vehicle.id}_${estimate.id}_${option.name}`}>
							{estimate[option.name] || ''}</td>)}

		</tr>
		</>
		);
	}
}

const mappedActions = {
	lookupUserById,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	deleteEstimate,
	sendEstimate,
	appraisalRequestNotification,
	updateVehicleDepartment,
	appraisalNotification,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		all_users: state.settings_users.all_users,
		current_dealership: state.settings_dealerships.current_dealership,
		installation: state.settings_dealerships.installation,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(Expense);
