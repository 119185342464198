import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as AllMutates from './AllMutates';

export function updateWorkFlow(id, data, errorType) {
	return dispatch => Api.patchData(`work_flows/${id}`, data).then(() => {
		dispatch(getInfo.getWorkFlows());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addWorkFlow(data, errorType) {
	return dispatch => Api.postData('work_flows/', data).then(() => {
		dispatch(getInfo.getWorkFlows());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addWorkFlowProperty(data, errorType) {
	return dispatch => Api.postData('work_flow_properties/', data).then(() => {
		dispatch(getInfo.getWorkFlows());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateWorkFlowPropertyGroup(items, errorType) {
	return (dispatch, getState) => {
		const { work_flows } = getState().settings_departments;
		const { edit_work_flows } = getState().settings_site;
		const index = _.findIndex(work_flows, { id: parseInt(edit_work_flows) });
		let i = 1;
		items.map((item) => {
			const data = {
				order: i,
			};
			i += 1;
			Api.patchData(`work_flow_properties/${item.property_id}`, data);
		});
		setTimeout(() => { dispatch(getInfo.getWorkFlows()); }, 3000);
	};
}

export function deleteWorkFlow(id, errorType) {
	return dispatch => Api.delete(`work_flows/${id}`).then(() => {
		dispatch(getInfo.getWorkFlows());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}
export function deleteWorkFlowProperty(id, errorType) {
	return dispatch => Api.delete(`work_flow_properties/${id}`).then(() => dispatch(getInfo.getWorkFlows())).catch(ex => Api.handleApiError(ex, errorType));
}
