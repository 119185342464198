import React, { memo, useCallback } from 'react';

// eslint-disable-next-line prefer-arrow-callback
const ButtonWithIcon = memo(function ButtonWithIcon({
	name, label, icon, onClick, style = {},
}) {
	const onClickHandler = useCallback(() => onClick(name), [name]);
	return (
		<li className="nav-item headericon" onClick={onClickHandler}>
			<a className="nav-link" style={style}>
				<i className={icon} />
				<div className="col">{label}</div>
			</a>
		</li>
	);
});
export default ButtonWithIcon;
