import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SidebarListButton from '../../inputs/SidebarListButton';
import {
	setVehiclePageNumber,
	setVehiclePageSize,
	setSortByData,
} from '../../actions/apiSetInfo';
import {
	getCurrentDepartment,
} from '../../actions/apiGetInfo';
import {
	lookupDepartmentById,
	lookupUserById,
} from '../../actions/lookups';
import {
	getDateTime,
	readableStamp,
	returnDaysFromTimestamp,
	readableStampSimple,
} from '../../utils/dateFunctions';

import Dropdown from '../../inputs/NewDropdown';
import DownloadCSV from './DownloadCSV';
import SearchBar from './SearchBar';

class SubHeader extends Component {
	constructor(props) {
		super(props);

		this.state = { alertChecked: false };
		this.onPageSizeChange = this.onPageSizeChange.bind(this);
		this.onClickNext = this.onClickNext.bind(this);
		this.onClickPrevious = this.onClickPrevious.bind(this);
		this.onClickPage = this.onClickPage.bind(this);
		this.selectSortOptions = this.selectSortOptions.bind(this);
		this.onSortChange = this.onSortChange.bind(this);
		this.onPrintButtonClick = this.onPrintButtonClick.bind(this);
	}

	handleAlertChecked = () => {
		sessionStorage.setItem('alertChecked', 'true');
		this.setState(() => ({ alertChecked: true }));
	   };

	componentDidMount() {
		console.log(sessionStorage.getItem('alertChecked'));
		if (sessionStorage.getItem('alertChecked') == 'true') {
			this.setState(() => ({ alertChecked: true }));
		} else {
			this.setState(() => ({ alertChecked: false }));
		}
	}

	onPrintButtonClick() {
		const vehicles = this.props.active_vehicles;
		let automax = false;
		if (this.props.installation.subdomain === 'automaxatlanta') {
			automax = true;
		}
		let columns = ['department', 'days', 'age', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles'];

		const department = this.props.current_department.display_name || this.props.current_department.name;
		const department_properties = this.props.current_department.department_properties;
		const extra_props = [];
		department_properties.map((dept_prop) => {
			extra_props.push(dept_prop.vehicle_column.name);
		});

		if (automax) {
			if (department === 'tires') {
				columns = ['department', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'tires needed'];
				extra_props.push('tire size');
			}

			if (department === 'wheel repair') {
				columns = ['department', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'wheel repair'];
			}

			if (department === 'interior dye') {
				columns = ['department', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'interior dye'];
			}

			if (department === 'interior repair') {
				columns = ['department', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'interior repair'];
			}

			if (department === 'pdr') {
				columns = ['department', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'pdr'];
			}

			if (department === 'refresh') {
				columns = ['department', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'refresh'];
			}

			if (department === 'service') {
				columns = ['department', 'stock', 'vin', 'year', 'make', 'model', 'color', 'miles', 'service'];
			}

			if (department === 'parts waiting') {
				columns = ['stock', 'vin', 'year', 'make', 'part', 'from', 'date_ordered', 'date_expected'];
			}
		}

		const dept_columns = columns.concat(extra_props);

		const print_window = window.open('', 'newWin',
			'width=800,height=800,toolbar=yes,locationbar=1,directories=1,statusbar=1,menubar=1,scrollbar=1,resizable=yes,titlebar=1');

		const topDoc = '<html><head><title>Print Vehicles</title></head>';
		const endDoc = '</body></html>';
		const style_tag = '<style> table {border-collapse: collapse;}'
            + 'td { border: 1px solid black; padding: 7px; } '
            + 'tr { border: 1px solid green; padding-top: 5px;} </style>';

		print_window.document.write(topDoc, style_tag, '<body>');

		print_window.document.write("<p style='margin:5px'>Recon Advisor</p>");
		print_window.document.write("<table class='ReactPrintTable'>");
		print_window.document.write('<tbody>');

		print_window.document.write('<tr>');

		dept_columns.map((column) => {
			if (typeof column !== 'undefined') {
				print_window.document.write('<td>');
				print_window.document.write(column);
				print_window.document.write('</td>');
			}
		});

		print_window.document.write('</tr>');

		if (automax && department == 'parts waiting') {
			vehicles.map((vehicle) => {
				const { part_orders } = vehicle;

				part_orders.map((part_order) => {
					if (part_order.parts_on_hand === false) {
						print_window.document.write('<tr>');

						dept_columns.map((column) => {
							if (typeof column !== 'undefined') {
								print_window.document.write('<td>');

								if (column == 'department') {
									print_window.document.write(department);
								} else if (column == 'part') {
									print_window.document.write(part_order.part_ordered);
								} else if (column == 'date_expected') {
									print_window.document.write(readableStampSimple(part_order.date_expected));
								} else if (column == 'date_ordered') {
									print_window.document.write(readableStampSimple(part_order.date_ordered));
								} else if (column == 'from') {
									print_window.document.write(part_order.from);
								} else {
									print_window.document.write(vehicle[column]);
								}

								print_window.document.write('</td>');
							}
						});

						print_window.document.write('</tr>');
					}
				});
			});
		} else {
		vehicles.map((vehicle) => {
			print_window.document.write('<tr>');
			dept_columns.map((column) => {
				if (typeof column !== 'undefined') {
					print_window.document.write('<td>');

					if (column == 'department') {
						print_window.document.write(department);
					} else if (column === 'tires needed' && automax) {
						const fl = _.find(vehicle.vehicle_properties, { key: 'Tires FL' }) || [];
						const fr = _.find(vehicle.vehicle_properties, { key: 'Tires FR' }) || [];
						const bl = _.find(vehicle.vehicle_properties, { key: 'Tires BL' }) || [];
						const br = _.find(vehicle.vehicle_properties, { key: 'Tires BR' }) || [];
						let col = '';
						if (fl.value === 'true') {
							col += ' | FL ';
						}
						if (fr.value === 'true') {
							col += ' | FR ';
						}
						if (bl.value === 'true') {
							col += ' | BL ';
						}
						if (br.value === 'true') {
							col += ' | BR ';
						}
						print_window.document.write(col);
					} else if (column === 'wheel repair' && automax) {
						const fl = _.find(vehicle.vehicle_properties, { key: 'Wheel Repair FL' }) || [];
						const fr = _.find(vehicle.vehicle_properties, { key: 'Wheel Repair FR' }) || [];
						const bl = _.find(vehicle.vehicle_properties, { key: 'Wheel Repair BL' }) || [];
						const br = _.find(vehicle.vehicle_properties, { key: 'Wheel Repair BR' }) || [];

						let col = '';
						if (fl.value === 'true') {
							col += ' | FL ';
						}
						if (fr.value === 'true') {
							col += ' | FR ';
						}
						if (bl.value === 'true') {
							col += ' | BL ';
						}
						if (br.value === 'true') {
							col += ' | BR ';
						}

						print_window.document.write(col);
					} else if (column === 'interior dye' && automax) {
						const d_only = _.find(vehicle.vehicle_properties, { key: 'driver seat only' }) || [];
						const o_interior = _.find(vehicle.vehicle_properties, { key: 'overall interior' }) || [];

						let col = '';
						if (d_only.value === 'true') {
							col += ' | Driver Seat Only ';
						}
						if (o_interior.value === 'true') {
							col += ' | Overall Interior ';
						}

						print_window.document.write(col);
					} else if (column === 'interior repair' && automax) {
						const d_seat = _.find(vehicle.vehicle_properties, { key: 'repair driver seat' }) || [];
						const p_seat = _.find(vehicle.vehicle_properties, { key: 'repair passenger seat' }) || [];
						const repair_other = _.find(vehicle.vehicle_properties, { key: 'repair other' }) || [];

						let col = '';
						if (d_seat.value === 'true') {
							col += ' | Repair Driver ';
						}
						if (p_seat.value === 'true') {
							col += ' | Repair Passenger ';
						}
						if (repair_other.value) {
							col += `${repair_other.value}`;
						}

						print_window.document.write(col);
					} else if (column === 'pdr' && automax) {
						const pdr_notes = _.find(vehicle.vehicle_properties, { key: 'pdr notes' }) || [];

						let col = '';
						if (pdr_notes.value) {
							col += `${pdr_notes.value}`;
						}

						print_window.document.write(col);
					} else if (column === 'refresh' && automax) {
						const refresh_notes = _.find(vehicle.vehicle_properties, { key: 'refresh notes' }) || [];

						let col = '';
						if (refresh_notes.value) {
							col += `${refresh_notes.value}`;
						}

						print_window.document.write(col);
					} else if (column === 'part' && automax) {
						const pdr_notes = _.find(vehicle.vehicle_properties, { key: 'pdr notes' }) || [];

						let col = '';
						if (pdr_notes.value) {
							col += `${pdr_notes.value}`;
						}

						print_window.document.write(col);
					} else if (column === 'service' && automax) {
						const service_notes = _.find(vehicle.vehicle_properties, { key: 'service notes' }) || [];

						let col = '';
						if (service_notes.value) {
							col += `${service_notes.value}`;
						}

						print_window.document.write(col);
					} else if (extra_props.includes(column)) {
						const val = _.find(vehicle.vehicle_properties, { key: column }) || [];
						const printval = val.value || '';
						print_window.document.write(printval);
					} else if (column === 'days') {
						print_window.document.write(returnDaysFromTimestamp(vehicle.days));
					} else if (column === 'age') {
						print_window.document.write(returnDaysFromTimestamp(vehicle.age));
					} else {
						print_window.document.write(vehicle[column]);
					}
					print_window.document.write('</td>');
				}
			});

			print_window.document.write('</tr>');
		});
		}

		print_window.document.write('</tr>');
		print_window.document.write('</tbody>');
		print_window.document.write('</table>');
		print_window.document.write(endDoc);
		print_window.document.close();
		print_window.print();
	}

	onSortChange(e) {
		this.setState({ sort_by_data: e.value });
		this.props.setSortByData(e.value);
		this.props.getCurrentDepartment(this.props.current_department.id);
	}

	selectOptions() {
		let step;
		const select_options = [];
		for (step = 5; step < 1000; step += 5) {
			let selected = false;
			if (this.props.page_size === step) {
				selected = true;
			}

			const obj = {
				id: step, label: step, value: step, selected,
			};
			select_options.push(obj);
		}

		return select_options || [];
	}

	selectSortOptions() {
		let step;
		const optionsArray = [];
		const department = this.props.current_department || [];
		const department_properties = department.department_properties || [];

		const options = ['year', 'make', 'model', 'days', 'age', 'make', 'classification', 'stocktype'];
		let i = 999999;
		options.map((sort_data) => {
			const obj = { id: i, value: sort_data, label: sort_data };
			optionsArray.push(obj);
			i++;
		});

		department_properties.map((dept_prop) => {
			const obj = { id: dept_prop.id, value: dept_prop.vehicle_column.name, label: dept_prop.vehicle_column.name };
			optionsArray.push(obj);
		});

		return optionsArray || [];
	}

	onClickPage(e) {
		const nextVal = parseInt(e.target.dataset.page);
		this.props.setVehiclePageNumber(nextVal);
		this.props.getCurrentDepartment(this.props.current_department.id);
	}

	onClickNext(e) {
		const nextVal = parseInt(e.target.dataset.page) + 1;
		this.props.setVehiclePageNumber(nextVal);
		this.props.getCurrentDepartment(this.props.current_department.id);
	}

	onClickPrevious(e) {
		const nextVal = parseInt(e.target.dataset.page) - 1;
		this.props.setVehiclePageNumber(nextVal);
		this.props.getCurrentDepartment(this.props.current_department.id);
	}

	onPageSizeChange(e) {
		this.setState({ page_size: e.value });
		this.props.setVehiclePageSize(e.value);
		this.props.getCurrentDepartment(this.props.current_department.id);
	}

	render() {
		const sidebar_status = this.props.sidebar_status === true ? 'fas fa-chevron-double-left' : 'fas fa-chevron-double-right';
		let vehicle_page = '';
		let start = '';
		let end = '';
		let showing = '';

		const { page_number } = this.props;
		const { page_size } = this.props;
		const current_count = this.props.current_department.vehicle_count || 1;
		const total_pages = this.props.page_total;
		const buttons = [];

		if (this.props.sort_descending === true) {
			buttons.push(<SidebarListButton key={'asc_button'} title="Sort Asc" name="menu-descending" classtype="light" icon={'fas fa-angle-double-up'} />);
		} else {
			buttons.push(<SidebarListButton key={'desc_button'} title="Sort Desc" name="menu-descending" classtype="light" icon={'fas fa-angle-double-down'} />);
		}

		if (this.props.expand_all) {
			buttons.push(<SidebarListButton key={'compress_button'} title="Collapse" name="menu-expandall" classtype="light" icon={'fas fa-compress-alt'} />);
		} else {
			buttons.push(<SidebarListButton key="compress_button2" title="Expand" name="menu-expandall" classtype="light" icon={'fas fa-expand-alt'} />);
		}

		if (this.props.current_department.name && this.props.page === 'content') {
			if (this.props.vehicle_total < this.props.page_size) {
				showing = `${this.props.vehicle_total} of ${this.props.vehicle_total}`;
			} else {
				start = (this.props.page_size * this.props.page_number) - this.props.page_size;
				if ((start + this.props.page_size) > this.props.vehicle_total) {
					end = this.props.vehicle_total;
				} else {
					end = start + this.props.page_size;
				}
				showing = `${start} - ${end} of ${this.props.vehicle_total}`;
			}

			vehicle_page = <div className="btn-group text-center" role="group" key="toppage">
							<button type="button" className="btn btn-sm btn-default">{this.props.current_department.name} - {showing} </button>
							{page_number > 1 ? <button className="btn btn-sm btn-default" onClick={this.onClickPrevious} data-page={page_number}>&laquo;</button> : ''}
							{page_number > 2 ? <button className="btn btn-sm btn-default" onClick={this.onClickPage} data-page={1}> 1</button> : ''}
							{page_number > 0 ? <button className="btn btn-sm btn-default" onClick={this.onClickPage} data-page={page_number}>Page {page_number} of {Math.ceil(total_pages)}</button> : ''}
							{parseInt(page_number) + 1 < total_pages ? <button className="btn btn-sm btn-default" onClick={this.onClickPage} data-page={total_pages}> {total_pages}</button> : ''}
							{page_number < total_pages ? <button className="btn btn-sm btn-default" onClick={this.onClickNext} data-page={page_number}>&raquo;</button> : ''}
							<button className="btn btn-sm btn-default">Display:</button>
							<Dropdown
								titleHelper=""
								title={''}
								list={this.selectOptions()}
								onChange={this.onPageSizeChange}
								update_type={'page_size'}
								value={this.props.page_size}
							/>
							<button className="btn btn-sm btn-default">Sort:</button>
							<Dropdown
								titleHelper=""
								title={''}
								list={this.selectSortOptions()}
								update_type={'sort_by_data'}
								value={this.props.sort_by_data}
								onChange={this.onSortChange}
								key={this.props.current_department.id}
							/>
							{buttons}
							{this.props.current_department ? <DownloadCSV {...this.props} /> : ''}
							{this.props.current_department ? <button className="btn btn-light btn-sm" key="print-button" onClick={this.onPrintButtonClick}><i className="fa fa-print" /></button> : ''}
						</div>;
		}

		return (
			<div className="container-fluid searchBar bg-default text-dark border-top-1 border-top border-light clearfix">
			<div className="container-fluid bg-light clearfix shadow_2 space-bottom-half">
				<div className="d-flex">
					<span className="float-start"><SidebarListButton key={`navleft_${this.props.sidebar_status}`} title="ToggleSidebar" name="menu-togglesidebar" classtype="default" icon={sidebar_status} /></span>
					<span className="flex-grow-1">{Object.keys(this.props.current_user_profile).length > 0 && this.props.current_user_profile.constructor === Object ? <SearchBar key="search-bar" /> : ''}</span>
					<span className="float-end">{ this.props.inactive_status ? 'Sleeping...' : ''} </span>

					<span className="float-end"><button type="button" className="btn btn-sm btn-light">{ this.props.inactive_status ? 'Sleeping...' : ''} { this.props.current_user.email} | { this.props.installation.subdomain} | { this.props.current_user.position}</button>
								</span>
				</div>
			</div>
			<div className="row bg-dark clearfix text-center shadow_2 space-left-0 space-right-0 space-bottom-half">
				<span className="float-start clearfix bg-light text-center">{vehicle_page}</span>
			</div>
				</div>
		);
	}
}

const mappedActions = {
	lookupDepartmentById,
	lookupUserById,
	setVehiclePageNumber,
	setVehiclePageSize,
	setSortByData,
	returnDaysFromTimestamp,
	getCurrentDepartment,
};

function mapStateToProps(state) {
	return {
		active_vehicles: state.settings_refresh.active_vehicles,
		current_user: state.current_user,
		installation: state.settings_dealerships.installation,
		current_user_profile: state.settings_users.current_user_profile || [],
		sidebar_status: state.settings_nav.menus.sidebar_status,
		current_department: state.settings_departments.department,
		vehicle_total: state.settings_nav.vehicle_pagination.vehicle_total,
		page_size: state.settings_nav.vehicle_pagination.page_size,
		page_number: state.settings_nav.vehicle_pagination.page_number,
		page_total: state.settings_nav.vehicle_pagination.page_total,
		vehicle_total: state.settings_nav.vehicle_pagination.vehicle_total,
		sort_descending: state.settings_nav.menus.sort_descending,
		sort_by_data: state.settings_nav.menus.sort_by_data,
		expand_all: state.settings_nav.menus.expand_all,
		page: state.settings_nav.page,
		inactive_status: state.settings_nav.inactive_status,
	};
}
export default connect(mapStateToProps, mappedActions)(SubHeader);
