const AUTH_API_HEADERS = {}

let baseUrl = "https://auth-api.autoipacket.com/api/"

if (window.ENV && window.ENV.AUTH_API_URL) {
  baseUrl = window.ENV.AUTH_API_URL
}

const AUTH_API_URLS = {
  BASE_URL: baseUrl,
  REFRESH_TOKEN: `${baseUrl}authn/token/refresh`,
  SWAP_REDIRECT_TOKEN: `${baseUrl}authn/token/code`,
  INITIATE_RESET_PASSWORD: `${baseUrl}authn/password/reset/initiate`,
}

export default function AUTH_API(url, params) {
  if (params) {
    params.headers = {
      ...AUTH_API_HEADERS,
      ...params.headers,
    }
  } else {
    params = {
      headers: AUTH_API_HEADERS,
    }
  }

  return fetch(url, params).then(response => {
    return response
  })
}

export { AUTH_API_URLS, AUTH_API_HEADERS }
