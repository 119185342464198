import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AllInputs from '../../../../inputs/AllInputs';
import {
	deletePartOrder,
	updatePartOrder,
} from '../../../../actions/apiPartOrders';

const partsFields = [{ name: 'part_ordered', label: 'Part Ordered', type: 'text' },
	{ name: 'from', label: 'From', type: 'text' },
	{ name: 'date_ordered', label: 'Date Ordered', type: 'date' },
	{ name: 'date_expected', label: 'Date Expected', type: 'date' },
	{ name: 'parts_on_hand', label: 'Parts on Hand', type: 'switch-no-label-parts' }];

const allFields = ['part_ordered', 'from', 'date_ordered', 'date_expected',
	'parts_on_hand', 'deleted'];

class PartOrderSingle extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addDeleteEstimateError: '',
			vehicle_id: this.props.active_vehicle.id,
			part_ordered: this.props.part_order.part_ordered,
			from: this.props.part_order.from,
			date_ordered: this.props.part_order.date_ordered,
			date_expected: this.props.part_order.date_expected,
			parts_on_hand: this.props.part_order.parts_on_hand,
			deleted: this.props.part_order.deleted,
			uid: this.props.part_order.uid,
		};
		this.onChange = this.onChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onDelete(e) {
		this.props.deletePartOrder(this.props.part_order.id, this.props.active_vehicle.id, 'addDeletepart_orderError');
	}

	onUpdate(e) {
		const data = {
			updatePartOrderError: '',
			vehicle_id: this.props.active_vehicle.id,
			part_ordered: this.props.part_order.part_ordered,
			from: this.props.part_order.from,
			date_ordered: this.props.part_order.date_ordered,
			date_expected: this.props.part_order.date_expected,
			parts_on_hand: this.props.part_order.parts_on_hand,
			deleted: this.props.part_order.deleted,
			uid: this.props.parts_order.uid,
		};

		this.props.updatePartOrder(this.props.part_order.id, data, 'addpart_orderError');
	}

	render() {
		const self = this;
		const { part_order } = self.props;
		const { vehicle } = self.props;

		return (
			<tr key={`part_order_row_${this.props.active_vehicle.id}_${this.props.part_order.id}`} className="text-center">
				<td>{this.props.part_order.id}</td>
            	{partsFields.map(option => <td key={`part_order_col_${this.props.active_vehicle.id}_${this.props.part_order.id}_${option.name}`} className={option.name === 'description' ? 'desk-w-30 text-center' : `${option.name}` === 'ro_files' ? 'desk-w-30 text-center' : 'text-center'}>
                	<AllInputs
						onChange={this.onChange}
						value={part_order[option.name]}
						type={option.type}
						name={option.label}
						parentid={vehicle.id}
						part_order_id={part_order.id}
						updatetype="part_order"
						user_name=""
						updated_at=""
						options=""
                	/>
				</td>)}

				<td><button className="btn btn-sm btn-danger" onClick={self.onDelete}>X</button></td>
			</tr>
		);
	}
}

const mappedActions = {
	deletePartOrder,
	updatePartOrder,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(PartOrderSingle);
