import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import EditDepartmentSettings from '../../inputs/EditDepartmentSettings';
import AddDepartmentButton from './AddDepartmentButton';
import DepartmentList from './DepartmentList';
import TestTable from './TestTable';
import DropdownMultiple from '../../inputs/DropdownMultiple';

const activeOptions = ['true', 'false'];

class TabEditDepartmentSettings extends Component {
	constructor(props) {
		super(props);

		this.state = { value: this.props.value || '', checked: this.props.checked || '' };
		this.deleteDepartmentFilter = this.deleteDepartmentFilter.bind(this);
	}

	deleteDepartmentFilter(e) {
		this.props.deleteDepartmentFilter(e.target.dataset.id);
	}

	selectDealers(dealerships) {
		const dealer_values = dealerships.split(',');

		const options = dealer_values.map((option) => {
			const obj = { value: option, label: option };
			return obj;
		});
		return options;
	}

	selectRelatedInstallations() {
		const dealerships = this.props.dealerships || [];
		const selected_dealerships = [];

		dealerships.map((option) => {
			selected_dealerships.push(option.installation_id);
		});

		const options = this.props.set_dealerships.map((option) => {
			let selected = false;
			let dataid = 0;
			if (selected_dealerships.includes(option.id)) {
				selected = true;
				const user_installation_id = _.filter(dealerships, o => o.installation_id === option.id);
				dataid = user_installation_id[0].id;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, selected, dataid,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, selected, dataid,
				};
			}
			return obj;
		});
		return options;
	}

	render() {
		const self = this;
		const department = self.props.edit_department;
		const dealerships = department.dealerships || '';
		const dealers = this.selectDealers(dealerships);

		return (
			<div className="container">
				<div className="col-md-12">
					<AddDepartmentButton />
				</div>

				<div className="col-md-12">
					<small>
						<i className="fa fa-info-circle text-info" />
						{' '}
Select Department to edit Columns and Settings
					</small>
				</div>
				<TestTable />
			</div>

		);
	}
}

function mapStateToProps(state) {
	return {
		input_types: state.settings_departments.input_types,
		edit_department: state.settings_admin.edit_department,
		departments: state.settings_departments.original_departments,
		dealerships: state.settings_admin.edit_locations,
		set_dealerships: state.settings_dealerships.related_installations,
	};
}
export default connect(mapStateToProps)(TabEditDepartmentSettings);
