import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userStamp } from '../../../../utils/dateFunctions';
import { deleteAttachment } from '../../../../actions/apiAttachments';

class FilePreview extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { addDepartmentPropertyError: '' };
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		this.props.deleteAttachment(this.props.attachment.id);
	}

	render() {
		const createdDate = userStamp(this.props.attachment.created_at);

		var file_preview = '';

		if (this.props.attachment.fileid.match(/.(jpg|jpeg|png|gif)$/i)) {
			file_preview = <img src={this.props.attachment.location} width="100%" />;
		}
		return (
			<li className="list-group-item">
				{file_preview}
				<a className="btn btn-default" href={this.props.attachment.location} target="_blank">
					<i className={'fas fa-cloud-download'} />
				</a>
				{this.props.attachment.fileid}
				<button onClick={this.onClick} className="btn btn-sm btn-danger float-right">
					<i className={'fas fa-trash'} />
				</button>
				<small>
					<br />
Uploaded:
					{' '}
					{this.props.attachment.user_name ? this.props.attachment.user_name.split('@')[0] : ''}
					{' '}
					{` ${createdDate}`}
				</small>
			</li>
		);
	}
}

const mappedActions = {
	deleteAttachment,
};

export default connect(undefined, mappedActions)(FilePreview);
