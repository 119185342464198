import _ from 'lodash';
import * as types from './types';
import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as AllMutates from './AllMutates';

export function update_loading_status(update_status) {
	return {
		type: types.UPDATE_STATUS,
		update_status,
	};
}

export function setCurrentDefaultProfileFull(default_profile) {
	return {
		type: types.SET_CURRENT_DEFAULT_PROFILE,
		default_profile,
	};
}

export function editDefaultProfile(id, data, errorType) {
	return (dispatch) => {
		dispatch(update_loading_status('loading'));

		return Api.patchData(`default_profiles/${id}`, data).then((resp) => {
			dispatch(setCurrentDefaultProfileFull(resp.data.data));
			dispatch(update_loading_status('success'));
			dispatch(getInfo.getDefaultProfiles());
		}).catch((ex) => {
			dispatch(update_loading_status('error found'));
			return Api.handleApiError(ex, errorType);
		});
	};
}

export function setCurrentDefaultProfile(id) {
	return (dispatch, getState) => {
		const newArray = getState().settings_users.default_profiles;
		const default_profile = _.find(newArray, {
			id: parseInt(id, 10),
		});
		dispatch(setCurrentDefaultProfileFull(default_profile));
	};
}

export function addDefaultProfile(data, errorType) {
	return dispatch => Api.postData('default_profiles', data).then((resp) => {
		const profile = resp.data.data;
		if (typeof profile !== undefined) {
			Promise.all([
				dispatch(getInfo.getDefaultProfiles()),
			]).then(() => {
				dispatch(setInfo.setCurrentDefaultProfile(profile.id));
				dispatch(setInfo.setCurrentTab('default-departments'));
			});
		}

		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function editUserProfile(id, data, errorType) {
	return (dispatch) => {
		dispatch(update_loading_status('loading'));

		return Api.patchData(`users/${id}`, data).then(() => {
			dispatch(getInfo.getUsers());
			return 'success';
		}).catch(ex => Api.handleApiError(ex, errorType));
	};
}

export function deleteUser(id, data, errorType) {
	return (dispatch) => {
		dispatch(update_loading_status('loading'));
		return Api.patchData(`users/${id}`, data).then(() => {
			dispatch(getInfo.getUsers());
			return 'success';
		}).catch(ex => Api.handleApiError(ex, errorType));
	};
}

export function addUserInstallation(data, errorType) {
	return dispatch => Api.postData('user_installations', data).then(() => {
		dispatch(getInfo.getUsers());
		dispatch(getInfo.getInstallation());
		dispatch(getInfo.getRelatedInstallations());
		dispatch(getInfo.getDealerships());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addNewUser(data, errorType) {
	return (dispatch, getState) => Api.postData('users', data).then((resp) => {
		const current_dealership = getState().settings_dealerships.current_dealership_id;
		const data2 = {
			installation_id: current_dealership,
			user_id: resp.data.data.id,
		};
		dispatch(addUserInstallation(data2, errorType));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addUserPermission(data, errorType) {
	return dispatch => Api.postData('user_permissions', data).then(() => {
		dispatch(getInfo.getUsers());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addProfilePermission(data, errorType) {
	return dispatch => Api.postData('profile_permissions', data).then(() => {
		dispatch(getInfo.getDefaultProfiles());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteUserInstallation(id, errorType) {
	return (dispatch) => {
		dispatch(update_loading_status('loading'));
		return Api.delete(`user_installations/${id}`).then(() => {
			dispatch(getInfo.getUsers());
			dispatch(getInfo.getInstallation());
			dispatch(getInfo.getRelatedInstallations());
			dispatch(getInfo.getDealerships());

			return 'success';
		}).catch(ex => Api.handleApiError(ex, errorType));
	};
}
