import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import {
	getMonthDayYear,
} from '../../../../utils/dateFunctions';

class ServiceForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addPartOrderError: '', warranty: false, spare_keys_yes: false, spare_keys_no: false, one_master: false, one_valet: false, possible_certify: false, certify: false, no_certify: false, safety_check: false, oil_change: false, detail: false, vendor_hold: false,
		};
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onPrint = this.onPrint.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClick(e) {
		const name = e.target.dataset.name;
		this.setState({ [name]: !this.state[name] });
	}

	onPrint(e) {
		const vehicle = this.props.active_vehicle;
		const print_page = document.getElementById(`service_form_${vehicle.stock}`);
		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 0, 0);
				    pdf.save(`service_form_${vehicle.stock}.pdf`);
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	render() {
		const self = this;
		const vehicle = this.props.active_vehicle;
		const errors = this.state;
		const arbitrationDeadline = _.find(vehicle.vehicle_properties, { key: 'arbitration deadline' }) || '';
		const safetyCheckCompletedBy = _.find(vehicle.vehicle_properties, { key: 'safety check completed by' }) || '';
		const waitingOnParts = _.find(vehicle.vehicle_properties, { key: 'waiting on parts' }) || '';
		const waitingOnPartsNotes = _.find(vehicle.vehicle_properties, { key: 'waiting on parts notes' }) || '';
		const clearedForDetail = _.find(vehicle.vehicle_properties, { key: 'cleared for detail' }) || '';
		const detailCompletedBy = _.find(vehicle.vehicle_properties, { key: 'detail completed by' }) || '';
		const qualityInspectedBy = _.find(vehicle.vehicle_properties, { key: 'quality inspected by' }) || '';
		const dateDetailCompleted = _.find(vehicle.vehicle_properties, { key: 'date detail completed' }) || '';
		const photosTaken = _.find(vehicle.vehicle_properties, { key: 'photos taken' }) || '';

		return (
			<div className="container">
				<button onClick={this.onPrint} className="btn btn-success float-right">Print</button>
				<div className="card" id={`service_form_${vehicle.stock}`}>
					<div className="card" style={{ padding: '10px', width: '800px' }}>
						<div className="card-text">
							<span style={{ textAlign: 'left', textDecoration: 'underline', fontWeight: 'bold' }}>USED CAR INSPECTION</span>
							{' '}
							<small className="float-right">
Date:
								{getMonthDayYear()}
								{' '}

							</small>
						</div>
						<div className="card-text">
							<div
								className="d-flex flex-wrap"
								style={{
									padding: '10px', width: '95%', fontSize: '12px',
								}}
							>
								<table className="table service-form" cellPadding={2}>
									<thead className={'thead text-center'}>
										<tr>
											<th className="text-left" style={{ width: '30%' }}>
Stock #
												{vehicle.stock}
											</th>
											<th className="text-left">
Year / Make / Model:
												{vehicle.year}
												{' '}
/
												{vehicle.make}
												{' '}
/
												{vehicle.model}
											</th>

										</tr>
										<tr>
											<th className="text-left">
Vin #
												{vehicle.vin}
											</th>
											<th className="text-left">
Miles -
												{vehicle.miles.toLocaleString()}
											</th>
											<td />
										</tr>
										<tr>
											<th className="text-left">
WARRANTY -
												<span onClick={this.onClick} data-name={'warranty'} className={this.state.warranty ? 'addborder circled' : 'addborder'}>N</span>
											</th>
											<th className="text-left">
SPARE KEYS NEEDED? -
												<span onClick={this.onClick} data-name={'spare_keys_yes'} className={this.state.spare_keys_yes ? 'addborder circled' : 'addborder'}>Y</span>
												{' '}
/
												<span onClick={this.onClick} data-name={'spare_keys_no'} className={this.state.spare_keys_no ? 'addborder circled' : 'addborder'}>N</span>
												{' '}
|
												<span onClick={this.onClick} data-name={'one_master'} className={this.state.one_master ? 'addborder circled' : 'addborder'}>ONE MASTER</span>
												{' '}
/
												<span onClick={this.onClick} data-name={'one_valet'} className={this.state.one_valet ? 'addborder circled' : 'addborder'}>ONE VALET</span>
											</th>
										</tr>
										<tr>
											<th className="text-left"><span onClick={this.onClick} data-name={'possible_certify'} className={this.state.possible_certify ? 'addborder circled' : 'addborder'}>POSSIBLE CERTIFY</span></th>
											<th className="text-left">
												<span onClick={this.onClick} data-name={'certify'} className={this.state.certify ? 'addborder circled' : 'addborder'}>CERTIFY</span>
												{' '}
/
												{' '}
												<span onClick={this.onClick} data-name={'no_certify'} className={this.state.no_certify ? 'addborder circled' : 'addborder'}>NO CERTIFY</span>
											</th>
											<td />
										</tr>
										<tr>
											<th className="text-left" colSpan={2}>
JOB REQUESTS --
												<span onClick={this.onClick} data-name={'safety_check'} className={this.state.safety_check ? 'addborder circled' : 'addborder'}>SAFETY CHECK</span>
												{' '}
/
												<span onClick={this.onClick} data-name={'oil_change'} className={this.state.oil_change ? 'addborder circled' : 'addborder'}>OIL CHANGE</span>
												{' '}
-- SEND TO:
												<span onClick={this.onClick} data-name={'detail'} className={this.state.detail ? 'addborder circled' : 'addborder'}>DETAIL</span>
												{' '}
/
												<span onClick={this.onClick} data-name={'vendor_hold'} className={this.state.vendor_hold ? 'addborder circled' : 'addborder'}>VENDOR HOLD</span>
											</th>
										</tr>
										<tr>
											<th className="text-left">MANAGER - </th>
											<td />
										</tr>
									</thead>
									<tbody />
								</table>
							</div>

						</div>
						<div className="card-text"><h4 style={{ textAlign: 'center' }}>SERVICE COMPLETE</h4></div>
					</div>
					<div className="card" style={{ padding: '10px', width: '800px', borderTop: '1px solid black' }}>
						<div className="card-text">
							<span style={{ textAlign: 'left', textDecoration: 'underline', fontWeight: 'bold' }}>USED CAR INSPECTION</span>
							{' '}
							<small className="float-right">
Date:
								{getMonthDayYear()}
								{' '}

							</small>
						</div>
						<div className="card-text">
							<div
								className="d-flex flex-wrap"
								style={{
									padding: '10px', width: '95%', fontSize: '12px',
								}}
							>
								<table className="table service-form" cellPadding={2}>
									<thead className={'thead text-center'}>
										<tr>
											<th className="text-left" style={{ width: '30%' }}>
Stock #
												{vehicle.stock}
											</th>
											<th className="text-left">
Year / Make / Model:
												{vehicle.year}
												{' '}
/
												{vehicle.make}
												{' '}
/
												{vehicle.model}
											</th>

										</tr>
										<tr>
											<th className="text-left">
Vin #
												{vehicle.vin}
											</th>
											<th className="text-left">
Miles -
												{vehicle.miles.toLocaleString()}
											</th>
											<td />
										</tr>
										<tr>
											<th className="text-left">
WARRANTY -
												<span onClick={this.onClick} data-name={'warranty'} className={this.state.warranty ? 'addborder circled' : 'addborder'}>N</span>
											</th>
											<th className="text-left">
SPARE KEYS NEEDED? -
												<span onClick={this.onClick} data-name={'spare_keys_yes'} className={this.state.spare_keys_yes ? 'addborder circled' : 'addborder'}>Y</span>
												{' '}
/
												<span onClick={this.onClick} data-name={'spare_keys_no'} className={this.state.spare_keys_no ? 'addborder circled' : 'addborder'}>N</span>
												{' '}
|
												<span onClick={this.onClick} data-name={'one_master'} className={this.state.one_master ? 'addborder circled' : 'addborder'}>ONE MASTER</span>
												{' '}
/
												<span onClick={this.onClick} data-name={'one_valet'} className={this.state.one_valet ? 'addborder circled' : 'addborder'}>ONE VALET</span>
											</th>
										</tr>
										<tr>
											<th className="text-left"><span onClick={this.onClick} data-name={'possible_certify'} className={this.state.possible_certify ? 'addborder circled' : 'addborder'}>POSSIBLE CERTIFY</span></th>
											<th className="text-left">
												<span onClick={this.onClick} data-name={'certify'} className={this.state.certify ? 'addborder circled' : 'addborder'}>CERTIFY</span>
												{' '}
/
												{' '}
												<span onClick={this.onClick} data-name={'no_certify'} className={this.state.no_certify ? 'addborder circled' : 'addborder'}>NO CERTIFY</span>
											</th>
											<td />
										</tr>
										<tr>
											<th className="text-left" colSpan={2}>
JOB REQUESTS --
												<span onClick={this.onClick} data-name={'safety_check'} className={this.state.safety_check ? 'addborder circled' : 'addborder'}>SAFETY CHECK</span>
												{' '}
/
												<span onClick={this.onClick} data-name={'oil_change'} className={this.state.oil_change ? 'addborder circled' : 'addborder'}>OIL CHANGE</span>
												{' '}
-- SEND TO:
												<span onClick={this.onClick} data-name={'detail'} className={this.state.detail ? 'addborder circled' : 'addborder'}>DETAIL</span>
												{' '}
/
												<span onClick={this.onClick} data-name={'vendor_hold'} className={this.state.vendor_hold ? 'addborder circled' : 'addborder'}>VENDOR HOLD</span>
											</th>
										</tr>
										<tr>
											<th className="text-left">MANAGER - </th>
											<td />
										</tr>
									</thead>
									<tbody />
								</table>
							</div>

						</div>
						<div className="card-text"><h4 style={{ textAlign: 'center' }}>SERVICE COMPLETE</h4></div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return { active_vehicle: state.settings_refresh.active_vehicle };
}

export default connect(mapStateToProps)(ServiceForm);
