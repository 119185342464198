import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const date = new Date();
const firstDay_date = new Date(date.getFullYear(), date.getMonth(), 1);
const firstDay = `${firstDay_date.getFullYear()}-${(`0${firstDay_date.getMonth() + 1}`).slice(-2)}-${(`0${firstDay_date.getDate()}`).slice(-2)}`;
const lastDay_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const lastDay = `${lastDay_date.getFullYear()}-${(`0${lastDay_date.getMonth() + 1}`).slice(-2)}-${lastDay_date.getDate()}`;

const sixty_days = new Date(new Date().getTime() - (60 * 24 * 60 * 60 * 1000));
const start_60 = `${sixty_days.getFullYear()}-${(`0${sixty_days.getMonth() + 1}`).slice(-2)}-${(`0${sixty_days.getDate()}`).slice(-2)}`;
const end_60 = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

const initialState = {
	page: 'content',
	last_page: '',
	vehicle_filter: {
		type: 'department', value: '', updated: '', sold: false,
	},
	deep_link: '',
	vehicle_pagination: {
		page_size: 50,
		page_number: 1,
		page_total: 1,
		vehicle_total: 50,
		page_name: '',
	},
	vin_scanner: '',
	vin_decoded_vehicle: {
		vin: '', year: '', make: '', model: '', message: '',
	},
	vin_scanner_size: {
		height: 0,
		width: 0,
	},
	last_vehicle_filter: {},
	department: 0,
	last_department: 0,
	show_add_filter: false,
	edit_vehicle: '',
	interval_number: '',
	interval_numberB: '',
	interval_vin_scanner: '',
	last_updated: '',
	inactive_status: false,
	inactive_status_interval: '',
	is_updating: '',
	include_sold: false,
	cancel_old_requests: false,
	menus: {
		toggle_drawer_status: false,
		sidebar_status: true,
		sidebar_status_right: false,
		nav_status: false,
		report_menu_status: false,
		vehicle_tab: 'vehicle-body',
		site_map: false,
		expand_all: true,
		report_bar: false,
		hide_blank: false,
		sort_descending: false,
		sort_by_data: 'days',
		work_flow: 'all',
	},
	error: {},
};

export const settings_nav = createReducer(initialState, {
	[types.CHANGE_CURRENT_PAGE](state = initialState, action) {
		return {
			...state,
			last_page: state.page,
			page: action.page,
		};
	},
	[types.SET_VEHICLE_FILTER](state = initialState, action) {
		return {
			...state,
			last_vehicle_filter: state.vehicle_filter,
			vehicle_filter: action.vehicle_filter,
			last_department: state.department,
			department: action.department,
		};
	},
	[types.INCLUDE_SOLD](state = initialState, action) {
		return {
			...state,
			include_sold: action.include_sold,
		};
	},
	[types.IS_UPDATING](state = initialState, action) {
		return {
			...state,
			is_updating: action.updating,
		};
	},
	[types.LAST_PAGE](state = initialState) {
		return {
			...state,
			page: state.last_page,
			last_page: state.page,
			vehicle_filter: state.last_vehicle_filter,
			last_vehicle_filter: state.vehicle_filter,
			department: state.last_department,
			last_department: state.department,
		};
	},
	[types.TOGGLE_DRAWER](state = initialState) {
		const newState = state.menus;
		newState.toggle_drawer_status = !state.menus.toggle_drawer_status;
		return {
			...state,
			menus: newState,
		};
	},
	[types.CLOSE_DRAWER](state = initialState) {
		const newState = state.menus;
		newState.toggle_drawer_status = false;
		return {
			...state,
			menus: newState,
		};
	},
	[types.OPEN_DRAWER](state = initialState) {
		const newState = state.menus;
		newState.toggle_drawer_status = true;
		return {
			...state,
			menus: newState,
		};
	},
	[types.TOGGLE_HIDE_BLANK](state = initialState) {
		const newState = state.menus;
		newState.hide_blank = !state.menus.hide_blank;
		return {
			...state,
			menus: newState,
		};
	},
	[types.SET_SORT_BY_DATA](state = initialState, action) {
		const newState = state.menus;
		newState.sort_by_data = action.sort_data;
		return {
			...state,
			menus: newState,
		};
	},
	[types.TOGGLE_EXPAND_ALL](state = initialState) {
		const newState = state.menus;
		newState.expand_all = !state.menus.expand_all;
		return {
			...state,
			menus: newState,
		};
	},
	[types.TOGGLE_SORT_DESCENDING](state = initialState) {
		const newState = state.menus;
		newState.sort_descending = !state.menus.sort_descending;
		return {
			...state,
			menus: newState,
		};
	},
	[types.TOGGLE_SITE_MAP](state = initialState) {
		const newState = state.menus;
		newState.site_map = !state.menus.site_map;
		return {
			...state,
			menus: newState,
		};
	},

	[types.TOGGLE_SIDEBAR](state = initialState) {
		const newState = state.menus;
		newState.sidebar_status = !state.menus.sidebar_status;
		return {
			...state,
			menus: newState,
		};
	},
	[types.TOGGLE_SIDEBAR_RIGHT](state = initialState) {
		const newState = state.menus;
		newState.sidebar_status_right = !state.menus.sidebar_status_right;
		return {
			...state,
			menus: newState,
		};
	},
	[types.TOGGLE_REPORT_BAR](state = initialState) {
		const newState = state.menus;
		newState.report_bar = !state.menus.report_bar;
		return {
			...state,
			menus: newState,
		};
	},
	[types.CLOSE_SIDEBAR](state = initialState) {
		const newState = state.menus;
		newState.sidebar_status = false;
		return {
			...state,
			menus: newState,
		};
	},
	[types.OPEN_SIDEBAR](state = initialState) {
		const newState = state.menus;
		newState.sidebar_status = true;
		return {
			...state,
			menus: newState,
		};
	},
	[types.TOGGLE_NAV](state = initialState) {
		const newState = state.menus;
		newState.nav_status = !state.menus.nav_status;
		return {
			...state,
			menus: newState,
		};
	},
	[types.CLOSE_NAV](state = initialState) {
		const newState = state.menus;
		newState.nav_status = false;
		return {
			...state,
			menus: newState,
		};
	},
	[types.OPEN_NAV](state = initialState) {
		const newState = state.menus;
		newState.nav_status = true;
		return {
			...state,
			menus: newState,
		};
	},
	[types.TOGGLE_REPORT_NAV](state = initialState) {
		const newState = state.menus;
		newState.report_menu_status = !state.menus.report_menu_status;
		return {
			...state,
			menus: newState,
		};
	},
	[types.CLOSE_REPORT_NAV](state = initialState) {
		const newState = state.menus;
		newState.report_menu_status = false;
		return {
			...state,
			menus: newState,
		};
	},
	[types.OPEN_REPORT_NAV](state = initialState) {
		const newState = state.menus;
		newState.report_menu_status = true;
		return {
			...state,
			menus: newState,
		};
	},
	[types.SET_VEHICLE_TAB](state = initialState, action) {
		const newState = state.menus;
		newState.vehicle_tab = action.tab;
		return {
			...state,
			menus: newState,
			edit_vehicle: action.vehicle_id,
		};
	},
	[types.SET_WORK_FLOW](state = initialState, action) {
		const newState = state.menus;
		newState.work_flow = action.work_flow;
		return {
			...state,
			menus: newState,
		};
	},
	[types.VIN_SCANNER](state = initialState, action) {
		return {
			...state,
			vin_scanner: action.vin_scanner,
		};
	},
	[types.VIN_DECODED_VEHICLE](state = initialState, action) {
		return {
			...state,
			vin_decoded_vehicle: action.vehicle,
		};
	},
	[types.SHOW_ADD_FILTER](state = initialState, action) {
		return {
			...state,
			show_add_filter: action.show_add_filter,
		};
	},
	[types.SET_LAST_UPDATED](state = initialState, action) {
		return {
			...state,
			last_updated: action.last_updated,
		};
	},
	[types.SET_LOGIN_REDIRECT_DEEP_LINK](state = initialState, action) {
		return {
			...state,
			deep_link: action.deep_link,
		};
	},
	[types.SET_INACTIVE_STATUS](state = initialState, action) {
		return {
			...state,
			inactive_status: action.status,
		};
	},
	[types.SET_CANCEL_OLD_REQUESTS](state = initialState, action) {
		return {
			...state,
			cancel_old_requests: action.status,
		};
	},
	[types.SET_INACTIVE_STATUS_INTERVAL](state = initialState, action) {
		return {
			...state,
			inactive_status_interval: action.id,
		};
	},
	[types.SET_INTERVAL_NUMBER](state = initialState, action) {
		return {
			...state,
			interval_number: action.id,
		};
	},
	[types.SET_INTERVAL_NUMBER_B](state = initialState, action) {
		return {
			...state,
			interval_numberB: action.id,
		};
	},
	[types.SET_INTERVAL_VIN_SCANNER](state = initialState, action) {
		return {
			...state,
			interval_vin_scanner: action.id,
		};
	},
	[types.SET_VIN_SCANNER_HEIGHT](state = initialState, action) {
		return {
			...state,
			vin_scanner_size: {
				height: action.height,
				width: action.width,
			},
		};
	},
	[types.SET_VIN_SCANNER_WIDTH](state = initialState, action) {
		return {
			...state,
			vin_scanner_width: action.width,
		};
	},
	[types.VEHICLE_PAGE_NAME](state = initialState, action) {
		const newState = state.vehicle_pagination;
		newState.page_name = action.page_name;

		return {
			...state,
			vehicle_pagination: newState,
		};
	},
	[types.VEHICLE_PAGE_NUMBER](state = initialState, action) {
		const newState = state.vehicle_pagination;
		newState.page_number = action.page_number;

		return {
			...state,
			vehicle_pagination: newState,
		};
	},
	[types.VEHICLE_PAGE_SIZE](state = initialState, action) {
		const newState = state.vehicle_pagination;
		newState.page_size = action.page_size;

		return {
			...state,
			vehicle_pagination: newState,
		};
	},
	[types.VEHICLE_PAGE_TOTAL](state = initialState, action) {
		const newState = state.vehicle_pagination;
		newState.page_total = action.page_total;

		return {
			...state,
			page_total: newState,
		};
	},
	[types.VEHICLE_PAGE_VEHICLE_TOTAL](state = initialState, action) {
		const newState = state.vehicle_pagination;
		newState.vehicle_total = action.vehicle_total;

		return {
			...state,
			vehicle_total: newState,
		};
	},

	[types.RESET_NAV](state = initialState) {
		return {
			...state,
			page: 'content',
			last_page: '',
			vehicle_filter: {
				type: 'department', value: '', updated: '', sold: false,
			},
			vehicle_pagination: {
				page_size: 50,
				page_number: 1,
				page_total: 1,
				vehicle_total: 50,
				page_name: '',
			},
			deep_link: '',
			vin_scanner: '',
			vin_decoded_vehicle: {
				vin: '', year: '', make: '', model: '', message: '',
			},
			vin_scanner_size: {
				height: 0,
				width: 0,
			},
			last_vehicle_filter: {},
			department: 0,
			last_department: 0,
			show_add_filter: false,
			edit_vehicle: '',
			interval_number: '',
			interval_numberB: '',
			interval_vin_scanner: '',
			last_updated: '',
			inactive_status: false,
			inactive_status_interval: '',
			is_updating: '',
			include_sold: false,
			cancel_old_requests: false,
			menus: {
				toggle_drawer_status: false,
				sidebar_status: true,
				sidebar_status_right: false,
				nav_status: false,
				report_menu_status: false,
				vehicle_tab: 'vehicle-body',
				site_map: false,
				expand_all: true,
				report_bar: false,
				hide_blank: false,
				sort_descending: false,
				sort_by_data: 'days',
				work_flow: 'all',
			},
			error: {},
		};
	},
});

export default 'settings_nav';
