import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import TextFieldGroup from '../../inputs/TextFieldGroup';

class WorkItem extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { work_item_preset } = this.props;
		console.log(work_item_preset);

		return (
			<table>
				<thead />
				<tbody>
			<tr>
				<td>Name: {work_item_preset.original.name}</td>
				<td>Editable: {work_item_preset.original.name_editable}</td>
				<td>Name Options: {work_item_preset.original.name_options}</td>
				<td>Name InputType: {work_item_preset.original.name_inputtype}</td>
				<td>Name Locked: {work_item_preset.original.name_locked}</td>
			</tr>
			<tr>
				<td>Description: {work_item_preset.original.description}</td>
				<td>Description: {work_item_preset.original.description_editable}</td>
				<td>Description Options: {work_item_preset.original.description_options}</td>
				<td>Description InputType: {work_item_preset.original.description_inputtype}</td>
				<td>Description Locked: {work_item_preset.original.description_locked}</td>
			</tr>
			</tbody>
			</table>
		);
	}
}

function mapStateToProps(state) {
	return { work_item_presets: state.settings_refresh.work_item_presets };
}
export default connect(mapStateToProps)(WorkItem);
