import _ from 'lodash';
import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const initialState = {
	support_tickets: [],
	rooms: [],
	room_messages: [],
	support_chat_widget: false,
	support_chat_status: '',
	support_ticket_widget: false,
	support_ticket_status: '',
};

export const settings_support_tickets = createReducer(initialState, {
	[types.SET_SUPPORT_TICKETS](state = initialState, action) {
		return {
			...state,
			support_tickets: action.support_tickets,
		};
	},
	[types.SET_ROOMS](state = initialState, action) {
		return {
			...state,
			rooms: action.rooms,
		};
	},
	[types.SET_ROOM_MESSAGES](state = initialState, action) {
		return {
			...state,
			room_messages: action.room_messages,
		};
	},
	[types.OPEN_SUPPORT_CHAT](state = initialState, action) {
		return {
			...state,
			support_chat_widget: true,
		};
	},
	[types.CLOSE_SUPPORT_CHAT](state = initialState, action) {
		return {
			...state,
			support_chat_widget: false,
		};
	},
	[types.SUPPORT_CHAT_STATUS](state = initialState, action) {
		return {
			...state,
			support_chat_status: action.status,
		};
	},
	[types.OPEN_SUPPORT_TICKET](state = initialState, action) {
		return {
			...state,
			support_ticket_widget: true,
		};
	},
	[types.CLOSE_SUPPORT_TICKET](state = initialState, action) {
		return {
			...state,
			support_ticket_widget: false,
		};
	},
	[types.SUPPORT_TICKET_STATUS](state = initialState, action) {
		return {
			...state,
			support_ticket_status: action.status,
		};
	},
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			support_tickets: [],
			rooms: [],
			room_messages: [],
			support_chat_widget: false,
			support_chat_status: '',
			support_ticket_widget: false,
			support_ticket_status: '',
		};
	},
});

export default 'settings_support_tickets';
