import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addNewDepartmentProperty } from '../../actions/apiDepartmentUpdates';
import { getDepartments, processDepartments } from '../../actions/apiGetInfo';
import {
	setCurrentTab,
	setCurrentDefaultProfile,
} from '../../actions/apiSetInfo';
import {
	activeEditVehicleColumn,
	activeEditDepartment,
} from '../../actions/lookups';

class AddColumnToDepartmentPropertyButton extends Component {
	constructor(props) {
		super(props);

		this.state = { errors: { addDepartmentError: false } };
		this.addNewDepartmentProperty = this.addNewDepartmentProperty.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	addNewDepartmentProperty() {
		const { vehicle_column } = this.props;
		const id = this.props.edit_department.id;
		const errorType = 'addNewDepartmentProperty';
		const data = {
			department_id: id,
			vehicle_column_id: vehicle_column.original.vehicle_column_id[0],
			order: '99',
		};

		Promise.all([this.props.addNewDepartmentProperty(id, data, errorType),
		]).then(() => this.props.processDepartments()).then(() => this.props.activeEditDepartment(id));
	}

	render() {
		const { errors } = this.state;
		return (
			<button className="btn btn-sm btn-success" onClick={this.addNewDepartmentProperty}>
					Add to {this.props.edit_department.name}
				</button>
		);
	}
}

const mappedActions = {
	addNewDepartmentProperty,
	getDepartments,
	processDepartments,
	setCurrentTab,
	activeEditVehicleColumn,
	activeEditDepartment,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.original_departments,
		input_types: state.settings_departments.input_types,
		edit_department: state.settings_admin.edit_department,
		vehicle_columns: state.settings_departments.vehicle_columns,
	};
}
export default connect(mapStateToProps, mappedActions)(AddColumnToDepartmentPropertyButton);
