import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';

class ChartSingleWorkFlowPie extends Component {
	componentDidMount() {
		this.highChartsRender();
	}

	shouldComponentUpdate() {
		return false;
	}

	disposeChart() {
		const idx = Highcharts.charts.indexOf(this.chart);
		if (idx !== -1) {
			Highcharts.charts.splice(idx, 1);
			this.chart.destroy();
		}
		this.chart = undefined;
	}

	highChartsRender() {
		const self = this;
		const seriesArray = [];
		let series = [];
		const report_dealerships = self.props.report_dealerships.split(',');

		report_dealerships.map((dealership) => {
			if (dealership !== '') {
				const data = self.props.data.yAxis[dealership];
				series = {
					name: `${dealership}`,
					data,
					tooltip: {
						valueSuffix: ' Days',
					},
				};
				seriesArray.push(series);
			}
		});
		if (this.chart) {
			this.disposeChart();
		}
		this.chart = Highcharts.chart('monthly-chart', {
		    chart: {
				backgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				options3d: {
					enabled: true,
					alpha: 45,
				},
			},
			credits: {
				enabled: false,
			},
			title: {
				text: `${this.props.report_title}`,
			},
			xAxis: [{
				categories: this.props.data.xAxis,
				crosshair: true,
				labels: {
					style: {
		                color: 'black',
		            },
				},
			}],
			yAxis: [{
				labels: {
					format: '{value} Days',
					style: {
		                color: 'black',
		            },
				},
				title: {
					text: 'Average Recon Times',
				},
			}, {
				opposite: true,
			}],
			tooltip: {
				pointFormat: '{point.y:.1f} Days <b>{point.percentage:.1f}%</b>',
			},
			plotOptions: {
			    pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					innerSize: 100,
					depth: 45,
					dataLabels: {
						enabled: true,
						format: '<b>{point.y:.1f}',
						style: {
							color: '#000;',
							textShadow: false,
						},
						connectorColor: 'silver',
					},
				},
			    series: {
			    	cursor: 'pointer',
		            point: {
		                events: {
		                    click() { console.log(this); },
		                },
		            },
			    },
			  },
			series: seriesArray,
	  	});
	}

	componentWillUnmount() {
		this.disposeChart();
	}

	render() {
		var report_key = this.props.report_id;
		return (
			<div className="container space-top-1 space-bottom-1 pad-bottom-1 pad-top-2 bg-light shadow_4">
				<div id="monthly-chart" key="steps-chart" />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		report_dealerships: state.settings_reports.report_dealerships || '',
		report_start_date: state.settings_reports.report_start_date,
		report_end_date: state.settings_reports.report_end_date,
	};
}

export default connect(mapStateToProps)(ChartSingleWorkFlowPie);