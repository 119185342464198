import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class PrintTab extends PureComponent {
	render() {
		return (
			<div className="container">PrintTab</div>
		);
	}
}

export default PrintTab;
