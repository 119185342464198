import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import TextBox from '../../inputs/TextBox';
import {
	getVehicleByVinAndSubdomain,
} from '../../actions/apiGetInfo';
import {
	getDateTime,
} from '../../utils/dateFunctions';
import {
	vsearch,
} from '../../actions/lookups';

class VinSearch extends Component {
	constructor(props) {
		super(props);
		const vin_link = this.props.match.params.vinToken.split(':');
		this.state = {
			subdomain: vin_link[0], vin: vin_link[1], vehicle: {},
		};
		this.setLink = this.setLink.bind(this);
	}

	setLink() {
		this.props.vsearch(this.state.vin, true);
		this.setState({ vehicle: this.props.active_vehicle });
		const { history } = this.props;
		history.push('/');
	}

	componentDidMount() {
		Promise.all([this.props.getVehicleByVinAndSubdomain(this.state.vin, this.state.subdomain)]).then(() => {
			this.props.vsearch(this.state.vin, true);
		});
	}

	render() {
		const { errors } = this.state;
		const vehicle = this.props.active_vehicle;
		const vehicle_properties = vehicle.vehicle_properties;

		const vin_1_passed = _.find(vehicle_properties, { key: 'Correct VIN plate and anti-theft labels_passed' }) || [];

		return (
			<div className="row bg-white">

				<div className="container space-bottom-2">
					<li className="text-left text-dark list-group-item">
						{vehicle.year}
						{' '}
						{vehicle.make}
						{' '}
						{vehicle.model}
						{' '}
						{vehicle.trim}
						{' '}
VIN:
						{' '}
						{vehicle.vin}
						{' '}
STOCK:
						{' '}
						{vehicle.stock}
						<button className="btn-success">Go to Vehicle</button>
					</li>
				</div>
			</div>

		);
	}
}

const mappedActions = {
	getVehicleByVinAndSubdomain,
	vsearch,
};

function mapStateToProps(state) {
	return {
		active_vehicle: state.settings_refresh.active_vehicle,
		current_user_state: state.current_user_state,
	};
}
export default withRouter(connect(mapStateToProps, mappedActions)(VinSearch));
