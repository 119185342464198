import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';
import EditTabStyle from './EditTabStyle';
import EditTabProperties from '../../inputs/EditTabProperties';

class EditEstimates extends Component {
	constructor(props) {
		super(props);

		this.state = { errors: { editTab: false }, option_name: '' };
	}

	render() {
		const self = this;
		const { props } = this;

		return (
			<div className="col-md-12">
				<h3 className="text-center">Form Settings</h3>

				<div className="card space-top-1">

					<div className="card-header">
						<div className="col-md-12">
							<small>
								<i className={'fa fa-info-circle text-info'} />
								{' '}
Ro/Item Number.
							</small>
						</div>
					</div>

					<div className="card-body">

						<EditTabProperties {...this.props} type={'text-prepend'} name={'ro_number_label'} label={'Ro Number Label'} value={'Ro Number'} dataid={this.props.tab.id} />
						<EditTabProperties {...this.props} type={'switch'} name={'ro_number_required'} label={'Required (not blank)'} value={'true'} dataid={this.props.tab.id} />
						<EditTabProperties {...this.props} type={'switch'} name={'ro_number_lockable'} label={'Lockable'} value={'true'} dataid={this.props.tab.id} />
						<EditTabProperties {...this.props} type={'switch'} name={'ro_number_lockable'} label={'Include in Notification'} value={'true'} dataid={this.props.tab.id} />

					</div>
				</div>

				<div className="card space-top-1">

					<div className="card-text">

						<h3>Form Settings</h3>

						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Label</th>
									<th>Required?</th>
									<th>Allow Lock</th>
									<th>Include in Notification</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td />
									<td />
									<td />
									<td />
									<td />
								</tr>

							</tbody>
						</table>

						<h3>Description</h3>

						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Label</th>
									<th>Required?</th>
									<th>Allow Lock</th>
									<th>Include in Notification</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td />
									<td />
									<td />
									<td />
									<td />
								</tr>

							</tbody>
						</table>

						<h3>Amount</h3>

						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Label</th>
									<th>Required?</th>
									<th>Allow Lock</th>
									<th>Include in Notification</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td />
									<td />
									<td />
									<td />
									<td />
								</tr>

							</tbody>
						</table>

						<h3>Attachments</h3>
						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Label</th>
									<th>Required?</th>
									<th>Allow Lock</th>
									<th>Include in Notification</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td />
									<td />
									<td />
									<td />
									<td />
								</tr>

							</tbody>
						</table>

						<h3>Recipients</h3>
						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Label</th>
									<th>Required?</th>
									<th>Allow Lock</th>
									<th>Include in Notification</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td />
									<td />
									<td />
									<td />
									<td />
								</tr>

							</tbody>
						</table>

						<h3>Notification Settings</h3>
						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Label</th>
									<th>Required?</th>
									<th>Allow Lock</th>
									<th>Include in Notification</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td />
									<td />
									<td />
									<td />
									<td />
								</tr>

							</tbody>
						</table>

						<h3>Status Settings</h3>
						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Label</th>
									<th>Required?</th>
									<th>Allow Lock</th>
									<th>Include in Notification</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td />
									<td />
									<td />
									<td />
									<td />
								</tr>

							</tbody>
						</table>

						<h3>Lockable</h3>
						<table className="table table-striped table-sm">
							<thead className={'thead text-center'}>
								<tr>
									<th>Label</th>
									<th>Required?</th>
									<th>Allow Lock</th>
									<th>Include in Notification</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td />
									<td />
									<td />
									<td />
									<td />
								</tr>

							</tbody>
						</table>

					</div>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		edit_tab: state.settings_admin.edit_tab,
	};
}
export default withRouter(connect(mapStateToProps)(EditEstimates));
