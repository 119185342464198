import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as AllMutates from './AllMutates';

export function updateAudits(id, data, errorType) {
	return dispatch => Api.patchData(`audits/${id}`, data).then(() => {
		dispatch(getInfo.getAudits());
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addAudit(data, errorType) {
	return (dispatch, getState) => Api.postData('audits/', data).then(resp => dispatch(getInfo.getAudits())).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteAudit(id, errorType) {
	return dispatch => Api.delete(`audits/${id}`).then(() => {
		dispatch(getInfo.getAudits());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addNewAuditProperty(id, data, errorType) {
	return dispatch => Api.postData('audit_properties/', data);
}
