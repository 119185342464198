import React, { useEffect } from 'react'
import sha256 from 'crypto-js/sha256'
import cryptoRandomString from 'crypto-random-string'

let sso_url = "https://auth.autoipacket.com/"

if (window.ENV && window.ENV.SSO_URL) {
  sso_url = window.ENV.SSO_URL
}

export default function LoginPage(props) {
  const { require_login } = props
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    let pkce_unhashed = cryptoRandomString({ length: 128, type: 'alphanumeric' })
    let pkce_hashed = sha256(pkce_unhashed)

    localStorage.setItem('pendingLogin', JSON.stringify({ pkce_hashed, pkce_unhashed }))
    console.log(localStorage.pendingLogin)
    if (window.location.hostname === 'localhost') {
      window.location.href = `${sso_url}?redirect=http://${
        window.location.host
      }/auth&pkce=${pkce_hashed}&client_id=8931252${require_login ? '&require_login=true' : ''}`
    } else {
      window.location.href = `${sso_url}?redirect=https://${
        window.location.host
      }/auth&pkce=${pkce_hashed}&client_id=8931252${require_login ? '&require_login=true' : ''}`
    }
  }, [])
  return <React.Fragment />
}
