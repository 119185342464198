import { combineReducers } from 'redux';
import * as settings_site from './settings_site';
import * as settings_nav from './settings_nav';
import * as settings_admin from './settings_admin';
import * as settings_refresh from './settings_refresh';
import * as settings_departments from './settings_departments';
import * as settings_reports from './settings_reports';
import * as settings_users from './settings_users';
import * as settings_feed from './settings_feed';
import * as current_user from './current_user';
import * as flashMessages from './flashMessages';
import * as settings_dealerships from './settings_dealerships';
import * as settings_vin_scanner from './settings_vin_scanner';
import * as settings_support_tickets from './settings_support_tickets';
import authentication from './authenticationReducer'

const rootReducer = combineReducers({
  	...settings_site,
	...settings_nav,
	...settings_admin,
	...settings_refresh,
	...settings_departments,
	...settings_reports,
	...settings_users,
	...settings_feed,
	...settings_dealerships,
	...settings_vin_scanner,
	...current_user,
	...flashMessages,
	...settings_support_tickets,
  authentication,
});

export default rootReducer;
