import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AllInputs from '../../../../inputs/AllInputs';
import DropBoxEstimate from '../../../../inputs/FileUploadButton/DropBoxEstimate';
import { updateVehicleDepartment } from '../../../../actions/AllUpdates';
import {
	getDateTime,
	readableStamp,
} from '../../../../utils/dateFunctions';
import {
	deleteEstimate,
	sendEstimate,
} from '../../../../actions/apiEstimates';
import {
	appraisalRequestNotification,
	appraisalNotification,
} from '../../../../actions/apiNotifications';
import {
	lookupUserById,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
} from '../../../../actions/lookups';
import FileAttachmentSingle from './FileAttachmentSingle';

let estimateFields = [{ name: 'ro_number', label: 'Ro Number', type: 'text' },
	{ name: 'recipients', label: 'Recipients', type: 'select-recipients' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'estimate', label: 'Amount', type: 'dollar' }];

const classicFields = [{ name: 'ro_number', label: 'Ro Number', type: 'text' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'estimate', label: 'Amount', type: 'dollar' }];

const freddyFields = [{ name: 'ro_number', label: 'Ro Number', type: 'text' },
	{ name: 'recipients', label: 'Recipients', type: 'select-recipients' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'item_1', label: 'Parts', type: 'number' },
	{ name: 'item_2', label: 'Labor', type: 'number' },
	{ name: 'item_3', label: 'Hours', type: 'number' },
	{ name: 'estimate', label: 'Total', type: 'dollar' }];

const astorgFields = [{ name: 'ro_number', label: 'Ro Number', type: 'text' },
	{ name: 'recipients', label: 'Recipients', type: 'select-recipients' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'item_1', label: 'Parts price', type: 'number' },
	{ name: 'item_2', label: 'Labor units', type: 'number' },
	{ name: 'item_3', label: 'Labor price', type: 'number' },
	{ name: 'estimate', label: 'Total', type: 'dollar' }];

const stanleyFields = [{ name: 'ro_number', label: 'ROs', type: 'text' },
	{ name: 'type_item_1', label: 'Created', type: 'date' },
	{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'estimate', label: 'Est Amount', type: 'dollar' }];

const allFields = ['vehicle_id', 'ro_number', 'requested_by_uid', 'description', 'estimate',
	'actual', 'ro_status', 'approved_by_uid', 'approved_date', 'declined_by_uid', 'declined_date', 'ro_files',
	'declined_reason', 'ro_email_link_declined', 'ro_email_link_approved'];

const classicArray = [15143, 16596];

class EstimateSingle extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addDeleteEstimateError: '',
			denied_reason: false,
			vehicle_id: this.props.active_vehicle.id,
			ro_number: this.props.estimate.ro_number,
			requested_by_uid: this.props.estimate.requested_by_uid,
			requested_date: this.props.estimate.requested_date,
			description: this.props.estimate.description,
			estimate: this.props.estimate.estimate,
			actual: this.props.estimate.actual,
			ro_status: this.props.estimate.ro_status,
			approved_by_uid: this.props.estimate.approved_by_uid,
			approved_date: this.props.estimate.approved_date,
			declined_by_uid: this.props.estimate.declined_by_uid,
			declined_date: this.props.estimate.declined_date,
			ro_files: this.props.estimate.ro_files,
			declined_reason: this.props.estimate.declined_reason,
			ro_email_link_declined: this.props.estimate.ro_email_link_declined,
			ro_email_link_approved: this.props.estimate.ro_email_link_approved,
		};
		this.onChange = this.onChange.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSend = this.onSend.bind(this);
		this.onApprove = this.onApprove.bind(this);
		this.onDeny = this.onDeny.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	componentDidMount() {
		const estimate = this.props.estimate;

		this.setState({
			addDeleteEstimateError: '',
			denied_reason: false,
			vehicle_id: this.props.active_vehicle.id,
			ro_number: estimate.ro_number,
			requested_by_uid: estimate.requested_by_uid,
			requested_date: estimate.requested_date,
			description: estimate.description,
			estimate: estimate.estimate,
			actual: estimate.actual,
			ro_status: estimate.ro_status,
			approved_by_uid: estimate.approved_by_uid,
			approved_date: estimate.approved_date,
			declined_by_uid: estimate.declined_by_uid,
			declined_date: estimate.declined_date,
			ro_files: estimate.ro_files,
			declined_reason: estimate.declined_reason,
			ro_email_link_declined: estimate.ro_email_link_declined,
			ro_email_link_approved: estimate.ro_email_link_approved,
		});
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onDelete(e) {
		this.props.deleteEstimate(this.props.estimate.id, this.props.active_vehicle.id, 'addDeleteEstimateError');
	}

	onClick(e) {
	}

	onSend(e) {
		const self = this;
		var estimate = this.props.estimate;
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			requested_by_uid: this.props.current_user.user,
			requested_date: getDateTime(),
			ro_status: 'pending',
			lock_estimate: 'true',
		};
		this.props.sendEstimate(this.props.estimate.id, data, 'addEstimateError');

		const message = `Vehicle Vin:${this.props.active_vehicle.vin} Stock: ${this.props.active_vehicle.stock} requires your approval. Vehicle is currently located in the ${this.props.lookupDepartmentById(this.props.active_vehicle.department_id).name} stage`;
		const { recipients } = estimate;

		let recipientsArray = recipients.split(',');

		if (this.props.installation.database.includes('classic')) {
			recipientsArray = classicArray;
		}

		recipientsArray.map((user_id) => {
			if (parseInt(user_id) > 0) {
				const data2 = {
					note_type: 'appraisal_request',
					message,
					user_id: parseInt(user_id),
					data_id: this.props.estimate.id,
					vehicle_id: this.props.active_vehicle.id,
				};
				this.props.appraisalRequestNotification(data2, 'approvalRequestNotification');
			}
		});

		if (this.props.installation.softname.toLowerCase().includes('stanley')) {
			const department = this.props.lookupDepartmentByName('pending approval');
			if (department.id) {
				setTimeout(() => { self.props.updateVehicleDepartment(self.props.vehicle.id, 'department_id', parseInt(department.id), self.props.active_vehicle); }, 5000);
			}
		}
	}

	onApprove(e) {
		const self = this;
		const subdomain = this.props.installation.subdomain;
		const message = 'Estimate Approved';
		const data = {
			requested_by_uid: this.state.requested_by_uid,
			ro_status: 'approved',
			approved_by_uid: this.props.current_user.user,
			approved_date: getDateTime(),
			vehicle_id: this.props.active_vehicle.id,
		};

		if (this.props.installation.softname.toLowerCase().includes('stanley')) {
			const department = this.props.lookupDepartmentByName('pending parts');
			if (department.id) {
				setTimeout(() => { self.props.updateVehicleDepartment(self.props.vehicle.id, 'department_id', parseInt(department.id), self.props.active_vehicle); }, 5000);
			}
		} else if (this.props.installation.database.includes('classic')) {
			const data2 = {
				note_type: 'appraisal_approved',
				message,
				user_id: 15386,
				data_id: this.props.estimate.id,
				vehicle_id: this.props.estimate.vehicle_id,
			};
			this.props.appraisalNotification(data2, 'approvalRequestNotification', subdomain);

			const data3 = {
				note_type: 'appraisal_approved',
				message,
				user_id: 15142,
				data_id: this.props.estimate.id,
				vehicle_id: this.props.estimate.vehicle_id,
			};
			this.props.appraisalNotification(data3, 'approvalRequestNotification', subdomain);
		} else {
			const data2 = {
				note_type: 'appraisal_approved',
				message,
				user_id: parseInt(this.props.estimate.requested_by_uid),
				data_id: this.props.estimate.id,
				vehicle_id: this.props.estimate.vehicle_id,
			};
			this.props.appraisalNotification(data2, 'approvalRequestNotification', subdomain);
		}

		this.props.sendEstimate(this.props.estimate.id, data, 'addEstimateError');
	}

	onDeny(e) {
		const subdomain = this.props.installation.subdomain;
		const message = 'Estimate Declined';
		this.setState({ denied_reason: true });
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_status: 'declined',
			declined_by_uid: this.props.current_user.user,
			declined_date: getDateTime(),
		};

		this.props.sendEstimate(this.props.estimate.id, data, 'addEstimateError');

		if (this.props.installation.database.includes('classic')) {
			const data2 = {
				note_type: 'appraisal_declined',
				message,
				user_id: 15386,
				data_id: this.props.estimate.id,
				vehicle_id: this.props.estimate.vehicle_id,
			};
			this.props.appraisalNotification(data2, 'approvalRequestNotification', subdomain);

			const data3 = {
				note_type: 'appraisal_declined',
				message,
				user_id: 15142,
				data_id: this.props.estimate.id,
				vehicle_id: this.props.estimate.vehicle_id,
			};
			this.props.appraisalNotification(data3, 'approvalRequestNotification', subdomain);
		} else {
			const data2 = {
				note_type: 'appraisal_declined',
				message,
				user_id: parseInt(this.props.estimate.requested_by_uid),
				data_id: this.props.estimate.id,
				vehicle_id: this.props.estimate.vehicle_id,
			};
			this.props.appraisalNotification(data2, 'approvalRequestNotification', subdomain);
		}
	}

	render() {
		self = this;
		var setdisabled = true;
		const html = [];
		const filerow = [];
		var estimate = this.props.estimate;
		var vehicle = this.props.active_vehicle;

		if (estimate.ro_status === 'pending') {
			setdisabled = false;
		}

		if (estimate.ro_status === 'approved') {
			setdisabled = true;
		}

		if (estimate.ro_status == 'getapproved') {
			setdisabled = false;
		}

		if (this.props.current_dealership === 'fredychevy') {
			estimateFields = freddyFields;
		}

		if (this.props.installation.database.includes('classic')) {
			estimateFields = classicFields;
		}
		if (this.props.installation.database.includes('astorg')) {
			estimateFields = astorgFields;
		}

		if (this.props.installation.database.includes('stanley')) {
			estimateFields = stanleyFields;

			if (estimate.ro_status === 'pending' || estimate.ro_status === 'approved') {
				html.push(<div key={`approved-col_${estimate.id}`} className="p-2">
					<label>Approved Amount</label>
					<AllInputs
						value={estimate.type_item_2 || ''}
						type={'dollar'}
						name={'type_item_2'}
						parentid={this.props.active_vehicle.id}
						estimate_id={estimate.id}
						updatetype="estimate"
						setdisabled={setdisabled}
						user_name={''}
						updated_at={''}
						vehicle={vehicle}
					/>
				</div>);
			}
		}

		filerow.push(<div key={`approved-col_${estimate.id}`} className="col-md-6">
					<label>Add Files</label>
					<DropBoxEstimate vid={vehicle.id} siteid={`${this.props.current_dealership}_${vehicle.id}`} estimate_id={estimate.id} />
				</div>);

			filerow.push(<ul className="list-group col-md-6">
									{vehicle.attachments.map(attachment => {
										if (attachment.appraisal_id === estimate.id) {
											return <FileAttachmentSingle key={`attach_${attachment.id}`} attachment={attachment} vehicle_id={vehicle.id} data_id={attachment.id} />;
										}
										})}
								</ul>);

		return (
			<div className="row">
			<div key={`estimate_row_${this.props.active_vehicle.id}_${estimate.id}`} className="card flex-row">
				<div className="p-2">
					<small>
ID:
						{estimate.id}
					</small>
				</div>
            	{estimateFields.map(option => <div key={`estimate_col_${this.props.active_vehicle.id}_${estimate.id}_${option.name}`} className="p-2">
            		<div className="row">
            			<div className="col-md-12 pad-left-1 pad-right-1">
            				<label>{option.label}</label>
							<AllInputs
								value={estimate[option.name] || ''}
								type={option.type}
								name={option.name}
								parentid={this.props.active_vehicle.id}
								estimate_id={estimate.id}
								updatetype="estimate"
								setdisabled={setdisabled}
								user_name={''}
								updated_at={''}
								vehicle={vehicle}

							/>
						</div>
					</div>
                                           </div>)}

				{html}
				<div className="p-2 align-self-center">
					<div className="d-flex flex-column">
						{(() => {
							switch (estimate.ro_status) {
							case 'pending':
								return (
									<div className="p-2">
										<p className="text-center">
											<small className="text-capitalize" key={`requested_sign_${estimate.id}`}>
											Requested By:
												{' '}
												{estimate.requested_by_uid ? this.props.lookupUserById(estimate.requested_by_uid).user_name : ''}
												<br />
												{estimate.requested_date ? readableStamp(estimate.requested_date) : ''}
											</small>
										</p>
										<div className={'btn-group'} role="group">
											<button className="btn btn-sm btn-success" onClick={self.onApprove}>Approve</button>
											<button className="btn btn-sm btn-danger" onClick={self.onDeny}>Deny</button>
										</div>
									</div>
								);

							case 'approved':
								return (
									<button className="btn btn-sm btn-success text-capitalize">
									Approved
										{' '}
										{estimate.approved_by_uid ? this.props.lookupUserById(estimate.approved_by_uid).user_name : ''}
										{estimate.approved_date ? readableStamp(estimate.approved_date) : ''}
									</button>
								);

							case 'declined':
								return <div className="p-2">
									<div className="p-2">
										<button className="btn btn-sm btn-danger">
										Denied
											{' '}
											{estimate.declined_by_uid ? this.props.lookupUserById(estimate.declined_by_uid).user_name : ''}
											{estimate.declined_date ? readableStamp(estimate.declined_date) : ''}
										</button>
									</div>
									<div className="p-2">Denied Reason:</div>
									<div className="p-2">{this.state.declined_reason}</div>
								</div>;

							default:
								return <button className="btn btn-sm btn-success" onClick={self.onSend}>Get Approved</button>;
							}
						})()}
					</div>

				</div>

				<div className="ml-auto p-2">
					<button className="btn btn-sm btn-danger" onClick={self.onDelete}>X</button>
				</div>
				</div>
				<div className="row">
				{filerow}
				</div>
			</div>
		);
	}
}

const mappedActions = {
	lookupUserById,
	lookupDepartmentById,
	lookupDepartmentByName,
	lookupVehicleEstimateById,
	deleteEstimate,
	sendEstimate,
	appraisalRequestNotification,
	updateVehicleDepartment,
	appraisalNotification,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		all_users: state.settings_users.all_users,
		current_dealership: state.settings_dealerships.current_dealership,
		installation: state.settings_dealerships.installation,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(EstimateSingle);
