import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDealershipSettings from '../../inputs/EditDealershipSettings';
import EditInstallation from '../../inputs/EditInstallation';
import DropBoxFeed from '../../inputs/FileUploadButton/DropBoxFeed';

class FeedSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value1: this.props.override_value_1, current_default_profile: this.props.current_default_profile, errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');
		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	render() {
		const { installation } = this.props;
		const html = [];

		return (
			<div className="col-md-12">

				<h3 className="text-center pad-top-1">
Feed INFO for {installation.subdomain}
				</h3>

				<div className="card bg-light space-top-1 mx-auto w-75 p-3">
				<div className="row">
						<div className="col-md-6">
							<EditInstallation {...this.props} type={'select-provider'} name="provider" label="Feed Provider" value={installation.provider} parentid={installation.id} />
						</div>
						<div className="col-md-6">
							<EditInstallation {...this.props} type={'text'} name="feedprefix" label="Feed File Name (With Ext)" value={installation.feedprefix} parentid={installation.id} />
						</div>
						<div className="col-md-6">
							<EditInstallation {...this.props} type={'text'} name="feed_contact" label="Feed Contact" value={installation.feed_contact} parentid={installation.id} />
						</div>
						<div className="col-md-6">
							<EditInstallation {...this.props} type={'text'} name="feed_email" label="Feed Email" value={installation.feed_email} parentid={installation.id} />
						</div>
					</div>

					<div className="row">

						<div className="col-md-6">
							<EditInstallation {...this.props} type={'text'} name="vendor_feedprefix" label="Vendor Feed Prefix" value={installation.vendor_feedprefix} parentid={installation.id} />
						</div>
						<div className="col-md-6">
							<EditInstallation {...this.props} type={'text'} name="photo_feedprefix" label="Photo Feed Prefix" value={installation.photo_feedprefix} parentid={installation.id} />
						</div>
						<div className="col-md-6">
							<EditInstallation {...this.props} type={'text'} name="appraisal_feedprefix" label="Appraisal Feed Prefix" value={installation.appraisal_feedprefix} parentid={installation.id} />
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'switch'} name="feed_override_sold" label="Override Sold Safety" value={this.dealerSettingValue('feed_override_sold')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'switch'} name="feed_exclude_new" label="Exclude New" value={this.dealerSettingValue('feed_exclude_new')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'text'} name="feed_sold_percentage" label="Sold Safety Percentage" value={this.dealerSettingValue('feed_sold_percentage')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'switch'} name="feed_safe_purge" label="Turn on Safe Purge" value={this.dealerSettingValue('feed_safe_purge')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'switch'} name="feed_shared" label="Feed Shared" value={this.dealerSettingValue('feed_shared')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'text'} name="feed_shared_with" label="Dealers that Share Feed (seperate by comma)" value={this.dealerSettingValue('feed_shared_with')} />
						</div>
					</div>
					<div className="col-md-12">
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from1" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from1')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from2" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from2')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from3" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from3')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from4" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from4')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from5" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from5')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from6" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from6')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from7" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from7')} />
						</div>
						<div className="col-md-6">
							<EditDealershipSettings {...this.props} type={'select-departments'} name="feed_only_purge_from8" label="Only purge while in stage:" value={this.dealerSettingValue('feed_only_purge_from8')} />
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<div className="card card-default">
								<div className="card-heading" />
								<div className="card-body drop-container">
									<DropBoxFeed {...this.props} installation={installation.id} siteid={`${installation.id}_feed`} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dealer_settings: state.settings_site.dealer_settings,
		installation: state.settings_dealerships.installation,
	};
}
export default connect(mapStateToProps)(FeedSettings);
