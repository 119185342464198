import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteVehicleColumnOption } from '../../actions/apiDepartmentUpdates';

class DisplayOptions extends Component {
	constructor(props) {
		super(props);
		this.state = { value: this.props.value };
		this.deleteOption = this.deleteOption.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({ value: e.value });
	}

	deleteOption(e) {
		const id = e.target.dataset.dbid;
		const errorType = 'delete option';
		const { column_id } = this.props;
		this.props.deleteVehicleColumnOption(id, column_id, errorType);
	}

	render() {
		const id = this.props.edit_vehicle_column.id;

		const edit_vehicle_column = this.props.edit_vehicle_column || [];
		let cols = [];

		if (typeof edit_vehicle_column.options !== 'undefined') {
			cols = edit_vehicle_column.options.map(option => <li className="list-group-item" key={`opt_${edit_vehicle_column.id}_${option.id}`}>
				{option.name}
				{' '}
				<button className="btn btn-sm btn-danger float-end" data-dbid={option.id} onClick={this.deleteOption}>x</button>
                                                    </li>);
		}

		return (
			<div className="p-2">
				<div className="showOptions">
					<ul className="list-group">
						<li className="list-group-item blockquote text-center">Add/Delete Options</li>
						{cols}
					</ul>
				</div>
			</div>

		);
	}
}

const mappedActions = {
	deleteVehicleColumnOption,
};

function mapStateToProps(state) {
	return {
		vehicle_columns: state.settings_departments.vehicle_columns,
		edit_vehicle_column: state.settings_admin.edit_vehicle_column,
	};
}
export default connect(mapStateToProps, mappedActions)(DisplayOptions);
