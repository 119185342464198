import _ from 'lodash';
import Api from '../api/reconApi.js';
import * as setInfo from './apiSetInfo';
import setAuthorizationToken from '../api/setAuthorizationToken';
import * as AllUpdates from './AllUpdates';
import * as lookups from './lookups';
import * as Auth from './authentication';
import * as ApiReports from './apiReports';
import * as apiNotifications from './apiNotifications';
import * as AllMutates from './AllMutates';
import * as dateFunctions from '../utils/dateFunctions';

export function getUser() {
	return (dispatch) => {
		const user = localStorage.reconUser;
		const token = localStorage.reconToken;
		if (parseInt(user) > 0) {
			const browser = getBrowser();
			setAuthorizationToken(token, user);

			return Api.getUser(token, user).then((resp) => {
				const username = resp.data.user_name;
				const userAll = resp.data;
				localStorage.setItem('reconUserName', username);
				localStorage.setItem('reconToken', token);
				localStorage.setItem('reconUser', user.toString());

				dispatch(setInfo.setCurrentUser({ user: userAll, token }));
				dispatch(getDealerships());
				dispatch(getRelatedInstallations());
			}).catch((ex) => {
				if (ex.response.status === 500) {
					localStorage.setItem('reconToken', '');
					localStorage.setItem('reconUser', '');
					localStorage.setItem('reconUserName', '');
					dispatch(Auth.logout());
					dispatch(setInfo.setDealership(''));
					dispatch(setInfo.resetRefresh(''));
					dispatch(setInfo.resetNav(''));
					dispatch(setInfo.resetAdmin(''));
					dispatch(setInfo.resetSite(''));
					location.reload(true);
				}
			});
		}
	};
}

export function getUsers() {
	return (dispatch, getState) => Api.get('users?embed=user_installations&q[deleted_eq]=false', '').then((resp) => {
		const installations = getState().settings_dealerships.related_installations;

		const installationArray = [];
		const userArray = [];

		installations.map((installation) => {
			installationArray.push(installation.id);
		});

		_.filter(resp.data, o => o.user_installations.map((installation) => {
			if (installationArray.includes(installation.installation_id)) {
				userArray.push(o.id);
			}
		}));

		const users = _.filter(resp.data, o => userArray.includes(o.id));

		dispatch(setInfo.setUsers(users));
	}).catch(() => {
	});
}

export function getRelatedUsers() {
	return (dispatch, getState) => Api.get('users?embed=user_installations&q[deleted_eq]=false', '').then((resp) => {
		const installations = getState().settings_dealerships.related_installations;
		const { current_dealership_id } = getState().settings_dealerships;

		const installationArray = [];
		const userArray = [];
		const dealerUsers = [];

		installations.map((installation) => {
			installationArray.push(installation.id);
		});

		_.filter(resp.data, o => o.user_installations.map((installation) => {
			if (installationArray.includes(installation.installation_id)) {
				userArray.push(o.id);
			}
		}));

		const users = _.filter(resp.data, o => userArray.includes(o.id));

		dispatch(setInfo.setUsers(users));
	}).catch(() => {
	});
}

export function getRelatedUsers2() {
	return (dispatch, getState) => {
		const { set_dealerships } = getState().settings_dealerships;
		const { related_installations } = getState().settings_dealerships;
		const { current_dealership_id } = getState().settings_dealerships;
		const { all_users } = getState().settings_users;

		const userArray = [];
		const dealershipArray = [];

		var dealers = [];

		if (related_installations.length > 0) {
			dealers = related_installations;
		} else {
			dealers = set_dealerships;
		}

		dealers.map((dealership) => {
			dealership.user_installations.map((installation) => {
				userArray.push(installation.user_id);
				if (current_dealership_id === installation.installation_id) {
					dealershipArray.push(installation.user_id);
				}
			});
		});

		const users = [...new Set(userArray)];

		const newArray = [];

		const relatedArray = _.filter(all_users, o => userArray.includes(o.id));
		const dealershipUsers = _.filter(all_users, o => dealershipArray.includes(o.id));
		const estimateUsers = _.filter(all_users, o => dealershipArray.includes(o.id) && o.approve_estimates);

		dispatch(setInfo.setUsers(relatedArray));
	};
}

export function getUserCheck() {
	return (dispatch) => {
		const user = localStorage.reconUser;
		const token = localStorage.reconToken;

		return Api.getUser(token, user).then((resp) => {
		}).catch((ex) => {
			if (ex) {
				localStorage.setItem('reconToken', '');
				localStorage.setItem('reconUser', '');
				localStorage.setItem('reconUserName', '');
				dispatch(Auth.logout());
				dispatch(setInfo.setDealership(''));
				dispatch(setInfo.resetRefresh(''));
				dispatch(setInfo.resetNav(''));
				dispatch(setInfo.resetAdmin(''));
				dispatch(setInfo.resetSite(''));
				location.reload(true);
			}
		});
	};
}

export function getDealerships() {
	return (dispatch, getState) => Api.get('installations?embed=user_installations&q[active_eq]=true', '').then((resp) => {
		const { user } = getState().current_user;
		const installs = [];

		resp.data.map((option) => {
			option.user_installations.map((o) => {
				if (o.user_id === user) {
					installs.push(o.installation_id);
				}
			});
		});

		var dealers = [];

		dealers = resp.data;
		dispatch(setInfo.setDealerships({ dealerships: _.sortBy(dealers, 'subdomain') }));

		const newArray = [];

		if (dealers.length === 1) {
			dispatch(setInfo.setSingleDealership(true));
			dispatch(setInfo.setCurrentDealership(dealers[0].database, dealers[0].subdomain, dealers[0].id));
		}

		return 'success';
	}).catch((ex) => {
		const errorType = 'getDealerships';
		return Api.handleApiError(ex, errorType);
	});
}

export function getInstallation() {
	const subdomain = Api.subdomain();
	return dispatch => Api.get(`installations/?embed=user_installations&q[active_eq]=true&q\[subdomain_eq\]=${subdomain}`, '').then((resp) => {
		dispatch(setInfo.setInstallation(resp.data));
	}).catch(() => {
	});
}

export function getRelatedInstallations() {
	const database = Api.database();
	return dispatch => Api.get(`installations/?embed=user_installations&q[active_eq]=true&q\[database_eq\]=${database}`, '').then((resp) => {
		dispatch(setInfo.setRelatedInstallations(resp.data));
	}).catch(() => {
	});
}

export function getAllVehicles() {
	return dispatch => Api.get('vehicles?embed=vehicle_properties,appraisals,vehicle_notes,part_orders,attachments,work_items,vehicle_histories,completed_data_steps,completed_departments&q[created_at_gt]=1-01-2020', '').then((resp) => {
		dispatch(setInfo.setAllVehicles(resp.data));
	}).catch((ex) => {
		const errorType = 'getAllVehicles';
		return Api.handleApiError(ex, errorType);
	});
}

export function getDuplicateVins() {
	return dispatch => Api.get('vehicles?q[id_vinduplicate]=', '').then((resp) => {
		dispatch(setInfo.setDuplicateVins(resp.data));
	}).catch((ex) => {
		const errorType = 'setDuplicateVins';
		return Api.handleApiError(ex, errorType);
	});
}

export function getActiveVehicles() {
	return (dispatch, getState) => {
		const { all_vehicles } = getState().settings_refresh;

		const active_vehicles = _.filter(all_vehicles, o => o.sold === false);

		return dispatch(setInfo.setVehicles(active_vehicles));
	};
}

export function getSoldVehicles() {
	return (dispatch, getState) => {
		const { all_vehicles } = getState().settings_refresh;

		const sold_vehicles = _.filter(all_vehicles, o => o.sold === true);

		return dispatch(setInfo.setSoldVehicles(sold_vehicles));
	};
}

export function getInitialReportData() {
	return (dispatch, getState) => {
		const departments = getState().settings_departments.original_departments;
		const { current_dealership } = getState().settings_dealerships;

		const { related_installations } = getState().settings_dealerships;
		const { report_start_date } = getState().settings_reports;
		const { report_end_date } = getState().settings_reports;
		const { report_work_flows } = getState().settings_reports;
		const { work_flows } = getState().settings_departments;
		const work_flow = _.find(work_flows, { name: 'used' }) || [];
		const { installation } = getState().settings_dealerships;
		if (installation.database === 'classic') {
			dispatch(setInfo.setSortByData('age'));
		}

		const set_dealerships = [];
		related_installations.map((dealer) => {
			if (dealer.recon_center !== true) {
				set_dealerships.push(dealer.subdomain);
			}
		});

		const set_report_departments = [];
		const set_work_flows = [];
		set_work_flows.push(work_flow.id);

		departments.map((department) => {
			if (department.department_type === 'department' || department.department_type === 'data step') {
				const department_found = _.find(work_flow.work_flow_properties, { department_id: department.id });
				if (department_found || work_flow.length === 0) {
					set_report_departments.push(department.id);
				}
			}
		});

		dispatch(setInfo.setReportWorkFlows(set_work_flows.join()));
		dispatch(setInfo.setReportDealerships(current_dealership));
		dispatch(setInfo.setReportDepartments(set_report_departments.join()));
		dispatch(getReportDepartments());
	};
}
export function getBrowser() {
	const ua = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i); let browser;
	if (navigator.userAgent.match(/Edge/i) || navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
		browser = 'msie';
	} else {
		browser = ua[1].toLowerCase();
	}
	const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1].split('.');
	browser = `${domain[0].replace(':', '')}${browser}`;
	return encodeURI(browser);
}

export function getDefaultProfiles() {
	return (dispatch, getState) => {
		const { current_user } = getState();
		const position = current_user.position;

		return Api.get('default_profiles?embed=profile_permissions', '').then((resp) => {
			const profile = _.find(resp.data, { name: position }) || _.find(resp.data, { name: 'standard_user' });
			dispatch(setInfo.setCurrentUserProfile(profile));
			dispatch(setInfo.setDefaultProfiles(resp.data));
		}).catch(() => {
		});
	};
}

export function getVehicleHistories() {
	return dispatch => Api.get('vehicle_histories', '').then((resp) => {
		dispatch(setInfo.setVehicleHistories({ vehicle_histories: resp.data }));
	}).catch((ex) => {
		const errorType = 'getDealerSettings';
		return Api.handleApiError(ex, errorType);
	});
}

export function getReportDepartments() {
	return (dispatch, getState) => {
		const { current_user } = getState();
		const position = current_user.position;

		return Api.get('report_departments', '').then((resp) => {
			dispatch(setInfo.setReportDepartmentData(resp.data));
		}).catch(() => {
		});
	};
}

export function getDealerSettings() {
	return dispatch => Api.get('dealer_settings', '').then((resp) => {
		dispatch(setInfo.setDealerSettings({ dealer_settings: resp.data }));
	}).catch((ex) => {
		const errorType = 'getDealerSettings';
		return Api.handleApiError(ex, errorType);
	});
}

export function getRooms() {
	return dispatch => Api.get('rooms?embed=room_messages', '').then((resp) => {
		dispatch(setInfo.setRooms(resp.data));
	}).catch((ex) => {
		const errorType = 'getRooms';
		return Api.handleApiError(ex, errorType);
	});
}

export function getRoomMessages() {
	return dispatch => Api.get('room_messages', '').then((resp) => {
		dispatch(setInfo.setRoomMessages(resp.data));
	}).catch((ex) => {
		const errorType = 'getRoomMessages';
		return Api.handleApiError(ex, errorType);
	});
}

export function getSupportTickets() {
	return dispatch => Api.get('support_tickets', '').then((resp) => {
		dispatch(setInfo.setSupportTickets(resp.data));
	}).catch((ex) => {
		const errorType = 'getSupportTickets';
		return Api.handleApiError(ex, errorType);
	});
}

export function refreshDepartment(department_id) {
	return (dispatch, getState) => {
		let departments = [];
		departments = getState().settings_departments.allowed_departments;
		const department = _.find(departments, { id: department_id }) || [];

		const { dealer_settings } = getState().settings_site;
		const { current_dealership } = getState().settings_dealerships;
		const { installation } = getState().settings_dealerships;
		const lotqueues = [];
		const all_reconditioning = [];
		const all_reconditioning_count = 0;
		const all_vehicles = [];
		const all_vehicles_count = 0;
		const all_recon_id = 0;
		const all_vehicles_id = 0;
		const department_counts = [];
		const only_dealership = `&q[dealership_eq]=${current_dealership}`;
		const query_only_dealership = `${current_dealership}_${department.id}`;
		const query_only_subdomain = `${current_dealership}_${department.id}`;

		const page_number = getState().settings_nav.vehicle_pagination.page_number;
		const page_size = getState().settings_nav.vehicle_pagination.page_size;
		const sort_descending = getState().settings_nav.menus.sort_descending;
		const sort_by_data = getState().settings_nav.menus.sort_by_data;

		var sortedVehicles = [];
		var sortArray = [];

		_.filter(dealer_settings, (o) => {
			if (o.key === 'ra_lot_queue_new' || o.key === 'ra_lot_queue_used' || o.key === 'ra_lot_queue_misc' || o.key === 'ra_lot_queue_misc2' || o.key === 'ra_lot_queue_misc3' || o.key === 'ra_lot_queue_misc4') {
				lotqueues.push(parseInt(o.value));
			}
		});

		switch (department.department_type) {
		case 'department':
			Api.get(`vehicles?q[department_id_departmentvehicles]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false`, '').then((resp2) => {
			});
			break;
		case 'inventory':
			Api.get(`vehicles?q[department_id_departmentvehicles]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false`, '').then((resp2) => {

			});
			break;
		case 'transfer':
			Api.get(`vehicles?q[temp_department_departmentvehicles]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false${only_dealership}`, '').then((resp2) => {

			});
			break;
		case 'recently sold':
			break;
		case 'data step': {
			Api.get(`vehicles?q[id_datastep]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false`, '').then((resp2) => {

			});
			break;
		}
		case 'parent step': {
			Api.get(`vehicles?q[id_parentstep]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false`, '').then((resp2) => {

			});
			break;
		}
		case 'all reconditioning':
			Api.get(`vehicles?q[id_allrecon]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false`, '').then((resp2) => {

			});
			break;
		case 'all vehicles':
			Api.get(`vehicles?q[department_id_allvehicles]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false`, '').then((resp2) => {

			});
			break;
		default:
			break;
		}
	};
}

export function getReportVehicles(report_id) {
	return (dispatch, getState) => {
		Api.get(`vehicles?q[id_reportvehicles]=${report_id}&embed=vehicle_properties,appraisals,completed_departments,vehicle_histories,completed_data_steps,vehicle_notes,attachments`, '').then((resp) => {
			dispatch(setInfo.setReportVehicles(resp.data));
		});
	};
}

export function getCurrentDepartment(department_id) {
	return (dispatch, getState) => {
		let departments = [];
		departments = getState().settings_departments.departments;
		const department = _.find(departments, { id: department_id }) || [];
		const { dealer_settings } = getState().settings_site;
		const { current_dealership } = getState().settings_dealerships;
		const query_only_subdomain = `${current_dealership}_${department.id}`;
		const { installation } = getState().settings_dealerships;
		const lotqueues = [];
		const all_reconditioning = [];
		const all_reconditioning_count = 0;
		let all_vehicles = [];
		const all_vehicles_count = 0;
		const all_recon_id = 0;
		const all_vehicles_id = 0;
		const department_counts = [];

		const page_number = getState().settings_nav.vehicle_pagination.page_number;
		const page_size = getState().settings_nav.vehicle_pagination.page_size;
		const sort_descending = getState().settings_nav.menus.sort_descending;
		const sort_by_data = getState().settings_nav.menus.sort_by_data;

		var sortedVehicles = [];
		var sortArray = [];

		_.filter(dealer_settings, (o) => {
			if (o.key === 'ra_lot_queue_new' || o.key === 'ra_lot_queue_used' || o.key === 'ra_lot_queue_misc' || o.key === 'ra_lot_queue_misc2' || o.key === 'ra_lot_queue_misc3' || o.key === 'ra_lot_queue_misc4') {
				lotqueues.push(parseInt(o.value));
			}
		});

		switch (department.department_type) {
		case 'department':
			Api.get(`vehicles?q[department_id_departmentvehicles]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false&embed=vehicle_properties,appraisals,completed_departments,vehicle_histories,vehicle_notes,attachments`, '').then((resp2) => {
				all_vehicles = _.sortBy(resp2.data, 'department_time');

				all_vehicles.map((vehicle) => {
					let sort_data = '';
					const value = _.filter(vehicle.vehicle_properties, o => o.key === sort_by_data);

					if (value.length > 0) {
						if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
							sort_data = value[0].value;
						}
					} else {
						sort_data = vehicle[sort_by_data];
					}

					sortArray.push({ id: vehicle.id, sort_data });
				});
				sortArray = _.sortBy(sortArray, 'sort_data');

				if (sort_descending === true) {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
					sortedVehicles.reverse();
				} else {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
				}
				dispatch(setInfo.setVehicleVehicleTotal(sortedVehicles.length));
				dispatch(setInfo.setVehiclePageTotal(Math.ceil(sortedVehicles.length / page_size)));
				dispatch(setInfo.setVehicles(dateFunctions.paginateList(sortedVehicles, page_size, page_number)));
			});
			break;
		case 'inventory':
			Api.get(`vehicles?q[department_id_departmentvehicles]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false&embed=vehicle_properties,appraisals,completed_departments,vehicle_histories,vehicle_notes,attachments`, '').then((resp2) => {
				all_vehicles = _.sortBy(resp2.data, 'department_time');

				all_vehicles.map((vehicle) => {
					let sort_data = '';
					const value = _.filter(vehicle.vehicle_properties, o => o.key === sort_by_data);

					if (value.length > 0) {
						if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
							sort_data = value[0].value;
						}
					} else {
						sort_data = vehicle[sort_by_data];
					}

					sortArray.push({ id: vehicle.id, sort_data });
				});
				sortArray = _.sortBy(sortArray, 'sort_data');

				if (sort_descending === true) {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
					sortedVehicles.reverse();
				} else {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
				}
				dispatch(setInfo.setVehicleVehicleTotal(sortedVehicles.length));
				dispatch(setInfo.setVehiclePageTotal(Math.ceil(sortedVehicles.length / page_size)));
				dispatch(setInfo.setVehicles(dateFunctions.paginateList(sortedVehicles, page_size, page_number)));
			});
			break;
		case 'transfer':
			Api.get(`vehicles?q[temp_department_departmentvehicles]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false&embed=vehicle_properties,appraisals,completed_departments,vehicle_histories,vehicle_notes,attachments`, '').then((resp2) => {
				all_vehicles = _.sortBy(resp2.data, 'department_time');

				all_vehicles.map((vehicle) => {
					let sort_data = '';
					const value = _.filter(vehicle.vehicle_properties, o => o.key === sort_by_data);

					if (value.length > 0) {
						if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
							sort_data = value[0].value;
						}
					} else {
						sort_data = vehicle[sort_by_data];
					}

					sortArray.push({ id: vehicle.id, sort_data });
				});
				sortArray = _.sortBy(sortArray, 'sort_data');

				if (sort_descending === true) {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
					sortedVehicles.reverse();
				} else {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
				}
				dispatch(setInfo.setVehicleVehicleTotal(sortedVehicles.length));
				dispatch(setInfo.setVehiclePageTotal(Math.ceil(sortedVehicles.length / page_size)));
				dispatch(setInfo.setVehicles(dateFunctions.paginateList(sortedVehicles, page_size, page_number)));
			});
			break;
		case 'recently sold':
			Api.get('vehicles?q[sold_eq]=true&q[deleted_eq]=false&embed=vehicle_properties,vehicle_notes,appraisals,attachments,completed_departments&page=1&per=300', '').then((resp2) => {
				all_vehicles = _.sortBy(resp2.data, 'department_time');

				all_vehicles.map((vehicle) => {
					let sort_data = '';
					const value = _.filter(vehicle.vehicle_properties, o => o.key === sort_by_data);

					if (value.length > 0) {
						if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
							sort_data = value[0].value;
						}
					} else {
						sort_data = vehicle[sort_by_data];
					}

					sortArray.push({ id: vehicle.id, sort_data });
				});
				sortArray = _.sortBy(sortArray, 'sort_data');

				if (sort_descending === true) {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
					sortedVehicles.reverse();
				} else {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
				}
				dispatch(setInfo.setVehicleVehicleTotal(sortedVehicles.length));
				dispatch(setInfo.setVehiclePageTotal(Math.ceil(sortedVehicles.length / page_size)));
				dispatch(setInfo.setVehicles(dateFunctions.paginateList(sortedVehicles, page_size, page_number)));
			});
			break;
		case 'data step': {
			Api.get(`vehicles?q[id_datastep]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false&embed=vehicle_properties,appraisals,completed_departments,vehicle_histories,vehicle_notes,attachments,part_orders`, '').then((resp2) => {
				all_vehicles = _.sortBy(resp2.data, 'department_time');

				all_vehicles.map((vehicle) => {
					let sort_data = '';
					const value = _.filter(vehicle.vehicle_properties, o => o.key === sort_by_data);

					if (value.length > 0) {
						if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
							sort_data = value[0].value;
						}
					} else {
						sort_data = vehicle[sort_by_data];
					}

					sortArray.push({ id: vehicle.id, sort_data });
				});
				sortArray = _.sortBy(sortArray, 'sort_data');

				if (sort_descending === true) {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
					sortedVehicles.reverse();
				} else {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
				}
				dispatch(setInfo.setVehicleVehicleTotal(sortedVehicles.length));
				dispatch(setInfo.setVehiclePageTotal(Math.ceil(sortedVehicles.length / page_size)));
				dispatch(setInfo.setVehicles(dateFunctions.paginateList(sortedVehicles, page_size, page_number)));
			});
			break;
		}
		case 'parent step': {
			Api.get(`vehicles?q[id_parentstep]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false&embed=vehicle_properties,appraisals,completed_departments,vehicle_histories,vehicle_notes,attachments`, '').then((resp2) => {
				all_vehicles = _.sortBy(resp2.data, 'department_time');

				all_vehicles.map((vehicle) => {
					let sort_data = '';
					const value = _.filter(vehicle.vehicle_properties, o => o.key === sort_by_data);

					if (value.length > 0) {
						if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
							sort_data = value[0].value;
						}
					} else {
						sort_data = vehicle[sort_by_data];
					}

					sortArray.push({ id: vehicle.id, sort_data });
				});
				sortArray = _.sortBy(sortArray, 'sort_data');

				if (sort_descending === true) {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
					sortedVehicles.reverse();
				} else {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
				}
				dispatch(setInfo.setVehicleVehicleTotal(sortedVehicles.length));
				dispatch(setInfo.setVehiclePageTotal(Math.ceil(sortedVehicles.length / page_size)));
				dispatch(setInfo.setVehicles(dateFunctions.paginateList(sortedVehicles, page_size, page_number)));
			});
			break;
		}
		case 'all reconditioning':
			Api.get(`vehicles?q[id_allrecon]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false&embed=vehicle_properties,appraisals,completed_departments,vehicle_histories,vehicle_notes,attachments`, '').then((resp2) => {
				all_vehicles = _.sortBy(resp2.data, 'department_time');

				all_vehicles.map((vehicle) => {
					let sort_data = '';
					const value = _.filter(vehicle.vehicle_properties, o => o.key === sort_by_data);

					if (value.length > 0) {
						if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
							sort_data = value[0].value;
						}
					} else {
						sort_data = vehicle[sort_by_data];
					}

					sortArray.push({ id: vehicle.id, sort_data });
				});
				sortArray = _.sortBy(sortArray, 'sort_data');

				if (sort_descending === true) {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
					sortedVehicles.reverse();
				} else {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
				}
				dispatch(setInfo.setVehicleVehicleTotal(sortedVehicles.length));
				dispatch(setInfo.setVehiclePageTotal(Math.ceil(sortedVehicles.length / page_size)));
				dispatch(setInfo.setVehicles(dateFunctions.paginateList(sortedVehicles, page_size, page_number)));
			});
			break;
		case 'all vehicles':
			Api.get(`vehicles?q[department_id_allvehicles]=${query_only_subdomain}&q[sold_eq]=false&q[deleted_eq]=false&embed=vehicle_properties,appraisals,completed_departments,vehicle_histories,vehicle_notes,attachments`, '').then((resp2) => {
				all_vehicles = _.sortBy(resp2.data, 'department_time');

				all_vehicles.map((vehicle) => {
					let sort_data = '';
					const value = _.filter(vehicle.vehicle_properties, o => o.key === sort_by_data);

					if (value.length > 0) {
						if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
							sort_data = value[0].value;
						}
					} else {
						sort_data = vehicle[sort_by_data];
					}

					sortArray.push({ id: vehicle.id, sort_data });
				});
				sortArray = _.sortBy(sortArray, 'sort_data');

				if (sort_descending === true) {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
					sortedVehicles.reverse();
				} else {
					sortArray.map((item) => {
						sortedVehicles.push(_.find(all_vehicles, { id: item.id }));
					});
				}
				dispatch(setInfo.setVehicleVehicleTotal(sortedVehicles.length));
				dispatch(setInfo.setVehiclePageTotal(Math.ceil(sortedVehicles.length / page_size)));
				dispatch(setInfo.setVehicles(dateFunctions.paginateList(sortedVehicles, page_size, page_number)));
			});
			break;
		default:
			break;
		}
	};
}
export function refreshAllDepartments() {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;

		departments.map((department) => {
			dispatch(refreshDepartment(department.id));
		});
	};
}

export function refreshAllDataSteps() {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;

		departments.map((department) => {
			if (department.department_type === 'data step') {
				dispatch(refreshDepartment(department.id));
			}
		});
	};
}

export function processDepartments() {
	return (dispatch, getState) => Api.get('departments?embed=department_properties,department_filters,department_dealerships', '').then((resp) => {
		const { department } = getState().settings_departments;
		const { vehicle_filter } = getState().settings_nav;
		const { cancel_old_requests } = getState().settings_nav;
		const { current_dealership } = getState().settings_dealerships;
		const { current_user_profile } = getState().settings_users;
		const profile_permissions = current_user_profile.profile_permissions || [];
		const { work_flows } = getState().settings_departments;
		const { installation } = getState().settings_dealerships;
		const dealer_departments = [];
		const dealer_columns = [];
		const newState = [];
		const original_departments = resp.data;
		let newDepartments = [];
		const view_departments = [];
		let allowed_departments = [];

		const all_reconditioning = [];
		const department_counts = [];

		profile_permissions.map((profile_permission) => {
			if (profile_permission.value === 'true') {
				view_departments.push(profile_permission.key);
			}
		});

		newDepartments = _.filter(original_departments, (o) => {
			const dealers = o.dealerships || '';
			const dealersArray = dealers.split(',');
			const d = o;
			d.vehicles = [];
			if (dealersArray.indexOf(current_dealership) > -1) {
				dealer_departments.push(d);
			}

			if (view_departments.includes(o.name) && dealersArray.indexOf(current_dealership) > -1) {
				return d;
			}
		});

		if (current_user_profile.name !== 'super_admin' && current_user_profile.name !== 'admin') {
			allowed_departments = newDepartments;
		} else {
			allowed_departments = dealer_departments;
		}

		dispatch(setInfo.setDealershipDepartments(dealer_departments));
		dispatch(setInfo.setDepartments(dealer_departments));
		dispatch(setInfo.setOriginalDepartments(original_departments));
		dispatch(setInfo.setAllowedDepartments(allowed_departments));
		if (vehicle_filter.type === 'searched') {
			if (cancel_old_requests === false) {
				dispatch(lookups.vsearch(vehicle_filter.value));
			}
		} else if (cancel_old_requests === false) {
				dispatch(getCurrentDepartment(parseInt(department)));
			}
	}).catch((ex) => {
		const errorType = 'getDepartments';
		return Api.handleApiError(ex, errorType);
	});
}

export function getDepartments() {
	return (dispatch, getState) => Api.get('departments?embed=department_properties,department_filters,department_dealerships', '').then((resp) => {
		const { current_dealership } = getState().settings_dealerships;
		const { current_user_profile } = getState().settings_users;
		const profile_permissions = current_user_profile.profile_permissions || [];
		const { work_flows } = getState().settings_departments;
		const { installation } = getState().settings_dealerships;
		const dealer_departments = [];
		const dealer_columns = [];
		const newState = [];
		const original_departments = resp.data;
		let newDepartments = [];
		const view_departments = [];
		let allowed_departments = [];

		const all_reconditioning = [];
		const department_counts = [];

		const data_step_targets = [];

		profile_permissions.map((profile_permission) => {
			view_departments.push(profile_permission.key);
		});

		newDepartments = _.filter(original_departments, (o) => {
			const dealers = o.dealerships || '';
			const dealersArray = dealers.split(',');
			const d = o;
			d.vehicles = [];
			if (dealersArray.indexOf(current_dealership) > -1) {
				dealer_departments.push(d);
				d.department_properties.map((c) => {
					const name = c.vehicle_column.name;
					dealer_columns.push(name);
				});
				d.department_filters.map((c) => {
					const target = c.all_filter.filter_target;
					const pushme = { department_id: d.id, target };
					data_step_targets.push(pushme);
				});
			}

			if (view_departments.includes(o.name) && dealersArray.indexOf(current_dealership) > -1) {
				return d;
			}
		});

		if (current_user_profile.name !== 'super_admin' && current_user_profile.name !== 'admin') {
			allowed_departments = newDepartments;
		} else {
			allowed_departments = dealer_departments;
		}
		dispatch(setInfo.setDataStepTargets({ data_step_targets }));
		dispatch(setInfo.setDealershipDepartments(dealer_departments));
		dispatch(setInfo.setDepartments(dealer_departments));
		dispatch(setInfo.setDealershipColumns(Array.from(new Set(dealer_columns))));
		dispatch(setInfo.setOriginalDepartments(original_departments));
		dispatch(setInfo.setAllowedDepartments(allowed_departments));
	}).catch((ex) => {
		const errorType = 'getDepartments';
		return Api.handleApiError(ex, errorType);
	});
}

export function getTabs() {
	return dispatch => Api.get('tabs?embed=tab_properties', '').then((resp) => {
		dispatch(setInfo.setTabs({ tabs: resp.data }));
	}).catch((ex) => {
		const errorType = 'getTabs';
		return Api.handleApiError(ex, errorType);
	});
}

export function getDepartmentCounts() {
	return dispatch => Api.get('departments?q[id_vehiclecount]=', '').then((resp) => {
		dispatch(setInfo.setDepartmentCounts({ departments: resp.data }));
	}).catch((ex) => {
		const errorType = 'getDepartmentCounts';
		return Api.handleApiError(ex, errorType);
	});
}

export function getVehicleColumns() {
	return dispatch => Api.get('vehicle_columns?embed=options,vehicle_column_filters', '').then((resp) => {
		dispatch(setInfo.setVehicleColumns({ vehicle_columns: resp.data }));
	}).catch((ex) => {
		const errorType = 'getVehicleColumns';
		return Api.handleApiError(ex, errorType);
	});
}

export function getInputTypes() {
	return dispatch => Api.get('input_types', '').then((resp) => {
		dispatch(setInfo.setInputTypes({ input_types: resp.data }));
	}).catch(() => {
	});
}

export function getAllFilters() {
	return dispatch => Api.get('all_filters', '').then((resp) => {
		dispatch(setInfo.setAllFilters({ all_filters: resp.data }));
	}).catch((ex) => {
		const errorType = 'all_filters';
		return Api.handleApiError(ex, errorType);
	});
}

export function getWorkFlows() {
	return dispatch => Api.get('work_flows?embed=work_flow_properties', '').then((resp) => {
		dispatch(setInfo.setWorkFlows({ work_flows: resp.data }));
	}).catch((ex) => {
		const errorType = 'getWorkFlows';
		return Api.handleApiError(ex, errorType);
	});
}

export function getNotificationSettings() {
	return dispatch => Api.get('notification_settings', '').then((resp) => {
		dispatch(setInfo.setNotificationSettings({ notification_settings: resp.data }));
	}).catch(() => {
	});
}

export function getPhotosByVid(vehicle_id) {
	return dispatch => Api.get(`photos?q[vehicle_id_eq]=${vehicle_id}&page=1&per=1`, '').then((resp) => {
		const photos = resp.data.reverse();
		dispatch(setInfo.setPhotos({ photos: JSON.parse(photos[0].value) }));
	}).catch((ex) => {
		const errorType = 'getPhotos';
		return Api.handleApiError(ex, errorType);
	});
}

export function getFeedLogs() {
	return dispatch => Api.get('feed_logs&page=1&per=20', '').then((resp) => {
		dispatch(setInfo.setFeedLogs(resp.data));
	}).catch(() => {
	});
}

export function getFeedProviders() {
	return dispatch => Api.get('feed_providers', '').then((resp) => {
		dispatch(setInfo.setFeedProviders(resp.data));
	}).catch(() => {
	});
}

export function getFeedProviderRules() {
	return dispatch => Api.get('feed_provider_rules', '').then((resp) => {
		dispatch(setInfo.setFeedProviderRules(resp.data));
	}).catch(() => {
	});
}

export function getFeedProviderSettings() {
	return dispatch => Api.get('feed_provider_settings', '').then((resp) => {
		dispatch(setInfo.setFeedProviderSettings(resp.data));
	}).catch(() => {
	});
}

export function getFeedNotificationSettings() {
	return dispatch => Api.get('feed_notification_settings', '').then((resp) => {
		dispatch(setInfo.setFeedNotificationSettings(resp.data));
	}).catch(() => {
	});
}

export function getFeedErrors() {
	return dispatch => Api.get('feed_errors', '').then((resp) => {
		dispatch(setInfo.setFeedErrors(resp.data));
	}).catch(() => {
	});
}

export function getThisWeekFeedLogs() {
	var d = new Date();
	d.setDate(d.getDate() - 7);

	const year = d.getFullYear();
	const month = d.getMonth() + 1;
	const day = d.getDate();

	const formattedTime = `${month}-${day}-${year}`;
	const subdomain = Api.subdomain();

	return dispatch => Api.get(`feed_logs?q[created_at_gt]=${formattedTime}&q[dealership_eq]=${subdomain}`, '').then((resp) => {
		dispatch(setInfo.setFeedLogs(resp.data));
	}).catch(() => {
	});
}

export function getFeedCenter() {
	return (dispatch, getState) => {
		dispatch(getFeedProviders());
		dispatch(getFeedProviderRules());
		dispatch(getFeedProviderSettings());
		dispatch(getFeedNotificationSettings());
		dispatch(getFeedErrors());
	};
}

export function getEmailReports() {
	return dispatch => Api.get('email_reports', '').then((resp) => {
		dispatch(setInfo.setEmailReports({ email_reports: resp.data }));
	}).catch(() => {
	});
}

export function getReportTypes() {
	return dispatch => Api.get('report_types', '').then((resp) => {
		dispatch(setInfo.setReportTypes({ report_types: resp.data }));
	}).catch(() => {
	});
}

export function getAudits() {
	return dispatch => Api.get('audits?embed=audit_properties', '').then((resp) => {
		dispatch(setInfo.setAudits({ audits: resp.data }));
	}).catch(() => {
	});
}

export function getWorkItemPresets() {
	return dispatch => Api.get('work_item_presets', '').then((resp) => {
		dispatch(setInfo.setWorkItemPresets({ work_item_presets: resp.data }));
	}).catch(() => {
	});
}

export function getEstimateUserById(id, subdomain) {
	return dispatch => Api.getUserById(`users/${id}`, subdomain).then((resp) => {
		dispatch(setInfo.setSingleEstimateUser({ user: resp.data }));
	}).catch(() => {
	});
}

export function getVehicleByVinAndSubdomain(vin, subdomain) {
	return dispatch => Api.getVehicleByVinAndSubdomain(`vehicles?embed=vehicle_properties&q[vin_eq]=${vin}`, subdomain).then((resp) => {
		dispatch(setInfo.setActiveVehicle({ active_vehicle: resp.data[0] }));
	}).catch((ex) => {
		const errorType = 'getVehicleByVinAndSubdomain';
		return Api.handleApiError(ex, errorType);
	});
}

export function getEstimatesByApprovedLink(ro_email_link_approved, subdomain, data) {
	return dispatch => Api.getAppraisal(`appraisals?q[ro_email_link_approved_eq]=${ro_email_link_approved}`, subdomain).then((resp) => {
		const message = 'Estimate Approved';
		resp.data.map((estimate) => {
			if (estimate.ro_status === 'pending') {
					if (subdomain.includes('classic')) {
						const data2 = {
							note_type: 'appraisal_approved',
							message,
							user_id: 15386,
							data_id: estimate.id,
							vehicle_id: estimate.vehicle_id,
						};
						dispatch(apiNotifications.appraisalNotification(data2, 'approvalRequestNotification', subdomain));

						const data3 = {
							note_type: 'appraisal_approved',
							message,
							user_id: 15142,
							data_id: estimate.id,
							vehicle_id: estimate.vehicle_id,
						};
						dispatch(apiNotifications.appraisalNotification(data3, 'approvalRequestNotification', subdomain));
					} else if (parseInt(estimate.requested_by_uid) > 0) {
							const data2 = {
								note_type: 'appraisal_approved',
								message,
								user_id: parseInt(estimate.requested_by_uid),
								data_id: estimate.id,
								vehicle_id: estimate.vehicle_id,
							};
							dispatch(apiNotifications.appraisalNotification(data2, 'approvalRequestNotification', subdomain));
						}

				dispatch(patchEstimatesById(estimate.id, subdomain, data, estimate.vehicle_id));
			}
		});
	}).catch((ex) => {
		const errorType = 'getWorkItems';
		return Api.handleApiError(ex, errorType);
	});
}

export function getEstimatesByDeclinedLink(ro_email_link_declined, subdomain, data) {
	return dispatch => Api.getAppraisal(`appraisals?q[ro_email_link_declined_eq]=${ro_email_link_declined}`, subdomain).then((resp) => {
		const message = 'Estimate Declined';
		resp.data.map((estimate) => {
			if (estimate.ro_status === 'pending') {
				if (subdomain.includes('classic')) {
						const data2 = {
							note_type: 'appraisal_declined',
							message,
							user_id: 15386,
							data_id: estimate.id,
							vehicle_id: estimate.vehicle_id,
						};
						dispatch(apiNotifications.appraisalNotification(data2, 'approvalRequestNotification', subdomain));

						const data3 = {
							note_type: 'appraisal_declined',
							message,
							user_id: 15142,
							data_id: estimate.id,
							vehicle_id: estimate.vehicle_id,
						};
						dispatch(apiNotifications.appraisalNotification(data3, 'approvalRequestNotification', subdomain));
					} else if (parseInt(estimate.requested_by_uid) > 0) {
							const data2 = {
								note_type: 'appraisal_declined',
								message,
								user_id: parseInt(estimate.requested_by_uid),
								data_id: estimate.id,
								vehicle_id: estimate.vehicle_id,
							};
							dispatch(apiNotifications.appraisalNotification(data2, 'approvalRequestNotification', subdomain));
						}

				dispatch(patchEstimatesById(estimate.id, subdomain, data, estimate.vehicle_id));
			}
		});
	}).catch((ex) => {
		const errorType = 'getWorkItems';
		return Api.handleApiError(ex, errorType);
	});
}

export function patchEstimatesById(id, subdomain, data, vehicle_id) {
	return (dispatch, getState) => Api.patchAppraisal(`appraisals/${id}`, subdomain, data).then((resp) => {
		dispatch(setInfo.setSingleEstimate({ estimates: resp.data }));

		Api.getAppraisals(`appraisals?q[vehicle_id_eq]=${vehicle_id}`, subdomain).then((resp2) => {
			let pendingCount = 0;
			let successCount = 0;
			let warningCount = 0;
			let status_estimates;

			resp2.data.map((est) => {
				if (est.ro_status === 'pending') {
					pendingCount += 1;
				} else if (est.ro_status === 'approved') {
					successCount += 1;
				} else if (est.ro_status === 'denied') {
					warningCount += 1;
				} else if (est.ro_status === 'getapproved') {
					warningCount += 1;
				}
				return true;
			});

			if (pendingCount > 0) {
				status_estimates = 'pending';
			} else if (successCount > 0) {
				status_estimates = 'success';
			} else if (warningCount > 0) {
				status_estimates = 'warning';
			} else {
				status_estimates = '';
			}

			const errorType = 'addVehicleProperty';

			const data2 = {
				vehicle_id,
				key: 'status_estimates',
				value: status_estimates,
			};

			Api.postAppraisalData('vehicle_properties/', data2, subdomain);
		});
	}).catch((ex) => {
		const errorType = 'getWorkItems';
		return Api.handleApiError(ex, errorType);
	});
}

export function getWorkItemsByVidAndUpdate(vehicle_id) {
	return dispatch => Api.get(`work_items?q[vehicle_id_eq]=${vehicle_id}`, '').then((resp) => {
		let pendingCount = 0;
		let successCount = 0;
		let warningCount = 0;
		let status_work_items;

		resp.data.map((work_item) => {
			if ((work_item.recommended === 'true' || work_item.required === 'true') && (work_item.item_completed !== 'true' && work_item.work_declined !== 'true')) {
				pendingCount += 1;
			} else if (work_item.item_completed === 'true') {
				successCount += 1;
			} else if (work_item.ro_status === 'denied') {
				warningCount += 1;
			}
			return true;
		});

		if (pendingCount > 0) {
			status_work_items = 'pending';
		} else if (successCount > 0) {
			status_work_items = 'success';
		} else if (warningCount > 0) {
			status_work_items = 'warning';
		} else {
			status_work_items = '';
		}

		const errorType = 'addVehicleProperty';

		const data = {
			vehicle_id,
			key: 'status_work_items',
			value: status_work_items,
		};

		dispatch(AllUpdates.addVehicleProperty(data, errorType));
	}).catch((ex) => {
		const errorType = 'getWorkItems';
		return Api.handleApiError(ex, errorType);
	});
}

export function getPartOrdersByVidAndUpdate(vehicle_id) {
	return dispatch => Api.get(`part_orders?q[vehicle_id_eq]=${vehicle_id}`, '').then((resp) => {
		let pendingCount = 0;
		let successCount = 0;
		const warningCount = 0;
		let status_parts;
		resp.data.map((parts) => {
			if (parts.parts_on_hand !== true) {
				pendingCount += 1;
			} else if (parts.parts_on_hand === true) {
				successCount += 1;
			}
		});

		if (pendingCount > 0) {
			status_parts = 'pending';
		} else if (successCount > 0) {
			status_parts = 'success';
		} else if (warningCount > 0) {
			status_parts = 'warning';
		} else {
			status_parts = '';
		}

		const errorType = 'addVehicleProperty';

		const data = {
			vehicle_id,
			key: 'status_parts',
			value: status_parts,
		};

		dispatch(AllUpdates.addVehicleProperty(data, errorType));
	}).catch((ex) => {
		const errorType = 'setPartOrders';
		return Api.handleApiError(ex, errorType);
	});
}

export function getEstimatesByVidAndUpdate(vehicle_id) {
	return dispatch => Api.get(`vehicles/${vehicle_id}?embed=vehicle_properties,appraisals,vehicle_notes,part_orders,attachments,work_items,vehicle_histories,completed_data_steps,completed_departments`, '').then((resp) => {
		const vehicle = resp.data;

		let pendingCount = 0;
		let successCount = 0;
		let warningCount = 0;
		let status_estimates;

		vehicle.appraisals.map((est) => {
			if (est.ro_status === 'pending') {
				pendingCount += 1;
			} else if (est.ro_status === 'approved') {
				successCount += 1;
			} else if (est.ro_status === 'denied') {
				warningCount += 1;
			} else if (est.ro_status === 'getapproved') {
				warningCount += 1;
			}
			return true;
		});

		if (pendingCount > 0) {
			status_estimates = 'pending';
		} else if (successCount > 0) {
			status_estimates = 'success';
		} else if (warningCount > 0) {
			status_estimates = 'warning';
		} else {
			status_estimates = '';
		}

		const errorType = 'addVehicleProperty';

		const data = {
			vehicle_id,
			key: 'status_estimates',
			value: status_estimates,
		};

		dispatch(AllUpdates.addVehicleProperty(data, errorType));

		if (status_estimates === 'success') {
			const department = dispatch(lookups.lookupDepartmentByName('stage three - cosmetic work items'));
			if (vehicle.department_id === parseInt(department.id)) {
				const next_department = dispatch(lookups.lookupDepartmentByName('stage four - detail assignment'));
				dispatch(AllUpdates.updateVehicleDepartment(vehicle_id, 'department_id', parseInt(next_department.id), vehicle));
			}
		}
	}).catch((ex) => {
		const errorType = 'getWorkItems';
		return Api.handleApiError(ex, errorType);
	});
}
