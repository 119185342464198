import React, { Component, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { MaterialReactTable } from 'material-react-table';
import {
 Box, Button, createTheme, ThemeProvider, useTheme,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import ReportSingleVehicle from './ReportSingleVehicle';
import {
  getReportVehicles,
} from '../../actions/apiGetInfo';
import { convertSecondsToTime, getSecondsFromTimeRange_24_5, noteStamp } from '../../utils/dateFunctions';

// defining columns outside of the component is fine, is stable
const columns = [
  {
    accessorKey: 'stock',
    header: 'Stock',
    size: 120,
  },
  {
    accessorKey: 'year',
    header: 'Year',
  },
  {
    accessorKey: 'make',
    header: 'Make',
  },
  {
    accessorKey: 'model',
    header: 'Model',
  },
  {
    accessorKey: 'starttime',
    header: 'StartTime',
  },
  {
    accessorKey: 'endtime',
    header: 'EndTime',
  },
  {
    accessorKey: 'time',
    header: 'Time',
  },
  {
    accessorKey: 'entries',
    header: 'Entries',
  },
  {
    accessorKey: 'total',
    header: 'Total',
  },
  {
    accessorKey: 'details',
    header: 'Details',
    Cell: ({ renderedCellValue, row }) => (
		<Box
				  sx={{
					display: 'flex',
					alignItems: 'center',
					gap: '1rem',
				  }}
		>
				  <ReportSingleVehicle vehicle={row.original.vehicle[0]} />
				</Box>
			  ),
	},
];

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const ExportCSV = (props) => {
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () => createTheme({
        palette: {
          mode: 'light',
        },
      }),
    [globalTheme],
  );

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const newdata = [];

          props.report_vehicles.map((vehicle) => {
            var total_time = 0;
            var entries = 0;
            var completed_departments = [];

            completed_departments = _.filter(vehicle.completed_data_steps, o => o.department_id === parseInt(props.department_id));

            if (completed_departments.length < 1) {
              completed_departments = _.filter(vehicle.completed_departments, o => o.department_id === parseInt(props.department_id));
            }

            completed_departments.map((completed_department) => {
              if (completed_department.department_id === props.department_id) {
                total_time += completed_department.recon_total_24_7;
                entries += 1;
              }
            });

            completed_departments.map((completed_department) => {
                  newdata.push({
                    stock: [vehicle.stock],
                    year: [vehicle.year],
                    make: [vehicle.make],
                    model: [vehicle.model],
                    starttime: [noteStamp(completed_department.recon_starttime)],
                    endtime: [noteStamp(completed_department.recon_endtime)],
                    time: [convertSecondsToTime(completed_department.recon_total_24_7)],
                    entries: [entries],
                    total: [convertSecondsToTime(total_time)],
                    vehicle: [vehicle],
                });
              });
          });

  return (
    <ThemeProvider theme={tableTheme}>
    <MaterialReactTable
      columns={columns}
      data={newdata}
      enableRowSelection
      positionToolbarAlertBanner="bottom"
    /></ThemeProvider>
  );
};

const mappedActions = {
  getReportVehicles,
  convertSecondsToTime,
  getSecondsFromTimeRange_24_5,
  noteStamp,
};

function mapStateToProps(state) {
  return {
    report_vehicles: state.settings_reports.report_vehicles,
  };
}

export default connect(mapStateToProps, mappedActions)(ExportCSV);