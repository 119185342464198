import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddRoom from './AddRoom';
import RoomTable from './RoomTable';
import { getRooms } from '../../actions/apiGetInfo';

class Chat extends Component {
	constructor(props) {
		super(props);

		this.state = { username: '', password: '' };
	}

	componentDidMount() {
		this.props.getRooms();
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card space-top-0">
					<div className="card bg-light space-bottom-1 space-top-1 mx-auto">
						<div className="row pad-bottom-1 text-center space-right-0 space-left-0 pad-left-0 pad-right-0">
							<h3 className="text-center"><AddRoom {...this.props} /></h3>

							{this.props.rooms.length > 0 ? <RoomTable {...this.props} /> : ''}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	getRooms,
};

function mapStateToProps(state) {
	return {
		rooms: state.settings_support_tickets.rooms,
	};
}

export default connect(mapStateToProps, mappedActions)(Chat);
