import React, { Component } from 'react';
import { connect } from 'react-redux';
import { findUser } from '../../actions/lookups';

class SearchUser extends Component {
	constructor(props) {
		super(props);

		this.state = { search: '', errors: {}, isLoading: false };
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value }, () => {});

		if (e.target.value) {
			this.props.findUser(e.target.value.toLowerCase());
		}
	}

	render() {
		const { errors } = this.state;
		return (
			<div className="input-group col-md-5 pad-left-2">
				<input type="text" className="form-control" placeholder="Search Users" onChange={this.onChange} name="search" value={this.state.search} />
				{/* <div className="input-group-append">
					<span className="input-group-text">
						{' '}
						<i className="fa fa-search" />
					</span>
				</div>
				<div className="input-group-append">
					<span className="input-group-text">
						{' '}
						<i className="fa fa-filter" />
					</span>
				</div> */}
			</div>
		);
	}
}

const mappedActions = {
	findUser,
};

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps, mappedActions)(SearchUser);
