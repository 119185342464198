import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import FlashMessageList from './FlashMessageList';
import {
	deleteUser,
} from '../actions/users';
import {
	deleteDepartment,
} from '../actions/apiDepartmentUpdates';
import {
	addFlashMessage,
	deleteFlashMessages,
} from '../actions/toggleMenu';

class DeleteUserButton extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, email: '', user_name: '', password: '', errors: { deleteUserError: false },
		};
		this.onChange = this.onChange.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	deleteDepartment(e) {
		this.props.deleteDepartment(e.target.dataset.id);
	}

	deleteUser() {
		const { id } = this.props.user;
		const data = {
			id,
			deleted: true,
			user_name: `deleted_${Math.floor(1000 + Math.random() * 9000)}_${this.props.user.user_name}`,
			email: `deleted_${Math.floor(1000 + Math.random() * 9000)}_${this.props.user.email}`,
		};
		const self = this;
		const errorType = 'deleteUserError';
		self.props.deleteUser(id, data, errorType).then((resp) => {
			if (resp == 'success') {
				self.setState({ errors: { deleteUserError: false } });
				self.props.addFlashMessage({
					type: 'success',
					text: 'User has been successfully Deleted',
				});
				self.setState({ isLoading: false, modalIsOpen: false });
				setTimeout(() => { self.props.deleteFlashMessages(); }, 3000);
			} else {
				const { errors } = resp;
				self.props.addFlashMessage({
					type: 'error',
					text: errors.deleteUserError,
					modalIsOpen: false,
				});
				setTimeout(() => { self.props.deleteFlashMessages(); }, 3000);
			}
		});
	}

	render() {
		const { user, currentUserId } = this.props;
		const cantDelete = user.id === currentUserId;
		return (
			<span>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					className="d-flex justify-content-center"
					contentLabel="Add User"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-6 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="card-header bg-darkgrey text-white">
                    Delete User
							{' '}
							<button className="btn btn-sm btn-danger float-right" onClick={this.closeModal}>x</button>
						</div>
						<div className="card-text">
							<div className="d-flex flex-column">
								<FlashMessageList />
							</div>
						</div>
						<div className="card-footer">
							<button className="btn btn-sm btn-warning float-left" onClick={this.deleteUser}>
								<i className="fas fa-minus-square" />
								{' '}
Confirm Delete User:
								{' '}
								{user.user_name}
							</button>
							<button className="btn btn-sm btn-danger float-right" onClick={this.closeModal}>Cancel</button>
						</div>
					</div>

				</Modal>
				<button disabled={cantDelete} className="btn btn-sm btn-danger" onClick={this.openModal}>
					<i className="fas fa-minus-square" />
					{' '}
Delete User
				</button>
			</span>
		);
	}
}

const mappedActions = {
	deleteDepartment,
	deleteFlashMessages,
	deleteUser,
	addFlashMessage,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.departments,
		currentUserId: state.current_user.user,
	};
}
export default connect(mapStateToProps, mappedActions)(DeleteUserButton);
