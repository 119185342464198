import {
	createStore, applyMiddleware, combineReduxers, compose,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/rootReducer.js';

const loggerMiddleware = createLogger({ predicate: (getState, action) => process.env.NODE_ENV === 'development' });

export default (initialState) => {
	const enhancer = compose(
		applyMiddleware(
			thunkMiddleware,
		),
		window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
	);
	let store = createStore(rootReducer, initialState, enhancer);
	return {store}
}
