import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	getSecondsFromTimeRange,
	returnAgeSeconds,
	readableStamp,
} from '../../utils/dateFunctions';
import {
	lookupUserById,
} from '../../actions/lookups';

class VehicleDataHistorySummarySingle extends PureComponent {
	department(id) {
		const self = this;
		const department = _.sortBy(_.filter(self.props.all_departments, o => o.id == id), 'id');
		return department[0] || [];
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	render() {
		const self = this;
		var totaltime = 0;
		const { department_history } = this.props;

		const second_time_tab = this.dealerSettingValue('second_time_tab');
		switch (second_time_tab) {
			case 'age-24_7':
				totaltime = department_history.recon_total_24_7;
				break;
			case 'age-24_6':
				totaltime = department_history.recon_total_24_6;
				break;
			case 'age-24_5':
				totaltime = department_history.recon_total_24_5;
				break;
			default:
				totaltime = department_history.recon_total_24_7;
				break;
			}

		var totalhours = totaltime / (24 * 60 * 60);

		if (totaltime > (24 * 60 * 60)) {
			totaltime /= (24 * 60 * 60);
			var units = ' days';
		} else {
			var units = ' hours';
			totaltime /= (60 * 60);
		}

		const user_id = self.props.lookupUserById(department_history.recon_entered_by);
		const user_id_completed = self.props.lookupUserById(department_history.recon_ended_by);
		const user_name = user_id !== undefined ? user_id.user_name : 'ReconAdvisor';
		const unit = <span className="float-right">
			{totaltime.toFixed(1)}
			{' '}
			{units}
               </span>;
		return (
			<li key={`timeline_single_${department_history.id}`}>
				<div className="timeline-badge"><i className="fas fa-check-circle" /></div>
				<div className="timeline-panel bg-light box">
					<div className="timeline-heading">
						<h4 className="timeline-title text-capitalize">
							{self.department(department_history.department_id).name}
							{' '}
							{unit}
						</h4>
						<p>
							<small className="text-muted">
								<i className="glyphicon glyphicon-time" />
                                        Initiated By:
								{' '}
								<span className="text-primary">{readableStamp(department_history.recon_starttime)}</span>
								{' '}
by
								<span className="text-success">{user_name}</span>
							</small>
							<br />
							<small className="text-muted">
								<i className="glyphicon glyphicon-time" />
                                        Completed By:
								{' '}
								<span className="text-primary">{readableStamp(department_history.recon_endtime)}</span>
								{' '}
by
								<span className="text-success">{user_id_completed._user_name}</span>
							</small>
						</p>
					</div>
					<div className="timeline-body" />
				</div>

			</li>
		);
	}
}

const mappedActions = {
	lookupUserById,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		all_departments: state.settings_departments.original_departments,
		active_vehicle: state.settings_refresh.active_vehicle,
		dealer_settings: state.settings_site.dealer_settings,
	};
}
export default connect(mapStateToProps, mappedActions)(VehicleDataHistorySummarySingle);