import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';

class ChartSingleVehiclePie extends Component {
	constructor(props) {
		super(props);

		this.state = { chart: {} };
	}

	componentDidMount() {
		const _this = this;
		const colors = ['#00bf00', '#00bf00', '#00bf00', '#00bf00'];
		// Init chart with data from props.
		const chart = Highcharts.chart('chart', {
			chart: {
				backgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie',
				options3d: {
					enabled: true,
					alpha: 45,
				},
			},
			title: {
				text: '',
			},
			tooltip: {
				pointFormat: '{point.y:.1f} Days <b>{point.percentage:.1f}%</b>',
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					innerSize: 100,
					depth: 45,
					dataLabels: {
						enabled: true,
						format: '{point.name}',
						style: {
							color: '#333;',
							textShadow: false,
							fontWeight: 'lighter',
							letterSpacing: '3px',
							fontSize: 22,
							textTransform: 'capitalize',
						},
						connectorColor: 'silver',
					},
					colors,
				},
			},
			series: [{ data: _this.props.data }],
			credits: {
				enabled: false,
			},
		});
		// Save the chart "container" in the state so we can access it from anywhere.
		this.setState({ chart });
	}

	// Destroy chart before unmount.
	componentWillUnmount() {
		this.state.chart.destroy();
	}

	render() {
		return (
			<div className="col-md-12" id={this.props.container} />
		);
	}
}

function mapStateToProps(state) {
	return {};
}
export default connect(mapStateToProps)(ChartSingleVehiclePie);
