import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import WorkItem from './WorkItem';
import {
	lookupVehicleById,
} from '../../../../actions/lookups';

class WorkItems extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const vehicle = this.props.vehicle;
		const work_items = _.sortBy(vehicle.work_items, 'id') || [];
		const items = work_items.map(work_item => <WorkItem {...this.props} vehicle={vehicle} work_item={work_item} key={`work_item_row_${vehicle.id}_${work_item.id}`} />);

		return (
			<tbody>
				{ items }
			</tbody>
		);
	}
}

const mappedActions = {
	lookupVehicleById,
};

function mapStateToProps(state) {
	return { active_vehicle: state.settings_refresh.active_vehicle };
}

export default connect(mapStateToProps, mappedActions)(WorkItems);
