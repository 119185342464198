import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
	noteStamp,
} from '../../../../utils/dateFunctions';
import {
	lookupUserById,
} from '../../../../actions/lookups';
import {
	addVehicleNote,
} from '../../../../actions/apiVehicleNotes';

class MechanicalNoteContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { value: '' };
		this.addNote = this.addNote.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
	}

	notes() {
		return Object.keys(this.props.notes).map(key => this.props.notes[key]);
	}

	onChange(e) {
		this.setState({ value: e.target.value });
	}

	onKeyPress(e) {
		if (e.key === 'Enter') {
			const data = {
				vehicle_id: this.props.active_vehicle.id,
				value: this.state.value,
				note_type: 'mechanical',
				uid: this.props.current_user.user,
			};
			this.setState({ value: '' });
			this.props.addVehicleNote(data, 'addVehicleNoteError');
		}
	}

	addNote(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			value: this.state.value,
			note_type: 'mechanical',
			uid: this.props.current_user.user,
		};
		this.setState({ value: '' });
		this.props.addVehicleNote(data, 'addVehicleNoteError');
	}

	render() {
		const vehicle = this.props.active_vehicle;

		return (
			<div className="container ReactNotesBox">
				<div className="input-group">
					<input type="text" className="form-control" key={`note_input_${vehicle.id}`} placeholder="Add Mechanical Note..." onKeyPress={this.onKeyPress} onChange={this.onChange} value={this.state.value} />

					<div className="input-group-append" onClick={this.addNote}>
						<span className="input-group-text btn btn-primary"><i className="fal fa-plus" /></span>

					</div>

				</div>
				{

					this.notes().map((note) => {
						if (note.note_type === 'mechanical') {
							const username = this.props.lookupUserById(note.uid);
							if (typeof username.user_name !== 'undefined') {
								var user = username.user_name.split('@');
							}
							return <div className="card pad-bottom-1" key={`note_${note.id}_${vehicle.id}`}>
								<div className="row space-left-0 space-right-0">
									<div className="col-3 ReactNoteTime pad-right-0 pad-left-0">
										{user ? user[0] : ''}
										<br />
										{noteStamp(note.created_at)}
									</div>
									<div className="col-9">
										<small>{note.value}</small>
									</div>
								</div>
              </div>;
						}
					})
				}
			</div>
		);
	}
}

const mappedActions = {
	lookupUserById,
	addVehicleNote,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(MechanicalNoteContainer);
