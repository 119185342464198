import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as AllMutates from './AllMutates';

export function addDealerSetting(data, errorType) {
	return dispatch => Api.postData('dealer_settings/', data).then(() => {
		dispatch(getInfo.getDealerSettings({}));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateInstallation(id, data, errorType) {
	return dispatch => Api.patchData(`installations/${id}`, data).then(() => {
		dispatch(getInfo.getInstallation());
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addTabSetting(data, errorType) {
	return dispatch => Api.postData('tabs/', data).then(() => {
		dispatch(getInfo.getTabs({}));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateTabSetting(id, data, errorType) {
	return dispatch => Api.patchData(`tabs/${id}`, data).then(() => {
		dispatch(getInfo.getTabs({}));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addTabProperty(data, errorType) {
	return dispatch => Api.postData('tab_properties/', data).then(() => {
		dispatch(getInfo.getTabs({}));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function updateTabProperty(id, data, errorType) {
	return dispatch => Api.postData(`tab_properties/${id}`, data).then(() => {
		dispatch(getInfo.getTabs({}));
		return 'success';
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteTabProperty(id, errorType) {
	return dispatch => Api.delete(`tab_properties/${id}`).then(() => {
		dispatch(getInfo.getTabs({}));
	}).catch(ex => Api.handleApiError(ex, errorType));
}
