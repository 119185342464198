import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	numberWithCommas,
} from '../../utils/dateFunctions';
import AddSupportTicket from './AddSupportTicket';
import { change_page } from '../../actions/toggleMenu';

class SupportCenter extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.onChange = this.onChange.bind(this);
		this.onClickSupport = this.onClickSupport.bind(this);
		this.onClickLiveChat = this.onClickLiveChat.bind(this);
		this.onClickVidoes = this.onClickVidoes.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClickSupport() {
		this.props.change_page('support-tickets');
	}

	onClickLiveChat() {
		this.props.change_page('live-chat');
	}

	onClickVidoes() {
		this.props.change_page('support-videos');
	}

	render() {
		return (
			<div className="col-md-12">

				<div className="card space-top-0 v80">

					<h3 className="text-center pad-top-1">Support Center</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text">
						 <div className="row">
					        <div className="col-md-6 py-2">
					            <div className="card h-100 text-white bg-info">
					                <div className="card-body">
					                    <h3 className="card-title">Support Tickets</h3>
					                    <p className="card-text">Create and manage support tickets.</p>
					                    <a onClick={this.onClickSupport} className="btn btn-outline-light">View Tickets</a>
					                    </div>
					            </div>
					        </div>

					        <div className="col-md-6 py-2">
					            <div className="card h-100 text-white bg-info">
					                <div className="card-body">
					                    <h3 className="card-title">Live Chat</h3>
					                    <p className="card-text">Start a live chat for quick assistance.</p>
					                    <a onClick={this.onClickLiveChat} className="btn btn-outline-light">Start Chat</a>
					                </div>
					            </div>
					        </div>
							<div className="col-md-6 py-2">
					            <div className="card h-100 text-white bg-info">
					                <div className="card-body">
					                    <h3 className="card-title">Video Tutorials</h3>
					                    <p className="card-text">Check out our videos of how to use all of Recon Advisor's features</p>
					                    <a onClick={this.onClickVidoes} className="btn btn-outline-light">View Videos</a>
					                </div>
					            </div>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mappedActions = {
	change_page,
};

function mapStateToProps(state) {
	return {
		softname: state.settings_dealerships.installation.softname || '',
		support_tickets: state.settings_support_tickets.support_tickets || [],
		rooms: state.settings_support_tickets.rooms || [],
		room_messages: state.settings_support_tickets.room_messages || [],
		current_user: state.current_user,
	};
}
export default connect(mapStateToProps, mappedActions)(SupportCenter);
