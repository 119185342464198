import React from 'react';
import classnames from 'classnames';

const DollarInput = ({
	type, name, value, disabled, onChange, onBlur, placeholder = '',
}) => (
	<div className="input-group mb-3">
						<div className="input-group-prepend">
							<span className="input-group-text">$</span>
						</div>
						<input
							onChange={onChange}
			onBlur={onBlur}
			value={value}
			type={'number'}
			name={name}
			autoComplete=""
			autoCapitalize="none"
			autoCorrect="off"
			placeholder={placeholder}
			disabled={disabled}
			className={'form-control text-left'}
						/>
					</div>

);

export default DollarInput;
