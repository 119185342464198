import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SketchPicker, SwatchesPicker } from 'react-color';
import { updateDepartmentSetting } from '../actions/apiDepartmentUpdates';
import { updateInstallation } from '../actions/apiDealerUpdates';

class EditInstallation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', logo: '', checked: this.props.checked || '', background_color: this.props.background_color, text_color: this.props.text_color,
		};
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.handleChangeComplete = this.handleChangeComplete.bind(this);
		this.onChangeProvider = this.onChangeProvider.bind(this);
		this.selectProvider = this.selectProvider.bind(this);
	}

	onChangeProvider(e) {
		const self = this;
		const errorType = 'onChangeProvider';
		const data = {
			id: self.props.parentid,
			[self.props.name]: e.value,
		};

		self.setState({ value: e.value });
		self.props.updateInstallation(self.props.parentid, data, errorType);
	}

	handleChangeComplete(e) {
		this.setState({ value: e.hex });

		const self = this;
		const errorType = 'editColorPicker';
		const data = {
			id: self.props.parentid,
			[this.props.name]: e.hex,
		};

		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	onChangeInput(e) {
		this.setState({ value: e.target.value });
	}

	onBlur(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'updateInstallation';
			const data = {
				id: self.props.parentid,
				[e.target.name]: e.target.value,
			};

			self.setState({ value: e.target.value });

			self.props.updateInstallation(self.props.parentid, data, errorType);
		}
	}

	onChangeSelect(e) {
		const self = this;
		const errorType = 'editVehicleColumnError';
		const data = {
			id: self.props.parentid,
			[self.props.name]: e.value,
		};

		self.setState({ value: e.value });
		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	onChangeChecked(e) {
		const self = this;
		const errorType = 'editVehicleColumnError';
		const data = {
			id: self.props.parentid,
			[e.target.name]: e.target.checked,
		};
		self.setState({ checked: e.target.checked });

		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	selectOptions() {
		const departments = this.props.options.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});
		return departments;
	}

	selectData() {
		const options = this.props.vehicle_columns.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});
		return options;
	}

	selectDepartments() {
		const options = this.props.departments.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.id, label: option.name };
			} else {
				var obj = { value: option.id, label: option.name };
			}
			return obj;
		});
		return options;
	}

	selectProvider() {
		const options = this.props.feed_providers.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option.name, label: option.name };
			}
			return obj;
		});
		return options;
	}

	selectComparison() {
		const comparisonArray = [{ name: 'Greater Than', label: 'Greater Than' },
			{ name: 'Greater or Equal', label: 'Greater or Equal' }, { name: 'Equals', label: 'Equals' },
			{ name: 'Less Than', label: 'Less Than' }, { name: 'Less or Equal', label: 'Less or Equal' }, { name: 'Contains', label: 'Contains' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	returnDescription() {
		switch (this.props.name) {
		case 'active':
			return 'Step is Active';

		case 'goal':
			return 'Current Goal in Days/Hours';

		case 'children_departments':
			return 'Select children steps';

		case 'subgroup':
			return 'Parent step';

		case 'nonstep':
			return 'This stage is a representation of compared data. Example: "Jason", would be all vehicles that Jason appraised';

		case 'nonstep_meta':
			return 'This stage is a representation of compared data. Example: "Jason"';

		case 'nonstep_compare':
			return 'Comparison operator';

		case 'step':
			return 'Is this step considered part of the reconditioning process to be timed';

		case 'display_name':
			return 'Display Name';

		case 'logo':
			return 'Set your dealership logo url below or upload your logo to our fileservers';

		default:
			return '';
		}
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="card departmentCard">
					<div className="card-body row">
						<div className="col-md-12">
							<div className={classnames('form-group', { 'has-danger': this.props.error })}>
								{(() => {
									switch (this.props.type) {
									case 'switch':
										return <span className="switch">
											<input
												onChange={this.onChangeChecked}
												type={'checkbox'}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												checked={this.state.checked ? 'checked' : ''}
												className={classnames('text-center', { 'form-control-danger': this.props.error })}
												id={`switch_id_${this.props.name}_${this.props.parentid}`}
											/>
											<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}_${this.props.parentid}`}>{this.props.label.replace(/_/g, ' ')}</label>
										</span>;

									case 'switch-goal':
										return <span className="switch">
											<input
												onChange={this.onChangeChecked}
												type={'checkbox'}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												checked={this.state.checked ? 'checked' : ''}
												className={classnames('text-center', { 'form-control-danger': this.props.error })}
												id={`switch_id_${this.props.name}_${this.props.parentid}`}
											/>
											<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}_${this.props.parentid}`} />

										</span>;

									case 'select':
										return <div className="form-group bg-dark text-white">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectOptions().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectOptions()}
												className="text-capitalize"
												data-id={this.props.parentid}
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-data':
										return <div className="form-group bg-dark text-white">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectData().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectData()}
												className="text-capitalize"
												data-id={this.props.parentid}
												clearable={false}
												searchable
												key={`ds_${this.props.parentid}_${this.props.field}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-data-raw':
										return <Select
											onChange={this.onChangeSelect}
											value={this.selectData().filter(({ value }) => value === this.state.value)}
											name={this.props.name}
											options={this.selectData()}
											className="text-capitalize"
											data-id={this.props.parentid}
											clearable={false}
											searchable
											key={`ds_${this.props.parentid}_${this.props.field}`}
											menuContainerStyle={{ zIndex: 5 }}
										/>;

									case 'select-provider':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeProvider}
												value={this.selectProvider().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectProvider()}
												className="text-capitalize"
												data-id={this.props.parentid}
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-comparison':
										return <div className="form-group bg-dark text-white">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectComparison().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectComparison()}
												className="text-capitalize"
												data-id={this.props.parentid}
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-comparison-raw':
										return <Select
											onChange={this.onChangeSelect}
											value={this.selectComparison().filter(({ value }) => value === this.state.value)}
											name={this.props.name}
											options={this.selectComparison()}
											className="text-capitalize"
											data-id={this.props.parentid}
											clearable={false}
											searchable={false}
											key={`ds_${this.props.parentid}_${this.props.field}`}
											menuContainerStyle={{ zIndex: 5 }}
										/>;

									case 'select-departments':
										return <div className="form-group bg-dark text-white">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectDepartments().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectDepartments()}
												className="text-capitalize"
												data-id={this.props.parentid}
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'text':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<input
												onChange={this.onChangeInput}
												onBlur={this.onBlur}
												value={this.state.value}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
											/>
										</div>;

									case 'text-logo':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<input
												onChange={this.onChangeInput}
												onBlur={this.onBlur}
												value={this.state.value}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
											/>
										</div>;

									case 'text-raw':
										return <input
											onChange={this.onChangeInput}
											onBlur={this.onBlur}
											value={this.state.value}
											type={this.props.type}
											name={this.props.name}
											data-id={this.props.parentid}
											key={`ds_${this.props.parentid}_${this.props.field}`}
											className={classnames('form-control ', { 'form-control-danger': this.props.error })}
										/>;

									case 'text-goal':
										return <div className="form-group">
											<input
												onChange={this.onChangeInput}
												onBlur={this.onBlur}
												value={this.state.value}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
											/>
                 </div>;

									case 'colorpicker':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<SwatchesPicker
												color={this.state.value}
												onChangeComplete={this.handleChangeComplete}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
											/>
										</div>;

									default:
										return <input
											onChange={this.onChange}
											onBlur={this.onBlur}
											value={this.state.value}
											type={this.props.type}
											name={this.props.field}
											key={`ds_${this.props.parentid}_${this.props.field}`}
											className={classnames('form-control', { 'form-control-danger': this.props.error })}
										/>;
									}
								})()}

							</div>
						</div>
						<div className="col-md-12">
							<small>
								<i className={'fa fa-info-circle text-info'} />
								{' '}
								{this.returnDescription()}
							</small>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

const mappedActions = {
	updateDepartmentSetting,
	updateInstallation,
};

function mapStateToProps(state) {
	return {
		edit_department: state.settings_admin.edit_department,
		vehicle_columns: state.settings_departments.vehicle_columns,
		departments: state.settings_departments.departments,
		installation: state.settings_dealerships.installation,
		feed_providers: state.settings_feed.feed_providers,
	};
}
export default connect(mapStateToProps, mappedActions)(EditInstallation);
