import React, { Component } from 'react';
import { connect } from 'react-redux';

const EditFilterButton = ({ name, onClick }) => (
	<button className="btn btn-sm btn-success space-right-2 float-end" onClick={() => onClick(name)}>
		<i className="fa fa-edit float-end" />
		{' '}

	</button>
);

export default EditFilterButton;
