import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const date = new Date();
const firstDay_date = new Date(date.getFullYear(), date.getMonth(), 1);
const firstDay = `${firstDay_date.getFullYear()}-${(`0${firstDay_date.getMonth() + 1}`).slice(-2)}-${(`0${firstDay_date.getDate()}`).slice(-2)}`;
const lastDay_date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
const lastDay = `${lastDay_date.getFullYear()}-${(`0${lastDay_date.getMonth() + 1}`).slice(-2)}-${lastDay_date.getDate()}`;

const sixty_days = new Date(new Date().getTime() - (60 * 24 * 60 * 60 * 1000));
const start_60 = `${sixty_days.getFullYear()}-${(`0${sixty_days.getMonth() + 1}`).slice(-2)}-${(`0${sixty_days.getDate()}`).slice(-2)}`;
const end_60 = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

const thirty_days = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
const start_30 = `${thirty_days.getFullYear()}-${(`0${thirty_days.getMonth() + 1}`).slice(-2)}-${(`0${thirty_days.getDate()}`).slice(-2)}`;
const end_30 = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}`;

const initialState = {
	current_report_tab: 'Department Reports',
	current_report: 'Step Averages',
	time_range: '24-5',
	report_start_date: start_30,
	report_end_date: end_30,
	report_dealerships: '',
	report_departments: '',
	report_work_flows: '',
	report_filters: '',
	report_data: {},
	reports_ready: false,
	report_time_range: '30',
	report_vehicles: [],
	report_data_filtered: {
		yAxis: [],
		xAxis: [],
		xCount: [],
		rawTime: 0,
		rawCount: 0,
		rawCountArray: [],
		departmentVehicles: [],
	},
	report_monthly_data_filtered: {
		yAxis: [],
		xAxis: [],
		xCount: [],
		rawTime: 0,
		rawCount: 0,
		rawCountArray: [],
		departmentVehicles: [],
	},
	report_department_data: [],
	email_reports: [],
	email_report: [],
	report_types: [],
	vehicle_histories: [],

};

export const settings_reports = createReducer(initialState, {
	[types.SET_REPORT_START_DATE](state = initialState, action) {
		return {
			...state,
			report_start_date: action.report_start_date,
		};
	},
	[types.SET_REPORT_END_DATE](state = initialState, action) {
		return {
			...state,
			report_end_date: action.report_end_date,
		};
	},
	[types.SET_REPORT_VEHICLES](state = initialState, action) {
		return {
			...state,
			report_vehicles: action.vehicles,
		};
	},
	[types.SET_REPORT_DEALERSHIPS](state = initialState, action) {
		return {
			...state,
			report_dealerships: action.report_dealerships,
		};
	},
	[types.SET_REPORT_DEPARTMENTS](state = initialState, action) {
		return {
			...state,
			report_departments: action.report_departments,
		};
	},
	[types.SET_REPORT_DEPARTMENT_DATA](state = initialState, action) {
		return {
			...state,
			report_department_data: action.report_data,
		};
	},
	[types.SET_REPORT_FILTERS](state = initialState, action) {
		return {
			...state,
			report_filters: action.report_filters,
		};
	},
	[types.SET_REPORT_WORK_FLOWS](state = initialState, action) {
		return {
			...state,
			report_work_flows: action.report_work_flows,
		};
	},
	[types.SET_CURRENT_REPORT_TAB](state = initialState, action) {
		return {
			...state,
			current_report_tab: action.report_tab,
		};
	},
	[types.SET_CURRENT_REPORT](state = initialState, action) {
		return {
			...state,
			current_report: action.current_report,
		};
	},
	[types.SET_REPORT_DATA](state = initialState, action) {
		return {
			...state,
			report_data: action.report_data,
		};
	},
	[types.SET_REPORT_DATA_FILTERED](state = initialState, action) {
		return {
			...state,
			report_data_filtered: action.report_data_filtered,
		};
	},
	[types.SET_MONTHLY_REPORT_DATA_FILTERED](state = initialState, action) {
		return {
			...state,
			report_monthly_data_filtered: action.report_data_filtered,
		};
	},
	[types.REPORTS_READY](state = initialState, action) {
		return {
			...state,
			reports_ready: action.reports_ready,
		};
	},
	[types.SET_TIME_RANGE](state = initialState, action) {
		return {
			...state,
			time_range: action.time_range,
		};
	},
	[types.SET_REPORT_TIME_RANGE](state = initialState, action) {
		return {
			...state,
			report_time_range: action.report_time_range,
		};
	},
	[types.SET_EMAIL_REPORTS](state = initialState, action) {
		return {
			...state,
			email_reports: _.sortBy(action.email_reports, 'name'),
		};
	},
	[types.SET_REPORT_TYPES](state = initialState, action) {
		return {
			...state,
			report_types: _.sortBy(action.report_types, 'name'),
		};
	},
	[types.SET_EMAIL_REPORT](state = initialState, action) {
		return {
			...state,
			email_report: _.sortBy(action.email_report, 'name'),
		};
	},
	[types.SET_VEHICLE_HISTORIES](state = initialState, action) {
		return {
			...state,
			vehicle_histories: _.sortBy(action.vehicle_histories, 'inventory_date'),
		};
	},
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			current_report_tab: 'ReportPage',
			current_report: 'Step Averages',
			time_range: '24-5',
			report_time_range: '30',
			report_start_date: start_30,
			report_end_date: end_30,
			report_dealerships: '',
			report_departments: '',
			report_work_flows: '',
			report_filters: '',
			report_data: {},
			reports_ready: false,
			report_data_filtered: {
				yAxis: [],
				xAxis: [],
				xCount: [],
				rawTime: 0,
				rawCount: 0,
				rawCountArray: [],
				departmentVehicles: [],
			},
			report_monthly_data_filtered: {
				yAxis: [],
				xAxis: [],
				xCount: [],
				rawTime: 0,
				rawCount: 0,
				rawCountArray: [],
				departmentVehicles: [],
			},
			email_reports: [],
			email_report: [],
			report_types: [],
			vehicle_histories: [],
			report_department_data: [],
			report_vehicles: [],
		};
	},
});

export default 'settings_reports';
