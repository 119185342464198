import Api from '../api/reconApi.js';
import * as getInfo from './apiGetInfo';
import * as setInfo from './apiSetInfo';
import * as lookups from './lookups';
import * as AllMutates from './AllMutates';

export function updateVehicleNote(id, data, errorType) {
	return (dispatch, getState) => Api.patchData(`vehicle_notes/${id}`, data).then(() => {
		const { vehicle_filter, department } = getState().settings_nav;

		if (vehicle_filter.type === 'searched') {
			dispatch(lookups.vsearch(vehicle_filter.value));
		} else {
			dispatch(getInfo.getCurrentDepartment(parseInt(department)));
		}
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function addVehicleNoteRaw(data, errorType) {
	return (dispatch, getState) => Api.postData('vehicle_notes/', data).then(() => 'success').catch(ex => Api.handleApiError(ex, errorType));
}

export function addVehicleNote(data, errorType) {
	return (dispatch, getState) => Api.postData('vehicle_notes/', data).then((resp) => {
		const { vehicle_filter,department } = getState().settings_nav;

		if (vehicle_filter.type === 'searched') {
			dispatch(lookups.vsearch(vehicle_filter.value));
		} else {
			console.log('dispatching get current dept')
			dispatch(getInfo.getCurrentDepartment(parseInt(department)));
		}
	}).catch(ex => Api.handleApiError(ex, errorType));
}

export function deleteVehicleNote(id, errorType) {
	return (dispatch, getState) => Api.delete(`vehicle_notes/${id}`).then(() => {
		const { vehicle_filter, department } = getState().settings_nav;

		if (vehicle_filter.type === 'searched') {
			dispatch(lookups.vsearch(vehicle_filter.value));
		} else {
			dispatch(getInfo.getCurrentDepartment(parseInt(department)));
		}
	}).catch(ex => Api.handleApiError(ex, errorType));
}
