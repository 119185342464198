import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import EditTabSettings from '../../inputs/EditTabSettings';

class ColorPickerPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, input_name: '', name: '', errors: { addVehicleColumnError: false },
		};
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	render() {
		const defaultStyle = {
			background: this.props.bg_color,
			backgroundColor: this.props.bg_color,
			color: this.props.font_color,
		};

		return (
			<span>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					contentLabel="PickColor"
					className="d-flex justify-content-center"
					ariaHideApp={false}
					overlayClassName="overlay"
				>
					<div className="card col-md-6 md-auto space-top-2 pad-left-0 pad-right-0">
						<div className="modal-content">

							<div className="modal-header">
								<h4 className="modal-title">Select Color</h4>
								<button type="button" className="close" data-dismiss="modal" onClick={this.closeModal}>&times;</button>
							</div>

							<div className="modal-body">
								<div className="card-block bg-white">
									<div className="p-2">
										<EditTabSettings {...this.props} type={this.props.type} name={this.props.name} value={this.props.value} dataid={this.props.dataid} />
									</div>
								</div>
							</div>

						</div>
					</div>

				</Modal>
				<button className="btn btn-sm btn-clear" style={defaultStyle} onClick={this.openModal}>
					<i className="fal fa-edit" />
					{' '}
				</button>
			</span>
		);
	}
}

function mapStateToProps() {
	return {};
}
export default connect(mapStateToProps)(ColorPickerPopup);
