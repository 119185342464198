import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import _ from 'lodash';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import AddOptionButton from '../../inputs/AddOptionButton';
import DisplayOptions from './DisplayOptions';
import {
	addVehicleColumnOption,
	updateVehicleColumn,
} from '../../actions/apiDepartmentUpdates';

const customStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.85)',
	},
	content: {
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
	},
};

class EditVehicleColumnButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			column_id: this.props.column.id,
			name: this.props.column.name,
			input_name: this.props.column.input_name,
			options: [],
			option_name: '',
			errors: { addDepartmentPropertyError: false },
			automove_destination: this.props.column.automove_destination,
			automove: this.props.column.automove,
			automove_trigger_value: this.props.column.automove_trigger_value,
			automove_condition: this.props.column.automove_condition,
		};

		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);

		this.onChangeSelectInputType = this.onChangeSelectInputType.bind(this);

		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChangeSelectAutomoveDestination = this.onChangeSelectAutomoveDestination.bind(this);
		this.onChangeSelectAllFilters = this.onChangeSelectAllFilters.bind(this);
		this.editVehicleColumn = this.editVehicleColumn.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeSelectInputType(e) {
		this.setState({ input_name: e.value });

		const { name } = this.state;
		const input_name = e.value;
		const { id } = this.props.column;
		const errorType = 'editVehicleColumnError';
		const data = {
			id,
			name,
			input_name,
		};

		this.props.updateVehicleColumn(id, data, errorType);
	}

	onChangeSelectAutomoveDestination(e) {
		this.setState({ automove_destination: e.value });

		const { name } = this.state;
		const automove_destination = e.value;
		const { id } = this.props.column;
		const errorType = 'editVehicleColumnError';
		const data = {
			id,
			name,
			automove_destination,
		};

		this.props.updateVehicleColumn(id, data, errorType);
	}

	onChangeSelectAllFilters(e) {
		this.setState({ automove_condition: e.value });

		const { name } = this.state;
		const automove_condition = e.value;
		const { id } = this.props.column;
		const errorType = 'editVehicleColumnError';
		const data = {
			id,
			name,
			automove_condition,
		};

		this.props.updateVehicleColumn(id, data, errorType);
	}

	onClick(e) {
		e.preventDefault();
		const name = this.state.option_name;
		const { id } = this.props.column;
		const errorType = 'addVehicleColumnOption';
		const data = {
			vehicle_column_id: id,
			name,
			special: '',
			active: 'true',
		};

		this.props.addVehicleColumnOption(id, data, errorType);
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	input_types() {
		const newObj = Object.keys(this.props.input_types).map(key => this.props.input_types[key]);
		return _.sortBy(newObj, 'name');
	}

	inputColumns() {
		const input_types = this.input_types();
		const options = input_types.map((option) => {
			const obj = { value: option.name, label: `${option.name} ( ${option.description} )` };
			return obj;
		});
		return options;
	}

	editVehicleColumn() {
		const { name } = this.state;
		const { input_name } = this.state;
		const { id } = this.props.column;
		const errorType = 'editVehicleColumnError';
		const data = {
			id,
			name,
			input_name,
		};

		this.props.updateVehicleColumn(id, data, errorType);
	}

	onBlur() {
		const name = this.state.automove_trigger_value;
		const { automove_trigger_value } = this.state;
		const { id } = this.props.column;
		const errorType = 'editVehicleColumnError';
		const data = {
			id,
			name,
			automove_trigger_value,
		};

		this.props.updateVehicleColumn(id, data, errorType);
	}

	selectDepartments() {
		const options = this.props.departments.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.id, label: option.name };
			} else {
				var obj = { value: option.id, label: option.name };
			}
			return obj;
		});
		return options;
	}

	allFilters() {
		const options = this.props.all_filters.map((option) => {
			const obj = { value: option.id, label: `${option.filter_name} ( ${option.filter_target} ${option.filter_operator} ${option.filter_value} )` };
			return obj;
		});

		return options;
	}

	render() {
		const { errors } = this.state;

		return (
			<span>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					style={customStyles}
					contentLabel="Edit Vehicle Column"
					newdepartment={this.state.newdepartment}
					key={`editVehicleColumnKey_${this.props.column.id}`}
					ariaHideApp={false}
				>

					<div className="card">
						<div className="card-header bg-darkgrey text-white">
                      Edit Department Column
							{' '}
							<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>x</button>
						</div>
						<div className="card-text">

							<div className="d-flex flex-column">
								<div className="p-2">
									<TextFieldGroup
										field="name"
										label="Column Name"
										onChange={this.onChange}
										value={this.state.name}
										type="displayonly"
										error={errors.addDepartmentPropertyError}
									/>
								</div>

								<div className="p-2">
									<label>Input Type</label>
									<Select
										menuContainerStyle={{ zIndex: 1005 }}
										onChange={this.onChangeSelectInputType}
										value={this.inputColumns().filter(({ value }) => value === this.state.input_name)}
										name="input_name"
										options={this.inputColumns()}
										className="text-capitalize bigdropdown"
										clearable={false}
										searchable={false}
										key={`${this.props.column.id}_input_types`}
										placeholder="Choose Input Type"
										error={errors.addDepartmentPropertyError}
									/>
								</div>
								<div className="p-2">
									<TextFieldGroup
										field="automove_trigger_value"
										label="Automove Trigger Value"
										onChange={this.onChange}
										onBlur={this.onBlur}
										value={this.state.automove_trigger_value || ''}
										type="text"
										error={errors.addDepartmentPropertyError}
									/>
								</div>
								<div className="p-2">
									<div className="form-group">
										<label className="control-label">AutoMove Destination</label>
										<Select
											onChange={this.onChangeSelectAutomoveDestination}
											value={this.selectDepartments().filter(({ value }) => parseInt(value) === parseInt(this.state.automove_destination))}
											name={'automove_destination'}
											options={this.selectDepartments()}
											className="text-capitalize text-dark"
											data-id={this.props.column.id}
											clearable={false}
											searchable={false}
											key={`ds_${this.props.column.id}_automove_destination`}
											menuContainerStyle={{ zIndex: 1005 }}
										/>
									</div>
								</div>

								<div className="p-2">
									<label>Automove Condition</label>
									<Select
										onChange={this.onChangeSelectAllFilters}
										value={this.allFilters().filter(({ value }) => parseInt(value) === parseInt(this.state.automove_condition))}
										name={'automove_condition'}
										options={this.allFilters()}
										className="text-capitalize bigdropdown"
										clearable={false}
										searchable
										data-id={this.props.column.id}
										key={`ds_${this.props.column.id}automove_condition`}
										placeholder="Choose Filter"
										error={errors.addAllFilterError}
									/>
								</div>

								<div className="p-2">
									<AddOptionButton
										field="option_name"
										label=""
										onChange={this.onChange}
										onClick={this.onClick}
										value={this.state.option_name}
										type="add_option"
										key="add_option"
										error={errors.addDepartmentPropertyError}
									/>
								</div>

								<DisplayOptions {...this.props} column_id={this.props.column.id} key="display_options" />

							</div>
						</div>
						<div className="card-footer" />
					</div>
				</Modal>

				<button className="btn btn-sm btn-primary float-end" key={`button_${this.state.name}`} onClick={this.openModal}>
					<i className="fa fa-edit float-end" />
					{' '}
				</button>
			</span>
		);
	}
}

const mappedActions = {
	addVehicleColumnOption,
	updateVehicleColumn,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.original_departments,
		input_types: state.settings_departments.input_types,
		vehicle_columns: state.settings_departments.vehicle_columns,
		all_filters: state.settings_site.all_filters,
	};
}
export default connect(mapStateToProps, mappedActions)(EditVehicleColumnButton);
