import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import FileUploadButton from '../../../../inputs/FileUploadButton';
import {
	getDateTime,
	readableStamp,
} from '../../../../utils/dateFunctions';
import {
	lookupUserNameById,
	lookupVendors,
} from '../../../../actions/lookups';
import {
	updateEstimateVendor,
	deleteEstimate,
	sendEstimate,
} from '../../../../actions/apiEstimates';
import {
	updateVehicleProperty,
} from '../../../../actions/AllUpdates';

const workitemsFields = [{ name: 'ro_number', label: 'Invoice #', type: 'text' },
	{ name: 'assigned', label: 'Vendor', type: 'vendors' },
	{ name: 'description', label: 'Description', type: 'textarea' }];

class WorkOrderListItem extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			addDeleteEstimateError: '',
			vehicle_id: this.props.active_vehicle.id,
			ro_number: this.props.estimate.ro_number,
			requested_by_uid: this.props.estimate.requested_by_uid,
			requested_date: this.props.estimate.requested_date,
			description: this.props.estimate.description,
			estimate: this.props.estimate.estimate,
			assigned: this.props.estimate.assigned,
			actual: this.props.estimate.actual,
			ro_status: this.props.estimate.ro_status,
			approved_by_uid: this.props.estimate.approved_by_uid,
			approved_date: this.props.estimate.approved_date,
			declined_by_uid: this.props.estimate.declined_by_uid,
			declined_date: this.props.estimate.declined_date,
			ro_files: this.props.estimate.ro_files,
			declined_reason: this.props.estimate.declined_reason,
			ro_email_link_declined: this.props.estimate.ro_email_link_declined,
			ro_email_link_approved: this.props.estimate.ro_email_link_approved,
		};
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSend = this.onSend.bind(this);
		this.onApprove = this.onApprove.bind(this);
		this.onDeny = this.onDeny.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onBlur(e) {
		const self = this;
		if (e.target.value !== this.props.estimate[e.target.name]) {
			self.setState({ [e.target.name]: e.target.value });
			const { estimate } = self.props;

			const errorType = 'updateEstimateError';
			const { id } = estimate;
			const key = e.target.name;
			const { value } = e.target;

			const data = {
				id,
				[key]: value,
				vehicle_id: this.props.active_vehicle.id,
			};
			this.props.updateEstimateVendor(id, key, data, errorType);
		}
	}

	onChangeSelect(e) {
		const { estimate } = this.props;

		this.setState({ assigned: e.value });
		const self = this;
		const errorType = 'updateEstimateError';
		const { id } = estimate;
		const key = 'assigned';
		const { value } = e;

		const data = {
			id,
			[key]: value,
			vehicle_id: this.props.active_vehicle.id,
		};
		this.props.updateEstimateVendor(id, key, data, errorType);
	}

	onDelete(e) {
		switch (this.state.assigned) {
		case 'safelite':
			var key = 'safelite auto glass';
			break;
		case 'alloywheels':
			var key = 'alloy wheels repair';
			break;
		case 'theglassman':
			var key = 'the glass man';
			break;
		case 'allanglescollision':
			var key = 'all angles collision repair';
			break;
		case 'dentcenter':
			var key = 'dent center';
			break;
		case 'scholfield':
			var key = 'scholfield hatchett';
			break;
		case 'discountautoglass':
			var key = 'discountautoglass';
			break;
		case 'andersonauto_interior':
			var key = 'anderson auto interior';
			break;
		case 'autoimagebody':
			var key = 'auto image - body shop';
			break;
		case 'autoimage_pdr':
			var key = 'auto image - pdr';
			break;
		case 'bodyshopneeded':
			var key = 'body shop needed';
			break;
		default:
			var key = this.state.assigned;
			break;
		}

		const data2 = {
			vehicle_id: this.props.active_vehicle.id,
			key,
			value: '',
		};
		const errorType = 'updateVehicleProperty';

		this.props.updateVehicleProperty(this.props.active_vehicle.id, this.props.active_vehicle.department_id, data2, errorType);

		this.props.deleteEstimate(this.props.estimate.id, this.props.active_vehicle.id, 'addDeleteEstimateError');
	}

	vendorOptions() {
		const vendors = this.props.lookupVendors().map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.user_name, label: option.user_name };
			} else {
				var obj = { value: option.user_name, label: option.user_name };
			}
			return obj;
		});
		return vendors;
	}

	onSend(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_number: this.state.ro_number,
			requested_by_uid: this.props.current_user.user,
			requested_date: getDateTime(),
			description: this.state.description,
			estimate: this.state.estimate,
			actual: this.state.actual,
			assigned: this.state.assigned,
			ro_status: 'pending',
			ro_files: this.state.ro_files,
			ro_email_link_declined: this.state.ro_email_link_declined,
			ro_email_link_approved: this.state.ro_email_link_approved,
			lock_estimate: 'true',
		};

		switch (this.state.assigned) {
		case 'safelite':
			var key = 'safelite auto glass';
			break;
		case 'alloywheels':
			var key = 'alloy wheels repair';
			break;
		case 'theglassman':
			var key = 'the glass man';
			break;
		case 'allanglescollision':
			var key = 'all angles collision repair';
			break;
		case 'dentcenter':
			var key = 'dent center';
			break;
		case 'scholfield':
			var key = 'scholfield hatchett';
			break;
		case 'discountautoglass':
			var key = 'discountautoglass';
			break;
		case 'andersonauto_interior':
			var key = 'anderson auto interior';
			break;
		case 'autoimagebody':
			var key = 'auto image - body shop';
			break;
		case 'autoimage_pdr':
			var key = 'auto image - pdr';
			break;
		case 'bodyshopneeded':
			var key = 'body shop needed';
			break;
		default:
			var key = this.state.assigned;
			break;
		}

		const data2 = {
			vehicle_id: this.props.active_vehicle.id,
			key,
			value: 'needed',
		};
		const errorType = 'updateVehicleProperty';

		this.props.updateVehicleProperty(this.props.active_vehicle.id, this.props.active_vehicle.department_id, data2, errorType);

		this.props.sendEstimate(this.props.estimate.id, data, 'addEstimateError');
	}

	onApprove(e) {
		const data = {
			requested_by_uid: this.state.requested_by_uid,
			estimate: this.state.estimate,
			ro_status: 'approved',
			approved_by_uid: this.props.current_user.user,
			approved_date: getDateTime(),
			vehicle_id: this.props.active_vehicle.id,
		};

		switch (this.state.assigned) {
		case 'safelite':
			var key = 'safelite auto glass';
			break;
		case 'alloywheels':
			var key = 'alloy wheels repair';
			break;
		case 'theglassman':
			var key = 'the glass man';
			break;
		case 'allanglescollision':
			var key = 'all angles collision repair';
			break;
		case 'dentcenter':
			var key = 'dent center';
			break;
		case 'scholfield':
			var key = 'scholfield hatchett';
			break;
		case 'discountautoglass':
			var key = 'discountautoglass';
			break;
		case 'andersonauto_interior':
			var key = 'anderson auto interior';
			break;
		case 'autoimagebody':
			var key = 'auto image - body shop';
			break;
		case 'autoimage_pdr':
			var key = 'auto image - pdr';
			break;
		case 'bodyshopneeded':
			var key = 'body shop needed';
			break;
		default:
			var key = this.state.assigned;
			break;
		}

		const data2 = {
			vehicle_id: this.props.active_vehicle.id,
			key,
			value: 'completed',
		};

		const errorType = 'updateVehicleProperty';
		this.props.updateVehicleProperty(this.props.active_vehicle.id, this.props.active_vehicle.department_id, data2, errorType);

		this.props.sendEstimate(this.props.estimate.id, data, 'addEstimateError');
	}

	onDeny(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_number: this.state.ro_number,
			requested_by_uid: this.state.requested_by_uid,
			description: this.state.description,
			estimate: this.state.estimate,
			actual: this.state.actual,
			ro_status: 'denied',
			declined_by_uid: this.props.current_user.user,
			declined_date: getDateTime(),
			declined_reason: this.state.declined_reason,
			ro_email_link_counter_declined: this.state.ro_email_link_counter_declined,
			ro_email_link_counter_approved: this.state.ro_email_link_counter_approved,

		};

		switch (this.state.assigned) {
		case 'safelite':
			var key = 'safelite auto glass';
			break;
		case 'alloywheels':
			var key = 'alloy wheels repair';
			break;
		case 'theglassman':
			var key = 'the glass man';
			break;
		case 'allanglescollision':
			var key = 'all angles collision repair';
			break;
		case 'dentcenter':
			var key = 'dent center';
			break;
		case 'scholfield':
			var key = 'scholfield hatchett';
			break;
		case 'discountautoglass':
			var key = 'discountautoglass';
			break;
		case 'andersonauto_interior':
			var key = 'anderson auto interior';
			break;
		case 'autoimagebody':
			var key = 'auto image - body shop';
			break;
		case 'autoimage_pdr':
			var key = 'auto image - pdr';
			break;
		case 'bodyshopneeded':
			var key = 'body shop needed';
			break;
		default:
			var key = this.state.assigned;
			break;
		}

		const data2 = {
			vehicle_id: this.props.active_vehicle.id,
			key,
			value: 'completed',
		};

		const errorType = 'updateVehicleProperty';
		this.props.updateVehicleProperty(this.props.active_vehicle.id, this.props.active_vehicle.department_id, data2, errorType);

		this.props.sendEstimate(this.props.estimate.id, data, 'addEstimateError');
	}

	selectOptions() {
		const vendors = this.props.lookupVendors().map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.user_name, label: option.user_name };
			} else {
				var obj = { value: option.user_name, label: option.user_name };
			}
			return obj;
		});
		return vendors;
	}

	render() {
		self = this;
		var setdisabled = true;
		const { estimate } = self.props;
		if (this.props.estimate.ro_status == 'getapproved') {
			var setdisabled = false;
		}
		const defaultStyle = {
			width: '30%',
		};

		let action_button = '';
		let requested_date = '';
		let requested_by_uid = '';
		let approved_date = '';
		let approved_by_uid = '';
		let declined_date = '';
		let declined_by_uid = '';

		switch (estimate.ro_status) {
		case 'pending':
			requested_date = readableStamp(estimate.requested_date);
			requested_by_uid = this.props.lookupUserNameById(estimate.requested_by_uid);
			action_button = <div className={'btn-group'} role="group">
				<button className="btn btn-sm btn-warning" onClick={self.onApprove}>Mark as Completed</button>
				<button className="btn btn-sm btn-danger" onClick={self.onDeny}>Cancel Item</button>
				<small>
Requested By:
					{requested_date}
					{' '}
					{requested_by_uid}
				</small>
			</div>;
			break;

		case 'approved':
			approved_date = readableStamp(estimate.approved_date);
			approved_by_uid = this.props.lookupUserNameById(estimate.approved_by_uid);
			action_button = <button className="btn-success text-capitalize">
Completed
				{approved_date}
				{' '}
				{approved_by_uid}
				{' '}
			</button>;
			break;

		case 'denied':
			declined_date = readableStamp(estimate.declined_date);
			declined_by_uid = this.props.lookupUserNameById(estimate.declined_by_uid);
			action_button = <button className="btn-danger">
Canceled Item
				{declined_date}
				{' '}
				{declined_by_uid}
                   </button>;
			break;

		default:
			action_button = <button className="btn-success" onClick={self.onSend}>Notify Vendor</button>;
		}

		return (
			<tr key={`estimate_row_${this.props.active_vehicle.id}_${this.props.estimate.id}`} className="text-center">
            	<td>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						value={this.state.ro_number}
						type={'text'}
						name={'ro_number'}
						className={'form-control'}
					/>
             	</td>
				<td style={defaultStyle}>
					<Select
						onChange={this.onChangeSelect}
						value={this.vendorOptions().filter(({ value }) => value == this.state.assigned)}
						name={'assigned'}
						options={this.vendorOptions()}
						className="text-capitalize col"
						data-id={this.props.estimate.id}
						clearable={false}
						searchable={false}
						menuContainerStyle={{ zIndex: 5 }}
					/>
				</td>
				<td style={defaultStyle}>
					<textarea
						onChange={this.onChange}
						onBlur={this.onBlur}
						value={this.state.description}
						name={'description'}
						className={'form-control'}
					/>
				</td>
				<td>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						value={this.state.estimate}
						type={'text'}
						name={'estimate'}
						className={'form-control'}
					/>
             	</td>
             	<td>
					<input
						onChange={this.onChange}
						onBlur={this.onBlur}
						value={this.state.actual}
						type={'text'}
						name={'actual'}
						className={'form-control'}
					/>
             	</td>

				<td>
					{action_button}
				</td>
				<td><button className="btn btn-sm btn-danger" onClick={self.onDelete}>X</button></td>
			</tr>
		);
	}
}

const mappedActions = {
	updateEstimateVendor,
	deleteEstimate,
	sendEstimate,
	updateVehicleProperty,
	lookupUserNameById,
	lookupVendors,
	getDateTime,
	readableStamp,
};

function mapStateToProps(state) {
	return {
		current_user: state.current_user,
		active_vehicle: state.settings_refresh.active_vehicle,
	};
}
export default connect(mapStateToProps, mappedActions)(WorkOrderListItem);
