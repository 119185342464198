import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
import TextFieldGroup from '../../inputs/TextFieldGroup';
import { addFilter } from '../../actions/apiFilters';
import { toggleAddFilter } from '../../actions/toggleMenu';

class AddFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false, filter_choice: '', filter_name: '', filter_operator: '', filter_value: '', filter_target: '', data_origin: '', filter_type: '', name: '', errors: { addFilterError: false },
		};
		this.onChange = this.onChange.bind(this);
		this.onChangeData = this.onChangeData.bind(this);
		this.onChangeType = this.onChangeType.bind(this);
		this.onChangeDataOrigin = this.onChangeDataOrigin.bind(this);
		this.onChangeOperator = this.onChangeOperator.bind(this);

		this.onChangeSelectAllFilters = this.onChangeSelectAllFilters.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.addFilter = this.addFilter.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChangeData(e) {
		this.setState({ filter_target: e.value });
	}

	onChangeType(e) {
		this.setState({ filter_type: e.value });
	}

	onChangeDataOrigin(e) {
		this.setState({ data_origin: e.value });
	}

	onChangeOperator(e) {
		this.setState({ filter_operator: e.value });
	}

	onChangeSelectAllFilters(e) {
		if (e.value == 'Create New Filter') {
			this.props.toggleAddFilter(true);
		} else {
			this.props.toggleAddFilter(false);
		}
		this.setState({ filter_choice: e.value });
	}

	onClick(e) {

	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	afterOpenModal() {
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	vehicle_columns() {
		const newObj = Object.keys(this.props.vehicle_columns).map(key => this.props.vehicle_columns[key]);
		return _.sortBy(newObj, 'name');
	}

	selectColumns() {
		const columns = this.vehicle_columns();

		const options = columns.map((option) => {
			const obj = { value: option.id, label: option.name };
			return obj;
		});
		return options;
	}

	allFilters() {
		const options = this.props.all_filters.map((option) => {
			const obj = { value: option.id, label: `${option.filter_name} ( ${option.filter_target} ${option.filter_operator} ${option.filter_value} )` };
			return obj;
		});

		const obj = { value: 'Create New Filter', label: 'Create New Filter' };
		options.push(obj);
		return options;
	}

	selectData() {
		const options = this.props.vehicle_columns.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});
		return options;
	}

	selectComparison() {
		const comparisonArray = [{ name: 'Greater Than', label: 'Greater Than' },
			{ name: 'Greater or Equal', label: 'Greater or Equal' }, { name: 'Equals', label: 'Equals' }, { name: 'Not Equals', label: 'Not Equals' },
			{ name: 'Less Than', label: 'Less Than' }, { name: 'Less or Equal', label: 'Less or Equal' }, { name: 'Contains', label: 'Contains' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectDataType() {
		const comparisonArray = [{ name: 'number', label: 'Number' },
			{ name: 'condition', label: 'Condition' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectDataOrigin() {
		const comparisonArray = [{ name: 'vehicle', label: 'Vehicle' },
			{ name: 'vehicle_meta', label: 'Vehicle Meta' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	deleteDepartment(e) {
		this.props.deleteDepartment(e.target.dataset.id);
	}

	addFilter() {
		const self = this;
		const { department } = this.props;
		const errorType = 'add filter';

		const data = {
			filter_name: this.state.filter_name,
			filter_operator: this.state.filter_operator,
			filter_value: this.state.filter_value,
			filter_target: this.state.filter_target,
			filter_type: this.state.filter_type,
			data_origin: this.state.data_origin,
			active: true,
		};

		this.props.addFilter(data, errorType);
	}

	render() {
		const { department } = this.props;
		const { errors } = this.state;
		var body = '';
		var body = <div className="container">

			<div className="list-group">

				<div className="list-group-item">
					<TextFieldGroup
						field="filter_name"
						label="Filter Name"
						onChange={this.onChange}
						value={this.state.filter_name}
						type="text"
						key={'add_filter_name'}
						error={errors.addFilterError}
					/>
				</div>
				<div className="list-group-item">
					<label>Data Origin</label>
					<Select
						onChange={this.onChangeDataOrigin}
						value={this.selectDataOrigin().filter(({ value }) => value === this.state.data_origin)}
						name={'data_origin'}
						options={this.selectDataOrigin()}
						className="text-capitalize bigdropdown"
						clearable={false}
						searchable
						key={'add_data_origin'}
						placeholder="Choose Data Origin"
						error={errors.addFilterError}
					/>
				</div>
				<div className="list-group-item">
					<label>Data Type</label>
					<Select
						onChange={this.onChangeType}
						value={this.selectDataType().filter(({ value }) => value === this.state.filter_type)}
						name={'filter_type'}
						options={this.selectDataType()}
						className="text-capitalize bigdropdown"
						clearable={false}
						searchable
						key={'add_filter_type'}
						placeholder="Choose Filter Type"
						error={errors.addFilterError}
					/>
				</div>
				<div className="list-group-item">
					<label>Data</label>
					<Select
						onChange={this.onChangeData}
						value={this.selectData().filter(({ value }) => value === this.state.filter_target)}
						name={'filter_target'}
						options={this.selectData()}
						className="text-capitalize bigdropdown"
						clearable={false}
						searchable
						key={'add_filter_target'}
						placeholder="Choose Input Type"
						error={errors.addFilterError}
					/>
				</div>
				<div className="list-group-item">
					<label>Operator</label>
					<Select
						onChange={this.onChangeOperator}
						value={this.selectComparison().filter(({ value }) => value === this.state.filter_operator)}
						name={'filter_operator'}
						options={this.selectComparison()}
						className="text-capitalize bigdropdown"
						clearable={false}
						searchable
						key={'add_filter_operator'}
						placeholder="Choose Operator"
						error={errors.addFilterError}
					/>
				</div>
				<div className="list-group-item">
					<TextFieldGroup
						field="filter_value"
						name="filter_value"
						label="Value"
						onChange={this.onChange}
						value={this.state.filter_value}
						type="text"
						key={'add_filter_value'}
						error={errors.addFilterError}
					/>

				</div>
			</div>
             </div>;

		return (
			<span>
				<Modal
					isOpen={this.state.modalIsOpen}
					onAfterOpen={this.afterOpenModal}
					onRequestClose={this.closeModal}
					className="d-flex justify-content-center"
					contentLabel="Add Condition/Filter"
					ariaHideApp={false}
					overlayClassName="overlay"
				>

					<div className="card space-top-1 w-75">
						<div className="card-header bg-dark text-white">

                      Add/Create new Condition
							{' '}
							<button className="btn btn-sm btn-danger float-end" onClick={this.closeModal}>x</button>
						</div>
						<div className="card-block bg-white">
							<hr />
							{body}

						</div>
						<div className="card-footer">
							<button className="btn btn-sm btn-success float-start" onClick={this.addFilter}>
								<i className="fa fa-plus" />
								{' '}
Add
							</button>
						</div>
					</div>
				</Modal>
				<button className="btn btn-sm btn-primary float-end" onClick={this.openModal}>
					<i className="fa fa-plus" />
					{' '}
Create/Add Condition
				</button>
			</span>
		);
	}
}

const mappedActions = {
	toggleAddFilter,
	addFilter,
};

function mapStateToProps(state) {
	return {
		departments: state.settings_departments.departments,
		all_filters: state.settings_site.all_filters,
		vehicle_columns: state.settings_departments.vehicle_columns,
		edit_department: state.settings_admin.edit_department,
		show_add_filter: state.settings_nav.show_add_filter,
	};
}
export default connect(mapStateToProps, mappedActions)(AddFilter);
