import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditDropdownLists from './EditDropdownLists';

class EditColumns extends Component {
	constructor(props) {
		super(props);

		this.state = {
			current_default_profile: this.props.current_default_profile, departments: this.props.departments, errors: { addDepartmentPropertyError: false }, option_name: '',
		};
	}

	vehicle_columns() {
		const newObj = Object.keys(this.props.vehicle_columns).map(key => this.props.vehicle_columns[key]);
		return _.sortBy(newObj, 'name');
	}

	render() {
		const self = this;
		const { props } = this;

		return (
			<div className="col-md-12">
				<div className="card space-top-0">

					<h3 className="text-center pad-top-1">Add/Remove Options from Dropdown Lists</h3>

					<div className="card bg-light space-bottom-1 space-top-1 mx-auto w-75 p-3">

						<div className="card-text">

							<div className="d-flex flex-column">
								<div className="p-2 list-group setscroll">
									<small>
										<i className={'fa fa-info-circle text-info'} />
										{' '}
Use the Edit button to update your lists
									</small>
									{this.vehicle_columns().map((vehicle_column) => {
										if (vehicle_column.input_name == 'select') {
											return <EditDropdownLists {...props} key={`vehicle_column_name_${vehicle_column.name}`} name={vehicle_column.name} column={vehicle_column} column_id={vehicle_column.id} />;
										}
									})}

								</div>

							</div>
						</div>
						<div className="card-footer" />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		vehicle_columns: state.settings_departments.vehicle_columns,
	};
}
export default connect(mapStateToProps)(EditColumns);
