import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DealershipLink from './DealershipLink';
import Content from '../Content';
import SearchDealer from './SearchDealer';
import Logo from '../../assets/images/ipacket_logo.png';
import './dealerships.scss'

import {
	getUserCheck,
} from '../../actions/apiGetInfo';

import {
	setCurrentDealership,
	setDealership,
	resetRefresh,
	resetNav,
	resetAdmin,
	resetSite,
} from '../../actions/apiSetInfo';

import {
	logout,
} from '../../actions/authentication';

import {
	vsearch,
} from '../../actions/lookups';

class Dealerships extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
				subdomain: '' || '', vin: '' || '', vehicle: {},
			};

		if (props.match) {
			const vin_link = props.match.params.vinToken.split(':');

			this.state = {
				subdomain: vin_link[0] || '', vin: vin_link[1] || '', vehicle: {},
			};
		}
	}

	componentDidMount() {
		if (this.state.vin != '') {
			this.props.vsearch(this.state.vin, true);
		}

		setTimeout(() => {
			if (this.props.dealerships.length === 1) {
				this.props.setCurrentDealership(this.props.dealerships[0].database, this.props.dealerships[0].subdomain, this.props.dealerships[0].id);
			}
		}, 3000);
		setInterval(() => {
			if (this.props.authentication && this.props.authentication.access_token) {
				this.props.getUserCheck();
			}
		}, 150000);
	}

	logout(e) {
		logout(true)
		// clearInterval(this.props.interval_number);
		// setTimeout(() => {
		// 	this.props.setDealership('');
		// 	this.props.resetRefresh();
		// 	this.props.resetNav();
		// 	this.props.resetAdmin();
		// 	this.props.resetSite();
		// }, 2000);
		// window.location.reload(true);
	}

	displayDealerships() {
		if (this.props.searched_dealerships.length > 0) {
			return this.props.searched_dealerships.map(installation => <DealershipLink dealership={installation} key={`dealership_${installation.id}`} />);
		}

		return this.props.dealerships.map(installation => <DealershipLink dealership={installation} key={`dealership_${installation.id}`} />);
	}

	render() {

		return <div className="container-fluid mobile-pad-left-2 mobile-pad-right-2 dealerpage dealership-search-page">
			{this.props.current_dealership ? <React.Fragment/> :
				<div className="row navbar-light sticky-top dealership-search-page-header" style={{ background: 'white' }}>

					<div className="col-sm pad-top-1 pad-bottom-1 mobile-pad-left-1 dealership-search-page-header-section dealership-search-page-header-section-left">
						<img key="toplogo" src={Logo} style={{ marginTop: 5 }} height={50} />
					</div>

					<div className="col-sm pad-top-1 pad-bottom-1 dealership-search-page-header-section dealership-search-page-header-section-middle">
						<SearchDealer />
					</div>

					<div className="col-sm pad-top-1 pad-bottom-1 dealership-search-page-header-section dealership-search-page-header-section-right">
						<div className="btn btn-md float-end btn-secondary" onClick={this.logout.bind(this)}>
							<i className="far fa-power-off" />
							{' '}
							Logout
						</div>
					</div>
				</div>
			}
			{this.props.current_dealership ? <Content /> : <div className="dealership-search-page-body">{this.displayDealerships()}</div>}
		</div>;
	}
}

const mappedActions = {
	getUserCheck,
	setCurrentDealership,
	setDealership,
	resetRefresh,
	resetNav,
	resetAdmin,
	resetSite,
	logout,
	vsearch,
};

function mapStateToProps(state) {
	return {
		current_dealership: state.settings_dealerships.current_dealership,
		dealerships: state.settings_dealerships.set_dealerships || [],
		searched_dealerships: state.settings_dealerships.set_searched_dealerships || [],
		authentication: state.authentication,
	};
}
export default connect(mapStateToProps, mappedActions)(Dealerships);
