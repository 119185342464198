import _ from 'lodash';
import createReducer from './createReducer.js';
import * as types from '../../actions/types';

const initialState = {
	active_vehicles: [],
	active_vehicle: [],
	all_vehicles: [],
	duplicate_vins: [],
	sold_vehicles: [],
	estimate: [],
	estimates: [],
	appraisal: [],
	appraisals: [],
	single_estimate: [],
	single_estimate_user: [],
	audits: [],
	work_items: [],
	work_item_presets: [],
	attachments: [],
	recalls: [],
	photos: [],
	part_orders: [],
	create_calendar_event: {
		vehicle_id: '',
		start_date: '',
		start_time: '',
		end_date: '',
		end_time: '',
		event: '',
		notes: '',
	},
	dealer_form: 'usedcar',
	department_histories: [],
	searched_vehicles: [],
	vehicle_notes: [],
	found_vehicles: [],
	update_status: 'first_load',
	report_status: 'first_load',
	custom_modal_tab: {
		vehicle_id: '',
		tab: {},
		vehicle: [],
		status: false,
	},
};

export const settings_refresh = createReducer(initialState, {
	[types.SET_SEARCHED_VEHICLES](state = initialState, action) {
		return {
			...state,
			searched_vehicles: action.vehicles,
		};
	},
	[types.SET_ACTIVE_VEHICLE](state = initialState, action) {
		return {
			...state,
			active_vehicle: action.active_vehicle,
		};
	},
	[types.SET_FOUND_VEHICLE](state = initialState, action) {
		return {
			...state,
			found_vehicles: action.vehicles,
		};
	},
	[types.SET_VEHICLES](state = initialState, action) {
		return {
			...state,
			active_vehicles: action.vehicles,
		};
	},
	[types.SET_ALL_VEHICLES](state = initialState, action) {
		return {
			...state,
			all_vehicles: action.vehicles,
		};
	},
	[types.SET_DUPLICATE_VINS](state = initialState, action) {
		return {
			...state,
			duplicate_vins: action.vehicles,
		};
	},
	[types.SET_SOLD_VEHICLES](state = initialState, action) {
		return {
			...state,
			sold_vehicles: _.sortBy(action.vehicles, 'days'),
		};
	},
	[types.SET_AUDITS](state = initialState, action) {
		return {
			...state,
			audits: action.audits,
		};
	},
	[types.SET_RECALLS](state = initialState, action) {
		return {
			...state,
			recalls: action.recalls,
		};
	},
	[types.SET_ESTIMATES](state = initialState, action) {
		return {
			...state,
			estimates: _.sortBy(action.estimates, 'id'),
		};
	},
	[types.SET_SINGLE_ESTIMATE](state = initialState, action) {
		return {
			...state,
			single_estimate: _.sortBy(action.estimates, 'id'),
		};
	},
	[types.SET_SINGLE_ESTIMATE_USER](state = initialState, action) {
		return {
			...state,
			single_estimate_user: _.sortBy(action.user, 'id'),
		};
	},
	[types.SET_WORK_ITEM_PRESETS](state = initialState, action) {
		return {
			...state,
			work_item_presets: _.sortBy(action.work_item_presets, 'id'),
		};
	},
	[types.SET_WORK_ITEMS](state = initialState, action) {
		return {
			...state,
			work_items: _.sortBy(action.work_items, 'id'),
		};
	},
	[types.SET_ATTACHMENTS](state = initialState, action) {
		return {
			...state,
			attachments: _.sortBy(action.attachments, 'id'),
		};
	},
	[types.SET_PHOTOS](state = initialState, action) {
		return {
			...state,
			photos: action.photos,
		};
	},
	[types.SET_PART_ORDERS](state = initialState, action) {
		return {
			...state,
			part_orders: _.sortBy(action.part_orders, 'id'),
		};
	},
	[types.SET_ACTIVE_APPRAISALS](state = initialState, action) {
		return {
			...state,
			appraisals: _.sortBy(action.appraisals, 'id'),
		};
	},
	[types.SET_DEPARTMENT_HISTORIES](state = initialState, action) {
		return {
			...state,
			department_histories: _.sortBy(action.department_histories, 'id'),
		};
	},
	[types.SET_VEHICLE_NOTES](state = initialState, action) {
		return {
			...state,
			vehicle_notes: _.sortBy(action.vehicle_notes, 'id'),
		};
	},
	[types.SET_ESTIMATE_SINGLE](state = initialState, action) {
		return {
			...state,
			estimate: action.estimate,
		};
	},
	[types.SET_CREATE_CALENDAR_EVENT](state = initialState) {
		return {
			...state,
			create_calendar_event: action.create_calendar_event,
		};
	},
	[types.UPDATE_STATUS](state = initialState, action) {
		return {
			...state,
			update_status: action.update_status,
		};
	},
	[types.REPORT_STATUS](state = initialState, action) {
		return {
			...state,
			report_status: action.report_status,
		};
	},
	[types.SET_CURRENT_DEALER_FORM](state = initialState, action) {
		return {
			...state,
			dealer_form: action.dealer_form,
		};
	},
	[types.SET_CUSTOM_MODAL_TAB](state = initialState, action) {
		return {
			...state,
			custom_modal_tab: {
				tab: action.tab,
				vehicle_id: action.vehicle_id,
				vehicle: action.vehicle,
				status: action.status,
			},
		};
	},
	[types.RESET_REFRESH](state = initialState) {
		return {
			...state,
			active_vehicles: [],
			active_vehicle: [],
			all_vehicles: [],
			duplicate_vins: [],
			sold_vehicles: [],
			estimate: [],
			estimates: [],
			appraisal: [],
			appraisals: [],
			single_estimate: [],
			single_estimate_user: [],
			work_item_presets: [],
			work_items: [],
			attachments: [],
			audits: [],
			recalls: [],
			photos: [],
			part_orders: [],
			create_calendar_event: {
				vehicle_id: '',
				start_date: '',
				start_time: '',
				end_date: '',
				end_time: '',
				event: '',
				notes: '',
			},
			dealer_form: 'usedcar',
			department_histories: [],
			searched_vehicles: [],
			vehicle_notes: [],
			found_vehicles: [],
			update_status: 'first_load',
			report_status: 'first_load',
			custom_modal_tab: {
				vehicle_id: '',
				tab: {},
				vehicle: [],
				status: false,
			},
		};
	},
});

export default 'settings_refresh';
